import { TipoBuscaBovino } from "../shared/enums/Enums";

export class ConsultaRapidaBovinoModel {
  Id: string;
  CodigoBovino: string;
  NomeBovino: string;
  SexoBovino: number;
  IdProprietario: string;
  IdProprietarioConsulta: string;
  CodigoNomeProprietario: string;
  TipoBusca: TipoBuscaBovino;
  ConsideraInativos: boolean;
  ConsultaAnimalTodosCriadores: boolean;
  ExibeProprietario: boolean;
  DesabilitaBuscaAvançada: boolean;
  TituloConsulta: string;
  CodigoNomeBovino: string;
  CodigoNomeBovinoComProprietario: string;
  Prefix: string;
  CallBackFunction: string;
  CodigoTouro: string;
  NomeTouro: string;
  DataNascimento: string;

  constructor() {
    this.Id = "";
    this.CodigoBovino = "";
    this.NomeBovino = "";
    this.SexoBovino = 0;
    this.IdProprietario = "";
    this.IdProprietarioConsulta = "";
    this.CodigoNomeProprietario = "";
    this.TipoBusca = TipoBuscaBovino.Todos;
    this.ConsideraInativos = false;
    this.ConsultaAnimalTodosCriadores = false;
    this.ExibeProprietario = false;
    this.DesabilitaBuscaAvançada = false;
    this.TituloConsulta = "";
    this.CodigoNomeBovino = "";
    this.CodigoNomeBovinoComProprietario = "";
    this.Prefix = "";
    this.CallBackFunction = "";
    this.CodigoTouro = "";
    this.NomeTouro = "";
    this.DataNascimento = "";

  }
}