import { environment } from "src/environments/environment";
import { TipoRegistroReservado } from "../shared/enums/Enums";
import { InspetorModel } from "./InspetorModel";

export class ReservarRegistrosInspetorModel {
  FiliadaId: string;
  Tipo: TipoRegistroReservado;
  Quantidade: string;
  Inspetor: InspetorModel;

  constructor()  {
    this.FiliadaId = (environment as any).contexto === 'jersey'? '' : 'PJ_ACGWB';
    this.Tipo = TipoRegistroReservado.None;
    this.Quantidade = '';
    this.Inspetor = new InspetorModel();
  }
}