<div class="row">
  <div class="form-group col-lg-4 col-md-4 col-sm-12">
    <label for="CodigoBovino">Código {{bovino.TituloConsulta}}</label>
    <input *ngIf="bovino.Readonly" type="text" class="form-control form-control-sm" name="CodigoBovino" [disabled]="bovino.Readonly || permiteEditar()" [(ngModel)]="bovino.CodigoBovino" readonly>

    <div *ngIf="!bovino.Readonly" class="input-group input-group-sm">
      <input type="text" class="form-control" name="CodigoBovino" [(ngModel)]="bovino.CodigoBovino" autocomplete="off" (keydown.enter)="consultarPorCodigo()" [disabled]="permiteEditar()">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" type="button" (click)="consultarPorCodigo()"> <i class="bi bi-check"></i> </button>
      </div>
    </div>
  </div>

  <div class="form-group col-lg-8 col-md-8 col-sm-12">
    <label for="NomeBovino">Nome {{bovino.TituloConsulta}}</label>
    <input *ngIf="bovino.Readonly" type="text" class="form-control form-control-sm" name="NomeBovino" [(ngModel)]="bovino.NomeBovino" [disabled]="permiteEditar()" readonly>

    <div *ngIf="!bovino.Readonly" class="input-group input-group-sm">
      <input type="text" class="form-control" name="NomeBovino" [(ngModel)]="bovino.NomeBovino" [disabled]="permiteEditar()" readonly>
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" type="button" (click)="consultarPorNome()" [disabled]="permiteEditar()"> <i class="bi bi-search"></i> </button>
        <button class="btn btn-outline-secondary" type="button" (click)="limpar()" [disabled]="permiteEditar()"> <i class="bi bi-eraser"></i> </button>
      </div> 
    </div>
  </div>
</div>