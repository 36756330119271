<app-formulario-tabs
  [formulario]="formulario"
  [listaComunicacao]="lote?.ListaComunicacao"
  (tabSelecionada)="tabSelecionada($event)"
  (criadorSelecionado)="criadorSelecionado($event)"
></app-formulario-tabs>

<div class="row">
  <div class="col-lg-12 col-sm-12">
    <div class="card card-principal-evento">
      <div class="card-body">

        <!--Ocorrencia Atual-->
        <div *ngIf="!isNewLote()">
          <div class="row">
            <div class="col-lg-12">
              <div class="card card-evento">
                <div class="card-body">

                  <div class="row card-title-evento">

                    <div class="col-6">
                      <h4 class="card-title">
                        <mat-icon svgIcon="balanca"></mat-icon> Dados da Pesagem
                      </h4>
                    </div>

                    <div class="col-6 text-right">
                      <button 
                        *ngIf="ocorrenciaAtual.Id !== '-1'"  
                        type="button" class="btn btn-link btn-sm" 
                        (click)="documentosDaOcorrencia()" >
                        <i class="far fa-folder"></i> Documento
                      </button>
                    </div>
                    
                  </div>


                  <div class="row">
                    
                    <div class="col-lg-9 col-md-12 col-sm-12 col-xs-12">
                      <app-consultar-bovino 
                      [bovino]="ocorrenciaAtual.AnimalCadastrado"
                      [ocorrenciaAtualSituacao]="ocorrenciaAtual.Situacao"></app-consultar-bovino>
                    </div> 

                    <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
                      <label>Número particular</label>
                      <input disabled name="NumeroParticular" type="text" class="form-control form-control-sm" [(ngModel)]="ocorrenciaAtual.AnimalCadastrado.OrelhaDireita">
                    </div>

                    <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
                      <label for="DataOcorrencia">*Data Pesagem</label>
                      <div class="input-group input-group-sm">
                        <input class="form-control"  [max]="maxDate" [matDatepicker]="dataPesagem" name="DataOcorrencia" [(ngModel)]="ocorrenciaAtual.DataPesagem" [disabled]="isOcorrenciaReadOnly()">
                        <div *ngIf="!isOcorrenciaReadOnly()" class="input-group-append">
                          <button class="btn btn-outline-secondary" type="button" (click)="dataPesagem.open()"> <i class="bi bi-calendar3"></i> </button>
                        </div>
                      </div>
                      <mat-datepicker [startAt]="ocorrenciaAtual.DataOcorrencia" #dataPesagem></mat-datepicker>
                    </div>

                    <div class="form-group col-lg-2 col-md-4 col-sm-6 col-xs-12">
                      <label for="TipoPadreacao">Sexo</label>
                      <select
                        disabled
                        class="form-control form-control-sm"
                        name="Sexo"
                        [(ngModel)]="ocorrenciaAtual.AnimalCadastrado.SexoBovino"
                      >
                        <option value="1">Macho</option>
                        <option value="2">Fêmea</option>
                      </select>
                    </div>

                    <div class="form-group col-lg-4 col-md-8 col-sm-6 col-xs-12">
                      <!-- Retira * de obrigatoriedade do campo. -->
                      <!-- {{ ocorrenciaAtual.AnimalCadastrado.SexoBovino != 1? '' : '*' }} -->
                      <label for="formulario">Circunferência escrotal</label>
                      <div class="input-group">
                        <input
                          [disabled]="isOcorrenciaReadOnly() || ocorrenciaAtual.AnimalCadastrado.SexoBovino != 1"
                          type="number"
                          class="form-control form-control-sm"
                          name="CircunferenciaEscrotal"
                          [(ngModel)]="ocorrenciaAtual.CircunferenciaEscrotal"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text form-control-sm offset-md-0">cm</span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
                      <label for="formulario">*Peso</label>
                      <div class="input-group">
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          name="Peso"
                          [disabled]="isOcorrenciaReadOnly()"
                          [(ngModel)]="ocorrenciaAtual.Peso"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text form-control-sm offset-md-0">Kg</span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
                      <label for="formulario">Altura da cernelha</label>
                      <div class="input-group">
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          name="AlturaCernelha"
                          [disabled]="isOcorrenciaReadOnly()"
                          [(ngModel)]="ocorrenciaAtual.AlturaCernelha"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text form-control-sm offset-md-0">m</span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
                      <label for="Manejo">*Manejo</label>
                      <select class="form-control form-control-sm" name="Manejo" [(ngModel)]="ocorrenciaAtual.Manejo" [disabled]="isOcorrenciaReadOnly()" >
                        <option value="-1">Selecione...</option>
                        <option value=1>Confinado</option>
                        <option value=2>Semi confinado</option>
                        <option value=3>Pasto</option>
                      </select>
                    </div>

                    <div class="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12">
                      <label for="formulario">Manejo criador</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        name="ManejoCriador"
                        [disabled]="isOcorrenciaReadOnly()"
                        [(ngModel)]="ocorrenciaAtual.ManejoCriador"
                      />
                    </div>

                  </div>

                </div>
              </div>
            </div>

            <div class="col-lg-12">
              <app-botoes-acoes-ocorrencia [lote]="lote" [idOcorrencia]="ocorrenciaAtual.Id"
                (acaoSelecionada)="acaoBotao($event)" tipoEvento="pesagem"></app-botoes-acoes-ocorrencia>
            </div>
          </div>


          <!--Lista de Ocorrencias-->
          <div>
            <div *ngIf="ocorrencias.length > 0" class="row" style="margin-top: 15px;">
              <div class="col-lg-12 table-responsive">
                <table class="table table-hover table-header-custom">
                  <thead>
                    <tr>
                      <th>Animal</th>
                      <th>Data Pesagem</th>
                      <th class="text-center">Peso</th>
                      <th class="text-center">Altura Cernelha</th>
                      <th class="text-center">Circunferência Escrotal</th>
                      <th>Manejo</th>
                      <th>Manejo Criador</th>
                      <th class="text-center">Situação</th>
                      <th class="text-center" width="10%">Opções</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let ev of ocorrencias" [ngClass]="{'ocorrencia-em-destaque': ev.Id === ocorrenciaAtual.Id }">
                      <td>{{ ev.AnimalCadastrado.CodigoBovino + '-' + ev.AnimalCadastrado.NomeBovino }}</td>
                      <td>{{ ev.DataPesagem | date: 'dd/MM/yyyy' }}</td>
                      <td class="text-center">{{ ev.Peso }}</td>
                      <td class="text-center">{{ ev.AlturaCernelha }}</td>
                      <td class="text-center">{{ ev.CircunferenciaEscrotal }}</td>
                      <td>{{ getDescTipoManejo(ev.Manejo) }}</td>
                      <td>{{ ev.ManejoCriador }}</td>
                      <td class="text-center text-nowrap">
                        <div [innerHtml]="descSituacao(ev.Situacao)"></div>
                      </td>
                      <td class="text-center text-nowrap acoes-evento">
                        <i *ngIf="ev.ImputaMulta === 1" matTooltip="Multa Imputada" class="fas fa-dollar-sign"></i>
                        <i *ngIf="ev.SolicitarDna === 1" matTooltip="Será solicitado DNA no Nascimento" class="fas fa-dna"> </i>
                        <i *ngIf="permiteEdicao(ev.Situacao)" class="fas fa-edit" (click)="editar(ev)"></i>
                        <i *ngIf="!permiteEdicao(ev.Situacao)" class="fas fa-eye" (click)="editar(ev)"></i>
                        <i *ngIf="lote.Situacao === 1" class="fas fa-trash-alt" (click)="deletar(ev)"></i>
                        <i *ngIf="ev.IndInconsitencia" class="fa fa-exclamation-triangle" style="cursor: pointer;" matTooltip="{{ev.Inconsistencia}}"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</div>