<div class="row">
    <div class="col-lg-6 col-md-12 col-sm-12">
      <app-consultar-criador [criador]="formConsulta.criador" (criadorSelecionado)="criadorSelecionado($event)"></app-consultar-criador>
    </div>

    <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
      <label>Nascimento De</label>
      <div class="input-group input-group-sm">
        <input class="form-control" [matDatepicker]="nascimentoDe" name="nascimentoDe" [(ngModel)]="formConsulta.nascimentoDe"> 
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" type="button" (click)="nascimentoDe.open()"> <i class="bi bi-calendar3"></i> </button>
        </div>
      </div>
      <mat-datepicker [startAt]="formConsulta.nascimentoDe" #nascimentoDe></mat-datepicker>
    </div>
    
    <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
      <label>Nascimento Até</label>
      <div class="input-group input-group-sm">
        <input class="form-control" [matDatepicker]="nascimentoAte" name="nascimentoAte" [(ngModel)]="formConsulta.nascimentoAte"> 
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" type="button" (click)="nascimentoAte.open()"> <i class="bi bi-calendar3"></i> </button>
        </div>
      </div>
      <mat-datepicker [startAt]="formConsulta.nascimentoAte" #nascimentoAte></mat-datepicker>
    </div>
    
    <div class="col-lg-6 col-md-12 col-sm-12">
      <div class="row">
        <div class="form-group col-lg-4 col-md-4 col-sm-12">
          <label for="CodigoBovino">Código Animal</label>
          <input (keydown.enter)="consultar()" type="text" class="form-control form-control-sm" name="CodigoBovino" [(ngModel)]="formConsulta.bovino.CodigoBovino">
        </div>
      
        <div class="form-group col-lg-8 col-md-8 col-sm-12">
          <label for="NomeBovino">Nome Animal</label>
          <input (keydown.enter)="consultar()" type="text" class="form-control form-control-sm" name="NomeBovino" [(ngModel)]="formConsulta.bovino.NomeBovino">
        </div>
      </div>
    </div>
        
    <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
      <label for="formularioProtocolo">Situação</label>
      <select class="form-control form-control-sm" name="situacao" [(ngModel)]="formConsulta.situacao">
        <option *ngFor="let situacaoBovino of situacoesBovino" [value]="situacaoBovino.value" [label]="situacaoBovino.label"></option>
      </select>
    </div>
  
    <div *ngIf="env.contexto === 'wagyu'" class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
      <label for="formularioProtocolo">Raça</label>
      <select [disabled]="!formConsulta.criador.id || formConsulta.criador.id === '-1' || racas.length === 1" class="form-control form-control-sm" name="raca" [(ngModel)]="formConsulta.bovino.RacaBovino">
        <option *ngFor="let raca of racas" [value]="raca.value" [label]="raca.label"></option>
      </select>
    </div>
  
  </div>