import { TipoRegistroReservado } from "../shared/enums/Enums";
import { InspetorModel } from "./InspetorModel";

export class FiltroConsultaRegistrosReservadosModels {
  inspetor: InspetorModel;
  tipo: TipoRegistroReservado;
  filiadaId: string;

  constructor() {
    this.filiadaId = '';
    this.tipo = TipoRegistroReservado.None;
    this.inspetor = new InspetorModel();
  }
}