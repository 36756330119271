import { ECertificado, SituacaoSRG } from './../../shared/enums/Enums';
import { environment } from "src/environments/environment";
import { AccountService } from 'src/app/account/shared/account.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { CertificadoModel } from 'src/app/models/CertificadoModel'
import { ApiService } from 'src/app/shared/api.service';
import Swal from 'sweetalert2';
import { DestinoInspecao } from "src/app/shared/enums/Enums";
import { BovinoModel } from 'src/app/models/BovinoModel';

@Component({
  selector: 'app-certificados-manter',
  templateUrl: './certificados-manter.component.html',
  styleUrls: ['./certificados-manter.component.scss']
})
export class CertificadosManterComponent implements OnInit {

  readOnly: boolean = false;
  Action: string = 'Create';
  formRegistrar: CertificadoModel = new CertificadoModel();
  inspecaoLocalizada: boolean = false;
  contexto: string = 'jersey';

  constructor(
    private router: Router,
    public accountService: AccountService,
    private api: ApiService,
    private route: ActivatedRoute
  ) {
    this.contexto = (environment as any).contexto;
  }

  ngOnInit(): void {
    const actionParam = this.route.snapshot.queryParamMap.get('Action') as string;
    if (actionParam) {
      this.Action = atob(actionParam);
    }

    if (this.Action == 'Show') {
      this.readOnly = true
      this.formRegistrar.id = this.route.snapshot.queryParamMap.get('Id') as string;
      this.api.consultarCertificadosPorId(this.formRegistrar.id).then((result: any) => {
        if (result) {
          if (result.success) {
            this.formRegistrar.animal = result.Animal
            this.formRegistrar.certificado = result.certificado
            this.formRegistrar.dataEmissao = result.dataEmissao
          } else {
            Swal.fire(result.message, '', 'error');
          }
        }
      });

    } else if (this.Action == 'Gerar') {
      const bovinoIdParam = this.route.snapshot.queryParamMap.get('Id') as string;
      if (!bovinoIdParam) return;

      const bovinoId = atob(bovinoIdParam);

      // Recupera o certificado e popula o formRegistrar
      this.api.recuperarCertificadoPendente(bovinoId).then((result: any) => {
        if (result) {
          if (result.success) {
            this.formRegistrar.animal = result.Animal
            this.formRegistrar.certificado = result.Certificado
          } else {
            Swal.fire(result.message, '', 'error');
          }
        }
      });

    } else {
      //Se o usuário logado for um criador, verifica se ele tem um evento em elaboração
      if (this.accountService.isCriador) {
        let token: any = this.accountService.getPayloadToken();
        if (token) {
          this.formRegistrar.animal.IdProprietario = token.CriadorId;
        }
      } else {
        if (this.accountService.isAdmin) {
          this.formRegistrar.animal.ConsultaAnimalTodosCriadores = true;
        }
      }
    }
  }

  botaoEnviar(): void {
    this.api.consultarDetalhesBovino(this.formRegistrar.animal.Id).then((result: any) => {
      if (result) {
        if ((this.formRegistrar.certificado == ECertificado.NASCIMENTO && result.Bovino.SituacaoSrg == 4) ||
          (this.formRegistrar.certificado == ECertificado.NASCIMENTO && result.Bovino.SituacaoSrg == 6)) {
          Swal.fire("ATENÇÃO! Só é permitida emissão de certificado DEFINITIVO ou ABATE para esse animal. Por favor, selecione o certificado válido para continuar.", '', 'warning');
          return;
        }
        if ((this.formRegistrar.certificado == ECertificado.REGISTRO && result.Bovino.SituacaoSrg == 2) ||
          (this.formRegistrar.certificado == ECertificado.REGISTRO && result.Bovino.SituacaoSrg == 3) ||
          (this.formRegistrar.certificado == ECertificado.REGISTRO && result.Bovino.SituacaoSrg == 5)) {
          Swal.fire("ATENÇÃO! Só é permitida emissão de certificado NASCIMENTO ou ABATE para esse animal. Por favor, selecione o certificado válido para continuar.", '', 'warning');
          return;
        }
      }
      if (['Create', 'Gerar'].includes(this.Action)) {
        this.api.manterCertificado(this.formRegistrar).then((result: any) => {
          if (result) {
            if (result.success) {
              Swal.fire(result.message, '', 'success');
              this.formRegistrar = new CertificadoModel();
              this.router.navigate(['certificados_consultar']);
            } else {
              Swal.fire(result.message, '', 'error');
            }
          }
        });
      }
    });
  }

  selecionaTipoCertificado(): void {
    if (this.formRegistrar.animal) {
      if (this.formRegistrar.animal.DestinoInspecao == DestinoInspecao.Abate) {
        this.inspecaoLocalizada = true
        this.formRegistrar.certificado = ECertificado.ABATE;
      } else if (this.formRegistrar.animal.DestinoInspecao == DestinoInspecao.Reproducao) {
        this.inspecaoLocalizada = true;
        this.formRegistrar.certificado = ECertificado.REGISTRO;
      } else {
        //this.limparFormCertificado();
        this.inspecaoLocalizada = false;
        this.formRegistrar.certificado = -1;
        Swal.fire("Não foi localizada Inspeção para este animal. Selecione manualmente o tipo no campo Certificado.", '', 'warning')
      }
    }
  }

  limparFormCertificado(): void {
    this.formRegistrar = new CertificadoModel();

    if (this.accountService.isCriador) {
      let token: any = this.accountService.getPayloadToken();
      if (token) {
        this.formRegistrar.animal.IdProprietario = token.CriadorId;
      }
    } else {
      if (this.accountService.isAdmin) {
        this.formRegistrar.animal.ConsultaAnimalTodosCriadores = true;
      }
    }
  }
}
