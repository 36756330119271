class EntidadeGenealogiaBovinoModel {
  Nome: string;
  Codigo: string;
  // Titulo: string;
  Situacao: string;
  BovinoId: string;
  // LinhaSuperior: string;
  // LinhaInferior: string;
  DataNascimento: string;
  AnimalExistente: boolean;

  constructor() {
    this.Nome = "";
    this.Codigo = "";
    // this.Titulo = "";
    this.Situacao = "";
    this.BovinoId = "";
    // this.LinhaInferior = "";
    // this.LinhaSuperior = "";
    this.DataNascimento = "";
    this.AnimalExistente = false;
  }
}

export class GenealogiaBovinoModel {
  Animal: EntidadeGenealogiaBovinoModel | null = null;

  Pai: EntidadeGenealogiaBovinoModel | null = null;
  Mae: EntidadeGenealogiaBovinoModel | null = null;

  Avo1Paterno: EntidadeGenealogiaBovinoModel | null = null;
  Avo2Paterno: EntidadeGenealogiaBovinoModel | null = null;
  Avo1Materno: EntidadeGenealogiaBovinoModel | null = null;
  Avo2Materno: EntidadeGenealogiaBovinoModel | null = null;
  
  Bisavo1Paterno: EntidadeGenealogiaBovinoModel | null = null;
  Bisavo2Paterno: EntidadeGenealogiaBovinoModel | null = null;
  Bisavo3Paterno: EntidadeGenealogiaBovinoModel | null = null;
  Bisavo4Paterno: EntidadeGenealogiaBovinoModel | null = null;
  Bisavo1Materno: EntidadeGenealogiaBovinoModel | null = null;
  Bisavo2Materno: EntidadeGenealogiaBovinoModel | null = null;
  Bisavo3Materno: EntidadeGenealogiaBovinoModel | null = null;
  Bisavo4Materno: EntidadeGenealogiaBovinoModel | null = null;
}