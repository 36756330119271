<div *ngIf="!valido" class="card" style="margin-top: 1em;">
	<div class="card-body row">
		
    <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
      <label>*CPF</label>
      <input 
        name="cpf" 
        type="text" 
        mask="000.000.000-00" 
        class="form-control form-control-sm" 
        [(ngModel)]="formValidaCpf.cpf"
      >
    </div>

    <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
      <label>*Grupo de Usuário</label>
      <select class="form-control form-control-sm" name="grupo" [(ngModel)]="formValidaCpf.grupo">
        <option *ngFor="let grupo of gruposUsuario" [value]="grupo.value" [label]="grupo.label"></option>
      </select>
    </div>

    <div class="form-group col-lg-5 col-md-8 col-sm-8 col-xs-12" *ngIf="contexto == 'jersey'">
      <app-selecionar-filiadas 
        [readonly]="filiadaReadOnly" 
        [filiadaId]="formValidaCpf.vinculacao" 
        defaultFirstSelectedLabel="Selecione..."
        (filiadaSelecionada)="filiadaSelecionada($event)"
      >
      </app-selecionar-filiadas>
    </div>

    <div class="botao-container col-lg-1 col-md-4 col-sm-4 col-xs-12">
      <button 
        type="button" 
        (click)="botaoValidar()"
        matTooltip="Consultar pessoa por CPF"
        class="btn btn-sm btn-block btn-primary"
      >
        <i class="fas fa-check"></i>
      </button>
    </div>

	</div>
</div>

<app-usuarios-manter 
  *ngIf="valido" 
  [usuarioValidacao]="usuario"
  [formCpfValido]="formValidaCpf" 
  [usuarioExistente]="usuarioExistente" 
></app-usuarios-manter>