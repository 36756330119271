<div class="card" style="margin-top: 1em;">
    <div class="card-body">
      <div *ngIf="exibirFiltrosConsulta" >
        <app-filtro-consulta-exposicao [formConsulta]="formConsulta"></app-filtro-consulta-exposicao>
  
        <div class="row justify-content-end">
          <div *ngIf="permiteNovo"  class="col-lg-2 col-md-4 col-sm-6">
            <button type="button" class="btn btn-sm btn-block btn-primary" (click)="novo()" >Novo</button>
          </div>
          <div class="col-lg-2 col-md-4 col-sm-6">
            <button type="button" class="btn btn-sm btn-block btn-primary" (click)="botaoConsultar()" >Buscar</button>
          </div>
        </div>
      </div>
  
      <div *ngIf="exposicoes.length > 0"  class="row" style="margin-top: 15px;">
        <div class="col-lg-12 table-responsive">
          <table class="table table-hover table-header-custom">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Data</th> 
                <th>Município</th> 
                <th>UF</th> 
                <th class="text-center">Total inscritos</th> 
              <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let exposicao of exposicoes | paginate : {itemsPerPage: pageSize, currentPage: page, totalItems: count};">
                <td>{{ exposicao.NomeExposicao }}</td>
                <td>{{ exposicao.DataExposicao | date: 'dd/MM/yyyy' }}</td>
                <td>{{ exposicao.Municipio }}</td> 
                <td>{{ exposicao.UF }}</td>
                <td class="text-center">{{ exposicao.TotalInscritos }}</td>
              <td class="text-center text-nowrap">
                <a type="button" matTooltip="Criadores">    <!-- TO-DO: deixar variavel/  "criadores" ou "nenhum criador inscrito" -->
                  <i class="action-icons fas fa-hat-cowboy"></i>
                </a>
                <a type="button" matTooltip="Inscrever">
                  <i class="action-icons fas fa-edit"></i>
                </a>
                <a type="button" matTooltip="Visualizar">
                  <i class="action-icons fas fa-eye"></i>
                </a>
                <a type="button" matTooltip="Editar">
                  <i class="action-icons bi bi-pen-fill"></i>
                </a>
                <a type="button" matTooltip="Manter premiações">
                  <i class="action-icons bi bi-trophy-fill"></i>
                </a>
              </td>
              </tr>
            </tbody>
          </table>
        </div>
  
        <div class="col-md-12 text-center">
          <pagination-controls 
            class="srg-paginacao"
            previousLabel="Anterior" 
            nextLabel="Próximo" 
            [responsive]="true" 
            (pageChange)="handlePageChange($event)"
            [autoHide]="true"
          >
          </pagination-controls>
        </div>
      
      </div>
  
    </div>
  </div>