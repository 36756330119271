import { ValidaCpfUsuario, UsuarioModel } from './UsuarioModel';
import { IdentificacaoFazenda } from './IdentificacaoFazenda';
import { PerfilUsuarioFazendaModel } from './PerfilUsuarioFazendaModel';
import { FazendaModel } from './FazendaModel';
import { IdentificacaoCriadorModel } from './IdentificacaoCriadorModel';


export class PerfilModel {

  CPF: ValidaCpfUsuario;
  CodigoAcesso: string;
  Afixo: string;
  Login: string;
  CNPJ: string;
  TipoEmail: string;
  Usuario: UsuarioModel;
  TipoUsuario: string;
  FiliadaId: string;
  ReadOnly: boolean;

  IdentificacaoFazenda: IdentificacaoFazenda;

  GrupoUsuario: PerfilUsuarioFazendaModel;

  Fazenda: FazendaModel;
  Fazendas: FazendaModel[];

  IdFazenda: string;


  constructor() {
    this.CPF = new ValidaCpfUsuario();
    this.Afixo = "";
    this.Login = "";
    this.CNPJ = "";
    this.TipoEmail = "";
    this.TipoUsuario = "";
    this.CodigoAcesso = "";
    this.FiliadaId = "";
    this.ReadOnly = false;
    this.IdentificacaoFazenda = new IdentificacaoFazenda();
    this.GrupoUsuario = new PerfilUsuarioFazendaModel();
    this.Usuario = new UsuarioModel();

    this.IdFazenda = "";
    this.Fazenda = new FazendaModel();
    this.Fazendas = [new FazendaModel()];
  }

}
