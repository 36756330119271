<div class="row">

  <div class="form-group col-lg-6 col-md-8 col-sm-12 col-xs-12">
    <app-consultar-criador [criador]="formConsulta.proprietario" (criadorSelecionado)="criadorSelecionado($event)"></app-consultar-criador>
  </div>

  <div class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
    <label>Emissão de</label>
    <div class="input-group input-group-sm">
      <input class="form-control" [matDatepicker]="emissaoDe" name="emissaoDe" [(ngModel)]="formConsulta.emissaoDe">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" type="button" (click)="emissaoDe.open()"> 
          <i class="bi bi-calendar3"></i> 
        </button>
      </div>
    </div>
    <mat-datepicker [startAt]="formConsulta.emissaoDe" #emissaoDe></mat-datepicker>
  </div>

  <div class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
    <label>Emissão até</label>
    <div class="input-group input-group-sm">
      <input class="form-control" [matDatepicker]="emissaoAte" name="emissaoAte" [(ngModel)]="formConsulta.emissaoAte">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" type="button" (click)="emissaoAte.open()"> 
          <i class="bi bi-calendar3"></i> 
        </button>
      </div>
    </div>
    <mat-datepicker [startAt]="formConsulta.emissaoAte" #emissaoAte></mat-datepicker>
  </div>

  <div class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
    <label for="formularioProtocolo">Assinado</label>
    <select class="form-control form-control-sm" name="assinado" [(ngModel)]="formConsulta.assinado">
      <option *ngFor="let assinado of listaAssinado" [value]="assinado.value" [label]="assinado.label"></option>
    </select>
  </div>

  <div class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
    <label for="formularioProtocolo">Certificado</label>
    <select class="form-control form-control-sm" name="certificado" [(ngModel)]="formConsulta.certificado">
      <option *ngFor="let certificado of listaCertificado" [value]="certificado.value" [label]="certificado.label">
      </option>
    </select>
  </div>
  <div class="col-lg-6 col-md-12 col-sm-12">
    <app-consultar-bovino [bovino]="formConsulta.bovino" ></app-consultar-bovino>
  </div>

</div>