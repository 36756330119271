<div class="card" style="margin-top: 1em;">
  <div class="card-body">

    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <a class="nav-link active" id="perfil-tab" data-toggle="tab" href="#perfil" role="tab" aria-controls="perfil"
          aria-selected="true">
          Perfil
        </a>
      </li>
      <!-- <li class="nav-item" role="presentation" *ngIf="perfilModel.GrupoUsuario.Id == associadoId">
        <a class="nav-link" id="procuradores-tab" data-toggle="tab" href="#procuradores" role="tab"
          aria-controls="procuradores" aria-selected="false">
          Procuradores
        </a>
      </li> -->
    </ul>

    <div class="tab-content border">

      <div class="tab-pane fade show active" id="perfil" role="tabpanel" aria-labelledby="perfil-tab">
        <div class="row">

          <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
            <label>Código</label>
            <input disabled name="codigo" type="text" class="form-control form-control-sm"
              [(ngModel)]="perfilModel.Usuario.codigo">
          </div>

          <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
            <label>*Nome</label>
            <input name="nome" type="text" class="form-control form-control-sm" [(ngModel)]="perfilModel.Usuario.nome"
              readonly>
          </div>

          <ng-template #elseNome>
            <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
              <label>*Nome</label>
              <input name="nome" type="text" class="form-control form-control-sm" [(ngModel)]="perfilModel.Usuario.nome"
                readonly>
            </div>
          </ng-template>

          <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12"
            *ngIf="!perfilModel.CNPJ; else elsePessoaJuridica">
            <label>*CPF</label>
            <input name="nome" type="text" class="form-control form-control-sm" readonly
              [(ngModel)]="perfilModel.CPF.cpf" mask="000.000.000-00">
          </div>

          <ng-template #elsePessoaJuridica>
            <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
              <label>*CNPJ</label>
              <input name="nome" type="text" class="form-control form-control-sm" readonly
                [(ngModel)]="perfilModel.CNPJ">
            </div>
          </ng-template>

          <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
            <label>*E-mail</label>
            <input name="email" type="text" class="form-control form-control-sm"
              [(ngModel)]="perfilModel.Usuario.email">
          </div>

        </div>

        <app-endereco-manter [endereco]="perfilModel.Usuario.endereco"></app-endereco-manter>

        <app-telefones-manter [celular]="perfilModel.Usuario.celular" [residencial]="perfilModel.Usuario.residencial"></app-telefones-manter>

        
        <app-afixo-manter [criador]="criadorModel" [readOnly]="false"></app-afixo-manter>
        

        <div *ngIf="perfilModel.GrupoUsuario.Id == associadoId">
          <h3 class="custom-titulo" *ngIf="env.contexto === 'jersey'">Dados da Fazenda Principal</h3>
          
          <div class="titulo-fazenda titulo-fazenda-wagyu" *ngIf="env.contexto === 'wagyu'">
            <h3>Dados da(as) Fazenda(s)</h3>
            <!-- REMOVER COMENTARIO SE O CRIADOR PUDER ADICIONAR MAIS FAZENDAS PARA ELE - PRECISA ADAPTAR O SERVER -->
            <!-- <a type="button" matTooltip="Adicionar mais uma fazenda">
              <i class="fas fa-plus" (click)="adicionarMaisFazenda()"></i>
            </a> -->
          </div>
          
          <div *ngFor="let fazenda of perfilModel.Fazendas; let i = index">
            <app-fazenda-manter 
            [readOnly]="false"
            [index_titulo]="i" 
            [fazenda]="fazenda" 
            [length]="perfilModel.Fazendas.length" 
            (index_remover)="removerFazenda($event)"
            >
          </app-fazenda-manter>
        </div>
        
      </div>

        <div class="row justify-content-end">
          <div class="col-lg-2 col-md-4 col-sm-6">
            <button type="button" class="btn btn-sm btn-block btn-primary" (click)="botaoAlterar()">Alterar</button>
          </div>
        </div>

      </div>

      <!-- <div class="tab-pane fade " id="procuradores" role="tabpanel" aria-labelledby="procuradores-tab">
        <app-procurador [idCriador]="perfilModel.Usuario.usuario"></app-procurador>
      </div> -->

    </div>
  </div>
</div>