import { OcorrenciaEventoAdministrativoModel } from "./OcorrenciaEventoAdministrativoModel";
import { VeterinarioModel } from "./VeterinarioModel";

export class OcorrenciaEventoApropriacaoVeterinarioModel extends OcorrenciaEventoAdministrativoModel {
  Veterinario: VeterinarioModel;
  PeriodoVigencia: { De: string; Ate: string; };

  constructor() {
    super()
    
    this.PeriodoVigencia = {
      De: '',
      Ate: '',
    };

    this.Veterinario = new VeterinarioModel();
  }
}