<div class="row">
  <button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <i class="bi bi-x" color="primary"></i>
  </button>
</div>
<h2 mat-dialog-title>Selecionar animais</h2>

<div class="row">
  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
    <div class="input-group input-group-sm">
      <input type="text" class="form-control" placeholder="Buscar animal" style="margin-bottom: 15px;" [(ngModel)]="animalBusca" (ngModelChange)="buscarAnimal()">
    </div>
  </div>
  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
    <button class="btn btn-primary btn-sm" type="button" matTooltip="Selecionar animais" (click)="selecionarAnimais()" style="float: right; padding: 5px;">Selecionar Animais</button>
  </div>
</div>
  <div class="row">
    <div class="col-lg-12">
      <table class="table table-hover table-header-custom">
        <thead>
          <tr>
            <th width="5%"></th>
            <th>Código Animal</th>
            <th>Nome Animal</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let animal of animais"
            >
            <td class="text-center">
              <input type="checkbox" class="flat"
              [(ngModel)]="animal.Selecionado" (change)="handleSelecionadoChange(animal)">
            </td>
            <td>{{animal.CodigoBovino}}</td>
            <td>{{animal.NomeBovino}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>