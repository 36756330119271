export class ExposicaoModel {
	uf: number;
	nome: string;
    email: string;
    municipio: string;
    juradoPista: string;
    responsavel: string;
	dataExposicao: string;
    fimInscricoes: string;
    juradoAdmissao: string;
    inicioInscricoes: string;

	constructor() {
		this.uf = 0;
		this.nome = "";
		this.email = "";
		this.municipio = "";
		this.juradoPista = "";
		this.responsavel = "";
		this.juradoPista = "";
		this.fimInscricoes = "";
		this.dataExposicao = "";
		this.juradoAdmissao = "";
		this.inicioInscricoes = "";
    }
}
