import { Injectable } from '@angular/core';
import { IdentificacaoCriadorModel } from 'src/app/models/IdentificacaoCriadorModel';
import { PosicaoAfixo, TipoGrauSangue, TipoRegistroReservado } from 'src/app/shared/enums/Enums';
import { IItemListaRacasBovinos } from 'src/app/shared/interfaces/Interfaces';
import { environment } from 'src/environments/environment';

interface IValueLabel<V, L> {
  value: V;
  label: L;
}

@Injectable({
  providedIn: 'root',
})
export class BovinoService {
  private readonly env = environment;

  constructor() { }

  private recuperarItemRaca(racas: IItemListaRacasBovinos[] | null, nome: string): IItemListaRacasBovinos {
    if (!racas) return {} as IItemListaRacasBovinos;
    return racas?.filter((item: IItemListaRacasBovinos) => item.label === nome)[0];
  }

  getListaRacas(controle: string[], selecione: boolean = false, labelInicial?: string): IValueLabel<string, string>[] {
    const listaRacas: IValueLabel<string, string>[] = [];

    //if (selecione) listaRacas.push({ label: labelInicial || 'Selecione...', value: '-1' });

    const racas = (this.env as any).racas as IItemListaRacasBovinos[];
    racas?.filter(
      (item: IItemListaRacasBovinos) =>
        item.controle.some(item => controle.includes(item)) &&
        listaRacas.push({
          label: item.label,
          value: item.value,
        })
    );

    return listaRacas;
  }

  getTiposPorRaca(raca: string, selecione: boolean = false, isAdmin?: boolean): IValueLabel<number, string>[] {
    const tipoSanguineo: IValueLabel<number, string>[] = [];
    const racas = (this.env as any).racas as IItemListaRacasBovinos[];

    if (selecione) tipoSanguineo.push({ value: -1, label: 'Selecione...' });

    // Retorna todos os tipos de grau de sangue caso o usuário seja admin, exceto PA e PS
    if (isAdmin === true && (this.env as any).contexto === 'wagyu') {
      tipoSanguineo.push({ value: TipoGrauSangue.PO, label: 'PO' });
      tipoSanguineo.push({ value: TipoGrauSangue.PC, label: 'PC' });
      tipoSanguineo.push({ value: TipoGrauSangue.PA, label: 'PA' });
      tipoSanguineo.push({ value: TipoGrauSangue.CCG, label: 'CCG' });
      tipoSanguineo.push({ value: TipoGrauSangue.ZT, label: 'ZT' });
      return tipoSanguineo;
    }

    switch (raca) {
      case this.recuperarItemRaca(racas, 'Jersey')?.value:
        tipoSanguineo.push({ value: TipoGrauSangue.PO, label: 'PO' });
        tipoSanguineo.push({ value: TipoGrauSangue.PC, label: 'PC' });
        tipoSanguineo.push({ value: TipoGrauSangue.PA, label: 'PA' });
        tipoSanguineo.push({ value: TipoGrauSangue.CCG, label: 'CCG' });
        break;
      case this.recuperarItemRaca(racas, 'Wagyu Kuroge')?.value:
      case this.recuperarItemRaca(racas, 'Wagyu Akaushi')?.value:
        tipoSanguineo.push({ value: TipoGrauSangue.PO, label: 'PO' });
        tipoSanguineo.push({ value: TipoGrauSangue.PC, label: 'PC' });
        tipoSanguineo.push({ value: TipoGrauSangue.PA, label: 'PA' });
        tipoSanguineo.push({ value: TipoGrauSangue.PS, label: 'PS' });
        tipoSanguineo.push({ value: TipoGrauSangue.CCG, label: 'CCG' });
        break;
      case this.recuperarItemRaca(racas, 'Nelore')?.value:
      case this.recuperarItemRaca(racas, 'Brangus')?.value:
      case this.recuperarItemRaca(racas, 'Simental')?.value:
      case this.recuperarItemRaca(racas, 'Senepol')?.value:
      case this.recuperarItemRaca(racas, 'Hereford')?.value:
      case this.recuperarItemRaca(racas, 'Braford')?.value:
      case this.recuperarItemRaca(racas, 'Angus')?.value:
      case this.recuperarItemRaca(racas, "Blonde d'Aquitaine")?.value:
      case this.recuperarItemRaca(racas, 'Bonsmara')?.value:
      case this.recuperarItemRaca(racas, 'Brahman')?.value:
      case this.recuperarItemRaca(racas, 'Caracu')?.value:
      case this.recuperarItemRaca(racas, 'Charoles')?.value:
      case this.recuperarItemRaca(racas, 'Chianina')?.value:
      case this.recuperarItemRaca(racas, 'Galloway')?.value:
      case this.recuperarItemRaca(racas, 'GIR')?.value:
      case this.recuperarItemRaca(racas, 'Ultra Black')?.value:
      case this.recuperarItemRaca(racas, 'Shorthorn')?.value:
      case this.recuperarItemRaca(racas, 'Limousin')?.value:
      case this.recuperarItemRaca(racas, 'Montana')?.value:
      case this.recuperarItemRaca(racas, 'Montbéliard')?.value:
      case this.recuperarItemRaca(racas, 'Normando')?.value:
      case this.recuperarItemRaca(racas, 'Red Angus')?.value:
      case this.recuperarItemRaca(racas, 'Rubia Gallega')?.value:
      case this.recuperarItemRaca(racas, 'Santa Gertrudis')?.value:
      case this.recuperarItemRaca(racas, 'Aberdeen Angus')?.value:
      case this.recuperarItemRaca(racas, (this.env as any).contexto === 'wagyu' ? 'Holandês' : 'Holandêsa')?.value:
        tipoSanguineo.push({ value: TipoGrauSangue.PO, label: 'PO' });
        if ((this.env as any).contexto === 'wagyu') {
          tipoSanguineo.push({ value: TipoGrauSangue.PC, label: 'PC' });
          tipoSanguineo.push({ value: TipoGrauSangue.PA, label: 'PA' });
          tipoSanguineo.push({ value: TipoGrauSangue.CCG, label: 'CCG' });
        }
        break;
      case this.recuperarItemRaca(racas, 'GIR')?.value:
      case this.recuperarItemRaca(racas, 'Sindi')?.value:
      case this.recuperarItemRaca(racas, 'Piemontes')?.value:
      case this.recuperarItemRaca(racas, 'Pardo Suiço')?.value:
      case this.recuperarItemRaca(racas, 'Sueco Vermelho')?.value:
        tipoSanguineo.push({ value: TipoGrauSangue.PO, label: 'PO' });
        break;
      case this.recuperarItemRaca(racas, 'Girolando')?.value:
        tipoSanguineo.push({ value: TipoGrauSangue.PS, label: 'PS' });
        tipoSanguineo.push({ value: TipoGrauSangue.CCG, label: 'CCG' });
        break;
      case this.recuperarItemRaca(racas, 'Sem raça definida (SRD)')?.value:
        tipoSanguineo.push({ value: TipoGrauSangue.SRD, label: 'SRD' });
        break;
    }

    if ((this.env as any).contexto === 'wagyu' && raca !== '-1')
      tipoSanguineo.push({ value: TipoGrauSangue.ZT, label: 'ZT' });

    return tipoSanguineo;
  }

  getTiposGrauSangue(selecione: boolean = false): IValueLabel<number, string>[] {
    const tiposGrauSangue: IValueLabel<number, string>[] = [];

    if (selecione) tiposGrauSangue.push({ value: -1, label: 'Selecione...' });

    tiposGrauSangue.push({ value: TipoGrauSangue.PO, label: 'PO' });
    tiposGrauSangue.push({ value: TipoGrauSangue.PC, label: 'PC' });
    tiposGrauSangue.push({ value: TipoGrauSangue.PA, label: 'PA' });
    tiposGrauSangue.push({ value: TipoGrauSangue.PS, label: 'PS' });
    tiposGrauSangue.push({ value: TipoGrauSangue.CCG, label: 'CCG' });

    return tiposGrauSangue;
  }

  getGrauSanguePorTipo(tipo: number, selecione: boolean = false, isAdmin?: boolean): IValueLabel<string, string>[] {
    const grauSangue: IValueLabel<string, string>[] = [];
    if (selecione) grauSangue.push({ value: '-1', label: 'Selecione...' });
    /**
    if (tipo == TipoGrauSangue.SRD) {
      grauSangue.push({ value: '1/2', label: '1/2' });
      return grauSangue;
    }
    **/
    if (tipo == TipoGrauSangue.PO) {
      grauSangue.push({ value: '1/1', label: '1/1' });
      return grauSangue;
    }

    if (tipo == TipoGrauSangue.PA) {
      grauSangue.push({ value: '31/32', label: '31/32' });

      // if ((this.env as any).contexto === 'wagyu') {
      //   grauSangue.push({ value: '63/64', label: '63/64' });
      //   grauSangue.push({ value: '127/128', label: '127/128' });
      //   grauSangue.push({ value: '255/256', label: '255/256' });
      //   grauSangue.push({ value: '511/512', label: '511/512' });
      // }

      return grauSangue;
    }

    if (tipo == TipoGrauSangue.PS) {
      grauSangue.push({ value: '5/8', label: '5/8' });
      return grauSangue;
    }

    if (tipo == TipoGrauSangue.CCG) {
      const contextoJersey = (this.env as any).contexto === 'jersey';

      grauSangue.push({ value: '1/2', label: '1/2' });
      grauSangue.push({ value: '1/4', label: '1/4' });
      grauSangue.push({ value: '3/4', label: '3/4' });
      if (contextoJersey) grauSangue.push({ value: '1/8', label: '1/8' });
      grauSangue.push({ value: '3/8', label: '3/8' });
      grauSangue.push({ value: '5/8', label: '5/8' });
      grauSangue.push({ value: '7/8', label: '7/8' });
      if (contextoJersey) grauSangue.push({ value: '3/16', label: '3/16' });
      grauSangue.push({ value: '9/16', label: '9/16' });
      grauSangue.push({ value: '11/16', label: '11/16' });
      grauSangue.push({ value: '15/16', label: '15/16' });
      return grauSangue;
    }

    if (tipo == TipoGrauSangue.PC || (tipo == TipoGrauSangue.ZT && isAdmin === true)) {
      if (tipo == TipoGrauSangue.ZT && isAdmin === true) {
        grauSangue.push({ value: '1/2', label: '0' });
      }
      grauSangue.push({ value: '31/32', label: '31/32' });
      grauSangue.push({ value: '63/64', label: '63/64' });
      grauSangue.push({ value: '127/128', label: '127/128' });
      grauSangue.push({ value: '255/256', label: '255/256' });
      grauSangue.push({ value: '511/512', label: '511/512' });
      grauSangue.push({ value: '1023/1024', label: '1023/1024' });
      grauSangue.push({ value: '2047/2048', label: '2047/2048' });
      grauSangue.push({ value: '4095/4096', label: '4095/4096' });
      grauSangue.push({ value: '8191/8192', label: '8191/8192' });
      grauSangue.push({ value: '16383/16384', label: '16383/16384' });
      grauSangue.push({ value: '32767/32768', label: '32767/32768' });
      grauSangue.push({ value: '65535/65536', label: '65535/65536' });
      grauSangue.push({ value: '131071/131072', label: '131071/131072' });
      grauSangue.push({ value: '262143/262144', label: '262143/262144' });
      grauSangue.push({ value: '524287/524288', label: '524287/524288' });
      grauSangue.push({ value: '1048575/1048576', label: '1048575/1048576' });
      grauSangue.push({ value: '2097151/2097154', label: '2097151/2097154' });
      grauSangue.push({ value: '4194303/4194304', label: '4194303/4194304' });
      grauSangue.push({ value: '8388607/8388608', label: '8388607/8388608' });
      grauSangue.push({ value: '16777215/16777216', label: '16777215/16777216' });
      grauSangue.push({ value: '33554431/33554432', label: '33554431/33554432' });
      grauSangue.push({ value: '67108863/67108864', label: '67108863/67108864' });
      return grauSangue;
    }

    if (isAdmin === false && tipo == TipoGrauSangue.ZT) {
      // Grau de sangue ZT fixo p/ criadores
      grauSangue.push({ value: '1/2', label: '0' });
      return grauSangue;
    }
    if (tipo == TipoGrauSangue._12B) {
      grauSangue.push({ value: '1/2', label: '1/2' });
      return grauSangue;
    }
    if (tipo == TipoGrauSangue._14B) {
      grauSangue.push({ value: '3/4', label: '3/4' });
      return grauSangue;
    }
    if (tipo == TipoGrauSangue._16B) {
      grauSangue.push({ value: '15/16', label: '15/16' });
      return grauSangue;
    }
    if (tipo == TipoGrauSangue._18B) {
      grauSangue.push({ value: '7/8', label: '7/8' });
      return grauSangue;
    }
    if (tipo == TipoGrauSangue._34B) {
      grauSangue.push({ value: '1/4', label: '1/4' });
      return grauSangue;
    }
    if (tipo == TipoGrauSangue._38A) {
      grauSangue.push({ value: '5/8', label: '5/8' });
      return grauSangue;
    }
    if (tipo == TipoGrauSangue._38B) {
      grauSangue.push({ value: '9/16', label: '9/16' });
      return grauSangue;
    }
    if (tipo == TipoGrauSangue._38P) {
      grauSangue.push({ value: '5/8', label: '5/8' });
      return grauSangue;
    }
    if (tipo == TipoGrauSangue.PC1) {
      grauSangue.push({ value: '63/64', label: '63/64' });
      return grauSangue;
    }
    if (tipo == TipoGrauSangue.PC2) {
      grauSangue.push({ value: '127/128', label: '127/128' });
      return grauSangue;
    }
    if (tipo == TipoGrauSangue.PC3) {
      grauSangue.push({ value: '255/256', label: '255/256' });
      return grauSangue;
    }
    if (tipo == TipoGrauSangue.PC4) {
      grauSangue.push({ value: '511/512', label: '511/512' });
      return grauSangue;
    }

    return [];
  }

  getLabelTipoRegistroReservado(tipo: TipoRegistroReservado): string {
    switch (tipo) {
      case TipoRegistroReservado.POFemea:
        return 'PO Fêmea';
      case TipoRegistroReservado.POMacho:
        return 'PO Macho';
      case TipoRegistroReservado.PONacionalizado:
        return 'PO Nacionalizado';
      case TipoRegistroReservado.PC:
        return 'PC';
      case TipoRegistroReservado.PA:
        return 'PA';
      case TipoRegistroReservado.CCG:
        return 'CCG (G)';
      case TipoRegistroReservado.CCG2:
        return 'CCG (Y)';
      default:
        return '';
    }
  }

  getTiposRegistrosReservados(labelInicial?: string, inspetor?: boolean): IValueLabel<number, string>[] {
    const tiposRegistros: IValueLabel<number, string>[] = [];

    if (labelInicial) tiposRegistros.push({ label: labelInicial, value: TipoRegistroReservado.None });

    tiposRegistros.push({ label: 'PO Fêmea', value: TipoRegistroReservado.POFemea });
    tiposRegistros.push({ label: 'PO Macho', value: TipoRegistroReservado.POMacho });
    if (!inspetor) tiposRegistros.push({ label: 'PO Nacionalizaco', value: TipoRegistroReservado.PONacionalizado });
    tiposRegistros.push({ label: 'PC', value: TipoRegistroReservado.PC });
    tiposRegistros.push({ label: 'PA', value: TipoRegistroReservado.PA });
    if (!inspetor) tiposRegistros.push({ label: 'CCG (G)', value: TipoRegistroReservado.CCG });
    tiposRegistros.push({ label: 'CCG (Y)', value: TipoRegistroReservado.CCG2 });

    return tiposRegistros;
  }

  removerOrelhaEsquerda(nomeAtual: string, orelha: string, contextoWagyu: boolean = true): string {
    if (!contextoWagyu || nomeAtual === '') return nomeAtual;

    return orelha ? nomeAtual.replace(` ${orelha}`, '') : nomeAtual.trim();
  }

  getNomeBovino(
    nomeAtual: string,
    orelha: string,
    afixos: IdentificacaoCriadorModel[],
    contextoWagyu: boolean = false,
    selecionarAfixo: (afixos: IdentificacaoCriadorModel[]) => IdentificacaoCriadorModel
  ): string {
    if (!contextoWagyu || nomeAtual === '') return nomeAtual;

    if (!afixos || afixos.length === 0) {
      return `${nomeAtual} ${orelha}`;
    }

    const nomePossuiAfixo = afixos.filter((item: IdentificacaoCriadorModel) => nomeAtual.includes(item.codigo));

    nomeAtual = this.removerOrelhaEsquerda(nomeAtual, orelha, contextoWagyu);

    if (nomePossuiAfixo.length === 0) {
      const afixo = selecionarAfixo(afixos);

      nomeAtual = `${nomeAtual} ${orelha}`;
      nomeAtual =
        afixo.posicaoAfixo === PosicaoAfixo.Prefixo ? `${afixo.codigo} ${nomeAtual}` : `${nomeAtual} ${afixo.codigo}`;
    } else if (nomePossuiAfixo[0].posicaoAfixo === PosicaoAfixo.Prefixo) {
      nomeAtual = `${nomeAtual} ${orelha}`;
    } else {
      const index = nomeAtual.indexOf(nomePossuiAfixo[0].codigo);
      if (index != -1) nomeAtual = `${nomeAtual.substring(0, index - 1)} ${orelha} ${nomePossuiAfixo[0].codigo}`;
    }

    return nomeAtual;
  }

  getLabelTipoGrauSangue(tipo: TipoGrauSangue): string {
    switch (tipo) {
      case TipoGrauSangue.PA:
        return 'PA';
      case TipoGrauSangue.PO:
        return 'PO';
      case TipoGrauSangue.PC:
        return 'PC';
      case TipoGrauSangue.CCG:
        return 'CCG';
      case TipoGrauSangue.ZT:
        return 'ZT';
      case TipoGrauSangue.SRD:
        return 'SRD';
      case TipoGrauSangue._12B:
        return 'CCG';
      case TipoGrauSangue._14B:
        return 'CCG';
      case TipoGrauSangue._16B:
        return 'CCG';
      case TipoGrauSangue._18B:
        return 'CCG';
      case TipoGrauSangue._38P:
        return 'CCG';
      case TipoGrauSangue._34B:
        return 'CCG';
      case TipoGrauSangue._38A:
        return 'CCG';
      case TipoGrauSangue._38B:
        return 'CCG';
      case TipoGrauSangue.PC1:
        return 'PC';
      case TipoGrauSangue.PC2:
        return 'PC';
      case TipoGrauSangue.PC3:
        return 'PC';
      case TipoGrauSangue.PC4:
        return 'PC';
      case TipoGrauSangue.PA1:
        return 'PA';
      case TipoGrauSangue.PA2:
        return 'PA';
      case TipoGrauSangue.PA3:
        return 'PA';
      case TipoGrauSangue.PA4:
        return 'PA';
      default:
        return '';
    }
  }
}
