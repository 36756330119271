    <div class="row card-title">
      <div class="col-12">
        <div class="swal2-icon swal2-question swal2-icon-show" style="display: flex;"><div class="swal2-icon-content">?</div></div>
        <h2 class="text-center">
          Confirmação
        </h2>
        <h4 style="margin-bottom: 0px;">{{confirmacao.mensagem}}</h4>
      </div>
    </div>

    <div class="row">
      <div *ngIf="exibirData"  class="form-group col-12">
        <label for="dataComunicacao">*Data Comunicação</label>
        <div class="input-group input-group-sm">
          <input matInput  class="form-control" [max]="maxDate" [matDatepicker]="dataComunicacao" name="dataComunicacao" [(ngModel)]="confirmacao.dataComunicacao" [disabled]="isDataReadOnly()">
          <div *ngIf="!isDataReadOnly()" class="input-group-append">
            <button class="btn btn-outline-secondary" type="button" (click)="dataComunicacao.open()"> <i class="bi bi-calendar3"></i> </button>
          </div>
        </div>
        <mat-datepicker [startAt]="confirmacao.dataComunicacao" #dataComunicacao></mat-datepicker>
      </div>

      <div class="form-group col-12">
        <label for="observacao">Observação</label>
        <textarea class="form-control form-control-sm" name="observacao" [(ngModel)]="confirmacao.observacao" rows="3"></textarea>
      </div>

      <div class="col-12 text-center">

        <button class="swal2-confirm swal2-styled" type="button" (click)="confirmar()">
          Sim
        </button>
        
        <button class="swal2-styled swal2-cancel" type="button" (click)="naoConfirmar()">
          Não
        </button>
    </div>


