import { QualificaLaudoDNA, Sexo, TipoBuscaBovino } from "../shared/enums/Enums";
import { BovinoModel } from "./BovinoModel";
import { CriadorModel } from "./CriadorModel";

export class FiltroConsultaLaudoDnaModel {
  NumeroLaudo: string;
  Qualifica: QualificaLaudoDNA;
  Sexo: Sexo;
  Pai: BovinoModel;
  Mae: BovinoModel;
  CodigoAnimal: string | null;
  NomeAnimal: string | null;
  Criador: CriadorModel;

  constructor() {
    this.NumeroLaudo = '';
    this.CodigoAnimal = null;
    this.NomeAnimal = null;
    this.Qualifica = QualificaLaudoDNA.None;
    this.Sexo = Sexo.Todos;

    this.Pai = new BovinoModel();
    this.Pai.ConsultaAnimalTodosCriadores = true;
    this.Pai.TituloConsulta = 'Pai';
    this.Pai.TipoBusca = TipoBuscaBovino.Macho;

    this.Mae = new BovinoModel();
    this.Mae.ConsultaAnimalTodosCriadores = true;
    this.Mae.TituloConsulta = 'Mãe';
    this.Mae.TipoBusca = TipoBuscaBovino.Fêmea;

    this.Criador = new CriadorModel();
  }
}