import { AccountService } from './../../account/shared/account.service';
import { Component, OnInit } from '@angular/core';
import { Commons } from 'src/app/shared/commons';
import { ApiService } from 'src/app/shared/api.service';
import { SubmetidosElement } from 'src/app/shared/interfaces/Interfaces';
import { ConsultaSubmetidosModel } from 'src/app/models/ConsultaSubmetidosModel';
import { OcorrenciasPorSituacaoModalComponent } from '../ocorrencias-por-situacao-modal/ocorrencias-por-situacao-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { EnumTipoEventoSRG, SituacaoOcorrenciaEvento, TipoPeriodoFixoConsulta } from 'src/app/shared/enums/Enums';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-submetidos',
  templateUrl: './submetidos.component.html',
  styleUrls: ['./submetidos.component.scss']
})
export class SubmetidosComponent implements OnInit {

  readonly env = environment;

  dataSourceSubmetidos: SubmetidosElement[] = [];
  formConsulta: ConsultaSubmetidosModel = new ConsultaSubmetidosModel();

  constructor(
    private accountService: AccountService,
    private commons: Commons,
    private api: ApiService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    let token: any = this.accountService.getPayloadToken();

    if (token) {
      this.formConsulta.filiadaId = token.DomainId;
      this.formConsulta.filiadaReadonly = (this.env as any).contexto === 'wagyu' || !this.accountService.isSuperAdmin;

      this.formConsulta.criador.id = token.CriadorId;
      this.formConsulta.criador.codigo = token.CodigoCriador;
      this.formConsulta.criador.nome = token.NomeCriador;
      this.formConsulta.criador.readonly = this.accountService.isCriador;

      this.consultar();
    }
  }

  consultar() {
    this.api.consultarPainelGerencial(this.formConsulta).then(result => {
      if (result) {


        const data: SubmetidosElement[] = [];
        result.model.Itens.forEach((element: any) => {
          const nomeIcone = this.commons.getNomeIcone(element.Tipo);
          if ((element.Tipo == 56 && this.accountService.isCriador)) {
            data.push(
              {
                icon: nomeIcone.icone,
                tipoIcon: nomeIcone.tipo,
                tipo: nomeIcone.nome,
                todas: 0,
                pendente: 0,
                cancelado: 0,
                aprovado: element.AprovadoCont,
                aprovadoComPendencias: 0,
                rejeitado: 0,
                aguardandoAprovacao: 0,
                aguardandoAprovacaoVeterinario: 0,
                aguardandoResolucao: 0,
                aprovadoPeloSuperintendente: 0,
                rejeitadoPeloSuperintendente: 0,
                rejeitadoPeloVeterinario: 0,
              });
          } else {
            data.push(
              {
                icon: nomeIcone.icone,
                tipoIcon: nomeIcone.tipo,
                tipo: nomeIcone.nome,
                todas: element.TodosCont,
                pendente: element.PendenteCont,
                cancelado: element.CanceladoCont,
                aprovado: element.AprovadoCont,
                aprovadoComPendencias: element.AprovadoComPendenciasCont,
                rejeitado: element.RejeitadoCont,
                aguardandoAprovacao: element.AguardandoAprovacaoCont,
                aguardandoAprovacaoVeterinario: element.AguardandoAprovacaoVeterinario,
                aguardandoResolucao: element.AguardandoResolucaoCont,
                aprovadoPeloSuperintendente: element.AprovadoPeloSuperintendenteCont,
                rejeitadoPeloSuperintendente: element.RejeitadoPeloSuperintendenteCont,
                rejeitadoPeloVeterinario: element.RejeitadoPeloVeterinarioCont,
              });
          }

        });

        this.dataSourceSubmetidos = data;
      }
    })
  }

  private convertToMillis = (day: number): number => ((60 * 60 * 24 * 1000) * day);

  periodoAlterado(): void {
    if (this.formConsulta.periodo == TipoPeriodoFixoConsulta.PERIODO_ESPECIFICO) {
      const date = new Date();
      this.formConsulta.dataInicial = new Date(new Date(date.getFullYear(), date.getMonth(), 1).getTime()).toISOString();
      this.formConsulta.dataFinal = '';
      return;
    }

    const now = new Date();
    now.setHours(0, 0, 0, 0);

    if (this.formConsulta.periodo == TipoPeriodoFixoConsulta.ESTE_MES) {
      this.formConsulta.dataInicial = new Date(now.getFullYear(), now.getMonth(), 1).toISOString();
      this.formConsulta.dataFinal = new Date(now.getFullYear(), now.getMonth() + 1, 0).toISOString();
      return;
    }

    if (this.formConsulta.periodo == TipoPeriodoFixoConsulta.ESTA_SEMANA) {
      const firstday = new Date(now.getTime() - this.convertToMillis(now.getDay()));
      const lastday = new Date(firstday.getTime() + this.convertToMillis(6));
      this.formConsulta.dataInicial = firstday.toISOString();
      this.formConsulta.dataFinal = lastday.toISOString();
      return;
    }

    // voltar para a opcao 'todos'
    this.formConsulta.dataFinal = '';
    this.formConsulta.dataInicial = '';
  }

  filiadaSelecionada(idFiliada: string) {
    this.formConsulta.filiadaId = idFiliada;
  }

  itemSelecionado(evento: SubmetidosElement, quantidade: number, situacao: string) {
    if (quantidade === 0) return;

    const eventoInt = ((): number => {
      switch (evento.tipo) {
        case 'Padreação': case 'Cobertura': return EnumTipoEventoSRG.Padreacao;
        case 'Nascimento': return EnumTipoEventoSRG.Nascimento;
        case 'FIV': return EnumTipoEventoSRG.FIV;
        case 'Inovulação': case 'Implantação': return EnumTipoEventoSRG.Inovulacao;
        case 'Baixa': return EnumTipoEventoSRG.Baixa;
        case 'Transferência': return EnumTipoEventoSRG.Transferencia;
        case 'Inspeção': return EnumTipoEventoSRG.Inspecao;
        case 'Premiação': return EnumTipoEventoSRG.Premiacao;
        case 'Nacionalização': return EnumTipoEventoSRG.Nacionalizacao;
        case 'Nacionalização de Embrião': return EnumTipoEventoSRG.NacionalizacaoEmbriao;
        case 'Classificação Linear': return EnumTipoEventoSRG.ClassificacaoLinear;
        case 'Pesagem': return EnumTipoEventoSRG.Pesagem;
        case 'Transferência': return EnumTipoEventoSRG.Transferencia;
        case 'Abate com Avaliação de Carcaça': return EnumTipoEventoSRG.Carcaca;
      }
      return 0;
    })();

    const situacaoInt = ((): number => {
      switch (situacao) {
        case 'Todas': return SituacaoOcorrenciaEvento.Todos;
        case 'Pendente': return SituacaoOcorrenciaEvento.Pendente;
        case 'Cancelado': return SituacaoOcorrenciaEvento.Cancelado;
        case 'Aprovado': return SituacaoOcorrenciaEvento.Aprovado;
        case 'Aprovado Com Pendências': return SituacaoOcorrenciaEvento.AprovadoComPendencias;
        case 'Rejeitado': return SituacaoOcorrenciaEvento.Rejeitado;
        case 'Rejeitado Pelo Veterinário': return SituacaoOcorrenciaEvento.RejeitadoPeloVeterinario;
        case 'Aguardando Aprovação': return SituacaoOcorrenciaEvento.AguardandoAprovacao;
        case 'Aguardando Aprovação Veterinário': return SituacaoOcorrenciaEvento.AguardandoAprovacaoVeterinario;
        case 'Aguardando Resolução': return SituacaoOcorrenciaEvento.AguardandoResolucao;
        case 'Aprovado Pelo Superintendente': return SituacaoOcorrenciaEvento.AprovadoSuperintendente;
        case 'Rejeitado Pelo Superintendente': return SituacaoOcorrenciaEvento.RejeitadoSuperintendente;
      }
      return 0;
    })();

    this.dialog.open(
      OcorrenciasPorSituacaoModalComponent,
      {
        height: '90%',
        width: '90%',
        autoFocus: false,
        data: {
          ...this.formConsulta,
          EventoInt: eventoInt,
          SituacaoInt: situacaoInt,
        }
      }
    );
  }

  exibirColuna(tipo: string) {
    let exibir = false;

    if ((tipo === 'Todas' || tipo === 'Pendente' || tipo === 'Cancelado' || tipo === 'Aprovado' ||
      tipo === 'Aprovado com Pendências' || tipo === 'Rejeitado') &&
      (this.accountService.isAdmin || this.accountService.isCriador)) {
      exibir = true;
    }

    if ((tipo === 'Aguardando Resolução' || tipo === 'Aguardando Aprovação' ||
      tipo === 'Aprovado Pelo Superintendente' ||
      tipo === 'Rejeitado Pelo Superintendente') &&
      (this.accountService.isAdmin || this.accountService.isSuperintendente || this.accountService.isCriador || this.accountService.isVeterinario)) {
      exibir = true;
    }

    if ((tipo === 'Aguardando Aprovação Veterinário' || tipo === 'Rejeitado Pelo Veterinário') &&
      (
        this.accountService.isVeterinario ||
        this.accountService.isCriador ||
        this.accountService.isAdmin ||
        this.accountService.isSuperintendente
      )
    ) {
      exibir = true;
    }

    return exibir;
  }

}
