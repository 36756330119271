<div class="row" style="margin-top: 15px;">
  <div class="col-lg-12 table-responsive">
    <table class="table table-hover table-header-custom">
      <thead>
        <tr>
          <th class="text-center">Data</th>
          <th class="text-center">Nome</th>
          <th class="text-center">Descrição</th>
          <th width="10%"></th>
        </tr>
      </thead>
      <tbody *ngIf="documentos.length > 0">
        <tr *ngFor="let documento of documentos | paginate : {itemsPerPage: pageSize, currentPage: page, totalItems: count};">
          <td class="text-center">{{ documento.DataDocumento | date: 'dd/MM/yyyy' }}</td>
          <td class="text-center"><strong>{{ documento.Nome }}</strong></td>
          <td class="text-center">{{ documento.Descricao }}</td>
          <td class="text-center">
            <a type="button" matTooltip="Baixar documento">
              <i class="action-icons fas fa-file-alt" (click)="botaoDownloadArquivo(documento.DocumentoId, documento.Nome, documento.CertificadoId)"></i>
            </a>
            <a type="button" style="margin-left: 5px;" matTooltip="Visualizar o documento" (click)="visualizarDocumento(documento.DocumentoId, documento.CertificadoId)">
              <i class="far fa-file-alt"></i>
            </a>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="documentos.length === 0">
        <tr class="text-center">
          <td colspan="4">
            <strong>Sem itens para exibição.</strong>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="col-md-12 text-center" *ngIf="documentos.length > 0">
    <pagination-controls  
      [autoHide]="true"
      [responsive]="true"
      nextLabel="Próximo" 
      class="srg-paginacao" 
      previousLabel="Anterior" 
      (pageChange)="handlePageChange($event)" 
    >
    </pagination-controls>
  </div>
  
</div>