import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from '../shared/account.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  backgroundImage = "";
  logoImage = "";

  login = {
    codigo: '',
    usuario: '',
    senha: ''
  }

  constructor(
    private accountService: AccountService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.backgroundImage = environment.backgroundImage;
    this.logoImage = environment.logoImage;
    if(this.accountService.isSessaoExpirou()){
      Swal.fire('Sua sessão expirou.', '', 'info');
    }
  }

  async onSubmit(){
    const result = this.accountService.login(this.login);
    result.then(result => {
      if(result) {
        this.router.navigate(['']);
      }
    }).catch((_) => {
      Swal.fire('Nome de Usuário e Senha não conferem.', '', 'error');
    });
  }

}
