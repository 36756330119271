<div class="row">

    <div class="form-group col-lg-2 col-md-6 col-sm-6 col-xs-12">
        <label for="formularioProtocolo">Código</label>
        <input (keydown.enter)="consultar()" type="text" class="form-control form-control-sm" name="formularioProtocolo" [(ngModel)]="formConsulta.codigo">
    </div>

    <div class="form-group col-lg-2 col-md-6 col-sm-6 col-xs-12">
        <label for="formularioProtocolo">CPF</label>
        <input (keydown.enter)="consultar()" type="text" class="form-control form-control-sm" name="formularioProtocolo" [(ngModel)]="formConsulta.cpf" mask="000.000.000-00">
    </div>

    <div class="form-group col-lg-8 col-md-12 col-sm-12 col-xs-12">
        <label for="formularioProtocolo">Nome</label>
        <input (keydown.enter)="consultar()" type="text" class="form-control form-control-sm" name="formularioProtocolo" [(ngModel)]="formConsulta.nome">
    </div>

    <div class="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12">
        <label for="formularioProtocolo">Grupo de Usuário</label>
        <select class="form-control form-control-sm" name="situacao" [(ngModel)]="formConsulta.grupo">
          <option *ngFor="let grupo of gruposUsuario" [value]="grupo.value" [label]="grupo.label"></option>
        </select>
    </div>

    <div *ngIf="contexto == 'jersey'" class="form-group col-lg-6 col-md-8 col-sm-6 col-xs-12">
        <app-selecionar-filiadas 
            defaultFirstSelectedLabel="Selecione..."
            [filiadaId]="formConsulta.vinculacao" 
            [readonly]="formConsulta.filiadaReadonly"  
            (filiadaSelecionada)="filiadaSelecionada($event)"
        ></app-selecionar-filiadas>
    </div>

    <div class="form-group col-lg-2 col-md-4 col-sm-6 col-xs-12">
        <label for="formularioProtocolo">Situação</label>
        <select class="form-control form-control-sm" name="situacao" [(ngModel)]="formConsulta.situacao">
          <option *ngFor="let situacao of situacoes" [value]="situacao.value" [label]="situacao.label"></option>
        </select>
    </div>

</div>