import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/shared/api.service';
import { Commons } from 'src/app/shared/commons';
import { IVisualizaCertificado } from 'src/app/shared/interfaces/Interfaces';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-documentos-bovino',
  templateUrl: './documentos-bovino.component.html',
  styleUrls: ['./documentos-bovino.component.scss']
})
export class DocumentosBovinoComponent implements OnInit {

  @Input() bovinoId: string | null = null;

  page = 1;
  count = 0;
  documentos: any[] = [];
  pageSize = environment.pageSize;

  constructor(
    private router: Router,
    private api: ApiService,
    private commons: Commons
  ) { }

  ngOnInit(): void {
    this.consultar();
  }

  consultar(): void {

    if (!this.bovinoId) {
      this.router.navigate(['plantel_consultar']);
      return;
    }

    this.api.consultarDocumentosBovino(this.bovinoId, this.page, this.pageSize).then((result: any) => {
      if (result) {
        if (result.success) {

          result.Itens.forEach((certificado: any) => {
            if (certificado.CertificadoId != null && certificado.CertificadoId != -1 && certificado.CertificadoId != "") {
              this.documentos.push(certificado)
              this.count += 1;
            }
          });
        }
      }
    });

  }

  botaoDownloadArquivo(id: string, filename: string, certificadoId: string): void {

    if (!id) return;

    this.api.downloadDocumentoBovino(id, certificadoId).then((result: any) => {
      if (!result) {
        Swal.fire('Não foi possível realizar a operação.', '', 'error');
        return;
      }

      const blob: Blob = this.commons.base64toBlob(result);
      const blobURL: string = URL.createObjectURL(blob);

      const anchor: HTMLAnchorElement = document.createElement('a');
      anchor.href = blobURL;
      anchor.download = filename;
      anchor.click();
      anchor.remove();
    });
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.consultar();
  }

  visualizarDocumento(id: string, certificadoId: string): void {
    this.api.visualizarDocumento(id, certificadoId).then((result: IVisualizaCertificado) => {
      if (result && result.Data.success) {
        this.commons.visualizarBase64EmPdf(result.Data.content || '', result.ContentType);
      } else {
        Swal.fire(result.Data.message, '', 'error');
      }
    });
  }
}
