import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { ApiService } from '../shared/api.service';
import { PerfilModel } from '../models/PerfilModel';
import { ProcuradorModel } from '../models/ProcuradorModel';
import Swal from 'sweetalert2';
import { Commons } from '../shared/commons';
import { AccountService } from '../account/shared/account.service';
import { RolesKeys } from '../account/shared/RolesKeys';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-procurador',
  templateUrl: './procurador.component.html',
  styleUrls: ['./procurador.component.scss']
})
export class ProcuradorComponent implements OnInit {

  readonly contexto = (environment as any).contexto;

  @Input() idCriador: string = "";
  @Input() readOnly: boolean = true;
  procuradorModel: ProcuradorModel = new ProcuradorModel();
  permiteEditar: boolean = false;

  constructor(
    public commons: Commons,
    private apiService: ApiService,
    public account: AccountService,
  ) { }

  ngOnInit(): void {
    this.permiteEditar = this.account.checkRole(RolesKeys.Admin) || this.account.checkRole(RolesKeys.Superintendente)
  }

  ngOnChanges(changes: SimpleChanges) {
    const token: any = this.account.getPayloadToken();
    if (this.contexto === 'wagyu' && token && this.account.isCriador && this.idCriador === token.UserId) {
      this.readOnly = false;
    }

    this.recuperarProcuradores(changes.idCriador.currentValue);
  }

  recuperarProcuradores(id: string) {
    this.apiService.consultarProcuradores(id, null).then(response => {
      if (response) {
        const data: PerfilModel[] = [];
        response.Procuradores.forEach((element: any) => {
          data.push(
            {
              CPF: {
                cpf: element.CPF,
                grupo: "",
                vinculacao: "",
              },
              GrupoUsuario: element.GrupoUsuario,
              Usuario: {
                criadorId: element.CriadorId,
                usuarioId: element.UsuarioId,
                nome: element.Nome,
                codigo: element.Codigo,
                situacao: element.Situacao,
                CRMV_CodInspetor: "",
                celular: {
                  ddd: element.Celular.DDD,
                  numero: element.Celular.Numero,
                  ramal: element.Celular.Ramal,
                  tipo: element.Celular.Tipo,
                },
                email: element.Email.Email,
                residencial: element.Telefone,
                endereco: element.Endereco,
                usuario: element.Id,
                grupo: '',
                tipoInspetor: -1,
                codigoEspecializacaoInspetor: '',

              },
              Login: element.Login,
              Afixo: element.Afixo,
              CNPJ: element.CNPJ,
              CodigoAcesso: element.CodigoAcesso,
              Fazenda: element.Fazenda,
              Fazendas: [],
              FiliadaId: element.FiliadaId,
              IdFazenda: element.Fazenda.Id,
              IdentificacaoFazenda: element.IdentificacaoFazenda,
              TipoEmail: element.TipoEmail,
              TipoUsuario: element.TipoUsuario,
              ReadOnly: element.ReadOnly,
            });
        });
        this.procuradorModel.Procuradores = data;
        this.procuradorModel.GrupoUsuario = response.GrupoUsuario;
      };
    });

  }

  recuperaProcuradorDaLista(procuradorEscolhido: any) {
    Object.assign(this.procuradorModel.Usuario, procuradorEscolhido.Usuario);
    this.procuradorModel.CPF = procuradorEscolhido.CPF;
    this.procuradorModel.Login = procuradorEscolhido.Login;
    this.procuradorModel.ReadOnly = procuradorEscolhido.ReadOnly;
  }

  alterarProcurador() {
    this.apiService.alterarProcurador(this.procuradorModel, this.idCriador).then(result => {
      if (result) {
        if (result.success === true) {
          Swal.fire(result.message, "", "success");
          this.limparCampos();
          this.recuperarProcuradores(this.idCriador);
        } else {
          Swal.fire(result.message || "Ocorreu um erro desconhecido.", "", "error");
        }
      }
    });
  }

  async botaReiniciarSenha(idUsuario: string): Promise<void> {
    const response = await this.commons.modalConfirmacao({
      title: 'Confirma a reinicialização da senha do Usuário?'
    });

    if (!response) return;

    this.apiService.reiniciarSenha(idUsuario).then(result => {
      if (result) {
        if (result.success === true) {
          Swal.fire(result.message, "", "success");
        } else {
          Swal.fire(result.message, "", "error");
        }
      }
    });
  }

  limparCampos() {
    this.procuradorModel = new ProcuradorModel();
  }

}
