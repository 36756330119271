import { TipoGrauSangue } from "../shared/enums/Enums";

export class SangueBovinoModel {
  tipo: TipoGrauSangue;
  grau: string;

  constructor() {
    this.tipo = -1;
    this.grau = '-1';
  }
}