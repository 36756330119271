import { Injectable } from '@angular/core';
import { FiltroConsultaEventosModel } from '../models/FiltroConsultaEventosModel';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  private storage: Storage;
  constructor() {
    this.storage = window.localStorage;
   }

  //salva dentro do localStorage um valor e forma de JSON, NÃO SALVAR INFORMAÇÕES IMPORTANTES
  set(key: string, value: any) {
    if(value != null && value != ""){
      let data = JSON.stringify(value);
      //codifica os dados para uma base64, para não ficar disponível visualmente ao usuario
      this.storage.setItem(key, btoa(data));
    } 
  }

  //retorna o valor armazenado no localStorage a partir da chave
  get(key: string): any {
    if(this.storage.getItem(key)){
      let data = this.storage.getItem(key) || btoa("{}");
      //faz o processo de decodificação
      let decodeData = JSON.parse(atob(data));
      return decodeData;
    }
    else{
      return "";
    }
  }

  //remove uma chave do localStorage junto com seu conteúdo
  remove(key: string) {
    this.storage.removeItem(key);
  }
}
