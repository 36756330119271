<div class="card">
  <div class="card-body">

    <mat-tab-group [selectedIndex]="tabSelected.value" (selectedIndexChange)="tabChanged($event)" style="margin-bottom: 20px;" color="accent">
      <mat-tab>
        <ng-template mat-tab-label>
          <i class="action-icons fa fa-search"></i>
          Consultar
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <i class="action-icons fa fa-save"></i>
          Registrar
        </ng-template>
      </mat-tab>    
    </mat-tab-group>

    <div class="row">
      <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
        <label>Vigência De</label>
        <div class="input-group input-group-sm">
          <input [disabled]="readOnly" class="form-control" name="periodo_vigencia_de" [matDatepicker]="dataInicioVigencia" [(ngModel)]="formulario.PeriodoVigencia.De"> 
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button" (click)="dataInicioVigencia.open()"> <i class="bi bi-calendar3"></i> </button>
          </div>
        </div>
        <mat-datepicker #dataInicioVigencia></mat-datepicker>
      </div>

      <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
        <label>Vigência Até</label>
        <div class="input-group input-group-sm">
          <input [disabled]="readOnly" class="form-control" name="periodo_vigencia_ate" [matDatepicker]="dataFinalVigencia" [(ngModel)]="formulario.PeriodoVigencia.Ate"> 
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button" (click)="dataFinalVigencia.open()"> <i class="bi bi-calendar3"></i> </button>
          </div>
        </div>
        <mat-datepicker #dataFinalVigencia></mat-datepicker>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <app-consultar-criador [criador]="formulario.Criador"></app-consultar-criador>
      </div>
      <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <app-consultar-veterinario [veterinario]="formulario.Veterinario"></app-consultar-veterinario>
      </div>
    </div>

    <div class="row justify-content-end" *ngIf="!readOnly">
      <div class="col-lg-3 col-md-6 col-sm-12">
        <button type="button" class="btn btn-sm btn-block btn-primary" (click)="botaoReset()">Limpar</button>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12">
        <button type="button" class="btn btn-sm btn-block btn-primary" (click)="isEdicao() ? botaoSalvar() : botaoConsultar()">
          {{ isEdicao()? 'Salvar' : 'Buscar' }}
        </button>
      </div>
    </div>

  </div>
</div>

<div *ngIf="!isEdicao()" class="card" style="margin-top: 20px;">
  <div class="card-body">
    
    <div class="col-lg-12 table-responsive">
      <table class="table table-hover table-header-custom">
        <thead>
          <tr>
            <th>Data</th>
            <th>Criador</th>
            <th>Veterinário</th>
            <th>Início Vigência</th>
            <th>Fim Vigência</th>
            <th class="text-center">Situação</th>
            <th width="10%"></th> 
          </tr>
        </thead>
        <tbody *ngIf="ocorrencias.length > 0">
          <tr *ngFor="let ocorrencia of ocorrencias | paginate : { itemsPerPage: pageSize, currentPage: page, totalItems: count };">
            <td> {{ ocorrencia.DataOcorrencia | date: 'dd/MM/yyyy' }} </td>
            <td> {{ ocorrencia.Criador.CodigoCriador + ' - ' + ocorrencia.Criador.NomeCriador }} </td>
            <td> {{ ocorrencia.Inspetor.Codigo + ' - ' + ocorrencia.Inspetor.NomeInspetor }} </td>
            <td> {{ ocorrencia.InicioVigencia | date: 'dd/MM/yyyy' }} </td>
            <td> {{ ocorrencia.FimVigencia | date: 'dd/MM/yyyy' }} </td>
            <td class="text-center"> {{ getDescrSituacao(ocorrencia.Situacao) }} </td>
            <td width="10%" class="text-center">
              <a *ngIf="ocorrencia.Situacao == 1" type="button" matTooltip="Inativar">
                <i class="action-icons fas fa-lock" (click)="inativarOcorrencia(ocorrencia.Id)"></i>
              </a>
              <a *ngIf="permiteEdicao" type="button" matTooltip="Editar">
                <i class="action-icons fas fa-edit" (click)="editar(ocorrencia)"></i>
              </a>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="ocorrencias.length === 0">
          <tr class="text-center">
            <td colspan="7">
              <strong>Sem itens para exibição.</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="col-md-12 text-center">
      <pagination-controls 
        class="srg-paginacao"
        previousLabel="Anterior" 
        nextLabel="Próximo" 
        [responsive]="true" 
        (pageChange)="handlePageChange($event)"
        [autoHide]="true"
      >
      </pagination-controls>
    </div>
    
  </div>
</div>