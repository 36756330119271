import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import Swal from 'sweetalert2';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ApiService } from '../api.service';
import { InspetorModel } from 'src/app/models/InspetorModel';
import { UsuarioProcuradorModel } from 'src/app/models/UsuarioProcuradorModel';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-consultar-procurador-modal',
  templateUrl: './consultar-procurador-modal.component.html',
  styleUrls: ['./consultar-procurador-modal.component.scss']
})
export class ConsultarProcuradorModalComponent implements OnInit {

  procuradores: any[] = [];
  formConsulta: UsuarioProcuradorModel = new UsuarioProcuradorModel();
  @Output() procuradorSelecionado = new EventEmitter<any>();
  pageSize = environment.pageSize;
  page = 1;
  count = 0;

  constructor(
    private api: ApiService,
    public dialogRef: MatDialogRef<ConsultarProcuradorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {}

  botaoBuscar(){
    this.api.consultarUsuarioProcuradores(this.formConsulta, this.page, this.pageSize).then((result: any) => {
      if (result) { 
        if(result.success){
          this.procuradores = result.Itens;
          this.count = result.Itens.length;
        } else {
          this.procuradores = [];
          Swal.fire(result.message, '', 'error');
        }
      }
    });
  }

  selecionarProcurador(inspetor: any) {
    this.procuradorSelecionado.emit(inspetor);
    this.dialogRef.close();
  }

  fechar() {
    this.dialogRef.close();
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.botaoBuscar();
  }
}
