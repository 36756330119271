<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <i class="bi bi-x" color="primary"></i>
</button>

<h2 mat-dialog-title>Histórico de Comunicação</h2>

<div class="row" *ngIf="listaComunicacao.length > 0">
  <div class="col-lg-12 table-responsive">
    <table class="table table-hover table-header-custom">
      <thead>
        <tr>
          <th>Data</th>
          <th>Situação</th> 
          <th>Responsável</th>
          <th>Mensagem</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of listaComunicacao" >
          <td>{{ item.DataOcorrencia | date: 'dd/MM/yyyy HH:mm:ss' }}</td>
          <td>{{ item.DescSituacaoLote }}</td>
          <td>{{ item.NomeResponsavel }}</td>
          <td>{{ item.Mensagem }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>