import { ConstantesEventos } from 'src/app/shared/constantes';
import { SituacaoOcorrenciaEvento, SituacaoLoteOcorrencia } from './../../../shared/enums/Enums';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AccountService } from 'src/app/account/shared/account.service';
import { FormularioModel } from 'src/app/models/FormularioModel';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-botoes-acoes-ocorrencia',
  templateUrl: './botoes-acoes-ocorrencia.component.html',
  styleUrls: ['./botoes-acoes-ocorrencia.component.scss'],
})
export class BotoesAcoesOcorrenciaComponent implements OnInit {
  private readonly contexto: string = (environment as any).contexto;

  @Input() formulario: FormularioModel = new FormularioModel();
  @Input() lote: any = {};
  @Input() idOcorrencia: string = '';
  @Input() tipoEvento: string = '';
  @Output() acaoSelecionada = new EventEmitter<string>();

  @Input() temporarioEdicaoEventoAprovado: boolean = false;
  @Input() temporarioEdicaoEventoAguardandoAprovacao: boolean = false;
  @Input() permiteEdicaoNome: boolean = false;
  @Input() permiteSalvarAprovado: boolean = false;

  constructor(private accountService: AccountService) { }

  ngOnInit(): void { }

  acaoBotao(acao: string) {
    this.acaoSelecionada.emit(acao);
  }

  aprovar() {
    this.acaoSelecionada.emit(ConstantesEventos.ACAO_BOTAO_APROVAR);
  }

  aprovarComPendencias() {
    this.acaoSelecionada.emit(ConstantesEventos.ACAO_BOTAO_APROVAR_COM_PENDENCIAS);
  }

  // rejeitar() {
  //   this.acaoSelecionada.emit(ConstantesEventos.ACAO_BOTAO_REJEITAR);
  // }

  reverterRejeicao() {
    this.acaoSelecionada.emit(ConstantesEventos.ACAO_BOTAO_REVERTER_REJEICAO);
  }

  novo() {
    this.acaoSelecionada.emit(ConstantesEventos.ACAO_BOTAO_NOVO);
  }

  salvar() {
    this.acaoSelecionada.emit(ConstantesEventos.ACAO_BOTAO_SALVAR);
  }

  gerarPDF() {
    this.acaoSelecionada.emit(ConstantesEventos.ACAO_BOTAO_GERAR_PDF);
  }

  gerarExcel() {
    this.acaoSelecionada.emit(ConstantesEventos.ACAO_BOTAO_GERAR_EXCEL);
  }

  submeter() {
    this.acaoSelecionada.emit(ConstantesEventos.ACAO_BOTAO_SUBMETER);
  }

  submeterEAprovar() {
    this.acaoSelecionada.emit(ConstantesEventos.ACAO_BOTAO_SUBMETER_E_APROVAR);
  }

  descartar() {
    this.acaoSelecionada.emit(ConstantesEventos.ACAO_BOTAO_DESCARTAR);
  }

  aprovarTodas() {
    this.acaoSelecionada.emit(ConstantesEventos.ACAO_BOTAO_GERAR_EXCEL);
  }

  revisar() {
    this.acaoSelecionada.emit(ConstantesEventos.ACAO_BOTAO_REVISAR);
  }

  aprovarTodos() {
    this.acaoSelecionada.emit(ConstantesEventos.ACAO_BOTAO_APROVAR_TODOS);
  }

  permiteNovo() {
    if (this.lote && this.lote.Situacao === SituacaoLoteOcorrencia.EmElaboracao && this.isNewOcorrencia() && this.tipoEvento != 'abate-com-aval-carcaca') {

      return true;
    }
    return false;
  }

  permiteSalvar() {
    if (this.temporarioEdicaoEventoAprovado) return true;
    if (this.temporarioEdicaoEventoAguardandoAprovacao) return true;
    if (this.permiteEdicaoNome) return true;
    if (this.permiteSalvarAprovado) return true;

    if (this.lote && this.lote.Situacao === SituacaoLoteOcorrencia.ProcessadoComPendencias) {
      if (
        this.lote.OcorrenciaAtual.Situacao === SituacaoOcorrenciaEvento.AprovadoComPendencias ||
        this.lote.OcorrenciaAtual.Situacao === SituacaoOcorrenciaEvento.AguardandoResolucao
      ) {
        return true;
      }
    }

    if (this.lote && (this.lote.Situacao === SituacaoLoteOcorrencia.EmElaboracao || this.isNew())) {
      return true;
    }
    console.log(this.lote)
    if (this.lote.TipoOcorrenciaEmExecucao.includes('OcorrenciaEventoInspecaoModel') && this.lote.OcorrenciaAtual.Situacao == SituacaoOcorrenciaEvento.Aprovado
      && (this.accountService.isAdmin || this.accountService.isSuperintendente)) {
      return true;
    }
    return false;
  }

  permiteAprovar() {
    if (!this.isNew()) {
      if (this.lote.IsEmDeliberacao && this.lote.OcorrenciaAtual != null) {
        if (this.contexto === 'wagyu') {
          if (
            (this.tipoEvento === 'baixa' || this.tipoEvento === 'transferencia' || this.tipoEvento === 'fiv') &&
            this.lote.OcorrenciaAtual.Situacao === SituacaoOcorrenciaEvento.AguardandoAprovacao &&
            this.accountService.isSuperintendente &&
            this.lote.OcorrenciaAtual.Situacao === SituacaoOcorrenciaEvento.AguardandoAprovacao
          ) {
            return true;
          }

          const token: any = this.accountService.getPayloadToken();

          if (
            this.lote.OcorrenciaAtual.Situacao == SituacaoOcorrenciaEvento.AguardandoAprovacaoVeterinario &&
            this.accountService.isVeterinario &&
            token &&
            token.CRMV === this.lote.OcorrenciaAtual?.Veterinario?.crmv.trim().replace('/', ' ')
          ) {
            return true;
          }
        }

        if (
          this.lote.OcorrenciaAtual.Situacao === SituacaoOcorrenciaEvento.Pendente ||
          this.lote.OcorrenciaAtual.Situacao === SituacaoOcorrenciaEvento.AprovadoComPendencias ||
          (this.accountService.isSuperintendente &&
            this.lote.OcorrenciaAtual.Situacao === SituacaoOcorrenciaEvento.AguardandoAprovacao) ||
          (this.contexto === 'wagyu' &&
            !this.accountService.isSuperintendente &&
            !this.accountService.isVeterinario &&
            this.lote.OcorrenciaAtual.Situacao === SituacaoOcorrenciaEvento.AguardandoAprovacao)
        ) {
          return true;
        }
      }
    }
    return false;
  }

  /*permiteAprovarComPendencias() {
    if (!this.isNew()) {

      if (this.lote.IsEmDeliberacao && this.lote.OcorrenciaAtual != null) {
        if ((
          this.lote.OcorrenciaAtual.Situacao === SituacaoOcorrenciaEvento.Pendente ||
          this.lote.OcorrenciaAtual.Situacao === SituacaoOcorrenciaEvento.AguardandoAprovacao ||
          this.lote.OcorrenciaAtual.Situacao === SituacaoOcorrenciaEvento.AguardandoResolucao)  &&
          (this.accountService.isAdmin || this.accountService.isSuperintendente))
          {
          return true;
        }
      }
    }
    return false;
  }*/

  permiteRejeitar() {
    if (!this.isNew()) {
      if (this.lote.IsEmDeliberacao && this.lote.OcorrenciaAtual != null) {
        if (this.contexto === 'wagyu') {
          if (
            (this.tipoEvento === 'baixa' || this.tipoEvento === 'transferencia' || this.tipoEvento === 'fiv') &&
            this.lote.OcorrenciaAtual.Situacao === SituacaoOcorrenciaEvento.AguardandoAprovacao &&
            ((!this.accountService.isSuperintendente &&
              this.lote.OcorrenciaAtual.Situacao === SituacaoOcorrenciaEvento.AguardandoResolucao) ||
              (this.accountService.isSuperintendente &&
                this.lote.OcorrenciaAtual.Situacao === SituacaoOcorrenciaEvento.AguardandoAprovacao))
          ) {
            return true;
          }

          const token: any = this.accountService.getPayloadToken();

          if (
            this.lote.OcorrenciaAtual.Situacao === SituacaoOcorrenciaEvento.AguardandoAprovacaoVeterinario &&
            this.accountService.isVeterinario &&
            token &&
            token.CRMV === this.lote.OcorrenciaAtual?.Veterinario?.crmv
          ) {
            return true;
          }
        }

        if (
          this.lote.OcorrenciaAtual.Situacao === SituacaoOcorrenciaEvento.Pendente ||
          this.lote.OcorrenciaAtual.Situacao === SituacaoOcorrenciaEvento.AprovadoComPendencias ||
          (!this.accountService.isSuperintendente &&
            this.lote.OcorrenciaAtual.Situacao === SituacaoOcorrenciaEvento.AguardandoResolucao) ||
          (this.accountService.isSuperintendente &&
            this.lote.OcorrenciaAtual.Situacao === SituacaoOcorrenciaEvento.AguardandoAprovacao) ||
          (this.contexto === 'wagyu' &&
            !this.accountService.isSuperintendente &&
            !this.accountService.isVeterinario &&
            this.lote.OcorrenciaAtual.Situacao === SituacaoOcorrenciaEvento.AguardandoAprovacao)
        ) {
          return true;
        }
      }
    }
    return false;
  }

  permiteReverterRejeicao() {
    if (!this.isNew()) {
      if (
        this.lote.OcorrenciaAtual.Situacao === SituacaoOcorrenciaEvento.Rejeitado &&
        this.accountService.isSuperAdmin
      ) {
        return true;
      }
    }
    return false;
  }

  permiteGerarExcelInspecao() {
    if (this.lote && !this.isNew()) {
      if (this.lote.Situacao === SituacaoLoteOcorrencia.EmElaboracao) {
        if (this.lote.TipoOcorrenciaEmExecucao === 'OcorrenciaEventoInspecaoModel') {
          return true;
        }
      }
    }
    return false;
  }

  permiteGerarPdfInspecao() {
    if (this.lote && !this.isNew()) {
      if (this.lote.Situacao === SituacaoLoteOcorrencia.EmElaboracao) {
        if (this.lote.TipoOcorrenciaEmExecucao === 'OcorrenciaEventoInspecaoModel') {
          return true;
        }
      }
    }
    return false;
  }

  permiteSubmeter() {
    /*if (this.lote && !this.isNew()) {
      if (this.lote.Situacao === SituacaoLoteOcorrencia.EmElaboracao) {
        if (
          this.lote.TipoOcorrenciaEmExecucao.includes('OcorrenciaEventoBaixaModel') ||
          this.lote.TipoOcorrenciaEmExecucao.includes('OcorrenciaEventoPadreacaoModel') ||
          this.lote.TipoOcorrenciaEmExecucao.includes('OcorrenciaEventoNascimentoModel') ||
          this.lote.TipoOcorrenciaEmExecucao.includes('OcorrenciaEventoClassificacaoLinearModel') ||
          (this.contexto === 'wagyu' &&
            (
              (!this.accountService.isCriador && this.lote.TipoOcorrenciaEmExecucao.includes('OcorrenciaEventoTransferencia')) ||
              this.lote.TipoOcorrenciaEmExecucao.includes('OcorrenciaEventoFiv') ||
              this.lote.TipoOcorrenciaEmExecucao.includes('OcorrenciaEventoInovulacao') ||
              this.lote.TipoOcorrenciaEmExecucao.includes('OcorrenciaEventoPesagem') ||
              this.lote.TipoOcorrenciaEmExecucao.includes('OcorrenciaEventoAbateComAvalCarcacaModel')
            )
          )
        ) {
          return false;
        } else {
          return true;
        }
      }
    }*/
    if (this.lote && !this.isNew()) {
      if (
        this.lote.Situacao === SituacaoLoteOcorrencia.EmElaboracao ||
        (this.lote.Situacao === SituacaoLoteOcorrencia.ProcessadoComPendencias &&
          this.lote.OcorrenciaAtual.Situacao === SituacaoOcorrenciaEvento.AguardandoResolucao)
      ) {
        if (
          this.lote.TipoOcorrenciaEmExecucao.includes('OcorrenciaEventoBaixaModel') ||
          this.lote.TipoOcorrenciaEmExecucao.includes('OcorrenciaEventoPadreacaoModel') ||
          this.lote.TipoOcorrenciaEmExecucao.includes('OcorrenciaEventoNascimentoModel') ||
          this.lote.TipoOcorrenciaEmExecucao.includes('OcorrenciaEventoClassificacaoLinearModel') ||
          (this.contexto === 'wagyu' &&
            (this.lote.TipoOcorrenciaEmExecucao.includes('OcorrenciaEventoTransferenciaModel') ||
              this.lote.TipoOcorrenciaEmExecucao.includes('OcorrenciaEventoFiv') ||
              this.lote.TipoOcorrenciaEmExecucao.includes('OcorrenciaEventoInovulacao') ||
              this.lote.TipoOcorrenciaEmExecucao.includes('OcorrenciaEventoPesagem') ||
              this.lote.TipoOcorrenciaEmExecucao.includes('OcorrenciaEventoAbateComAvalCarcacaModel') ||
              this.lote.TipoOcorrenciaEmExecucao.includes('OcorrenciaEventoPadreacaoModel') ||
              this.lote.TipoOcorrenciaEmExecucao.includes('OcorrenciaEventoNacionalizacaoModel') ||
              this.lote.TipoOcorrenciaEmExecucao.includes('OcorrenciaEventoInspecaoModel') ||
              this.lote.TipoOcorrenciaEmExecucao.includes('OcorrenciaEventoNacionalizacaoEmbriaoModel')))
        ) {
          return true;
        }
      }
    }
    return false;
  }

  permiteSubmeterEAprovar() {
    /*if (this.lote && !this.isNew()) {
      if (this.lote.Situacao === SituacaoLoteOcorrencia.EmElaboracao) {
        if (

          this.lote.TipoOcorrenciaEmExecucao.includes('OcorrenciaEventoBaixaModel') ||
          this.lote.TipoOcorrenciaEmExecucao.includes('OcorrenciaEventoPadreacaoModel') ||
          this.lote.TipoOcorrenciaEmExecucao.includes('OcorrenciaEventoNascimentoModel') ||
          this.lote.TipoOcorrenciaEmExecucao.includes('OcorrenciaEventoClassificacaoLinearModel') ||
          (this.contexto === 'wagyu' &&
            (
              (!this.accountService.isCriador && this.lote.TipoOcorrenciaEmExecucao.includes('OcorrenciaEventoTransferencia')) ||
              this.lote.TipoOcorrenciaEmExecucao.includes('OcorrenciaEventoFiv') ||
              this.lote.TipoOcorrenciaEmExecucao.includes('OcorrenciaEventoInovulacao') ||
              this.lote.TipoOcorrenciaEmExecucao.includes('OcorrenciaEventoPesagem') ||
              this.lote.TipoOcorrenciaEmExecucao.includes('OcorrenciaEventoAbateComAvalCarcacaModel') ||
              this.lote.TipoOcorrenciaEmExecucao.includes('OcorrenciaEventoPadreacaoModel')
            )
          )
        ) {
          return true;
        }
      }
    }
    return false;*/
    return false;
  }

  permiteDescartar() {
    if (this.lote && !this.isNew()) {
      if (this.lote.Situacao === SituacaoLoteOcorrencia.EmElaboracao) {
        return true;
      }
    }
    return false;
  }

  permiteAprovarTodas() {
    if (this.lote && !this.isNew()) {
      if (
        this.lote.TipoOcorrenciaEmExecucao.includes('OcorrenciaEventoInspecaoModel') &&
        this.lote.Ocorrencias.Count > 1 &&
        this.lote.IsEmDeliberacao
      ) {
        return true;
      }
    }
    return false;
  }

  permiteRevisar() {
    if (!this.isNew()) {
      if (this.lote.IsEmDeliberacao && this.lote.OcorrenciaAtual != null) {
        if (this.contexto === 'wagyu') {
          if (
            (this.tipoEvento === 'baixa' || this.tipoEvento === 'transferencia' || this.tipoEvento === 'fiv') &&
            this.lote.OcorrenciaAtual.Situacao === SituacaoOcorrenciaEvento.AguardandoAprovacao &&
            this.accountService.isSuperintendente &&
            this.lote.OcorrenciaAtual.Situacao === SituacaoOcorrenciaEvento.AguardandoAprovacao
          ) {
            return true;
          }

          const token: any = this.accountService.getPayloadToken();

          if (
            this.lote.OcorrenciaAtual.Situacao === SituacaoOcorrenciaEvento.AguardandoAprovacaoVeterinario &&
            this.accountService.isVeterinario &&
            token &&
            token.CRMV === this.lote.OcorrenciaAtual?.Veterinario?.crmv.trim().replace('/', ' ')
          ) {
            return true;
          }
        }

        if (
          (this.accountService.isSuperintendente &&
            this.lote.OcorrenciaAtual.Situacao === SituacaoOcorrenciaEvento.AguardandoAprovacao) ||
          (this.contexto === 'wagyu' &&
            !this.accountService.isSuperintendente &&
            !this.accountService.isVeterinario &&
            this.lote.OcorrenciaAtual.Situacao === SituacaoOcorrenciaEvento.AguardandoAprovacao)
        ) {
          return true;
        }
      }
    }
    return false;
  }

  permiteAprovarTodos() {
    if (!this.isNew()) {
      if (this.lote.IsEmDeliberacao && this.lote.OcorrenciaAtual != null) {
        if (this.contexto === 'wagyu') {
          if ((this.tipoEvento == 'inspecao' || this.tipoEvento == 'inovulacao'
            || this.tipoEvento == 'nascimento' || this.tipoEvento == 'baixa'
            || this.tipoEvento == 'padreacao') &&
            this.lote.OcorrenciaAtual.Situacao === SituacaoOcorrenciaEvento.AguardandoAprovacao &&
            (this.accountService.isSuperintendente ||
              this.accountService.isAdmin)
            && this.verificaSituacao()) {
            return true;
          }
        }
      }
    }
    return false;
  }


  isNew() {
    if (this.lote) {
      return this.lote.Id === '' || this.lote.Id === '-1' || this.lote.Id === undefined;
    }
    return true;
  }

  isNewOcorrencia() {
    return this.idOcorrencia !== '' && this.idOcorrencia !== '-1' && this.idOcorrencia !== undefined;
  }

  verificaSituacao() {
    let i = 0;
    this.lote.Ocorrencias.forEach((element: any) => {
      if (element.Situacao != SituacaoOcorrenciaEvento.AguardandoAprovacao) {
        i++;
      }
    });

    if (i == 0) {
      return true;
    } else {
      return false;
    }
  }
}
