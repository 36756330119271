<app-formulario-tabs [formulario]="formulario" [listaComunicacao]="lote?.ListaComunicacao" [ocorrenciaAtualPad]="lote?.Ocorrencias" [tipoEvento]="padreacao" (tabSelecionada)="tabSelecionada($event)" (criadorSelecionado)="criadorSelecionado($event)"></app-formulario-tabs>

<div class="row">
  <div class="col-lg-12 col-sm-12">
    <div class="card card-principal-evento">
      <div class="card-body">

        <!--Ocorrencia Atual-->
        <div *ngIf="!isNewLote()">
          <div class="row">
            <div class="col-lg-12">
              <div class="card card-evento">
                <div class="card-body">

                  <div class="row card-title-evento">
                    <div class="col-6">
                      <h4 class="card-title">
                        <mat-icon svgIcon="padreacao"></mat-icon> Dados da {{ env.contexto === 'wagyu'? 'Cobertura' : 'Padreação' }} 
                      </h4>
                    </div>
                    <div class="col-6 text-right">
                      <button *ngIf="ocorrenciaAtual.Id !== '-1'"  type="button" class="btn btn-link btn-sm" (click)="documentosDaOcorrencia()" ><i class="far fa-folder"></i> Documentos</button>
                    </div>
                  </div>


                  <div class="row">
                    <div *ngIf="env.contexto === 'wagyu'" class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
                      <label>Estação de Monta?</label>
                      <select class="form-control form-control-sm" name="EstacaoDeMonta" [(ngModel)]="ocorrenciaAtual.EstacaoDeMonta" [disabled]="isOcorrenciaReadOnly()" (change)="estacaoDeMontaAlterada()">
                        <option value=1>Sim</option>
                        <option value=2>Não</option>
                      </select>
                    </div>

                    <div *ngIf="ocorrenciaAtual.EstacaoDeMonta == 2" class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
                      <label for="DataOcorrencia">*Data {{ env.contexto === 'wagyu'? 'Cobertura' : 'Padreação' }} </label>
                      <div class="input-group input-group-sm">
                        <input class="form-control"  [max]="maxDate" [matDatepicker]="dataPadreacao" name="DataOcorrencia" [(ngModel)]="ocorrenciaAtual.DataOcorrencia" [disabled]="isOcorrenciaReadOnly()">
                        <div *ngIf="!isOcorrenciaReadOnly()" class="input-group-append">
                          <button class="btn btn-outline-secondary" type="button" (click)="dataPadreacao.open()"> <i class="bi bi-calendar3"></i> </button>
                        </div>
                      </div>
                      <mat-datepicker [startAt]="ocorrenciaAtual.DataOcorrencia" #dataPadreacao></mat-datepicker>
                    </div>

                    <div class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
                      <label for="TipoPadreacao">*Tipo {{ env.contexto === 'wagyu'? 'Cobertura' : 'Padreação' }} </label>
                      <select class="form-control form-control-sm" name="TipoPadreacao" [(ngModel)]="ocorrenciaAtual.TipoPadreacao" [disabled]="isOcorrenciaReadOnly() || readonly()">
                        <option value="1">Inseminação Artificial</option>
                        <option value="2">Monta Natural</option>
                      </select>
                    </div>

                    <!-- wagyu - periodo da cobertura a campo -->
                    <div *ngIf="env.contexto === 'wagyu' && ocorrenciaAtual.EstacaoDeMonta == 1" class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
                      <label>*Início Estação</label>
                      <div class="input-group input-group-sm">
                        <input class="form-control"  [max]="maxDate" [matDatepicker]="inicioCobertura" name="InicioCobertura" [(ngModel)]="ocorrenciaAtual.InicioEstacaoDeMonta" [disabled]="isOcorrenciaReadOnly()">
                        <div *ngIf="!isOcorrenciaReadOnly()" class="input-group-append">
                          <button class="btn btn-outline-secondary" type="button" (click)="inicioCobertura.open()"> <i class="bi bi-calendar3"></i> </button>
                        </div>
                      </div>
                      <mat-datepicker [startAt]="ocorrenciaAtual.InicioEstacaoDeMonta" #inicioCobertura></mat-datepicker>
                    </div>
                    <div *ngIf="env.contexto === 'wagyu' && ocorrenciaAtual.EstacaoDeMonta == 1" class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
                      <label>*Término Estação</label>
                      <div class="input-group input-group-sm">
                        <input class="form-control"  [max]="maxDate" [matDatepicker]="fimCobertura" name="FimCobertura" [(ngModel)]="ocorrenciaAtual.FimEstacaoDeMonta" [disabled]="isOcorrenciaReadOnly()">
                        <div *ngIf="!isOcorrenciaReadOnly()" class="input-group-append">
                          <button class="btn btn-outline-secondary" type="button" (click)="fimCobertura.open()"> <i class="bi bi-calendar3"></i> </button>
                        </div>
                      </div>
                      <mat-datepicker [startAt]="ocorrenciaAtual.FimEstacaoDeMonta" #fimCobertura></mat-datepicker>
                    </div>
                  </div>

                  <div class="row">

                    <div class="form-group col-lg-2 col-md-4 col-sm-6 col-xs-12">
                      <label for="MatrizCadastrada">Matriz Cadastrada?</label>
                      <select class="form-control form-control-sm" name="MatrizCadastrada" [(ngModel)]="ocorrenciaAtual.MatrizCadastrada" [disabled]="isOcorrenciaReadOnly()" (change)="matrizCadastradaSelecionada()">
                        <option value=1>Sim</option>
                        <option value=2>Não</option>
                      </select>
                    </div>

                    <div *ngIf="ocorrenciaAtual.MatrizCadastrada == 1" class="col-lg-10 col-md-8 col-sm-6 col-xs-12">
                      <app-consultar-bovino 
                      [bovino]="ocorrenciaAtual.Matriz"
                      [ocorrenciaAtualSituacao]="ocorrenciaAtual.Situacao"></app-consultar-bovino>
                    </div>

                    <div *ngIf="ocorrenciaAtual.MatrizCadastrada == 2" class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
                      <label for="formulario">*Código Matriz</label>
                      <input type="text" class="form-control form-control-sm" name="CodigoMatrizNaoCadastrada" [(ngModel)]="ocorrenciaAtual.CodigoMatrizNaoCadastrada" [disabled]="isOcorrenciaReadOnly()">
                    </div>

                    <div *ngIf="ocorrenciaAtual.MatrizCadastrada == 2" class="form-group col-lg-7 col-md-4 col-sm-6 col-xs-12">
                      <label for="protocolo">*Nome Matriz</label>
                      <input type="text" class="form-control form-control-sm" name="NomeMatrizNaoCadastrada" [(ngModel)]="ocorrenciaAtual.NomeMatrizNaoCadastrada" [disabled]="isOcorrenciaReadOnly()">
                    </div>

                  </div>

                  <div class="row">

                    <div class="form-group col-lg-2 col-md-4 col-sm-6 col-xs-12">
                      <label for="ReprodutorCadastrado">Reprodutor Cadastrado?</label>
                      <select class="form-control form-control-sm" name="ReprodutorCadastrado" [(ngModel)]="ocorrenciaAtual.ReprodutorCadastrado" [disabled]="isOcorrenciaReadOnly()" (change)="reprodutorCadastradoSelecionado()">
                        <option value=1>Sim</option>
                        <option value=2>Não</option>
                      </select>
                    </div>

                    <div *ngIf="ocorrenciaAtual.ReprodutorCadastrado == 1" class="col-lg-10 col-md-8 col-sm-6 col-xs-12">
                      <app-consultar-bovino 
                      [ocorrenciaAtualSituacao]="ocorrenciaAtual.Situacao"
                      [bovino]="ocorrenciaAtual.Reprodutor"></app-consultar-bovino>
                    </div>

                    <div *ngIf="ocorrenciaAtual.ReprodutorCadastrado == 2" class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
                      <label for="formulario">*Código Reprodutor</label>
                      <input type="text" class="form-control form-control-sm" name="CodigoReprodutorNaoCadastrado" [(ngModel)]="ocorrenciaAtual.CodigoReprodutorNaoCadastrado" [disabled]="isOcorrenciaReadOnly() ">
                    </div>

                    <div *ngIf="ocorrenciaAtual.ReprodutorCadastrado == 2" class="form-group col-lg-7 col-md-4 col-sm-6 col-xs-12">
                      <label for="protocolo">*Nome Reprodutor</label>
                      <input type="text" class="form-control form-control-sm" name="NomeReprodutorNaoCadastrado" [(ngModel)]="ocorrenciaAtual.NomeReprodutorNaoCadastrado" [disabled]="isOcorrenciaReadOnly()">
                    </div>

                  </div>

                  <div *ngIf="lote?.IsEmDeliberacao" class="row">

                    <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
                      <label for="protocolo">Situação</label>
                      <input type="text" class="form-control form-control-sm" [value]="descSituacao(ocorrenciaAtual.Situacao, true)" readonly>
                    </div>
                    <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label for="protocolo">Inconsistência</label>
                      <textarea type="text" class="form-control form-control-sm" name="Inconsistencia"  [(ngModel)]="ocorrenciaAtual.Inconsistencia"  readonly>
                        </textarea>
                    </div>

                  </div>

                  <div *ngIf="isExibirMultaEDna()" class="row">
                    <div class="form-group col-lg-2 col-md-4 col-sm-6 col-xs-12">
                      <label for="ImputaMulta">*Imputa Multa</label>
                      <select class="form-control form-control-sm" name="ImputaMulta" [(ngModel)]="ocorrenciaAtual.ImputaMulta" [disabled]="isMultaOuDnaReadOnly()">
                        <option value=1>Sim</option>
                        <option value=2>Não</option>
                      </select>
                    </div>

                    <div class="form-group col-lg-2 col-md-4 col-sm-6 col-xs-12">
                      <label for="SolicitarDna">*Solicitar DNA</label>
                      <select class="form-control form-control-sm" name="SolicitarDna" [(ngModel)]="ocorrenciaAtual.SolicitarDna" [disabled]="isMultaOuDnaReadOnly()">
                        <option value=1>Sim</option>
                        <option value=2>Não</option>
                      </select>
                    </div>

                  </div>

                </div>
              </div>
            </div>

            <div class="col-lg-12">
              <app-botoes-acoes-ocorrencia [lote]="lote" [idOcorrencia]="ocorrenciaAtual.Id" (acaoSelecionada)="acaoBotao($event)"
              tipoEvento="padreacao"></app-botoes-acoes-ocorrencia>
            </div>

          </div>


          <!--Lista de Ocorrencias-->
          <div>

            <div *ngIf="ocorrencias.length > 0" class="row" style="margin-top: 15px;">
              <div class="col-lg-12 table-responsive">
                <table class="table table-hover table-header-custom">
                  <thead>
                    <tr>
                      <th>Matriz</th>
                      <th>Reprodutor</th>
                      <th>Data {{ env.contexto === 'jersey'? 'Padreação' : 'Cobertura' }}</th>
                      <th>Tipo {{ env.contexto === 'jersey'? 'Padreação' : 'Cobertura' }}</th>
                      <th class="text-center">Situação</th>
                      <th class="text-center" width="10%">Opções</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let ev of ocorrencias" [ngClass]="{'ocorrencia-em-destaque': ev.Id === ocorrenciaAtual.Id }">
                      <td *ngIf="permite || ev.Comprador == compradorLogado">{{ ev.MatrizCadastrada == 1 ? ev.Matriz.CodigoNomeBovino : ev.CodigoNomeMatrizNaoCadastrada }}</td>
                      <td *ngIf="permite || ev.Comprador == compradorLogado">{{ ev.ReprodutorCadastrado == 1 ? ev.Reprodutor.CodigoNomeBovino : ev.CodigoNomeReprodutorNaoCadastrado }}</td>
                      <td *ngIf="ev.EstacaoDeMonta == 2 && (permite || ev.Comprador == compradorLogado)">{{ ev.DataOcorrencia | date: 'dd/MM/yyyy' }}</td>
                      <td *ngIf="ev.EstacaoDeMonta == 1 && (permite || ev.Comprador == compradorLogado)">
                        {{ ev.InicioEstacaoDeMonta | date: 'dd/MM/yyyy' }} - {{ ev.FimEstacaoDeMonta | date: 'dd/MM/yyyy' }}
                      </td>
                      <td *ngIf="permite || ev.Comprador == compradorLogado">{{ descTipoPadreacao(ev.TipoPadreacao) }}</td>
                      <td class="text-center text-nowrap" *ngIf="permite || ev.Comprador == compradorLogado">
                        <div [innerHtml]="descSituacao(ev.Situacao)"></div>
                      </td>
                      <td class="text-center text-nowrap acoes-evento" *ngIf="permite || ev.Comprador == compradorLogado">
                        <i *ngIf="ev.ImputaMulta === 1" matTooltip="Multa Imputada" class="fas fa-dollar-sign"></i>
                        <i *ngIf="ev.SolicitarDna === 1" matTooltip="Será solicitado DNA no Nascimento" class="fas fa-dna"> </i>
                        <i *ngIf="permiteEdicao(ev.Situacao)" class="fas fa-edit" (click)="editar(ev)"></i>
                        <i *ngIf="!permiteEdicao(ev.Situacao)" class="fas fa-eye" (click)="editar(ev)"></i>
                        <i *ngIf="lote.Situacao === 1" class="fas fa-trash-alt" (click)="deletar(ev)"></i>
                        <i *ngIf="ev.IndInconsitencia" class="fa fa-exclamation-triangle" style="cursor: pointer;" matTooltip="{{ev.Inconsistencia}}"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>