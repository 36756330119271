import { AccountService } from 'src/app/account/shared/account.service';
import { Sexo, SituacaoOcorrenciaEvento, TipoBuscaBovino, TipoFiv, TipoTransferencia } from 'src/app/shared/enums/Enums';
import { Commons } from 'src/app/shared/commons';
import { ApiService } from 'src/app/shared/api.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { BaseEventos } from 'src/app/eventos/base-eventos';
import { TipoPadreacao } from 'src/app/shared/enums/Enums';
import { ConstantesTipoEvento } from 'src/app/shared/constantes';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { BovinoModel } from 'src/app/models/BovinoModel';
import { AssociacaoOcorrenciaEventoBovinoModel } from 'src/app/models/AssociacaoOcorrenciaEventoBovinoModel';

@Component({
  selector: 'app-te-fiv-manter',
  templateUrl: './te-fiv-manter.component.html',
  styleUrls: ['./te-fiv-manter.component.scss']
})
export class TeFivManterComponent extends BaseEventos implements OnInit {
  isReadOnly: boolean = false;

  readonly env = environment;
  tipoFiv: TipoFiv = TipoFiv.None;
  fiv: string = 'fiv';

  tiposTransferenciaFiv: { label: string, value: number }[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    public accountService: AccountService,
    public api: ApiService,
    public datePipe: DatePipe,
    public commons: Commons,
    public router: Router,
    public dialog: MatDialog
  ) {
    super(api, datePipe, commons, router, accountService, dialog);
    this.tipoEvento = ConstantesTipoEvento.TEFIV;
  }

  ngOnInit(): void {
    this.novoEvento();
    this.activatedRoute.paramMap.subscribe(params => {
      this.iniciarTela(params).then(() => {
        if (this.ocorrenciaAtual) {
          if (this.ocorrenciaAtual.TipoFIV !== '-1') {
            this.changeTipoFiv(this.ocorrenciaAtual.TipoFIV)
          }
        }
        this.preencherTipos();
      });
    });
    this.preencherTipos();
  }

  handleBovinoAtualChange(bovino: BovinoModel) {
    if (!bovino || !bovino.Id || bovino.Id === '-1') return;

    const hasBovino = this.ocorrenciaAtual.ListaBovinos.filter((item: AssociacaoOcorrenciaEventoBovinoModel) => item.BovinoId === bovino.Id);

    if (hasBovino && hasBovino.length > 0) {
      Swal.fire('O bovino selecionado já está na lista.', '', 'warning');
      this.resetBovinoAtual();
      return;
    }

    this.ocorrenciaAtual.ListaBovinos.push(<AssociacaoOcorrenciaEventoBovinoModel>{
      BovinoId: bovino.Id,
      Codigo: bovino.CodigoBovino,
      OcorrenciaId: this.ocorrenciaAtual.Id,
      Nome: bovino.NomeBovino,
      Proprietario: bovino.CodigoNomeProprietario,
    });

    // this.resetBovinoAtual();
  }

  salvar(confirmacao: boolean = false) {
    if (this.lote.OcorrenciaFivAtual)
      this.lote.OcorrenciaFivAtual.ListaBovinos = [];
    super.salvar(confirmacao);
  }

  private resetBovinoAtual() {
    this.ocorrenciaAtual.Touro = new BovinoModel();
    this.ocorrenciaAtual.Touro.TituloConsulta = "Touro";
    this.ocorrenciaAtual.Touro.TipoBusca = TipoBuscaBovino.Macho;
    this.ocorrenciaAtual.Touro.SexoBovino = Sexo.Macho;
    this.ocorrenciaAtual.Touro.ConsideraInativos = false;
    this.ocorrenciaAtual.Touro.ConsultaAnimalTodosCriadores = true;
    this.ocorrenciaAtual.Touro.IdProprietarioConsulta = this.lote.Criador.Id;
  }

  private preencherTipos() {
    this.tiposTransferenciaFiv = [];

    this.tiposTransferenciaFiv.push({
      value: TipoTransferencia.COLETA_COM_IMPLANTE_TOTAL,
      label: `Coleta Com Implante Total ${(this.env as any).contexto === 'wagyu' ? '(a fresco)' : ''}`
    });
    this.tiposTransferenciaFiv.push({
      value: TipoTransferencia.COLETA_COM_CONGELAMENTO_TOTAL,
      label: 'Coleta Com Congelamento Total'
    });
    this.tiposTransferenciaFiv.push({
      value: TipoTransferencia.COLETA_COM_IMPLANTE_E_CONGELAMENTO,
      label: 'Coleta Com Implante E Congelamento'
    });
  }

  descTipoPadreacao(tipo: number) {
    let descricao = "";
    if (tipo == TipoPadreacao.InseminacaoArtifical) {
      descricao = "Inseminação Artificial";
    }
    else if (tipo == TipoPadreacao.MontaNatural) {
      descricao = "Monta Natural";
    }

    return descricao;
  }

  isTipoPadreacaoReadOnly(): boolean {
    if (this.lote) {
      if (this.ocorrenciaAtual.Situacao === SituacaoOcorrenciaEvento.Pendente) {
        let idCriadorSelecionado = this.lote.Criador.Id;
        let idProprietarioReprodutorSelecionado = this.ocorrenciaAtual.Reprodutor.IdProprietario;

        if (idCriadorSelecionado !== '-1' &&
          idCriadorSelecionado !== '' &&
          (idProprietarioReprodutorSelecionado !== '-1' &&
            idProprietarioReprodutorSelecionado !== '') &&
          idCriadorSelecionado === idProprietarioReprodutorSelecionado) {
          return false;
        } else {
          this.ocorrenciaAtual.TipoPadreacao = 1;
          return true;
        }
      }
      return true;
    }
    return true;
  }

  RemoverBovino(id: string) {
    if (super.isOcorrenciaReadOnly()) return;

    this.ocorrenciaAtual.ListaBovinos = this.ocorrenciaAtual.ListaBovinos.filter((item: AssociacaoOcorrenciaEventoBovinoModel) =>
      (item.BovinoId !== id)
    );
  }

  isExibirMultaEDna() {
    if (this.lote) {
      if (this.ocorrenciaAtual.Situacao === SituacaoOcorrenciaEvento.AguardandoAprovacao ||
        this.ocorrenciaAtual.Situacao === SituacaoOcorrenciaEvento.AprovadoSuperintendente ||
        this.ocorrenciaAtual.Situacao === SituacaoOcorrenciaEvento.RejeitadoSuperintendente) {
        return true;
      }
    }
    return false;
  }

  isMultaOuDnaReadOnly() {
    if (this.lote) {
      if (this.ocorrenciaAtual.Situacao === SituacaoOcorrenciaEvento.AguardandoAprovacao && this.accountService.isSuperintendente) {
        return false;
      }
    }
    return true;
  }

  matrizCadastradaSelecionada() {
    if (this.ocorrenciaAtual.MatrizCadastrada == 1) {//Sim
      this.ocorrenciaAtual.CodigoMatrizNaoCadastrada = "";
      this.ocorrenciaAtual.NomeMatrizNaoCadastrada = "";
    }
    else if (this.ocorrenciaAtual.MatrizCadastrada == 2) {//Não
      this.ocorrenciaAtual.Matriz.Id = "-1";
      this.ocorrenciaAtual.Matriz.CodigoBovino = "";
      this.ocorrenciaAtual.Matriz.NomeBovino = "";
      this.ocorrenciaAtual.Matriz.CodigoNomeProprietario = "";
    }
  }

  reprodutorCadastradoSelecionado() {
    if (this.ocorrenciaAtual.ReprodutorCadastrado == 1) {//Sim
      this.ocorrenciaAtual.CodigoReprodutorNaoCadastrado = "";
      this.ocorrenciaAtual.NomeReprodutorNaoCadastrado = "";
    }
    else if (this.ocorrenciaAtual.ReprodutorCadastrado == 2) {//Não
      this.ocorrenciaAtual.Reprodutor.Id = "-1";
      this.ocorrenciaAtual.Reprodutor.CodigoBovino = "";
      this.ocorrenciaAtual.Reprodutor.NomeBovino = "";
      this.ocorrenciaAtual.Reprodutor.CodigoNomeProprietario = "";
    }
  }

  permiteEditarAprovado() {
    if ((this.accountService.isAdmin || this.accountService.isSuperintendente) &&
      (this.ocorrenciaAtual.Situacao == SituacaoOcorrenciaEvento.Aprovado)) {
      return true;
    } else {
      return false;
    }
  }

  changeTipoFiv(tipoFiv: TipoFiv) {
    this.tipoFiv = tipoFiv;
    this.ocorrenciaAtual.TipoFIV = tipoFiv;
  }
}

