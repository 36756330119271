<div class="card" style="margin-top: 1em;">
	<div class="card-body">
		<div class="row">

			<div id="arquivo-container" class="form-group col-lg-4 col-md-4 col-sm-4 col-xs-6">
				<label>Data importação</label>
				<div>
					<input 
            readonly 
            type="text" 
            name="dataImportacao" 
            class="form-control form-control-sm" 
            [value]="controleLeiteiro.dataImportacao"
          >
				</div>
			</div>

			<div id="arquivo-container" class="form-group col-lg-8 col-md-8 col-sm-8 col-xs-6">
				<label>Arquivo</label>
				<div>
					<input 
            readonly 
            type="text" 
            name="arquivo" 
            class="form-control form-control-sm" 
            [value]="controleLeiteiro.arquivo.nome"
          >
				</div>
			</div>

		</div>

		<div class="row">

			<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
				<label>Descrição</label>
				<textarea 
          readonly
          type="text" 
          name="descricao" 
          class="form-control form-control-sm"
					[(ngModel)]="controleLeiteiro.descricao"
					style="min-height: calc(1.5em + 0.5rem + 2px); height: 50px;"
        >
        </textarea>
			</div>

		</div>

      <div class="row justify-content-between btns-container">
          <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <button type="button" class="btn btn-sm btn-block btn-primary" (click)="downloadArquivo()">Download</button>
          </div>

          <div *ngIf="!apenasLeitura" class="btns-acoes-container" style="display: flex; justify-content: flex-end;">
              <div class="col-lg-8 col-md-5 col-xs-12">
                  <button type="button" class="btn btn-sm btn-block btn-primary" (click)="aprovarImportacao()">Aprovar importação</button>
              </div>
              <div class="col-lg-8 col-md-5 col-xs-12">
                  <button type="button" class="btn btn-sm btn-block btn-primary" (click)="descartarImportacao()">Descartar importação</button>
              </div>
          </div>
      </div>

      <div *ngIf="controleLeiteiro.arquivo.itens && controleLeiteiro.arquivo.itens.length > 0"  class="row" style="margin-top: 15px;">
          <div class="col-lg-12 table-responsive">
            <table class="table table-hover table-header-custom">
              <thead>
                <tr>
                  <th>Nº</th>
                  <th>Código</th>
                  <th>Nome</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr 
                  *ngFor="let controle of controleLeiteiro.arquivo.itens" 
                  [ngStyle]="{'background-color': obterCorDeFundo(controle.Situacao)}"
                >
                  <td>{{ controle.NumeroLinha }}</td>
                  <td>{{ controle.CodigoRegistro }}</td>
                  <td>{{ controle.NomeAnimal }}</td>
                  <td>
                    <a 
                      type="button"
                      matTooltip="Item importado com sucesso" 
                      *ngIf="controle.Situacao === 3" 
                    >
                      <i class="fas fa-check" style="color: #515151;"></i>
                    </a>
                    <a 
                      type="button"
                      matTooltipClass="tooltip-list"
                      matTooltip="{{controle.Observacao | tooltipObservacao }}" 
                      *ngIf="controle.Situacao === 2 || controle.Situacao === 4" 
                    >
                      <i style="color: #515151;" class="fas fa-exclamation-triangle"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

	</div>
</div>