<div class="card" style="margin-top: 1em;">
  <div class="card-body">

    <div class="row">

      <div class="form-group col-lg-4 col-md-6 col-sm-6 col-xs-12">
        <label>*Nome</label>
        <input type="text" class="form-control form-control-sm" name="nome" [(ngModel)]="formRegistrar.nome">
      </div>

      <div class="form-group col-lg-4 col-md-6 col-sm-6 col-xs-12">
        <label>*Município</label>
        <input type="text" class="form-control form-control-sm" name="municipio" [(ngModel)]="formRegistrar.municipio">
      </div>

      <div class="form-group col-lg-2 col-md-6 col-sm-6 col-xs-12">
        <label>*UF</label>
        <select class="form-control form-control-sm" name="uf" [(ngModel)]="formRegistrar.uf">
          <option *ngFor="let uf of UFs" [value]="uf.value" [label]="uf.label"></option>
        </select>
      </div>

      <div class="form-group col-lg-2 col-md-6 col-sm-6 col-xs-12">
        <label>*Data exposição</label>
        <div class="input-group input-group-sm">
          <input class="form-control" name="dataExposicao" [matDatepicker]="dataExposicao"
            [(ngModel)]="formRegistrar.dataExposicao">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button" (click)="dataExposicao.open()">
              <i class="bi bi-calendar3"></i>
            </button>
          </div>
        </div>
        <mat-datepicker [startAt]="formRegistrar.dataExposicao" #dataExposicao></mat-datepicker>
      </div>

      <div class="form-group col-lg-4 col-md-6 col-sm-6 col-xs-12">
        <label>Jurado de Pista</label>
        <input type="text" class="form-control form-control-sm" name="juradoPista"
          [(ngModel)]="formRegistrar.juradoPista">
      </div>

      <div class="form-group col-lg-4 col-md-6 col-sm-6 col-xs-12">
        <label>Jurado de Admissão</label>
        <input type="text" class="form-control form-control-sm" name="juradoAdmissao"
          [(ngModel)]="formRegistrar.juradoAdmissao">
      </div>

      <div class="form-group col-lg-4 col-md-6 col-sm-6 col-xs-12">
        <label>Responsável</label>
        <input type="text" class="form-control form-control-sm" name="responsavel"
          [(ngModel)]="formRegistrar.responsavel">
      </div>

      <div class="form-group col-lg-4 col-md-6 col-sm-6 col-xs-12">
        <label>*Email</label>
        <input type="email" class="form-control form-control-sm" name="email" [(ngModel)]="formRegistrar.email">
      </div>

      <div class="form-group col-lg-2 col-md-6 col-sm-6 col-xs-12">
        <label>*Início inscrições</label>
        <div class="input-group input-group-sm">
          <input class="form-control" name="inicioInscricoes" [matDatepicker]="inicioInscricoes"
            [(ngModel)]="formRegistrar.inicioInscricoes">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button" (click)="inicioInscricoes.open()">
              <i class="bi bi-calendar3"></i>
            </button>
          </div>
        </div>
        <mat-datepicker [startAt]="formRegistrar.inicioInscricoes" #inicioInscricoes></mat-datepicker>
      </div>

      <div class="form-group col-lg-2 col-md-6 col-sm-6 col-xs-12">
        <label>*Fim inscrições</label>
        <div class="input-group input-group-sm">
          <input class="form-control" name="fimInscricoes" [matDatepicker]="fimInscricoes"
            [(ngModel)]="formRegistrar.fimInscricoes">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button" (click)="fimInscricoes.open()">
              <i class="bi bi-calendar3"></i>
            </button>
          </div>
        </div>
        <mat-datepicker [startAt]="formRegistrar.fimInscricoes" #fimInscricoes></mat-datepicker>
      </div>

    </div>

    <div class="row justify-content-end" id="botao-container">
      <div *ngIf="permiteNovo" class="col-lg-4 col-md-10 col-sm-10">
        <button type="button" class="btn btn-sm btn-block btn-primary" (click)="novo()">Salvar</button>
      </div>
    </div>

  </div>
</div>