import { BovinoModel } from 'src/app/models/BovinoModel';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-consultar-bovino-modal',
  templateUrl: './consultar-bovino-modal.component.html',
  styleUrls: ['./consultar-bovino-modal.component.scss']
})
export class ConsultarBovinoModalComponent implements OnInit {

  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 50, 100];
  bovinos: any[] = [];

  tituloConsulta: string = "";
  @Output() bovinoSelecionado = new EventEmitter<any>();

  formConsulta: BovinoModel = new BovinoModel();

  constructor(
    private api: ApiService,
    public dialogRef: MatDialogRef<ConsultarBovinoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.formConsulta = this.data.bovino;
    this.formConsulta.CodigoBovino = "";
    this.formConsulta.NomeBovino = "";
  }

  limpar() {
    this.formConsulta.CodigoBovino = "";
    this.formConsulta.NomeBovino = "";
  }

  botaoBuscar(){
    this.page = 1;
    this.consultar();
  }

  consultar(){
    this.api.consultarBovinos(
      this.formConsulta,
      this.page,
      10
    ).then((result: any) => {
      if(result){
        if(result.Status === 0){
          this.bovinos = result.Bovinos;
          this.count = result.TotalItens;
        }else{
          this.bovinos = [];
          this.count = 0;
          this.limpar();
          Swal.fire(result.Mensagem, '', 'error');
        }
      }
    });
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.consultar();
  }

  selecionarBovino(bovino: any) {
    this.bovinoSelecionado.emit(bovino);
    this.dialogRef.close();
  }

  fechar() {
    this.dialogRef.close();
  }

}
