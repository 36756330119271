import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CriadorModel, ValidaCriadorModel } from 'src/app/models/CriadorModel';
import { ApiService } from 'src/app/shared/api.service';
import { Commons } from 'src/app/shared/commons';
import { UnidadesFederativas } from 'src/app/shared/enums/Enums';
import { IJsonResultModel, IValidaUsuario } from 'src/app/shared/interfaces/Interfaces';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-criador-validar',
  templateUrl: './criador-validar.component.html',
  styleUrls: ['./criador-validar.component.scss']
})
export class CriadorValidarComponent implements OnInit {

  readonly env = environment;

  @Input() valido: boolean = false;
  @Input() criador: CriadorModel = new CriadorModel();
  @Output() validoChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() formValidaCriador: ValidaCriadorModel = new ValidaCriadorModel();

  constructor(
    private api: ApiService,
    private commons: Commons,
  ) { }

  ngOnInit(): void {
  }

  filiadaSelecionada(idFiliada: string) {
    this.formValidaCriador.vinculacao = idFiliada;
  }

  private validar(value: boolean) {
    this.valido = value;
    this.validoChange.emit(this.valido);
  }

  preencherDadosCriadorExistente(dados: IJsonResultModel<IValidaUsuario>): void {
    if (!dados.Data) return;
    
    this.criador.nome = dados.Data.Nome || "";
    this.criador.email = dados.Data.Email;
    
    this.criador.endereco.bairro = dados.Data.Bairro;
    this.criador.endereco.cep = dados.Data.Cep;
    this.criador.endereco.cidade = dados.Data.Cidade;
    this.criador.endereco.numero = dados.Data.Numero;
    this.criador.endereco.complemento = dados.Data.Complemento;
    this.criador.endereco.logradouro = dados.Data.Logradouro;
    this.criador.endereco.uf = Number(UnidadesFederativas[dados.Data.UF as any]);

    this.criador.celular.ddd = dados.Data.CelularDDD;
    this.criador.celular.numero = dados.Data.CelularNumero;

    this.criador.residencial.ddd = dados.Data.TelefoneDDD;
    this.criador.residencial.numero = dados.Data.TelefoneNumero;
  }

  botaoValidar(): void {
    this.api.validarCriador(this.formValidaCriador).then(async (result: IJsonResultModel<IValidaUsuario>) => {
      if (result && result.Data?.success) {
        if (result.Data.PessoaCadastrada) {
          this.validar(await this.commons.modalConfirmacao({
            title: result.Data.UsuarioMesmaAssociacao
              ? 'CPF já cadastrado nessa Filiada. Deseja cadastrar um novo criador para esta pessoa?'
              : 'CPF já cadastrado em outra Filiada. Deseja cadastrar um novo criador nesta filiada para esta pessoa?'
          }));
          this.preencherDadosCriadorExistente(result);
        } else {
          this.validar(true);
          Swal.fire(result.Data.message, '', 'success');
        }
      } else {
        this.validar(false);
        Swal.fire(result.Data?.message || 'A operação não pode ser realizada.', '', 'error');
      }
    });
  }

}
