<div class="row">

  <div *ngIf="env.contexto === 'jersey'" class="form-group col-lg-4 col-md-12 col-sm-112 col-xs-12">
    <app-selecionar-filiadas 
      [readonly]="false" 
      [filiadaId]="formConsulta.vinculacao"
      defaultFirstSelectedLabel="Todos" 
      (filiadaSelecionada)="filiadaSelecionada($event)">
    </app-selecionar-filiadas>
  </div>  

  <div class="form-group col-lg-8 col-md-12 col-sm-12 col-xs-12">
    <app-consultar-criador [criador]="formConsulta.criador"></app-consultar-criador>
  </div>

</div>