import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/shared/api.service';

interface IDataInput {
  idCriador: string
}
@Component({
  selector: 'app-lista-animais-modal',
  templateUrl: './lista-animais-modal.component.html',
  styleUrls: ['./lista-animais-modal.component.scss']
})
export class ListaAnimaisModalComponent implements OnInit {

  animais: any;
  listaAux: any;
  animaisSelecionados: any[] = [];
  animalBusca: string = '';

  @Output() animaisSelecionadosEmitter = new EventEmitter<string[]>();

  constructor(
    public dialogRef: MatDialogRef<ListaAnimaisModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDataInput,
    public dialog: MatDialog,
    public api: ApiService,
  ) { }

  ngOnInit(): void {
    this.api.consultarAnimaisPreAbate(this.data.idCriador).then((result: any) => {
      this.animais = result.Bovinos;
      this.listaAux = result.Bovinos;
    })
  }

  handleSelecionadoChange(animal: any) {
    const index: number = this.animaisSelecionados.indexOf(animal.Id);

    if (index === -1) {
      this.animaisSelecionados.push(animal)
    } else {
      this.animaisSelecionados.splice(index, 1);
    }
  }

  selecionarAnimais() {
    this.animaisSelecionadosEmitter.emit(this.animaisSelecionados);
    this.dialogRef.close();
  }

  buscarAnimal() {
    if (this.animalBusca != '') {
      this.animais = this.listaAux.filter((animal: any) => animal?.NomeBovino.toLowerCase().includes(this.animalBusca.toLowerCase()) || animal?.CodigoBovino.toLowerCase().includes(this.animalBusca.toLowerCase()));
    } else {
      this.animais = this.listaAux;
    }
  }
}
