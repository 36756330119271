import { AccountService } from 'src/app/account/shared/account.service';
import { Commons } from 'src/app/shared/commons';
import { ApiService } from 'src/app/shared/api.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { BaseEventos } from 'src/app/eventos/base-eventos';
import { ConstantesTipoEvento } from 'src/app/shared/constantes';
import { MatDialog } from '@angular/material/dialog';

import { environment } from 'src/environments/environment';
import { TipoMotivoMorteNatural, TipoMotivoBaixa } from 'src/app/shared/enums/Enums';
import { FiltroConsultaEventosModel } from 'src/app/models/FiltroConsultaEventosModel';
import Swal from 'sweetalert2';
import { BovinoModel } from 'src/app/models/BovinoModel';


@Component({
  selector: 'app-carcaca-manter-pos',
  templateUrl: './carcaca-manter-pos.component.html',
  styleUrls: ['./carcaca-manter-pos.component.scss'],
  providers: [
  ],
})

export class CarcacaManterPosComponent extends BaseEventos implements OnInit {
  isReadOnly: boolean = false;
  tipoEventoPos: string = 'abatecomavalcarcaca_manter_pos';
  readonly env = environment;
  motivos: { label: string; value: TipoMotivoBaixa; }[] = [];
  causasMorteNatural: { label: string; value: TipoMotivoMorteNatural; }[] = [];
  possuiPreAbate: string = "2";
  filtroConsultaPreAbate: FiltroConsultaEventosModel = new FiltroConsultaEventosModel();
  relacoesPreAbate: any;
  Desossa: string = 'Desossa';
  Abate: string = 'Abate';

  constructor(
    private activatedRoute: ActivatedRoute,
    public accountService: AccountService,
    public api: ApiService,
    public datePipe: DatePipe,
    public commons: Commons,
    public router: Router,
    public dialog: MatDialog
  ) {
    super(api, datePipe, commons, router, accountService, dialog);
    this.tipoEvento = ConstantesTipoEvento.BAIXA;
  }

  ngOnInit(): void {
    this.novoEvento();
    this.activatedRoute.paramMap.subscribe(params => {
      this.iniciarTela(params).then(async () => {
        this.setFrigorifico(this.ocorrenciaAtual);
        this.setFrigorificoAbate(this.ocorrenciaAtual);

      });
    });

    if (this.accountService.isTecnico) {
      const token: any = this.accountService.getPayloadToken();
      if (token) {
        this.ocorrenciaAtual.Inspetor.Codigo = token.CodigoInspetor;
        this.ocorrenciaAtual.Inspetor.NomeInspetor = token.NomePessoaUsuario;
        this.ocorrenciaAtual.Inspetor.Id = token.UserId;
        this.ocorrenciaAtual.InspetorId = token.UserId;
      }
    }
    this.preencherCausasMorteNatural();

    this.preencherTiposMotivo();

    this.showBotaoRelatorio();

    this.showFrigorificoAbate();

    this.possuiPreAbate = '1';

    this.showInputProtocolo();
  }

  private preencherTiposMotivo(): void {

    this.motivos = [];
    this.motivos.push({ value: -1, label: 'Selecione...' });
    this.motivos.push({ value: TipoMotivoBaixa.ABATE_FRIGORIFICO, label: 'Abate/Frigorífico' });
  }

  private preencherCausasMorteNatural(): void {
    this.causasMorteNatural = [];
    this.causasMorteNatural.push({ value: -1, label: 'Selecione...' });

    Object.values(TipoMotivoMorteNatural).map((item: any) => {
      if (!isNaN(item) && item > 0)
        this.causasMorteNatural.push({ value: item, label: this.commons.getDescricaoMotivoMorte(item) });
    });
  }

  private setFrigorifico(ev: any) {
    this.ocorrenciaAtual.Frigorifico.id = ev.Frigorifico.Id;
    this.ocorrenciaAtual.Frigorifico.usuarioId = ev.Frigorifico.Id;
    this.ocorrenciaAtual.Frigorifico.nome = ev.Frigorifico.NomeCriador;
    this.ocorrenciaAtual.Frigorifico.codigo = ev.Frigorifico.CodigoCriador;
    this.ocorrenciaAtual.Frigorifico.afixo = ev.Frigorifico.AfixoCriador;
    this.ocorrenciaAtual.Frigorifico.readonly = super.isOcorrenciaReadOnly();
    this.ocorrenciaAtual.Frigorifico.tituloConsulta = 'Frigorífico';

  }

  private setFrigorificoAbate(ev: any) {
    this.ocorrenciaAtual.FrigorificoAbate.id = ev.FrigorificoAbate.Id;
    this.ocorrenciaAtual.FrigorificoAbate.usuarioId = ev.FrigorificoAbate.Id;
    this.ocorrenciaAtual.FrigorificoAbate.nome = ev.FrigorificoAbate.NomeCriador;
    this.ocorrenciaAtual.FrigorificoAbate.codigo = ev.FrigorificoAbate.CodigoCriador;
    this.ocorrenciaAtual.FrigorificoAbate.afixo = ev.FrigorificoAbate.AfixoCriador;
    this.ocorrenciaAtual.FrigorificoAbate.readonly = super.isOcorrenciaReadOnly();
    this.ocorrenciaAtual.FrigorificoAbate.tituloConsulta = 'Frigorífico';

  }

  editar(evento: any) {

    // evento.FrigorificoAbate.Id = this.ocorrenciaAtual.Frigorifico.Id;
    // evento.FrigorificoAbate.CodigoCriador = this.ocorrenciaAtual.Frigorifico.CodigoCriador;
    // evento.FrigorificoAbate.NomeCriador = this.ocorrenciaAtual.Frigorifico.NomeCriador;
    super.editar(evento);
    this.setFrigorifico(evento);
    this.setFrigorificoAbate(evento);
    if (this.ocorrenciaAtual.HasPreAbate) {
      this.ocorrenciaAtual.PossuiPreAbate = '1';
    } else {
      this.ocorrenciaAtual.PossuiPreAbate = '2';
    }
  }

  frigorificoSelecionado(ev: any) {
    this.ocorrenciaAtual.Frigorifico.id = ev;
    this.ocorrenciaAtual.Frigorifico.usuarioId = ev;
  }

  adaptaValor() {
    this.ocorrenciaAtual.Gordura = this.ocorrenciaAtual.Gordura.toFixed(2);
  }

  criadorSelecionadoPosAbate(idCriador: string) {
    this.ocorrenciaAtual.TipoMotivoBaixa = TipoMotivoBaixa.ABATE_FRIGORIFICO;
  }

  showInputProtocolo() {

    if (this.ocorrenciaAtual.PossuiPreAbate == "1") {
      this.ocorrenciaAtual.HasPreAbate = true;
      return true;
    }
    else {
      this.ocorrenciaAtual.HasPreAbate = false;
      return false;
    }
  }

  animalCruzadoSelecionado() {
    if (this.ocorrenciaAtual.CruzadoCadastrado == 1) {//Sim
      this.ocorrenciaAtual.Animal.Id = "-1";
      this.ocorrenciaAtual.Animal.CodigoBovino = "";
      this.ocorrenciaAtual.Animal.NomeBovino = "";
    }
    else if (this.ocorrenciaAtual.CruzadoCadastrado == 2) {//Não
      this.ocorrenciaAtual.CodigoAnimalCruzado = "";
      this.ocorrenciaAtual.NomeAnimalCruzado = "";
      this.ocorrenciaAtual.IdadeAnimalCruzado = 0;
      this.ocorrenciaAtual.CompRacialAnimalCruzado = "";
    }
  };

  consultarProtocolo() {
    const idBovino = '-1';
    this.filtroConsultaPreAbate.formularioProtocolo = this.ocorrenciaAtual.PreAbate;
    this.api.consultarAbateComAvalCaracaca(this.filtroConsultaPreAbate, 1, 100).then(async (result: any) => {
      this.ocorrenciaAtual.DataAbate = result.Itens[0].DataInspecaoEAbate;
      this.ocorrenciaAtual.PreAbate = this.filtroConsultaPreAbate.formularioProtocolo;
      var idOcorrencia = result.Itens[0].Id;
      const response = await this.api.consultarRelacaoAbateComAvalCaracaca(
        { idBovino, idOcorrencia }, 1, 100   // to-do: paginacao
      )

      if (this.relacoesPreAbate == null) {
        this.relacoesPreAbate = [];
      }
      if (this.isOcorrenciaReadOnly()) {
        this.relacoesPreAbate = [];
        //this.ocorrenciaAtual.RelacoesPosAbate = [];
      }

      if (response && response.success) {
        this.relacoesPreAbate = [...this.relacoesPreAbate, ...(response.Itens?.map((i: any) => i.PreAbate))];
        //this.ocorrenciaAtual.RelacoesPosAbate = [...this.ocorrenciaAtual.RelacoesPosAbate, ...(response.Itens?.map((i: any) => i.PosAbate))];

      } else {
        this.ocorrenciaAtual.relacoesPreAbate = [];
        this.ocorrenciaAtual.RelacoesPosAbate = [];
        Swal.fire(response.message || 'Não foi possível realizar a operação.', '', 'error');
      }
    });
  }

  selecionaAnimal(codigo: string) {
    var bovino = new BovinoModel();
    bovino.CodigoBovino = codigo;

    this.api.consultarBovinoPorCodigo(bovino).then((result: any) => {
      if (result) {

        if (result.Status === 0) {
          this.ocorrenciaAtual.Animal.Id = result.Bovino.Id;
          this.ocorrenciaAtual.Animal.CodigoBovino = result.Bovino.CodigoBovino;
          this.ocorrenciaAtual.Animal.OrelhaDireita = result.Bovino.CodigoSecundarioBovino;
          this.ocorrenciaAtual.Animal.NomeBovino = result.Bovino.NomeBovino;
          this.ocorrenciaAtual.Animal.SexoBovino = result.Bovino.SexoBovino;
          this.ocorrenciaAtual.Animal.CodigoNomeProprietario = result.Bovino.ProprietarioBovino;
          this.ocorrenciaAtual.Animal.IdProprietario = result.Bovino.IdProprietario;
          this.ocorrenciaAtual.Animal.DestinoInspecao = result.Bovino.DestinoInspecao;
        }
      }
    });
  }

  // bloqueiaQuantidadeSelos() {
  //   if (this.lote != null && this.lote.Ocorrencias != null
  //     && this.lote.Ocorrencias.length > 0) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  showBotaoRelatorio() {
    if (this.lote) {
      if (this.lote.Situacao != 3) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }

  gerarRelatorio(idLote: string) {
    this.api.gerarRelatorioPreAbate("", idLote).then((result: any) => {
      const blob: Blob = this.commons.base64toBlob(result.Data.data);
      const blobURL: string = URL.createObjectURL(blob);

      const anchor: HTMLAnchorElement = document.createElement('a');
      anchor.href = blobURL;
      anchor.download = 'Relacao_pos_abate.xlsx';
      anchor.click();
      anchor.remove();
    });
  }

  frigorificoAbateSelecionado(ev: any) {
    this.ocorrenciaAtual.FrigorificoAbate.id = ev;
    this.ocorrenciaAtual.FrigorificoAbate.usuarioId = ev;
  }

  identificaAnimalSalvo(id: any): boolean {

    return this.ocorrencias.some(ocorrencias => ocorrencias.Animal.Id === id);
  }

  formatarCompRacial(value: string): void {
    value = value.replace(/\//g, '').replace(/\s/g, '');

    // Adiciona a barra no meio se o comprimento for suficiente
    if (value.length > 1) {
      this.ocorrenciaAtual.CompRacialAnimalCruzado = `${value.substring(0, value.length / 2)}/${value.substring(value.length / 2)}`;
    } else {
      this.ocorrenciaAtual.CompRacialAnimalCruzado = value;
    }
  }

  showFrigorificoAbate() {
    if (this.ocorrenciaAtual.PossuiPreAbate == "1") {
      return false;
    } else {
      return true;
    }
  }
}
