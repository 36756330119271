import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { RolesKeys } from './RolesKeys';
import { ConstantesRotas } from 'src/app/shared/constantes';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  private readonly contexto = (environment as any).contexto;

  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  private sessaoExpirada: boolean = false;
  private roles: string[] = [];

  public isAdmin: boolean = false;
  public isCriador: boolean = false;
  public isSuperAdmin: boolean = false;
  public isSuperintendente: boolean = false;
  public isVeterinario: boolean = false;
  public isProcurador: boolean = false;
  public isInspetor: boolean = false;
  public isTecnico: boolean = false;

  constructor(private http: HttpClient, private router: Router) {
    this.refreshProperties();
  }

  refreshProperties() {
    this.isAdmin = this.checkRole(RolesKeys.Admin);
    this.isSuperAdmin = this.checkRole(RolesKeys.SuperAdmin);
    this.isCriador = this.checkRole(RolesKeys.Associado);
    this.isSuperintendente = this.checkRole(RolesKeys.Superintendente);
    this.isVeterinario = this.checkRole(RolesKeys.Veterinario);
    this.isProcurador = this.checkRole(RolesKeys.Procurador);
    this.isInspetor = this.checkRole(RolesKeys.Inspetor);
    this.isTecnico = this.checkRole(RolesKeys.Tecnico);
  }

  async login(user: any) {
    let data = `username=${this.pad(user.codigo, 8)}@${user.usuario}&password=${user.senha}&grant_type=password&client_id=${environment.client_id}`;
    const result = await this.http.post<any>(`${environment.api}/oauth2/token`, data).toPromise();
    if (result && result.access_token) {
      localStorage.setItem(this.JWT_TOKEN, result.access_token);
      localStorage.setItem(this.REFRESH_TOKEN, result.refresh_token);
      return true;
    }

    return false;
  }

  logoff() {
    this.sessaoExpirada = false;
    window.localStorage.clear();
    this.router.navigate(['login']);
  }

  sessaoExpirou() {
    this.sessaoExpirada = true;
    window.localStorage.clear();
    this.router.navigate(['login']);
  }

  isSessaoExpirou() {
    return this.sessaoExpirada;
  }

  pad(num: number, size: number): string {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }

  getAuthorizationToken() {
    const token = window.localStorage.getItem(this.JWT_TOKEN);
    return token;
  }

  getRefreshToken() {
    const refreshToken = window.localStorage.getItem(this.REFRESH_TOKEN);
    return refreshToken;
  }

  getTokenExpirationDate(token: string): Date {
    const decoded: any = jwt_decode(token);

    if (decoded.exp === undefined) {
      return new Date(0);
    }

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  isTokenExpired(token?: string): boolean {
    if (!token) {
      return true;
    }

    const date = this.getTokenExpirationDate(token);
    if (date === undefined) {
      return false;
    }

    return !(date.valueOf() > new Date().valueOf());
  }

  isUserLoggedIn() {
    const token = this.getAuthorizationToken();
    if (!token) {
      return false;
    }

    return true;
  }

  refreshToken() {
    let data = `refresh_token=${this.getRefreshToken()}&grant_type=refresh_token&client_id=${environment.client_id}`;
    return this.http.post<any>(`${environment.api}/oauth2/token`, data).pipe(tap((tokens: any) => {
      localStorage.setItem(this.JWT_TOKEN, tokens.access_token);
      localStorage.setItem(this.REFRESH_TOKEN, tokens.refresh_token);
    }));
  }

  getPayloadToken() {
    const token = this.getAuthorizationToken();
    if (token) {
      return jwt_decode(token);
    }
  }

  getUsuarioLogado() {
    const token = this.getAuthorizationToken();
    if (token) {
      const payload: any = jwt_decode(token);
      if (payload.NomePessoaUsuario) {
        return payload.NomePessoaUsuario;
      }
    }
    return "";
  }

  getRoles() {
    const token = this.getAuthorizationToken();
    if (token) {
      const payload: any = jwt_decode(token);
      if (payload.role) {
        return payload.role;
      }
    }
    return [];
  }

  checkRole(role: string) {

    if (!this.roles || this.roles.length == 0) {
      this.roles = this.getRoles();
    }
    if (this.roles && this.roles.length > 0 && this.roles.some(a => a === role)) {
      return true;
    }

    else return false;
  }

  getMenuPrincipal(): ItemMenu[] {
    let itens: ItemMenu[] = [];
    this.roles = [];

    //Menu Painel Gerencial
    itens.push(new ItemMenu("Painel Gerencial", "dashboard", "", "", "painel_gerencial", []));

    //Menu Criadores
    if (this.checkRole(RolesKeys.Admin) || this.checkRole(RolesKeys.Criador)) {
      let subMenuCriadores: ItemMenu[] = [];
      if (this.checkRole(RolesKeys.Admin)) {
        subMenuCriadores.push(new ItemMenu("Registrar Criador", "arrow_right", "", "", ConstantesRotas.CRIADOR_MANTER, []));
      }

      if (this.checkRole(RolesKeys.InspecaoApropriarTecnico)) {
        subMenuCriadores.push(new ItemMenu("Liberar Procurador", "arrow_right", "", "", "criador/apropriar_procurador", []));
      }

      subMenuCriadores.push(new ItemMenu("Consultar", "arrow_right", "", "", "criadores_consultar", []));
      itens.push(new ItemMenu("Criadores", "", "criador", "menu_ciadores", "", subMenuCriadores));
    }

    //Menu Usuários
    if (this.checkRole(RolesKeys.Admin) || this.checkRole(RolesKeys.Usuario)) {
      let subMenuUsuarios: ItemMenu[] = [];
      if (this.checkRole(RolesKeys.Admin)) {
        subMenuUsuarios.push(new ItemMenu("Registrar Usuário", "arrow_right", "", "", "registrar_usuario", []));
      }
      subMenuUsuarios.push(new ItemMenu("Consultar", "arrow_right", "", "", "usuario_consultar", []));
      itens.push(new ItemMenu("Usuários", "groups", "", "menu_usuarios", "", subMenuUsuarios));
    }

    //Menu Exposições
    if (this.checkRole(RolesKeys.Admin) || this.checkRole(RolesKeys.Exposicao)) {
      let subMenuExposicao: ItemMenu[] = [];
      if (this.checkRole(RolesKeys.Admin)) {
        subMenuExposicao.push(new ItemMenu("Registrar Exposição", "arrow_right", "", "", "registrar_exposicao", []));
      }
      subMenuExposicao.push(new ItemMenu("Consultar", "arrow_right", "", "", "exposicao_consultar", []));
      itens.push(new ItemMenu("Exposições", "emoji_events", "", "menu_exposicoes", "", subMenuExposicao));
    }

    //Menu Controle Leiteiro
    if ((environment as any).contexto === 'jersey' && this.checkRole(RolesKeys.SuperAdmin)) {
      let subMenuControleLeiteiro: ItemMenu[] = [];
      subMenuControleLeiteiro.push(new ItemMenu(`Registrar Controle Leiteiro`, "arrow_right", "", "", "registrar_controle_leiteiro", []));
      subMenuControleLeiteiro.push(new ItemMenu("Consultar", "arrow_right", "", "", "controle_leiteiro_consultar", []));
      itens.push(new ItemMenu(`Controle Leiteiro`, "", "analise_leite", "menu_contro_leiteiro", "", subMenuControleLeiteiro));
    }

    //Menu Controle de Carcaça
    if ((environment as any).contexto === 'wagyu' && this.checkRole(RolesKeys.AbateComAvalCarcaca)) {
      let subMenuControleCarcaca: ItemMenu[] = [];
      if (this.checkRole(RolesKeys.AbateComAvalCarcacaRegistrar)) {
        subMenuControleCarcaca.push(new ItemMenu(`Registrar Pré-Abate`, "arrow_right", "", "", "abatecomavalcarcaca_manter", []));
      }
      if (this.checkRole(RolesKeys.AbateComAvalCarcacaRegistrarPos)) {
        subMenuControleCarcaca.push(new ItemMenu("Registrar Pós-Abate", "arrow_right", "", "", "abatecomavalcarcaca_manter_pos", []));
      }
      subMenuControleCarcaca.push(new ItemMenu("Consultar", "arrow_right", "", "", "abatecomavalcarcaca_consultar", []));
      if (this.checkRole(RolesKeys.InspecaoApropriarTecnico)) {
        subMenuControleCarcaca.push(new ItemMenu("Liberar Técnico", "arrow_right", "", "", "abate_carcaca/apropriar_tecnico", []));
      }
      itens.push(new ItemMenu(`Controle Carcaça`, "", "controle_carcaca", "menu_contro_carcaca", "", subMenuControleCarcaca));
    }

    //Menu Bovino
    if (this.checkRole(RolesKeys.Plantel)) {
      let subMenuBovino: ItemMenu[] = [];
      subMenuBovino.push(new ItemMenu("Plantel", "arrow_right", "", "", "plantel_consultar", []));
      if (this.checkRole(RolesKeys.SuperAdmin) || (this.contexto === 'wagyu' && this.checkRole(RolesKeys.PlantelEdit))) {
        if (this.contexto === 'jersey')
          subMenuBovino.push(new ItemMenu("Registros Reservados", "arrow_right", "", "", "registros_reservados", []));
        subMenuBovino.push(new ItemMenu("Cadastro de Bovino", "arrow_right", "", "", "bovino_manter", []));
      }
      itens.push(new ItemMenu("Bovino", "", "plantel", "menu_bovino", "", subMenuBovino));
    }

    //Menu Certificado
    if (this.checkRole(RolesKeys.Certificado)) {
      let subMenuCertificado: ItemMenu[] = [];
      if (this.checkRole(RolesKeys.CertificadoAssinar)) {
        subMenuCertificado.push(new ItemMenu("Assinar", "arrow_right", "", "", "assinar_certificado", []));
      }
      if (this.checkRole(RolesKeys.CertificadoConsultar)) {
        subMenuCertificado.push(new ItemMenu("Consultar", "arrow_right", "", "", "certificados_consultar", []));
      }
      if (this.checkRole(RolesKeys.Admin)) {
        subMenuCertificado.push(new ItemMenu("Pendentes de Emissão", "arrow_right", "", "", "pendentes_de_emissao", []));
      }
      itens.push(new ItemMenu("Certificados", "", "certificado", "menu_certificado", "", subMenuCertificado));
    }

    if ((environment as any).contexto === 'wagyu' && this.checkRole(RolesKeys.LaudoDNA)) {
      let subMenuLaudoDNA: ItemMenu[] = [];
      if (this.checkRole(RolesKeys.LaudoDNARegistrar)) {
        subMenuLaudoDNA.push(new ItemMenu("Registrar Laudo DNA", "arrow_right", "", "", "laudo_dna_manter", []));
      }
      subMenuLaudoDNA.push(new ItemMenu("Consultar", "arrow_right", "", "", "laudo_dna_consultar", []));
      itens.push(new ItemMenu("Laudo DNA", "", "dna", "menu_laudo_dna", "", subMenuLaudoDNA));
    }

    //Menu Padreacao
    if (this.checkRole(RolesKeys.Padreacao)) {
      let subMenuPadreacao: ItemMenu[] = [];
      const label = this.contexto === 'jersey' ? 'Padreação' : 'Cobertura';

      if (this.checkRole(RolesKeys.PadreacaoRegistrar)) {
        subMenuPadreacao.push(new ItemMenu(`Registrar ${label}`, "arrow_right", "", "", "padreacao_manter", []));
      }
      subMenuPadreacao.push(new ItemMenu("Consultar", "arrow_right", "", "", "padreacao_consultar", []));
      itens.push(new ItemMenu(label, "", "padreacao", "menu_padreacao", "", subMenuPadreacao));
    }

    //Menu Nascimento
    if (this.checkRole(RolesKeys.Nascimento)) {
      let subMenuNascimento: ItemMenu[] = [];
      if (this.checkRole(RolesKeys.NascimentoRegistrar)) {
        subMenuNascimento.push(new ItemMenu("Registrar Nascimento", "arrow_right", "", "", "nascimento_manter", []));
      }
      subMenuNascimento.push(new ItemMenu("Consultar", "arrow_right", "", "", "nascimento_consultar", []));
      itens.push(new ItemMenu("Nascimento", "", "bovino", "menu_nascimento", "", subMenuNascimento));
    }

    //Menu TE / FIV
    if (this.checkRole(RolesKeys.Fiv)) {
      let subMenuTeFiv: ItemMenu[] = [];
      if (this.checkRole(RolesKeys.FivRegistrar)) {
        subMenuTeFiv.push(new ItemMenu("Registrar TE / FIV", "arrow_right", "", "", "fiv_manter", []));
      }
      subMenuTeFiv.push(new ItemMenu("Consultar", "arrow_right", "", "", "fiv_consultar", []));

      if (this.checkRole(RolesKeys.InspecaoApropriarTecnico)) {
        subMenuTeFiv.push(new ItemMenu("Liberar Veterinário", "arrow_right", "", "", "fiv/apropriar_veterinario", []));
      }
      itens.push(new ItemMenu("TE / FIV", "", "fiv", "menu_fiv", "", subMenuTeFiv));
    }

    //Menu Inovulação
    if (this.checkRole(RolesKeys.Inovulacao)) {
      let subMenuInovulacao: ItemMenu[] = [];
      const label = this.contexto === 'jersey' ? 'Inovulação' : 'Implantação';

      if (this.checkRole(RolesKeys.InovulacaoRegistrar)) {
        subMenuInovulacao.push(new ItemMenu(`Registrar ${label}`, "arrow_right", "", "", "inovulacao_manter", []));
      }
      subMenuInovulacao.push(new ItemMenu("Consultar", "arrow_right", "", "", "inovulacao_consultar", []));
      itens.push(new ItemMenu(label, "", "inovulacao", "menu_inovulacao", "", subMenuInovulacao));
    }

    //Menu Baixa
    if (this.checkRole(RolesKeys.Baixa)) {
      let subMenuBaixa: ItemMenu[] = [];
      if (this.checkRole(RolesKeys.BaixaRegistrar)) {
        subMenuBaixa.push(new ItemMenu("Registrar Baixa", "arrow_right", "", "", "baixa_manter", []));
      }
      subMenuBaixa.push(new ItemMenu("Consultar", "arrow_right", "", "", "baixa_consultar", []));
      itens.push(new ItemMenu("Baixa", "", "baixa", "menu_Baixa", "", subMenuBaixa));
    }

    //Menu Transferência
    if (this.checkRole(RolesKeys.Transferencia)) {
      let subMenuTransferencia: ItemMenu[] = [];
      if (this.checkRole(RolesKeys.TransferenciaRegistrar)) {
        subMenuTransferencia.push(new ItemMenu("Registrar Transferência", "arrow_right", "", "", "transferencia_manter", []));
      }
      subMenuTransferencia.push(new ItemMenu("Consultar", "arrow_right", "", "", "transferencia_consultar", []));
      itens.push(new ItemMenu("Transferência", "swap_horiz", "", "menu_transferencia", "", subMenuTransferencia));
    }

    //Menu Inspeção Jersey
    if (this.contexto === 'jersey' && this.checkRole(RolesKeys.Inspecao)) {
      let subMenuInspecao: ItemMenu[] = [];
      if (this.checkRole(RolesKeys.InspecaoRegistrar)) {
        subMenuInspecao.push(new ItemMenu("Registrar Inspeção", "arrow_right", "", "", "inspecao_manter", []));
      }
      subMenuInspecao.push(new ItemMenu("Consultar", "arrow_right", "", "", "inspecao_consultar", []));
      itens.push(new ItemMenu("Inspeção", "", "inspecao", "menu_inspecao", "", subMenuInspecao));
    }

    //Meny Inspeção Wagyu
    if (this.contexto === 'wagyu') {
      let subMenuInspecao: ItemMenu[] = [];
      if (this.checkRole(RolesKeys.Inspecao)) {
        if (this.checkRole(RolesKeys.InspecaoRegistrar)) {
          subMenuInspecao.push(new ItemMenu("Registrar Inspeção", "arrow_right", "", "", "inspecao_manter", []));
        }
        subMenuInspecao.push(new ItemMenu("Consultar", "arrow_right", "", "", "inspecao_consultar", []));
        subMenuInspecao.push(new ItemMenu("Relatório", "arrow_right", "", "", "inspecao_relatorio", []));
      }
      if (this.checkRole(RolesKeys.InspecaoApropriarTecnico)) {
        subMenuInspecao.push(new ItemMenu("Liberar Técnico", "arrow_right", "", "", "inspecao/apropriar_tecnico", []));
      }

      if (subMenuInspecao.length !== 0)
        itens.push(new ItemMenu("Inspeção", "", "inspecao", "menu_inspecao", "", subMenuInspecao));
    }

    //Menu Classificação Linear
    if (this.checkRole(RolesKeys.ClassificacaoLinear)) {
      let subMenuClassificacaoLinear: ItemMenu[] = [];
      if (this.checkRole(RolesKeys.ClassificacaoLinearRegistrar)) {
        subMenuClassificacaoLinear.push(new ItemMenu("Registrar Classificação Linear", "arrow_right", "", "", "classificacaolinear_manter", []));
      }
      subMenuClassificacaoLinear.push(new ItemMenu("Consultar", "arrow_right", "", "", "classificacaolinear_consultar", []));
      itens.push(new ItemMenu("Classificação Linear", "account_tree", "", "menu_classificacao_linear", "", subMenuClassificacaoLinear));
    }

    //Menu Nacionalizacao
    if (this.checkRole(RolesKeys.Nacionalizacao)) {
      let subMenuNacionalizacao: ItemMenu[] = [];
      let subMenuNacionalizacaoSemen: ItemMenu[] = [];
      let subMenuNacionalizacaoEmbriao: ItemMenu[] = [];

      if (this.checkRole(RolesKeys.NacionalizacaoRegistrar)) {
        subMenuNacionalizacaoSemen.push(new ItemMenu("Registrar Nacionalização", "arrow_right", "", "", "nacionalizacao_manter", []));
      }
      subMenuNacionalizacaoSemen.push(new ItemMenu("Consultar", "arrow_right", "", "", "nacionalizacao_consultar", []));
      subMenuNacionalizacao.push(new ItemMenu("Sêmen", "arrow_right", "", "menu_nacionalizacao_semen", "", subMenuNacionalizacaoSemen));

      if (this.checkRole(RolesKeys.NacionalizacaoEmbriaoRegistrar)) {
        subMenuNacionalizacaoEmbriao.push(new ItemMenu("Registrar Nacionalização", "arrow_right", "", "", "nacionalizacaoembriao_manter", []));
      }
      subMenuNacionalizacaoEmbriao.push(new ItemMenu("Consultar", "arrow_right", "", "", "nacionalizacaoembriao_consultar", []));
      subMenuNacionalizacao.push(new ItemMenu("Embrião", "arrow_right", "", "menu_nacionalizacao_embriao", "", subMenuNacionalizacaoEmbriao));

      itens.push(new ItemMenu("Nacionalização", "", "nacionalizacao", "menu_nacionalizacao", "", subMenuNacionalizacao));
    }

    //Menu Pesagem
    if ((environment as any).contexto === 'wagyu' && this.checkRole(RolesKeys.Pesagem)) {
      let subMenuPesagem: ItemMenu[] = [];
      if (this.checkRole(RolesKeys.PesagemRegistrar)) {
        subMenuPesagem.push(new ItemMenu("Registrar Pesagem", "arrow_right", "", "", "pesagem_manter", []));
      }
      subMenuPesagem.push(new ItemMenu("Consultar", "arrow_right", "", "", "pesagem_consultar", []));

      itens.push(new ItemMenu("Pesagem", "", "balanca", "menu_pesagem", "", subMenuPesagem));
    }

    //Menu E-mail
    if (this.checkRole(RolesKeys.Admin)) {
      let subMenuEmail: ItemMenu[] = [];
      subMenuEmail.push(new ItemMenu("Enviar E-mail", "arrow_right", "", "", "enviar_email", []));
      itens.push(new ItemMenu("E-mail", "forward_to_inbox", "", "menu_email", "", subMenuEmail));
    }

    //Menu Configurações
    if (this.checkRole(RolesKeys.Admin)) {
      let subMenuConfiguracoes: ItemMenu[] = [];
      subMenuConfiguracoes.push(new ItemMenu("Integradores", "arrow_right", "", "", "integradores", []));
      itens.push(new ItemMenu("Configurações", "settings", "", "menu_configuracoes", "", subMenuConfiguracoes));
    }

    //Menu Relatórios
    if (this.checkRole(RolesKeys.Admin)) {
      let subRelatorios: ItemMenu[] = [];
      subRelatorios.push(new ItemMenu("Serviços Aprovados", "arrow_right", "", "", "servicos_aprovados", []));
      itens.push(new ItemMenu("Relatórios", "picture_as_pdf", "", "menu_relatorios", "", subRelatorios));
    }

    return itens;
  }

}

export class ItemMenu {
  label: string = "";
  materialIcon: string = "";
  svgIcon: string = "";
  idCollapseLayout: string = "";
  rota: string = "";
  subItens: ItemMenu[] = [];

  constructor(label: string, materialIcon: string, svgIcon: string, idCollapseLayout: string, rota: string, subItens: ItemMenu[]) {
    this.label = label;
    this.materialIcon = materialIcon;
    this.svgIcon = svgIcon;
    this.idCollapseLayout = idCollapseLayout;
    this.rota = rota;
    this.subItens = subItens;
  }
}

