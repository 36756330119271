export class ConsultaRapidaCriadorModel {
  Id: string;
  CodigoCriador: string;
  NomeCriador: string;
  AfixoCriador: string;
  ExibeAfixo: boolean;
  TituloConsulta: string;
  Prefix: string;
  VerificarOcorrenciaEmElaboracao: boolean;
  CallBackFunction: string;
  ConsultaEmTodasFiliadas: boolean;

  constructor() {
    this.Id = "-1";
    this.CodigoCriador = "";
    this.NomeCriador = "";
    this.AfixoCriador = "";
    this.ExibeAfixo = false;
    this.TituloConsulta = "";
    this.Prefix = "";
    this.VerificarOcorrenciaEmElaboracao = false;
    this.CallBackFunction = "";
    this.ConsultaEmTodasFiliadas = false;

  }

}