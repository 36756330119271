import { InspetorModel } from "./InspetorModel";
import { OcorrenciaEventoAdministrativoModel } from "./OcorrenciaEventoAdministrativoModel";

export class OcorrenciaEventoApropriacaoTecnicoModel extends OcorrenciaEventoAdministrativoModel {
  Inspetor: InspetorModel;
  PeriodoVigencia: { De: string; Ate: string; };
  GrupoUsuarioId: string;

  constructor() {
    super()

    this.PeriodoVigencia = {
      De: '',
      Ate: '',
    };

    this.Inspetor = new InspetorModel();

    this.GrupoUsuarioId = '';
  }
}