import { Component, OnInit } from '@angular/core';
import { ClasseClassificacaoLinear } from 'src/app/shared/enums/Enums';
import { ApiService } from 'src/app/shared/api.service';
import { BaseEventos } from 'src/app/eventos/base-eventos';
import { AccountService } from 'src/app/account/shared/account.service';
import { DatePipe } from '@angular/common';
import { Commons } from 'src/app/shared/commons';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConstantesTipoEvento } from 'src/app/shared/constantes';

@Component({
  selector: 'app-classificacao-linear-manter',
  templateUrl: './classificacao-linear-manter.component.html',
  styleUrls: ['./classificacao-linear-manter.component.scss'],
})
export class ClassificacaoLinearManterComponent extends BaseEventos implements OnInit {

  bloqInputsPorCriador: boolean = true;
  classes: { value: number, label: string }[] = [];

  constructor(
    public activatedRoute: ActivatedRoute,
    public accountService: AccountService,
    public api: ApiService,
    public datePipe: DatePipe,
    public commons: Commons,
    public router: Router,
    public dialog: MatDialog
  ) {
    super(api, datePipe, commons, router, accountService, dialog);
    this.tipoEvento = ConstantesTipoEvento.CLASSIFICAO_LINEAR;
  }

  ngOnInit(): void {
    this.novoEvento();
    this.activatedRoute.paramMap.subscribe(params => {
      this.iniciarTela(params);
    });

    this.preencherClasses();
    this.ocorrenciaAtual.Touro.Readonly = true;
    this.ocorrenciaAtual.Animal.Readonly = true;
    this.ocorrenciaAtual.Inspetor.readonly = true;
    this.ocorrenciaAtual.Touro.TituloConsulta = "Touro";
  }

  preencherFormulario(result: any, novoItem: boolean) {
    super.preencherFormulario(result, novoItem);
    this.ocorrenciaAtual.Inspetor.Id = this.ocorrenciaAtual.InspetorId;
    this.ocorrenciaAtual.Inspetor.NomeInspetor = this.ocorrenciaAtual.NomeInspetor;
    this.ocorrenciaAtual.Inspetor.readonly = this.ocorrenciaAtual.Inspetor.NomeInspetor || this.ocorrenciaAtual.Inspetor.Id;
  }

  animalSelecionado(_: any) {
    this.api.consultarDetalhesBovino(this.ocorrenciaAtual.Animal.Id).then((result: any) => {
      if (result && result.Status === 0) {
        this.ocorrenciaAtual.DataNascimento = result.Bovino.DataNascimento;
      }
    });
  }

  async criadorSelecionado(criador: any) {

    this.classes[0].label = !criador ? '' : 'Selecione...';
    super.criadorSelecionado(criador);
  }

  editar(evento: any) {
    super.editar(evento);
    this.ocorrenciaAtual.Inspetor.readonly = true;
    this.ocorrenciaAtual.Inspetor.Id = evento.InspetorId;
    this.ocorrenciaAtual.Inspetor.NomeInspetor = evento.NomeInspetor;
  }

  preencherClasses(): void {
    this.classes.push({ label: '', value: -1 });
    this.classes.push({ label: 'EX3', value: ClasseClassificacaoLinear.EX3 });
    this.classes.push({ label: 'EX2', value: ClasseClassificacaoLinear.EX2 });
    this.classes.push({ label: 'EX1', value: ClasseClassificacaoLinear.EX1 });
    this.classes.push({ label: 'MB', value: ClasseClassificacaoLinear.MB });
    this.classes.push({ label: 'B+', value: ClasseClassificacaoLinear['B+'] });
    this.classes.push({ label: 'B', value: ClasseClassificacaoLinear.B });
    this.classes.push({ label: 'R', value: ClasseClassificacaoLinear.R });
    this.classes.push({ label: 'P', value: ClasseClassificacaoLinear.P });
  }

}
