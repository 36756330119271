import { BovinoModel } from "./BovinoModel";
import { CriadorModel } from "./CriadorModel";

export class FiltroConsultaNacionalizacaoModel{
  formularioProtocolo: string;
  situacao: number;
  eventoDe: string;
  eventoAte: string;
  criador: CriadorModel;
  bovino: BovinoModel;

  constructor(){
    this.formularioProtocolo = "";
    this.situacao = 0;
    this.eventoDe = '';
    this.eventoAte = '';
    this.criador = new CriadorModel();
    this.bovino = new BovinoModel();
  }
}
