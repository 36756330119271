<div class="row">

  <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
    <label>*Codigo</label>
    <input [disabled]="readOnly" name="codigoProcurador" type="text" class="form-control form-control-sm" [(ngModel)]="procuradorModel.Usuario.codigo" readonly >
  </div>

  <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
    <label>*Nome</label>
    <input [disabled]="readOnly" name="nomeProcurador" type="text" class="form-control form-control-sm" [(ngModel)]="procuradorModel.Usuario.nome">
  </div>

  <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
    <label>*CPF</label>
    <input [disabled]="readOnly" name="nome" type="text" class="form-control form-control-sm"  [(ngModel)]="procuradorModel.CPF.cpf" mask="000.000.000-00">
  </div>

  <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
    <label>*E-mail</label>
    <input [disabled]="readOnly" name="emailProcurador" type="text" class="form-control form-control-sm" [(ngModel)]="procuradorModel.Usuario.email" >
  </div>

  <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
    <label>*Usuário</label>
    <input [disabled]="readOnly" name="usuarioProcurador" type="text" class="form-control form-control-sm" [(ngModel)]="procuradorModel.Login" >
  </div>

  <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
    <label>Situação</label>
    <select [disabled]="readOnly" class="form-control form-control-sm" name="situacao" [(ngModel)]="procuradorModel.Usuario.situacao">
      <option value="1" label="Ativo"></option>
      <option value="2" label="Inativo"></option>
    </select>
  </div>

  <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
    <label for="formularioProtocolo">Celular</label>
    <select class="form-control form-control-sm" name="celular" disabled>
      <option value="celular" label="Celular"></option>
    </select>
  </div>

  <div class="form-group input-sem-label col-lg-1 col-md-2 col-sm-2 col-4">
    <br>
    <input
      [disabled]="readOnly"
      type="text"
      mask="(00)"
      name="celular_ddd"
      placeholder="(DDD)"
      [(ngModel)]="procuradorModel.Usuario.celular.ddd"
      class="form-control form-control-sm "
    >
  </div>

  <div class="form-group input-sem-label telefone-container col-lg-2 col-md-4 col-sm-4 col-8">
    <br>
    <input
      [disabled]="readOnly"
      type="text"
      mask="0000-0000"
      placeholder="(número)"
      name="celular_numero"
      [(ngModel)]="procuradorModel.Usuario.celular.numero"
      class="form-control form-control-sm "
    >
  </div>
</div>

<div class="row justify-content-end pt-2 pb-4">
  <div  class="col-lg-2 col-md-4 col-sm-6 pt-3">
    <button [disabled]="readOnly" type="button" class="btn btn-sm btn-block btn-primary" (click)="alterarProcurador()">
      Salvar
    </button>
  </div>
</div>

<div class="table-responsive">
  <table class="table table-hover table-header-custom">
    <thead>
      <tr>
        <th>Codigo</th>
        <th>Login</th>
        <th>Nome</th>
        <th>CPF</th>
        <th>Situação</th>
        <th width="10%"></th>
      </tr>
    </thead>

    <tbody *ngIf="procuradorModel.Procuradores.length > 0">
      <tr *ngFor="let procurador of procuradorModel.Procuradores">
        <td>{{procurador.Usuario.codigo}}</td>
        <td>{{procurador.Login}}</td>
        <td>{{procurador.Usuario.nome}}</td>
        <td>{{procurador.CPF.cpf}}</td>
        <td>{{ commons.getDescricaoSituacaoCriador(procurador.Usuario.situacao) }}</td>
        <td>
          <a type="button" *ngIf="!readOnly && permiteEditar" matTooltip="Reiniciar senha" (click)="botaReiniciarSenha(procurador.Usuario.usuario)">
            <i class="action-icons fas fa-key"></i>
          </a>
          <a type="button" *ngIf="(!readOnly && permiteEditar) || (contexto === 'wagyu' && account.isCriador)" matTooltip="Editar" (click)="recuperaProcuradorDaLista(procurador)">
            <i class="action-icons fas fa-edit"></i>
          </a>
        </td>
      </tr>
    </tbody>
    <tbody *ngIf="procuradorModel.Procuradores.length === 0">
      <tr class="text-center">
        <td colspan="6">
          <strong>Sem itens para exibição.</strong>
        </td>
      </tr>
    </tbody>

  </table>
</div>
