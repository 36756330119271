<div class="row">
  <div class="form-group col-lg-4 col-md-4 col-sm-12">
    <label for="codigoCriador">Código {{criador.tituloConsulta}} {{identificacaoFrigorifico}}</label>
    <input *ngIf="criador.readonly" type="text" class="form-control form-control-sm" name="codigoCriador" [(ngModel)]="criador.codigo" readonly>

    <div *ngIf="!criador.readonly" class="input-group input-group-sm">
      <input type="text" class="form-control" name="codigoCriador" [(ngModel)]="criador.codigo" autocomplete="off" (keydown.enter)="consultarPorCodigo()">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" type="button" (click)="consultarPorCodigo()"> <i class="bi bi-check"></i> </button>
      </div>
    </div>
  </div>

  <div class="form-group col-lg-8 col-md-8 col-sm-12">
    <label for="nomeCriador">Nome {{criador.tituloConsulta}} {{identificacaoFrigorifico}}</label>
    <input *ngIf="criador.readonly" type="text" class="form-control form-control-sm" name="nomeCriador" [(ngModel)]="criador.nome" readonly>

    <div *ngIf="!criador.readonly" class="input-group input-group-sm">
      <input type="text" class="form-control" name="nomeCriador" [(ngModel)]="criador.nome" readonly>
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" type="button" (click)="consultarPorNome()"> <i class="bi bi-search"></i> </button>
        <button class="btn btn-outline-secondary" type="button" (click)="limpar()"> <i class="bi bi-eraser"></i> </button>
      </div>
    </div>
  </div>
</div>