import { AvaliacaoReprodutivaInspecao, Castrado, DesenvolvimentoInspecao, ManchaPeleInspecao, PossuiChifre, Sexo, TipoBuscaBovino, TipoPelagemInspecao } from "../shared/enums/Enums";
import { BovinoModel } from "./BovinoModel";
import { ConsultaRapidaBovinoModel } from "./ConsultaRapidaBovinoModel";
import { InspetorModel } from "./InspetorModel";
import { OcorrenciaEventoModel } from "./OcorrenciaEventoModel";
import { environment } from 'src/environments/environment';

export class OcorrenciaEventoInspecaoModel extends OcorrenciaEventoModel {

    Inspetor: InspetorModel;
    OrelhaEsquerda: string;
    OrelhaDireita: string;
    DataInspecao: string;
    TipoInspecao: number;
    AptoRegistro: number;
    Destino: number;
    Peso: number;
    Brinco: number | string;

    PossuiChifre: number;
    Pelagem: number;
    ManchaPele: number;
    Desenvolvimento: number;
    Castrado: number;
    AvaliacaoReprodutiva: number;

    GrauSangue: string;
    TipoGrauSangue: string | number;
    NomeInspetor: string;

    SituacaoSrg: number;
    DataNascimento: string;
    Sexo: number;

    Mae: ConsultaRapidaBovinoModel;
    Pai: ConsultaRapidaBovinoModel;
    Animal: BovinoModel;

    InspetorId: string;

    Readonly: boolean;
    isCastrado: boolean;

    IsAprovarTodos: boolean;
    Raca: string;

    Genotipado: number;
    constructor() {
        super();

        this.Inspetor = new InspetorModel();
        this.OrelhaEsquerda = '';
        this.OrelhaDireita = '';
        this.DataInspecao = '';
        this.TipoInspecao = -1;
        this.AptoRegistro = -1;
        this.Destino = 1;
        this.Peso = 0;
        this.Brinco = '';

        this.PossuiChifre = PossuiChifre.None;
        this.Pelagem = TipoPelagemInspecao.None;
        this.ManchaPele = ManchaPeleInspecao.None;
        this.Desenvolvimento = DesenvolvimentoInspecao.None;
        this.Castrado = Castrado.None;
        this.AvaliacaoReprodutiva = AvaliacaoReprodutivaInspecao.None;

        this.TipoGrauSangue = -1;
        this.GrauSangue = '-1';
        this.NomeInspetor = '';

        this.SituacaoSrg = 1;
        this.DataNascimento = '';
        this.Sexo = -1;

        this.Mae = new ConsultaRapidaBovinoModel();
        this.Pai = new ConsultaRapidaBovinoModel();
        this.Animal = new BovinoModel();

        this.Mae.TituloConsulta = "Mãe";
        this.Mae.TipoBusca = TipoBuscaBovino.Matriz;
        this.Mae.SexoBovino = Sexo.Fêmea;
        this.Mae.ConsideraInativos = true;
        this.Mae.ConsultaAnimalTodosCriadores = true;

        this.Pai.TituloConsulta = "Pai";
        this.Pai.TipoBusca = TipoBuscaBovino.Reprodutor;
        this.Pai.SexoBovino = Sexo.Macho;
        this.Pai.ConsideraInativos = false;
        this.Pai.ConsultaAnimalTodosCriadores = true;

        this.InspetorId = '';

        this.Readonly = false;
        this.isCastrado = false;

        this.IsAprovarTodos = false;

        if (environment.raca == 'kuroge') {
            this.Raca = 'WAGYU_KUROGE';
        } else if (environment.raca == 'akaushi') {
            this.Raca = 'WAGYU_AKAUSHI';
        } else {
            this.Raca = '-1';
        }
        this.Genotipado = 2;
    }
}