export class TecnicoModel {
    Id: string;
    Codigo: string;
    readonly: boolean;
    NomeTecnico: string;
  
    constructor() {
      this.Id = "";
      this.Codigo = "";
      this.readonly = false;
      this.NomeTecnico = "";
    }
  }