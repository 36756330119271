<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <i class="bi bi-x" color="primary"></i>
</button>

<h2 mat-dialog-title>Consultar Inspetor</h2>

<div class="row">

  <div class="form-group col-lg-3 col-md-3 col-sm-12">
    <label for="codigoInspetor">Código</label>
    <input type="text" class="form-control form-control-sm" id="codigoInspetor" name="codigoInspetor" autocomplete="off" [(ngModel)]="formConsulta.Codigo" (keydown.enter)="botaoBuscar()">
  </div>

  <div class="form-group col-lg-6 col-md-6 col-sm-12">
    <label for="nomeInspetor">Nome</label>
    <input appAutoFocus type="text" class="form-control form-control-sm" name="nomeInspetor" autocomplete="off" [(ngModel)]="formConsulta.NomeInspetor" (keydown.enter)="botaoBuscar()">
  </div>

  <div class="form-group col-lg-3 col-md-3 col-sm-12">
    <label for="consulta">&nbsp;</label>
    <button type="button" class="btn btn-sm btn-primary form-control form-control-sm" name="consulta" (click)="botaoBuscar()">Buscar</button>
  </div>

</div>

<div class="row" *ngIf="inspetores.length > 0">
  <div class="col-lg-12">
    <table class="table table-hover table-header-custom">
      <thead>
        <tr>
          <th>Código</th>
          <th>Nome</th> 
          <th class="text-center">Selecionar</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let inspetor of inspetores" style="cursor: pointer;" (click)="selecionarInspetor(inspetor)" >
          <td>{{ inspetor.Codigo }}</td>
          <td>{{ inspetor.NomeInspetor }}</td>
          <td class="text-center"><a><i class="bi bi-check icone-selecao"></i></a></td>
        </tr>
      </tbody>
    </table>
  </div>
  
</div>


 