<app-formulario-tabs [formulario]="formulario" [listaComunicacao]="lote?.ListaComunicacao" [ocorrenciaAtualPosAbate]="lote?.Ocorrencias" [tipoEvento]="tipoEventoPos"
  (tabSelecionada)="tabSelecionada($event)" (criadorSelecionado)="criadorSelecionado($event)" (criadorSelecionado)="criadorSelecionadoPosAbate($event)"></app-formulario-tabs>

<div class="row">

  <div class="col-lg-12 col-sm-12">
    <div class="card card-principal-evento">
      <div class="card-body">

        <!--Ocorrencia Atual-->
        <div *ngIf="!isNewLote()">
          <div class="row">
            <div class="col-lg-12">
              <div class="card card-evento">
                <div class="card-body">

                  <div class="row card-title-evento">
                    <div class="col-3">
                      <h4 class="card-title">
                        <mat-icon svgIcon="baixa"></mat-icon> Dados da Baixa
                      </h4>
                    </div>
                    <div class="col-6 text-right">
                      <button *ngIf="ocorrenciaAtual.Id !== '-1'" type="button" class="btn btn-link btn-sm"
                        (click)="documentosDaOcorrencia()"><i class="far fa-folder"></i> Documentos</button>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-2">
                      <label>Possui Pré-Abate?</label>
                      <select class="form-control form-control-sm" [(ngModel)]="ocorrenciaAtual.PossuiPreAbate" [disabled]="false" (ngModelChange)="showInputProtocolo()">
                        <option value="1">Sim</option>
                        <option value="2">Não</option>
                      </select>
                    </div>
                    <div class="col-3" *ngIf="showInputProtocolo()">
                      <label for="protocolo">Protocolo do Pré-Abate</label>
                      <div class="input-group input-group-sm">
                        <input type="text" class="form-control form-control-sm" (keydown.enter)="consultarProtocolo()" [(ngModel)]="ocorrenciaAtual.PreAbate">
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary" type="button"> <i class="bi bi-check" (click)="consultarProtocolo()"></i> </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-6" *ngIf="showFrigorificoAbate()">
                      <app-consultar-criador
                      [criador]="ocorrenciaAtual.FrigorificoAbate"
                      [criadorFrigorifico]="ocorrenciaAtual.FrigorificoAbate"
                      [apenasFrigorifico]="true"
                      (criadorSelecionado)="frigorificoAbateSelecionado($event)"
                      [identificacaoFrigorifico]="Abate"
                      >
                      </app-consultar-criador>
                    </div>

                    <div class="col-lg-3 col-md-6" *ngIf="ocorrenciaAtual.TipoMotivoBaixa == 55">
                      <label for="PesoVivo">*Quantidade de Selos</label>
                      <div class="input-group">
                        <input class="form-control form-control-sm" name="QuantidadeSelos" type="number" [(ngModel)]="ocorrenciaAtual.QuantidadeSelos" />
                        <!-- [disabled]="bloqueiaQuantidadeSelos()" -->
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="form-group col-lg-2 col-md-4 col-sm-6 col-xs-12">
                      <label for="CruzadoCadastrado">Animal Cruzado?</label>
                        <select class="form-control form-control-sm" name="CruzadoCadastrado" [(ngModel)]="ocorrenciaAtual.CruzadoCadastrado" (change)="animalCruzadoSelecionado()" (ngModelChange)="showInputProtocolo()">
                          <option value="1">Sim</option>
                          <option value="2">Não</option>
                        </select>
                    </div>

                    <div *ngIf="ocorrenciaAtual.CruzadoCadastrado == 2" class="col-lg-10 col-md-8 col-sm-6 col-xs-12">
                      <app-consultar-bovino
                      [ocorrenciaAtualSituacao]="ocorrenciaAtual.Situacao"
                      [bovino]="ocorrenciaAtual.Animal">
                      </app-consultar-bovino>
                    </div>

                    <div *ngIf="ocorrenciaAtual.CruzadoCadastrado == 1" class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
                      <label for="CodigoAnimalCruzado">*Código Animal Cruzado </label>
                      <input type="text" [required]="ocorrenciaAtual.CruzadoCadastrado == 1" class="form-control form-control-sm" name="CodigoAnimalCruzado" [(ngModel)]="ocorrenciaAtual.CodigoAnimalCruzado">
                    </div>

                    <div *ngIf="ocorrenciaAtual.CruzadoCadastrado == 1" class="form-group col-lg-7 col-md-4 col-sm-6 col-xs-12">
                      <label for="NomeAnimalCruzado">*Nome Animal Cruzado</label>
                      <input type="text" [required]="ocorrenciaAtual.CruzadoCadastrado == 1" class="form-control form-control-sm" name="NomeAnimalCruzado" [(ngModel)]="ocorrenciaAtual.NomeAnimalCruzado">
                    </div>

                  </div>

                  <div class="row align-items-end">

                    <div class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
                      <label for="DataOcorrencia">*Data Abate</label>
                      <div class="input-group input-group-sm">
                        <input class="form-control" [max]="maxDate" [matDatepicker]="dataAbate" name="DataOcorrencia"
                          [(ngModel)]="ocorrenciaAtual.DataAbate" [disabled]="isOcorrenciaReadOnly() || showInputProtocolo()">
                        <div *ngIf="!isOcorrenciaReadOnly()" class="input-group-append">
                          <button class="btn btn-outline-secondary" type="button" (click)="dataAbate.open()"> <i
                              class="bi bi-calendar3"></i> </button>
                        </div>
                      </div>
                      <mat-datepicker [startAt]="ocorrenciaAtual.DataAbate" #dataAbate></mat-datepicker>
                    </div>

                    <div class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
                      <label for="DataOcorrencia">*Data de Desossa</label>
                      <div class="input-group input-group-sm">
                        <input class="form-control" [max]="maxDate" [matDatepicker]="dataBaixa" name="DataOcorrencia"
                          [(ngModel)]="ocorrenciaAtual.DataBaixa" [disabled]="isOcorrenciaReadOnly()">
                        <div *ngIf="!isOcorrenciaReadOnly()" class="input-group-append">
                          <button class="btn btn-outline-secondary" type="button" (click)="dataBaixa.open()"> <i
                              class="bi bi-calendar3"></i> </button>
                        </div>
                      </div>
                      <mat-datepicker [startAt]="ocorrenciaAtual.DataBaixa" #dataBaixa></mat-datepicker>
                    </div>

                    <div class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
                      <label for="TipoPadreacao">*Motivo</label>
                      <select [disabled]="true" class="form-control form-control-sm" name="Motivo"
                        [(ngModel)]="ocorrenciaAtual.TipoMotivoBaixa">
                        <option *ngFor="let motivo of motivos" [value]="motivo.value" [label]="motivo.label"></option>
                      </select>
                    </div>

                    <div *ngIf="env.contexto == 'wagyu' && ocorrenciaAtual.TipoMotivoBaixa == 52"
                      class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
                      <label for="TipoPadreacao">*Causa</label>
                      <select [disabled]="isOcorrenciaReadOnly()" class="form-control form-control-sm"
                        name="TipoPadreacao" [(ngModel)]="ocorrenciaAtual.MotivoMorte">
                        <option *ngFor="let causa of causasMorteNatural" [value]="causa.value" [label]="causa.label">
                        </option>
                      </select>
                    </div>

                    <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12" *ngIf="ocorrenciaAtual.TipoMotivoBaixa == 55">
                      <label for="PesoVivo">*Peso do Animal Vivo</label>
                      <div class="input-group">
                        <input class="form-control form-control-sm" name="PesoVivo" type="number" [(ngModel)]="ocorrenciaAtual.PesoVivo" />
                        <div class="input-group-append">
                          <span class="input-group-text form-control-sm offset-md-0">Kg</span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12" *ngIf="ocorrenciaAtual.TipoMotivoBaixa == 55">
                      <label for="PesoCarcaca">*Peso da Carcaça do Animal</label>
                      <div class="input-group">
                        <input class="form-control form-control-sm " name="PesoCarcaca" type="number" [(ngModel)]="ocorrenciaAtual.PesoCarcaca" />
                        <div class="input-group-append">
                          <span class="input-group-text form-control-sm offset-md-0">Kg</span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12" *ngIf="ocorrenciaAtual.TipoMotivoBaixa == 55">
                      <label for="Gordura">*Gordura</label>
                      <select class="form-control form-control-sm" name="Dentes"
                        [(ngModel)]="ocorrenciaAtual.Gordura">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </select>
                      <!--<div class="input-group">
                        <input class="form-control form-control-sm" name="Gordura" type="range" [(ngModel)]="ocorrenciaAtual.Gordura" min="1" max="5" step="0.1" (ngModelChange)="adaptaValor()"/>
                        <div class="input-group-append">
                          <span class="input-group-text form-control-sm offset-md-0">{{ocorrenciaAtual.Gordura}}</span>
                        </div>
                      </div>-->
                    </div>

                    <div class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12" *ngIf="ocorrenciaAtual.TipoMotivoBaixa == 55">
                      <label for="Dentes">*Número de dentes</label>
                      <select class="form-control form-control-sm" name="Dentes" [(ngModel)]="ocorrenciaAtual.NroDentes">
                        <option *ngFor="let dente of [1, 2, 3, 4, 5, 6, 7, 8].slice(0, ocorrenciaAtual.CruzadoCadastrado == 1 ? 7 : 8)" [value]="dente">
                          {{ dente }}
                        </option>
                      </select>
                    </div>

                    <div class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12" *ngIf="ocorrenciaAtual.TipoMotivoBaixa == 55">
                      <label for="Marmoreio">*Marmoreio</label>
                      <select class="form-control form-control-sm" name="Marmoreio"
                        [(ngModel)]="ocorrenciaAtual.Marmoreio">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="10">11</option>
                        <option value="10">12</option>
                      </select>
                    </div>

                    <div class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12" *ngIf="ocorrenciaAtual.TipoMotivoBaixa == 55">
                      <label for="OrdemRomaneio">*Ordem de Romaneio</label>
                      <input class="form-control form-control-sm " name="OrdemRomaneio" type="number" [(ngModel)]="ocorrenciaAtual.OrdemRomaneio" />
                    </div>

                    <div class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12" *ngIf="ocorrenciaAtual.TipoMotivoBaixa == 55">
                      <label for="CertificadoBaixa">*Certificado</label>
                      <select class="form-control form-control-sm" name="CertificadoBaixa" [(ngModel)]="ocorrenciaAtual.CertificadoBaixa">
                        <option value="1">Aprovado</option>
                        <option value="2">Reprovado</option>
                      </select>
                    </div>

                    <div *ngIf="ocorrenciaAtual.CruzadoCadastrado == 1" class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
                      <label for="IdadeAnimalCruzado">Idade(Meses)</label>
                      <input class="form-control form-control-sm " [required]="ocorrenciaAtual.CruzadoCadastrado == 1" name="IdadeAnimalCruzado" type="number" [(ngModel)]="ocorrenciaAtual.IdadeAnimalCruzado" />
                    </div>

                    <div *ngIf="ocorrenciaAtual.CruzadoCadastrado == 1" class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12" >
                      <label for="SexoAnimalCruzado">*Sexo</label>
                      <select class="form-control form-control-sm" [required]="ocorrenciaAtual.CruzadoCadastrado == 1" name="SexoAnimalCruzado" [(ngModel)]="ocorrenciaAtual.SexoAnimalCruzado">
                        <option value="1">Macho</option>
                        <option value="2">Fêmea</option>
                      </select>
                    </div>

                    <div *ngIf="ocorrenciaAtual.CruzadoCadastrado == 1" class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
                      <label for="CompRacialAnimalCruzado">*Comp. Racial</label>
                      <input class="form-control form-control-sm" [required]="ocorrenciaAtual.CruzadoCadastrado == 1" name="CompRacialAnimalCruzado" type="text" [(ngModel)]="ocorrenciaAtual.CompRacialAnimalCruzado" (ngModelChange)="formatarCompRacial($event)" />
                    </div>

                    <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12"
                      *ngIf="ocorrenciaAtual.TipoMotivoBaixa == 55 && ocorrenciaAtual.CertificadoBaixa == 2">
                      <label for="MotivoReprova">Motivo da Reprovação</label>
                      <textarea class="form-control form-control-sm" name="MotivoReprova"
                        [(ngModel)]="ocorrenciaAtual.MotivoReprova" style="min-height: calc(1.5em + 0.5rem + 2px); height: 50px;"></textarea>
                    </div>

                  </div>

                  <div class="row" *ngIf="ocorrenciaAtual.TipoMotivoBaixa == 55">
                    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                      <app-consultar-inspetor [tituloConsulta]="'Técnico'" [inspetor]="ocorrenciaAtual.Inspetor">
                      </app-consultar-inspetor>
                    </div>

                    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                      <app-consultar-criador
                        [criador]="ocorrenciaAtual.Frigorifico"
                        [criadorFrigorifico]="ocorrenciaAtual.Frigorifico"
                        [apenasFrigorifico]="true"
                        (criadorSelecionado)="frigorificoSelecionado($event)"
                        [identificacaoFrigorifico]="Desossa"
                      >
                      </app-consultar-criador>
                    </div>
                  </div>


                </div>
              </div>
            </div>

            <div class="col-lg-12">
              <div style="margin-bottom: 10px;" *ngIf="showBotaoRelatorio()">
                <button class="btn btn-primary btn-sm" type="button" matTooltip="Gerar relatório" (click)="gerarRelatorio(lote.Id)">Gerar relatório de Pós-Abate</button>
              </div>
              <app-botoes-acoes-ocorrencia [lote]="lote" [idOcorrencia]="ocorrenciaAtual.Id"
                (acaoSelecionada)="acaoBotao($event)" tipoEvento="baixa"></app-botoes-acoes-ocorrencia>
            </div>

          </div>

          <div class="row" style="margin-top: 30px;" *ngIf="showInputProtocolo()">
            <!-- AVALIACAO PRÉ ABATE -->
            <div class="table-responsive col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <table class="table table-hover table-striped table-wrap">
                <thead>
                  <td colspan="9" class="text-center"><b>RELAÇÃO PRÉ-ABATE</b></td>
                  <tr>
                    <th>Nome do Animal</th>
                    <th>N° do Animal</th>
                    <th>Data de Nascimento</th>
                    <th>Idade (meses)</th>
                    <th>Sexo</th>
                    <th>Comp Racial</th>
                    <th>N° do RGD/CCG</th>
                    <th>Peso Vivo</th>
                    <th>Selecionar animal</th>
                  </tr>
                </thead>
                <tbody >
                  <tr *ngFor="let relacao of relacoesPreAbate; let i = index" [ngStyle]="{'background-color': identificaAnimalSalvo(relacao.IdBovino) ? '#c7f2c7' : ''}">
                    <td> {{ relacao.Nome }} </td>
                    <td> {{ relacao.OrelhaEsquerda }} </td>
                    <td> {{ relacao.DataNascimento | date: 'dd/MM/yyyy' }} </td>
                    <td> {{ relacao.Idade }} </td>
                    <td> {{ commons.getDescricaoSexo(relacao.Sexo) }} </td>
                    <td> {{ relacao.ComposicaoRacial }} </td>
                    <td> {{ relacao.OrelhaDireita }} </td>
                    <td> {{ relacao.PesoVivo === 0? '-' : relacao.PesoVivo }} </td>
                    <td class="acoes-evento"><i class="bi bi-check" (click)="selecionaAnimal(relacao.OrelhaDireita)"></i></td>
                    <td class="text-center action-icons">
                  </tr>
                </tbody>

              </table>
            </div>
          </div>
          <!--Lista de Ocorrencias-->
          <div>

            <div *ngIf="ocorrencias.length > 0" class="row" style="margin-top: 15px;">
              <div class="col-lg-12 table-responsive">
                <table class="table table-hover table-header-custom">
                  <thead>
                    <tr>
                      <th>Animal</th>
                      <th>Data Baixa</th>
                      <th>Motivo Baixa</th>
                      <th *ngIf="env.contexto === 'wagyu'">Certificado</th>
                      <!--<th *ngIf="env.contexto === 'wagyu'">Motivo Morte</th>-->
                      <th>Situação</th>
                      <th class="text-center" width="10%">Opções</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let ev of ocorrencias"
                      [ngClass]="{'ocorrencia-em-destaque': ev.Id === ocorrenciaAtual.Id }">
                      <script>
                        console.log('ev: ',ev);
                      </script>
                      <td *ngIf="ev.CruzadoCadastrado == 2">{{ ev.Animal.NomeBovino }}</td>
                      <td *ngIf="ev.CruzadoCadastrado == 1">{{ ev.NomeAnimalCruzado }}</td>
                      <td>{{ ev.DataBaixa | date: 'dd/MM/yyyy' }}</td>
                      <td>{{ commons.getDescricaoMotivoBaixa( ev.TipoMotivoBaixa) }}</td>
                      <td *ngIf="env.contexto === 'wagyu'">{{ commons.getCertificadoBaixa( ev.CertificadoBaixa) }}</td>
                      <!--<td *ngIf="env.contexto === 'wagyu'">{{ commons.getDescricaoMotivoMorte( ev.MotivoMorte) }}</td>-->
                      <td>{{ commons.getDescricaoSituacao( ev.Situacao ) }}</td>
                      <td class="text-center text-nowrap acoes-evento">
                        <i *ngIf="ev.ImputaMulta === 1" matTooltip="Multa Imputada" class="fas fa-dollar-sign"></i>
                        <i *ngIf="ev.SolicitarDna === 1" matTooltip="Será solicitado DNA no Nascimento"
                          class="fas fa-dna"> </i>
                        <i *ngIf="permiteEdicao(ev.Situacao)" class="fas fa-edit" (click)="editar(ev)"></i>
                        <i *ngIf="!permiteEdicao(ev.Situacao)" class="fas fa-eye" (click)="editar(ev)"></i>
                        <i *ngIf="lote.Situacao === 1" class="fas fa-trash-alt" (click)="deletar(ev)"></i>
                        <i *ngIf="ev.IndInconsitencia" class="fa fa-exclamation-triangle" style="cursor: pointer;"
                          matTooltip="{{ev.Inconsistencia}}"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
