import { BovinoModel } from "./BovinoModel";

export class ConsultaSugestaoFivModel {
  Doadora: BovinoModel;
  SugestaoDe: string;
  SugestaoAte: string;
  CriadorId: string;
  

  constructor() {
    this.Doadora = new BovinoModel();
    this.SugestaoAte = '';
    this.SugestaoDe = '';
    this.CriadorId = '-1';
  }
}