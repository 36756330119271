import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from 'src/app/account/shared/account.service';
import { BovinoService } from 'src/app/bovino/shared/bovino.service';
import { BaseEventos } from 'src/app/eventos/base-eventos';
import { BovinoModel } from 'src/app/models/BovinoModel';
import { ItemAscendenciaNacionalizacaoModel } from 'src/app/models/ItemAscendenciaNacionalizacaoModel';
import { ApiService } from 'src/app/shared/api.service';
import { Commons } from 'src/app/shared/commons';
import { ConstantesTipoEvento } from 'src/app/shared/constantes';
import { Sexo, SiglaPais, SituacaoOcorrenciaEvento, TipoBuscaBovino, TipoGrauSangue, TipoItemNacionalizacao } from 'src/app/shared/enums/Enums';
import { IAscendenciaBovinoNacionalizacao, IJsonResultModel } from 'src/app/shared/interfaces/Interfaces';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-manter-semen',
  templateUrl: './manter-semen.component.html',
  styleUrls: ['./manter-semen.component.scss']
})
export class ManterSemenComponent extends BaseEventos implements OnInit {

  readonly env = environment;
  
  TipoSexo = Sexo;
  TipoBusca = TipoBuscaBovino;

  racas: { value: string, label: string}[] = [];
  grauSangue: { value: string, label: string }[] = [];
  siglasPaises: { value: number, label: string }[] = [];
  tiposGrauSangue: { value: number, label: string }[] = [];

  constructor(
    public activatedRoute: ActivatedRoute,
    public accountService: AccountService,
    public api: ApiService,
    public datePipe: DatePipe,
    public commons: Commons,
    public router: Router,
    public dialog: MatDialog,
    private bovinoService: BovinoService,
  ) { 
    super(api, datePipe, commons, router, accountService, dialog);
    this.tipoEvento = ConstantesTipoEvento.NACIONALIZACAO;
  }

  ngOnInit(): void {
    this.novoEvento();
    this.activatedRoute.paramMap.subscribe(params => {
      this.iniciarTela(params).then(() => {
        if (this.ocorrenciaAtual) {
          if (this.ocorrenciaAtual.Codigo !== '-1') {
            this.preencherGenealogiaAtualizada(this.ocorrenciaAtual.Codigo);
          }
        }
      }); 
    });

    this.tiposGrauSangue = this.bovinoService.getTiposGrauSangue();
    this.preencherSiglasPaises();
    this.atualizarGrauSanguePorTipo(TipoGrauSangue.PO);

    this.preencherRacas();
  }

  private preencherRacas() {
    this.racas = [];

    if ((this.env as any).contexto === 'wagyu') {
      this.racas.push({ value: 'WAGYU KUROGE', label: 'Wagyu Kuroge' });
      this.racas.push({ value: 'WAGYU AKAUSHI', label: 'Wagyu Akaushi' });
    } else if ((this.env as any).contexto === 'jersey') {
      this.racas.push({ value: 'Jersey', label: 'Jersey' });
    }
  }

  editar(evento: any) {
    this.api.consultarAscendenciaNacionalizacao(evento.Id)
      .then((result: IJsonResultModel<IAscendenciaBovinoNacionalizacao>) => {
        if (result && result.Data?.success) {
          evento.Ascendencia = result.Data.ascendencia;
          super.editar(evento);
        } else {
          Swal.fire(result.Data?.message || 'Ocorreu um erro ao recuperar a ascendência.', '', 'error');
        }
      });  

    if (this.ocorrenciaAtual) {
      if (this.ocorrenciaAtual.Codigo !== '-1') {
        this.preencherGenealogiaAtualizada(this.ocorrenciaAtual.Codigo);
      }
    }
  }

  salvar(confirmacao: boolean = false) {
    this.ocorrenciaAtual.Ascendencia.Pai = this.ajusteParaSalvar(TipoItemNacionalizacao.PAI, this.ocorrenciaAtual.Ascendencia.Pai);
    this.ocorrenciaAtual.Ascendencia.Mae = this.ajusteParaSalvar(TipoItemNacionalizacao.MAE, this.ocorrenciaAtual.Ascendencia.Mae);

    this.ocorrenciaAtual.Ascendencia.Avo1Paterno = this.ajusteParaSalvar(TipoItemNacionalizacao.AVO1, this.ocorrenciaAtual.Ascendencia.Avo1Paterno);
    this.ocorrenciaAtual.Ascendencia.Avo2Paterno = this.ajusteParaSalvar(TipoItemNacionalizacao.AVO2, this.ocorrenciaAtual.Ascendencia.Avo2Paterno);
    this.ocorrenciaAtual.Ascendencia.Avo1Materno = this.ajusteParaSalvar(TipoItemNacionalizacao.AVO3, this.ocorrenciaAtual.Ascendencia.Avo1Materno);
    this.ocorrenciaAtual.Ascendencia.Avo2Materno = this.ajusteParaSalvar(TipoItemNacionalizacao.AVO4, this.ocorrenciaAtual.Ascendencia.Avo2Materno);

    this.ocorrenciaAtual.Ascendencia.Bisavo1Paterno = this.ajusteParaSalvar(TipoItemNacionalizacao.BISAVO1, this.ocorrenciaAtual.Ascendencia.Bisavo1Paterno);
    this.ocorrenciaAtual.Ascendencia.Bisavo2Paterno = this.ajusteParaSalvar(TipoItemNacionalizacao.BISAVO2, this.ocorrenciaAtual.Ascendencia.Bisavo2Paterno);
    this.ocorrenciaAtual.Ascendencia.Bisavo3Paterno = this.ajusteParaSalvar(TipoItemNacionalizacao.BISAVO3, this.ocorrenciaAtual.Ascendencia.Bisavo3Paterno);
    this.ocorrenciaAtual.Ascendencia.Bisavo4Paterno = this.ajusteParaSalvar(TipoItemNacionalizacao.BISAVO4, this.ocorrenciaAtual.Ascendencia.Bisavo4Paterno);

    this.ocorrenciaAtual.Ascendencia.Bisavo1Materno = this.ajusteParaSalvar(TipoItemNacionalizacao.BISAVO5, this.ocorrenciaAtual.Ascendencia.Bisavo1Materno);
    this.ocorrenciaAtual.Ascendencia.Bisavo2Materno = this.ajusteParaSalvar(TipoItemNacionalizacao.BISAVO6, this.ocorrenciaAtual.Ascendencia.Bisavo2Materno);
    this.ocorrenciaAtual.Ascendencia.Bisavo3Materno = this.ajusteParaSalvar(TipoItemNacionalizacao.BISAVO7, this.ocorrenciaAtual.Ascendencia.Bisavo3Materno);
    this.ocorrenciaAtual.Ascendencia.Bisavo4Materno = this.ajusteParaSalvar(TipoItemNacionalizacao.BISAVO8, this.ocorrenciaAtual.Ascendencia.Bisavo4Materno);

    super.salvar(confirmacao);
  }

  private ajusteParaSalvar(tipo: TipoItemNacionalizacao, item: ItemAscendenciaNacionalizacaoModel | null): ItemAscendenciaNacionalizacaoModel | null {
    if (item === null) return null;

    item.Tipo = tipo;
    item.ItemParaCadastrar = this.itemParaCadastrar(item);
    item.Raca = (this.env as any).contexto === 'jersey'? 'Jersey' : 'WAGYU KUROGE';
    
    return item;
  }

  getBackgroundColor(item: ItemAscendenciaNacionalizacaoModel): string {
    return (!item.AnimalExistente && item.Codigo && item.Codigo !== '' && item.ItemParaCadastrar)
      ? 'background-color-green'
      : 'background-color-default';
  }

  preencherSiglasPaises() {
    this.siglasPaises.push({ value: SiglaPais.None, label: 'Selecione...' });
    
    this.siglasPaises.push({ value: SiglaPais.CAN, label: 'CAN' });
    this.siglasPaises.push({ value: SiglaPais.DEU, label: 'DEU' });
    this.siglasPaises.push({ value: SiglaPais.GBR, label: 'GBR' });
    this.siglasPaises.push({ value: SiglaPais.USA, label: 'USA' });
    
    if ((this.env as any).contexto === 'wagyu') {
      this.siglasPaises.push({ value: SiglaPais.AUS, label: 'AUS' });
      this.siglasPaises.push({ value: SiglaPais.URY, label: 'URY' });
      this.siglasPaises.push({ value: SiglaPais.ARG, label: 'ARG' });
    }
  }

  atualizarGrauSanguePorTipo(tipo: number): void {
    this.grauSangue = this.bovinoService.getGrauSanguePorTipo(tipo);
  }

  private preencherPrimeiroNivel(result: any, sexo: Sexo): void {
    [
      this.ocorrenciaAtual.Ascendencia[`${sexo === Sexo.Macho? 'Pai' : 'Mae'}`],
      this.ocorrenciaAtual.Ascendencia[`${sexo === Sexo.Macho? 'Avo1Paterno' : 'Avo1Materno'}`],
      this.ocorrenciaAtual.Ascendencia[`${sexo === Sexo.Macho? 'Avo2Paterno' : 'Avo2Materno'}`],
      this.ocorrenciaAtual.Ascendencia[`${sexo === Sexo.Macho? 'Bisavo1Paterno' : 'Bisavo1Materno'}`],
      this.ocorrenciaAtual.Ascendencia[`${sexo === Sexo.Macho? 'Bisavo2Paterno' : 'Bisavo2Materno'}`],
      this.ocorrenciaAtual.Ascendencia[`${sexo === Sexo.Macho? 'Bisavo3Paterno' : 'Bisavo3Materno'}`],
      this.ocorrenciaAtual.Ascendencia[`${sexo === Sexo.Macho? 'Bisavo4Paterno' : 'Bisavo4Materno'}`],
    ] = [
      { ...result.Animal, ItemParaCadastrar: this.itemParaCadastrar(result.Animal) },
      { ...result.Pai, ItemParaCadastrar: this.itemParaCadastrar(result.Pai) },
      { ...result.Mae, ItemParaCadastrar: this.itemParaCadastrar(result.Mae) },
      { ...result.Avo1Paterno, ItemParaCadastrar: this.itemParaCadastrar(result.Avo1Paterno) },
      { ...result.Avo2Paterno, ItemParaCadastrar: this.itemParaCadastrar(result.Avo2Paterno) },
      { ...result.Avo1Materno, ItemParaCadastrar: this.itemParaCadastrar(result.Avo1Materno) },
      { ...result.Avo2Materno, ItemParaCadastrar: this.itemParaCadastrar(result.Avo2Materno) },
    ]; 

    if (
      (sexo === Sexo.Macho && this.ocorrenciaAtual.Ascendencia.Avo1Materno === null) ||
      (sexo === Sexo.Fêmea && this.ocorrenciaAtual.Ascendencia.Avo1Paterno === null)
    )
    [
      this.ocorrenciaAtual.Ascendencia[`${sexo === Sexo.Macho? 'Avo1Materno' : 'Avo1Paterno'}`],
      this.ocorrenciaAtual.Ascendencia[`${sexo === Sexo.Macho? 'Avo2Materno' : 'Avo2Paterno'}`],
      this.ocorrenciaAtual.Ascendencia[`${sexo === Sexo.Macho? 'Bisavo1Materno' : 'Bisavo1Paterno'}`],
      this.ocorrenciaAtual.Ascendencia[`${sexo === Sexo.Macho? 'Bisavo2Materno' : 'Bisavo2Paterno'}`],
      this.ocorrenciaAtual.Ascendencia[`${sexo === Sexo.Macho? 'Bisavo3Materno' : 'Bisavo3Paterno'}`],
      this.ocorrenciaAtual.Ascendencia[`${sexo === Sexo.Macho? 'Bisavo4Materno' : 'Bisavo4Paterno'}`],
    ] = Array.from({length: 6}, () => new ItemAscendenciaNacionalizacaoModel());

    this.ocorrenciaAtual.Ascendencia.Pai.PermiteAlterar = true;
    this.ocorrenciaAtual.Ascendencia.Mae.PermiteAlterar = true;
  }

  private preencherSegundoNivel(result: any, sexo: Sexo, avo: number): void {
    [
      this.ocorrenciaAtual.Ascendencia[`${sexo === Sexo.Macho? `Avo${avo}Paterno` : `Avo${avo}Materno`}`],
      this.ocorrenciaAtual.Ascendencia[`${sexo === Sexo.Macho? `Bisavo${avo === 1? '1' : '3'}Paterno` : `Bisavo${avo === 1? '1' : '3'}Materno`}`],
      this.ocorrenciaAtual.Ascendencia[`${sexo === Sexo.Macho? `Bisavo${avo === 1? '2' : '4'}Paterno` : `Bisavo${avo === 1? '2' : '4'}Materno`}`],
    ] = [
      { ...result.Animal, PermiteAlterar: true, ItemParaCadastrar: this.itemParaCadastrar(result.Animal) },
      { ...result.Pai, ItemParaCadastrar: this.itemParaCadastrar(result.Pai) },
      { ...result.Mae, ItemParaCadastrar: this.itemParaCadastrar(result.Mae) },
    ]; 
  }

  private preencherTerceiroNivel(result: any, sexo: Sexo, bisavo: number): void {
    this.ocorrenciaAtual.Ascendencia[`${sexo === Sexo.Macho? `Bisavo${bisavo}Paterno` : `Bisavo${bisavo}Materno`}`] = {
      ...result.Animal,
      PermiteAlterar: true,
      ItemParaCadastrar: true,
    };
  }

  private itemParaCadastrar(item: any): boolean {
    return (item && item.Codigo && item.Codigo !== '');
  }

  bovinoSelecionado(id: string, sexoPrimeiroNivel: Sexo, nivel?: number, numeroEntidade?: number): void {
    this.api.consultarGenealogiaBovino(id).then((result:any) => {
      if (result && result.success) {
        if (!nivel || nivel === 0) return this.preencherPrimeiroNivel(result, sexoPrimeiroNivel);
        if (numeroEntidade && nivel === 1) return this.preencherSegundoNivel(result, sexoPrimeiroNivel, numeroEntidade);
        if (numeroEntidade && nivel === 2) return this.preencherTerceiroNivel(result, sexoPrimeiroNivel, numeroEntidade);
      } else {
        Swal.fire(result.message || "Ocorreu um erro ao tentar recuperar a ascendência do bovino.");
      }
    });
  }

  private limparPrimeiroNivel(sexo: Sexo) {
    if (sexo === Sexo.Macho) {
      this.ocorrenciaAtual.Ascendencia.Pai = new ItemAscendenciaNacionalizacaoModel();
      this.ocorrenciaAtual.Ascendencia.Pai.Readonly = false;
      this.ocorrenciaAtual.Ascendencia.Pai.PermiteAlterar = true;
    } else if (sexo === Sexo.Fêmea) {
      this.ocorrenciaAtual.Ascendencia.Mae = new ItemAscendenciaNacionalizacaoModel();
      this.ocorrenciaAtual.Ascendencia.Mae.Readonly = false;
      this.ocorrenciaAtual.Ascendencia.Mae.PermiteAlterar = true;
    }
    
    if (this.ocorrenciaAtual.Ascendencia[`${sexo === Sexo.Macho? 'Avo1Paterno' : 'Avo1Materno'}`] === null) return;
    this.limparSegundoNivel(sexo, 1);
    this.limparSegundoNivel(sexo, 2);
  }

  private limparSegundoNivel(sexo: Sexo, avo: number) {
    this.ocorrenciaAtual.Ascendencia[`${sexo === Sexo.Macho? `Avo${avo}Paterno` : `Avo${avo}Materno`}`] = new ItemAscendenciaNacionalizacaoModel();

    if (avo === 1) {
      this.limparTerceiroNivel(sexo, 1);
      this.limparTerceiroNivel(sexo, 2);
    } else if (avo === 2) {
      this.limparTerceiroNivel(sexo, 3);
      this.limparTerceiroNivel(sexo, 4);
    }
  }

  private limparTerceiroNivel(sexo: Sexo, bisavo: number) {
    this.ocorrenciaAtual.Ascendencia[`${sexo === Sexo.Macho? `Bisavo${bisavo}Paterno` : `Bisavo${bisavo}Materno`}`] = new ItemAscendenciaNacionalizacaoModel();
  }

  limpar(sexoPrimeiroNivel: Sexo, nivel?: number, numeroEntidade?: number) {
    if (!nivel || nivel === 0) return this.limparPrimeiroNivel(sexoPrimeiroNivel);
    if (numeroEntidade && nivel === 1) return this.limparSegundoNivel(sexoPrimeiroNivel, numeroEntidade);
    if (numeroEntidade && nivel === 2) return this.limparTerceiroNivel(sexoPrimeiroNivel, numeroEntidade);
  }

  setAvos(criar: boolean, ajusteApenasUmSexo: Sexo = Sexo.Todos) { 
    if (!criar) this.setBisavos(false);
   
    if (ajusteApenasUmSexo === Sexo.Todos || ajusteApenasUmSexo === Sexo.Macho)
      [
        this.ocorrenciaAtual.Ascendencia.Avo1Paterno,
        this.ocorrenciaAtual.Ascendencia.Avo2Paterno,
      ] = Array.from({length: 2}, () => criar 
        ? new ItemAscendenciaNacionalizacaoModel(
          this.ocorrenciaAtual.Ascendencia.Pai.ItemParaCadastrar && !this.ocorrenciaAtual.Ascendencia.Pai.AnimalExistente
        ) 
        : null);

    if (ajusteApenasUmSexo === Sexo.Todos || ajusteApenasUmSexo === Sexo.Fêmea)
      [
        this.ocorrenciaAtual.Ascendencia.Avo1Materno,
        this.ocorrenciaAtual.Ascendencia.Avo2Materno,
      ] = Array.from({length: 2}, () => criar 
        ? new ItemAscendenciaNacionalizacaoModel(
          this.ocorrenciaAtual.Ascendencia.Mae.ItemParaCadastrar && !this.ocorrenciaAtual.Ascendencia.Mae.AnimalExistente
        ) 
        : null);
  }

  setBisavos(criar: boolean) {
    if (criar && this.ocorrenciaAtual.Ascendencia.Avo1Paterno === null) this.setAvos(true);

    [
      this.ocorrenciaAtual.Ascendencia.Bisavo1Paterno,
      this.ocorrenciaAtual.Ascendencia.Bisavo2Paterno,
      this.ocorrenciaAtual.Ascendencia.Bisavo3Paterno,
      this.ocorrenciaAtual.Ascendencia.Bisavo4Paterno,
      this.ocorrenciaAtual.Ascendencia.Bisavo1Materno,
      this.ocorrenciaAtual.Ascendencia.Bisavo2Materno,
      this.ocorrenciaAtual.Ascendencia.Bisavo3Materno,
      this.ocorrenciaAtual.Ascendencia.Bisavo4Materno,
    ] = Array.from({length: 8}, () => criar? new ItemAscendenciaNacionalizacaoModel() : null);
  }

  atualizaBisavosPorAvoAdicionado(sexo: Sexo, avo: number) {
    [
      this.ocorrenciaAtual.Ascendencia[`Bisavo${avo === 1? '1' : '3'}${sexo === Sexo.Macho ? 'Paterno' : 'Materno'}`],
      this.ocorrenciaAtual.Ascendencia[`Bisavo${avo === 1? '2' : '4'}${sexo === Sexo.Macho ? 'Paterno' : 'Materno'}`],
    ] = Array.from({length: 2}, () => new ItemAscendenciaNacionalizacaoModel(
        this.ocorrenciaAtual.Ascendencia[`${sexo === Sexo.Macho ? 'Pai' : 'Mae'}`].ItemParaCadastrar 
        && !this.ocorrenciaAtual.Ascendencia[`${sexo === Sexo.Macho ? 'Pai' : 'Mae'}`].AnimalExistente
      ) 
    );
  }
  permiteSalvarAprovado(){
    if(this.ocorrenciaAtual.Situacao == SituacaoOcorrenciaEvento.Aprovado &&
      (this.accountService.isAdmin || this.accountService.isSuperintendente)){
      return true;
    }else{
      return false;
    }
  }

  preencherGenealogiaAtualizada(codigoBovino: string) {
    let bovinoConsulta = new BovinoModel();

    bovinoConsulta.CodigoBovino = codigoBovino;
    bovinoConsulta.ConsideraInativos = false;
    this.api.consultarBovinoPorCodigo(bovinoConsulta).then((result) => {
      if(result){
        if(result.Status === 0){
          this.api.consultarGenealogiaBovino(result.Bovino.Id).then((res: any) => {
            if (res) {
              if (res.success) {
                
                this.ocorrenciaAtual.Ascendencia.Pai = this.ajusteParaSalvar(TipoItemNacionalizacao.PAI, res.Pai);
                this.ocorrenciaAtual.Ascendencia.Mae = this.ajusteParaSalvar(TipoItemNacionalizacao.MAE, res.Mae);
            
                this.ocorrenciaAtual.Ascendencia.Avo1Paterno = this.ajusteParaSalvar(TipoItemNacionalizacao.AVO1, res.Avo1Paterno);
                this.ocorrenciaAtual.Ascendencia.Avo2Paterno = this.ajusteParaSalvar(TipoItemNacionalizacao.AVO2, res.Avo2Paterno);
                this.ocorrenciaAtual.Ascendencia.Avo1Materno = this.ajusteParaSalvar(TipoItemNacionalizacao.AVO3, res.Avo1Materno);
                this.ocorrenciaAtual.Ascendencia.Avo2Materno = this.ajusteParaSalvar(TipoItemNacionalizacao.AVO4, res.Avo2Materno);
            
                this.ocorrenciaAtual.Ascendencia.Bisavo1Paterno = this.ajusteParaSalvar(TipoItemNacionalizacao.BISAVO1, res.Bisavo1Paterno);
                this.ocorrenciaAtual.Ascendencia.Bisavo2Paterno = this.ajusteParaSalvar(TipoItemNacionalizacao.BISAVO2, res.Bisavo2Paterno);
                this.ocorrenciaAtual.Ascendencia.Bisavo3Paterno = this.ajusteParaSalvar(TipoItemNacionalizacao.BISAVO3, res.Bisavo3Paterno);
                this.ocorrenciaAtual.Ascendencia.Bisavo4Paterno = this.ajusteParaSalvar(TipoItemNacionalizacao.BISAVO4, res.Bisavo4Paterno);
            
                this.ocorrenciaAtual.Ascendencia.Bisavo1Materno = this.ajusteParaSalvar(TipoItemNacionalizacao.BISAVO5, res.Bisavo1Materno);
                this.ocorrenciaAtual.Ascendencia.Bisavo2Materno = this.ajusteParaSalvar(TipoItemNacionalizacao.BISAVO6, res.Bisavo2Materno);
                this.ocorrenciaAtual.Ascendencia.Bisavo3Materno = this.ajusteParaSalvar(TipoItemNacionalizacao.BISAVO7, res.Bisavo3Materno);
                this.ocorrenciaAtual.Ascendencia.Bisavo4Materno = this.ajusteParaSalvar(TipoItemNacionalizacao.BISAVO8, res.Bisavo4Materno);
            
    
              } else {
                Swal.fire(res.message, '', 'error');
              }
            }
          });
        }
      } else {
        Swal.fire('Ocorreu um erro ao recuperar a ascendência.', '', 'error');
      }
    })
  }
  
}
