export class IdentificacaoFazenda{

  Codigo: string;
  Nome: string;
  IdPrincipal: string;

  constructor(){
    this.Codigo = "";
    this.Nome = "";
    this.IdPrincipal = "";
  }

}
