import { AccountService } from 'src/app/account/shared/account.service';
import { Commons } from 'src/app/shared/commons';
import { ApiService } from 'src/app/shared/api.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { BaseEventos } from 'src/app/eventos/base-eventos';
import { ConstantesTipoEvento } from 'src/app/shared/constantes';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { BovinoModel } from 'src/app/models/BovinoModel';
import { ConsultarSugestaoNascimentoModalComponent } from 'src/app/shared/consultar-sugestao-nascimento-modal/consultar-sugestao-nascimento-modal.component';
import {
  IFazenda,
  IItemSugestaoNascimento,
  IJsonResultModel,
  IResponseConsultaCriadorId,
  IResponseRecuperarLaudoPorId,
  IResponseVisualizarLaudoDna,
} from 'src/app/shared/interfaces/Interfaces';
import Swal from 'sweetalert2';
import {
  AnimalCadastrado,
  EnumTipoEventoSRG,
  IsGemelar,
  PosicaoAfixo,
  Sexo,
  SituacaoOcorrenciaEvento,
  TipoBuscaBovino,
  TipoFiv,
  TipoGrauSangue,
} from 'src/app/shared/enums/Enums';
import { ConsultaRapidaBovinoModel } from 'src/app/models/ConsultaRapidaBovinoModel';
import { OcorrenciaEventoPadreacaoModel } from 'src/app/models/OcorrenciaEventoPadreacaoModel';
import { OcorrenciaEventoInovulacaoModel } from 'src/app/models/OcorrenciaEventoInovulacaoModel';
import { BovinoService } from 'src/app/bovino/shared/bovino.service';
import { FazendaModel } from 'src/app/models/FazendaModel';
import { IdentificacaoCriadorModel } from 'src/app/models/IdentificacaoCriadorModel';
import { LaudoDNAModel } from 'src/app/models/LaudoDNAModel';
import { CalculoGrauSangueService } from 'src/app/shared/calculo-grau-sangue.service';
import { promise } from 'protractor';
import { OcorrenciaEventoNascimentoModel } from 'src/app/models/OcorrenciaEventoNascimentoModel';
import { temporaryAllocator } from '@angular/compiler/src/render3/view/util';
import { isGeneratedFile } from '@angular/compiler/src/aot/util';

@Component({
  selector: 'app-nascimento-manter',
  templateUrl: './nascimento-manter.component.html',
  styleUrls: ['./nascimento-manter.component.scss'],
  providers: [],
})
export class NascimentoManterComponent extends BaseEventos implements OnInit {
  isReadOnly: boolean = false;
  readonly env = environment;
  sugestaoSelecionada: boolean = false;
  descEventoSugestao: string = `${(this.env as any).contexto === 'jersey' ? 'Padreação' : 'Cobertura'} / Inovulação`;
  racas: { value: string; label: string }[] = [];
  locaisNascimento: { value: string; label: string }[] = [];
  eventoAssociado: EnumTipoEventoSRG | null = null;
  cont: number = 0;
  grauDeSanguePai: string = "";
  grauDeSangueMae: string = "";
  grauDeSangueProduto: string = "";
  porcentagemPai: any;
  porcentagemMae: any;
  porcentagemProduto: any;
  showFIV: boolean = false;
  nome: string = "";


  constructor(
    private bovinoService: BovinoService,
    private activatedRoute: ActivatedRoute,
    public accountService: AccountService,
    public api: ApiService,
    public datePipe: DatePipe,
    public commons: Commons,
    public router: Router,
    public dialog: MatDialog,
    private calculoGrauSangueService: CalculoGrauSangueService
  ) {
    super(api, datePipe, commons, router, accountService, dialog);
    this.tipoEvento = ConstantesTipoEvento.NASCIMENTO;
  }

  ngOnInit(): void {
    this.novoEvento();
    this.activatedRoute.paramMap.subscribe(params => {
      this.iniciarTela(params).then(() => {
        this.eventoAssociado = this.tipoEventoAssociado();
        if (this.ocorrenciaAtual.OcorrenciaEventoInovulacao.OcorrenciaEventoFiv) {
          this.showFIV = true;
        }

        this.preencherLocaisNascimento();
        if (this.eventoAssociado !== null) {
          if (this.ocorrenciaAtual.HasPadreacaoAssociada) {
            this.sugestaoSelecionada = true;
            this.descEventoSugestao = `${(this.env as any).contexto === 'jersey' ? 'Padreação' : 'Cobertura'}`;
            this.ocorrenciaAtual.Mae.Readonly = true;
            this.ocorrenciaAtual.Pai.Readonly = true;
          } else if (
            this.ocorrenciaAtual.OcorrenciaEventoInovulacao &&
            this.ocorrenciaAtual.OcorrenciaEventoInovulacao.Id &&
            this.ocorrenciaAtual.OcorrenciaEventoInovulacao.Id !== '-1'
          ) {
            this.sugestaoSelecionada = true;
            this.ocorrenciaAtual.Mae.Readonly = true;
            this.ocorrenciaAtual.Pai.Readonly = true;
            this.ocorrenciaAtual.OcorrenciaEventoInovulacao.DataOcorrencia =
              this.ocorrenciaAtual.OcorrenciaEventoInovulacao.DataTransferencia;
            this.descEventoSugestao = `${(this.env as any).contexto === 'jersey' ? 'Inovulação' : 'Implantação'}`;
          }
          this.obterGrauSangue(this.ocorrenciaAtual.Pai.Id, this.ocorrenciaAtual.Mae.Id);
        } else {
          let temporario = this.ocorrenciaAtual;
          this.ocorrenciaAtual = new OcorrenciaEventoNascimentoModel();
          this.ocorrenciaAtual.Id = temporario.Id;
          this.ocorrenciaAtual.Situacao = temporario.Situacao;
          this.ocorrenciaAtual.Brinco = temporario.Brinco;
          this.ocorrenciaAtual.OrelhaDireita = temporario.OrelhaDireita;
          this.ocorrenciaAtual.Sexo = temporario.Sexo;
          this.ocorrenciaAtual.Peso = temporario.Peso;
          this.ocorrenciaAtual.Raca = temporario.Raca;

        }
      });
    });
    this.racas = this.bovinoService.getListaRacas(['evento_nascimento'], true, 'Selecione...');

  }

  laudoSelecionado(id: string) {
    if (!id || id === '-1') this.ocorrenciaAtual.LaudoDna = new LaudoDNAModel();
  }

  laudoSelecionadoGemeo(id: string) {
    if (!id || id === '-1') this.ocorrenciaAtual.LaudoDnaGemeo = new LaudoDNAModel();
  }

  async criadorSelecionado(idCriador: string, novoItem: boolean = true) {
    super.criadorSelecionado(idCriador, novoItem);

    if (this.formulario.criador.usuarioId && this.formulario.criador.usuarioId !== '-1')
      this.recuperarListaAfixosCriador(this.formulario.criador.usuarioId);

    this.preencherLocaisNascimento();
  }

  async recuperarListaAfixosCriador(codigo: string) {
    await this.api.consultarCriadorPorCodigo({ codigo, consultaEmTodasFiliadas: true }).then((result: IResponseConsultaCriadorId) => {
      if (result) {
        this.formulario.criador.afixos = result.AfixosCriador || [];
        this.formulario.criador.posicaoAfixo = result.PosicaoAfixo;
        this.formulario.criador.fazendas = result.Fazendas?.map(
          ({ Endereco, ...item }: IFazenda): FazendaModel => ({
            Id: item.Id,
            Nome: item.Nome,
            IdentificacaoFazenda: item.IdentificacaoFazenda,
            Endereco: {
              bairro: Endereco.Bairro,
              cep: Endereco.Cep,
              cidade: Endereco.Cidade,
              logradouro: Endereco.Logradouro,
              numero: Endereco.Numero,
              uf: Endereco.UF,
              complemento: Endereco.Complemento,
            },
          })
        )
      }
    });
  }

  private async recuperarFazendasCriador(codigo: string): Promise<FazendaModel[]> {
    const response = await this.api.consultarCriadorPorCodigo({ codigo, consultaEmTodasFiliadas: true });

    if (response) {
      this.formulario.criador.afixos = response.AfixosCriador || [];
      this.formulario.criador.posicaoAfixo = response.PosicaoAfixo;
      if (response.Status === 0) {
        return response.FazendasCriador?.filter((item: IFazenda) => ({
          Id: item.Id,
          Nome: item.Nome,
          IdentificacaoFazenda: item.IdentificacaoFazenda,
        }));
      } else {
        Swal.fire(response.Mensagem, '', 'error');
      }
    }

    return [];
  }

  private async preencherLocaisNascimento() {
    this.locaisNascimento = [];
    this.locaisNascimento.push({ value: '-1', label: 'Selecione...' });

    if (this.accountService.isCriador) {
      const token: any = this.accountService.getPayloadToken();
      if (token) {
        this.formulario.criador.fazendas = await this.recuperarFazendasCriador(token.CodigoCriador);
      }
    } else if (this.accountService.isAdmin || this.accountService.isSuperintendente || this.accountService.isProcurador) {
      if (this.lote && this.lote.Criador)
        this.formulario.criador.fazendas = await this.recuperarFazendasCriador(this.lote.Criador.CodigoCriador);
    }

    this.formulario.criador.fazendas.map((item: FazendaModel) =>
      this.locaisNascimento.push({ value: item.Id, label: item.Nome })
    );


    // to-do: se houver apenas uma fazenda, seleciona-la e bloquear combo
    // if (this.locaisNascimento.length === 2)
    //   this.ocorrenciaAtual.LocalNascimento = this.locaisNascimento[1].value;
  }

  animalSelecionado(bovino: BovinoModel) {
    if (!bovino || !bovino.Id || bovino.Id === '-1') {
      this.ocorrenciaAtual.LaudoDna = new LaudoDNAModel();
      this.ocorrenciaAtual.LaudoDnaGemeo = new LaudoDNAModel();
    } else {
      this.ocorrenciaAtual.LaudoDna[`${bovino.SexoBovino === Sexo.Macho ? 'Pai' : 'Mae'}`].Id = bovino.Id;
      this.ocorrenciaAtual.LaudoDnaGemeo[`${bovino.SexoBovino === Sexo.Macho ? 'Pai' : 'Mae'}`].Id = bovino.Id;
    }
  }

  editar(evento: any) {
    super.editar(evento);
    Object.assign(this.ocorrenciaAtual.LaudoDna.Pai, this.ocorrenciaAtual.Pai);
    Object.assign(this.ocorrenciaAtual.LaudoDna.Mae, this.ocorrenciaAtual.Mae);

    this.recuperarLaudoNascimento();
    // this.recuperarEventoAssociado();

    if (this.ocorrenciaAtual.HasPadreacaoAssociada) {
      this.sugestaoSelecionada = true;
      this.descEventoSugestao = `${(this.env as any).contexto === 'jersey' ? 'Padreação' : 'Cobertura'}`;
      this.ocorrenciaAtual.Mae.Readonly = true;
      this.ocorrenciaAtual.Pai.Readonly = true;
    } else if (
      this.ocorrenciaAtual.OcorrenciaEventoInovulacao &&
      this.ocorrenciaAtual.OcorrenciaEventoInovulacao.Id &&
      this.ocorrenciaAtual.OcorrenciaEventoInovulacao.Id !== '-1'
    ) {
      this.sugestaoSelecionada = true;
      this.ocorrenciaAtual.Mae.Readonly = true;
      this.ocorrenciaAtual.Pai.Readonly = true;
      this.ocorrenciaAtual.OcorrenciaEventoInovulacao.DataOcorrencia =
        this.ocorrenciaAtual.OcorrenciaEventoInovulacao.DataTransferencia;
      this.descEventoSugestao = `${(this.env as any).contexto === 'jersey' ? 'Inovulação' : 'Implantação'}`;
    }

    this.eventoAssociado = this.tipoEventoAssociado();
  }

  async recuperarLaudoNascimento(): Promise<any> {
    if (!this.commons.isNullOID(this.ocorrenciaAtual.LaudoDna.Id)) {
      await this.api.recuperarLaudoDnaPorId(this.ocorrenciaAtual.LaudoDna.Id).then((result: IResponseRecuperarLaudoPorId) => {
        if (result && result.success) {
          let laudo = new LaudoDNAModel();
          Object.assign(this.ocorrenciaAtual.LaudoDna, result);
          this.ocorrenciaAtual.LaudoDna.NomeAnimal = result.NomeAnimal;
          this.ocorrenciaAtual.LaudoDna.NumeroLaudo = result.NumeroLaudo;
          Object.assign(this.ocorrenciaAtual.LaudoDna.Arquivo, result.Arquivo);
          Object.assign(this.ocorrenciaAtual.LaudoDna.Pai, { ...result.Pai, TituloConsulta: 'Pai' });
          Object.assign(this.ocorrenciaAtual.LaudoDna.Mae, { ...result.Mae, TituloConsulta: 'Mãe' });
        }
      });
    }
  }

  /*recuperarEventoAssociado() {
    if (!this.commons.isNullOID(this.ocorrenciaAtual.OcorrenciaEventoInovulacao.Id)) {
      this.api.consultarEventoPorId(this.ocorrenciaAtual.EventoAssociado.Id, 'inovulacao').then(res => {
        console.log(res);
      });
    }

    if (!this.commons.isNullOID(this.ocorrenciaAtual.OcorrenciaEventoPadreacao)) {
      this.api.consultarEventoPorId(this.ocorrenciaAtual.EventoAssociado.Id, 'padreacao').then(res => {
        console.log(res);
      });
    }
  }*/

  novoEvento(manterInfosParaSugestao: boolean = false) {
    super.novoEvento(manterInfosParaSugestao);
    this.sugestaoSelecionada = false;
    this.eventoAssociado = null;
    this.descEventoSugestao = `${(this.env as any).contexto === 'jersey' ? 'Padreação /  Inovulação' : 'Cobertura / Implantação'
      }`;
  }

  botaoConsultarLimparSugestao() {
    if (this.sugestaoSelecionada) this.botaoLimparSugestao();
    else this.consultarSugestoes();
  }

  botaoLimparSugestao() {
    this.sugestaoSelecionada = false;
    this.descEventoSugestao = `${(this.env as any).contexto === 'jersey' ? 'Padreação /  Inovulação' : 'Cobertura / Implantação'
      }`;

    // this.ocorrenciaAtual.EventoAssociado = null;
    // this.ocorrenciaAtual.OcorrenciaEventoPadreacao = new OcorrenciaEventoPadreacaoModel();
    // this.ocorrenciaAtual.OcorrenciaEventoInovulacao = new OcorrenciaEventoInovulacaoModel();
    // this.ocorrenciaAtual.Nome = '';

    // this.ocorrenciaAtual.Mae = new ConsultaRapidaBovinoModel();
    // this.ocorrenciaAtual.Mae.IdProprietario = this.lote.Criador.Id;
    // this.ocorrenciaAtual.Mae.IdProprietarioConsulta = this.lote.Criador.Id;
    // this.ocorrenciaAtual.Mae.TituloConsulta = 'Mãe';
    // this.ocorrenciaAtual.Mae.ConsultaAnimalTodosCriadores = false;
    // this.ocorrenciaAtual.Mae.TipoBusca = TipoBuscaBovino.Fêmea;
    // this.ocorrenciaAtual.Mae.SexoBovino = Sexo.Fêmea;
    // this.ocorrenciaAtual.Mae.ConsideraInativos = false;

    // this.ocorrenciaAtual.Pai = new ConsultaRapidaBovinoModel();
    // this.ocorrenciaAtual.Pai.IdProprietario = this.lote.Criador.Id;
    // this.ocorrenciaAtual.Pai.IdProprietarioConsulta = this.lote.Criador.Id;
    // this.ocorrenciaAtual.Pai.TituloConsulta = 'Pai';
    // this.ocorrenciaAtual.Pai.ConsultaAnimalTodosCriadores = false;
    // this.ocorrenciaAtual.Pai.TipoBusca = TipoBuscaBovino.Macho;
    // this.ocorrenciaAtual.Pai.SexoBovino = Sexo.Macho;
    // this.ocorrenciaAtual.Pai.ConsideraInativos = false;
    this.grauDeSangueMae = "";
    this.grauDeSanguePai = "";
    this.grauDeSangueProduto = "";
    this.porcentagemMae = "";
    this.porcentagemPai = "";
    this.porcentagemProduto = "";

    // this.ocorrenciaAtual.LaudoDna = new LaudoDNAModel();

    this.eventoAssociado = null;

    this.api.limparEventoAssociadoNascimento(this.ocorrenciaAtual.Id);
    let temporario = this.ocorrenciaAtual;
    this.ocorrenciaAtual = new OcorrenciaEventoNascimentoModel();
    this.ocorrenciaAtual.Id = temporario.Id;
    this.ocorrenciaAtual.Situacao = temporario.Situacao;
    this.ocorrenciaAtual.Brinco = temporario.Brinco;
    this.ocorrenciaAtual.OrelhaDireita = temporario.OrelhaDireita;
    this.ocorrenciaAtual.Sexo = temporario.Sexo;
    this.ocorrenciaAtual.Peso = temporario.Peso;
    this.ocorrenciaAtual.Raca = temporario.Raca;
    //this.lote.ocorrenciaAtual = this.ocorrenciaAtual;
  }

  consultarSugestoes() {
    if (!this.ocorrenciaAtual.DataNascimento) {
      Swal.fire('Informe a Data de Nascimento para continuar.', '', 'warning');
      return;
    }

    const dialogRef = this.dialog.open(ConsultarSugestaoNascimentoModalComponent, {
      height: '90%',
      width: '90%',
      autoFocus: false,
      data: {
        Mae: {
          Id: this.ocorrenciaAtual.Mae.Id,
          CodigoBovino:
            this.ocorrenciaAtual.MaeCadastrada == AnimalCadastrado.Sim
              ? this.ocorrenciaAtual.Mae.CodigoBovino
              : this.ocorrenciaAtual.CodigoMaeNaoCadastrada,
          NomeBovino:
            this.ocorrenciaAtual.MaeCadastrada == AnimalCadastrado.Sim
              ? this.ocorrenciaAtual.Mae.NomeBovino
              : this.ocorrenciaAtual.NomeMaeNaoCadastrada,
          AnimalCadastrado:
            this.ocorrenciaAtual.MaeCadastrada == AnimalCadastrado.Sim ? AnimalCadastrado.Sim : AnimalCadastrado.Nao,
        },
        DataNascimento: this.ocorrenciaAtual.DataNascimento,
        CriadorId: this.formulario.criador.id,
        SugerirIntervaloTempo: true,
      },
    });

    dialogRef.componentInstance.sugestaoSelecionada.subscribe((result: IItemSugestaoNascimento) => {
      if (result) {

        this.sugestaoSelecionada = true;
        this.descEventoSugestao = this.commons.getDescricaoEvento(result.TipoEventoSRG);

        this.ocorrenciaAtual.MaeCadastrada = AnimalCadastrado.Sim;
        this.ocorrenciaAtual.Mae = { ...result.Matriz, Readonly: true };

        this.ocorrenciaAtual.PaiCadastrado = AnimalCadastrado.Sim;
        this.ocorrenciaAtual.Pai = { ...result.Reprodutor, Readonly: true };

        this.ocorrenciaAtual.EventoAssociado = result;

        this.ocorrenciaAtual.LaudoDna = new LaudoDNAModel();
        this.ocorrenciaAtual.LaudoDna.Pai.Id = this.ocorrenciaAtual.Pai.Id;
        this.ocorrenciaAtual.LaudoDna.Mae.Id = this.ocorrenciaAtual.Mae.Id;

        if (result.TipoEventoSRG == EnumTipoEventoSRG.Padreacao) {
          this.ocorrenciaAtual.OcorrenciaEventoPadreacao = {
            Id: result.Id,
            DataOcorrencia: result.Data,
            TipoPadreacao: result.TipoPadreacao,
            MatrizCadastrada: result.MatrizCadastrada,
            Matriz: result.Matriz,
            CodigoMatrizNaoCadastrada: result.CodigoMatrizNaoCadastrada,
            NomeMatrizNaoCadastrada: result.NomeMatrizNaoCadastrada,
            CodigoNomeMatrizNaoCadastrada: result.CodigoNomeMatrizNaoCadastrada,
            ReprodutorCadastrado: result.ReprodutorCadastrado,
            Reprodutor: result.Reprodutor,
            CodigoReprodutorNaoCadastrado: result.CodigoReprodutorNaoCadastrado,
            NomeReprodutorNaoCadastrado: result.NomeReprodutorNaoCadastrado,
            CodigoNomeReprodutorNaoCadastrado: result.CodigoNomeReprodutorNaoCadastrado,
            LoteOcorrencia: this.lote,
          };
          if (this.ocorrenciaAtual.OcorrenciaEventoPadreacao.CodigoMatrizNaoCadastrada) {
            this.ocorrenciaAtual.MaeCadastrada = AnimalCadastrado.Nao;
          }
        } else if (result.TipoEventoSRG == EnumTipoEventoSRG.Inovulacao) {
          this.ocorrenciaAtual.OcorrenciaEventoInovulacao = {
            Id: result.Id,
            DataOcorrencia: result.Data,
            Doadora: result.Matriz,
            Reprodutor: result.Reprodutor,
            ReceptoraCadastrada: result.ReceptoraCadastrada,
            Receptora: result.ReceptoraCadastrada
              ? result.Receptora
              : ({
                CodigoBovino: result.CodigoReceptoraNaoCadastrada,
                NomeBovino: result.NomeReceptoraNaoCadastrada,
              } as BovinoModel),
            TipoTransferencia: result.TipoTransferencia,
            LoteOcorrencia: this.lote,
            TipoFiv: result.TipoFiv,
          };
          this.ocorrenciaAtual.OcorrenciaEventoInovulacao.Receptora.TituloConsulta = 'Receptora';
          this.ocorrenciaAtual.OcorrenciaEventoInovulacao.Receptora.Readonly = true;

          // if (this.ocorrenciaAtual.Nome !== '')
          //   this.ocorrenciaAtual.Nome = `${this.ocorrenciaAtual.Nome} ${this.getTipoFivNomeAnimal()}`;

        }

        if (this.ocorrenciaAtual.OcorrenciaEventoInovulacao.OcorrenciaEventoFiv) {
          this.showFIV = true;
        }
        this.eventoAssociado = this.tipoEventoAssociado();
        this.obterGrauSangue(this.ocorrenciaAtual.Pai.Id, result.Matriz.Id);
      }
    });


  }

  getLabelRaca(raca_id: string): string {
    const raca: { label: string; value: string }[] = this.racas.filter(item => item.value === raca_id);
    return raca[0].label;
  }

  private getTipoFivNomeAnimal() {
    var tipo = '';
    if (this.ocorrenciaAtual.OcorrenciaEventoInovulacao.TipoFiv == TipoFiv.FIV) {
      tipo = 'FIV';
    } else if (this.ocorrenciaAtual.OcorrenciaEventoInovulacao.TipoFiv == TipoFiv.TE) {
      tipo = 'TE';
    } else if (this.ocorrenciaAtual.OcorrenciaEventoInovulacao.OcorrenciaEventoFiv) {
      if (this.ocorrenciaAtual.OcorrenciaEventoInovulacao.OcorrenciaEventoFiv.TipoFIV == TipoFiv.FIV) {
        tipo = 'FIV';
      } else if (this.ocorrenciaAtual.OcorrenciaEventoInovulacao.OcorrenciaEventoFiv.TipoFIV == TipoFiv.TE) {
        tipo = 'TE';
      }
    }
    return tipo;
  }

  private tipoEventoAssociado = (): EnumTipoEventoSRG | null => {
    if (
      this.ocorrenciaAtual.OcorrenciaEventoPadreacao &&
      this.ocorrenciaAtual.OcorrenciaEventoPadreacao.Id !== '' &&
      this.ocorrenciaAtual.OcorrenciaEventoPadreacao.Id !== '-1'
    )
      return EnumTipoEventoSRG.Padreacao;

    if (
      this.ocorrenciaAtual.OcorrenciaEventoInovulacao &&
      this.ocorrenciaAtual.OcorrenciaEventoInovulacao.Id !== '' &&
      this.ocorrenciaAtual.OcorrenciaEventoInovulacao.Id !== '-1'
    )
      return EnumTipoEventoSRG.Inovulacao;

    return null;
  };

  formatarNomeBovino(
    contextoWagyu: boolean = false,
    removerCodigo: boolean,
    setBrinco: boolean = false,
    setTipoImplantacao: boolean = false
  ) {
    if (setTipoImplantacao && this.tipoEventoAssociado() === EnumTipoEventoSRG.Inovulacao)
      this.ocorrenciaAtual.Nome = this.ocorrenciaAtual.Nome.replace(` ${this.getTipoFivNomeAnimal()}`, '');

    /*this.ocorrenciaAtual.Nome = removerCodigo
      ? this.bovinoService.removerOrelhaEsquerda(
          this.ocorrenciaAtual.Nome,
          this.ocorrenciaAtual.OrelhaDireita,
          contextoWagyu
        )
      : this.bovinoService.getNomeBovino(
          this.ocorrenciaAtual.Nome,
          this.ocorrenciaAtual.OrelhaDireita,
          this.formulario.criador.afixos,
          contextoWagyu,
          (afixos: IdentificacaoCriadorModel[]): IdentificacaoCriadorModel =>
            afixos.filter((item: IdentificacaoCriadorModel) => item.principal && item)[0]
        );*/

    if (setBrinco) this.ocorrenciaAtual.Brinco = this.ocorrenciaAtual.OrelhaDireita;

    if (setTipoImplantacao && this.tipoEventoAssociado() === EnumTipoEventoSRG.Inovulacao)
      this.ocorrenciaAtual.Nome = `${this.ocorrenciaAtual.Nome} ${this.getTipoFivNomeAnimal()}`;
  }

  permiteEdicaoNome() {
    if (
      (this.accountService.isAdmin || this.accountService.isSuperintendente) &&
      (this.ocorrenciaAtual.Situacao == SituacaoOcorrenciaEvento.AguardandoAprovacao ||
        this.ocorrenciaAtual.Situacao == SituacaoOcorrenciaEvento.AguardandoAprovacaoVeterinario ||
        this.ocorrenciaAtual.Situacao == SituacaoOcorrenciaEvento.Pendente)
    ) {
      return true;
    } else {
      return false;
    }
  }

  desabilitarCamposAcessoCriador() {
    if (this.accountService.isCriador) {
      this.ocorrenciaAtual.Pai.Readonly = true;
      this.ocorrenciaAtual.Mae.Readonly = true;
      return true;
    } else {
      return false;
    }
  }
  aprovadoComPendencia() {
    if (
      this.ocorrenciaAtual.Situacao == SituacaoOcorrenciaEvento.AprovadoComPendencias &&
      this.accountService.isAdmin
    ) {
      return true;
    } else {
      return false;
    }
  }

  simplyformat(nomeAnimal: string = '') {
    // 1 - Limpa o nome do animal
    this.nome = nomeAnimal;
    this.ocorrenciaAtual.Nome = '';

    this.recuperarListaAfixosCriador(this.formulario.criador.codigo);
    if (this.formulario.criador && this.formulario.criador.afixo !== '') {
      // Se o criador tiver mais de um afixo
      if (this.formulario.criador.afixos && this.formulario.criador.afixos.length > 0) {
        // Recupera o primeiro afixo/sufixo principal
        let afixoBovino = this.formulario.criador.afixos.filter(
          afixo => afixo.codigo == this.formulario.criador.afixo && afixo.principal == true
        )[0];
        // 2 - verifica posição do afixo/sufixo
        if (nomeAnimal !== '' && this.ocorrenciaAtual.OrelhaDireita && this.ocorrenciaAtual.OrelhaDireita !== '') {
          // 3 - Verifica se a origem é FIV/TE e adiciona origem ao nome
          if (this.getTipoFivNomeAnimal() !== '') {
            // se for PREFIXO => AFIXO + NOME + BRINCO + TIPO IMPLANTACAO
            if (afixoBovino.posicaoAfixo == PosicaoAfixo.Prefixo) {
              if (this.ocorrenciaAtual.IsGemelar == IsGemelar.Sim) {
                this.ocorrenciaAtual.Nome =
                  afixoBovino.codigo +
                  ' ' +
                  nomeAnimal +
                  ' ' +
                  this.ocorrenciaAtual.OrelhaDireita +
                  ' ' +
                  'GÊMEO ' +
                  this.getTipoFivNomeAnimal();
              } else {
                this.ocorrenciaAtual.Nome =
                  afixoBovino.codigo +
                  ' ' +
                  nomeAnimal +
                  ' ' +
                  this.ocorrenciaAtual.OrelhaDireita +
                  ' ' +
                  this.getTipoFivNomeAnimal();
              }
            } else {
              // se for SUFIXO => NOME + BRINCO + AFIXO + TIPO IMPLANTACAO
              if (this.ocorrenciaAtual.IsGemelar == IsGemelar.Sim) {
                this.ocorrenciaAtual.Nome =
                  nomeAnimal +
                  ' ' +
                  this.ocorrenciaAtual.OrelhaDireita +
                  ' ' +
                  afixoBovino.codigo +
                  ' ' +
                  'GÊMEO ' +
                  this.getTipoFivNomeAnimal();
              } else {
                this.ocorrenciaAtual.Nome =
                  nomeAnimal +
                  ' ' +
                  this.ocorrenciaAtual.OrelhaDireita +
                  ' ' +
                  afixoBovino.codigo +
                  ' ' +
                  this.getTipoFivNomeAnimal();
              }

            }
          } else {
            // se for PREFIXO => AFIXO + NOME + BRINCO
            if (afixoBovino.posicaoAfixo == PosicaoAfixo.Prefixo) {
              if (this.ocorrenciaAtual.IsGemelar == IsGemelar.Sim) {
                this.ocorrenciaAtual.Nome =
                  afixoBovino.codigo + ' ' + nomeAnimal + ' ' + this.ocorrenciaAtual.OrelhaDireita + ' GÊMEO';
              } else {
                this.ocorrenciaAtual.Nome =
                  afixoBovino.codigo + ' ' + nomeAnimal + ' ' + this.ocorrenciaAtual.OrelhaDireita;
              }
            } else {
              // se for SUFIXO => NOME + BRINCO + AFIXO
              if (this.ocorrenciaAtual.IsGemelar == IsGemelar.Sim) {
                this.ocorrenciaAtual.Nome =
                  nomeAnimal + ' ' + this.ocorrenciaAtual.OrelhaDireita + ' ' + afixoBovino.codigo + ' GÊMEO';
              } else {
                this.ocorrenciaAtual.Nome =
                  nomeAnimal + ' ' + this.ocorrenciaAtual.OrelhaDireita + ' ' + afixoBovino.codigo;
              }
            }
          }
        }
      } else {
        // Recupera o primeiro afixo/sufixo principal
        let afixoBovino = this.formulario.criador.afixo;
        // 2 - verifica posição do afixo/sufixo
        if (nomeAnimal !== '' && this.ocorrenciaAtual.OrelhaDireita && this.ocorrenciaAtual.OrelhaDireita !== '') {
          // 3 - Verifica se a origem é FIV/TE e adiciona origem ao nome
          if (this.getTipoFivNomeAnimal() !== '') {
            // se for PREFIXO => AFIXO + NOME + BRINCO + TIPO IMPLANTACAO
            if (this.formulario.criador.posicaoAfixo == PosicaoAfixo.Prefixo) {
              if (this.ocorrenciaAtual.IsGemelar == IsGemelar.Sim) {
                this.ocorrenciaAtual.Nome =
                  afixoBovino +
                  ' ' +
                  nomeAnimal +
                  ' ' +
                  this.ocorrenciaAtual.OrelhaDireita +
                  ' ' +
                  'GÊMEO ' +
                  this.getTipoFivNomeAnimal();
              } else {
                this.ocorrenciaAtual.Nome =
                  afixoBovino +
                  ' ' +
                  nomeAnimal +
                  ' ' +
                  this.ocorrenciaAtual.OrelhaDireita +
                  ' ' +
                  this.getTipoFivNomeAnimal();
              }

            } else {
              // se for SUFIXO => NOME + BRINCO + AFIXO + TIPO IMPLANTACAO
              if (this.ocorrenciaAtual.IsGemelar == IsGemelar.Sim) {
                this.ocorrenciaAtual.Nome =
                  nomeAnimal +
                  ' ' +
                  this.ocorrenciaAtual.OrelhaDireita +
                  ' ' +
                  afixoBovino +
                  ' ' +
                  'GÊMEO ' +
                  this.getTipoFivNomeAnimal();
              } else {
                this.ocorrenciaAtual.Nome =
                  nomeAnimal +
                  ' ' +
                  this.ocorrenciaAtual.OrelhaDireita +
                  ' ' +
                  afixoBovino +
                  ' ' +
                  this.getTipoFivNomeAnimal();
              }

            }
          } else {
            // se for PREFIXO => AFIXO + NOME + BRINCO
            if (this.formulario.criador.posicaoAfixo == PosicaoAfixo.Prefixo) {
              if (this.ocorrenciaAtual.IsGemelar == IsGemelar.Sim) {
                this.ocorrenciaAtual.Nome = afixoBovino + ' ' + nomeAnimal + ' ' + this.ocorrenciaAtual.OrelhaDireita + ' GÊMEO';
              } else {
                this.ocorrenciaAtual.Nome = afixoBovino + ' ' + nomeAnimal + ' ' + this.ocorrenciaAtual.OrelhaDireita;
              }
            } else {
              // se for SUFIXO => NOME + BRINCO + AFIXO
              if (this.ocorrenciaAtual.IsGemelar == IsGemelar.Sim) {
                this.ocorrenciaAtual.Nome = nomeAnimal + ' ' + this.ocorrenciaAtual.OrelhaDireita + ' ' + afixoBovino + ' GÊMEO';
              } else {
                this.ocorrenciaAtual.Nome = nomeAnimal + ' ' + this.ocorrenciaAtual.OrelhaDireita + ' ' + afixoBovino;
              }
            }
          }
        }
      }

      /*if (this.tipoEventoAssociado() === EnumTipoEventoSRG.Inovulacao)
        this.ocorrenciaAtual.Nome = this.ocorrenciaAtual.Nome.replace(` ${this.getTipoFivNomeAnimal()}`, '');
      if (!this.ocorrenciaAtual.Nome.includes(afixo)) {
        this.ocorrenciaAtual.Nome = afixo + ' ' + nomeAtual + ' ' + this.ocorrenciaAtual.OrelhaDireita;
      }
      if (setTipoImplantacao && this.tipoEventoAssociado() === EnumTipoEventoSRG.Inovulacao)
        this.ocorrenciaAtual.Nome = `${this.ocorrenciaAtual.Nome} ${this.getTipoFivNomeAnimal()}`;*/
    }
  }

  simplyformatGemeo(nomeAnimal: string = '') {
    // 1 - Limpa o nome do animal
    this.ocorrenciaAtual.NomeGemeo = '';
    this.recuperarListaAfixosCriador(this.formulario.criador.codigo);
    if (this.formulario.criador && this.formulario.criador.afixo !== '') {
      // Se o criador tiver mais de um afixo
      if (this.formulario.criador.afixos && this.formulario.criador.afixos.length > 0) {
        // Recupera o primeiro afixo/sufixo principal
        let afixoBovino = this.formulario.criador.afixos.filter(
          afixo => afixo.codigo == this.formulario.criador.afixo && afixo.principal == true
        )[0];
        // 2 - verifica posição do afixo/sufixo
        if (nomeAnimal !== '' && this.ocorrenciaAtual.OrelhaDireitaGemeo && this.ocorrenciaAtual.OrelhaDireitaGemeo !== '') {
          // 3 - Verifica se a origem é FIV/TE e adiciona origem ao nome
          if (this.getTipoFivNomeAnimal() !== '') {
            // se for PREFIXO => AFIXO + NOME + BRINCO + TIPO IMPLANTACAO
            if (afixoBovino.posicaoAfixo == PosicaoAfixo.Prefixo) {
              if (this.ocorrenciaAtual.IsGemelar == IsGemelar.Sim) {
                this.ocorrenciaAtual.NomeGemeo =
                  afixoBovino.codigo +
                  ' ' +
                  nomeAnimal +
                  ' ' +
                  this.ocorrenciaAtual.OrelhaDireitaGemeo +
                  ' ' +
                  'GÊMEO ' +
                  this.getTipoFivNomeAnimal();
              } else {
                this.ocorrenciaAtual.NomeGemeo =
                  afixoBovino.codigo +
                  ' ' +
                  nomeAnimal +
                  ' ' +
                  this.ocorrenciaAtual.OrelhaDireitaGemeo +
                  ' ' +
                  this.getTipoFivNomeAnimal();
              }
            } else {
              // se for SUFIXO => NOME + BRINCO + AFIXO + TIPO IMPLANTACAO
              if (this.ocorrenciaAtual.IsGemelar == IsGemelar.Sim) {
                this.ocorrenciaAtual.NomeGemeo =
                  nomeAnimal +
                  ' ' +
                  this.ocorrenciaAtual.OrelhaDireitaGemeo +
                  ' ' +
                  afixoBovino.codigo +
                  ' ' +
                  'GÊMEO ' +
                  this.getTipoFivNomeAnimal();
              } else {
                this.ocorrenciaAtual.NomeGemeo =
                  nomeAnimal +
                  ' ' +
                  this.ocorrenciaAtual.OrelhaDireitaGemeo +
                  ' ' +
                  afixoBovino.codigo +
                  ' ' +
                  this.getTipoFivNomeAnimal();
              }

            }
          } else {
            // se for PREFIXO => AFIXO + NOME + BRINCO
            if (afixoBovino.posicaoAfixo == PosicaoAfixo.Prefixo) {
              if (this.ocorrenciaAtual.IsGemelar == IsGemelar.Sim) {
                this.ocorrenciaAtual.NomeGemeo =
                  afixoBovino.codigo + ' ' + nomeAnimal + ' ' + this.ocorrenciaAtual.OrelhaDireitaGemeo + ' GÊMEO';
              } else {
                this.ocorrenciaAtual.NomeGemeo =
                  afixoBovino.codigo + ' ' + nomeAnimal + ' ' + this.ocorrenciaAtual.OrelhaDireitaGemeo;
              }
            } else {
              // se for SUFIXO => NOME + BRINCO + AFIXO
              if (this.ocorrenciaAtual.IsGemelar == IsGemelar.Sim) {
                this.ocorrenciaAtual.NomeGemeo =
                  nomeAnimal + ' ' + this.ocorrenciaAtual.OrelhaDireitaGemeo + ' ' + afixoBovino.codigo + ' GÊMEO';
              } else {
                this.ocorrenciaAtual.NomeGemeo =
                  nomeAnimal + ' ' + this.ocorrenciaAtual.OrelhaDireitaGemeo + ' ' + afixoBovino.codigo;
              }
            }
          }
        }
      } else {
        // Recupera o primeiro afixo/sufixo principal
        let afixoBovino = this.formulario.criador.afixo;
        // 2 - verifica posição do afixo/sufixo
        if (nomeAnimal !== '' && this.ocorrenciaAtual.OrelhaDireitaGemeo && this.ocorrenciaAtual.OrelhaDireitaGemeo !== '') {
          // 3 - Verifica se a origem é FIV/TE e adiciona origem ao nome
          if (this.getTipoFivNomeAnimal() !== '') {
            // se for PREFIXO => AFIXO + NOME + BRINCO + TIPO IMPLANTACAO
            if (this.formulario.criador.posicaoAfixo == PosicaoAfixo.Prefixo) {
              if (this.ocorrenciaAtual.IsGemelar == IsGemelar.Sim) {
                this.ocorrenciaAtual.NomeGemeo =
                  afixoBovino +
                  ' ' +
                  nomeAnimal +
                  ' ' +
                  this.ocorrenciaAtual.OrelhaDireitaGemeo +
                  ' ' +
                  'GÊMEO ' +
                  this.getTipoFivNomeAnimal();
              } else {
                this.ocorrenciaAtual.NomeGemeo =
                  afixoBovino +
                  ' ' +
                  nomeAnimal +
                  ' ' +
                  this.ocorrenciaAtual.OrelhaDireitaGemeo +
                  ' ' +
                  this.getTipoFivNomeAnimal();
              }

            } else {
              // se for SUFIXO => NOME + BRINCO + AFIXO + TIPO IMPLANTACAO
              if (this.ocorrenciaAtual.IsGemelar == IsGemelar.Sim) {
                this.ocorrenciaAtual.NomeGemeo =
                  nomeAnimal +
                  ' ' +
                  this.ocorrenciaAtual.OrelhaDireitaGemeo +
                  ' ' +
                  afixoBovino +
                  ' ' +
                  'GÊMEO ' +
                  this.getTipoFivNomeAnimal();
              } else {
                this.ocorrenciaAtual.NomeGemeo =
                  nomeAnimal +
                  ' ' +
                  this.ocorrenciaAtual.OrelhaDireitaGemeo +
                  ' ' +
                  afixoBovino +
                  ' ' +
                  this.getTipoFivNomeAnimal();
              }

            }
          } else {
            // se for PREFIXO => AFIXO + NOME + BRINCO
            if (this.formulario.criador.posicaoAfixo == PosicaoAfixo.Prefixo) {
              if (this.ocorrenciaAtual.IsGemelar == IsGemelar.Sim) {
                this.ocorrenciaAtual.NomeGemeo = afixoBovino + ' ' + nomeAnimal + ' ' + this.ocorrenciaAtual.OrelhaDireitaGemeo + ' GÊMEO';
              } else {
                this.ocorrenciaAtual.NomeGemeo = afixoBovino + ' ' + nomeAnimal + ' ' + this.ocorrenciaAtual.OrelhaDireitaGemeo;
              }
            } else {
              // se for SUFIXO => NOME + BRINCO + AFIXO
              if (this.ocorrenciaAtual.IsGemelar == IsGemelar.Sim) {
                this.ocorrenciaAtual.NomeGemeo = nomeAnimal + ' ' + this.ocorrenciaAtual.OrelhaDireitaGemeo + ' ' + afixoBovino + ' GÊMEO';
              } else {
                this.ocorrenciaAtual.NomeGemeo = nomeAnimal + ' ' + this.ocorrenciaAtual.OrelhaDireitaGemeo + ' ' + afixoBovino;
              }
            }
          }
        }
      }

      /*if (this.tipoEventoAssociado() === EnumTipoEventoSRG.Inovulacao)
        this.ocorrenciaAtual.Nome = this.ocorrenciaAtual.Nome.replace(` ${this.getTipoFivNomeAnimal()}`, '');
      if (!this.ocorrenciaAtual.Nome.includes(afixo)) {
        this.ocorrenciaAtual.Nome = afixo + ' ' + nomeAtual + ' ' + this.ocorrenciaAtual.OrelhaDireita;
      }
      if (setTipoImplantacao && this.tipoEventoAssociado() === EnumTipoEventoSRG.Inovulacao)
        this.ocorrenciaAtual.Nome = `${this.ocorrenciaAtual.Nome} ${this.getTipoFivNomeAnimal()}`;*/
    }
  }

  handleLimparNome() {
    this.ocorrenciaAtual.Nome = '';
  }

  handleLimparNomeGemeo() {
    this.ocorrenciaAtual.NomeGemeo = '';
  }

  handleOrelhaDireitaChange() {
    if (!this.ocorrenciaAtual.Brinco || this.ocorrenciaAtual.Brinco.length == 0) {
      this.ocorrenciaAtual.Brinco = this.ocorrenciaAtual.OrelhaDireita;
    }
    this.ocorrenciaAtual.Nome = '';
  }

  handleOrelhaDireitaChangeGemeo() {
    if (!this.ocorrenciaAtual.BrincoGemeo || this.ocorrenciaAtual.BrincoGemeo.length == 0) {
      this.ocorrenciaAtual.BrincoGemeo = this.ocorrenciaAtual.OrelhaDireitaGemeo;
    }
    this.ocorrenciaAtual.NomeGemeo = '';
  }

  async obterGrauSangue(idReprodutor: string, idMatriz: string) {
    //consulta os detalhes dos pais para pergar o grau de sangue
    const promisePai = await this.api.consultarDetalhesBovino(idReprodutor, true);
    const promiseMae = await this.api.consultarDetalhesBovino(idMatriz, true);

    Promise.all([promisePai, promiseMae]).then(res => {
      const [responsePai, responseMae] = res;

      if (responsePai) {
        if (responsePai.success) {
          this.ocorrenciaAtual.GrauDeSanguePai = responsePai.Bovino.TipoGrauSangue;
          this.porcentagemPai = responsePai.Bovino.GrauSangue;
        }
      }
      //chama o servide para calcular o grau do produto
      if (responseMae) {
        if (responseMae.success) {
          this.ocorrenciaAtual.GrauDeSangueMae = responseMae.Bovino.TipoGrauSangue;
          this.porcentagemMae = responseMae.Bovino.GrauSangue;

          if (this.ocorrenciaAtual.GrauDeSangueMae !== null
            && this.ocorrenciaAtual.GrauDeSangueMae !== ""
            && this.ocorrenciaAtual.GrauDeSanguePai !== null
            && this.ocorrenciaAtual.GrauDeSanguePai !== ""
          ) {
            this.ocorrenciaAtual.GrauDeSangueProduto = this.calculoGrauSangueService.calculaGrauSangue(this.ocorrenciaAtual.GrauDeSanguePai, this.ocorrenciaAtual.GrauDeSangueMae);

            if (this.ocorrenciaAtual.GrauDeSangueProduto) {
              this.porcentagemProduto = this.calculoGrauSangueService.calcularPercentual(this.ocorrenciaAtual.GrauDeSangueProduto);
            }
          }
        }
      }
      //concatena a porcentagem e o grau para mostrar na tela
      this.grauDeSangueMae = this.bovinoService.getLabelTipoGrauSangue(this.ocorrenciaAtual.GrauDeSangueMae as TipoGrauSangue) + " - " + this.porcentagemMae ?? '';
      this.grauDeSanguePai = this.bovinoService.getLabelTipoGrauSangue(this.ocorrenciaAtual.GrauDeSanguePai as TipoGrauSangue) + " - " + this.porcentagemPai ?? '';
      this.grauDeSangueProduto = this.bovinoService.getLabelTipoGrauSangue(this.ocorrenciaAtual.GrauDeSangueProduto as TipoGrauSangue) + " - " + this.porcentagemProduto ?? '';


    })
    if (this.porcentagemProduto === null) {
      this.botaoLimparSugestao();
      this.grauDeSangueMae = "";
      this.grauDeSanguePai = "";
      this.grauDeSangueProduto = "";
      this.porcentagemMae = "";
      this.porcentagemPai = "";
      this.porcentagemProduto = "";
      Swal.fire('Erro ao recuperar os dados da Cobertura/Implantação. Tente novamente.', '', 'error');
    }
  }

  showOrigemPelaFiv() {
    if (this.ocorrenciaAtual.OcorrenciaEventoInovulacao.OcorrenciaEventoFiv != undefined
      && this.ocorrenciaAtual.OcorrenciaEventoInovulacao.OcorrenciaEventoFiv.Id != undefined
      && this.ocorrenciaAtual.OcorrenciaEventoInovulacao.OcorrenciaEventoFiv.Id != -1) {
      return true;
    } else {
      return false;
    }
  }

  showOrigemPelaInov() {
    if (this.ocorrenciaAtual.OcorrenciaEventoInovulacao.OcorrenciaEventoFiv != undefined
      && this.ocorrenciaAtual.OcorrenciaEventoInovulacao.OcorrenciaEventoFiv.Id != undefined
      && this.ocorrenciaAtual.OcorrenciaEventoInovulacao.OcorrenciaEventoFiv.Id != -1) {
      return false;
    } else {
      return true;
    }
  }

}
