<div class="card card-body" style="margin-top: 1em;">

    <div class="row">
  
      <div class="col-lg-6 col-md-7 col-sm-12 col-xs-12">     
        <app-consultar-bovino [bovino]="formRegistrar.animal" (BovinoEncontrado)="selecionaTipoCertificado()"></app-consultar-bovino>
      </div>

      <div class="form-group col-lg-4 col-md-5 col-sm-12 col-xs-12">
        <label>Proprietário</label>
        <input disabled type="text" class="form-control form-control-sm" [(ngModel)]="formRegistrar.animal.CodigoNomeProprietario" >
      </div>

      <div class="form-group col-lg-2 col-md-4 col-sm-6 col-xs-12">
        <label for="dataEmissao">Data de Emissão</label>
        <div class="input-group input-group-sm">
          <input
            class="form-control"
            [matDatepicker]="dataEmissao"
            [(ngModel)]="formRegistrar.dataEmissao"
            [disabled]="readOnly"
          />
          <div *ngIf="!readOnly" class="input-group-append">
            <button
              class="btn btn-outline-secondary"
              type="button"
              (click)="dataEmissao.open()"
            >
              <i class="bi bi-calendar3"></i>
            </button>
          </div>
        </div>
        <mat-datepicker [startAt]="formRegistrar.dataEmissao" #dataEmissao></mat-datepicker>
      </div>

      <div class="form-group col-lg-2 col-md-6 col-sm-6 col-xs-12">
        <label for="formularioProtocolo">Certificado</label>
        <select [disabled]="inspecaoLocalizada" class="form-control form-control-sm" [(ngModel)]="formRegistrar.certificado">
          <option value="-1" label="Selecione"></option>
          <option value="1" label="Definitivo"></option>
          <!-- <option value="2" label="Desempenho"></option> -->
          <option *ngIf="contexto === 'wagyu'" value="4" label="Abate"></option>
          <option value="5" label="Nascimento"></option>
        </select>
      </div>
  
    </div>
  
    <div *ngIf='!readOnly' class="row justify-content-end" id="botao-container">
      
      <div class="col-lg-4 col-md-10 col-sm-10 col-xs-12">
        <button 
          type="button" 
          (click)="botaoEnviar()"
          class="btn btn-sm btn-block btn-primary"
        >
          Enviar
        </button>
      </div>
    
    </div>
  
  </div>