import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BovinoService } from 'src/app/bovino/shared/bovino.service';
import { CriadorModel } from 'src/app/models/CriadorModel';
import { ItemAscendenciaNacionalizacaoModel } from 'src/app/models/ItemAscendenciaNacionalizacaoModel';
import { TipoGrauSangue } from 'src/app/shared/enums/Enums';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cadastro-animal-ascendencia',
  templateUrl: './cadastro-animal-ascendencia.component.html',
  styleUrls: ['./cadastro-animal-ascendencia.component.scss']
})
export class CadastroAnimalAscendenciaComponent implements OnInit {

  readonly maxDate = new Date();
  readonly env = environment;

  proprietario: CriadorModel = new CriadorModel();
  racas: { value: string, label: string}[] = [];
  grauSangue: { value: string, label: string }[] = [];
  tiposGrauSangue: { value: number, label: string }[] = [];

  @Input() permiteCodigoCentral: boolean = false;
  @Input() bovino: ItemAscendenciaNacionalizacaoModel = new ItemAscendenciaNacionalizacaoModel();
  @Output() bovinoParaSalvar: EventEmitter<ItemAscendenciaNacionalizacaoModel> = new EventEmitter<ItemAscendenciaNacionalizacaoModel>();

  constructor(
    private bovinoService: BovinoService,
  ) { }

  ngOnInit(): void {
    this.tiposGrauSangue = this.bovinoService.getTiposGrauSangue();
    this.proprietario.tituloConsulta = 'Proprietário';
    this.atualizarGrauSanguePorTipo(TipoGrauSangue.PO);
    this.proprietario.nome = this.bovino.Proprietario === '-1' ? '' : this.bovino.Proprietario;

    this.preencherRacas();
  }

  private preencherRacas() {
    this.racas = [];

    if ((this.env as any).contexto === 'wagyu') {
      this.racas.push({ value: 'WAGYU KUROGE', label: 'Wagyu Kuroge' });
      this.racas.push({ value: 'WAGYU AKAUSHI', label: 'Wagyu Akaushi' });
    } else if ((this.env as any).contexto === 'jersey') {
      this.racas.push({ value: 'Jersey', label: 'Jersey' });
    }
  }

  proprietarioSelecionado(id: string) {
    this.bovino.ProprietarioId = id;
    this.bovino.Proprietario = `${this.proprietario.codigo} - ${this.proprietario.nome}`;
  }

  botaoSalvar() {
    this.bovinoParaSalvar.emit(this.bovino);
  }

  atualizarGrauSanguePorTipo(tipo: number): void {
    this.grauSangue = this.bovinoService.getGrauSanguePorTipo(tipo);
  }

}
