<div class="card" style="margin-top: 1em;">
  <div class="card-body">
    <div *ngIf="exibirFiltrosConsulta" >
      <app-filtro-consulta-eventos [formConsulta]="formConsulta" (triggerConsulta)="botaoConsultar()"></app-filtro-consulta-eventos>

      <div class="row justify-content-end">
        <div *ngIf="permiteNovo"  class="col-lg-2 col-md-4 col-sm-6">
          <button type="button" class="btn btn-sm btn-block btn-primary" (click)="novo()" >Novo</button>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-6">
          <button type="button" class="btn btn-sm btn-block btn-primary" (click)="botaoConsultar()" >Buscar</button>
        </div>
      </div>
    </div>

    <div *ngIf="eventos.length > 0"  class="row" style="margin-top: 15px;">
      <div class="col-lg-12 table-responsive">
        <table class="table table-hover table-header-custom">
          <thead>
            <tr>
              <th class="text-center">Nº Formulário / Protocolo</th>
              <th class="text-center">Data Comunicação</th> 
              <th>Situação</th> 
              <th>Tipo</th> 
              <th>Mãe</th> 
              <th>Pai</th> 
              <th>Nome do Animal</th> 
              <th>Nascimento</th> 
              <th class="text-center">{{ env.contexto === 'wagyu'? 'Cobertura' : 'Padreação'  }}</th> 
              <th class="text-center" width="10%"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let ev of eventos | paginate : {itemsPerPage: pageSize, currentPage: page, totalItems: count};">
              <td class="text-center">{{ ev.Protocolo }}</td>
              <td class="text-center">{{ev.DescDataComunicacao}}</td>
              <td>{{ ev.DescSituacao }}</td> 
              <td>{{ ev.TipoEvento }}</td> 
              <td>{{ ev.NomeMatriz }}</td> 
              <td>{{ ev.NomeReprodutor }}</td> 
              <td>{{ ev.NomeAnimal }}</td> 
              <td>{{ ev.DataNascimento | date: 'dd/MM/yyyy' }}</td> 
              <td class="text-center">{{ ev.DataPadreacao | date: 'dd/MM/yyyy' }}</td>
              <td class="text-center text-nowrap">
                <app-opcoes-retorno-consulta-ocorrencia [evento]="ev"></app-opcoes-retorno-consulta-ocorrencia>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="col-md-12 text-center">
        <pagination-controls 
          class="srg-paginacao"
          previousLabel="Anterior" 
          nextLabel="Próximo" 
          [responsive]="true" 
          (pageChange)="handlePageChange($event)"
          [autoHide]="true"
        >
        </pagination-controls>
      </div>
    
    </div>

  </div>
</div>