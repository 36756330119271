<app-formulario-tabs
  [formulario]="formulario"
  [listaComunicacao]="lote?.ListaComunicacao"
  (tabSelecionada)="tabSelecionada($event)"
  (criadorSelecionado)="criadorSelecionado($event)"
></app-formulario-tabs>

<div class="row">
  <div class="col-lg-12 col-sm-12">
    <div class="card card-principal-evento">
      <div class="card-body">
        <!--Ocorrencia Atual-->
        <div *ngIf="!isNewLote()">
          <div class="row">
            <div class="col-lg-12">
              <div class="card card-evento">
                <div class="card-body">
                  <div class="row card-title-evento">
                    <div class="col-6">
                      <h4 class="card-title">
                        <mat-icon svgIcon="nacionalizacao"></mat-icon> Dados de Nacionalização de Sêmen
                      </h4>
                    </div>
                    <div class="col-6 text-right">
                      <button
                        *ngIf="ocorrenciaAtual.Id !== '-1'"
                        type="button"
                        class="btn btn-link btn-sm"
                        (click)="documentosDaOcorrencia()"
                      >
                        <i class="far fa-folder"></i> Documentos
                      </button>
                    </div>
                  </div>  

                  <div class="row">

                    <div class="form-group col-lg-2 col-md-3 col-sm-6 col-xs-12">
                      <label>*Código</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        name="codigo"
                        [(ngModel)]="ocorrenciaAtual.Codigo"
                        [disabled]="isOcorrenciaReadOnly() || env.contexto === 'wagyu'"
                      />
                    </div>

                    <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <label>*Nome</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        name="nome"
                        [(ngModel)]="ocorrenciaAtual.Nome"
                        [disabled]="isOcorrenciaReadOnly()"
                      />
                    </div>

                    <div class="form-group col-lg-2 col-md-3 col-sm-6 col-xs-12">
                      <label>*Sexo</label>
                      <select
                        disabled
                        name="sexo"
                        class="form-control form-control-sm"
                        [(ngModel)]="ocorrenciaAtual.Sexo"
                      >
                        <option value="1">Macho</option>
                        <option value="2">Fêmea</option>
                      </select>
                    </div>

                    <div class="form-group col-lg-2 col-md-3 col-sm-6 col-xs-12">
                      <label>*Nascimento</label>
                      <div class="input-group input-group-sm">
                          <input class="form-control"  [max]="maxDate" [matDatepicker]="DataCobertura" name="DataCobertura" [(ngModel)]="ocorrenciaAtual.DataNascimento" [disabled]="isOcorrenciaReadOnly()">
                          <div *ngIf="!isOcorrenciaReadOnly()" class="input-group-append">
                            <button class="btn btn-outline-secondary" type="button" (click)="DataCobertura.open()"> <i class="bi bi-calendar3"></i> </button>
                          </div>
                      </div>
                      <mat-datepicker [startAt]="ocorrenciaAtual.DataCobertura" #DataCobertura></mat-datepicker>
                    </div>
                    
                    <div class="form-group col-lg-2 col-md-4 col-sm-6 col-xs-12">
                      <label>*País Origem</label>
                      <select
                        name="pais_origem"
                        class="form-control form-control-sm"
                        [(ngModel)]="ocorrenciaAtual.PaisOrigem"
                        [disabled]="isOcorrenciaReadOnly()"
                      >
                        <option *ngFor="let sigla of siglasPaises" [value]="sigla.value" [label]="sigla.label"></option>
                      </select>
                    </div>

                    <div class="form-group col-lg-4 col-md-5 col-sm-6 col-xs-12">
                      <label>{{ env.contexto === 'jersey'? '*' : ''}}Tatuagem Origem</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        name="tatuagem_origem"
                        [(ngModel)]="ocorrenciaAtual.Tatuagem"
                        [disabled]="isOcorrenciaReadOnly()"
                      />
                    </div>

                    <div class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
                      <label>{{ env.contexto === 'jersey'? '*' : ''}}Nº Registro Origem</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        name="numero_registro_origem"
                        [(ngModel)]="ocorrenciaAtual.NumeroRegistroOrigem"
                        [disabled]="isOcorrenciaReadOnly()"
                      />
                    </div>

                    <div class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
                      <label>*Código Central</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        name="CodigoCentral"
                        [(ngModel)]="ocorrenciaAtual.CodigoCentral"
                        [disabled]="isOcorrenciaReadOnly()"
                      />
                    </div>

                    <div class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
                      <label>*Raça</label>
                      <select
                        name="raca"
                        [disabled]="isOcorrenciaReadOnly() || env.contexto === 'jersey'"
                        class="form-control form-control-sm"
                        [(ngModel)]="ocorrenciaAtual.Raca"
                      >
                        <option *ngFor="let raca of racas" [value]="raca.value" [label]="raca.label"></option>
                      </select>
                    </div>

                    <div class="form-group col-lg-2 col-md-4 col-sm-6 col-xs-12">
                      <label>*Tipo</label>
                      <select
                        name="tipo"
                        class="form-control form-control-sm"
                        [(ngModel)]="ocorrenciaAtual.TipoGrauSangue"
                        (ngModelChange)="atualizarGrauSanguePorTipo($event)"
                        [disabled]="isOcorrenciaReadOnly()"
                      >
                        <option *ngFor="let tipo of tiposGrauSangue" [value]="tipo.value" [label]="tipo.label"></option>
                      </select>
                    </div>

                    <div class="form-group col-lg-2 col-md-4 col-sm-6 col-xs-12">
                      <label>*Grau Sangue</label>
                      <select
                        name="grau_sangue"
                        class="form-control form-control-sm"
                        [(ngModel)]="ocorrenciaAtual.GrauSangue"
                        [disabled]="isOcorrenciaReadOnly()"
                      >
                        <option *ngFor="let grau of grauSangue" [value]="grau.value" [label]="grau.label"></option>
                      </select>
                    </div>

                    <!-- ascendencia -->
                    
                    <table 
                      class="table table-borderless" 
                      style="margin-top: 30px; padding: 10px;" 
                      [ngClass]="{'ascendencia-completa': ocorrenciaAtual.Ascendencia.Bisavo1Paterno !== null, 'ascendencia-primeiro-nivel': ocorrenciaAtual.Ascendencia.Avo1Paterno === null, 'ascendencia-exceto-terceiro-nivel': ocorrenciaAtual.Ascendencia.Avo1Paterno !== null && ocorrenciaAtual.Ascendencia.Bisavo1Paterno === null}"
                    >
                      <thead>
                        <tr>
                          <th class="text-center" width="33%">Pais</th> 
                          <th class="text-center" width="34%">
                            Avós
                            <a *ngIf="!isOcorrenciaReadOnly()" type="button" [matTooltip]="ocorrenciaAtual.Ascendencia.Avo1Paterno === null? 'Adicionar avós': 'Remover avós'">
                              <i 
                                class="fas fa-plus" 
                                style="color: black;" 
                                (click)="setAvos(true)"
                                *ngIf="ocorrenciaAtual.Ascendencia.Avo1Paterno === null" 
                              ></i>
                              <i 
                                class="fas fa-minus" 
                                style="color: black;" 
                                (click)="setAvos(false)"
                                *ngIf="ocorrenciaAtual.Ascendencia.Avo1Paterno !== null" 
                              ></i>
                            </a>
                          </th> 
                          <th class="text-center" width="33%">
                            Bisavós
                            <a *ngIf="!isOcorrenciaReadOnly()" type="button" [matTooltip]="ocorrenciaAtual.Ascendencia.Bisavo1Paterno === null? 'Adicionar bisavós': 'Remover bisavós'">
                              <i 
                                class="fas fa-plus" 
                                style="color: black;" 
                                (click)="setBisavos(true)"
                                *ngIf="ocorrenciaAtual.Ascendencia.Bisavo1Paterno === null" 
                              ></i>
                              <i 
                                class="fas fa-minus" 
                                style="color: black;" 
                                (click)="setBisavos(false)"
                                *ngIf="ocorrenciaAtual.Ascendencia.Bisavo1Paterno !== null" 
                              ></i>
                            </a>
                          </th> 
                        </tr>
                      </thead>

                      <tbody>
                        <!-- Bisavo1Paterno -->
                        <tr *ngIf="ocorrenciaAtual.Ascendencia.Bisavo1Paterno !== null">
                          <td></td>
                          <td></td>
                          <td 
                            [class]="getBackgroundColor(ocorrenciaAtual.Ascendencia.Bisavo1Paterno)"
                            class="align-middle text-center mat-elevation-z3"
                          >
                            <app-item-ascendencia 
                              [tipoBusca]="TipoBusca.Macho"
                              (bovinoId)="bovinoSelecionado($event, TipoSexo.Macho, 2, 1)"
                              [idProprietario]="lote?.Criador?.Id"
                              (itemParaRemover)="limpar(TipoSexo.Macho, 2, 1)"
                              [(itemAscendencia)]="ocorrenciaAtual.Ascendencia.Bisavo1Paterno"
                              [permiteSalvarAprovado]="permiteSalvarAprovado()"
                            >
                            </app-item-ascendencia>
                          </td>
                        </tr>
                        <!-- Avo1Paterno -->
                        <tr *ngIf="ocorrenciaAtual.Ascendencia.Avo1Paterno !== null">
                          <td></td>
                          <td 
                            [class]="getBackgroundColor(ocorrenciaAtual.Ascendencia.Avo1Paterno)"
                            class="align-middle text-center mat-elevation-z3"
                          >
                            <app-item-ascendencia 
                              [tipoBusca]="TipoBusca.Macho"
                              (bovinoId)="bovinoSelecionado($event, TipoSexo.Macho, 1, 1)"
                              [idProprietario]="lote?.Criador?.Id"
                              (itemAdicionado)="atualizaBisavosPorAvoAdicionado(TipoSexo.Macho, 1)"
                              (itemParaRemover)="limpar(TipoSexo.Macho, 1, 1)"
                              [(itemAscendencia)]="ocorrenciaAtual.Ascendencia.Avo1Paterno"
                              [permiteSalvarAprovado]="permiteSalvarAprovado()"
                            >
                            </app-item-ascendencia>
                          </td>
                          <td></td>
                        </tr>
                        <!-- Bisavo2Paterno -->
                        <tr *ngIf="ocorrenciaAtual.Ascendencia.Bisavo2Paterno !== null">
                          <td></td>
                          <td></td>
                          <td 
                            [class]="getBackgroundColor(ocorrenciaAtual.Ascendencia.Bisavo2Paterno)"
                            class="align-middle text-center mat-elevation-z3"
                          >
                            <app-item-ascendencia 
                              (itemParaRemover)="limpar(TipoSexo.Macho, 2, 2)"
                              [tipoBusca]="TipoBusca['Fêmea']"
                              (bovinoId)="bovinoSelecionado($event, TipoSexo.Macho, 2, 2)"
                              [idProprietario]="lote?.Criador?.Id"
                              [(itemAscendencia)]="ocorrenciaAtual.Ascendencia.Bisavo2Paterno"
                              [permiteSalvarAprovado]="permiteSalvarAprovado()"
                            >
                            </app-item-ascendencia>
                          </td>
                        </tr>
                        <!-- Pai -->
                        <tr *ngIf="ocorrenciaAtual.Ascendencia.Pai !== null">
                          <td 
                            [class]="getBackgroundColor(ocorrenciaAtual.Ascendencia.Pai)"
                            class="align-middle text-center mat-elevation-z3"
                          >
                            <app-item-ascendencia 
                              [ocorrenciaReadOnly]="isOcorrenciaReadOnly()"
                              (bovinoId)="bovinoSelecionado($event, TipoSexo.Macho)"
                              [tipoBusca]="TipoBusca.Macho"
                              (itemAdicionado)="setAvos(true, TipoSexo.Macho)"
                              [idProprietario]="lote?.Criador?.Id"
                              (itemParaRemover)="limpar(TipoSexo.Macho)"
                              [(itemAscendencia)]="ocorrenciaAtual.Ascendencia.Pai"
                              [permiteSalvarAprovado]="permiteSalvarAprovado()"
                            >
                            </app-item-ascendencia>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                        <!-- Bisavo3Paterno -->
                        <tr *ngIf="ocorrenciaAtual.Ascendencia.Bisavo3Paterno !== null">
                          <td></td>
                          <td></td>
                          <td 
                            [class]="getBackgroundColor(ocorrenciaAtual.Ascendencia.Bisavo3Paterno)"
                            class="align-middle text-center mat-elevation-z3"
                          >
                            <app-item-ascendencia 
                              [tipoBusca]="TipoBusca.Macho"
                              (bovinoId)="bovinoSelecionado($event, TipoSexo.Macho, 2, 3)"
                              [idProprietario]="lote?.Criador?.Id"
                              (itemParaRemover)="limpar(TipoSexo.Macho, 2, 3)"
                              [(itemAscendencia)]="ocorrenciaAtual.Ascendencia.Bisavo3Paterno"
                              [permiteSalvarAprovado]="permiteSalvarAprovado()"
                            >
                            </app-item-ascendencia>
                          </td>
                        </tr>
                        <!-- Avo2Paterno -->
                        <tr *ngIf="ocorrenciaAtual.Ascendencia.Avo2Paterno !== null">
                          <td></td>
                          <td 
                            [class]="getBackgroundColor(ocorrenciaAtual.Ascendencia.Avo2Paterno)"
                            class="align-middle text-center mat-elevation-z3"
                          >
                            <app-item-ascendencia 
                              [tipoBusca]="TipoBusca['Fêmea']"
                              (bovinoId)="bovinoSelecionado($event, TipoSexo.Macho, 1, 2)"
                              [idProprietario]="lote?.Criador?.Id"
                              (itemAdicionado)="atualizaBisavosPorAvoAdicionado(TipoSexo.Macho, 2)"
                              (itemParaRemover)="limpar(TipoSexo.Macho, 1, 2)"
                              [(itemAscendencia)]="ocorrenciaAtual.Ascendencia.Avo2Paterno"
                              [permiteSalvarAprovado]="permiteSalvarAprovado()"
                            >
                            </app-item-ascendencia>
                          </td>
                          <td></td>
                        </tr>
                        <!-- Bisavo4Paterno -->
                        <tr *ngIf="ocorrenciaAtual.Ascendencia.Bisavo4Paterno !== null">
                          <td></td>
                          <td></td>
                          <td 
                            [class]="getBackgroundColor(ocorrenciaAtual.Ascendencia.Bisavo4Paterno)"
                            class="align-middle text-center mat-elevation-z3"
                          >
                            <app-item-ascendencia 
                              [tipoBusca]="TipoBusca['Fêmea']"
                              [idProprietario]="lote?.Criador?.Id"
                              (itemParaRemover)="limpar(TipoSexo.Macho, 2, 4)"
                              (bovinoId)="bovinoSelecionado($event, TipoSexo.Macho, 2, 4)"
                              [(itemAscendencia)]="ocorrenciaAtual.Ascendencia.Bisavo4Paterno"
                              [permiteSalvarAprovado]="permiteSalvarAprovado()"
                            >
                            </app-item-ascendencia>
                          </td>
                        </tr>

                        <tr><td></td><td></td><td></td>
                        
                        </tr>
                        <!-- Bisavo1Materno -->
                        <tr *ngIf="ocorrenciaAtual.Ascendencia.Bisavo1Materno !== null">
                          <td></td>
                          <td></td>
                          <td 
                            [class]="getBackgroundColor(ocorrenciaAtual.Ascendencia.Bisavo1Materno)"
                            class="align-middle text-center mat-elevation-z3"
                          >
                            <app-item-ascendencia 
                              [tipoBusca]="TipoBusca.Macho"
                              (bovinoId)="bovinoSelecionado($event, TipoSexo['Fêmea'], 2, 1)"
                              [idProprietario]="lote?.Criador?.Id"
                              (itemParaRemover)="limpar(TipoSexo['Fêmea'], 2, 1)"
                              [(itemAscendencia)]="ocorrenciaAtual.Ascendencia.Bisavo1Materno"
                              [permiteSalvarAprovado]="permiteSalvarAprovado()"
                            >
                            </app-item-ascendencia>
                          </td>
                        </tr>
                        <!-- Avo1Materno -->
                        <tr *ngIf="ocorrenciaAtual.Ascendencia.Avo1Materno !== null">
                          <td></td>
                          <td 
                            [class]="getBackgroundColor(ocorrenciaAtual.Ascendencia.Avo1Materno)"
                            class="align-middle text-center mat-elevation-z3"
                          >
                            <app-item-ascendencia 
                              [tipoBusca]="TipoBusca.Macho"
                              (bovinoId)="bovinoSelecionado($event, TipoSexo['Fêmea'], 1, 1)"
                              [idProprietario]="lote?.Criador?.Id"
                              (itemAdicionado)="atualizaBisavosPorAvoAdicionado(TipoSexo['Fêmea'], 1)"
                              (itemParaRemover)="limpar(TipoSexo['Fêmea'], 1, 1)"
                              [(itemAscendencia)]="ocorrenciaAtual.Ascendencia.Avo1Materno"
                              [permiteSalvarAprovado]="permiteSalvarAprovado()"
                            >
                            </app-item-ascendencia>
                          </td>
                          <td></td>
                        </tr>
                        <!-- Bisavo2Materno  -->
                        <tr *ngIf="ocorrenciaAtual.Ascendencia.Bisavo2Materno !== null">
                          <td></td>
                          <td></td>
                          <td 
                            [class]="getBackgroundColor(ocorrenciaAtual.Ascendencia.Bisavo2Materno)"
                            class="align-middle text-center mat-elevation-z3"
                          >
                            <app-item-ascendencia 
                              [tipoBusca]="TipoBusca['Fêmea']"
                              (bovinoId)="bovinoSelecionado($event, TipoSexo['Fêmea'], 2, 2)"
                              [idProprietario]="lote?.Criador?.Id"
                              (itemParaRemover)="limpar(TipoSexo['Fêmea'], 2, 2)"
                              [(itemAscendencia)]="ocorrenciaAtual.Ascendencia.Bisavo2Materno"
                              [permiteSalvarAprovado]="permiteSalvarAprovado()"
                            >
                            </app-item-ascendencia>
                          </td>
                        </tr>
                        <!-- Mae -->
                        <tr *ngIf="ocorrenciaAtual.Ascendencia.Mae !== null">
                          <td 
                            [class]="getBackgroundColor(ocorrenciaAtual.Ascendencia.Mae)"
                            class="align-middle text-center mat-elevation-z3"
                          >
                            <app-item-ascendencia 
                              [ocorrenciaReadOnly]="isOcorrenciaReadOnly()"
                              (bovinoId)="bovinoSelecionado($event, TipoSexo['Fêmea'])"
                              [tipoBusca]="TipoBusca['Fêmea']"
                              (itemAdicionado)="setAvos(true, TipoSexo['Fêmea'])"
                              [idProprietario]="lote?.Criador?.Id"
                              (itemParaRemover)="limpar(TipoSexo['Fêmea'])"
                              [(itemAscendencia)]="ocorrenciaAtual.Ascendencia.Mae"
                              [permiteSalvarAprovado]="permiteSalvarAprovado()"
                            >
                            </app-item-ascendencia>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                        <!-- Bisavo3Materno -->
                        <tr *ngIf="ocorrenciaAtual.Ascendencia.Bisavo3Materno !== null">
                          <td></td>
                          <td></td>
                          <td 
                            [class]="getBackgroundColor(ocorrenciaAtual.Ascendencia.Bisavo3Materno)"
                            class="align-middle text-center mat-elevation-z3"
                          >
                            <app-item-ascendencia 
                              [tipoBusca]="TipoBusca.Macho"
                              (bovinoId)="bovinoSelecionado($event, TipoSexo['Fêmea'], 2, 3)"
                              [idProprietario]="lote?.Criador?.Id"
                              (itemParaRemover)="limpar(TipoSexo['Fêmea'], 2, 3)"
                              [(itemAscendencia)]="ocorrenciaAtual.Ascendencia.Bisavo3Materno"
                              [permiteSalvarAprovado]="permiteSalvarAprovado()"
                            >
                            </app-item-ascendencia>
                          </td>
                        </tr>
                        <!-- Avo2Materno -->
                        <tr *ngIf="ocorrenciaAtual.Ascendencia.Avo2Materno !== null">
                          <td></td>
                          <td 
                            [class]="getBackgroundColor(ocorrenciaAtual.Ascendencia.Avo2Materno)"
                            class="align-middle text-center mat-elevation-z3"
                          >
                            <app-item-ascendencia 
                              [tipoBusca]="TipoBusca['Fêmea']"
                              (bovinoId)="bovinoSelecionado($event, TipoSexo['Fêmea'], 1, 2)"
                              [idProprietario]="lote?.Criador?.Id"
                              (itemAdicionado)="atualizaBisavosPorAvoAdicionado(TipoSexo['Fêmea'], 2)"
                              (itemParaRemover)="limpar(TipoSexo['Fêmea'], 1, 2)"
                              [(itemAscendencia)]="ocorrenciaAtual.Ascendencia.Avo2Materno"
                              [permiteSalvarAprovado]="permiteSalvarAprovado()"
                            >
                            </app-item-ascendencia>
                          </td>
                          <td></td>
                        </tr>
                        <!-- Bisavo4Materno -->
                        <tr *ngIf="ocorrenciaAtual.Ascendencia.Bisavo4Materno !== null">
                          <td></td>
                          <td></td>
                          <td 
                            [class]="getBackgroundColor(ocorrenciaAtual.Ascendencia.Bisavo4Materno)"
                            class="align-middle text-center mat-elevation-z3"
                          >
                            <app-item-ascendencia 
                              [tipoBusca]="TipoBusca['Fêmea']"
                              (bovinoId)="bovinoSelecionado($event, TipoSexo['Fêmea'], 2, 4)"
                              [idProprietario]="lote?.Criador?.Id"
                              (itemParaRemover)="limpar(TipoSexo['Fêmea'], 2, 4)"
                              [(itemAscendencia)]="ocorrenciaAtual.Ascendencia.Bisavo4Materno"
                              [permiteSalvarAprovado]="permiteSalvarAprovado()"
                            >
                            </app-item-ascendencia>
                          </td>
                        </tr>

                      </tbody>
                    </table>
                    
                  </div>

                </div>
              </div>
            </div>

            <div class="col-lg-12">
              <app-botoes-acoes-ocorrencia
                [lote]="lote"
                [idOcorrencia]="ocorrenciaAtual.Id"
                [permiteSalvarAprovado]="permiteSalvarAprovado()"
                (acaoSelecionada)="acaoBotao($event)"
              ></app-botoes-acoes-ocorrencia>
            </div>
          </div>

          <!--Lista de Ocorrencias-->
          <div
            class="row"
            *ngIf="ocorrencias.length > 0"
            style="margin-top: 15px"
          >
            <div class="col-lg-12 table-responsive">
              <table class="table table-hover table-header-custom">
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>Data Nascimento</th>
                    <th>Código Central</th>
                    <th>Situação</th>
                    <th width="10%"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let ev of ocorrencias"
                    [ngClass]="{'ocorrencia-em-destaque': ev.Id === ocorrenciaAtual.Id}"
                  >
                    <td> {{ ev.Nome }} </td>
                    <td> {{ ev.DataNascimento | date: 'dd/MM/yyyy' }} </td>
                    <td> {{ ev.CodigoCentral }} </td>
                    <td> {{ commons.getDescricaoSituacao(ev.Situacao) }} </td>
                    <td class="text-center text-nowrap acoes-evento">
                      <i
                        *ngIf="ev.ImputaMulta === 1"
                        matTooltip="Multa Imputada"
                        class="fas fa-dollar-sign"
                      ></i>
                      <i
                        *ngIf="ev.SolicitarDna === 1"
                        matTooltip="Será solicitado DNA no Nascimento"
                        class="fas fa-dna"
                      >
                      </i>
                      <i
                        *ngIf="permiteEdicao(ev.Situacao)"
                        class="fas fa-edit"
                        (click)="editar(ev)"
                      ></i>
                      <i
                        *ngIf="!permiteEdicao(ev.Situacao)"
                        class="fas fa-eye"
                        (click)="editar(ev)"
                      ></i>
                      <i
                        *ngIf="lote.Situacao === 1"
                        class="fas fa-trash-alt"
                        (click)="deletar(ev)"
                      ></i>
                      <i
                        *ngIf="ev.IndInconsitencia"
                        class="fa fa-exclamation-triangle"
                        style="cursor: pointer"
                        matTooltip="{{ ev.Inconsistencia }}"
                      ></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
