import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Commons } from 'src/app/shared/commons';

@Component({
  selector: 'app-comunicacoes-modal',
  templateUrl: './comunicacoes-modal.component.html',
  styleUrls: ['./comunicacoes-modal.component.scss']
})
export class ComunicacoesModalComponent implements OnInit {

  listaComunicacao: any[] = [];

  constructor(
    public commons: Commons,
    public dialogRef: MatDialogRef<ComunicacoesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.listaComunicacao = this.data.listaComunicacao;

    if(this.listaComunicacao){
      this.listaComunicacao.forEach(element => {
        element.DescSituacaoLote = this.commons.getDescricaoSituacaoLote(element.SituacaoLote);
      })
    }
  }
}
