import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Router } from '@angular/router';
import { BovinoService } from 'src/app/bovino/shared/bovino.service';
import { CriadorModel, ValidaCriadorModel } from 'src/app/models/CriadorModel';
import { FazendaModel } from 'src/app/models/FazendaModel';
import { ApiService } from 'src/app/shared/api.service';
import { Commons } from 'src/app/shared/commons';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-criador-manter',
  templateUrl: './criador-manter.component.html',
  styleUrls: ['./criador-manter.component.scss']
})
export class CriadorManterComponent implements OnInit {

  readonly env = environment;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  listaRacas: { label: string, value: string }[] = [];
  @ViewChild('racasInput') racasInput: ElementRef<HTMLInputElement> | null = null;

  @Input() readOnly: boolean = false;
  @Input() criador: CriadorModel = new CriadorModel();
  @Input() formValidaCriador: ValidaCriadorModel = new ValidaCriadorModel();

  constructor(
    private router: Router,
    private api: ApiService,
    private commons: Commons,
    private bovinoService: BovinoService,
  ) { }

  ngOnInit(): void {
    if ((this.env as any).contexto === 'wagyu')
      this.listaRacas = this.bovinoService.getListaRacas(['manter_criador']);
  }

  procuradoresDoCriador() {

  }

  async botaoEnviar(): Promise<void> {

    if ((this.env as any).contexto === 'wagyu' && this.criador.afixos.length === 0) {
      const response = await this.commons.modalConfirmacao({
        title: 'Nenhum afixo foi informado. Deseja salvar o cadastro mesmo assim?'
      });
      if (!response) return;
    }

    if ((this.env as any).contexto === 'jersey' && !this.criador.afixo) {
      const response = await this.commons.modalConfirmacao({
        title: 'O afixo não foi informado. Deseja salvar o cadastro mesmo assim?'
      });
      if (!response) return;
    }

    this.api.registrarCriador(this.criador, this.formValidaCriador).then((result: any) => {
      if (result) {
        if (result.success) {
          this.criador = new CriadorModel();
          this.formValidaCriador = new ValidaCriadorModel();
          Swal.fire(result.message, '', 'success');
          this.router.navigate(['criadores_consultar']);
        } else {
          Swal.fire(result.message, '', 'error');
        }
      } else {
        Swal.fire('A operação não pode ser realizada.', '', 'error');
      }
    });
  }

  adicionarMaisFazenda(): void {
    this.criador.fazendas.push(new FazendaModel());
  }

  removerFazenda(index_fazenda: number): void {
    this.criador.fazendas = this.criador.fazendas.filter((item, index) => index !== index_fazenda && item);
  }

  removerRaca(raca: string): void {
    const index = this.criador.racas.indexOf(raca);
    if (index >= 0) this.criador.racas.splice(index, 1);
  }

  racaSelecionada(event: MatAutocompleteSelectedEvent): void {
    if (this.racasInput) {
      this.racasInput.nativeElement.blur();
      this.racasInput.nativeElement.value = '';
    }

    const raca: string[] = this.criador.racas.filter((item: string) => item === event.option.value);
    if (raca.length !== 0) return;

    this.criador.racas.push(event.option.value as string);
  }

  getLabelRaca(raca_id: string): string {
    const raca: { label: string, value: string }[] = this.listaRacas.filter(item => item.value === raca_id);
    return raca[0].label;
  }

}
