import { ClasseClassificacaoLinear } from "../shared/enums/Enums";
import { BovinoModel } from "./BovinoModel";
import { InspetorModel } from "./InspetorModel";
import { OcorrenciaEventoModel } from "./OcorrenciaEventoModel";

export class OcorrenciaEventoClassificacaoLinearModel extends OcorrenciaEventoModel {
  Inspetor: InspetorModel;
  InspetorId: string;
  NomeInspetor: string;
  Touro: BovinoModel;
  Animal: BovinoModel; 
  Pontos: number | string;
  DataClassificacao: string;
  Classe: ClasseClassificacaoLinear;
  Lactacoes: number | string;
  DataUltimoParto: string;
  Descricao: string;
  DataNascimento: string;

  // garupa
  ScoreGarupa: number | string = "";
  AnguloGarupa: number | string = "";
  LarguraDaGarupa: number | string = "";
  ForcaLombo: number | string = "";
  AnusAvancado: boolean = false;
  InsCaudaAvancada: boolean = false;
  InsCaudaRecuada: boolean = false;
  InsCaudaAlta: boolean = false;
  CaudaTorcida: boolean = false;

  // pes & pernas
  ScorePesPernas: number | string = "";
  AnguloDeCascos: number | string = "";
  ProfundidadeTalao: number | string = "";
  QualidadeOssea: number | string = "";
  PernasPosVLat: number | string = "";
  PernasPosVPost: number | string = "";
  Locomocao: number | string = "";
  UnhaTorcida: boolean = false;
  QuartelaFraca: boolean = false;
  DerrameJarretes: boolean = false;
  InsufucienciaOssea: boolean = false;
  Caimbra: boolean = false;
  CoxoFemAtrasAvanc: boolean = false;
  PesParaFora: boolean = false;

  // forca leiteira
  ScoreForcaLeiteira: number | string = "";
  Estatura: number | string = "";
  NivelamentoLinhaSuperior: number | string = "";
  LarguraDePeito: number | string = "";
  ProfdeCorporal: number | string = "";
  Angulosidade: number | string = "";
  DesvioDeChanfro: boolean = false;
  MandibulaDefeito: boolean = false;
  CostelasAntApert: boolean = false;
  RetroEscapulaFraca: boolean = false;
  DorsoFraco: boolean = false;
  FaltaArqueamento: boolean = false;
  FaltaDeEquilibrio: boolean = false;

  // sistema mamario
  ScoreSistemaMamario: number | string = "";
  InsercaoUbereAnterior: number | string = "";
  ColocacaoTetosAnt: number | string = "";
  ComprimentoTetos: number | string = "";
  AlturaUberePosterior: number | string = "";
  LarguraUberePosteiror: number | string = "";
  ColocacaoTetosPost: number | string = "";
  ProfundidadeDoUbere: number | string = "";
  TexturaDoUbere: number | string = "";
  LigamentoMediano: number | string = "";
  PisoUbInclinadoAnt: boolean = false;
  PisoUbInclinadoPost: boolean = false;
  InsAnteriorCurta: boolean = false;
  InsPosteriorCurta: boolean = false;
  FaltaDeConformUbere: boolean = false;
  QuartoDesbalanceado: boolean = false;
  QuartoCego: boolean = false;
  TetoPalmipede: boolean = false;
  TetoAntParaTraz: boolean = false;
  TetoPosParaTraz: boolean = false;

  constructor() {
    super();
    this.Classe = -1;
    this.Pontos = "";
    this.Lactacoes = "";
    this.Descricao = "";
    this.DataNascimento = "";
    this.DataUltimoParto = "";
    this.DataClassificacao = "";
    this.Touro = new BovinoModel();
    this.Animal = new BovinoModel();
    this.InspetorId = "";
    this.NomeInspetor = "";
    this.Inspetor = new InspetorModel();
  }
}