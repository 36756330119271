<div class="col-md-12">
	<div class="row">
		<div class="col-md-12">
			<div class="row">
				<div class="col-md-2">
					<label for="CodigoBovino">Código</label>
					<input [disabled]="readOnly" aria-readonly="true" type="text" class="form-control form-control-sm ng-untouched ng-pristine ng-valid" name="CodigoBovino" [(ngModel)]="bovino.Codigo" autocomplete="off">
				</div>

				<div class="col-md-5">
					<label for="NomeBovino">Nome</label>
					<!-- (focusout)="formatarNomeBovino(env.contexto === 'wagyu', false)" 
					(focusin)="formatarNomeBovino(env.contexto === 'wagyu', true)"  -->
					<input 
						[disabled]="readOnly" 
						aria-readonly="true" 
						type="text" 
						class="form-control form-control-sm ng-untouched ng-pristine ng-valid" 
						name="NomeBovino" 
						[(ngModel)]="bovino.Nome" 
						autocomplete="off"
					>
				</div>
				
				<div class="col-md-2">
					<label for="OrelhaDireitaBovino">Orelha {{ env.contexto === 'jersey'? 'Direita' : 'Esquerda' }}</label>
					<!-- (focusout)="formatarNomeBovino(env.contexto === 'wagyu', false)" 
					(focusin)="formatarNomeBovino(env.contexto === 'wagyu', true)"  -->
					<input 
						[disabled]="readOnly" 
						aria-readonly="true" 
						type="text" 
						class="form-control form-control-sm ng-untouched ng-pristine ng-valid" 
						name="OrelhaDireitaBovino" 
						[(ngModel)]="bovino.OrelhaDireita" 
						autocomplete="off"
					>
				</div>
				
				<div class="col-md-3">
					<label for="SituacaoSRGBovino">Situação SRG</label>
					<select [disabled]="readonlyStatus" aria-disabled="true" class="form-control form-control-sm ng-pristine ng-valid ng-touched" [(ngModel)]="bovino.SituacaoSrg">
						<option *ngFor="let situacao of situacoesSRG" [value]="situacao.value" [label]="situacao.label"></option>
					</select>
				</div>
			</div>

			<div class="row mt-2">
				<div class="col-lg-2">
					<label>Data Nascimento</label>
					<!-- Para mostrar a data vazia caso seja readonly e 01/01/0001 -->
					<div *ngIf="bovino.DataNascimento === '0001-01-01T00:00:00' && readOnly">
						<div class="input-group input-group-sm">
							<input [disabled]="readOnly" class="form-control" name="data_nascimento" [matDatepicker]="dataNascimentoEmpty"> 
							<div class="input-group-append">
								<button class="btn btn-outline-secondary" type="button" (click)="dataNascimentoEmpty.open()"> <i class="bi bi-calendar3"></i> </button>
							</div>
						</div>
						<mat-datepicker [startAt]="" #dataNascimentoEmpty></mat-datepicker>
					</div>

					
					<div *ngIf="!readOnly || bovino.DataNascimento !== '0001-01-01T00:00:00'">
						<div class="input-group input-group-sm">
							<input [disabled]="readOnly" class="form-control" name="data_nascimento" [matDatepicker]="dataNascimento" [(ngModel)]="bovino.DataNascimento"> 
							<div class="input-group-append">
								<button class="btn btn-outline-secondary" type="button" (click)="dataNascimento.open()"> <i class="bi bi-calendar3"></i> </button>
							</div>
						</div>
						<mat-datepicker [startAt]="bovino.DataNascimento" #dataNascimento></mat-datepicker>
					</div>

				</div>


				<div class="col-md-2">
					<label for="SexoBovino">Sexo</label>
					<select [disabled]="readOnly" aria-disabled="true" [(ngModel)]="bovino.Sexo" class="form-control form-control-sm ng-pristine ng-valid ng-touched">
						<option value="-1" label="Selecione..."></option>
						<option value="1" label="Macho"></option>
						<option value="2" label="Fêmea"></option>
					</select>
				</div>
				
				<div [ngClass]="{ 'col-md-2': env.contexto === 'wagyu', 'col-md-3': env.contexto === 'jersey' }">
					<label for="RacaBovino">*Raça</label>
					<select class="form-control form-control-sm" name="RacaBovino" [(ngModel)]="bovino.Raca" (ngModelChange)="atualizarTiposPorRaca($event)" [disabled]="readOnly" >
					  <option *ngFor="let raca of racas" [value]="raca.value" [label]="raca.label"></option>
					</select>
				</div>

				<div class="form-group col-lg-2 col-md-6 col-sm-6 col-xs-12">
					<label for="formularioProtocolo">*Tipo</label>
					<select class="form-control form-control-sm" name="tipo" [(ngModel)]="bovino.TipoGrauSangue" (ngModelChange)="atualizarGrauSanguePorTipo($event)" [disabled]="readOnly">
					  <option *ngFor="let tipo of tipoSanguineo" [value]="tipo.value" [label]="tipo.label"></option>
					</select>
				  </div>
				  
				  <div [ngClass]="{ 'col-md-2': env.contexto === 'wagyu', 'col-md-3': env.contexto === 'jersey' }">
					  <label for="formularioProtocolo">*Grau Sangue</label>
					<select class="form-control form-control-sm" name="grau_sangue" [(ngModel)]="bovino.GrauSangue" [disabled]="readOnly">
					  <option *ngFor="let gs of grauSangue" [value]="gs.value" [label]="gs.label"></option>
					</select>
				</div>

				<div *ngIf="env.contexto === 'wagyu'" class="col-md-2">
					<label for="CodigoBovino">Brinco</label>
					<input [disabled]="readOnly" aria-readonly="true" type="text" class="form-control form-control-sm ng-untouched ng-pristine ng-valid" name="CodigoBovino" [(ngModel)]="bovino.Brinco" autocomplete="off">
				</div>
			</div>

			<div class="row mt-2">
				<div *ngIf="bovino.Raca !== 'Jersey'" class="col-md-2">
					<label for="AnimaldeCentralBovino">Animal de Central</label>
					<select [disabled]="readOnly" aria-disabled="true" class="form-control form-control-sm ng-pristine ng-valid ng-touched" [(ngModel)]="bovino.AnimalDeCentral">
						<option value="1" label="Sim"></option>
						<option value="0" label="Não"></option>
					</select>
				</div>

				<div class="col-md-2">
					<label for="NacionalizadoBovino">Nacionalizado</label>
					<select [disabled]="readOnly" aria-disabled="true" class="form-control form-control-sm ng-pristine ng-valid ng-touched" [(ngModel)]="bovino.Nacionalizado">
						<option value="1" label="Sim"></option>
						<option value="0" label="Não"></option>
					</select>
				</div>
				
				<div *ngIf="bovino.Nacionalizado == 'Não' && env.contexto === 'jersey'" class="col-md-4">
					<label for="OrigemBovino">Origem</label>
					<select [disabled]="readOnly" aria-disabled="true" class="form-control form-control-sm ng-pristine ng-valid ng-touched" [(ngModel)]="bovino.Origem">
						<option *ngFor="let orig of origem" [value]="orig.value" [label]="orig.label"></option>
					</select>	
				</div>

				<div *ngIf="bovino.Nacionalizado == 'Sim'" class="col-md-3">
					<label for="CodigoCentral">Código Central</label>
					<input [disabled]="readOnly" aria-readonly="true" type="text" class="form-control form-control-sm ng-untouched ng-pristine ng-valid" name="CodigoCentral" [(ngModel)]="bovino.CodigoCentral" autocomplete="off">
				</div>
				
				<div class="col-md-2">
					<label for="SituacaoBovino">Situação</label>
					<select [disabled]="readOnly" aria-disabled="true" class="form-control form-control-sm ng-pristine ng-valid ng-touched" [(ngModel)]="bovino.Situacao">
						<option value="1" label="Ativo"></option>
        		<option value="2" label="Inativo"></option>
					</select>
				</div>

				<div *ngIf="env.contexto === 'wagyu'" class="col-md-2">
					<label for="CodigoBovino">Peso ao Nascer</label>
					<input [disabled]="readOnly" aria-readonly="true" type="text" class="form-control form-control-sm ng-untouched ng-pristine ng-valid" name="PesoAoNascer" [(ngModel)]="bovino.PesoAoNascer" autocomplete="off">
				</div>

				<div *ngIf="env.contexto === 'wagyu'" class="col-lg-4">
					<label for="OrigemBovino">Local de Nascimento</label>
					<select [disabled]="true" class="form-control form-control-sm" name="LocalNascimento" [(ngModel)]="bovino.PropriedadeNascimento.Id">
						<option [value]="bovino.PropriedadeNascimento.Id" [label]="bovino.PropriedadeNascimento.Nome"></option>
					</select>
				</div>
			</div>
			
			<div class="row mt-2">
				<div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
					<app-consultar-bovino [bovino]="bovino.Pai"></app-consultar-bovino>
				</div>
		
				<div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
					<app-consultar-bovino [bovino]="bovino.Mae"></app-consultar-bovino>
				</div>
			</div>

			<div class="row">
				<div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
					<app-consultar-criador [criador]="bovino.Criador" (criadorSelecionado)="criadorSelecionado($event)"></app-consultar-criador>
				</div>
		
				<div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
					<label>Afixo</label>
					<input 
						disabled 
						type="text" 
						name="CriadorAfixo" 
						[ngModel]="bovino.Criador.afixo"
						class="form-control form-control-sm" 
					>
				</div>
			</div>

			<div class="row mt-2">
				<div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
					<app-consultar-criador [criador]="bovino.Proprietario"></app-consultar-criador>
				</div>
		
				<div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
					<label>Afixo Proprietário</label>
					<input 
						disabled 
						type="text" 
						name="proprietarioAfixo" 
						[ngModel]="bovino.Proprietario.afixo"
						class="form-control form-control-sm" 
					>
				</div>
			</div>
		</div>
	</div>

	<div class="row justify-content-end" id="botao-container">
    <div *ngIf="!readOnly" class="col-lg-3 col-md-10 col-sm-10">
      <button type="button" class="btn btn-sm btn-block btn-primary" (click)="botaoSalvar()">Salvar</button>
    </div>
		<div *ngIf="mostrarBotaoNovo" class="col-lg-3 col-md-10 col-sm-10">
			<button type="button" class="btn btn-sm btn-block btn-primary" (click)="botaoCadastrarNovo()">Novo</button>
		</div>
  </div>

</div>