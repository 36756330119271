import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PrincipalService {

  constructor(private http: HttpClient) { }

  async consultarPainelGerencial() {

    const result = await this.http.post<any>(`${environment.api}/home/painel_gerencial`, {
      Contexto: (environment as any).contexto
    }).toPromise();

    return result;
  }
}
