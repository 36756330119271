import { environment } from "src/environments/environment";
import { IdentificacaoCriadorModel } from "./IdentificacaoCriadorModel";
import { FazendaModel } from "./FazendaModel";
import { UsuarioModel } from "./UsuarioModel";
import { Frigorifico, Socio, Suspenso, TipoServicoInspecaoFrigorifico } from "../shared/enums/Enums";

export class ValidaCriadorModel {
  cpf_cnpj: string;
  tipoPessoa: number;
  vinculacao: string;
  grupo: string = "GR_SRG_ASSOCIADO";

  constructor() {
    this.cpf_cnpj = '';
    this.tipoPessoa = 1;
    this.vinculacao = (environment as any).contexto === 'wagyu' ? 'PJ_ACGWB' : '';
  }
}

export class CriadorModel extends UsuarioModel {
  id: string = "-1";
  socio: Socio = Socio.Sim;
  afixo: string = "";
  idAfixo: string = "-1";
  suspenso: Suspenso = Suspenso.Nao;
  racas: string[] = [];
  dataCadastro: string = "";
  posicaoAfixo: number = 2;
  afixos: IdentificacaoCriadorModel[] = [];
  readonly: boolean = false;
  frigorifico: Frigorifico = Frigorifico.Nao;
  tipoServicoInspecao = TipoServicoInspecaoFrigorifico.None;
  numeroServicoInspecao: string = "";
  exibirAfixo: boolean = false;
  inscricaoEstadual: string = "";
  tituloConsulta: string = "Criador";
  consultaEmTodasFiliadas: boolean = true;
  fazendas: FazendaModel[] = [new FazendaModel()];
  emailSecundario: string = '';
  observacao: string = '';
  associacaoEntidadeId: string = '';

  constructor() {
    super();
  }
}