import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/shared/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-controle-leiteiro-bovino',
  templateUrl: './controle-leiteiro-bovino.component.html',
  styleUrls: ['./controle-leiteiro-bovino.component.scss']
})
export class ControleLeiteiroBovinoComponent implements OnInit {

  @Input() bovinoId: string | null = null;

  page = 1;
  count = 0;
  totais: any;
  controleLeiteiro: any[] = [];
  pageSize = environment.pageSize;

  constructor(
    private router: Router,
    private api: ApiService,
  ) { }

  ngOnInit(): void {
    this.consultar();
  }

  consultar(): void {

    if (!this.bovinoId) {
      this.router.navigate(['plantel_consultar']);
      return;
    }

    this.api.consultarControleLeiteiroBovino(this.bovinoId, this.page, this.pageSize).then((result: any) => {
      if (result) {
        if (result.success) {
          this.totais = result.Totais;
          this.count = result.TotalItens;
          this.controleLeiteiro = result.Itens;
        } 
      }
    });

  }

  handlePageChange(event: number): void {
    this.page = event;
    this.consultar();
  }

}
