<div class="row">
    <div class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
      <label for="formularioProtocolo">Nº Formulário / Protocolo</label>
      <input type="text" class="form-control form-control-sm" name="formularioProtocolo" [(ngModel)]="formConsulta.formularioProtocolo">
    </div>
    
    <div class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
      <label for="formularioProtocolo">Situação</label>
      <select class="form-control form-control-sm" name="situacao" [(ngModel)]="formConsulta.situacao">
        <option *ngFor="let situacaoEvento of situacoesEvento" [value]="situacaoEvento.value" [label]="situacaoEvento.label"></option>
      </select>
    </div>
  
    <div class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
      <label>De</label>
      <div class="input-group input-group-sm">
        <input class="form-control" [matDatepicker]="eventoDe" name="eventoDe" [(ngModel)]="formConsulta.eventoDe"> 
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" type="button" (click)="eventoDe.open()"> <i class="bi bi-calendar3"></i> </button>
        </div>
      </div>
      <mat-datepicker [startAt]="formConsulta.eventoDe" #eventoDe></mat-datepicker>
    </div>
  
    <div class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
      <label>Até</label>
      <div class="input-group input-group-sm">
        <input class="form-control" [matDatepicker]="eventoAte" name="eventoAte" [(ngModel)]="formConsulta.eventoAte"> 
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" type="button" (click)="eventoAte.open()"> <i class="bi bi-calendar3"></i> </button>
        </div>
      </div>
      <mat-datepicker [startAt]="formConsulta.eventoAte" #eventoAte></mat-datepicker>
    </div>
  
    <div class="col-lg-6 col-md-12 col-sm-12">
      <app-consultar-criador [criador]="formConsulta.criador" (criadorSelecionado)="criadorSelecionado($event)"></app-consultar-criador>
    </div>
  
    <div class="col-lg-6 col-md-12 col-sm-12">
      <app-consultar-bovino [bovino]="formConsulta.bovino" ></app-consultar-bovino>
    </div>
  
  </div>