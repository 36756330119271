<div class="card" style="margin-top: 1em;">
  <div class="card-body">
    <div class="row">
      <div class="col">
        <app-consultar-criador 
        [criador]="formConsulta.proprietario"
        (criadorSelecionado)="criadorSelecionado($event)" 
        ></app-consultar-criador>
      </div>
      <div class="col">
        <app-consultar-bovino 
        [bovino]="formConsulta.bovino"
        ></app-consultar-bovino>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
        <label for="formularioProtocolo">Certificado</label>
        <select class="form-control form-control-sm" name="certificado" [(ngModel)]="formConsulta.certificado" (change)="consultar()">
          <option *ngFor="let certificado of listaCertificado" [value]="certificado.value" [label]="certificado.label">
          </option>
        </select>
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-lg-2 col-md-4 col-sm-6 ">
        <button type="button" class="btn btn-sm btn-block btn-primary" (click)="botaoConsultar()">Buscar</button>
      </div>
    </div>

    <div class="row" style="margin-top: 15px;">
      <div class="col-lg-12 table-responsive">
        <table class="table table-hover table-header-custom">
          <thead>
            <tr>
              <th width="5%"></th>
              <th>Emissão</th>
              <th>Código</th>
              <th>Nome</th>
              <th>Resp. Emissão</th>
              <th></th>
            </tr>
          </thead>
          <tbody *ngIf="count > 0">
            <tr
              *ngFor="let certificado of certificados | paginate : {itemsPerPage: pageSize, currentPage: page, totalItems: count};">
              <td class="text-center">
                <input type="checkbox" class="flat" *ngIf="certificado.Assinado == 2"
                  [(ngModel)]="certificado.Selecionado" (change)="handleSelecionadoChange(certificado)">
              </td>
              <td>{{ certificado.DescDataEmissao  }}</td>
              <td>{{ certificado.OrelhaDireita }}</td>
              <td>{{ certificado.NomeBovino }}</td>
              <td>{{ certificado.NomeRespEmissao }}</td>
              <td>
                <a *ngIf="certificado.Assinado == 2" type="button" matTooltip="Visualizar o certificado" (click)="visualizarCertificado(certificado.Id, certificado.TipoCertificado)">
                  <i class="fas fa-file-alt"></i>
                </a>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="count === 0">
            <tr class="text-center">
              <td colspan="5">
                <strong>Sem certificados para assinar no momento.</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div *ngIf="count > 0" class="col-md-12 text-center">
        <pagination-controls class="srg-paginacao" previousLabel="Anterior" nextLabel="Próximo" [responsive]="true"
          (pageChange)="handlePageChange($event)" [autoHide]="true">
        </pagination-controls>
      </div>

    </div>
      
    <div *ngIf="count > 0" class="row justify-content-end" style="margin-top: 25px;">
      <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12">
        <button type="button" class="btn btn-sm btn-block btn-primary" (click)="botaoAssinar()" >Assinar certificados selecionados</button>
      </div>
    </div>

  </div>
</div>