import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import Swal from 'sweetalert2';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ApiService } from '../api.service';
import { TecnicoModel } from 'src/app/models/TecnicoModel';
  
@Component({
  selector: 'app-consultar-tecnico-modal',
  templateUrl: './consultar-tecnico-modal.component.html',
  styleUrls: ['./consultar-tecnico-modal.component.scss']
})
export class ConsultarTecnicoModalComponent implements OnInit {
  
  tecnicos: any[] = [];
  formConsulta: TecnicoModel = new TecnicoModel();
  @Output() tecnicoSelecionado = new EventEmitter<any>();
  
  constructor(
    private api: ApiService,
    public dialogRef: MatDialogRef<ConsultarTecnicoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  
  ngOnInit(): void {}
  
  botaoBuscar(){
    this.api.consultarTecnicos(this.formConsulta).then((result: any) => {
      if (result) { 
        if(result.success){
          this.tecnicos = result.Itens;
        } else {
          this.tecnicos = [];
          Swal.fire(result.message, '', 'error');
        }
      }
    });
  }
  
  selecionarTecnico(tecnico: any) {
    this.tecnicoSelecionado.emit(tecnico);
    this.dialogRef.close();
  }
  
  fechar() {
    this.dialogRef.close();
  }
  
}
  