<div [ngClass]="{'card card-body': env.contexto === 'wagyu'}" style="margin-bottom: 20px;">
  
  <div class="titulo-card" *ngIf="env.contexto === 'wagyu'">
    <h4>Fazenda {{ index_titulo + 1 }} {{ fazenda.Nome? "- " + fazenda.Nome : "" }}</h4>
    <a *ngIf="fazenda.Id === '-1' && !readOnly && length > 1" type="button" matTooltip="Remover fazenda">
      <i class="fas fa-minus" (click)="removerFazenda(index_titulo)"></i>
    </a>
  </div>

  <div class="row">
    <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <label>*Nome</label>
      <input [disabled]="readOnly" name="nome" type="text" class="form-control form-control-sm" [(ngModel)]="fazenda.Nome">
    </div>
  </div>
  
  <app-endereco-manter [endereco]="fazenda.Endereco" [readOnly]="readOnly"></app-endereco-manter>
  <div class="row">
    <div class="col-lg-3">
      <button class="btn btn-primary btn-sm" type="button" matTooltip="Visualizar Questionário" (click)="showQuestionario()">
        <i class="fa fa-eye"></i>&nbsp;Visualizar Questionário de Manejo
      </button>
    </div>
  </div>
  
</div>