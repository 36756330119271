import { state } from '@angular/animations';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { stat } from 'fs';
import { FiltroConsultaCertificadosModel } from 'src/app/models/FiltroConsultaCertificadosModel';
import { LocalStorageService } from 'src/app/shared/local-storage.service';
import { QueryStateService } from 'src/app/shared/query-state.service';
import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-filtro-consulta-certificacao',
  templateUrl: './filtro-consulta-certificacao.component.html',
  styleUrls: ['./filtro-consulta-certificacao.component.scss']
})

export class FiltroConsultaCertificacaoComponent implements OnInit {

  @Input() formConsulta: FiltroConsultaCertificadosModel = new FiltroConsultaCertificadosModel();
  @Output() triggerConsulta: EventEmitter<void> = new EventEmitter<void>();

  listaAssinado: { value: string, label: string }[] = [];
  listaCertificado: { value: number, label: string }[] = [];

  constructor(
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private queryState: QueryStateService
  ) { }

  ngOnInit(): void {
    this.preencherAssinado();
    this.preencherCertificados();
    this.formConsulta.proprietario.tituloConsulta = "Proprietário";
    this.formConsulta.certificado = 1;
    //o history tava sobrepondo o padrão da model, então fiz essa verificação pra puxar só quando ele existe
    if (history.state.Certificado ||
      history.state.Assinado ||
      history.state.EmissaoAte ||
      history.state.emissaoDe) {
      this.recuperarFormulario();
    }

  }

  preencherAssinado() {
    this.listaAssinado = [];
    this.listaAssinado.push({ value: "-1", label: "Todos" });
    this.listaAssinado.push({ value: "1", label: "Sim" });
    this.listaAssinado.push({ value: "2", label: "Não" });
  }

  preencherCertificados() {
    this.listaCertificado = [];
    this.listaCertificado.push({ value: 1, label: "Definitivo" });

    switch ((environment as any).contexto) {
      case 'jersey':
        this.listaCertificado.push({ value: 2, label: "Desempenho" }); break;
      case 'wagyu':
        this.listaCertificado.push({ value: 4, label: "Abate" }); break;
    }
    this.listaCertificado.push({ value: 5, label: "Nascimento" });
    this.formConsulta.certificado = 1;
  }

  private recuperarFormulario() {
    if (this.localStorage.get("rotaAnterior") == this.route.snapshot.url[0].path) {
      // Recupera o state passado pela função de consultas do painel gerencial através da rota
      let state = history.state;

      // Recupera o state da última busca do localStorage
      const queryState = this.localStorage.get("formConsultaCertificado");

      // Caso não haja state do form salvo, inicializa vazio
      if (!state && !queryState) return;

      // Caso não haja state passado pelo painel gerencial, mas exista um form salvo para essa consulta no storage
      Object.assign(state, queryState);

      this.formConsulta.assinado = state.Assinado;
      this.formConsulta.certificado = state.Certificado;
      this.formConsulta.emissaoAte = state.EmissaoAte;
      this.formConsulta.emissaoDe = state.emissaoDe;
      //this.formConsulta.proprietario.codigo = state.Proprietario.Codigo;
      this.formConsulta.proprietario.nome = state.Proprietario.Nome;

      this.triggerConsulta.emit();

    } else {
      this.localStorage.remove("formConsultaCertificado");
      this.localStorage.remove("page");
    }
  }

  criadorSelecionado(idCriador: string) {
    this.formConsulta.bovino.IdProprietarioConsulta = idCriador;
  }
}
