<div class="card">
  <div class="card-body">

    <div class="row">
      <div class="form-group col-lg-9 col-md-9 col-sm-9 col-xs-12">
        <app-consultar-inspetor [inspetor]="formularioConsulta.inspetor" tituloConsulta="Inspetor(a)"></app-consultar-inspetor>
      </div>
      
      <div class="form-group col-lg-3 col-md-3 col-sm-3 col-xs-12">
        <label>Tipo</label>
        <select class="form-control form-control-sm" name="tipos" [(ngModel)]="formularioConsulta.tipo">
          <option *ngFor="let tipo of tiposRegistros" [value]="tipo.value" [label]="tipo.label"></option>
        </select>
      </div>

      <div *ngIf="env.contexto === 'jersey'" class="form-group col-lg-6 col-md-12 col-sm-12 col-xs-12">
        <app-selecionar-filiadas 
          [readonly]="false" 
          [filiadaId]="formularioConsulta.filiadaId"
          defaultFirstSelectedLabel="Todas" 
          (filiadaSelecionada)="filiadaSelecionada($event)"
        >
        </app-selecionar-filiadas>
      </div>
    </div>

    <div class="row justify-content-end">
      <div class="col-lg-3 col-md-4 col-sm-6">
        <button type="button" class="btn btn-sm btn-block btn-primary" (click)="botaoConsultar()">Buscar</button>
      </div>
    </div>

    <mat-divider style="margin-top: 25px;"></mat-divider>
    <label class="label-totais">
      {{ bovinoService.getLabelTipoRegistroReservado(TipoRegistro.POFemea) }}: {{ totais.CountPOFemeas }} 
    </label>
    <label class="label-totais">
      {{ bovinoService.getLabelTipoRegistroReservado(TipoRegistro.POMacho) }}: {{ totais.CountPOMacho }}
    </label>
    <label class="label-totais">
      {{ bovinoService.getLabelTipoRegistroReservado(TipoRegistro.PONacionalizado) }}: {{ totais.CountPONacionalizado }}
    </label>
    <label class="label-totais">
      {{ bovinoService.getLabelTipoRegistroReservado(TipoRegistro.PC) }}: {{ totais.CountPC }}
    </label>
    <label class="label-totais">
      {{ bovinoService.getLabelTipoRegistroReservado(TipoRegistro.PA) }}: {{ totais.CountPA }}
    </label>
    <label class="label-totais">
      {{ bovinoService.getLabelTipoRegistroReservado(TipoRegistro.CCG) }}: {{ totais.CountCCG }}
    </label>
    <label class="label-totais">
      {{ bovinoService.getLabelTipoRegistroReservado(TipoRegistro.CCG2) }}: {{ totais.CountCCG2 }}
    </label>
    <mat-divider></mat-divider>

    <div class="row" style="margin-top: 25px;">
      <div class="col-lg-12 table-responsive">
        <table class="table table-hover table-header-custom">
          <thead>
            <tr>
              <th class="text-center">Intervalo</th>
              <th class="text-center">Quantidade</th>
              <th class="text-center">Tipo Grau Sangue</th>
              <th>Gerado Em</th>
              <th>Responsável</th>
              <th>Responsável Para</th>
            </tr>
          </thead>
          <tbody *ngIf="registros.length > 0">
            <tr *ngFor="let registro of registros">
              <td class="text-center"> {{ registro.Intervalo }} </td>
              <td class="text-center"> {{ registro.Quantidade }} </td>
              <td class="text-center"> {{ bovinoService.getLabelTipoRegistroReservado(registro.Tipo) }} </td>
              <td> {{ registro.GeradoEm }} </td>
              <td> {{ registro.Responsavel }} </td>
              <td> {{ registro.ReservadoPara }} </td>
            </tr>
          </tbody>
          <tbody *ngIf="registros.length === 0">
            <tr class="text-center">
              <td colspan="6">
                <strong>Sem itens para exibição.</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
      
  </div>
</div>