export const environment = {
  contexto: 'wagyu',
  production: false,
  versao: '1.36.218',
  backgroundImage: 'assets/wagyu/login.jpg',
  logoImage: 'assets/wagyu/logo.png',
  //api: 'http://fazendasc.cloudapp.net/SrgWagyuApi',
  api: 'https://fsc.homolog.rerum.dev.br/SrgWagyuApi',
  client_id: 'e84a2d13704647d18277966ec839d39e:CgP7NyLXtaGmyOgjj3sUMwmAlrSKqa5JyZ4P1OlfQeM',
  pageSize: 10,
  racas: [
    { label: 'Aberdeen Angus', value: '28dbbe30-2abe-4884-a20a-2f2d09315ad0', controle: [''] },
    { label: "Blonde d'Aquitaine", value: '408e0c20-56e7-4034-bed3-6aa9a6701c2a', controle: ['manter_bovino'] },
    { label: 'Bonsmara', value: '2dae1596-a4e5-4cc5-891e-5066eb863dfb', controle: ['manter_bovino'] },
    { label: 'Braford', value: '190c945d-c8d5-4593-b086-dd1685555c7f', controle: ['manter_bovino'] },
    { label: 'Brahman', value: 'fc67141c-03cf-4efe-a18d-711d4a1b206f', controle: ['manter_bovino'] },
    { label: 'Brangus', value: '23be3ee4-766d-423c-9b00-37aad5ec2304', controle: ['manter_bovino'] },
    { label: 'Caracu', value: '3ff7b421-365d-4b06-bba3-a74187f2e386', controle: ['manter_bovino'] },
    { label: 'Canchin', value: 'a9294370ea7f431ba9d890c7f35e4b7a', controle: ['manter_bovino'] },
    { label: 'Charoles', value: '5d74ca52-76fe-4b26-82c9-eb0b2ecf31ec', controle: ['manter_bovino'] },
    { label: 'Chianina', value: '34275e28-5829-43c4-9e5e-fd2f2f12bd4f', controle: ['manter_bovino'] },
    { label: 'Desconhecida', value: '02551ee85dd94141b544a9a1e25a0969', controle: [''] },
    { label: 'Devon', value: '749b13baf3234f989d14ca7404f8ddc4', controle: ['manter_bovino'] },
    { label: 'Galloway', value: '54f32d79-3ad2-4151-a276-cf2bdc3c1181', controle: ['manter_bovino'] },
    { label: 'GIR', value: 'd3bfc734-4c72-4d6b-a96f-ae122ea36c35', controle: ['manter_bovino'] },
    { label: 'Guzerá', value: '450e488a-bc3b-461c-bd5d-02f38a29cc05', controle: ['manter_bovino'] },
    { label: 'Hereford', value: '81356bb3-8d5f-4f94-a70d-3fd1cc1261bf', controle: ['manter_bovino'] },
    { label: 'Holandês', value: '05551ee85dd94141b544a9a1e25a0969', controle: ['manter_bovino'] },
    { label: 'Indubrasil', value: 'A425C581-56BB-4A00-972C-FFF8DE2444A9', controle: ['manter_bovino'] },
    { label: 'Jersey', value: 'c0e18330b24945c0bf1c6840bbb9ba5c', controle: ['manter_bovino'] },
    { label: 'Limousin', value: 'e976cace-ae9a-44eb-b55e-3c0d6bf77dfd', controle: ['manter_bovino'] },
    { label: 'Marchigiana', value: 'e7e48f692f1143bc83c6ff2e934e6bcf', controle: ['manter_bovino'] },
    { label: 'Montana', value: '75dbe22e-3ed5-44a3-9af4-aa093516604f', controle: [''] },
    { label: 'Montbéliard', value: 'c919bc2b-e7e8-4dd6-99e9-cb9f70d30376', controle: ['manter_bovino'] },
    { label: 'Nelore', value: '72D3D2B8-F31B-4277-874A-F1E2510DA782', controle: ['manter_bovino'] },
    { label: 'Normando', value: 'd65f02af-5fe4-4f0c-a041-aa7a8e9cb6ba', controle: ['manter_bovino'] },
    { label: 'Pardo Suiço', value: '14551ee85dd94141b544a9a1e25a0969', controle: ['manter_bovino'] },
    { label: 'Red Angus', value: 'dcc06e72-f7a5-4735-995a-a8e27b0fdf15', controle: [''] },
    { label: 'Red Poll', value: '17551ee85dd94141b544a9a1e25a0969', controle: ['manter_bovino'] },
    { label: 'Rubia Gallega', value: '93bb8b65-dc0e-4f45-be3e-2304f1ecfe59', controle: ['manter_bovino'] },
    { label: 'Santa Gertrudis', value: '1007444c-c5ca-42c6-8ff2-eee534d20a5d', controle: ['manter_bovino'] },
    { label: 'Senepol', value: '00592ec9-71ce-4d35-82b8-6ba42383ea9e', controle: ['manter_bovino'] },
    { label: 'Shorthorn', value: 'a2bfb474-d252-4dfb-9fa1-6a23f0bd0509', controle: ['manter_bovino'] },
    { label: 'Simental', value: '19551ee85dd94141b544a9a1e25a0969', controle: [''] },
    { label: 'Sindi', value: '20551ee85dd94141b544a9a1e25a0969', controle: ['manter_bovino'] },
    { label: 'Tabapuã', value: 'B2DA9495-BD90-4AAC-90F4-EBA959659EF3', controle: ['manter_bovino'] },
    { label: 'Ultra Black', value: '664b444a-42f1-42c9-85f0-de7e60ef3e35', controle: ['manter_bovino'] },
    {
      label: 'Wagyu Akaushi',
      value: 'WAGYU_AKAUSHI',
      controle: ['manter_bovino'],
    },
    {
      label: 'Wagyu Kuroge',
      value: 'WAGYU_KUROGE',
      controle: ['manter_criador', 'consulta_plantel', 'evento_nascimento', 'manter_bovino'],
    },
  ],
  LABORATORIOS: [
    { name: 'ALLELE BIOTECNOLOGIA LTDA ME (São Paulo/SP)', id: 'e48517a5-6e02-4d3f-bcd2-0b987483cac7' },
    {
      name: 'FUNDAÇÃO DE ESTUDO E PESQUISA EM MEDICINA VETERINÁRIA E ZOOTECNIA (UFMG Belo Horizonte/MG)',
      id: 'd42cf8ce-fe74-457a-9cca-cd0090e29f7e',
    },
    { name: 'GENEAL DIAGNÓSTICOS LTDA (Uberaba/MG)', id: '9486274d-e744-4bc0-8874-1e678b1caffb' },
    {
      name: 'GENE GENEALÓGICA CENTRAL DE GENOTIPAGEM DE ANIMAIS (Belo Horizonte/MG)',
      id: 'c735ab54-29a8-45d7-821d-40e33a27ff66',
    },
    { name: 'LABORATÓRIO RAÇA LTDA ME (Goiânia/GO)', id: 'eb5e7f1a-e631-42ef-b4bc-4b826749a6a5' },
    {
      name: 'LINHAGEN PRODUTOS E SERVIÇOS EM BIOTECNOLOGIA LTDA (Belo Horizonte/MG)',
      id: '2757cf99-a257-4618-b7a4-037ea8ffe846',
    },
    { name: 'LINKGEN BIOTECNOLOGIA LTDA ME (São Paulo/SP)', id: '33e36904-1699-4aa8-a3c6-49be93a5236f' },
    { name: 'LOCI BIOTECNOLOGIA LTDA ME (Lagoa Santa/MG)', id: '16240ee0-8669-49d0-8f23-f20f8cf10905' },
    { name: 'VRGEN LABORATÓRIO DE DNA LTDA (Araçatuba/SP)', id: 'ac65b724-5a93-4abf-9dfe-7c73102acd52' },
  ],
  raca: 'kuroge',
};
