import { environment } from "src/environments/environment";
import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/account/shared/account.service';
import { ValidaCpfUsuario } from 'src/app/models/UsuarioModel';
import { ApiService } from 'src/app/shared/api.service';
import { IJsonResultModel, IValidaUsuario } from 'src/app/shared/interfaces/Interfaces';
import Swal from 'sweetalert2';
import { Commons } from "src/app/shared/commons";

@Component({
  selector: 'app-usuarios-manter-valida-cpf',
  templateUrl: './usuarios-manter-valida-cpf.component.html',
  styleUrls: ['./usuarios-manter-valida-cpf.component.scss']
})
export class UsuariosManterValidaCpfComponent implements OnInit {

  valido: boolean;
  filiadaReadOnly: boolean = true;
  usuarioExistente: boolean = false;
  usuario: IJsonResultModel<IValidaUsuario> = {} as IJsonResultModel<IValidaUsuario>;
  formValidaCpf: ValidaCpfUsuario = new ValidaCpfUsuario();
  gruposUsuario: {value: string, label: string}[] = [];

  contexto: string = 'jersey';

  constructor(
    private api: ApiService,
    private commons: Commons,
    private accountService: AccountService
  ) { 
    this.valido = false;
    this.contexto = (environment as any).contexto;
  }

  ngOnInit(): void {
    this.preencherGruposUsuario();
    this.filiadaReadOnly = !this.accountService.isSuperAdmin; 
    if ((environment as any).contexto == 'wagyu'){
      this.formValidaCpf.vinculacao = 'PJ_ACGWB'
    }
  }

  preencherGruposUsuario() {
    const isJersey = (environment as any).contexto === 'jersey';

    if (this.accountService.isAdmin || this.accountService.isSuperintendente) {
      this.gruposUsuario = [];
      this.gruposUsuario.push({value: "-1", label: "Selecione..."});
      this.gruposUsuario.push({value: "GR_SRG_INSPETOR", label: `SRG Inspetor ${isJersey? '': ' de Registro'}`});
      this.gruposUsuario.push({value: "GR_SRG_JERSEY_ADMIN", label: "SRG Administrador"});
      this.gruposUsuario.push({value: "GR_SRG_SUPERINTENDENTE", label: "SRG Superintendente"});
      this.gruposUsuario.push({value: "GR_SRG_VETERINARIO", label: "SRG Veterinário"});
      this.gruposUsuario.push({value: "GR_SRG_TECNICO", label: `SRG Técnico ${isJersey? '': ' de Abate'}`});
      this.gruposUsuario.push({value: "GR_SRG_PROCURADOR", label: "SRG Procurador"});
    } else if (this.accountService.isCriador) {
      this.gruposUsuario.push({value: "-1", label: "Selecione..."});
      this.gruposUsuario.push({value: "GR_SRG_PROCURADOR", label: "SRG Procurador"});
    }
  }

  filiadaSelecionada(idFiliada: string) {
    this.formValidaCpf.vinculacao = idFiliada;
  }

  botaoValidar(): void {
    this.api.validarCpfUsuario(this.formValidaCpf).then(async (result: IJsonResultModel<IValidaUsuario>) => {
      if (result) {
        if (result.Data?.success) {
          this.usuario = result;
          this.usuarioExistente = result.Data.UsuarioMesmaAssociacao && result.Data.PessoaCadastrada;
          this.valido = this.usuarioExistente 
            ? await this.commons.modalConfirmacao({
                title: 'CPF já cadastrado nessa Associação com outro perfil. Deseja cadastrar um novo usuário nesta Associação para esta pessoa?'
              })
            : true;
        } else {
          Swal.fire(result.Data?.message, '', 'error');
        }
      }
    });
  }

}
