<div class="row">
    
    <div class="form-group col-lg-4 col-md-6 col-sm-6 col-xs-12">
        <label for="formularioProtocolo">Situação</label>
        <select class="form-control form-control-sm" name="UFs" [(ngModel)]="formConsulta.situacao">
          <option *ngFor="let situacao of situacoes" [value]="situacao.value" [label]="situacao.label"></option>
        </select>
    </div>

    <div class="form-group col-lg-4 col-md-6 col-sm-6 col-xs-12">
        <label>{{ env.contexto === 'wagyu'? 'Controle de Carcaça' : 'Controle leiteiro'}} De:</label>
        <div class="input-group input-group-sm">
          <input class="form-control" name="controle_de" [matDatepicker]="controleLeiteiroDe" [(ngModel)]="formConsulta.controleLeiteiroDe"> 
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button" (click)="controleLeiteiroDe.open()"> <i class="bi bi-calendar3"></i> </button>
          </div>
        </div>
        <mat-datepicker [startAt]="formConsulta.controleLeiteiroDe" #controleLeiteiroDe></mat-datepicker>
    </div>

    <div class="form-group col-lg-4 col-md-6 col-sm-6 col-xs-12">
        <label>{{ env.contexto === 'wagyu'? 'Controle de Carcaça' : 'Controle leiteiro'}} Até:</label>
        <div class="input-group input-group-sm">
          <input class="form-control" name="controle_ate" [matDatepicker]="controleLeiteiroAte" [(ngModel)]="formConsulta.controleLeiteiroAte"> 
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button" (click)="controleLeiteiroAte.open()"> <i class="bi bi-calendar3"></i> </button>
          </div>
        </div>
        <mat-datepicker [startAt]="formConsulta.controleLeiteiroAte" #controleLeiteiroAte></mat-datepicker>
    </div>
      
</div>