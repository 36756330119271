export class EnderecoModel {
  uf: number;
  cep: string;
  numero: string;
  bairro: string;
  cidade: string;
  logradouro: string;
  complemento?: string;

  constructor() {
    this.uf = 0;
    this.cep = '';
    this.numero = '';
    this.cidade = '';
    this.bairro = '';
    this.logradouro = '';
    this.complemento = '';
  }
}
