import { AnimalCadastrado, Sexo, TipoBuscaBovino, TipoTransferencia } from "../shared/enums/Enums";
import { BovinoModel } from "./BovinoModel";
import { OcorrenciaEventoEmbriaoModel } from "./OcorrenciaEventoEmbriaoModel";
import { OcorrenciaEventoModel } from "./OcorrenciaEventoModel";
import { OcorrenciaEventoTeFivModel } from "./OcorrenciaEventoTeFivModel";
import { VeterinarioModel } from "./VeterinarioModel";

export class OcorrenciaEventoInovulacaoModel extends OcorrenciaEventoModel {
  Doadora: BovinoModel;
  Reprodutor: BovinoModel;
  ReceptoraCadastrada: AnimalCadastrado;
  Receptora: BovinoModel;
  TipoTransferencia: TipoTransferencia;
  DataTransferencia: string;
  QuantidadeTransferidos: number;
  Nacionalizado: boolean;
  DoadoraFiv: string;
  NomeReceptoraNaoCadastrada: string;
  CodigoReceptoraNaoCadastrada: string;
  CodigoNomeReceptoraNaoCadastrada: string;

  OcorrenciaEventoFiv: OcorrenciaEventoTeFivModel;
  OcorrenciaNacionalizacaoEmbriao: OcorrenciaEventoEmbriaoModel;

  Veterinario: VeterinarioModel;

  isReadOnly: boolean;

  constructor() {
    super();

    this.Doadora = new BovinoModel();
    this.Reprodutor = new BovinoModel();
    this.Receptora = new BovinoModel();
    this.Veterinario = new VeterinarioModel();
    this.ReceptoraCadastrada = AnimalCadastrado.Sim;
    this.TipoTransferencia = TipoTransferencia.COLETA_COM_IMPLANTE_TOTAL;
    this.DataTransferencia = "";
    this.QuantidadeTransferidos = 0;
    this.NomeReceptoraNaoCadastrada = "";
    this.CodigoNomeReceptoraNaoCadastrada = "";
    this.CodigoReceptoraNaoCadastrada = "";
    this.Nacionalizado = false;
    this.DoadoraFiv = "";
    this.OcorrenciaEventoFiv = new OcorrenciaEventoTeFivModel();
    this.OcorrenciaNacionalizacaoEmbriao = new OcorrenciaEventoEmbriaoModel();

    this.Doadora.TituloConsulta = "Doadora";
    this.Doadora.TipoBusca = TipoBuscaBovino.Matriz;
    this.Doadora.SexoBovino = Sexo.Fêmea;
    this.Doadora.ConsideraInativos = false;
    this.Doadora.ConsultaAnimalTodosCriadores = true;

    this.Reprodutor.TituloConsulta = "Reprodutor";
    this.Reprodutor.TipoBusca = TipoBuscaBovino.Reprodutor;
    this.Reprodutor.SexoBovino = Sexo.Macho;
    this.Reprodutor.ConsideraInativos = false;
    this.Reprodutor.ConsultaAnimalTodosCriadores = true;

    this.Receptora.TituloConsulta = "Receptora";
    this.Receptora.TipoBusca = TipoBuscaBovino.Fêmea;
    this.Receptora.SexoBovino = Sexo.Fêmea;
    this.Receptora.ConsideraInativos = false;
    this.Receptora.ConsultaAnimalTodosCriadores = true;

    this.isReadOnly = false;
  }
}