<div class="row" style="text-align: left !important;">

  <div class="form-group col-lg-6 col-md-12 col-sm-12 col-xs-12">
    <label>*Código</label>
    <input name="codigo" type="text" class="form-control form-control-sm" [(ngModel)]="bovino.Codigo">
  </div>

  <div class="form-group col-lg-6 col-md-12 col-sm-12 col-xs-12">
    <label>*Data nascimento</label>
    <div class="input-group input-group-sm">
      <input class="form-control" name="data_nascimento" [matDatepicker]="dataNascimento" [max]="maxDate" [(ngModel)]="bovino.DataNascimento"> 
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" type="button" (click)="dataNascimento.open()"> <i class="bi bi-calendar3"></i> </button>
      </div>
    </div>
    <mat-datepicker [startAt]="bovino.DataNascimento" #dataNascimento></mat-datepicker>
  </div>

  <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <label>*Nome</label>
    <input name="nome" type="text" class="form-control form-control-sm" [(ngModel)]="bovino.Nome">
  </div>

  <div class="form-group col-lg-6 col-md-12 col-sm-12 col-xs-12">
    <label>Código central</label>
    <input [disabled]="!permiteCodigoCentral" name="codigo_central" type="text" class="form-control form-control-sm" [(ngModel)]="bovino.CodigoCentral">
  </div>

  <div class="form-group col-lg-6 col-md-12 col-sm-12 col-xs-12">
    <label for="formularioProtocolo">Nacionalizado</label>
    <select class="form-control form-control-sm" name="nacionalizado" [(ngModel)]="bovino.Nacionalizado">
      <option value="0" label="Não"></option>
      <option value="1" label="Sim"></option>
    </select>
  </div>

  <div class="form-group col-lg-6 col-md-12 col-sm-12 col-xs-12">
    <label for="formularioProtocolo">*Tipo</label>
    <select class="form-control form-control-sm" name="tipo" [(ngModel)]="bovino.TipoGrauSangue" (ngModelChange)="atualizarGrauSanguePorTipo($event)">
      <option *ngFor="let tipo of tiposGrauSangue" [value]="tipo.value" [label]="tipo.label"></option>
    </select>
  </div>
  
  <div class="form-group col-lg-6 col-md-12 col-sm-12 col-xs-12">
    <label for="formularioProtocolo">*Grau sangue</label>
    <select class="form-control form-control-sm" name="grau_sangue" [(ngModel)]="bovino.GrauSangue">
      <option *ngFor="let gs of grauSangue" [value]="gs.value" [label]="gs.label"></option>
    </select>
  </div>

  <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <label>*Raça</label>
    <select
      name="raca"
      [disabled]="env.contexto === 'jersey'"
      class="form-control form-control-sm"
      [(ngModel)]="bovino.Raca"
    >
      <option *ngFor="let raca of racas" [value]="raca.value" [label]="raca.label"></option>
    </select>
  </div>

  <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <app-consultar-criador 
      [criador]="proprietario" 
      [buscarPorCodigo]="false" 
      [mostrarLabelNome]="false"
      (criadorSelecionado)="proprietarioSelecionado($event)"
    >
    </app-consultar-criador>
  </div>
  
</div>

<div class="row justify-content-end botao-container">
  <div class="col-lg-6 col-md-10 col-sm-10">
    <button type="button" class="btn btn-sm btn-block btn-primary" (click)="botaoSalvar()">Confirmar</button>
  </div>
</div>