import { AccountService } from 'src/app/account/shared/account.service';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-confirmacao-modal',
  templateUrl: './confirmacao-modal.component.html',
  styleUrls: ['./confirmacao-modal.component.scss']
})
export class ConfirmacaoModalComponent implements OnInit {

  exibirData: boolean = true;
  maxDate: Date = new Date();

  confirmacao = {
    mensagem: "",
    dataComunicacao: "",
    observacao: ""
  }

  @Output() dadosSelecionados = new EventEmitter<any>();

  constructor(
    private accountService: AccountService,
    public dialogRef: MatDialogRef<ConfirmacaoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.confirmacao.mensagem = this.data.mensagem;
    this.exibirData = !this.accountService.isCriador && !this.accountService.isProcurador;
  }

  isDataReadOnly(){
    return false;
  }

  confirmar(){
    if(!this.confirmacao.dataComunicacao && this.exibirData){
      Swal.fire('Ocorreu um erro', 'Por favor informe a data da comunicação.', 'error');
    }else{
      this.dadosSelecionados.emit(this.confirmacao);
      this.dialogRef.close();
    }
  }

  naoConfirmar(){
    this.dialogRef.close();
  }
}
