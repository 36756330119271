<div class="row" style="margin-top: 15px;">
  <div class="col-lg-12 table-responsive">
    <table class="table table-hover table-header-custom">
      <thead>
        <tr>
          <th>Código</th>
          <th>Nome</th>
          <th class="text-center">Data de Nascimento</th>
          <th class="text-center">Orelha Direita</th>
          <th class="text-center">Sexo</th>
          <th class="text-center">Tipo</th>
          <th class="text-center">Grau de Sangue</th>
          <th class="text-center">Raça</th>
          <th class="text-center">Certificado</th>
        </tr>
      </thead>
      <tbody *ngIf="crias.length > 0">
        <tr *ngFor="let cria of crias | paginate : {itemsPerPage: pageSize, currentPage: page, totalItems: count};">
          <td>{{ cria.CodigoBovino }}</td>
          <td>{{ cria.NomeBovino }}</td>
          <td class="text-center">{{ cria.DataNascimento | date: 'dd/MM/yyyy' }}</td>
          <td class="text-center">{{ cria.OrelhaDireita }}</td>
          <td class="text-center">{{ commons.getDescricaoSexo(cria.Sexo) }}</td>
          <td class="text-center">{{ cria.TipoGrauSangue }}</td>
          <td class="text-center">{{ cria.GrauSangue }}</td>
          <td class="text-center">{{ cria.Raca }}</td>
          <td class="text-center">{{ cria.Certificado }}</td>
        </tr>
      </tbody>
      <tbody *ngIf="crias.length === 0">
        <tr class="text-center">
          <td colspan="9">
            <strong>Sem itens para exibição.</strong>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="col-md-12 text-center" *ngIf="crias.length > 0">
    <pagination-controls  
      [autoHide]="true"
      [responsive]="true"
      nextLabel="Próximo" 
      class="srg-paginacao" 
      previousLabel="Anterior" 
      (pageChange)="handlePageChange($event)" 
    >
    </pagination-controls>
  </div>
  
</div>