import { AccountService } from 'src/app/account/shared/account.service';
import { Commons } from 'src/app/shared/commons';
import { ApiService } from 'src/app/shared/api.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { BaseEventos } from 'src/app/eventos/base-eventos';
import { ConstantesTipoEvento } from 'src/app/shared/constantes';
import { MatDialog } from '@angular/material/dialog';
import { EnumTipoEventoSRG, Sexo, SituacaoOcorrenciaEvento, TipoBuscaBovino, TipoTransferencia } from 'src/app/shared/enums/Enums';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { ConsultarSugestaoFivModalComponent } from 'src/app/shared/consultar-sugestao-fiv-modal/consultar-sugestao-fiv-modal.component';
import { IItenFiv } from 'src/app/shared/interfaces/Interfaces';
import { ConsultaRapidaBovinoModel } from 'src/app/models/ConsultaRapidaBovinoModel';
import { OcorrenciaEventoTeFivModel } from 'src/app/models/OcorrenciaEventoTeFivModel';
import { OcorrenciaEventoModel } from 'src/app/models/OcorrenciaEventoModel';
import { OcorrenciaEventoEmbriaoModel } from 'src/app/models/OcorrenciaEventoEmbriaoModel';

@Component({
  selector: 'app-inovulacao-manter',
  templateUrl: './inovulacao-manter.component.html',
  styleUrls: ['./inovulacao-manter.component.scss']
})
export class InovulacaoManterComponent extends BaseEventos implements OnInit {

  readonly env = environment;

  isReadOnly: boolean = false;
  embriaoSelecionado: boolean = false;
  tipoEventoEmbriao: EnumTipoEventoSRG = EnumTipoEventoSRG.FIV;
  tiposTransferencia: { value: number; label: string; }[] = [];
  permite: boolean = false;
  compradorLogado: string = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    public accountService: AccountService,
    public api: ApiService,
    public datePipe: DatePipe,
    public commons: Commons,
    public router: Router,
    public dialog: MatDialog
  ) {
    super(api, datePipe, commons, router, accountService, dialog);
    this.tipoEvento = ConstantesTipoEvento.INOVULACAO;
  }

  ngOnInit(): void {
    this.novoEvento();
    this.activatedRoute.paramMap.subscribe(params => {
      this.iniciarTela(params).then(() => {
        if (this.accountService.isCriador || this.accountService.isVeterinario) {
          this.desabilitarCampos();
        }
        this.embriaoSelecionado =
          this.ocorrenciaAtual.EventoAssociado.Id !== '-1' &&
          (this.ocorrenciaAtual.OcorrenciaEventoFiv.Id !== '-1' || this.ocorrenciaAtual.OcorrenciaNacionalizacaoEmbriao.Id !== '-1');
        this.preencherTipos();
        this.showOcorrencias();
      });

    });
    this.isAdmin();
  }

  novoEvento(manterInfosParaSugestao: boolean = false) {
    super.novoEvento(manterInfosParaSugestao);
    this.embriaoSelecionado = manterInfosParaSugestao;
  }

  editar(evento: any) {
    super.editar(evento);

    if (this.ocorrenciaAtual.OcorrenciaEventoFiv.Id !== '-1') this.tipoEventoEmbriao = EnumTipoEventoSRG.FIV;
    else if (this.ocorrenciaAtual.OcorrenciaNacionalizacaoEmbriao.Id !== '-1')
      this.tipoEventoEmbriao = EnumTipoEventoSRG.NacionalizacaoEmbriao;

    this.embriaoSelecionado = (
      this.ocorrenciaAtual.EventoAssociado &&
      this.ocorrenciaAtual.EventoAssociado.Id &&
      this.ocorrenciaAtual.EventoAssociado.Id !== '-1'
    );
  }

  private preencherTipos() {
    this.tiposTransferencia = [];

    this.tiposTransferencia.push({
      value: TipoTransferencia.COLETA_COM_IMPLANTE_TOTAL,
      label: `Coleta Com Implante Total ${(this.env as any).contexto === 'wagyu' ? '(a fresco)' : ''}`
    });
    this.tiposTransferencia.push({
      value: TipoTransferencia.COLETA_COM_CONGELAMENTO_TOTAL,
      label: 'Coleta Com Congelamento Total'
    });
    this.tiposTransferencia.push({
      value: TipoTransferencia.COLETA_COM_IMPLANTE_E_CONGELAMENTO,
      label: 'Coleta Com Implante E Congelamento'
    });
    this.tiposTransferencia.push({
      value: TipoTransferencia.DESCONGELAMENTO_E_IMPLANTE,
      label: 'Descongelamento e  implante'
    });
  }

  botaoConsultarLimparEmbriao() {
    if (this.embriaoSelecionado) this.limparSugestao();
    else this.consultarEmbriao();
  }

  limparSugestao() {
    this.embriaoSelecionado = false;
    this.ocorrenciaAtual.EventoAssociado = new OcorrenciaEventoModel();
    this.ocorrenciaAtual.OcorrenciaEventoFiv = new OcorrenciaEventoTeFivModel();
    this.ocorrenciaAtual.OcorrenciaNacionalizacaoEmbriao = new OcorrenciaEventoEmbriaoModel();

    this.ocorrenciaAtual.Doadora = new ConsultaRapidaBovinoModel();
    this.ocorrenciaAtual.Doadora.TituloConsulta = "Doadora";
    this.ocorrenciaAtual.Doadora.TipoBusca = TipoBuscaBovino.Matriz;
    this.ocorrenciaAtual.Doadora.SexoBovino = Sexo.Fêmea;
    this.ocorrenciaAtual.Doadora.ConsideraInativos = false;
    this.ocorrenciaAtual.Doadora.ConsultaAnimalTodosCriadores = true;
    this.ocorrenciaAtual.Doadora.IdProprietarioConsulta = this.lote.Criador.Id;

    this.ocorrenciaAtual.Receptora.IdProprietarioConsulta = this.lote.Criador.Id;
  }

  consultarEmbriao() {
    if (!this.lote.Criador.Id) {
      Swal.fire('Por favor, informe o criador para relizar a consulta.', '', 'error');
      return;
    }
    const dialogRef = this.dialog.open(
      ConsultarSugestaoFivModalComponent,
      {
        height: '90%',
        width: '90%',
        autoFocus: false,
        data: {
          Doadora: {
            Id: this.ocorrenciaAtual.Doadora.Id,
            CodigoBovino: this.ocorrenciaAtual.Doadora.CodigoBovino,
            NomeBovino: this.ocorrenciaAtual.Doadora.NomeBovino,
            IdProprietario: this.lote.Criador.Id,
            IdProprietarioConsulta: this.lote.Criador.Id,
          },
          CriadorId: this.lote.Criador.Id,
        }
      }
    );

    dialogRef.componentInstance.embriaoSelecionado.subscribe((result: IItenFiv) => {
      if (result && result.success) {
        this.embriaoSelecionado = true;
        this.tipoEventoEmbriao = result.TipoEvento;

        Object.assign(this.ocorrenciaAtual.Doadora, result.Doadora);

        this.ocorrenciaAtual.EventoAssociado = {
          ...this.ocorrenciaAtual.EventoAssociado,
          Id: result.Id,
        }

        if (this.tipoEventoEmbriao === EnumTipoEventoSRG.FIV) {

          /*if (this.ocorrenciaAtual.Veterinario.id === '' || this.ocorrenciaAtual.Veterinario.id === '-1')
            Object.assign(this.ocorrenciaAtual.Veterinario, result.Veterinario)*/

          Object.assign(this.ocorrenciaAtual.OcorrenciaEventoFiv, {
            BovinosDaLista: result.Touros,
            ...result
          });
        }

        if (this.tipoEventoEmbriao === EnumTipoEventoSRG.NacionalizacaoEmbriao)
          Object.assign(this.ocorrenciaAtual.OcorrenciaNacionalizacaoEmbriao, {
            BovinosDaLista: result.Touros,
            ...result
          });
      } else {
        Swal.fire(result.message || 'Não foi possível realizar a operação. ', '', 'error');
      }
    });
  }

  desabilitarCampos() {
    this.ocorrenciaAtual.Doadora.Readonly = true;
    this.ocorrenciaAtual.Reprodutor.Readonly = true;
  }
  aprovadoComPendencia() {
    if ((this.ocorrenciaAtual.Situacao == SituacaoOcorrenciaEvento.AprovadoComPendencias) && (this.accountService.isAdmin)) {
      return true;
    } else {
      return false;
    }
  }

  isAdmin() {
    if (this.accountService.isAdmin || this.accountService.isVeterinario) {
      this.permite = true;
    } else {
      this.permite = false;
    }
  }

  showOcorrencias() {
    let token: any = this.accountService.getPayloadToken();
    if (token) {
      this.compradorLogado = token.CriadorId;
    }
  }
}
