import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'srg';

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitzer: DomSanitizer,
  ){
    this.matIconRegistry.addSvgIcon('baixa', this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/svg/baixa.svg'));
    this.matIconRegistry.addSvgIcon('criador', this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/svg/criador.svg'));
    this.matIconRegistry.addSvgIcon('analise_leite', this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/svg/analise_leite.svg'));
    this.matIconRegistry.addSvgIcon('plantel', this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/svg/plantel.svg'));
    this.matIconRegistry.addSvgIcon('certificado', this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/svg/certificado.svg'));
    this.matIconRegistry.addSvgIcon('padreacao', this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/svg/padreacao.svg'));
    this.matIconRegistry.addSvgIcon('bovino', this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/svg/bovino.svg'));
    this.matIconRegistry.addSvgIcon('fiv', this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/svg/fiv.svg'));
    this.matIconRegistry.addSvgIcon('inovulacao', this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/svg/inovulacao.svg'));
    this.matIconRegistry.addSvgIcon('inspecao', this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/svg/inspecao.svg'));
    this.matIconRegistry.addSvgIcon('nacionalizacao', this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/svg/nacionalizacao.svg'));
    this.matIconRegistry.addSvgIcon('balanca', this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/svg/balanca.svg'));
    this.matIconRegistry.addSvgIcon('dna', this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/svg/dna.svg'));
    this.matIconRegistry.addSvgIcon('controle_carcaca', this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/svg/controle_carcaca.svg'));
  }
}
