import { DocumentosLoteModalComponent } from './../documentos-lote-modal/documentos-lote-modal.component';
import { AccountService } from '../../../account/shared/account.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormularioModel } from 'src/app/models/FormularioModel';
import { MatDialog } from '@angular/material/dialog';
import { ComunicacoesModalComponent } from '../comunicacoes-modal/comunicacoes-modal.component';
import { OcorrenciaEventoPadreacaoModel } from 'src/app/models/OcorrenciaEventoPadreacaoModel';
import { OcorrenciaEventoTeFivModel } from 'src/app/models/OcorrenciaEventoTeFivModel';
import { Router } from '@angular/router';
import { ConstantesAcaoEvento } from 'src/app/shared/constantes';
import { OcorrenciaEventoAbateModel } from 'src/app/models/OcorrenciaEventoAbateModel';
import { OcorrenciaEventoAbateComAvalCarcacaModel } from 'src/app/models/OcorrenciaEventoAbateComAvalCarcacaModel';
import { OcorrenciaEventoBaixaModel } from 'src/app/models/OcorrenciaEventoBaixaModel';

@Component({
  selector: 'app-formulario-tabs',
  templateUrl: './formulario-tabs.component.html',
  styleUrls: ['./formulario-tabs.component.scss']
})
export class FormularioTabsComponent implements OnInit {

  @Input() formulario: FormularioModel = new FormularioModel();
  @Input() listaComunicacao: [] = [];
  @Input() ocorrenciaAtualPad: Array<OcorrenciaEventoPadreacaoModel> = [];
  @Input() ocorrenciaAtualFiv: Array<OcorrenciaEventoTeFivModel> = [];
  @Input() ocorrenciaAtualPosAbate: Array<OcorrenciaEventoBaixaModel> = [];
  @Input() tipoEvento: string = '';

  @Output() tabSelecionada = new EventEmitter<string>();
  @Output() criadorSelecionado = new EventEmitter<string>();
  @Output() triggerConsultaInsp: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private accountService: AccountService,
    private dialog: MatDialog,
    public router: Router,
  ) { }

  ngOnInit(): void {
    let token: any = this.accountService.getPayloadToken();

    if (token) {
      this.formulario.criador.id = token.CriadorId;
      this.formulario.criador.codigo = token.CodigoCriador;
      this.formulario.criador.nome = token.NomeCriador;
      this.formulario.criador.afixo = token.AfixoCriador;
      this.formulario.criador.readonly = this.accountService.isCriador;
    }

    if (this.formulario) {
    }
  }

  selecionarTab(tab: string) {
    this.tabSelecionada.emit(tab);
  }

  selecionarCriador(idCriador: string) {
    if (idCriador) {
      this.criadorSelecionado.emit(idCriador);
    }
  }

  comunicacoes() {
    this.dialog.open(
      ComunicacoesModalComponent,
      {
        height: '90%',
        width: '90%',
        autoFocus: false,
        data: { listaComunicacao: this.listaComunicacao }
      }
    );

  }

  documentos() {
    this.dialog.open(
      DocumentosLoteModalComponent,
      {
        height: '90%',
        width: '90%',
        autoFocus: false,
        data: { idLote: this.formulario.id, situacaoLote: this.formulario.situacaoLote }
      }
    ).afterClosed().subscribe(result => {
      if (this.tipoEvento == 'padreacao') {
        this.router.navigate([
          `${this.tipoEvento}_manter`,
          { e: btoa(this.ocorrenciaAtualPad[0].Id), a: btoa(ConstantesAcaoEvento.Visualizar) },
        ]);
      } else if (this.tipoEvento == 'fiv'){
          this.router.navigate([
            `${this.tipoEvento}_manter`,
            { e: btoa(this.ocorrenciaAtualFiv[0].Id), a: btoa(ConstantesAcaoEvento.Visualizar) },
          ]);
      } else if (this.tipoEvento == 'abatecomavalcarcaca_manter_pos')
          this.router.navigate([
            `${this.tipoEvento}`,
            { e: btoa(this.ocorrenciaAtualPosAbate[0].Id), a: btoa(ConstantesAcaoEvento.Visualizar) },
          ]);
    });
  }

  criadorSelecionadoInsp(idcriador: string) {
    this.triggerConsultaInsp.emit(idcriador);
  }

}
