import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GenealogiaBovinoModel } from 'src/app/models/EntidadeGenealogiaBovinoModel';
import { ApiService } from 'src/app/shared/api.service';
import { TabsPlantel } from 'src/app/shared/enums/Enums';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-genealogia-bovino',
  templateUrl: './genealogia-bovino.component.html',
  styleUrls: ['./genealogia-bovino.component.scss']
})
export class GenealogiaBovinoComponent implements OnInit {

  tabs = TabsPlantel;
  @Input() bovinoId: string | null = null;
  genealogia: GenealogiaBovinoModel = new GenealogiaBovinoModel();

  constructor(
    private router: Router,
    private api: ApiService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.bovinoId = params['id'];
      
      if (!this.bovinoId)
        this.router.navigate(['plantel_consultar']);
      else 
        this.consultar();
    });
  }

  consultar(): void {

    if (!this.bovinoId) {
      this.router.navigate(['plantel_consultar']);
      return;
    }

    this.api.consultarGenealogiaBovino(this.bovinoId).then((result: any) => {
      if (result) {
        if (result.success) {
          
          this.genealogia.Animal = result.Animal;
          
          this.genealogia.Pai = result.Pai;
          this.genealogia.Mae = result.Mae;

          this.genealogia.Avo1Paterno = result.Avo1Paterno;
          this.genealogia.Avo2Paterno = result.Avo2Paterno;
          this.genealogia.Avo1Materno = result.Avo1Materno;
          this.genealogia.Avo2Materno = result.Avo2Materno;
          
          this.genealogia.Bisavo1Materno = result.Bisavo1Materno;
          this.genealogia.Bisavo2Materno = result.Bisavo2Materno;
          this.genealogia.Bisavo3Materno = result.Bisavo3Materno;
          this.genealogia.Bisavo4Materno = result.Bisavo4Materno;
          this.genealogia.Bisavo1Paterno = result.Bisavo1Paterno;
          this.genealogia.Bisavo2Paterno = result.Bisavo2Paterno;
          this.genealogia.Bisavo3Paterno = result.Bisavo3Paterno;
          this.genealogia.Bisavo4Paterno = result.Bisavo4Paterno;

        } else {
          Swal.fire(result.message, '', 'error');
        }
      }

    });
  }

}
