<app-formulario-tabs 
  [formulario]="formulario" 
  [listaComunicacao]="lote?.ListaComunicacao" 
  (tabSelecionada)="tabSelecionada($event)" 
  (criadorSelecionado)="criadorSelecionado($event)"
>
</app-formulario-tabs>

<div class="card card-principal-evento">
  <div class="card-body">
    <div class="row">
      <div class="col-lg-12">
        <div *ngIf="!isNewLote()" class="card card-evento card-body">
        
          <div class="row card-title-evento">
            <div class="col-6">
              <h4 class="card-title">
                Dados da Classificação Linear
              </h4>
            </div>
            <div class="col-6 text-right">
              <button *ngIf="ocorrenciaAtual.Id !== '-1'"  type="button" class="btn btn-link btn-sm" (click)="documentosDaOcorrencia()" ><i class="far fa-folder"></i> Documentos</button>
            </div>
          </div>
  
          <div class="row">
            
            <div class="form-group col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <label>*Data Classificação</label>
              <div class="input-group input-group-sm">
                <input class="form-control" [disabled]="isOcorrenciaReadOnly()" name="dataClassificacao" [matDatepicker]="dataClassificacao" [max]="maxDate" [(ngModel)]="ocorrenciaAtual.DataClassificacao"> 
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" type="button" (click)="dataClassificacao.open()"> <i class="bi bi-calendar3"></i> </button>
                </div>
              </div>
              <mat-datepicker [startAt]="maxDate" #dataClassificacao></mat-datepicker>
            </div>
          
            <div class="form-group col-lg-9 col-md-9 col-sm-12 col-xs-12">
              <app-consultar-inspetor [inspetor]="ocorrenciaAtual.Inspetor"></app-consultar-inspetor>
            </div>
            
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <app-consultar-bovino [bovino]="ocorrenciaAtual.Animal"
                [ocorrenciaAtualSituacao]="ocorrenciaAtual.Situacao"
                (BovinoEncontrado)="animalSelecionado($event)">
              </app-consultar-bovino>
            </div>
            
            <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <app-consultar-bovino [bovino]="ocorrenciaAtual.Touro"></app-consultar-bovino>
            </div>
  
            <div class="form-group col-lg-4 col-md-3 col-sm-6 col-xs-12">
              <label>*Nascimento</label>
              <div class="input-group input-group-sm">
                <input disabled class="form-control" name="dataNascimento" [matDatepicker]="dataNascimento" [(ngModel)]="ocorrenciaAtual.DataNascimento"> 
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" type="button" (click)="dataNascimento.open()"> <i class="bi bi-calendar3"></i> </button>
                </div>
              </div>
              <mat-datepicker [startAt]="maxDate" #dataNascimento></mat-datepicker>
            </div>
  
            <div class="form-group col-lg-4 col-md-3 col-sm-6 col-xs-12">
              <label>*Último parto</label>
              <div class="input-group input-group-sm">
                <input class="form-control" [disabled]="isOcorrenciaReadOnly()" name="dataUltimoParto" [matDatepicker]="dataUltimoParto" [max]="maxDate" [(ngModel)]="ocorrenciaAtual.DataUltimoParto"> 
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" type="button" (click)="dataUltimoParto.open()"> <i class="bi bi-calendar3"></i> </button>
                </div>
              </div>
              <mat-datepicker [startAt]="ocorrenciaAtual.DataUltimoParto" #dataUltimoParto></mat-datepicker>
            </div>
  
            <div class="form-group col-lg-4 col-md-6 col-sm-6 col-xs-12">
              <label>*Lactações</label>
              <input [disabled]="isOcorrenciaReadOnly()" mask="00" name="lactacoes" type="text" class="form-control form-control-sm" [(ngModel)]="ocorrenciaAtual.Lactacoes">
            </div>
        
          </div>
  
          <!-- tabela -->
  
          <table class="table table-bordered custom-table" style="margin-top: 30px;">
            
            <thead>
              <tr>
                <th class="text-center">Seção</th> 
                <th class="text-center">Score</th> 
                <th >Características Descritivas</th> 
                <th >Características Defeitos</th> 
              </tr>
            </thead>
  
            <tbody>
              <tr class="custom-row">
                <td class="align-middle text-center">Garupa (8%)</td>
                <td class="align-middle">
                  <input name="garupa_score" [disabled]="isOcorrenciaReadOnly()" mask="00" type="text" class="form-control form-control-sm" [(ngModel)]="ocorrenciaAtual.ScoreGarupa">
                </td>
                <td class="custom-column">
                  <tr>
                    <td>Ângulo Garupa (5)</td>
                    <td>
                      <input name="garupa_angulo" [disabled]="isOcorrenciaReadOnly()" mask="0"  type="text" class="form-control form-control-sm" [(ngModel)]="ocorrenciaAtual.AnguloGarupa">
                    </td>
                  </tr>
                  <tr>
                    <td>Largura da Garupa (9)</td>
                    <td>
                      <input name="garupa_largura" [disabled]="isOcorrenciaReadOnly()" mask="0"  type="text" class="form-control form-control-sm" [(ngModel)]="ocorrenciaAtual.LarguraDaGarupa">
                    </td>
                  </tr>
                  <tr>
                    <td>Força Lombo (9)</td>
                    <td>
                      <input name="garupa_forca" [disabled]="isOcorrenciaReadOnly()" mask="0"  type="text" class="form-control form-control-sm" [(ngModel)]="ocorrenciaAtual.ForcaLombo">
                    </td>
                  </tr>
                </td>
                <td class="custom-column custom-label-checkbox">
                  <tr>
                    <td>
                      <label>
                        <input type="checkbox" [disabled]="isOcorrenciaReadOnly()" [(ngModel)]="ocorrenciaAtual.AnusAvancado"> 
                        (10) Anus Avançado 1,5
                      </label>
                    </td>
                    <td>
                      <label>
                        <input type="checkbox" [disabled]="isOcorrenciaReadOnly()" [(ngModel)]="ocorrenciaAtual.InsCaudaAlta">
                        (13) Ins. Cauda Alta 0,5
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>
                        <input type="checkbox" [disabled]="isOcorrenciaReadOnly()" [(ngModel)]="ocorrenciaAtual.InsCaudaAvancada">
                        (11) Ins. Cauda Avançada 1,5
                      </label>
                    </td>
                    <td>
                      <label>
                        <input type="checkbox" [disabled]="isOcorrenciaReadOnly()" [(ngModel)]="ocorrenciaAtual.CaudaTorcida">
                        (14) Cauda Torcida 1
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>
                        <input type="checkbox" [disabled]="isOcorrenciaReadOnly()" [(ngModel)]="ocorrenciaAtual.InsCaudaRecuada">
                        (12) Ins. Cauda Recuada 1
                      </label>
                    </td>
                    <td></td>
                  </tr>
                </td>
              </tr>
  
            </tbody>
          </table>
  
          <table class="table table-bordered custom-table" style="margin-top: 30px;">
            <tbody>
  
              <tr class="custom-row">
                <td class="align-middle text-center">Sistema mamário (48%)</td>
                <td class="align-middle">
                  <input name="sm_score" [disabled]="isOcorrenciaReadOnly()" mask="00" type="text" class="form-control form-control-sm" [(ngModel)]="ocorrenciaAtual.ScoreSistemaMamario">
                </td>
                <td class="custom-column">
                  <tr>
                    <td>Inserção Úbere Anterior (9)</td>
                    <td>
                      <input name="sm_ub_ant" [disabled]="isOcorrenciaReadOnly()" mask="0" type="text" class="form-control form-control-sm" [(ngModel)]="ocorrenciaAtual.InsercaoUbereAnterior">
                    </td>
                  </tr>
                  <tr>
                    <td>Colocação Tetos Ant (5)</td>
                    <td>
                      <input name="sm_tetos_ant" [disabled]="isOcorrenciaReadOnly()" mask="0" type="text" class="form-control form-control-sm" [(ngModel)]="ocorrenciaAtual.ColocacaoTetosAnt">
                    </td>
                  </tr>
                  <tr>
                    <td>Comprimento Tetos (5)</td>
                    <td>
                      <input name="sm_comp_tetos" [disabled]="isOcorrenciaReadOnly()" mask="0" type="text" class="form-control form-control-sm" [(ngModel)]="ocorrenciaAtual.ComprimentoTetos">
                    </td>
                  </tr>
                  <tr>
                    <td>Altura Úbere Posterior (9)</td>
                    <td>
                      <input name="sm_alt_ubere" [disabled]="isOcorrenciaReadOnly()" mask="0" type="text" class="form-control form-control-sm" [(ngModel)]="ocorrenciaAtual.AlturaUberePosterior">
                    </td>
                  </tr>
                  <tr>
                    <td>Largura Úbere Posterior (9)</td>
                    <td>
                      <input name="sm_larg_ubere" [disabled]="isOcorrenciaReadOnly()" mask="0" type="text" class="form-control form-control-sm" [(ngModel)]="ocorrenciaAtual.LarguraUberePosteiror">
                    </td>
                  </tr>
                  <tr>
                    <td>Colocação Tetos Post (5)</td>
                    <td>
                      <input name="sm_tetos_post" [disabled]="isOcorrenciaReadOnly()" mask="0" type="text" class="form-control form-control-sm" [(ngModel)]="ocorrenciaAtual.ColocacaoTetosPost">
                    </td>
                  </tr>
                  <tr>
                    <td>Profundidade do Úbere (5)</td>
                    <td>
                      <input name="sm_score_profun_ubere" [disabled]="isOcorrenciaReadOnly()" mask="0" type="text" class="form-control form-control-sm" [(ngModel)]="ocorrenciaAtual.ProfundidadeDoUbere">
                    </td>
                  </tr>
                  <tr>
                    <td>Textura do Úbere (9)</td>
                    <td>
                      <input name="sm_text_ubere" [disabled]="isOcorrenciaReadOnly()" mask="0" type="text" class="form-control form-control-sm" [(ngModel)]="ocorrenciaAtual.TexturaDoUbere">
                    </td>
                  </tr>
                  <tr>
                    <td>Ligamento mediano (9)</td>
                    <td>
                      <input name="sm_mediano" [disabled]="isOcorrenciaReadOnly()" mask="0" type="text" class="form-control form-control-sm" [(ngModel)]="ocorrenciaAtual.LigamentoMediano">
                    </td>
                  </tr>
                </td>
                <td class="custom-column custom-label-checkbox">
                  <tr>
                    <td>
                      <label>
                        <input type="checkbox" [disabled]="isOcorrenciaReadOnly()" [(ngModel)]="ocorrenciaAtual.PisoUbInclinadoAnt"> 
                        (20) Piso Ub Inclinado Ant 1
                      </label>
                    </td>
                    <td>
                      <label>
                        <input type="checkbox" [disabled]="isOcorrenciaReadOnly()" [(ngModel)]="ocorrenciaAtual.QuartoCego">
                        (26) Quarto Cego
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>
                        <input type="checkbox" [disabled]="isOcorrenciaReadOnly()" [(ngModel)]="ocorrenciaAtual.PisoUbInclinadoPost">
                        (21) Piso Ub Inclinado Pos 5
                      </label>
                    </td>
                    <td>
                      <label>
                        <input type="checkbox" [disabled]="isOcorrenciaReadOnly()" [(ngModel)]="ocorrenciaAtual.TetoPalmipede">
                        (27) Teto Palmípede 2
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>
                        <input type="checkbox" [disabled]="isOcorrenciaReadOnly()" [(ngModel)]="ocorrenciaAtual.InsAnteriorCurta">
                        (22) Ins. Anterior Curta 1
                      </label>
                    </td>
                    <td>
                      <label>
                        <input type="checkbox" [disabled]="isOcorrenciaReadOnly()" [(ngModel)]="ocorrenciaAtual.TetoAntParaTraz">
                        (28) Teto Ant P/ Traz 1
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>
                        <input type="checkbox" [disabled]="isOcorrenciaReadOnly()" [(ngModel)]="ocorrenciaAtual.InsPosteriorCurta">
                        (23) Ins. Posterior Curta 1
                      </label>
                    </td>
                    <td>
                      <label>
                        <input type="checkbox" [disabled]="isOcorrenciaReadOnly()" [(ngModel)]="ocorrenciaAtual.TetoPosParaTraz">
                        (29) Teto Pos P/ Traz 1
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>
                        <input type="checkbox" [disabled]="isOcorrenciaReadOnly()" [(ngModel)]="ocorrenciaAtual.FaltaDeConformUbere">
                        (24) Falta de Conform Úbere 1
                      </label>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <label>
                        <input type="checkbox" [disabled]="isOcorrenciaReadOnly()" [(ngModel)]="ocorrenciaAtual.QuartoDesbalanceado">
                        (25) Quarto Desbalanceado 1
                      </label>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                  </tr>
                </td>
              </tr>
  
            </tbody>
          </table>
  
          <table class="table table-bordered custom-table" style="margin-top: 30px;">
            <tbody>
  
              <tr class="custom-row">
                <td class="align-middle text-center">Pés & pernas (13%)</td>
                <td class="align-middle">
                  <input name="pes_score" [disabled]="isOcorrenciaReadOnly()" mask="00" type="text" class="form-control form-control-sm" [(ngModel)]="ocorrenciaAtual.ScorePesPernas">
                </td>
                <td class="custom-column">
                  <tr>
                    <td>Ângulo de Cascos (7)</td>
                    <td>
                      <input name="pes_ang_casco" [disabled]="isOcorrenciaReadOnly()" mask="0" type="text" class="form-control form-control-sm" [(ngModel)]="ocorrenciaAtual.AnguloDeCascos">
                    </td>
                  </tr>
                  <tr>
                    <td>Profundidade Talão (9)</td>
                    <td>
                      <input name="pes_talao" [disabled]="isOcorrenciaReadOnly()" mask="0" type="text" class="form-control form-control-sm" [(ngModel)]="ocorrenciaAtual.ProfundidadeTalao">
                    </td>
                  </tr>
                  <tr>
                    <td>Qualidade Óssea (9)</td>
                    <td>
                      <input name="pes_ossea" [disabled]="isOcorrenciaReadOnly()" mask="0" type="text" class="form-control form-control-sm" [(ngModel)]="ocorrenciaAtual.QualidadeOssea">
                    </td>
                  </tr>
                  <tr>
                    <td>Pernas Pos - V Lat (5)</td>
                    <td>
                      <input name="pes_pos_lat" [disabled]="isOcorrenciaReadOnly()" mask="0" type="text" class="form-control form-control-sm" [(ngModel)]="ocorrenciaAtual.PernasPosVLat">
                    </td>
                  </tr>
                  <tr>
                    <td>Pernas Pos - V Post (9)</td>
                    <td>
                      <input name="pes_pos_post" [disabled]="isOcorrenciaReadOnly()" mask="0" type="text" class="form-control form-control-sm" [(ngModel)]="ocorrenciaAtual.PernasPosVPost">
                    </td>
                  </tr>
                  <tr>
                    <td>Locomoção (9)</td>
                    <td>
                      <input name="pes_locomocao" [disabled]="isOcorrenciaReadOnly()" mask="0" type="text" class="form-control form-control-sm" [(ngModel)]="ocorrenciaAtual.Locomocao">
                    </td>
                  </tr>
                </td>
                <td class="custom-column custom-label-checkbox">
                  <tr>
                    <td>
                      <label>
                        <input type="checkbox" [disabled]="isOcorrenciaReadOnly()" [(ngModel)]="ocorrenciaAtual.UnhaTorcida"> 
                        (30) Unha Torcida
                      </label>
                    </td>
                    <td>
                      <label>
                        <input type="checkbox" [disabled]="isOcorrenciaReadOnly()" [(ngModel)]="ocorrenciaAtual.Caimbra">
                        (34) Câimbra 3
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>
                        <input type="checkbox" [disabled]="isOcorrenciaReadOnly()" [(ngModel)]="ocorrenciaAtual.QuartelaFraca">
                        (31) Quartela Fraca 1
                      </label>
                    </td>
                    <td>
                      <label>
                        <input type="checkbox" [disabled]="isOcorrenciaReadOnly()" [(ngModel)]="ocorrenciaAtual.CoxoFemAtrasAvanc">
                        (35) Coxa Fem Atras/Avanç
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>
                        <input type="checkbox" [disabled]="isOcorrenciaReadOnly()" [(ngModel)]="ocorrenciaAtual.DerrameJarretes">
                        (32) Derrame Jarretes 1
                      </label>
                    </td>
                    <td>
                      <label>
                        <input type="checkbox" [disabled]="isOcorrenciaReadOnly()" [(ngModel)]="ocorrenciaAtual.PesParaFora">
                        (36) Pés P/ Fora
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>
                        <input type="checkbox" [disabled]="isOcorrenciaReadOnly()" [(ngModel)]="ocorrenciaAtual.InsufucienciaOssea">
                        (12) Insuficiência Óssea
                      </label>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                  </tr>
                </td>
              </tr>
  
            </tbody>
          </table>
  
          <table class="table table-bordered custom-table" style="margin-top: 30px;">
            <tbody>
  
              <tr class="custom-row">
                <td class="align-middle text-center">Força leiteira (31%)</td>
                <td class="align-middle">
                  <input name="fl_score" [disabled]="isOcorrenciaReadOnly()" mask="00" type="text" class="form-control form-control-sm" [(ngModel)]="ocorrenciaAtual.ScoreForcaLeiteira">
                </td>
                <td class="custom-column">
                  <tr>
                    <td>Estatura (7,8,9)</td>
                    <td>
                      <input name="fl_estatura" [disabled]="isOcorrenciaReadOnly()" mask="0" type="text" class="form-control form-control-sm" [(ngModel)]="ocorrenciaAtual.Estatura">
                    </td>
                  </tr>
                  <tr>
                    <td>Nivelamento Linha Superior (5,6,7)</td>
                    <td>
                      <input name="fl_niv_sup" [disabled]="isOcorrenciaReadOnly()" mask="0" type="text" class="form-control form-control-sm" [(ngModel)]="ocorrenciaAtual.NivelamentoLinhaSuperior">
                    </td>
                  </tr>
                  <tr>
                    <td>Largura de Peito (7)</td>
                    <td>
                      <input name="fl_larg_peito" [disabled]="isOcorrenciaReadOnly()" mask="0" type="text" class="form-control form-control-sm" [(ngModel)]="ocorrenciaAtual.LarguraDePeito">
                    </td>
                  </tr>
                  <tr>
                    <td>Profundidade Corporal (7)</td>
                    <td>
                      <input name="fl_profun_corp" [disabled]="isOcorrenciaReadOnly()" mask="0" type="text" class="form-control form-control-sm" [(ngModel)]="ocorrenciaAtual.ProfdeCorporal">
                    </td>
                  </tr>
                  <tr>
                    <td>Angulosidade (9)</td>
                    <td>
                      <input name="fl_angulosidade" [disabled]="isOcorrenciaReadOnly()" mask="0" type="text" class="form-control form-control-sm" [(ngModel)]="ocorrenciaAtual.Angulosidade">
                    </td>
                  </tr>
                </td>
                <td class="custom-column custom-label-checkbox">
                  <tr>
                    <td>
                      <label>
                        <input type="checkbox" [disabled]="isOcorrenciaReadOnly()" [(ngModel)]="ocorrenciaAtual.DesvioDeChanfro"> 
                        (40) Desvio de Chanfro
                      </label>
                    </td>
                    <td>
                      <label>
                        <input type="checkbox" [disabled]="isOcorrenciaReadOnly()" [(ngModel)]="ocorrenciaAtual.DorsoFraco">
                        (44) Dorso Fraco 1
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>
                        <input type="checkbox" [disabled]="isOcorrenciaReadOnly()" [(ngModel)]="ocorrenciaAtual.MandibulaDefeito">
                        (41) Mandíbula Defeito 1
                      </label>
                    </td>
                    <td>
                      <label>
                        <input type="checkbox" [disabled]="isOcorrenciaReadOnly()" [(ngModel)]="ocorrenciaAtual.FaltaArqueamento">
                        (45) Falta Arqueamento 1,5
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>
                        <input type="checkbox" [disabled]="isOcorrenciaReadOnly()" [(ngModel)]="ocorrenciaAtual.CostelasAntApert">
                        (42) Costelas Ant Apert 1,5
                      </label>
                    </td>
                    <td>
                      <label>
                        <input type="checkbox" [disabled]="isOcorrenciaReadOnly()" [(ngModel)]="ocorrenciaAtual.FaltaDeEquilibrio">
                        (46) Falta de Equilíbrio 1
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>
                        <input type="checkbox" [disabled]="isOcorrenciaReadOnly()" [(ngModel)]="ocorrenciaAtual.RetroEscapulaFraca">
                        (43) Retro Escápula Fraca
                      </label>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                  </tr>
                </td>
              </tr>
  
            </tbody>
          </table>
  
          <div class="row">
            <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
              <label for="formularioProtocolo">Classe</label>
              <select [disabled]="isOcorrenciaReadOnly()" class="form-control form-control-sm" name="classe" [(ngModel)]="ocorrenciaAtual.Classe">
                <option *ngFor="let classe of classes" [value]="classe.value" [label]="classe.label"></option>
              </select>
            </div>
  
            <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
              <label>Pontos</label>
              <input [disabled]="isOcorrenciaReadOnly()" mask="00" name="pontos" type="text" class="form-control form-control-sm" [(ngModel)]="ocorrenciaAtual.Pontos">
            </div>
          </div>
  
          <div class="row">
            <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <label>Observação</label>
              <textarea 
                type="text" 
                name="observacao" 
                [disabled]="isOcorrenciaReadOnly()"
                class="form-control form-control-sm"
                [(ngModel)]="ocorrenciaAtual.Descricao"
                style="min-height: calc(1.5em + 0.5rem + 2px); height: 50px;"
              ></textarea>
            </div>
          </div>
    
        </div>
    
        <div class="col-lg-12">
          <app-botoes-acoes-ocorrencia [lote]="lote" [idOcorrencia]="ocorrenciaAtual.Id" (acaoSelecionada)="acaoBotao($event)"></app-botoes-acoes-ocorrencia>
        </div>
        
        <!--Lista de Ocorrencias-->
        <div>
          <div *ngIf="ocorrencias.length > 0" class="row" style="margin-top: 15px;">
            <div class="col-lg-12 table-responsive">
              <table class="table table-hover table-header-custom">
                <thead>
                  <tr>
                    <th>Animal</th>
                    <th>Data Classificação</th>
                    <th>Classe</th>
                    <th>Situação</th>
                    <th width="10%">Opções</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let ev of ocorrencias" [ngClass]="{'ocorrencia-em-destaque': ev.Id === ocorrenciaAtual.Id }">
                    <td>{{ ev.Animal.NomeBovino }}</td>
                    <td>{{ ev.DataClassificacao | date: 'dd/MM/yyyy' }}</td>
                    <td>{{ commons.getDescricaoClassifcacaoLinearClasse( ev.Classe ) }}</td>
                    <td>{{ commons.getDescricaoSituacao( ev.Situacao ) }}</td>
                    <td class="text-center text-nowrap acoes-evento">
                      <i *ngIf="ev.ImputaMulta === 1" matTooltip="Multa Imputada" class="fas fa-dollar-sign"></i>
                      <i *ngIf="ev.SolicitarDna === 1" matTooltip="Será solicitado DNA no Nascimento" class="fas fa-dna"> </i>
                      <i *ngIf="permiteEdicao(ev.Situacao)" class="fas fa-edit" (click)="editar(ev)"></i>
                      <i *ngIf="!permiteEdicao(ev.Situacao)" class="fas fa-eye" (click)="editar(ev)"></i>
                      <i *ngIf="lote.Situacao === 1" class="fas fa-trash-alt" (click)="deletar(ev)"></i>
                      <i *ngIf="ev.IndInconsitencia" class="fa fa-exclamation-triangle" style="cursor: pointer;" matTooltip="{{ev.Inconsistencia}}"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        
      </div>
    </div>
  </div>
</div>