<table class="table table-borderless" style="margin-top: 30px;">
            
  <thead>
    <tr>
      <th class="text-center" width="25%">Animal</th> 
      <th class="text-center" width="25%">Pais</th> 
      <th class="text-center" width="25%">Avós</th> 
      <th class="text-center" width="25%">Bisavós</th> 
    </tr>
  </thead>

  <tbody>
    <!-- Bisavo1Paterno -->
    <tr class="custom-row">
      <td></td>
      <td></td>
      <td></td>
      <td class="align-middle text-center custom-item-background-color mat-elevation-z3">
        <a [routerLink]="['/bovino_detalhar', genealogia.Bisavo1Paterno?.BovinoId ]" [queryParams]="{ tab: tabs.Genealogia }">
          <strong>{{ genealogia.Bisavo1Paterno?.Codigo }}</strong> - {{ genealogia.Bisavo1Paterno?.Nome }}
        </a>
      </td>
    </tr>
    <!-- Avo1Paterno -->
    <tr class="custom-row">
      <td></td>
      <td></td>
      <td class="align-middle text-center custom-item-background-color mat-elevation-z3">
        <a [routerLink]="['/bovino_detalhar', genealogia.Avo1Paterno?.BovinoId ]" [queryParams]="{ tab: tabs.Genealogia }">
          <strong >{{ genealogia.Avo1Paterno?.Codigo }}</strong> - {{ genealogia.Avo1Paterno?.Nome }}
        </a>
      </td>
      <td></td>
    </tr>
    <!-- Bisavo2Paterno -->
    <tr class="custom-row">
      <td></td>
      <td></td>
      <td></td>
      <td class="align-middle text-center custom-item-background-color mat-elevation-z3">
        <a [routerLink]="['/bovino_detalhar', genealogia.Bisavo2Paterno?.BovinoId ]" [queryParams]="{ tab: tabs.Genealogia }">
          <strong>{{ genealogia.Bisavo2Paterno?.Codigo }}</strong> - {{ genealogia.Bisavo2Paterno?.Nome }}
        </a>
      </td>
    </tr>
    <!-- Pai -->
    <tr class="custom-row">
      <td></td>
      <td class="align-middle text-center custom-item-background-color mat-elevation-z3">
        <a [routerLink]="['/bovino_detalhar', genealogia.Pai?.BovinoId ]" [queryParams]="{ tab: tabs.Genealogia }">
          <strong>{{ genealogia.Pai?.Codigo }}</strong> - {{ genealogia.Pai?.Nome }}
        </a>
      </td>
      <td></td>
      <td></td>
    </tr>
    <!-- Bisavo3Paterno -->
    <tr class="custom-row">
      <td></td>
      <td></td>
      <td></td>
      <td class="align-middle text-center custom-item-background-color mat-elevation-z3">
        <a [routerLink]="['/bovino_detalhar', genealogia.Bisavo3Paterno?.BovinoId ]" [queryParams]="{ tab: tabs.Genealogia }">
          <strong>{{ genealogia.Bisavo3Paterno?.Codigo }}</strong> - {{ genealogia.Bisavo3Paterno?.Nome }}
        </a>
      </td>
    </tr>
    <!-- Avo2Paterno -->
    <tr class="custom-row">
      <td></td>
      <td></td>
      <td class="align-middle text-center custom-item-background-color mat-elevation-z3">
        <a [routerLink]="['/bovino_detalhar', genealogia.Avo2Paterno?.BovinoId ]" [queryParams]="{ tab: tabs.Genealogia }">
          <strong>{{ genealogia.Avo2Paterno?.Codigo }}</strong> - {{ genealogia.Avo2Paterno?.Nome }}
        </a>
      </td>
      <td></td>
    </tr>
    <!-- Bisavo4Paterno -->
    <tr class="custom-row">
      <td></td>
      <td></td>
      <td></td>
      <td class="align-middle text-center custom-item-background-color mat-elevation-z3">
        <a [routerLink]="['/bovino_detalhar', genealogia.Bisavo4Paterno?.BovinoId ]" [queryParams]="{ tab: tabs.Genealogia }">
          <strong>{{ genealogia.Bisavo4Paterno?.Codigo }}</strong> - {{ genealogia.Bisavo4Paterno?.Nome }}
        </a>
      </td>
    </tr>
    
    <!-- Animal -->
    <tr class="custom-row">
      <td id="custom-td-animal" class="align-middle text-center custom-item-background-color mat-elevation-z3">
        <label><strong>Código:</strong> {{ genealogia. Animal?.Codigo }} </label>
        <label><strong>Nome:</strong> {{ genealogia. Animal?.Nome }} </label> 
        <label><strong>Nascimento:</strong> {{ genealogia. Animal?.DataNascimento }} </label>
        <label><strong>Ativo:</strong> {{ genealogia. Animal?.Situacao }} </label>
      </td>
      <td ></td>
      <td ></td>
      <td ></td>
    </tr>
    
    <!-- Bisavo1Materno -->
    <tr class="custom-row">
      <td></td>
      <td></td>
      <td></td>
      <td class="align-middle text-center custom-item-background-color mat-elevation-z3">
        <a [routerLink]="['/bovino_detalhar', genealogia.Bisavo1Materno?.BovinoId ]" [queryParams]="{ tab: tabs.Genealogia }">
          <strong>{{ genealogia.Bisavo1Materno?.Codigo }}</strong> - {{ genealogia.Bisavo1Materno?.Nome }}
        </a>
      </td>
    </tr>
    <!-- Avo1Materno -->
    <tr class="custom-row">
      <td></td>
      <td></td>
      <td class="align-middle text-center custom-item-background-color mat-elevation-z3">
        <a [routerLink]="['/bovino_detalhar', genealogia.Avo1Materno?.BovinoId ]" [queryParams]="{ tab: tabs.Genealogia }">
          <strong>{{ genealogia.Avo1Materno?.Codigo }}</strong> - {{ genealogia.Avo1Materno?.Nome }}
        </a>
      </td>
      <td></td>
    </tr>
    <!-- Bisavo2Materno -->
    <tr class="custom-row">
      <td></td>
      <td></td>
      <td></td>
      <td class="align-middle text-center custom-item-background-color mat-elevation-z3">
        <a [routerLink]="['/bovino_detalhar', genealogia.Bisavo2Materno?.BovinoId ]" [queryParams]="{ tab: tabs.Genealogia }">
          <strong>{{ genealogia.Bisavo2Materno?.Codigo }}</strong> - {{ genealogia.Bisavo2Materno?.Nome }}
        </a>
      </td>
    </tr>
    <!-- Mae -->
    <tr class="custom-row">
      <td></td>
      <td class="align-middle text-center custom-item-background-color mat-elevation-z3">
        <a [routerLink]="['/bovino_detalhar', genealogia.Mae?.BovinoId ]" [queryParams]="{ tab: tabs.Genealogia }">
          <strong>{{ genealogia.Mae?.Codigo }}</strong> - {{ genealogia.Mae?.Nome }}
        </a>
      </td>
      <td></td>
      <td></td>
    </tr>
    <!-- Bisavo3Materno -->
    <tr class="custom-row">
      <td></td>
      <td></td>
      <td></td>
      <td class="align-middle text-center custom-item-background-color mat-elevation-z3">
        <a [routerLink]="['/bovino_detalhar', genealogia.Bisavo3Materno?.BovinoId ]" [queryParams]="{ tab: tabs.Genealogia }">
          <strong>{{ genealogia.Bisavo3Materno?.Codigo }}</strong> - {{ genealogia.Bisavo3Materno?.Nome }}
        </a>
      </td>
    </tr>
    <!-- Avo2Materno -->
    <tr class="custom-row">
      <td></td>
      <td></td>
      <td class="align-middle text-center custom-item-background-color mat-elevation-z3">
        <a [routerLink]="['/bovino_detalhar', genealogia.Avo2Materno?.BovinoId ]" [queryParams]="{ tab: tabs.Genealogia }">
          <strong>{{ genealogia.Avo2Materno?.Codigo }}</strong> - {{ genealogia.Avo2Materno?.Nome }}
        </a>
      </td>
      <td></td>
    </tr>
    <!-- Bisavo4Materno -->
    <tr class="custom-row">
      <td></td>
      <td></td>
      <td></td>
      <td class="align-middle text-center custom-item-background-color mat-elevation-z3">
        <a [routerLink]="['/bovino_detalhar', genealogia.Bisavo4Materno?.BovinoId ]" [queryParams]="{ tab: tabs.Genealogia }">
          <strong>{{ genealogia.Bisavo4Materno?.Codigo }}</strong> - {{ genealogia.Bisavo4Materno?.Nome }}
        </a>
      </td>
    </tr>

  </tbody>

</table>