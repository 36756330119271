<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <i class="bi bi-x" color="primary"></i>
</button>

<h2 mat-dialog-title>Documentos do Evento</h2>

<div *ngIf="situacaoOcorrencia === 2"  class="row">
  <div class="col-12">
    <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload>

    <div class="file-upload">
      <div class="form-group">
        <label for="fileName">Arquivo</label>
        <div class="input-group input-group-sm">
          <input type="text" class="form-control" name="fileName" placeholder="Selecione o arquivo" aria-label="Selecione o arquivo" [(ngModel)]="fileName" readonly>
          <div class="input-group-append">
            <button class="btn btn-primary" type="button" (click)="fileUpload.click()">Carregar Arquivo</button>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="descricao">Descrição</label>
        <textarea class="form-control form-control-sm" name="descricao" [(ngModel)]="descricao" rows="2"></textarea>
      </div>

    </div>
  </div>

  <div class="col-md-12 text-right">
    <button class="btn btn-primary btn-sm" type="button" matTooltip="Gerar Relatório de Inspeção" (click)="anexarArquivo()">
      <i class="fas fa-cloud-upload-alt"></i>&nbsp;Anexar Arquivo
    </button>
  </div>
  
</div>

<div class="row" *ngIf="documentos.length > 0" style="margin-top: 15px;">
  <div class="col-lg-12 table-responsive">
    <table class="table table-hover table-header-custom">
      <thead>
        <tr>
          <th>Nome do Arquivo</th>
          <th>Descrição</th>
          <th class="text-center text-nowrap">Ação</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of documentos">
          <td>{{ item.Nome }}</td>
          <td>{{ item.Descricao }}</td>
          <td class="text-center text-nowrap acoes-evento">
            <i class="fas fa-cloud-download-alt" style="cursor: pointer;" matTooltip="Download do Arquivo" (click)="download(item)"></i>
            <i *ngIf="situacaoOcorrencia === 2" class="fas fa-trash-alt" style="cursor: pointer;" matTooltip="Deletar o Arquivo" (click)="deletar(item)"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>