import { BovinoModel } from "./BovinoModel";
import { CriadorModel } from "./CriadorModel";
import { InspetorModel } from "./InspetorModel";

export class FiltroConsultaPlantelModel{
  situacao: string;
  nascimentoDe: string;
  nascimentoAte: string;
  criador: CriadorModel;
  bovino: BovinoModel;
  inspetor: InspetorModel;

  constructor(){
    this.situacao = 'Ativo';
    this.nascimentoDe = '';
    this.nascimentoAte = '';
    this.criador = new CriadorModel();
    this.bovino = new BovinoModel();
    this.inspetor = new InspetorModel();
  }
}
