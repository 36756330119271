import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TabsPlantel } from 'src/app/shared/enums/Enums';
import { ApiService } from 'src/app/shared/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bovino-detalhar',
  templateUrl: './bovino-detalhar.component.html',
  styleUrls: ['./bovino-detalhar.component.scss']
})
export class BovinoDetalharComponent implements OnInit {

  readonly env = environment;
  
  tabs = TabsPlantel;
  tabSelecionada: TabsPlantel = TabsPlantel.DadosBovino;
  bovinoId: string | null = null;
  codigoNome: string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService,
  ) { }

  ngOnInit(): void {
    this.bovinoId = this.route.snapshot.paramMap.get('id');

    if (!this.bovinoId)
      this.router.navigate(['plantel_consultar']);
    else{
      this.consultarDetalhes();
    }

    const query_tab: (string|null) = this.route.snapshot.queryParamMap.get('tab');
    if (!query_tab) return;

    const number_tab: number = Number(query_tab);
    if (!isNaN(number_tab)) this.tabSelecionada = number_tab;  
  }

  setTab(tab: number) {
    this.tabSelecionada = tab in TabsPlantel? tab : 0;
    this.router.navigate(['.'], { 
      relativeTo: this.route, 
      queryParams: { tab: this.tabSelecionada, readOnly: true }, 
      replaceUrl: true 
    });
  }
  
  consultarDetalhes(){
    this.api.consultarDetalhesBovino(this.bovinoId + '').then((result: any) =>
      {
        if(result){
          if(result.success){
            this.codigoNome = result.Bovino.Codigo + " - " + result.Bovino.Nome;
          }
        }
      });
  }
  
}
