import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { AccountService } from 'src/app/account/shared/account.service';
import { RolesKeys } from 'src/app/account/shared/RolesKeys';
import { BovinoModel } from 'src/app/models/BovinoModel';
import { DocumentoFSCModel } from 'src/app/models/DocumentoFSCModel';
import { Commons } from 'src/app/shared/commons';
import { ConstantesRotas } from 'src/app/shared/constantes';
import { Lab, QualificaLaudoDNA, Sexo } from 'src/app/shared/enums/Enums';
import {
  IJsonResultModel,
  IResponseRecuperarLaudoPorId,
  IResponseVisualizarLaudoDna,
} from 'src/app/shared/interfaces/Interfaces';
import Swal from 'sweetalert2';
import { LaudoDNAModel } from '../../models/LaudoDNAModel';
import { ApiService } from '../../shared/api.service';
import { environment } from 'src/environments/environment';
import { CriadorModel } from 'src/app/models/CriadorModel';

@Component({
  selector: 'app-laudo-dna-manter',
  templateUrl: './laudo-dna-manter.component.html',
  styleUrls: ['./laudo-dna-manter.component.scss'],
})
export class LaudoDnaManterComponent implements OnInit {
  readonly env = environment;

  protected labs: Lab[] = (this.env as any).LABORATORIOS;

  public labCtrl: FormControl = new FormControl();

  public labFilterCtrl: FormControl = new FormControl();

  public filteredLabs: ReplaySubject<Lab[]> = new ReplaySubject<Lab[]>(1);


  @ViewChild('singleSelect')
  singleSelect!: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  readonly maxDate = new Date();

  resetForm: boolean = false;
  readyToSave: boolean = true;
  readOnly: boolean = true;
  listaQualifica: { value: number; label: string }[] = [];
  formularioLaudo: LaudoDNAModel = new LaudoDNAModel();
  animalQualificaPermanente: BovinoModel = new BovinoModel();
  @Output() triggerDocumento: EventEmitter<void> = new EventEmitter<void>();
  @Output() triggerConsulta: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private commons: Commons,
    private account: AccountService
  ) { }

  ngOnInit(): void {
    // set initial selection
    this.labCtrl.setValue(this.labs[0]);

    // load the initial lab list
    this.filteredLabs.next(this.labs.slice());

    // listen for search field value changes
    this.labFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterLabs();
    });

    this.preencherQualifica();

    this.animalQualificaPermanente.TituloConsulta = 'Animal';
    this.animalQualificaPermanente.ConsultaAnimalTodosCriadores = true;

    const readonly: string | null = this.route.snapshot.queryParamMap.get('readOnly');
    this.readOnly = readonly !== null && readonly === 'true';
    if (!this.readOnly && !this.account.checkRole(RolesKeys.LaudoDNARegistrar))
      this.router.navigate([ConstantesRotas.LAUDO_DNA_CONSULTAR]);

    this.formularioLaudo.Pai.Readonly = this.readOnly;
    this.formularioLaudo.Mae.Readonly = this.readOnly;

    this.formularioLaudo.Id = this.route.snapshot.queryParamMap.get('id') || '-1';
    if (this.formularioLaudo.Id !== '-1') this.recuperarPorId();

    this.resetForm = ((): boolean => {
      const fromUrl = this.route.snapshot.queryParamMap.get('resetForm');
      return fromUrl !== null && fromUrl === 'true';
    })();

    let token: any = this.account.getPayloadToken();

    if (token) {
      this.formularioLaudo.Criador.id = token.CriadorId;
      this.formularioLaudo.Criador.codigo = token.CodigoCriador;
      this.formularioLaudo.Criador.nome = token.NomeCriador;
      this.formularioLaudo.Criador.readonly = this.account.isCriador;
    }
  }

  protected setInitialValue() {
    this.filteredLabs.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
      this.singleSelect.compareWith = (a: Lab, b: Lab) => a && b && a.id === b.id;
    });
  }

  protected filterLabs() {
    if (!this.labs) {
      return;
    }
    let search = this.labFilterCtrl.value;
    if (!search) {
      this.filteredLabs.next(this.labs.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredLabs.next(this.labs.filter(lab => lab.name.toLowerCase().indexOf(search) > -1));
  }

  private recuperarPorId() {
    this.api.recuperarLaudoDnaPorId(this.formularioLaudo.Id).then(async (result: IResponseRecuperarLaudoPorId) => {
      if (result && result.success) {

        Object.assign(this.formularioLaudo, result);
        this.formularioLaudo.NomeAnimal = result.NomeAnimal;
        this.formularioLaudo.NumeroLaudo = result.NumeroLaudo;
        Object.assign(this.formularioLaudo.Arquivo, result.Arquivo);
        Object.assign(this.formularioLaudo.Pai, {
          ...result.Pai,
          Readonly: this.readOnly,
          TituloConsulta: 'Pai',
        });
        Object.assign(this.formularioLaudo.Mae, {
          ...result.Mae,
          Readonly: this.readOnly,
          TituloConsulta: 'Mãe',
        });

        let codigo = result.IdCriador;
        const response = await this.api.consultarCriadorPorCodigo({ codigo, consultaEmTodasFiliadas: true });
        if (response) {


          this.formularioLaudo.Criador.codigo = codigo;
          this.formularioLaudo.Criador.nome = response.NomeCriador;

        }
      } else {
        Swal.fire(result.message || 'Não foi possível realizar a operação.', '', 'error');
        this.router.navigate([ConstantesRotas.LAUDO_DNA_CONSULTAR]);
      }
      if (this.isEdit()) {
        let type = this.formularioLaudo.Arquivo.Nome.split(".");
        switch (type[1]) {
          case "pdf":
            this.formularioLaudo.Mimetype = "application/pdf";
            break;
          case "png":
            this.formularioLaudo.Mimetype = "image/png";
            break;
          case "jpg":
            this.formularioLaudo.Mimetype = "image/jpg";
            break;
          case "jpeg":
            this.formularioLaudo.Mimetype = "image/jpeg";
            break;
        }
      }
    });
  }

  preencherQualifica() {
    this.listaQualifica = [];

    this.listaQualifica.push({ value: -1, label: 'Selecione...' });
    this.listaQualifica.push({ value: QualificaLaudoDNA.Sim, label: 'Sim' });
    this.listaQualifica.push({ value: QualificaLaudoDNA.Nao, label: 'Não' });
    this.listaQualifica.push({
      value: QualificaLaudoDNA.Permanente,
      label: 'Permanente',
    });
  }

  botaoSalvar() {
    if (this.formularioLaudo.Criador.codigo == null || this.formularioLaudo.Criador.codigo == "") {
      Swal.fire('Por favor, informe o criador!.', '', 'error');
      return;
    }
    this.api.manterLaudoDna(this.formularioLaudo).then((result: any) => {
      if (result && result.success) {
        Swal.fire(result.message, '', 'success');
        if (result.Id) {
          this.router.navigate([ConstantesRotas.LAUDO_DNA_MANTER], {
            queryParams: {
              readOnly: true,
              id: result.Id,
              resetForm: this.formularioLaudo.Id === '' || this.formularioLaudo.Id === '-1',
            },
          });
          //window.location.reload();
        } else {
          this.router.navigate([ConstantesRotas.LAUDO_DNA_CONSULTAR]);
        }
      } else {
        Swal.fire(result.message || 'Não foi possível realizar a operação.', '', 'error');
      }
    });
  }

  visualizarDocumento(id: string | undefined) {
    if (!id) return;

    this.api.visualizarLaudoDna(id).then((result: IJsonResultModel<IResponseVisualizarLaudoDna>) => {
      if (result && result.Data?.success) {
        this.commons.visualizarBase64EmPdf(result.Data.content, result.ContentType || '');
      } else {
        Swal.fire(result.Data?.message || 'Não foi possível realizar a operação.', '', 'error');
      }
    });
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    const acceptedFileTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];

    if (file) {
      if (file.type && file.type !== '') {
        const isAccepted =
          acceptedFileTypes.findIndex(element => {
            return element === file.type;
          }) !== -1;
        if (isAccepted) {
          this.formularioLaudo.Arquivo.Nome = file.name;
          this.formularioLaudo.Arquivo.Length = file.size;
          this.formularioLaudo.Mimetype = file.type;
          const reader = new FileReader();
          reader.readAsDataURL(file);

          reader.onloadstart = () => {
            this.readyToSave = false;
          };
          reader.onloadend = () => {
            this.readyToSave = true;
            this.formularioLaudo.Arquivo.Content = (reader.result as string) || '';
          };
        } else {
          this.formularioLaudo.Arquivo = new DocumentoFSCModel();
          Swal.fire(
            'O formato de arquivo é inválido. São permitidos arquivos nos formatos: pdf, jpg, jpeg e png.',
            '',
            'error'
          );
        }
      }
    }
  }

  private setInfosAnimalQualificaAlterado(sexo: Sexo, nome: string, codigo: string) {
    this.formularioLaudo.Sexo = sexo;
    this.formularioLaudo.NomeAnimal = nome;
    this.formularioLaudo.CodigoAnimal = codigo;
  }

  private resetPaiEMae(readonly: boolean) {
    this.formularioLaudo.Pai = {
      ...this.formularioLaudo.Pai,
      Id: '',
      CodigoBovino: '',
      NomeBovino: '',
      Readonly: readonly,
    };
    this.formularioLaudo.Mae = {
      ...this.formularioLaudo.Mae,
      Id: '',
      CodigoBovino: '',
      NomeBovino: '',
      Readonly: readonly,
    };
  }

  qualificaAlterado() {
    const qualificaPermanente = this.formularioLaudo.Qualifica == QualificaLaudoDNA.Permanente;

    this.formularioLaudo.Pai.Readonly = qualificaPermanente;
    this.formularioLaudo.Mae.Readonly = qualificaPermanente;

    this.setInfosAnimalQualificaAlterado(this.formularioLaudo.Sexo, '', '');

    if (qualificaPermanente) {
      this.resetPaiEMae(true);
      this.formularioLaudo.Sexo = Sexo.Todos;
    }
  }

  animalSelecionado() {
    if (!this.animalQualificaPermanente.Id || this.animalQualificaPermanente.Id === '-1') {
      this.resetPaiEMae(true);
      this.setInfosAnimalQualificaAlterado(Sexo.Todos, '', '');
      return;
    }

    const { SexoBovino, NomeBovino, CodigoBovino } = this.animalQualificaPermanente;
    this.setInfosAnimalQualificaAlterado(SexoBovino, NomeBovino, CodigoBovino);

    // recuperar codigo-nome do pai e mae do animal
    this.api.consultarDetalhesBovino(this.animalQualificaPermanente.Id).then((result: any) => {
      if (result && result.success) {
        Object.assign(this.formularioLaudo.Pai, {
          ...result.Bovino.Pai,
          Readonly: true,
          TituloConsulta: 'Pai',
        });
        Object.assign(this.formularioLaudo.Mae, {
          ...result.Bovino.Mae,
          Readonly: true,
          TituloConsulta: 'Mãe',
        });
      } else {
        Swal.fire(result.message || 'Não foi possível recuperar as informações do animal.', '', 'error');
      }
    });
  }

  botaoCadastrarNovo() {
    this.formularioLaudo = new LaudoDNAModel();
    this.router.navigate([ConstantesRotas.LAUDO_DNA_MANTER]);
  }

  handleLabChange(event: any) {
    this.formularioLaudo.Laboratorio = event;
  }

  isEdit() {
    if (this.formularioLaudo.Id != null || this.formularioLaudo.Id != "") {
      return true;
    } else {
      return false;
    }
  }

  criadorSelecionado(criadorId: string) {
    this.formularioLaudo.Criador.id = criadorId;


  }
}
