import { OcorrenciaEventoAdministrativoModel } from "./OcorrenciaEventoAdministrativoModel";
import { UsuarioProcuradorModel } from "./UsuarioProcuradorModel";

export class OcorrenciaEventoApropriacaoProcuradorModel extends OcorrenciaEventoAdministrativoModel {
  Procurador: UsuarioProcuradorModel;
  PeriodoVigencia: { De: string; Ate: string; };

  constructor() {
    super()
    
    this.PeriodoVigencia = {
      De: '',
      Ate: '',
    };

    this.Procurador = new UsuarioProcuradorModel();
  }
}