import { AccountService } from 'src/app/account/shared/account.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FiltroConsultaNacionalizacaoModel } from 'src/app/models/FiltroConsultaNacionalizacaoModel';

@Component({
  selector: 'app-filtro-consulta-nacionalizacao',
  templateUrl: './filtro-consulta-nacionalizacao.component.html',
  styleUrls: ['./filtro-consulta-nacionalizacao.component.scss']
})
export class FiltroConsultaNacionalizacaoComponent implements OnInit {

  @Output() triggerConsulta: EventEmitter<void> = new EventEmitter<void>();
  @Input() formConsulta: FiltroConsultaNacionalizacaoModel = new FiltroConsultaNacionalizacaoModel();
  situacoesEvento: {value: number, label: string}[] = [];

  constructor(private accountService: AccountService) { }

  ngOnInit(): void {
    this.preencherSituacoes();
    let token: any = this.accountService.getPayloadToken();

    if(token){
      this.formConsulta.criador.id = token.CriadorId;
      this.formConsulta.criador.codigo = token.CodigoCriador;
      this.formConsulta.criador.nome = token.NomeCriador;
      this.formConsulta.criador.readonly = this.accountService.isCriador;

      this.criadorSelecionado(this.formConsulta.criador.id);
    }

    this.recuperarFormularioPainelGerencial();
  }

  preencherSituacoes(){
    this.situacoesEvento = [];
    this.situacoesEvento.push({value: 0, label: "Todas"});
    this.situacoesEvento.push({value: 2, label: "Pendente"});
    this.situacoesEvento.push({value: 5, label: "Cancelado"});
    this.situacoesEvento.push({value: 50, label: "Aprovado"});
    this.situacoesEvento.push({value: 51, label: "Aprovado Com Pendências"});
    this.situacoesEvento.push({value: 52, label: "Rejeitado"});
    this.situacoesEvento.push({value: 53, label: "Aguardando Aprovação"});
    this.situacoesEvento.push({value: 54, label: "Aguardando Resolução"});
    this.situacoesEvento.push({value: 55, label: "Aprovado Pelo Superintendente"});
    this.situacoesEvento.push({value: 56, label: "Rejeitado Pelo Superintendente"});
  }

  criadorSelecionado(idCriador: string){
    this.formConsulta.bovino.IdProprietarioConsulta = idCriador;
  }

  private recuperarFormularioPainelGerencial() {
    const state = history.state;
    if (!state) return;
  
    if (state.Periodo) {
      this.formConsulta.eventoDe = state.Periodo.De;
      this.formConsulta.eventoAte = state.Periodo.Ate;
    }

    if (state.Criador)
      Object.assign(this.formConsulta.criador, state.Criador)

    this.formConsulta.situacao = state.Situacao || 0;
    
    this.triggerConsulta.emit();
  }

}
