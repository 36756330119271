export abstract class ConstantesEventos {
  static readonly ACAO_BOTAO_APROVAR = 'APROVAR';
  static readonly ACAO_BOTAO_APROVAR_COM_PENDENCIAS = 'APROVAR_COM_PENDENCIAS';
  static readonly ACAO_BOTAO_REJEITAR = 'REJEITAR';
  static readonly ACAO_BOTAO_REVERTER_REJEICAO = 'REVERTER_REJEICAO';
  static readonly ACAO_BOTAO_NOVO = 'NOVO';
  static readonly ACAO_BOTAO_SALVAR = 'SALVAR';
  static readonly ACAO_BOTAO_GERAR_PDF = 'GERAR_PDF';
  static readonly ACAO_BOTAO_GERAR_EXCEL = 'GERAR_EXCEL';
  static readonly ACAO_BOTAO_SUBMETER = 'SUBMETER';
  static readonly ACAO_BOTAO_SUBMETER_E_APROVAR = 'SUBMETER_E_APROVAR';
  static readonly ACAO_BOTAO_DESCARTAR = 'DESCARTAR';
  static readonly ACAO_BOTAO_APROVAR_TODAS = 'APROVAR_TODAS';
  static readonly ACAO_BOTAO_REVISAR = 'REVISAR';
  static readonly ACAO_BOTAO_APROVAR_TODOS = 'APROVAR_TODOS';
}

export abstract class ConstantesTipoEvento {
  static readonly PADREACAO = "padreacao";
  static readonly NASCIMENTO = "nascimento";
  static readonly BAIXA = 'baixa';
  static readonly TEFIV = 'fiv';
  static readonly INOVULACAO = 'inovulacao';
  static readonly TRANSFERENCIA = 'transferencia';
  static readonly NACIONALIZACAO = 'nacionalizacao';
  static readonly EMBRIAO = 'nacionalizacao-embriao';
  static readonly CLASSIFICAO_LINEAR = 'classificacaoLinear';
  static readonly INSPECAO = 'inspecao';
  static readonly PESAGEM = 'pesagem';
  static readonly ABATE_COM_AVAL_CARCACA = 'abate-com-aval-carcaca';
}

export abstract class ConstantesAcaoEvento {
  static readonly Visualizar = "VISUALIZAR";
  static readonly Deliberar = "DELIBERAR";
  static readonly Editar = "EDITAR";
}

export abstract class ConstantesRotas {
  static readonly CRIADOR_CONSULTAR = "criadores_consultar";
  static readonly CRIADOR_MANTER = "criador_manter";

  static readonly USUARIO_REGISTRAR = "registrar_usuario";
  static readonly USUARIO_CONSULTAR = "usuario_consultar";

  static readonly EXPOSICAO_REGISTRAR = "registrar_exposicao";
  static readonly EXPOSICAO_CONSULTAR = "exposicao_consultar";

  static readonly REGISTRAR_CONTROLE_LEITERO = "registrar_controle_leiteiro";
  static readonly CONSULTAR_CONTROLE_LEITERO = "controle_leiteiro_consultar";

  static readonly PLANTEL_CONSULTAR = "plantel_consultar";
  static readonly BOVINO_MANTER = "bovino_manter";
  static readonly BOVINO_DETALHAR = "bovino_detalhar";

  static readonly CERTIFICADOS_CONSULTAR = "certificados_consultar";
  static readonly PENDENTES_DE_EMISSAO = "pendentes_de_emissao";

  static readonly PADREACAO_CONSULTAR = "padreacao_consultar";
  static readonly PADREACAO_MANTER = "padreacao_manter";

  static readonly NASCIMENTO_CONSULTAR = "nascimento_consultar";
  static readonly NASCIMENTO_MANTER = "nascimento_manter";

  static readonly TE_FIV_CONSULTAR = "fiv_consultar";
  static readonly TE_FIV_MANTER = "fiv_manter";

  static readonly INOVULACAO_CONSULTAR = "inovulacao_consultar";
  static readonly INOVULACAO_MANTER = "inovulacao_manter";

  static readonly BAIXA_CONSULTAR = "baixa_consultar";
  static readonly BAIXA_MANTER = "baixa_manter";

  static readonly TRANSFERENCIA_CONSULTAR = "transferencia_consultar";
  static readonly TRANSFERENCIA_MANTER = "transferencia_manter";

  static readonly INSPECAO_CONSULTAR = "inspecao_consultar";

  static readonly CLASSIFICACAO_CONSULTAR = "classificacaolinear_consultar";
  static readonly CLASSIFICACAO_MANTER = "classificacaolinear_manter";

  static readonly NACIONALIZACAO_CONSULTAR_SEMEN = "nacionalizacao_consultar";
  static readonly NACIONALIZACAO_MANTER_SEMEN = "nacionalizacao_manter";

  static readonly NACIONALIZACAO_CONSULTAR_EMBRIAO = "nacionalizacaoembriao_consultar";
  static readonly NACIONALIZACAO_MANTER_EMBRIAO = "nacionalizacaoembriao_manter";

  static readonly PESAGEM_MANTER = "pesagem_manter";
  static readonly PESAGEM_CONSULTAR = "pesagem_consultar";

  static readonly LAUDO_DNA_CONSULTAR = "laudo_dna_consultar";
  static readonly LAUDO_DNA_MANTER = "laudo_dna_manter";

  static readonly CARCACA_CONSULTAR = "abatecomavalcarcaca_consultar";
  static readonly CARCACA_MANTER = "abatecomavalcarcaca_manter";
  static readonly CARCACA_MANTER_POS = "abatecomavalcarcaca_manter_pos";

  static readonly INSPECAO_RELATORIO = "inspecao_relatorio"
  static readonly INSPECAO_MANTER = "inspecao_manter"
}