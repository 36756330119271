import { ApiService } from 'src/app/shared/api.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-documentos-ocorrencia-modal',
  templateUrl: './documentos-ocorrencia-modal.component.html',
  styleUrls: ['./documentos-ocorrencia-modal.component.scss']
})
export class DocumentosOcorrenciaModalComponent implements OnInit {

  documentos: any[] = [];
  fileName = '';
  base64: any;
  descricao = '';
  idOcorrencia = '';
  situacaoOcorrencia: number = 0;

  constructor(
    private api: ApiService,
    public dialogRef: MatDialogRef<DocumentosOcorrenciaModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.idOcorrencia = this.data.idOcorrencia;
    this.situacaoOcorrencia = this.data.situacaoOcorrencia;

    this.consultarArquivos();
  }

  consultarArquivos(){
    this.api.arquivosDaOcorrencia(this.idOcorrencia).then((result: any) =>{
      if(result){
        this.documentos = result.ListaArquivos;
      }
    })
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];

    if (file) {
      this.fileName = file.name;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.base64 = reader.result;
      };
    }
  }

  anexarArquivo() {
    if(this.fileName && this.base64){

      let arquivo = {
        IdOcorrencia: this.idOcorrencia,
        Descricao: this.descricao,
        Nome: this.fileName,
        Content: this.base64.split(",")[1]
      };

      this.api.uploadArquivoDaOcorrencia(arquivo).then((result: any) => {
        if(result){
          if(result.success){
            Swal.fire("Arquivo anexado com sucesso.", '', 'success');
            this.descricao = "";
            this.fileName = "";
            this.base64 = "";
            this.consultarArquivos();
          }else{
            Swal.fire(result.message, '', 'error');
          }
        }
      });
    }else{
      Swal.fire("Selecione um arquivo para enviar", '', 'error');
    }
  }

  deletar(arquivo: any){
    Swal.fire({title: "Confirmação", html: "Confirma a exclusão do arquivo?", icon: 'question', showCancelButton: true, cancelButtonText: 'Não', confirmButtonText: 'Sim'}).then((resultConfirmacao) => {
      if (resultConfirmacao.isConfirmed) {
        this.api.excluirArquivo(arquivo).then((result: any) => {
          if(result){
            if(result.success){
              Swal.fire("Arquivo excluido com sucesso.", '', 'success');
              this.consultarArquivos();
            }else{
              Swal.fire(result.message, '', 'error');
            }
          }
        });
      }
    });
  }

  download(arquivo: any){
    this.api.downloadArquivo(arquivo).then((result: any) => {
      if(result){
        const linkSource = `data:application/octet-stream;base64,${result.Content}`;
        const downloadLink = document.createElement("a");
        const fileName = result.Nome;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    });
  }
  
}
