import { Component, OnInit, Input } from '@angular/core';
import { FiltroExposicaoModel } from 'src/app/models/FiltroConsultaExposicaoModel';
import { UnidadesFederativas } from '../../shared/enums/Enums';

@Component({
  selector: 'app-filtro-consulta-exposicao',
  templateUrl: './filtro-consulta-exposicao.component.html',
  styleUrls: ['./filtro-consulta-exposicao.component.scss']
})
export class FiltroConsultaExposicaoComponent implements OnInit {

  @Input() formConsulta: FiltroExposicaoModel = new FiltroExposicaoModel();
  
  UFs: { value: number, label: string }[] = [];

  constructor() {}

  ngOnInit(): void {
    this.preencherEstados();
  }

  preencherEstados(){
    this.UFs = [];
    this.UFs.push({ value: 0, label: "Selecione ..."});
    for (let i=1 ; i<28 ; i++)
      this.UFs.push({ value: i, label: UnidadesFederativas[i]});
  }

}
