<div class="card" style="margin-top: 1em;">
	<div class="card-body">

		<div class="row">

			<div class="form-group col-lg-3 col-md-3 col-sm-3 col-xs-12">
				<label>Data importação</label>
				<div class="input-group input-group-sm">
					<input class="form-control" name="dataImportacao" [matDatepicker]="dataImportacao"
						[(ngModel)]="formRegistrar.dataImportacao">
					<div class="input-group-append">
						<button class="btn btn-outline-secondary" type="button" (click)="dataImportacao.open()">
							<i class="bi bi-calendar3"></i>
						</button>
					</div>
				</div>
				<mat-datepicker [startAt]="formRegistrar.dataImportacao" #dataImportacao></mat-datepicker>
			</div>

			<div id="arquivo-container" class="form-group col-lg-9 col-md-9 col-sm-9 col-xs-6">
				<label>Arquivo</label>
				<div>
					<input type="text" readonly class="form-control form-control-sm" name="arquivo"
						[value]="formRegistrar.arquivo.nome">
					<input type="file" #uploader name="arquivo" (change)="onFileSelected($event)">
					<button type="button" class="btn btn-sm btn-block btn-primary" (click)="uploader.click()">
						Enviar arquivo
					</button>
				</div>
			</div>

		</div>

		<div class="row">

			<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
				<label>Descrição</label>
				<textarea type="text" name="descricao" class="form-control form-control-sm"
					[(ngModel)]="formRegistrar.descricao"
					style="min-height: calc(1.5em + 0.5rem + 2px); height: 50px;"></textarea>
			</div>

		</div>

		<div class="row justify-content-end" id="botao-container">
			<div *ngIf="permiteNovo" class="col-lg-4 col-md-10 col-sm-10">
				<button type="button" class="btn btn-sm btn-block btn-primary" (click)="anexarArquivo()">Salvar</button>
			</div>
		</div>

	</div>
</div>