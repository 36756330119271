import { BovinoModel } from 'src/app/models/BovinoModel';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import Swal from 'sweetalert2';
import { ApiService } from '../api.service';
import { MatDialog } from '@angular/material/dialog';
import { Sexo, SituacaoOcorrenciaEvento } from '../enums/Enums';
import { ConsultarBovinoModalComponent } from '../consultar-bovino-modal/consultar-bovino-modal.component';
import { BaseEventos } from 'src/app/eventos/base-eventos';
import { AccountService } from 'src/app/account/shared/account.service';
import { CloseScrollStrategy } from '@angular/cdk/overlay';

@Component({
  selector: 'app-consultar-bovino',
  templateUrl: './consultar-bovino.component.html',
  styleUrls: ['./consultar-bovino.component.scss'],
})
export class ConsultarBovinoComponent implements OnInit {
  @Input() bovino: BovinoModel = new BovinoModel();
  @Input() ocorrenciaAtualSituacao: number = -1;
  @Input() nascimento: boolean = false;
  @Output() BovinoEncontrado: EventEmitter<any> = new EventEmitter<any>();
  @Input() permite: Boolean = true;

  constructor(private api: ApiService, private dialog: MatDialog, public accountService: AccountService) { }

  ngOnInit(): void {
    this.permiteEditarComPendencia();
    this.permiteEditar();
  }

  limpar() {
    this.bovino.Id = '';
    this.bovino.CodigoBovino = '';
    this.bovino.NomeBovino = '';
    this.bovino.SexoBovino = Sexo.Todos;
    this.bovino.CodigoNomeProprietario = '';
    this.bovino.IdProprietario = '';
    this.bovino.OrelhaDireita = '';
    this.BovinoEncontrado.emit({});
  }

  consultarPorCodigo() {
    this.api.consultarBovinoPorCodigo(this.bovino).then((result: any) => {
      if (result) {
        if (result.Status === 0) {
          this.bovino.Id = result.Bovino.Id;
          this.bovino.CodigoBovino = result.Bovino.CodigoBovino;
          this.bovino.OrelhaDireita = result.Bovino.CodigoSecundarioBovino;
          this.bovino.NomeBovino = result.Bovino.NomeBovino;
          this.bovino.SexoBovino = result.Bovino.SexoBovino;
          this.bovino.CodigoNomeProprietario = result.Bovino.ProprietarioBovino;
          this.bovino.IdProprietario = result.Bovino.IdProprietario;
          this.bovino.DestinoInspecao = result.Bovino.DestinoInspecao;
          this.BovinoEncontrado.emit(this.bovino);
        } else {
          this.limpar();
          Swal.fire(result.Mensagem, '', 'error');
        }
      }
    });
  }

  consultarPorNome() {
    if (this.bovino.IdProprietarioConsulta === '' && !this.bovino.ConsultaAnimalTodosCriadores) {
      Swal.fire('Por favor informe o criador.', '', 'error');
    } else {
      const dialogRef = this.dialog.open(ConsultarBovinoModalComponent, {
        height: '90%',
        width: '90%',
        autoFocus: false,
        data: { bovino: this.bovino },
      });

      dialogRef.componentInstance.bovinoSelecionado.subscribe((result: any) => {
        if (result) {
          this.bovino.Id = result.Id;
          this.bovino.CodigoBovino = result.CodigoBovino;
          this.bovino.OrelhaDireita = result.CodigoSecundarioBovino;
          this.bovino.NomeBovino = result.NomeBovino;
          this.bovino.SexoBovino = result.SexoBovino;
          this.bovino.CodigoNomeProprietario = result.ProprietarioBovino;
          this.bovino.IdProprietario = result.IdProprietario;
          this.bovino.DestinoInspecao = result.DestinoInspecao;
          this.BovinoEncontrado.emit(this.bovino);
        } else {
          this.limpar();
          Swal.fire(result.Mensagem, '', 'error');
        }
      });
    }
  }
  permiteEditarComPendencia() {
    if (
      (this.ocorrenciaAtualSituacao == SituacaoOcorrenciaEvento.AprovadoComPendencias ||
        this.ocorrenciaAtualSituacao == SituacaoOcorrenciaEvento.AguardandoResolucao ||
        this.ocorrenciaAtualSituacao == SituacaoOcorrenciaEvento.Pendente) &&
      this.accountService.isAdmin
    ) {
      this.bovino.Readonly = false;
    } else if (
      (this.ocorrenciaAtualSituacao == SituacaoOcorrenciaEvento.AprovadoComPendencias ||
        this.ocorrenciaAtualSituacao == SituacaoOcorrenciaEvento.AguardandoResolucao ||
        this.ocorrenciaAtualSituacao == SituacaoOcorrenciaEvento.Pendente) &&
      this.accountService.isAdmin &&
      this.nascimento == true
    ) {
      this.bovino.Readonly = true;
    }
  }

  permiteEditar() {
    if (this.permite) {
      return false;
    } else {
      return true;
    }
  }
}
