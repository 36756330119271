<div class="card">

  <div class="card-body">
      <h4 class="card-title">
        <option selected>{{codigoNome}}</option>
      </h4>
      <!-- Título das Abas -->
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <a class="nav-link" [ngClass]="{'active': tabSelecionada === tabs.DadosBovino}" (click)="setTab(tabs.DadosBovino)" id="dados-bovino-tab" data-toggle="tab" href="#dados-bovino" role="tab" aria-controls="dados-bovino" aria-selected="true">Dados do Bovino</a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link" [ngClass]="{'active': tabSelecionada === tabs.Genealogia}" (click)="setTab(tabs.Genealogia)" id="genealogia-bovino-tab" data-toggle="tab" href="#genealogia-bovino" role="tab" aria-controls="genealogia-bovino" aria-selected="false">Genealogia</a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link" [ngClass]="{'active': tabSelecionada === tabs.Eventos}" (click)="setTab(tabs.Eventos)" id="eventos-bovino-tab" data-toggle="tab" href="#eventos-bovino" role="tab" aria-controls="eventos-bovino" aria-selected="false">Eventos</a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link" [ngClass]="{'active': tabSelecionada === tabs.Documentos}" (click)="setTab(tabs.Documentos)" id="documentos-bovino-tab" data-toggle="tab" href="#documentos-bovino" role="tab" aria-controls="documentos-bovino" aria-selected="false">Documentos</a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link" [ngClass]="{'active': tabSelecionada === tabs.Crias}" (click)="setTab(tabs.Crias)" id="crias-bovino-tab" data-toggle="tab" href="#crias-bovino" role="tab" aria-controls="crias-bovino" aria-selected="false">Crias</a>
        </li>
        <li *ngIf="env.contexto === 'jersey'" class="nav-item" role="presentation">
          <a class="nav-link" [ngClass]="{'active': tabSelecionada === tabs.ControleLeiteiro}" (click)="setTab(tabs.ControleLeiteiro)" id="controle-leiteiro-bovino-tab" data-toggle="tab" href="#controle-leiteiro-bovino" role="tab" aria-controls="controle-leiteiro-bovino" aria-selected="false">Controle {{ env.contexto === 'jersey'? 'Leiteiro' : 'de Carcaça' }}</a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link" [ngClass]="{'active': tabSelecionada === tabs.Classificacao}" (click)="setTab(tabs.Classificacao)" id="classificacao-bovino-tab" data-toggle="tab" href="#classificacao-bovino" role="tab" aria-controls="classificacao-bovino" aria-selected="false">Classificação</a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link" [ngClass]="{'active': tabSelecionada === tabs.Premiacao}" (click)="setTab(tabs.Premiacao)" id="premiacao-bovino-tab" data-toggle="tab" href="#premiacao-bovino" role="tab" aria-controls="premiacao-bovino" aria-selected="false">Premiação</a>
        </li>
      </ul>
      <!-- Conteúdo das Abas -->
      <div class="tab-content border">
        <div class="tab-pane fade" [ngClass]="{'show active': tabSelecionada === tabs.DadosBovino}" id="dados-bovino" role="tabpanel" aria-labelledby="dados-bovino-tab">
          <app-dados-bovino *ngIf="tabSelecionada === tabs.DadosBovino"></app-dados-bovino>
        </div>
        <div class="tab-pane fade" [ngClass]="{'show active': tabSelecionada === tabs.Genealogia}" id="genealogia-bovino" role="tabpanel" aria-labelledby="genealogia-bovino-tab">
          <app-genealogia-bovino *ngIf="tabSelecionada === tabs.Genealogia" [bovinoId]="bovinoId"></app-genealogia-bovino>
        </div>
        <div class="tab-pane fade" [ngClass]="{'show active': tabSelecionada === tabs.Eventos}" id="eventos-bovino" role="tabpanel" aria-labelledby="eventos-bovino-tab">
          <app-eventos-bovino *ngIf="tabSelecionada === tabs.Eventos" [bovinoId]="bovinoId"></app-eventos-bovino>
        </div>
        <div class="tab-pane fade" [ngClass]="{'show active': tabSelecionada === tabs.Documentos}" id="documentos-bovino" role="tabpanel" aria-labelledby="documentos-bovino-tab">
          <app-documentos-bovino *ngIf="tabSelecionada === tabs.Documentos" [bovinoId]="bovinoId"></app-documentos-bovino>
        </div>
        <div class="tab-pane fade" [ngClass]="{'show active': tabSelecionada === tabs.Crias}" id="crias-bovino" role="tabpanel" aria-labelledby="crias-bovino-tab">
          <app-crias-bovino *ngIf="tabSelecionada === tabs.Crias" [bovinoId]="bovinoId"></app-crias-bovino>
        </div>
        <div *ngIf="env.contexto === 'jersey'" class="tab-pane fade" [ngClass]="{'show active': tabSelecionada === tabs.ControleLeiteiro}" id="controle-leiteiro-bovino" role="tabpanel" aria-labelledby="controle-leiteiro-bovino-tab">
          <app-controle-leiteiro-bovino *ngIf="tabSelecionada === tabs.ControleLeiteiro" [bovinoId]="bovinoId"></app-controle-leiteiro-bovino>
        </div>
        <div class="tab-pane fade" [ngClass]="{'show active': tabSelecionada === tabs.Classificacao}" id="classificacao-bovino" role="tabpanel" aria-labelledby="classificacao-bovino-tab">
          <app-classificacao-bovino *ngIf="tabSelecionada === tabs.Classificacao" [bovinoId]="bovinoId"></app-classificacao-bovino>
        </div>
        <div class="tab-pane fade" [ngClass]="{'show active': tabSelecionada === tabs.Premiacao}" id="premiacao-bovino" role="tabpanel" aria-labelledby="premiacao-bovino-tab">
          <app-premiacao-bovino *ngIf="tabSelecionada === tabs.Premiacao" [bovinoId]="bovinoId"></app-premiacao-bovino>
        </div>
      </div>
  </div>
</div>