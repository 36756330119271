<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <i class="bi bi-x" color="primary"></i>
</button>

<h2 mat-dialog-title>Ocorrências por Situação</h2>

<mat-divider></mat-divider>

<div class="row" style="padding-top: 10px; color: #6c757d;">
  <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
    <label>
      Evento:  
      <span *ngIf="commons.getNomeIcone(formConsulta.EventoInt).tipo === 'material'" class="inline-icon material-icons">{{commons.getNomeIcone(formConsulta.EventoInt).icone}}</span>
      <mat-icon *ngIf="commons.getNomeIcone(formConsulta.EventoInt).tipo === 'svg'" svgIcon="{{commons.getNomeIcone(formConsulta.EventoInt).icone}}"></mat-icon>
      {{ commons.getNomeIcone(formConsulta.EventoInt).nome }}
    </label>
  </div>

  <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
    Situação: {{ commons.getDescricaoSituacao(formConsulta.SituacaoInt) }}
  </div>
</div>

<mat-divider style="margin-bottom: 30px;"></mat-divider>

<!-- <div class="row justify-content-end">    
  <div class="form-group col-lg-2 col-md-2 col-sm-12">
    <label for="consulta">&nbsp;</label>
    <button type="button" class="btn btn-sm btn-primary form-control form-control-sm" name="consulta" (click)="botaoBuscar()">Buscar</button>
  </div>
</div> -->

<div class="row" *ngIf="itens.length > 0">
  <div class="col-lg-12 table-responsive">
    <table class="table table-hover table-header-custom">
      <thead>
        <tr>
          <th>Código</th>
          <th>Nome</th>
          <th class="text-center">Quantidade</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of itens | paginate : { itemsPerPage: pageSize, currentPage: page, totalItems: count };">
          <td>{{ item.CodigoCriador }}</td>
          <td>{{ item.NomeCriador }}</td>
          <td class="text-center td-redirecionar" (click)="redirecionar(item.IdCriador, item.CodigoCriador, item.NomeCriador)">{{ item.Total }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="col-md-12 text-center">
    <pagination-controls 
      class="srg-paginacao" 
      previousLabel="Anterior" 
      nextLabel="Próximo" 
      [responsive]="true"
      (pageChange)="handlePageChange($event)" 
      [autoHide]="true"
    >
    </pagination-controls>
  </div>
</div>