class FileModel {
	nome: string;
	tamanho: number;
	itens: any[] | undefined;

	constructor() {
		this.nome = "";
		this.itens = [];
		this.tamanho = 0;
    }
}

export class ControleLeiteiroModel {
	descricao: string;
	arquivo: FileModel;
	dataImportacao: string;

	constructor() {
		this.descricao = "";
		this.dataImportacao = "";
		this.arquivo = new FileModel();
    }
}
