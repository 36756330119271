<div class="row" style="margin-bottom: 10px;">
  <div class="col-md-6 text-left">

    <button *ngIf="permiteAprovar()" class="btn btn-primary btn-sm" type="button" matTooltip="Aprovar" (click)="aprovar()" >
      <i class="fa fa-check"></i>&nbsp;Aprovar
    </button>

    <button *ngIf="permiteAprovarTodos()" class="btn btn-primary btn-sm" type="button" matTooltip="Aprovar" (click)="aprovarTodos()" >
      <i class="fa fa-check"></i>&nbsp;Aprovar Todos
    </button>

    <!-- <button *ngIf="permiteAprovarComPendencias()" class="btn btn-primary btn-sm" type="button" matTooltip="Aprovar com Pendências" (click)="aprovarComPendencias()">
      <i class="fa fa-check-circle"></i>&nbsp;Aprovar com Pendências
    </button> -->
    
    <!-- <button *ngIf="permiteRejeitar()"  class="btn btn-primary btn-sm" type="button" matTooltip="Rejeitar" (click)="rejeitar()">
    <i class="fa fa-times"></i>&nbsp;Rejeitar
    </button> -->

    <button *ngIf="permiteRevisar()"  class="btn btn-primary btn-sm" type="button" matTooltip="Revisar" (click)="revisar()">
      <i class="fa fa-times"></i>&nbsp;Revisar
      </button>
    
    <button *ngIf="permiteReverterRejeicao()"  class="btn btn-primary btn-sm" type="button" matTooltip="Reverter Rejeição" (click)="reverterRejeicao()">
      <i class="fas fa-exchange-alt"></i>&nbsp;Reverter Rejeição
    </button>

    <button *ngIf="permiteNovo()"  class="btn btn-primary btn-sm" type="button" matTooltip="Novo" (click)="novo()">
      <i class="fa fa-plus"></i>&nbsp;Novo
    </button>

    <button *ngIf="permiteSalvar()" class="btn btn-primary btn-sm" type="button" matTooltip="Adicionar e salvar o registro atual em edição." (click)="salvar()">
      <i class="fa fa-download"></i>&nbsp;Salvar
    </button>

  </div>
  <div class="col-md-6 text-right">

    <button *ngIf="permiteGerarPdfInspecao()" class="btn btn-primary btn-sm" type="button" matTooltip="Gerar Relatório de Inspeção" (click)="gerarPDF()">
      <i class="fas fa-file-pdf"></i>&nbsp;Gerar PDF
    </button>
    
    <button *ngIf="permiteGerarExcelInspecao()" class="btn btn-primary btn-sm" type="button" matTooltip="Gerar Relatório de Inspeção" (click)="gerarExcel()">
      <i class="fas fa-file-excel"></i>&nbsp;Gerar Excel
    </button>

    <button *ngIf="permiteSubmeter()" class="btn btn-primary btn-sm" type="button" matTooltip="Enviar Formulário à Associação" (click)="submeter()">
      <i class="fa fa-envelope"></i>&nbsp;Submeter
    </button>
    
    <button *ngIf="permiteSubmeterEAprovar()" class="btn btn-primary btn-sm" type="button" matTooltip="Enviar Formulário à Associação" (click)="submeterEAprovar()">
      <i class="fa fa-envelope"></i>&nbsp;Submeter
    </button>

    <button *ngIf="permiteDescartar()" class="btn btn-primary btn-sm" type="button" matTooltip="Descartar Formulário" (click)="descartar()">
      <i class="fas fa-trash-alt"></i>&nbsp;Descartar
    </button>

    <button *ngIf="permiteAprovarTodas()" class="btn btn-primary btn-sm" type="button" matTooltip="Aprovar Todos" (click)="aprovarTodas()">
      <i class="fa fa-check"></i>&nbsp;Aprovar Todas
    </button>

  </div>
</div>
