export class PerfilUsuarioFazendaModel{
  Nome: string;
  IdGrupo: string;
  GrupoAdministrador: string;
  Id: string;

  constructor(){
    this.Id = "";
    this.Nome = "";
    this.IdGrupo = "";
    this.GrupoAdministrador = "";
  }
}
