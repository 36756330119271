import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FiltroConsultaRegistrosReservadosModels } from 'src/app/models/FiltroConsultaRegistrosReservadosModels';
import { ApiService } from 'src/app/shared/api.service';
import { TipoRegistroReservado } from 'src/app/shared/enums/Enums';
import { IItemRegistroReservadoIntervalo, IResponseConsultaRegistrosReservadosFiltro, ItemRegistroReservado } from 'src/app/shared/interfaces/Interfaces';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { BovinoService } from '../../shared/bovino.service';

@Component({
  selector: 'app-registros-reservados-consultar',
  templateUrl: './registros-reservados-consultar.component.html',
  styleUrls: ['./registros-reservados-consultar.component.scss']
})
export class RegistrosReservadosConsultarComponent implements OnInit {

  readonly env = environment;

  TipoRegistro = TipoRegistroReservado;

  totais: ItemRegistroReservado = <ItemRegistroReservado>{};
  registros: IItemRegistroReservadoIntervalo[] = [];

  tiposRegistros: { label: string, value: number }[] = [];
  formularioConsulta: FiltroConsultaRegistrosReservadosModels = new FiltroConsultaRegistrosReservadosModels();

  constructor(
    private api: ApiService,
    private router: Router,
    public bovinoService: BovinoService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.tiposRegistros = this.bovinoService.getTiposRegistrosReservados('Todos');

    const id = this.route.snapshot.queryParamMap.get('filiada');
    this.formularioConsulta.filiadaId = id || '';
  
    this.botaoConsultar();
  }

  botaoConsultar() {
    this.api.consultarRegistrosReservadosComFiltro(this.formularioConsulta).then((result: IResponseConsultaRegistrosReservadosFiltro) => {
      if (result && result.success) {
        this.totais = result.Totais;
        this.registros = result.Itens;
      } else {
        Swal.fire(result.message || 'Não foi possível recuperar as informações.', '', 'error');
        this.router.navigate(['registros_reservados']);
      }
    });
  }

  filiadaSelecionada(idFiliada: string) {
    this.formularioConsulta.filiadaId = idFiliada;
  }

}
