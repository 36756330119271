<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <i class="bi bi-x" color="primary"></i>
</button>

<h2 mat-dialog-title>Consultar Procurador</h2>

<div class="row">

  <div class="form-group col-lg-3 col-md-3 col-sm-12">
    <label for="codigoProcurador">Código</label>
    <input type="text" class="form-control form-control-sm" id="codigoProcurador" name="codigoProcurador" autocomplete="off" [(ngModel)]="formConsulta.Codigo" (keydown.enter)="botaoBuscar()">
  </div>

  <div class="form-group col-lg-6 col-md-6 col-sm-12">
    <label for="nomeProcurador">Nome</label>
    <input appAutoFocus type="text" class="form-control form-control-sm" name="nomeProcurador" autocomplete="off" [(ngModel)]="formConsulta.NomeProcurador" (keydown.enter)="botaoBuscar()">
  </div>

  <div class="form-group col-lg-3 col-md-3 col-sm-12">
    <label for="consulta">&nbsp;</label>
    <button type="button" class="btn btn-sm btn-primary form-control form-control-sm" name="consulta" (click)="botaoBuscar()">Buscar</button>
  </div>

</div>

<div class="row" *ngIf="procuradores.length > 0">
  <div class="col-lg-12">
    <table class="table table-hover table-header-custom">
      <thead>
        <tr>
          <th>Código</th>
          <th>Nome</th> 
          <th class="text-center">Selecionar</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let procurador of procuradores" style="cursor: pointer;" (click)="selecionarProcurador(procurador)" >
          <td>{{ procurador.Codigo }}</td>
          <td>{{ procurador.NomeProcurador }}</td>
          <td class="text-center"><a><i class="bi bi-check icone-selecao"></i></a></td>
        </tr>
      </tbody>
    </table>
  </div>
  
  
  <div class="col-md-12 text-center">
    <pagination-controls 
      class="srg-paginacao" 
      previousLabel="Anterior" 
      nextLabel="Próximo" 
      [responsive]="true"
      (pageChange)="handlePageChange($event)" 
      [autoHide]="true"
    >
    </pagination-controls>
  </div>
</div>


 