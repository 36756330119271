import { Component, Input, OnInit } from '@angular/core';
import { IdentificacaoCriadorModel } from 'src/app/models/IdentificacaoCriadorModel';
import { CriadorModel } from 'src/app/models/CriadorModel';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { ApiService } from '../api.service';
import { AfixoPrincipal, PosicaoAfixo } from '../enums/Enums';
import { AccountService } from 'src/app/account/shared/account.service';
import { E } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-afixo-manter',
  templateUrl: './afixo-manter.component.html',
  styleUrls: ['./afixo-manter.component.scss']
})
export class AfixoManterComponent implements OnInit {

  readonly env = environment;
  afixoSelecionado: string = '';

  afixoPrincipal: AfixoPrincipal = AfixoPrincipal.Nao;

  contador: number = 0;

  @Input() readOnly: boolean = false;
  @Input() criador: CriadorModel = new CriadorModel();

  constructor(
    private api: ApiService,
    public accountService: AccountService,
  ) { }

  ngOnInit(): void {
    if (this.accountService.isCriador) {
      let token: any = this.accountService.getPayloadToken();

      if (token) {
        this.api.recuperarCriadorPorId(token.UserId).then((result: any) => {
          this.criador.afixos = result.AfixosCriador;
          this.criador.afixo = result.Afixo;
          this.criador.codigo = result.Codigo;
          this.criador.afixos.forEach((element: any) => {
            if (element.principal == true) {
              this.afixoSelecionado = element.Id;
            }
          });
          // var afixoPrincipal = this.criador.afixos.find(afixo => afixo.principal == true);
          // if (afixoPrincipal) {
          //   this.afixoSelecionado = afixoPrincipal.Id;
          // }
        });
      }
    }
    else {
      var afixoPrincipal = this.criador.afixos.find(afixo => afixo.principal == true);
      if (afixoPrincipal) {
        this.afixoSelecionado = afixoPrincipal.Id;
      }
    }
  }

  botaoValidarAfixo(): void {
    if (this.criador.afixo === '') return;

    this.api.verificarAfixoDisponivel(this.criador.afixo).then((result: any) => {
      if (result) {
        if (result.success) {
          this.criador.afixo = '';
          Swal.fire(`O afixo não está disponível para uso e pertence ao criador ${result.message}.`, '', 'warning');
        } else {
          if (result.message) {
            Swal.fire(result.message, '', 'error');
          } else {
            // Swal.fire('Afixo disponível para uso.', '', 'success');
            this.adicionarAfixo();
          }
        }
      }
    });
  }

  getLabelAfixo(afixo: IdentificacaoCriadorModel): string {
    return `${afixo.codigo} - ${PosicaoAfixo[afixo.posicaoAfixo]}`
  }

  removerAfixo(afixo: IdentificacaoCriadorModel): void {
    const index = this.criador.afixos.indexOf(afixo);
    if (index >= 0) this.criador.afixos.splice(index, 1);
  }

  private adicionarAfixo(): void {
    if (!this.criador.afixo || this.criador.afixo === '') return;

    // if (this.criador.idAfixo !== "-1") {
    //   const index = this.criador.afixos.findIndex(item => item.Id === this.criador.idAfixo);
    //   if (index !== -1) {
    //     Object.assign(this.criador.afixos[index], {
    //       codigo: this.criador.afixo,
    //       principal: this.afixoPrincipal,
    //       posicaoAfixo: this.criador.posicaoAfixo,
    //     });
    //   } else {
    //     this.criador.afixo = '';
    //     this.criador.posicaoAfixo = PosicaoAfixo.Sufixo;
    //   }
    //   return;
    // }
    let mensagem = '';
    const afixo_existente = this.criador.afixos?.filter(item => {
      if (item.principal && (this.afixoPrincipal == AfixoPrincipal.Sim)) {
        mensagem = 'O criador já possui um afixo principal cadastrado.'
        return item;
      }

      if (item.posicaoAfixo === this.criador.posicaoAfixo && item.codigo === this.criador.afixo) {
        mensagem = 'O afixo informado já foi adicionado para o criador.'
        return item;
      }
      return null;
    });
    if (afixo_existente.length !== 0) {
      this.criador.afixo = '';
      this.afixoPrincipal = AfixoPrincipal.Nao;
      this.criador.posicaoAfixo = PosicaoAfixo.Sufixo;
      Swal.fire(mensagem, '', 'warning');
      return;
    }

    this.criador.afixos.push(new IdentificacaoCriadorModel({
      codigo: this.criador.afixo,
      posicaoAfixo: this.criador.posicaoAfixo,
      principal: this.afixoPrincipal == AfixoPrincipal.Sim,
    }));
    this.criador.afixo = '';
  }

  alterarAfixoPrincipal(codigoCriador: string, idAfixo: string) {
    this.api.atualizarAfixoPrincipal(codigoCriador, idAfixo).then((result: any) => {
      if (result.Status == 0) {
        if (this.accountService.isAdmin || this.accountService.isSuperintendente) {
          Swal.fire({
            html: result.Mensagem,
            icon: 'success',
          });
        } else if (this.accountService.isCriador) {
          Swal.fire({
            html: result.Mensagem + '&nbspPara que as alterações sejam salvas, você será redirecionado para a página de login.',
            icon: 'success',
          }).then(() => {
            if (this.accountService.isCriador) {
              this.accountService.logoff();
            }
          });
        }

      } else {
        Swal.fire(result.Mensagem, '', 'error');
      }
    })
  }

  permiteAdicionarAfixo() {
    if (this.accountService.isAdmin || this.accountService.isSuperintendente) {
      return true;
    } else {
      return false;
    }
  }

  avisoAfixo() {
    if (this.contador == 0) {
      Swal.fire({
        html: 'ATENÇÃO! <br> Ao clicar em outro afixo ele será selecionado como <strong>afixo principal</strong>, e você será redirecionado para a tela de <strong>login</strong>!',
        icon: 'warning',
      }).then(() => this.contador++);
    }
  }
}
