import { AccountService } from './../../account/shared/account.service';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-consultar-criador-modal',
  templateUrl: './consultar-criador-modal.component.html',
  styleUrls: ['./consultar-criador-modal.component.scss']
})
export class ConsultarCriadorModalComponent implements OnInit {

  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 50, 100];
  criadores: any[] = [];

  tituloConsulta: string = "";
  apenasFrigorifico?: boolean;
  @Output() criadorSelecionado = new EventEmitter<any>();

  formConsulta = {
    codigo: "",
    nome: "",
    associacao: "",
    filiadaReadonly: true
  }

  constructor(
    private api: ApiService,
    private accountService: AccountService,
    public dialogRef: MatDialogRef<ConsultarCriadorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.tituloConsulta = this.data.tituloConsulta;
    this.apenasFrigorifico = this.data.apenasFrigorifico;

    let token: any = this.accountService.getPayloadToken();
    if(token){
      this.formConsulta.associacao = token.DomainId;
    }

    this.formConsulta.filiadaReadonly = !this.accountService.isSuperAdmin;
  }

  botaoBuscar(){
    this.page = 1;
    this.consultar();
  }

  consultar(){
    this.api.consultarCriador(
      this.formConsulta.codigo,
      this.formConsulta.nome,
      1, //Somente Ativos 
      this.page,
      10,
      this.formConsulta.associacao === "",
      this.formConsulta.associacao,
      this.apenasFrigorifico,
    ).then((result: any) => {
      if(result){
        if(result.Status === 0){
          this.criadores = result.Criadores;
          this.count = result.TotalItens;
        }else{
          this.criadores = [];
          this.count = 0;
          Swal.fire(result.Mensagem, '', 'error');
        }
      }
    });
  }

  filiadaSelecionada(idFiliada: string){
    this.formConsulta.associacao = idFiliada;
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.consultar();
  }

  selecionarCriador(criador: any) {
    this.criadorSelecionado.emit(criador);
    this.dialogRef.close();
  }

  fechar() {
    this.dialogRef.close();
  }

}
