import { AnimalCadastrado, Sexo, TipoBuscaBovino, TransferenciaMotivo } from "../shared/enums/Enums";
import { BovinoModel } from "./BovinoModel";
import { CriadorModel } from "./CriadorModel";
import { OcorrenciaEventoInovulacaoModel } from "./OcorrenciaEventoInovulacaoModel";
import { OcorrenciaEventoModel } from "./OcorrenciaEventoModel";
import { OcorrenciaEventoPadreacaoModel } from "./OcorrenciaEventoPadreacaoModel";

export class OcorrenciaEventoTransferenciaModel extends OcorrenciaEventoModel {

  Comprador: CriadorModel;
  AnimalCadastrado: AnimalCadastrado;
  Animal: BovinoModel;
  CodigoAnimalNaoCadastrado: number | string;
  NomeAnimalNaoCadastrado: string;
  DataVenda: string;
  Prenha: number;
  TipoTransferenciaAnimal: number;
  TransferenciaMotivo: TransferenciaMotivo;

  OcorrenciaEventoInovulacao: OcorrenciaEventoInovulacaoModel;
  OcorrenciaEventoPadreacao: OcorrenciaEventoPadreacaoModel;

  DataLimite: string;
  SituacaoAposDataLimite: number;

  SugestaoSelecionada: boolean;
  HasReprodutor: boolean;

  constructor() {
    super();

    this.OcorrenciaEventoInovulacao = new OcorrenciaEventoInovulacaoModel();
    this.OcorrenciaEventoPadreacao = new OcorrenciaEventoPadreacaoModel();

    this.Comprador = new CriadorModel();
    this.Comprador.tituloConsulta = 'Comprador';
    this.AnimalCadastrado = AnimalCadastrado.Sim;
    this.Animal = new BovinoModel();
    this.DataVenda = "";
    this.Prenha = 2;
    this.TipoTransferenciaAnimal = 2;
    this.CodigoAnimalNaoCadastrado = '';
    this.TransferenciaMotivo = TransferenciaMotivo.None;
    this.NomeAnimalNaoCadastrado = '';

    this.DataLimite = "";
    this.SituacaoAposDataLimite = 0;

    this.Animal.TituloConsulta = "Animal";
    this.Animal.TipoBusca = TipoBuscaBovino.Todos;
    this.Animal.SexoBovino = Sexo.Todos;
    this.Animal.ConsideraInativos = false;
    this.Animal.ConsultaAnimalTodosCriadores = true;
    this.SugestaoSelecionada = false;
    this.HasReprodutor = true;


  }
}