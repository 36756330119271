<div class="card card-body" style="margin-top: 1em;">

  <div class="row">

    <div class="form-group col-lg-2 col-md-6 col-sm-6 col-xs-12">
      <label>Código</label>
      <input disabled name="codigo" type="text" class="form-control form-control-sm" [(ngModel)]="bovino.CodigoBovino">
    </div>

    <div class="form-group col-lg-5 col-md-6 col-sm-6 col-xs-12">
      <label>*Nome</label>
      <input name="nome" type="text" class="form-control form-control-sm" [(ngModel)]="bovino.NomeBovino">
    </div>

    <div class="form-group col-lg-2 col-md-6 col-sm-6 col-xs-12">
      <label>{{ env.contexto === 'jersey'? '*Orelha Direita' : 'Orelha Esquerda' }}</label>
      <input name="orelha_direita" type="text" class="form-control form-control-sm" [(ngModel)]="bovino.OrelhaDireita">
    </div>

    <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
      <label for="formularioProtocolo">Situação SRG</label>
      <select class="form-control form-control-sm" name="UFs" [(ngModel)]="bovino.SituacaoSRGBovino" [disabled]="isCriador()">
        <option *ngFor="let situacao of situacoesSRG" [value]="situacao.value" [label]="situacao.label"></option>
      </select>
    </div>

    <div class="form-group col-lg-2 col-md-6 col-sm-6 col-xs-12">
      <label>{{ env.contexto === 'jersey'? '*' : '' }}Data nascimento</label>
      <div class="input-group input-group-sm">
        <input class="form-control" name="data_nascimento" [matDatepicker]="dataNascimento" [(ngModel)]="bovino.DataNascimentoBovino"> 
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" type="button" (click)="dataNascimento.open()"> <i class="bi bi-calendar3"></i> </button>
        </div>
      </div>
      <mat-datepicker [startAt]="bovino.DataNascimentoBovino" #dataNascimento></mat-datepicker>
    </div>

    <div class="form-group col-lg-2 col-md-6 col-sm-6 col-xs-12">
      <label for="formularioProtocolo">*Sexo</label>
      <select class="form-control form-control-sm" name="sexo" [(ngModel)]="bovino.SexoBovino">
        <option value="-1" label="Selecione..."></option>
        <option value="1" label="Macho"></option>
        <option value="2" label="Fêmea"></option>
      </select>
    </div>
    
    <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
      <label for="formularioProtocolo">*Raça</label>
      <select class="form-control form-control-sm" name="raca" [(ngModel)]="bovino.RacaBovino" (ngModelChange)="atualizarTiposPorRaca($event)">
        <option *ngFor="let raca of racas" [value]="raca.value" [label]="raca.label"></option>
      </select>
    </div>

    <div class="form-group col-lg-2 col-md-6 col-sm-6 col-xs-12">
      <label for="formularioProtocolo">*Tipo</label>
      <select class="form-control form-control-sm" name="tipo" [(ngModel)]="bovino.SangueBovino.tipo" (ngModelChange)="atualizarGrauSanguePorTipo($event)" [disabled]="isAdmin === false">
        <option *ngFor="let tipo of tipoSanguineo" [value]="tipo.value" [label]="tipo.label"></option>
      </select>
    </div>
    
    <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
      <label for="formularioProtocolo">*Grau Sangue</label>
      <select class="form-control form-control-sm" name="grau_sangue" [(ngModel)]="bovino.SangueBovino.grau" [disabled]="isAdmin === false">
        <option *ngFor="let gs of grauSangue" [value]="gs.value" [label]="gs.label"></option>
      </select>
    </div>

    <div class="form-group col-lg-2 col-md-6 col-sm-6 col-xs-12">
      <label for="formularioProtocolo">*Animal de Central</label>
      <select class="form-control form-control-sm" name="animal_central" [(ngModel)]="bovino.AnimalDeCentral">
        <option value="0" label="Não"></option>
        <option value="1" label="Sim"></option>
      </select>
    </div>

    <div class="form-group col-lg-2 col-md-6 col-sm-6 col-xs-12">
      <label for="formularioProtocolo">Nacionalizado</label>
      <select class="form-control form-control-sm" name="nacionalizado" [(ngModel)]="bovino.Nacionalizado">
        <option value="0" label="Não"></option>
        <option value="1" label="Sim"></option>
      </select>
    </div>

    <div *ngIf="bovino.Nacionalizado == 0 && env.contexto === 'jersey'" class="form-group col-lg-5 col-md-6 col-sm-6 col-xs-12">
      <label for="formularioProtocolo">*Origem</label>
      <select class="form-control form-control-sm" name="origem" [(ngModel)]="bovino.OrigemBovino">
        <option *ngFor="let orig of origem" [value]="orig.value" [label]="orig.label"></option>
      </select>
    </div>
    <div *ngIf="bovino.Nacionalizado == 1" class="form-group col-lg-2 col-md-6 col-sm-6 col-xs-12">
      <label>Código central</label>
      <input name="orelha_direita" type="text" class="form-control form-control-sm" [(ngModel)]="bovino.CodigoCentral">
    </div>

    <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
      <label for="formularioProtocolo">Situação</label>
      <select class="form-control form-control-sm" name="situacao" [(ngModel)]="bovino.SituacaoBovino">
        <option value="1" label="Ativo"></option>
        <option value="2" label="Inativo"></option>
      </select>
    </div>

  </div>


  <div class="row">
    <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
      <app-consultar-bovino [bovino]="bovinoPai" [permite]="false"></app-consultar-bovino>
    </div>

    <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
      <app-consultar-bovino [bovino]="bovinoMae" [permite]="false"></app-consultar-bovino>
    </div>
  </div>


  <div class="row">
    <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
      <app-consultar-criador [criador]="criador" [recuperarListaAfixos]="true" (criadorSelecionado)="criadorSelecionado($event)"></app-consultar-criador>
    </div>

    <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
      <label>Afixo</label>
      <input 
        disabled 
        type="text" 
        name="criador_afixo" 
        [ngModel]="criador.afixo"
        *ngIf="env.contexto === 'jersey'"
        class="form-control form-control-sm" 
      >
      <select *ngIf="env.contexto === 'wagyu'" [disabled]="criador.afixos.length < 2" class="form-control form-control-sm" name="criador_afixo" [(ngModel)]="this.criador.idAfixo" (change)="alterarAfixoSelecionado()">
        <option *ngFor="let identificacao of criador.afixos" [value]="identificacao.Id" [label]="getLabelIdentificacao(identificacao)"></option>
      </select>
    </div>
  </div>


  <div class="row">
    <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
      <app-consultar-criador [criador]="proprietario"></app-consultar-criador>
    </div>

    <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
      <label>Afixo Proprietário</label>
      <input 
        disabled 
        type="text" 
        name="proprietario_afixo" 
        [ngModel]="proprietario.afixo"
        class="form-control form-control-sm" 
      >
    </div>
  </div>


  <div class="row justify-content-end" id="botao-container">
    <div class="col-lg-4 col-md-10 col-sm-10">
      <button type="button" class="btn btn-sm btn-block btn-primary" (click)="botaoSalvar()">Salvar</button>
    </div>
  </div>

</div>