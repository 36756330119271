<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <i class="bi bi-x" color="primary"></i>
</button>

<h2 mat-dialog-title>Buscar {{ env.contexto === 'jersey'? 'Padreação / Inovulação' : 'Cobertura / Implantação' }} </h2>

<div class="row">
  <div class="form-group col-lg-2 col-md-4 col-sm-6 col-xs-12">
    <label for="formulario">Código</label>
    <!--#187 Os campos de busca SEMPRE devem estar abertos-->
    <input
      type="text"
      class="form-control form-control-sm"
      name="codigo_animal_nao_cadastrado"
      [(ngModel)]="formConsulta.Mae.CodigoBovino"
    />
  </div>

  <div class="form-group col-lg-4 col-md-8 col-sm-6 col-xs-12">
    <label for="protocolo">Nome</label>
    <input
      type="text"
      class="form-control form-control-sm"
      name="nome_animal_nao_cadastrado"
      [(ngModel)]="formConsulta.Mae.NomeBovino"
    />
  </div>
  <!-- #254 travar os campos de data na sugestão de Cobertura -->
  <div class="form-group col-lg-2 col-md-6 col-sm-6 col-xs-12">
    <label>De</label>
    <div class="input-group input-group-sm">
      <input class="form-control" name="sugestao_de" [matDatepicker]="sugestaoDe" [(ngModel)]="formConsulta.SugestaoDe" [disabled]="true"> 
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" type="button" (click)="sugestaoDe.open()"> <i class="bi bi-calendar3"></i> </button>
      </div>
    </div>
    <mat-datepicker [startAt]="formConsulta.SugestaoDe" #sugestaoDe></mat-datepicker>
  </div>

  <div class="form-group col-lg-2 col-md-6 col-sm-6 col-xs-12">
    <label>Até</label>
    <div class="input-group input-group-sm">
      <input class="form-control" name="sugestao_ate" [matDatepicker]="sugestaoAte" [(ngModel)]="formConsulta.SugestaoAte" [disabled]="true"> 
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" type="button" (click)="sugestaoAte.open()"> <i class="bi bi-calendar3"></i> </button>
      </div>
    </div>
    <mat-datepicker [startAt]="formConsulta.SugestaoAte" #sugestaoAte></mat-datepicker>
  </div>

  <div class="form-group col-lg-2 col-md-12 col-sm-12">
    <label for="consulta">&nbsp;</label>
    <button type="button" class="btn btn-sm btn-primary form-control form-control-sm" name="consulta" (click)="botaoBuscar()">Buscar</button>
  </div>
</div>

<div class="row" *ngIf="sugestoes.length > 0">
  <div class="col-lg-12 table-responsive">
    <table class="table table-hover table-header-custom">
      <thead>
        <tr>
          <th>Evento</th>
          <th>Data</th>
          <th>Matriz / Receptora</th>
          <th>Reprodutor</th>
          <th>Tipo</th>
          <th class="text-center">Selecionar</th>
        </tr>
      </thead>
      <tbody>
        <tr 
          style="cursor: pointer;" 
          (click)="selecionarSugestao(sugestao)"
          *ngFor="let sugestao of sugestoes | paginate : { itemsPerPage: pageSize, currentPage: page, totalItems: count };"
        >
          <td>{{ commons.getDescricaoEvento(sugestao.TipoEventoSRG) }}</td>
          <td>{{ sugestao.Data | date: 'dd/MM/yyyy' }}</td>
          <td>{{ sugestao.Matriz.CodigoNomeBovino  }}{{ sugestao.TipoEventoSRG === 53? ' / ' + (sugestao.ReceptoraCadastrada ? sugestao.Receptora.CodigoNomeBovino : sugestao.CodigoNomeReceptoraNaoCadastrada) : '' }}</td>
          <td>{{ sugestao.Reprodutor.CodigoNomeBovino || sugestao.Touros }}</td>
          <td>{{ descricaoTipo(sugestao.TipoEventoSRG, sugestao.TipoPadreacao, sugestao.TipoFiv) }}</td>
          <td class="text-center">
            <a><i class="bi bi-check icone-selecao"></i></a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="col-md-12 text-center">
    <pagination-controls 
      class="srg-paginacao" 
      previousLabel="Anterior" 
      nextLabel="Próximo" 
      [responsive]="true"
      (pageChange)="handlePageChange($event)" 
      [autoHide]="true"
    >
    </pagination-controls>
  </div>
</div>