<div class="row">
    
  <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12" >
    <label>CEP</label>
    <div class="input-group input-group-sm">
      <input 
        name="cep" 
        type="text"
        mask="00000-000" 
        [disabled]="readOnly"
        class="form-control form-control-sm" 
        [(ngModel)]="endereco.cep"
      >
      <div class="input-group-append">
        <button [disabled]="readOnly" class="btn btn-outline-secondary" type="button" (click)="botaoConsultarCEP()">
          <i class="bi bi-search"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
    <label>Logradouro</label>
    <input [disabled]="readOnly" name="logradouro" type="text" class="form-control form-control-sm" [(ngModel)]="endereco.logradouro" >
  </div>

  <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
    <label>Nº</label>
    <input [disabled]="readOnly" name="numero" type="text" class="form-control form-control-sm" [(ngModel)]="endereco.numero" >
  </div>

  <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
    <label>Complemento</label>
    <input [disabled]="readOnly" name="complemento" type="text" class="form-control form-control-sm" [(ngModel)]="endereco.complemento" >
  </div>

  <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
    <label>Bairro</label>
    <input [disabled]="readOnly" name="bairro" type="text" class="form-control form-control-sm" [(ngModel)]="endereco.bairro" >
  </div>

  <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
    <label>Cidade</label>
    <input [disabled]="readOnly" name="cidade" type="text" class="form-control form-control-sm" [(ngModel)]="endereco.cidade" >
  </div>

  <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
    <label for="formularioProtocolo">UF</label>
    <select [disabled]="readOnly" class="form-control form-control-sm" name="UFs" [(ngModel)]="endereco.uf">
      <option *ngFor="let uf of UFs" [value]="uf.value" [label]="uf.label"></option>
    </select>
  </div>

</div>