import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FiltroConsultaCriadoresModel } from 'src/app/models/FiltroConsultaCriadoresModel';
import { ApiService } from 'src/app/shared/api.service';
import { environment } from './../../../environments/environment';
import { Commons } from 'src/app/shared/commons';
import Swal from 'sweetalert2';
import { AccountService } from 'src/app/account/shared/account.service';
import { RolesKeys } from 'src/app/account/shared/RolesKeys';
import { LocalStorageService } from 'src/app/shared/local-storage.service';
import { QueryStateService } from 'src/app/shared/query-state.service';

@Component({
  selector: 'app-criador-consultar',
  templateUrl: './criador-consultar.component.html',
  styleUrls: ['./criador-consultar.component.scss']
})
export class CriadorConsultarComponent implements OnInit {

  @Input() exibirFiltrosConsulta: boolean = true;

  formConsulta: FiltroConsultaCriadoresModel = new FiltroConsultaCriadoresModel();
  permiteNovo: boolean = true;
  page = 1;
  count = 0;
  pageSize = environment.pageSize;

  criadores: any[] = [];

  constructor(
    private api: ApiService,
    private router: Router,
    private commons: Commons,
    private accountService: AccountService,
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private queryState: QueryStateService
  ) { }

  ngOnInit(): void {
    this.permiteNovo = this.accountService.checkRole(RolesKeys.Criador);

  }

  botaoConsultar() {
    setTimeout(() => {
      //verifica se existe a paginação salva, se não, seta a 1
      if (this.localStorage.get("page")) {
        this.page = parseInt(this.localStorage.get("page"));
      } else {
        this.page = 1;
      }
      //salva o formulario de pesqusia no storage
      this.queryState.saveFormCriador(this.formConsulta);
      //obtem a rota da página atual
      this.queryState.getRota(this.route.snapshot.url[0].path);
      this.consultar();
    }, 100);
  }

  consultar() {
    this.queryState.saveFormCriador(this.formConsulta);
    //obtem a rota da página atual
    this.queryState.getRota(this.route.snapshot.url[0].path);
    this.api.consultarCriadores(this.formConsulta, this.page, this.pageSize).then((result: any) => {
      if (result) {
        if (result.success === true) {

          this.criadores = [];
          result.Itens.forEach((element: any) => {
            element.DescSituacao = this.commons.getDescricaoSituacaoCriador(element.Situacao);
            this.criadores.push(element);
          });
          this.count = result.TotalItens;
        } else {
          this.criadores = [];
          this.count = 0;
          Swal.fire(result.message, '', 'error');
        }
      }
    });
  }

  handlePageChange(event: number): void {
    this.page = event;
    //salva a ultima pagina da pesquisa
    this.queryState.paginacao(event);
    this.consultar();
  }

  novo() {
    this.router.navigate(['/criador_manter']);
  }

  async botaReiniciarSenha(idUsuario: string): Promise<void> {
    const response = await this.commons.modalConfirmacao({
      title: 'Confirma a reinicialização da senha do Usuário?'
    });

    if (!response) return;

    this.api.reiniciarSenha(idUsuario).then(result => {
      if (result) {
        if (result.success === true) {
          Swal.fire(result.message, "", "success");
        } else {
          Swal.fire(result.message, "", "error");
        }
      }
    });
  }

}
