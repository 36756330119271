export enum SituacaoOcorrenciaEvento {
  Todos = 0,
  Pendente = 2,
  Cancelado = 5,
  Aprovado = 50,
  AprovadoComPendencias = 51,
  Rejeitado = 52,
  AguardandoAprovacao = 53,
  AguardandoAprovacaoVeterinario = 58,
  RejeitadoPeloVeterinario = 59,
  AguardandoResolucao = 54,
  AprovadoSuperintendente = 55,
  RejeitadoSuperintendente = 56
}

export enum SituacaoLoteOcorrencia {
  Todos = 0,
  EmElaboracao = 1,
  Protocolado = 2,
  ProcessadoOk = 3,
  ProcessadoComPendencias = 4,
  Cancelado = 5,
  Rejeitado = 6
}

export enum ClasseClassificacaoLinear {
  "EX3" = 3,
  "EX2" = 2,
  "EX1" = 1,
  "MB" = 4,
  "B+" = 5,
  "B" = 6,
  "R" = 7,
  "P" = 8
}

export enum TipoTelefone {
  None = 0,
  Residencial = 1,
  Comercial = 2,
  Entrega = 3,
  Faturamento = 4,
  Coleta = 5,
  Contato = 6,
  Celular = 7,
  Fax = 8
}

export enum TipoBuscaBovino {
  Todos = 1,
  Macho = 2,
  Fêmea = 3,
  Matriz = 4,
  Reprodutor = 5
}

export enum EnumTipoEventoSRG {
  Todos = -1,
  Padreacao = 50,
  Nascimento = 51,
  FIV = 52,
  Inovulacao = 53,
  Baixa = 54,
  Transferencia = 55,
  Inspecao = 56,
  Premiacao = 57,
  Nacionalizacao = 58,
  NacionalizacaoEmbriao = 59,
  ClassificacaoLinear = 60,
  Pesagem = 61,
  Carcaca = 62
}

export enum Sexo {
  Todos = -1,
  Macho = 1,
  Fêmea = 2
}

export enum QualificaLaudoDNA {
  None = -1,
  Sim = 1,
  Nao = 2,
  Permanente = 3,
}

export enum TipoFiv {
  None = -1,
  FIV = 1,
  TE = 2,
}

export enum TipoPadreacao {
  InseminacaoArtifical = 1,
  MontaNatural = 2
}

export enum ImputaMulta {
  Sim = 1,
  Nao = 2
}

export enum AfixoPrincipal {
  Sim = 1,
  Nao = 2
}

export enum Suspenso {
  Sim = 1,
  Nao = 2
}

export enum Socio {
  Sim = 1,
  Nao = 2
}

export enum EvolucaoPCParaPO {
  Sim = 1,
  Nao = 0
}

export enum EstacaoDeMonta {
  Sim = 1,
  Nao = 2
}

export enum TipoMotivoBaixa {
  None = -1,
  ABATE = 1,
  MORTE = 2,
  VENDA = 3,
  SISTEMA = 4,
  ROUBO = 5,
  NATIMORTO = 6,
  DESCARTE = 50,
  ENVENENAMENTO = 51,
  MORTE_NATURAL = 52,
  PICADA_DE_COBRA = 53,
  BAIXA_POR_VENDA = 54,
  ABATE_FRIGORIFICO = 55,
}

export enum Frigorifico {
  Sim = 1,
  Nao = 2
}

export enum TipoServicoInspecaoFrigorifico {
  None = -1,
  SIF = 1,
  SISP = 2,
  SIM = 3,
  SISB = 4,
}

export enum SolicitarDna {
  Sim = 1,
  Nao = 2
}

export enum AnimalCadastrado {
  Sim = 1,
  Nao = 2
}

export enum UnidadesFederativas {
  None = -1,
  AC = 1,
  AL = 2,
  AP = 4,
  AM = 3,
  BA = 5,
  CE = 6,
  DF = 7,
  ES = 8,
  GO = 9,
  MA = 10,
  MT = 13,
  MS = 12,
  MG = 11,
  PA = 14,
  PB = 15,
  PR = 18,
  PE = 16,
  PI = 17,
  RJ = 19,
  RN = 20,
  RS = 22,
  RO = 23,
  RR = 21,
  SC = 25,
  SP = 27,
  SE = 26,
  TO = 24,
}

export enum SiglaPais {
  None = 0,
  CAN = 1,
  DEU = 2,
  GBR = 3,
  USA = 4,
  AUS = 5,
  URY = 6,
  ARG = 7,
}

export enum TabsPlantel {
  DadosBovino = 0,
  Genealogia = 1,
  Eventos = 2,
  Documentos = 3,
  Crias = 4,
  ControleLeiteiro = 5,
  Classificacao = 6,
  Premiacao = 7,
}

export enum PosicaoAfixo {
  None = -1,
  Prefixo = 1,
  Sufixo = 2
}

export enum TipoGrauSangue {
  PC = 2,
  PO = 3,
  PS = 5,
  CCG = 11,
  PA = 12,
  SRD = 13,
  ZT = 14,
  _12B = 15,
  _14B = 16,
  _38P = 17,
  _34B = 18,
  _38A = 19,
  _38B = 20,
  PC1 = 21,
  PC2 = 22,
  PC3 = 23,
  PC4 = 24,
  _16B = 25,
  _18B = 26,
  PA1 = 27,
  PA2 = 28,
  PA3 = 29,
  PA4 = 30,
  Default = -1
}

export enum TipoCertificado {
  Pendente = 1,
  Provisorio = 3,
  Definitivo = 4,
  Abate = 5,
}

export enum SituacaoControleLeiteiro {
  Todos = 0,
  Importado = 1,
  Aprovado = 2,
  Descartado = 3,
  "Em Processamento" = 4,
  "Erro no Processamento" = 5
}

export enum SituacaoItemControleLeiteiro {
  ImportacaoOk = 1,
  ImportacaoErro = 2,
  ProcessamentoOk = 3,
  ProcessamentoErro = 4
}

export enum SituacaoSRG {
  "Pendente de Documentação" = 1,
  "Pendente de Registro" = 2,
  "Registro Provisório Emitido" = 3,
  "Registro Definitivo Emitido" = 4,
  "Pendente de Emissão de Registro Provisório" = 5,
  "Pendente de Emissão de Registro Definitivo" = 6,
  "Registro Cancelado" = 7,
  "Abate" = 8
}

export enum SituacaoEntidade {
  Ativo = 1,
  Inativo = 2
}

export enum AnimalDeCentral {
  Sim = 1,
  Não = 0
}

export enum Nacionalizado {
  Sim = 1,
  Não = 0
}

export enum Origem {
  "Associação Brasileira Criadores Gado Pardo Suiço" = 29,
  "Associação Brasileira dos Criadores de Gir Leiteiro" = 28,
  "Associação Brasileira dos Criadores de Sindi" = 27,
  "Associação Jersey - NZL" = 26,
  "Associação Jersey - DEU" = 25,
  "Associação Jersey - GBR" = 24,
  "Associação Jersey - CAN" = 23,
  "Associação dos Criadores de Gado Jersey do Brasil" = 22,
  "Associação Jersey - USA" = 21,
  "Associação Nacional de Criadores Herd - Book Collares (Sueco Vermelho)" = 20,
  "Associação Brasileira de Criadores da Raça Piemontesa" = 19,
  "Associação Brasileira de Criadores de Bovinos da Raça Holandesa" = 17,
  "Associação Brasileira dos Criadores de Girolando" = 16,
  "CEN" = 15,
  "EI" = 14,
  "RGDI" = 13,
  "SISBOV" = 4,
  "Associação Jersey" = 3,
  "GTA" = 2,
  "FAZENDA" = 1,
  "Associação Brasileira de Angus" = 18,
}

export enum TipoItemNacionalizacao {
  None = -1,
  PAI = 1,
  MAE = 2,
  AVO1 = 3,
  AVO2 = 4,
  AVO3 = 5,
  AVO4 = 6,
  BISAVO1 = 7,
  BISAVO2 = 8,
  BISAVO3 = 9,
  BISAVO4 = 10,
  BISAVO5 = 11,
  BISAVO6 = 12,
  BISAVO7 = 13,
  BISAVO8 = 14
}

export enum TipoRegistroReservado {
  None = -1,
  POFemea = 1,
  POMacho = 2,
  PONacionalizado = 3,
  PC = 4,
  PA = 5,
  CCG = 6,
  CCG2 = 7,
}

export enum SituacaoRegistroReservado {
  RESERVADO_ASSOCIACAO = 1,
  RESERVADO_INSPETOR = 2,
  UTILIZADO = 3,
  RESERVADO_OCORRENCIA = 4,
}

export enum TipoManejo {
  NONE = -1,
  CONFINADO = 1,
  SEMI_CONFINADO = 2,
  PASTO = 3,
}

export enum TipoTransferencia {
  COLETA_COM_IMPLANTE_TOTAL = 1,
  COLETA_COM_CONGELAMENTO_TOTAL = 2,
  COLETA_COM_IMPLANTE_E_CONGELAMENTO = 3,
  DESCONGELAMENTO_E_IMPLANTE = 4,
}

export enum TipoPeriodoFixoConsulta {
  TODOS = 0,
  ESTA_SEMANA = 1,
  ESTE_MES = 2,
  PERIODO_ESPECIFICO = 3,
}

export enum TipoMotivoMorteNatural {
  None = -1,
  ABATE_FRIGORIFICO = 1,
  ABORTO = 21,
  ACIDENTE = 4,
  AFOGAMENTO = 11,
  ALEIJADO = 70,
  ANEMIA = 58,
  ATAQUE_ONCA = 3,
  BABESIOSE = 54,
  CARBUNCULO = 59,
  CHOQUE_ELETRICO = 12,
  CLOSTRIDIOSE = 74,
  COLICA = 60,
  CRUZAMENTO_INDUSTRIAL = 2,
  DESCARTE = 77,
  DESCONHECIDO = 80,
  DESIDRATACAO = 67,
  DIARREIA = 51,
  EMPANZINAMENTO = 65,
  FRACO = 64,
  GANGRENA = 55,
  HEMORRAGIA = 56,
  HEPATOPATIA = 79,
  INFECCAO = 57,
  INSUFICIENCIA_RENAL = 66,
  INSUFICIENCIA_RESPIRATORIA = 62,
  INTOXICACAO = 63,
  LEUCOSE = 78,
  LIPOMA = 75,
  NATIMORTO = 22,
  PARTO = 23,
  PERITONITE = 61,
  PICADA_DE_COBRA = 14,
  PIROPLASMOSE = 76,
  PNEUMONIA = 52,
  PROLAPSO_UTERINO = 69,
  QUEDA = 72,
  RAIO = 13,
  ROMPIMENTO_ALCA_INTESTINAL = 68,
  ROUBADO = 71,
  TETANO = 53,
  TRISTEZA = 73,
}

export enum TipoCertificadoBaixa {
  None = -1,
  Aprovado = 1,
  Reprovado = 2,
}

export enum TipoRegistroDocumental {
  None = -1,
  RGD = 1,
  CCG = 2,
}

export enum TipoSituacaoEventoAdministrativo {
  Ativo = 1,
  Inativo = 2,
  Pendente = 3,
}

export enum TransferenciaMotivo {
  None = -1,
  Venda = 1,
  Troca = 2,
  Doacao = 3,
  Cessao = 4,
}

export enum MotivoReprovacaoAbate {
  None = -1,
  Idade = 1,
  NumeroDentes = 2,
}

export enum AprovacaoCarcacaAbate {
  None = -1,
  Aprovada = 1,
  Reprovada = 2,
}

export enum CoberturaGorduraAbate {
  None = -1,
  Excessiva = 1,
  Uniforme = 2,
}

export enum TipoGerarRelatorio {
  Excel = 1,
  Pdf = 2
}

export enum ECertificado {
  REGISTRO = 1,
  DESEMPENHO = 2,
  PREMIACAO = 3,
  ABATE = 4,
  NASCIMENTO = 5,
}

export enum DestinoInspecao {
  None = -1,
  Reproducao = 1,
  Abate = 2,
}

export enum TipoRegistroInspecao {
  Sim = 1,
  Nao = 2,
  "Não Avaliado" = 3
}

export enum TipoInspecao {
  Avaliacao = 1,
  InspecaoDeRegistro = 2
}

export enum PossuiChifre {
  None = -1,
  Sim = 1,
  Nao = 2
}

export enum TipoPelagemInspecao {
  None = -1,
  Mista = 1,
  Preto = 2,
  Marrom = 3,
  Araça = 4
}

export enum ManchaPeleInspecao {
  None = -1,
  Sim = 1,
  Nao = 2
}

export enum DesenvolvimentoInspecao {
  None = -1,
  Superior = 1,
  Regular = 2,
  Inferior = 3,
}

export enum Castrado {
  None = -1,
  Sim = 1,
  Nao = 2
}

export enum AvaliacaoReprodutivaInspecao {
  None = -1,
  Apto = 1,
  "Não Apto" = 2,
}

export interface Lab {
  id: string;
  name: string;
}

export enum IsGemelar {
  Sim = 1,
  Nao = 2
}

export enum TipoConsultaCarcaca {
  Pos = 1,
  Pre = 2,
  None = 0
}