import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RelacaoPosAbateComAvalCarcacaModel } from 'src/app/models/RelacaoPosAbateComAvalCarcacaModel';
import { ApiService } from 'src/app/shared/api.service';
import { AprovacaoCarcacaAbate, CoberturaGorduraAbate } from 'src/app/shared/enums/Enums';
import Swal from 'sweetalert2';

interface IInputData {
  readonly: boolean | null;
  relacao?: RelacaoPosAbateComAvalCarcacaModel;
}

@Component({
  selector: 'app-relacao-apos-abate-modal',
  templateUrl: './relacao-apos-abate-modal.component.html',
  styleUrls: ['./relacao-apos-abate-modal.component.scss']
})
export class RelacaoAposAbateModalComponent implements OnInit {

  form: RelacaoPosAbateComAvalCarcacaModel = new RelacaoPosAbateComAvalCarcacaModel();
  
  itensCoberturaGordura: { value: number, label: string }[] = [];
  itensAprovacaoCarcaca: { value: number, label: string }[] = [];

  @Output() relacao: EventEmitter<RelacaoPosAbateComAvalCarcacaModel> = new EventEmitter<RelacaoPosAbateComAvalCarcacaModel>();

  constructor(
    public dialogRef: MatDialogRef<RelacaoAposAbateModalComponent>,
    private api: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: IInputData | null
  ) { 
    if (data) {
      if (data.relacao) this.form = data.relacao;
      if (data.readonly != null) this.form.Readonly = data.readonly;
    }
  }

  ngOnInit(): void {
    this.preencherItensCoberturaGordura();
    this.preencherItensAprovacaoCarcaca();
  }

  botaoSalvar() {
    this.api.salvarRelacaoPosAbateComAvalCarcaca(this.form).then(async (result: any) => {
      if (result && result.success) {
        await Swal.fire(result.message, '', 'success');
        this.relacao.emit(result);
        this.fechar();
      } else {
        Swal.fire(result.message || 'Não foi possível realizar a operação.', '', 'error');
      }
    });
  }

  preencherItensCoberturaGordura() {
    this.itensCoberturaGordura.push({ value: CoberturaGorduraAbate.None, label: 'Selecione ...' });
    this.itensCoberturaGordura.push({ value: CoberturaGorduraAbate.Excessiva, label: 'Excessiva' });
    this.itensCoberturaGordura.push({ value: CoberturaGorduraAbate.Uniforme, label: 'Uniforme' });
  }
  
  preencherItensAprovacaoCarcaca() {
    this.itensAprovacaoCarcaca.push({ value: AprovacaoCarcacaAbate.None, label: 'Selecione ...' });
    this.itensAprovacaoCarcaca.push({ value: AprovacaoCarcacaAbate.Aprovada, label: 'Aprovada' });
    this.itensAprovacaoCarcaca.push({ value: AprovacaoCarcacaAbate.Reprovada, label: 'Reprovada' });
  }

  fechar = () => this.dialogRef.close();

}
