import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from 'src/app/account/shared/account.service';
import { RolesKeys } from 'src/app/account/shared/RolesKeys';
import { FiltroConsultaLaudoDnaModel } from 'src/app/models/FiltroConsultaLaudoDnaModel';
import { ApiService } from 'src/app/shared/api.service';
import { Commons } from 'src/app/shared/commons';
import { ConstantesRotas } from 'src/app/shared/constantes';
import { QualificaLaudoDNA } from 'src/app/shared/enums/Enums';
import { IItemLaudoDna, IJsonResultModel, IResponseConsultaLaudoDna, IResponseVisualizarLaudoDna } from 'src/app/shared/interfaces/Interfaces';
import { LocalStorageService } from 'src/app/shared/local-storage.service';
import { QueryStateService } from 'src/app/shared/query-state.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-laudo-dna-consultar',
  templateUrl: './laudo-dna-consultar.component.html',
  styleUrls: ['./laudo-dna-consultar.component.scss']
})
export class LaudoDnaConsultarComponent implements OnInit {

  page = 1;
  count = 0;
  pageSize = environment.pageSize;
  permiteNovo: boolean = false;

  laudos: IItemLaudoDna[] = [];
  formConsulta: FiltroConsultaLaudoDnaModel = new FiltroConsultaLaudoDnaModel();

  constructor(
    private router: Router,
    private accountService: AccountService,
    private api: ApiService,
    private commons: Commons,
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private queryState: QueryStateService
  ) { }

  ngOnInit(): void {
    this.permiteNovo = this.accountService.checkRole(RolesKeys.LaudoDNARegistrar);
  }

  novo() {
    this.router.navigate([ConstantesRotas.LAUDO_DNA_MANTER]);
  }

  handlePageChange(event: number): void {
    this.page = event;
    //salva a ultima pagina da pesquisa
    this.queryState.paginacao(event);
    this.botaoConsultar();
  }

  getDescricaoQualifica(qualifica: QualificaLaudoDNA): string {
    switch (qualifica) {
      case QualificaLaudoDNA.Nao: return 'Não';
      case QualificaLaudoDNA.Sim: return 'Sim';
      case QualificaLaudoDNA.Permanente: return 'Permanente';
      default: return '';
    }
  }

  botaoConsultar() {
    if (this.localStorage.get("page")) {
      this.page = parseInt(this.localStorage.get("page"));
    } else {
      this.page = 1;
    }
    //salva o formulario de pesqusia no storage
    this.queryState.saveFormLadudos(this.formConsulta);
    //obtem a rota da página atual
    this.queryState.getRota(this.route.snapshot.url[0].path);
    this.formConsulta.NumeroLaudo = this.formConsulta.NumeroLaudo.trim().toUpperCase();

    this.api.consultarLaudoDna(this.formConsulta, this.page, this.pageSize).then((result: IResponseConsultaLaudoDna) => {
      if (result && result.success) {
        this.laudos = result.Itens;
        this.count = result.TotalItens;
      } else {
        this.page = 1;
        this.count = 0;
        this.laudos = [];
        Swal.fire(result.message || 'A operação não pode ser realizada.', '', 'error');
      }
    });
  }

  visualizarDocumento(id: string | undefined) {
    if (!id) return;

    this.api.visualizarLaudoDna(id).then((result: IJsonResultModel<IResponseVisualizarLaudoDna>) => {
      if (result && result.Data?.success) {
        this.commons.visualizarBase64EmPdf(result.Data.content, result.ContentType || '');
      } else {
        Swal.fire(result.Data?.message || 'Não foi possível realizar a operação.', '', 'error');
      }
    });
  }

}
