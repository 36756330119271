<div class="card" style="margin-top: 1em;">
  <div class="card-body">
      <div>
          <app-filtro-consulta-certificacao-pendentes [formConsulta]="formConsulta"></app-filtro-consulta-certificacao-pendentes>

          <div class="row justify-content-end">             
              <div class="col-lg-2 col-md-4 col-sm-6">
                  <button type="button" class="btn btn-sm btn-block btn-primary"
                      (click)="botaoConsultar()">Buscar</button>
              </div>
          </div>
      </div>

      <div *ngIf="count > 0" class="row" style="margin-top: 15px;">
          <div class="col-lg-12 table-responsive">
            <table class="table table-hover table-header-custom">
              <thead>
                <tr>
                  <th>Código</th> 
                  <th>Nome</th> 
                  <th>Situação SRG</th> 
                  <th class="text-center" width="10%"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let certificado of certificados | paginate : {itemsPerPage: pageSize, currentPage: page, totalItems: count};">
                  
                  <td>{{ certificado.CodigoBovino }}</td>
                  <td>{{ certificado.NomeBovino }}</td> 
                  <td>{{ certificado.DescSituacaoSrg }}</td> 
                  <td class="text-center text-nowrap">
                    <a 
                      type="button" 
                      matTooltip="Editar" 
                      [queryParams]="{Action: gerarParametro('Gerar'), Id: gerarParametro(certificado.Id)}"
                      [routerLink]="['/certificados_manter']" 
                    >
                      <i class="fas fa-edit"></i>
                    </a>

                    <!-- <a type="button" matTooltip="Editar">
                      <i class="fas fa-edit" style="font-size: 1.6em;"></i>
                    </a> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
    
          <div class="col-md-12 text-center">
            <pagination-controls 
              class="srg-paginacao"
              previousLabel="Anterior" 
              nextLabel="Próximo" 
              [responsive]="true" 
              (pageChange)="handlePageChange($event)"
              [autoHide]="true"
            >
            </pagination-controls>
          </div>
        
        </div>

  </div>
</div>
