import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FiltroConsultaCertificadosModel } from 'src/app/models/FiltroConsultaCertificadosModel';
import { ApiService } from 'src/app/shared/api.service';
import { Commons } from 'src/app/shared/commons';
import { IVisualizaCertificado } from 'src/app/shared/interfaces/Interfaces';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-certificado-assinar',
  templateUrl: './certificado-assinar.component.html',
  styleUrls: ['./certificado-assinar.component.scss']
})
export class CertificadoAssinarComponent implements OnInit {

  page = 1;
  count = 0;
  certificados: any[] = [];
  pageSize = environment.pageSize;
  certificadosSelecionados: string[] = [];
  listaCertificado: { value: number, label: string }[] = [];
  formConsulta: FiltroConsultaCertificadosModel = new FiltroConsultaCertificadosModel();

  constructor(
    private api: ApiService,
    private commons: Commons,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.formConsulta.assinado = 2;
    this.consultar();
    this.preencherCertificados();
  }

  preencherCertificados() {
    this.listaCertificado = [];
    this.listaCertificado.push({ value: 1, label: "Registro" });

    switch ((environment as any).contexto) {
      case 'jersey':
        this.listaCertificado.push({ value: 2, label: "Desempenho" }); break;
      case 'wagyu':
        this.listaCertificado.push({ value: 4, label: "Abate" }); break;
    }
    this.listaCertificado.push({ value: 5, label: "Nascimento" });
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.consultar();
  }

  async botaoAssinar(): Promise<void> {
    if (this.certificadosSelecionados.length === 0) {
      Swal.fire('Por favor, informe ao menos um certificado para assinar.', '', 'warning');
      return;
    }

    const response = await this.commons.modalConfirmacao({
      title: 'Confirma assinatura dos certificados selecionados?'
    });
    if (!response) return;

    this.api.assinarCertificado(this.formConsulta.certificado, this.certificadosSelecionados).then((result: any) => {
      if (result) {
        Swal.fire(result.message, '', result.success ? 'success' : 'error');
        this.consultar();
      }
    });
  }

  consultar() {
    if (this.formConsulta.certificado == 5) {
      this.formConsulta.classeCertificado = 2;
    } else if (this.formConsulta.certificado == 1) {
      this.formConsulta.classeCertificado = 1;
    } else {
      this.formConsulta.classeCertificado = 0;
    }
    this.api.consultarCertificados(this.formConsulta, this.page, this.pageSize).then((result: any) => {
      if (result) {
        if (result.success === true) {
          this.certificados = [];
          this.count = result.TotalItens;
          result.Itens?.forEach((element: any) => {
            element.Selecionado = false;
            element.DescDataEmissao = element.DataEmissao === '0001-01-01T00:00:00' ? '-' : this.datePipe.transform(element.DataEmissao, "dd/MM/yyyy");
            this.certificados.push(element);
          });
        } else {
          this.count = 0;
          this.certificados = [];
          Swal.fire(result.message, '', 'error');
        }
      }
    });
  }

  handleSelecionadoChange(certificado: any) {
    const index: number = this.certificadosSelecionados.indexOf(certificado.Id);

    if (index === -1) {
      this.certificadosSelecionados.push(certificado.Id)
    } else {
      this.certificadosSelecionados.splice(index, 1);
    }
  }

  botaoConsultar() {
    setTimeout(() => {
      this.page = 1;
      this.consultar();
    }, 100);
  }

  criadorSelecionado(idCriador: string) {
    this.formConsulta.bovino.IdProprietarioConsulta = idCriador;
  }

  visualizarCertificado(id: string, tipo: number): void {
    this.api.visualizarCertificado(id, tipo).then((result: IVisualizaCertificado) => {
      if (result && result.Data.success) {
        this.commons.visualizarBase64EmPdf(result.Data.content || '', result.ContentType);
      } else {
        Swal.fire(result.Data.message, '', 'error');
      }
    });
  }

}
