import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/account/shared/account.service';
import { ReservarRegistrosInspetorModel } from 'src/app/models/ReservarRegistrosInspetorModel';
import { ApiService } from 'src/app/shared/api.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { BovinoService } from '../../shared/bovino.service';

@Component({
  selector: 'app-manter-registro-inspetor',
  templateUrl: './manter-registro-inspetor.component.html',
  styleUrls: ['./manter-registro-inspetor.component.scss']
})
export class ManterRegistroInspetorComponent implements OnInit {

  readonly env = environment;
  
  tiposRegistros: { label: string, value: number }[] = [];
  formulario: ReservarRegistrosInspetorModel = new ReservarRegistrosInspetorModel();

  constructor(
    private router: Router,
    private api: ApiService,
    private accountService: AccountService,
    private bovinoService: BovinoService,
  ) { }

  ngOnInit(): void {
    const { DomiminoId: filiadaId } = this.accountService.getPayloadToken() as any;
    this.formulario.FiliadaId = filiadaId;

    this.tiposRegistros = this.bovinoService.getTiposRegistrosReservados('Selecione...', true);
  }

  botaoSalvar() {
    this.api.reservarRegistroInspetor(this.formulario).then((result: any) => {
      if (result && result.success) {
        Swal.fire(result.message, '', 'success');
        this.router.navigate(['registros_reservados']);
      } else {
        Swal.fire(result.message || 'Não foi possível reservar o registro.', '', 'error');
      }
    });
  }

}
