<div class="row">
  <div class="form-group col-lg-4 col-md-4 col-sm-12">
    <label for="CodigoVeterinario">CRMV {{veterinario.TituloConsulta}}</label>
    <input *ngIf="readonly" type="text" class="form-control form-control-sm" name="CodigoVeterinario" [(ngModel)]="veterinario.crmv" readonly>

    <div *ngIf="!readonly" class="input-group input-group-sm">
      <input type="text" class="form-control" name="CodigoVeterinario" [(ngModel)]="veterinario.crmv" autocomplete="off" (keydown.enter)="consultarPorCrmv()" [disabled]="isReadOnlyFunction()">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" type="button" (click)="consultarPorCrmv()" [disabled]="isReadOnlyFunction()"> <i class="bi bi-check"></i> </button>
      </div>
    </div>
  </div>

  <div class="form-group col-lg-8 col-md-8 col-sm-12">
    <label for="nomeVeterinario">Nome {{veterinario.TituloConsulta}}</label>
    <input *ngIf="readonly" type="text" class="form-control form-control-sm" name="nomeVeterinario" [(ngModel)]="veterinario.nomeVeterinario" readonly>

    <div *ngIf="!readonly" class="input-group input-group-sm">
      <input type="text" class="form-control" name="nomeVeterinario" [(ngModel)]="veterinario.nomeVeterinario" readonly>
      <div class="input-group-append">
        <!-- <button class="btn btn-outline-secondary" type="button" (click)="consultarPorNome()"> <i class="bi bi-search"></i> </button> -->
        <button class="btn btn-outline-secondary" type="button" (click)="limpar()" [disabled]="isReadOnlyFunction()"> <i class="bi bi-eraser"></i> </button>
      </div>
    </div>
  </div>
</div>