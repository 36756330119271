import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { QuestionarioManjeoModel } from 'src/app/models/QuestionarioManejoModel';
import { ApiService } from '../api.service';
import { AccountService } from 'src/app/account/shared/account.service';
import Swal from 'sweetalert2';
import { FazendaModel } from 'src/app/models/FazendaModel';
import { IFazenda } from '../interfaces/Interfaces';

interface IDataInput {
  CriadorId: string;
  Fazenda: FazendaModel;
  IsEdit: boolean;
}


@Component({
  selector: 'app-questionario-modal',
  templateUrl: './questionario-modal.component.html',
  styleUrls: ['./questionario-modal.component.scss']
})
export class QuestionarioModalComponent implements OnInit {

  public questionarioManejoModel: QuestionarioManjeoModel = new QuestionarioManjeoModel();
  public fazendasCriador: any;
  public contFazenda: number = 0;
  public numeroFazendas = 0;
  public fazenda = new FazendaModel();
  public isEdit: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<QuestionarioModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDataInput,
    private apiService: ApiService,
    private accountService: AccountService
  ) { }

  ngOnInit(): void {
    if (this.accountService.isCriador) {
      let token: any = this.accountService.getPayloadToken();
      if (token) {
        this.recuperarFazendasCriador(token.CodigoCriador).then(() => {
          this.numeroFazendas = this.fazendasCriador.length;
        });
      }
    }
    try {
      if (this.data.CriadorId && this.data.CriadorId != null && this.data.CriadorId != '') {
        this.apiService.consultarQuestionarioManejo(this.data.CriadorId, this.data.Fazenda.Id).then((result: any) => {
          this.questionarioManejoModel = result.questionarios[0];
        });
      }
      this.fazenda = this.data.Fazenda;
      this.isEdit = this.data.IsEdit;
    }
    catch {

    }


  }

  enviar() {
    if (this.isEdit) {
      if (this.questionarioManejoModel.IdadeDesmame == 0
        || this.questionarioManejoModel.PesoDesmame == 0
        || this.questionarioManejoModel.TempoManejoRecria == 0
        || this.questionarioManejoModel.PesoFinalRecria == 0
        || this.questionarioManejoModel.TempoTerminacao == 0
        || this.questionarioManejoModel.PesoFinalTerminacao == 0
        || this.questionarioManejoModel.IdadeCastracao == 0) {
        Swal.fire({ title: "Todas as perguntas são obrigatórias!", html: 'Por favor, responda todas as perguntas antes de enviar.', icon: 'error' });
      } else {
        if (this.accountService.isCriador) {
          let token: any = this.accountService.getPayloadToken();
          if (token) {
            this.questionarioManejoModel.Criador = token.CriadorId;
          }
          this.questionarioManejoModel.Fazenda = this.fazendasCriador[this.contFazenda].Id;
        } else {
          this.questionarioManejoModel.Fazenda = this.fazenda.Id;
        }

        this.apiService.manterQuestionarioManejo(this.questionarioManejoModel).then((result: any) => {
          if (this.accountService.isAdmin) {
            if (result.Data.success) {
              Swal.fire({ title: "Sucesso!", html: 'O questionário foi atualizado e está disponível para a associação.', icon: 'success' }).then(() => {
                this.dialogRef.close();
              });
            }
          }
          if (result.Data.success) {
            this.contFazenda++;
            if (this.fazendasCriador.length > 1 && this.contFazenda < this.numeroFazendas && !this.isEdit) {
              this.questionarioManejoModel = new QuestionarioManjeoModel();
              Swal.fire({ title: "Sucesso!", html: 'Agora, você precisa responder para a sua próxima fazenda.', icon: 'success' });
            } else if (!this.isEdit) {
              Swal.fire({ title: "Sucesso!", html: 'Seu questionário foi enviado e está disponível para a associação. Para finalizar, você será redirecionado para a tela de login.', icon: 'success' }).then(() => {
                this.dialogRef.close();
                if (this.accountService.isCriador) {
                  this.accountService.logoff();
                }
              });
            } else {
              Swal.fire({ title: "Sucesso!", html: 'Seu questionário foi atualizado e está disponível para a associação.', icon: 'success' }).then(() => {
                this.dialogRef.close();
              });
            }

          } else {
            Swal.fire(result.message, '', 'error');
          }
        });
      }
    } else {
      Swal.fire({
        title: 'Confirmação',
        html: 'Após enviado o questionário só poderá ser editado pela própria associação, sendo necessário que você entre em contato. Confirma o envio deste questionário?',
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim',
      }).then(resultConfirmacao => {
        if (resultConfirmacao.isConfirmed) {
          if (this.questionarioManejoModel.IdadeDesmame == 0
            || this.questionarioManejoModel.PesoDesmame == 0
            || this.questionarioManejoModel.TempoManejoRecria == 0
            || this.questionarioManejoModel.PesoFinalRecria == 0
            || this.questionarioManejoModel.TempoTerminacao == 0
            || this.questionarioManejoModel.PesoFinalTerminacao == 0
            || this.questionarioManejoModel.IdadeCastracao == 0) {
            Swal.fire({ title: "Todas as perguntas são obrigatórias!", html: 'Por favor, responda todas as perguntas antes de enviar.', icon: 'error' });
          } else {
            if (this.accountService.isCriador) {
              let token: any = this.accountService.getPayloadToken();
              if (token) {
                this.questionarioManejoModel.Criador = token.CriadorId;
              }
              this.questionarioManejoModel.Fazenda = this.fazendasCriador[this.contFazenda].Id;
            } else {
              this.questionarioManejoModel.Fazenda = this.fazenda.Id;
            }

            this.apiService.manterQuestionarioManejo(this.questionarioManejoModel).then((result: any) => {
              if (this.accountService.isAdmin) {
                if (result.Data.success) {
                  Swal.fire({ title: "Sucesso!", html: 'O questionário foi atualizado e está disponível para a associação.', icon: 'success' }).then(() => {
                    this.dialogRef.close();
                  });
                }
              }
              if (result.Data.success) {
                this.contFazenda++;
                if (this.fazendasCriador.length > 1 && this.contFazenda < this.numeroFazendas && !this.isEdit) {
                  this.questionarioManejoModel = new QuestionarioManjeoModel();
                  Swal.fire({ title: "Sucesso!", html: 'Agora, você precisa responder para a sua próxima fazenda.', icon: 'success' });
                } else if (!this.isEdit) {
                  Swal.fire({ title: "Sucesso!", html: 'Seu questionário foi enviado e está disponível para a associação. Para finalizar, você será redirecionado para a tela de login.', icon: 'success' }).then(() => {
                    this.dialogRef.close();
                    if (this.accountService.isCriador) {
                      this.accountService.logoff();
                    }
                  });
                } else {
                  Swal.fire({ title: "Sucesso!", html: 'Seu questionário foi atualizado e está disponível para a associação.', icon: 'success' }).then(() => {
                    this.dialogRef.close();
                  });
                }

              } else {
                Swal.fire(result.message, '', 'error');
              }
            });
          }
        }
      });
    }



  }

  showConsole() {
  }


  private async recuperarFazendasCriador(codigo: string) {
    const response = await this.apiService.consultarCriadorPorCodigo({ codigo, consultaEmTodasFiliadas: true });

    if (response) {
      if (response.Status === 0) {
        this.fazendasCriador = response.FazendasCriador;
      } else {
        Swal.fire(response.Mensagem, '', 'error');
      }
    }
  }

  fechar() {
    this.dialogRef.close();
  }

  permiteEditar() {
    if (this.accountService.isAdmin || this.accountService.isSuperintendente) {
      return true;
    }
    else {
      return false;
    }
  }
}
