import { PosicaoAfixo } from "../shared/enums/Enums";

interface IConstructor {
  codigo?: string;
  posicaoAfixo?: PosicaoAfixo;
  principal?: boolean;
}

export class IdentificacaoCriadorModel {
  Id: string;
  codigo: string;
  principal: boolean;
  posicaoAfixo: PosicaoAfixo;

  constructor(readonly _params: IConstructor = {} as IConstructor) {
    this.Id = "-1";
    this.codigo = _params.codigo || "";
    this.posicaoAfixo = _params.posicaoAfixo || PosicaoAfixo.Sufixo;
    this.principal = _params.principal || false;
  }
}