import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AccountService } from 'src/app/account/shared/account.service';
import { RolesKeys } from 'src/app/account/shared/RolesKeys';
import { ExposicaoModel } from 'src/app/models/ExposicaoModel';
import { ApiService } from 'src/app/shared/api.service';
import Swal from 'sweetalert2';
import { UnidadesFederativas } from 'src/app/shared/enums/Enums';

@Component({
  selector: 'app-exposicao-manter',
  templateUrl: './exposicao-manter.component.html',
  styleUrls: ['./exposicao-manter.component.scss']
})
export class ExposicaoManterComponent implements OnInit {

  permiteNovo: boolean = true;
  UFs: { value: number, label: string }[] = [];
  formRegistrar: ExposicaoModel = new ExposicaoModel();

  constructor(
    private api: ApiService,
    private accountService: AccountService
  ) { }

  ngOnInit(): void {
    this.preencherEstados();
    this.permiteNovo = this.accountService.checkRole(RolesKeys.Exposicao);
  }

  preencherEstados(){
    this.UFs = [];
    this.UFs.push({ value: 0, label: "Selecione ..."});
    for (let i=1 ; i<28 ; i++)
      this.UFs.push({ value: i, label: UnidadesFederativas[i]});
  }

  novo() {

    const { errors } = new FormControl(
      this.formRegistrar.email, 
      [Validators.required, Validators.email]
    );

    if (errors) {
      const key = Object.keys(errors);
      
      if (key.includes("required")) 
        Swal.fire('E-mail não informado', '', 'error');
        
      if (key.includes("email"))
        Swal.fire('E-mail inválido', '', 'error');
      
      return;
    }

    this.api.registrarExposicao(this.formRegistrar).then((result: any) => {
      if (result.success) {
        this.formRegistrar = {} as ExposicaoModel;
        Swal.fire(result.message, '', 'success');
      } else {
        Swal.fire(result.message, '', 'error');
      }
    });
  }

}
