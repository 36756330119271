<header>
    <style>
      #intro {
        height: 100vh;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }

      /* Height for devices larger than 576px */
      @media (min-width: 992px) {
        #intro {
          margin-top: -46px;
        }
      }

      .navbar .nav-link {
        color: #fff !important;
      }
    </style>

    <nav class="navbar navbar-expand-lg navbar-dark d-none d-lg-block" style="z-index: 2000;">
      <div class="container-fluid">
        <!-- Navbar brand -->
        <a class="navbar-brand nav-link" href="#">
          <strong>SRG - Sistema de Registro Genealógico</strong>
        </a>
      </div>
    </nav>

    <!-- Background image -->
    <div id="intro" class="bg-image shadow-2-strong" [style.backgroundImage]="'url('+ backgroundImage +')'" >
      <div class="mask d-flex align-items-center h-100" style="background-color: rgba(0, 0, 0, 0.7);">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-md-8">
              <form class="bg-white rounded shadow-5-strong p-5" (ngSubmit)="onSubmit()" #form="ngForm">

                <div class="form-outline mb-4 text-center">
                    <img src="{{logoImage}}" height="128px"/>
                </div>

                <div class="row">
                    <div class="col-md form-group">
                        <label class="form-label" for="codigo">Código</label>
                        <input autofocus type="email" id="codigo" name="codigo" class="form-control" style="text-transform:uppercase" autocomplete="off" [(ngModel)]="login.codigo" required/>
                    </div>
                    <div class="col-md form-group">
                        <label class="form-label" for="usuario">Usuário</label>
                        <input type="email" id="usuario" name="usuario" class="form-control" autocomplete="off" [(ngModel)]="login.usuario" required/>
                    </div>
                </div>
  
                <div class="form-group  mb-4">
                    <label class="form-label" for="senha">Senha</label>
                    <input type="password" id="senha" name="senha" class="form-control" autocomplete="off" [(ngModel)]="login.senha" required/>
                </div>

                <div class="form-outline mb-4">
                    <button type="submit" class="btn btn-primary btn-block" [disabled]="form.form.invalid">Entrar</button>
                </div>
                
                <!-- 2 column grid layout for inline styling -->
                <div class="row mb-4">
                  <div class="col text-center">
                    <!-- Simple link -->
                    <a href="#!">Esqueceu sua senha?</a>
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div> 

  </header>