export class VeterinarioModel {
  id: string;
  nomeVeterinario: string;
  crmv: string;
  TituloConsulta: string;
  Readonly: boolean;

  constructor() {
    this.id = "";
    this.nomeVeterinario = "";
    this.crmv = "";
    this.TituloConsulta = "Veterinário";
    this.Readonly = false;
  }
}