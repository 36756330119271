import { AccountService, ItemMenu } from './../../account/shared/account.service';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import * as $ from 'jquery';
import { ActivatedRoute, Router } from '@angular/router';
import { ConstantesRotas } from 'src/app/shared/constantes';
import { TabsPlantel } from 'src/app/shared/enums/Enums';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  readonly env = environment;
  logoImage = "";
  active: boolean = true;
  nomeUsuario = "";
  primeiroNomeUsuario = "";
  siglaAssociacao = "";
  funcaoUsuario = "";
  versao = "";
  listItensMenu: ItemMenu[] = [];
  isCriador: boolean = false;

  private readonly contexto = (environment as any).contexto;
  readonly isProduction = environment.production;

  public breadcrumbList: any[] = [];

  constructor(
    private accountService: AccountService,
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.logoImage = environment.logoImage;
    this.versao = environment.versao;
    this.dadosDoUsuario();
    this.listItensMenu = this.accountService.getMenuPrincipal();
    this.atualizarBreadcrumb();
    this.isCriador = this.accountService.isCriador;
  }

  dadosDoUsuario() {
    let payloadToken: any = this.accountService.getPayloadToken();
    if (payloadToken) {
      if (payloadToken.NomePessoaUsuario) {
        this.nomeUsuario = payloadToken.NomePessoaUsuario;
        this.primeiroNomeUsuario = this.nomeUsuario.split(' ')[0];
      }

      if (payloadToken.CodigoFiliada) {
        this.siglaAssociacao = payloadToken.CodigoFiliada;
      }

      if (payloadToken.IndCriadorAssociado) {
        this.funcaoUsuario = payloadToken.IndCriadorAssociado === "1" ? "Associado" : "Associação";
      }
    }
  }

  toggle() {
    this.active = !this.active;
    $("#wrapper").toggleClass("toggled");
  }

  sair() {
    Swal.fire({
      title: 'Deseja realmente sair do sistema?',
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: `Sair`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.accountService.logoff();
      }
    });
  }

  navegarBreadcrumb(item: any) {
    if (!item.active) {
      this.navegarPara(item.rota);
    }
  }

  navegarPara(rota: string) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([rota]);
  }

  atualizarBreadcrumb() {
    const labelPadreacao = this.contexto === 'jersey' ? 'Padreação' : 'Cobertura';
    const labelInovulacao = this.contexto === 'jersey' ? 'Inovulação' : 'Implantação';

    this.breadcrumbList = [];

    this.adicionarBreadcrumb('Principal', '');

    if (this.router.url == `/${ConstantesRotas.CRIADOR_CONSULTAR}`) {
      this.adicionarBreadcrumb('Consultar Criador', ConstantesRotas.CRIADOR_CONSULTAR);
    }
    else if (this.router.url == `/${ConstantesRotas.CRIADOR_MANTER}`) {
      this.adicionarBreadcrumb('Consultar Criador', ConstantesRotas.CRIADOR_CONSULTAR);
      this.adicionarBreadcrumb('Registrar Criador', ConstantesRotas.CRIADOR_MANTER);
    }
    else if (this.router.url == `/${ConstantesRotas.USUARIO_REGISTRAR}`) {
      this.adicionarBreadcrumb('Consultar Registros de Usuário', ConstantesRotas.USUARIO_CONSULTAR);
      this.adicionarBreadcrumb('Registrar Usuário', ConstantesRotas.USUARIO_REGISTRAR);
    }
    else if (this.router.url == `/${ConstantesRotas.USUARIO_CONSULTAR}`) {
      this.adicionarBreadcrumb('Consultar Registros de Usuário', ConstantesRotas.USUARIO_CONSULTAR);
    }
    else if (this.router.url == `/${ConstantesRotas.EXPOSICAO_REGISTRAR}`) {
      this.adicionarBreadcrumb('Consultar Exposições', ConstantesRotas.EXPOSICAO_CONSULTAR);
      this.adicionarBreadcrumb('Registrar Exposição', ConstantesRotas.EXPOSICAO_REGISTRAR);
    }
    else if (this.router.url == `/${ConstantesRotas.EXPOSICAO_CONSULTAR}`) {
      this.adicionarBreadcrumb('Consultar Exposições', ConstantesRotas.EXPOSICAO_CONSULTAR);
    }
    else if (this.router.url == `/${ConstantesRotas.REGISTRAR_CONTROLE_LEITERO}`) {
      this.adicionarBreadcrumb(`Consultar Controle Leiteiro`, ConstantesRotas.CONSULTAR_CONTROLE_LEITERO);
      this.adicionarBreadcrumb(`Importar Arquivo Controle Leiteiro`, ConstantesRotas.REGISTRAR_CONTROLE_LEITERO);
    }
    else if (this.router.url == `/${ConstantesRotas.CONSULTAR_CONTROLE_LEITERO}`) {
      this.adicionarBreadcrumb(`Consultar Controle Leiteiro`, ConstantesRotas.CONSULTAR_CONTROLE_LEITERO);
    }
    else if (this.router.url == `/${ConstantesRotas.CARCACA_CONSULTAR}`) {
      this.adicionarBreadcrumb(`Consultar registros de Abate com Avaliação de Carcaça`, ConstantesRotas.CARCACA_CONSULTAR);
    }
    else if (this.router.url == `/${ConstantesRotas.CARCACA_MANTER}`) {
      this.adicionarBreadcrumb(`Consultar registros de Abate com Avaliação de Carcaça`, ConstantesRotas.CARCACA_CONSULTAR);
      this.adicionarBreadcrumb(`Registrar Pré-Abate`, ConstantesRotas.CARCACA_MANTER);
    }
    else if (this.router.url == `/${ConstantesRotas.CARCACA_MANTER_POS}`) {
      this.adicionarBreadcrumb(`Consultar registros de Abate com Avaliação de Carcaça`, ConstantesRotas.CARCACA_CONSULTAR);
      this.adicionarBreadcrumb(`Registrar Pós-Abate`, ConstantesRotas.CARCACA_MANTER_POS);
    }
    else if (this.router.url == `/${ConstantesRotas.PLANTEL_CONSULTAR}`) {
      this.adicionarBreadcrumb('Plantel', ConstantesRotas.PLANTEL_CONSULTAR);
    }
    else if (this.router.url == `/${ConstantesRotas.BOVINO_MANTER}`) {
      this.adicionarBreadcrumb('Plantel', ConstantesRotas.PLANTEL_CONSULTAR);
      this.adicionarBreadcrumb('Cadastrar Bovino', ConstantesRotas.BOVINO_MANTER);
    }
    else if (this.router.url.indexOf(`/${ConstantesRotas.BOVINO_DETALHAR}`) >= 0) {
      this.adicionarBreadcrumb('Plantel', ConstantesRotas.PLANTEL_CONSULTAR);

      this.route.queryParams.subscribe(({ tab: tabQuery, readOnly: readOnlyQuery }) => {
        const tab = !isNaN(tabQuery) && tabQuery in TabsPlantel ? tabQuery : 0;
        const readOnly = readOnlyQuery instanceof Boolean ? readOnlyQuery : true;

        const label = ((): string => {
          switch (tab) {
            case '0': return 'Dados do Bovino';
            case '1': return 'Genealogia';
            case '2': return 'Eventos';
            case '3': return 'Documentos';
            case '4': return 'Crias';
            case '5': return 'Controle Leiteiro';
            case '6': return 'Classificação';
            case '7': return 'Premiação';
            default: return '';
          }
        })();

        this.adicionarBreadcrumb(label, `${ConstantesRotas.BOVINO_DETALHAR}?tab=${tab}&readOnly=${readOnly}`);
      });
    }
    else if (this.router.url == `/${ConstantesRotas.CERTIFICADOS_CONSULTAR}`) {
      this.adicionarBreadcrumb('Consultar Certificados', ConstantesRotas.CERTIFICADOS_CONSULTAR);
    }
    else if (this.router.url == `/${ConstantesRotas.PENDENTES_DE_EMISSAO}`) {
      this.adicionarBreadcrumb('Animais com certificado pendente de emissão', ConstantesRotas.PENDENTES_DE_EMISSAO);
    }
    else if (this.router.url == `/${ConstantesRotas.PADREACAO_CONSULTAR}`) {
      this.adicionarBreadcrumb(`Consultar Registros de ${labelPadreacao}`, ConstantesRotas.PADREACAO_CONSULTAR);
    }
    else if (this.router.url == `/${ConstantesRotas.PADREACAO_MANTER}`) {
      this.adicionarBreadcrumb(`Consultar Registros de ${labelPadreacao}`, ConstantesRotas.PADREACAO_CONSULTAR);
      this.adicionarBreadcrumb(`Registrar ${labelPadreacao}`, ConstantesRotas.PADREACAO_MANTER);
    }
    else if (this.router.url == `/${ConstantesRotas.NASCIMENTO_CONSULTAR}`) {
      this.adicionarBreadcrumb('Consultar Registros de Nascimento', ConstantesRotas.NASCIMENTO_CONSULTAR);
    }
    else if (this.router.url == `/${ConstantesRotas.NASCIMENTO_MANTER}`) {
      this.adicionarBreadcrumb('Consultar Registros de Nascimento', ConstantesRotas.NASCIMENTO_CONSULTAR);
      this.adicionarBreadcrumb('Registrar Nascimento', ConstantesRotas.NASCIMENTO_MANTER);
    }
    else if (this.router.url == `/${ConstantesRotas.TE_FIV_CONSULTAR}`) {
      this.adicionarBreadcrumb('Consultar Registros de TE / FIV', ConstantesRotas.TE_FIV_CONSULTAR);
    }
    else if (this.router.url == `/${ConstantesRotas.TE_FIV_MANTER}`) {
      this.adicionarBreadcrumb('Consultar Registros de TE / FIV', ConstantesRotas.TE_FIV_CONSULTAR);
      this.adicionarBreadcrumb('Registrar TE / FIV', ConstantesRotas.TE_FIV_MANTER);
    }
    else if (this.router.url == `/${ConstantesRotas.INOVULACAO_CONSULTAR}`) {
      this.adicionarBreadcrumb(`Consultar Registros de ${labelInovulacao}`, ConstantesRotas.INOVULACAO_CONSULTAR);
    }
    else if (this.router.url == `/${ConstantesRotas.INOVULACAO_MANTER}`) {
      this.adicionarBreadcrumb(`Consultar Registros de ${labelInovulacao}`, ConstantesRotas.INOVULACAO_CONSULTAR);
      this.adicionarBreadcrumb(`Registrar ${labelInovulacao}`, ConstantesRotas.INOVULACAO_MANTER);
    }
    else if (this.router.url == `/${ConstantesRotas.BAIXA_CONSULTAR}`) {
      this.adicionarBreadcrumb('Consultar Registros de Baixa', ConstantesRotas.BAIXA_CONSULTAR);
    }
    else if (this.router.url == `/${ConstantesRotas.BAIXA_MANTER}`) {
      this.adicionarBreadcrumb('Consultar Registros de Baixa', ConstantesRotas.BAIXA_CONSULTAR);
      this.adicionarBreadcrumb('Registrar Baixa', ConstantesRotas.BAIXA_MANTER);
    }
    else if (this.router.url == `/${ConstantesRotas.TRANSFERENCIA_CONSULTAR}`) {
      this.adicionarBreadcrumb('Consultar Registros de Transferência', ConstantesRotas.TRANSFERENCIA_CONSULTAR);
    }
    else if (this.router.url == `/${ConstantesRotas.TRANSFERENCIA_MANTER}`) {
      this.adicionarBreadcrumb('Consultar Registros de Transferência', ConstantesRotas.TRANSFERENCIA_CONSULTAR);
      this.adicionarBreadcrumb('Registrar Transferência', ConstantesRotas.TRANSFERENCIA_MANTER);
    }
    else if (this.router.url == `/${ConstantesRotas.INSPECAO_CONSULTAR}`) {
      this.adicionarBreadcrumb('Consultar Registros de Inspeção', ConstantesRotas.INSPECAO_CONSULTAR);
    }
    else if (this.router.url == `/${ConstantesRotas.CLASSIFICACAO_CONSULTAR}`) {
      this.adicionarBreadcrumb('Consultar Classificação Linear', ConstantesRotas.CLASSIFICACAO_CONSULTAR);
    }
    else if (this.router.url == `/${ConstantesRotas.CLASSIFICACAO_MANTER}`) {
      this.adicionarBreadcrumb('Consultar Classificação Linear', ConstantesRotas.CLASSIFICACAO_CONSULTAR);
      this.adicionarBreadcrumb('Registrar Classificação Linear', ConstantesRotas.CLASSIFICACAO_MANTER);
    }
    else if (this.router.url == `/${ConstantesRotas.NACIONALIZACAO_CONSULTAR_SEMEN}`) {
      this.adicionarBreadcrumb('Consultar Registros de Nacionalização', ConstantesRotas.NACIONALIZACAO_CONSULTAR_SEMEN);
    } else if (this.router.url == `/${ConstantesRotas.NACIONALIZACAO_MANTER_SEMEN}`) {
      this.adicionarBreadcrumb('Consultar Registros de Nacionalização', ConstantesRotas.NACIONALIZACAO_CONSULTAR_SEMEN);
      this.adicionarBreadcrumb('Registrar Nacionalização', ConstantesRotas.NACIONALIZACAO_MANTER_SEMEN)
    } else if (this.router.url == `/${ConstantesRotas.NACIONALIZACAO_CONSULTAR_EMBRIAO}`) {
      this.adicionarBreadcrumb('Consultar Registros de Nacionalização de Embrião', ConstantesRotas.NACIONALIZACAO_CONSULTAR_EMBRIAO);
    } else if (this.router.url == `/${ConstantesRotas.NACIONALIZACAO_MANTER_EMBRIAO}`) {
      this.adicionarBreadcrumb('Consultar Registros de Nacionalização de Embrião', ConstantesRotas.NACIONALIZACAO_CONSULTAR_EMBRIAO);
      this.adicionarBreadcrumb('Registrar Nacionalização de Embrião', ConstantesRotas.NACIONALIZACAO_MANTER_EMBRIAO)
    } else if (this.router.url == `/${ConstantesRotas.LAUDO_DNA_MANTER}`) {
      this.adicionarBreadcrumb('Registrar Laudo DNA', ConstantesRotas.LAUDO_DNA_MANTER);
    }
    else if (this.router.url == `/${ConstantesRotas.LAUDO_DNA_CONSULTAR}`) {
      this.adicionarBreadcrumb('Consultar Laudo DNA', ConstantesRotas.LAUDO_DNA_CONSULTAR);
    }
    else if (this.router.url == `/${ConstantesRotas.PESAGEM_CONSULTAR}`) {
      this.adicionarBreadcrumb('Consultar Registros de Pesagem', ConstantesRotas.PESAGEM_CONSULTAR);
    }
    else if (this.router.url == `/${ConstantesRotas.PESAGEM_MANTER}`) {
      this.adicionarBreadcrumb('Consultar Registros de Pesagem', ConstantesRotas.PESAGEM_CONSULTAR);
      this.adicionarBreadcrumb('Comunicação de Pesagem', ConstantesRotas.PESAGEM_MANTER);
    }
    else if (this.router.url == `/${ConstantesRotas.INSPECAO_RELATORIO}`) {
      this.adicionarBreadcrumb('Consultar Animais a Inspecionar', ConstantesRotas.INSPECAO_RELATORIO);
    }
    else if (this.router.url == `/${ConstantesRotas.INSPECAO_MANTER}`) {
      this.adicionarBreadcrumb('Consultar Registros de Inspeção', ConstantesRotas.INSPECAO_CONSULTAR);
      this.adicionarBreadcrumb('Registrar Inspeção', ConstantesRotas.INSPECAO_MANTER);
    }
    this.breadcrumbList[this.breadcrumbList.length - 1].active = true;

  }

  adicionarBreadcrumb(label: string, rota: string) {
    this.breadcrumbList.push({ label: label, rota: rota });
  }

  onActivate() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

}

