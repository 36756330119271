<div class="row">

    <div class="form-group col-md-6 col-sm-6 col-xs-12" [ngClass]="{'col-lg-2': formConsulta.tipoUsuario != -1, 'col-lg-3': formConsulta.tipoUsuario == -1 }">
        <label for="formularioProtocolo">Tipo</label>
        <select class="form-control form-control-sm" name="tipoUsuario" [(ngModel)]="formConsulta.tipoUsuario" (change)="alterarTipoUsuario()">
            <option *ngFor="let tipoUsuario of tiposUsuario" [value]="tipoUsuario.value" [label]="tipoUsuario.label"></option>
        </select>
    </div>

    <div *ngIf="formConsulta.tipoUsuario != -1" class="form-group col-lg-4 col-md-6 col-sm-6 col-xs-12">
        <label for="formularioProtocolo">{{ formConsulta.tipoUsuario == 1? 'CPF' : 'CNPJ' }}</label>
        <input 
            type="text" 
            mask="{{ formConsulta.tipoUsuario == 1? '000.000.000-00' : '00.000.000/0000-00' }}" 
            class="form-control form-control-sm" 
            name="formularioProtocolo" 
            [(ngModel)]="formConsulta.cpf_cnpj"
        >
    </div>

    <div class="form-group col-md-6 col-sm-6 col-xs-12" [ngClass]="{'col-lg-2': formConsulta.tipoUsuario != -1, 'col-lg-3': formConsulta.tipoUsuario == -1 }">
        <label for="formularioProtocolo">Situação</label>
        <select class="form-control form-control-sm" name="situacao" [(ngModel)]="formConsulta.situacao">
          <option *ngFor="let situacaoEvento of situacoesEvento" [value]="situacaoEvento.value" [label]="situacaoEvento.label"></option>
        </select>
    </div>

    <div class="form-group col-md-6 col-sm-6 col-xs-12" [ngClass]="{'col-lg-2': formConsulta.tipoUsuario != -1, 'col-lg-3': formConsulta.tipoUsuario == -1 }">
        <label for="formularioProtocolo">Afixo</label>
        <input (keydown.enter)="consultar()" type="text" class="form-control form-control-sm" name="formularioProtocolo" [(ngModel)]="formConsulta.afixo">
    </div>

    <div class="form-group col-md-6 col-sm-6 col-xs-12" [ngClass]="{'col-lg-2': formConsulta.tipoUsuario != -1, 'col-lg-3': formConsulta.tipoUsuario == -1 }">
        <label for="formularioProtocolo">Código</label>
        <input (keydown.enter)="consultar()" type="text" class="form-control form-control-sm" name="formularioProtocolo" [(ngModel)]="formConsulta.codigo">
    </div>

    <div class="form-group col-lg-6 col-md-12 col-sm-12 col-xs-12">
        <label for="formularioProtocolo">Nome</label>
        <input (keydown.enter)="consultar()" type="text" class="form-control form-control-sm" name="formularioProtocolo" [(ngModel)]="formConsulta.nome">
    </div>
    

    <div *ngIf="env.contexto === 'jersey'" class="form-group col-lg-6 col-md-12 col-sm-12 col-xs-12">
        <app-selecionar-filiadas [filiadaId]="formConsulta.vinculacao" [readonly]="formConsulta.filiadaReadonly"  (filiadaSelecionada)="filiadaSelecionada($event)"></app-selecionar-filiadas>
    </div>
      
</div>