import { ConsultaSubmetidosModel } from "./ConsultaSubmetidosModel";

export class ConsultaOcorrenciasPorSituacaoModel extends ConsultaSubmetidosModel {
  EventoInt: number;
  SituacaoInt: number;

  constructor() {
    super()
    this.EventoInt = 0;
    this.SituacaoInt = 0;
  }
}