import { TipoConsultaCarcaca } from "../shared/enums/Enums";
import { BovinoModel } from "./BovinoModel";
import { CriadorModel } from "./CriadorModel";

export class FiltroConsultaEventosModel {
  formularioProtocolo: string;
  situacao: number;
  eventoDe: string;
  eventoAte: string;
  comunicacaoDe: string;
  comunicacaoAte: string;
  criador: CriadorModel;
  bovino: BovinoModel;
  ocorrenciaCarcaca: TipoConsultaCarcaca;

  constructor() {
    this.formularioProtocolo = "";
    this.situacao = 0;
    this.eventoDe = '';
    this.eventoAte = '';
    this.comunicacaoDe = '';
    this.comunicacaoAte = '';
    this.criador = new CriadorModel();
    this.bovino = new BovinoModel();
    this.ocorrenciaCarcaca = TipoConsultaCarcaca.Pos;
  }
}
