import { PerfilModel } from './PerfilModel';
export class ProcuradorModel extends PerfilModel{

  Procuradores : PerfilModel[];

  constructor(){
    super();
    this.Procuradores = new Array();
  }
}
