import { Component, OnInit, Input } from '@angular/core';
import { FiltroConsultaControleLeiteiro } from 'src/app/models/FiltroConsultaControleLeiteiroModel';
import { environment } from 'src/environments/environment';
import { SituacaoControleLeiteiro } from '../../shared/enums/Enums';

@Component({
  selector: 'app-filtro-consulta-controle-leiteiro',
  templateUrl: './filtro-consulta-controle-leiteiro.component.html',
  styleUrls: ['./filtro-consulta-controle-leiteiro.component.scss']
})
export class FiltroConsultaControleLeiteiroComponent implements OnInit {

  readonly env = environment;
  
  @Input() formConsulta: FiltroConsultaControleLeiteiro = new FiltroConsultaControleLeiteiro();
  
  situacoes: { value: number, label: string }[] = [];

  constructor() {}

  ngOnInit(): void {
    this.preencherEstados();
  }

  preencherEstados(){
    this.situacoes = [];
    for (let i=0 ; i<6 ; i++)
      if (i!==3) this.situacoes.push({ value: i, label: SituacaoControleLeiteiro[i] });
  }

}
