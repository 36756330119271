import { TipoSituacaoEventoAdministrativo } from "../shared/enums/Enums";
import { CriadorModel } from "./CriadorModel";

export class OcorrenciaEventoAdministrativoModel {
  Id: string;
  Criador: CriadorModel;
  Situacao: TipoSituacaoEventoAdministrativo;
  DataOcorrencia: string;
  Descricao: string;

  constructor() {
    this.Id = "-1";
    this.Situacao = TipoSituacaoEventoAdministrativo.Pendente;
    this.DataOcorrencia = '';
    this.Descricao = '';
    this.Criador = new CriadorModel();
  }
}