<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <i class="bi bi-x" color="primary"></i>
</button>

<h2 mat-dialog-title>Relação Pós-Abate</h2>

<div class="row">
  <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
    <label>*Ordem do Romaneio</label>
    <input [disabled]="form.Readonly" type="number" class="form-control form-control-sm" name="OrdemRomaneio" [(ngModel)]="form.OrdemRomaneio">
  </div>
  
  <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
    <label>*Nº de Dentes</label>
    <input [disabled]="form.Readonly" type="number" class="form-control form-control-sm" name="NumeroDentes" [(ngModel)]="form.NroDentes">
  </div>
  
  <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
    <label>*Marmoreio</label>
    <input [disabled]="form.Readonly" type="number" class="form-control form-control-sm" name="Marmoreio" [(ngModel)]="form.Marmoreio">
  </div>

  <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
    <label>*Peso da Carcaça</label>
    <div class="input-group">
      <input [disabled]="form.Readonly" class="form-control form-control-sm " name="PesoCarcaca" type="number" [(ngModel)]="form.PesoCarcaca" />
      <div class="input-group-append">
        <span class="input-group-text form-control-sm offset-md-0">Kg</span>
      </div>
    </div>
  </div>
  
  <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
    <label>*Cob. de Gordura</label>
    <select [disabled]="form.Readonly" class="form-control form-control-sm" name="CoberturaGordura" [(ngModel)]="form.CoberturaGordura">
      <option *ngFor="let item of itensCoberturaGordura" [value]="item.value" [label]="item.label"></option>
    </select>
  </div>

  <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
    <label>*Aprovação da Carcaça</label>
    <select [disabled]="form.Readonly" class="form-control form-control-sm" name="AprovacaoCarcaca" [(ngModel)]="form.AprovacaoCarcaca">
      <option *ngFor="let item of itensAprovacaoCarcaca" [value]="item.value" [label]="item.label"></option>
    </select>
  </div>
  
</div>

<div *ngIf="!form.Readonly" class="row justify-content-end">
  <div class="form-group col-lg-4 col-md-6 col-sm-12">
    <label for="consulta">&nbsp;</label>
    <button type="button" class="btn btn-sm btn-primary form-control form-control-sm" name="salvar" (click)="botaoSalvar()">Salvar</button>
  </div>
</div>