import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../shared/api.service';
import { AlterarSenhaModel } from '../../models/AlterarSenhaModel';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-alterar-senha',
  templateUrl: './alterar-senha.component.html',
  styleUrls: ['./alterar-senha.component.scss']
})
export class AlterarSenhaComponent implements OnInit {

  infoLogin: AlterarSenhaModel = new AlterarSenhaModel();

  constructor(
    private router: Router, 
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
  }

  botaoVoltar() {
    this.router.navigate(['']);
  }

  botaoAlterar() {
    const tamanhoValido = this.verificaTamanhoDasSenhas();

    if (tamanhoValido == null) {
      this.apiService.alterarSenha(this.infoLogin).then((result: any) => {
        if (result) {
          if (result.success) Swal.fire(result.message || 'A senha foi alterada com sucesso.', '', 'success');
          else Swal.fire(result.message, '', 'error');
          this.limparCampos();
        }
      });
    } else {
      Swal.fire(tamanhoValido, '', 'error');
      this.limparCampos();
    }
  }

  verificaTamanhoDasSenhas() {
    return (this.infoLogin.senhaNova.length < 8 || this.infoLogin.confirmacaoSenhaNova.length < 8) ? 'As senhas precisam ter mais que 8 caracteres.' : null;
  }

  limparCampos() {
    this.infoLogin.senhaAtual = '';
    this.infoLogin.senhaNova = '';
    this.infoLogin.confirmacaoSenhaNova = '';
  }

}
