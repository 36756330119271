import { PosicaoAfixo, TipoInspecao } from './../../../shared/enums/Enums';
import { AccountService } from 'src/app/account/shared/account.service';
import {
  Castrado,
  DestinoInspecao,
  SituacaoOcorrenciaEvento,
  SituacaoSRG,
  TipoGrauSangue,
  TipoRegistroInspecao,
} from 'src/app/shared/enums/Enums';
import { Commons } from 'src/app/shared/commons';
import { ApiService } from 'src/app/shared/api.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { BaseEventos } from 'src/app/eventos/base-eventos';
import { ConstantesTipoEvento } from 'src/app/shared/constantes';
import { DateAdapter } from '@angular/material/core';
import { MyDateAdapter } from 'src/app/shared/adapters/date-adapter';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { BovinoService } from 'src/app/bovino/shared/bovino.service';
import { BovinoModel } from 'src/app/models/BovinoModel';
import Swal from 'sweetalert2';
import { IFazenda, IResponseConsultaCriadorId } from 'src/app/shared/interfaces/Interfaces';
import { FazendaModel } from 'src/app/models/FazendaModel';
import { count, log } from 'console';
import { RolesKeys } from 'src/app/account/shared/RolesKeys';

@Component({
  selector: 'app-inspecao-manter',
  templateUrl: './inspecao-manter.component.html',
  styleUrls: ['./inspecao-manter.component.scss'],
  providers: [{ provide: DateAdapter, useClass: MyDateAdapter }],
})
export class InspecaoManterComponent extends BaseEventos implements OnInit {
  readonly env = environment;
  isReadOnly: boolean = false;
  contexto: string = (environment as any).contexto;
  grauSangue: { value: string; label: string }[] = [];
  grauSangueAvaliacaoCCG: { value: string; label: string }[] = [];
  grauSangueAvaliacaoPA: { value: string; label: string }[] = [];
  tiposGrauSangue: { value: number; label: string }[] = [];
  tiposGrauSangueAvaliacao: { value: number; label: string }[] = [];
  situacoesSRG: { value: number; label: string }[] = [];
  AptoAbate: boolean = false;

  constructor(
    private bovinoService: BovinoService,
    private activatedRoute: ActivatedRoute,
    public accountService: AccountService,
    public api: ApiService,
    public datePipe: DatePipe,
    public commons: Commons,
    public router: Router,
    public dialog: MatDialog
  ) {
    super(api, datePipe, commons, router, accountService, dialog);
    this.tipoEvento = ConstantesTipoEvento.INSPECAO;
    this.contexto = (environment as any).contexto;
  }

  ngOnInit(): void {
    this.novoEvento();
    this.activatedRoute.paramMap.subscribe(async params => {
      await this.iniciarTela(params).then(() => {
        if (this.ocorrenciaAtual.Animal.DataNascimento) {
          this.ocorrenciaAtual.DataNascimento = this.ocorrenciaAtual.Animal.DataNascimento;
        } else if (this.ocorrenciaAtual.DataNascimento) {
          this.ocorrenciaAtual.Animal.DataNascimento = this.ocorrenciaAtual.DataNascimento;
        }
        //this.ocorrenciaAtual.GrauSangue = this.atualizarGrauSanguePorTipo(TipoGrauSangue.PA);
        if (this.ocorrenciaAtual.Animal && this.ocorrenciaAtual.Animal.Id !== '-1') {
          //this.ocorrenciaAtual.GrauSangue = this.atualizarGrauSanguePorTipo(this.ocorrenciaAtual.TipoGrauSangue);


        }
        this.AutoSelectAvaliacao();
      });
    });
    this.tiposGrauSangue = this.bovinoService.getTiposGrauSangue();
    this.preencherSituacoesSRG();

    //this.limparDadosBovino();
    this.AptoAbate = false;
    this.ocorrenciaAtual.SituacaoSrg = SituacaoSRG['Registro Definitivo Emitido'];
    this.preencheInpsetor();
    this.isAdmin();

  }

  isEditMode(): boolean {
    return !(
      this.ocorrenciaAtual.Id === '' ||
      this.ocorrenciaAtual.Id === '-1' ||
      this.ocorrenciaAtual.Id === undefined
    );
  }

  carregarAnimaisParaInspecao() {
    var dataInspecao = '';

    if (this.lote) var idCriador = this.lote.Criador.Id;
    if (this.ocorrenciaAtual.DataInspecao != '')
      dataInspecao = this.datePipe.transform(this.ocorrenciaAtual.DataInspecao, 'dd/MM/yyyy') as string;
    if (this.ocorrenciaAtual.Inspetor.Id != '') {
      var idInspetor = this.ocorrenciaAtual.Inspetor.Id;
    }
    if (this.ocorrenciaAtual.DataNascimento != '')
      this.ocorrenciaAtual.DataNascimento = this.datePipe.transform(
        this.ocorrenciaAtual.DataInspecao,
        'dd/MM/yyyy'
      ) as string;

    this.api.carregarAnimaisParaInspecao(idCriador, dataInspecao, idInspetor).then((_: any) => { });
  }

  /*simplyformat(afixo: string = '', nomeAtual: string = '') {
    if (this.ocorrenciaAtual.Animal.NomeBovino != '' && !this.ocorrenciaAtual.Animal.NomeBovino.includes(afixo)) {
      this.ocorrenciaAtual.Animal.NomeBovino = nomeAtual + ' ' + afixo;
    }
  }*/

  AutoSelectAvaliacao() {
    //this.limparDadosBovino();
    this.tiposGrauSangue = this.bovinoService.getTiposGrauSangue();
    this.tiposGrauSangue.push({ value: TipoGrauSangue._12B, label: 'CCG' });
    this.tiposGrauSangue.push({ value: TipoGrauSangue._14B, label: 'CCG' });
    this.tiposGrauSangue.push({ value: TipoGrauSangue._16B, label: 'CCG' });
    this.tiposGrauSangue.push({ value: TipoGrauSangue._18B, label: 'CCG' });
    this.tiposGrauSangue.push({ value: TipoGrauSangue._34B, label: 'CCG' });
    this.tiposGrauSangue.push({ value: TipoGrauSangue._38A, label: 'CCG' });
    this.tiposGrauSangue.push({ value: TipoGrauSangue._38B, label: 'CCG' });
    this.tiposGrauSangue.push({ value: TipoGrauSangue._38P, label: 'CCG' });

    this.tiposGrauSangue.push({ value: TipoGrauSangue.PC1, label: 'PC' });
    this.tiposGrauSangue.push({ value: TipoGrauSangue.PC2, label: 'PC' });
    this.tiposGrauSangue.push({ value: TipoGrauSangue.PC3, label: 'PC' });
    this.tiposGrauSangue.push({ value: TipoGrauSangue.PC4, label: 'PC' });

    if (this.ocorrenciaAtual.TipoGrauSangue != null && this.ocorrenciaAtual.TipoGrauSangue != ""
      && this.ocorrenciaAtual.TipoGrauSangue != "-1" && this.ocorrenciaAtual.TipoGrauSangue != TipoGrauSangue.CCG
      && this.ocorrenciaAtual.TipoGrauSangue != TipoGrauSangue.PA) {
      this.atualizarGrauSanguePorTipo(this.ocorrenciaAtual.TipoGrauSangue);
    }

    //this.atualizarGrauSanguePorTipo(this.ocorrenciaAtual.TipoGrauSangue);
    if (this.tiposGrauSangueAvaliacao == null || this.tiposGrauSangueAvaliacao.length == 0) {
      this.tiposGrauSangueAvaliacao.push({ value: TipoGrauSangue.CCG, label: 'CCG' });
      this.tiposGrauSangueAvaliacao.push({ value: TipoGrauSangue.PA, label: 'PA' });
    }

    if (this.grauSangueAvaliacaoCCG == null || this.grauSangueAvaliacaoCCG.length == 0) {
      this.grauSangueAvaliacaoCCG.push({ value: '1/2', label: '1/2' });
      this.grauSangueAvaliacaoCCG.push({ value: '3/4', label: '3/4' });
      this.grauSangueAvaliacaoCCG.push({ value: '7/8', label: '7/8' });
    }
    if (this.grauSangueAvaliacaoPA == null || this.grauSangueAvaliacaoPA.length == 0) {
      this.grauSangueAvaliacaoPA.push({ value: '31/32', label: '31/32' });
    }

    //this.atualizarGrauSanguePorTipo(this.ocorrenciaAtual.TipoGrauSangue);
    //this.tiposGrauSangueAvaliacao = this.tiposGrauSangue.filter(grau => (grau.label == 'CCG'
    //  || grau.label == 'PA'));
    //this.ocorrenciaAtual.SituacaoSrg = SituacaoSRG['Registro Definitivo Emitido'];
    //this.atualizarGrauSanguePorTipo(this.ocorrenciaAtual.TipoGrauSangue);
    // this.ocorrenciaAtual.GrauSangue = this.grauSangue[0].value;
    //this.atualizarGrauSanguePorTipo(TipoGrauSangue['PC']);

    if (this.lote) {
      this.ocorrenciaAtual.Animal.IdProprietarioConsulta = this.lote.Criador.Id;
    }
  }

  limparDadosBovino(): void {
    this.ocorrenciaAtual.Mae.CodigoBovino = '';
    this.ocorrenciaAtual.Mae.NomeBovino = '';
    this.ocorrenciaAtual.Mae.Readonly = true;
    this.ocorrenciaAtual.Pai.CodigoBovino = '';
    this.ocorrenciaAtual.Pai.NomeBovino = '';
    this.ocorrenciaAtual.Pai.Readonly = true;

    this.ocorrenciaAtual.TipoGrauSangue = '';
    this.ocorrenciaAtual.OrelhaDireita = '';
    this.ocorrenciaAtual.OrelhaEsquerda = '';
    this.ocorrenciaAtual.DataNascimento = '';
    this.ocorrenciaAtual.Brinco = '';
    this.ocorrenciaAtual.Animal.Sexo = '-1';

    this.ocorrenciaAtual.Animal.NomeBovino = '';
    this.ocorrenciaAtual.Animal.DataNascimento = '';
    this.ocorrenciaAtual.Animal.OrelhaDireita = '';
    this.ocorrenciaAtual.Animal.Brinco = '';
    this.ocorrenciaAtual.Sexo = '-1';

    // Mae
    this.ocorrenciaAtual.Mae = {};

    // Pai
    this.ocorrenciaAtual.Pai = {};
  }

  atualizarGrauSanguePorTipo(tipo: number): void {
    this.grauSangue = this.bovinoService.getGrauSanguePorTipo(tipo, false);
    if (this.ocorrenciaAtual.TipoInspecao == 1) {
      this.grauSangue = this.grauSangue.filter((porcentagem) =>
        (porcentagem.value == '1/2' || porcentagem.value == '3/4' || porcentagem.value == '7/8' || porcentagem.value == '31/32'))
    }
    this.ocorrenciaAtual.GrauSangue = this.grauSangue[0].value;
  }

  preencherSituacoesSRG(): void {
    for (let i = 1; i <= 7; i++) {
      this.situacoesSRG.push({ value: i, label: SituacaoSRG[i] });
    }
  }

  atualizarDestinoInspecao() {
    // if (this.ocorrenciaAtual.Sexo == 1) {
    //   this.ocorrenciaAtual.Destino = 2
    // }

    // Caso animal não esteja apto para abate, destino = reprodução
    if (!this.ocorrenciaAtual.isCastrado) {
      if (parseInt(this.ocorrenciaAtual.AptoRegistro) === TipoRegistroInspecao['Sim']) {
        this.AptoAbate = true;
      } else {
        this.ocorrenciaAtual.Destino = DestinoInspecao['Reproducao'].toString();
        this.AptoAbate = false;
      }
    }
  }

  animalSelecionado(bovino: BovinoModel) {
    this.api.consultarDetalhesBovino(bovino.Id).then((result: any) => {
      if (result) {
        if (result.success) {
          this.ocorrenciaAtual.TipoGrauSangue = result.Bovino.TipoGrauSangue;
          this.atualizarGrauSanguePorTipo(result.Bovino.TipoGrauSangue);
          if ((this.env as any).contexto === 'jersey') {
            this.ocorrenciaAtual.OrelhaDireita = result.Bovino.OrelhaDireita;
          } else {
            this.ocorrenciaAtual.OrelhaEsquerda = result.Bovino.OrelhaDireita;
            this.ocorrenciaAtual.OrelhaDireita = result.Bovino.Codigo;
          }

          this.ocorrenciaAtual.SituacaoSrg = result.Bovino.SituacaoSrg;
          this.ocorrenciaAtual.Brinco = result.Bovino.Brinco;
          this.ocorrenciaAtual.DataNascimento = result.Bovino.DataNascimento;
          this.ocorrenciaAtual.Sexo = result.Bovino.Sexo;

          this.ocorrenciaAtual.Animal.DataNascimento = result.Bovino.DataNascimento;
          this.ocorrenciaAtual.Animal.OrelhaDireita = result.Bovino.OrelhaDireita;
          this.ocorrenciaAtual.Animal.SituacaoSrg = result.Bovino.SituacaoSrg;
          this.ocorrenciaAtual.Animal.Brinco = result.Bovino.Brinco;
          this.ocorrenciaAtual.Animal.Sexo = result.Bovino.Sexo;

          // Mae
          this.ocorrenciaAtual.Mae = { ...result.Bovino.Mae, Readonly: true };

          // Pai
          this.ocorrenciaAtual.Pai = { ...result.Bovino.Pai, Readonly: true };
        }
      } else {
        Swal.fire(result.Mensagem, '', 'error');
      }
    });

    if (!bovino || Object.keys(bovino).length === 0 || this.ocorrenciaAtual.TipoInspecao == 1) {
      this.limparDadosBovino();
    }
  }

  editar(evento: any) {
    super.editar(evento);
    if (this.lote.OcorrenciaAtual) {
      this.ocorrenciaAtual.Animal.DataNascimento = this.ocorrenciaAtual.DataNascimento;
      this.ocorrenciaAtual.Animal.NomeBovino = this.ocorrenciaAtual.NomeAnimal;
      //this.ocorrenciaAtual.NomeInspetor = this.ocorrenciaAtual.Inspetor.NomeInspetor;
      if (this.ocorrenciaAtual.Animal && this.ocorrenciaAtual.Animal.Id !== '-1') {
        this.atualizarGrauSanguePorTipo(this.ocorrenciaAtual.TipoGrauSangue);
      } else {
        this.AutoSelectAvaliacao();
      }
    }
  }

  inspetorSelecionado(codigoInspetor: string): void {
    this.lote.CodigoInspetor = codigoInspetor;
  }

  existeInspetor() {
    //verifica se o nome do inspetor esta preenchido na ococrrencia atual
    if (this.ocorrenciaAtual.NomeInspetor == null || this.ocorrenciaAtual.NomeInspetor == '') {
      return true;
    } else {
      return false;
    }
  }

  bloquearReproducaoCastrado() {
    if (
      this.ocorrenciaAtual.Castrado == Castrado.Sim &&
      (this.ocorrenciaAtual.TipoInspecao == 2 || this.ocorrenciaAtual.TipoInspecao == 1)
    ) {
      this.ocorrenciaAtual.Destino = DestinoInspecao.Abate;
      this.ocorrenciaAtual.isCastrado = true;
    } else if (this.ocorrenciaAtual.Castrado == Castrado.Nao) {
      this.ocorrenciaAtual.Destino = DestinoInspecao.Reproducao;
      this.ocorrenciaAtual.isCastrado = false;
    }
  }

  simplyformat(nomeAnimal: string = '') {
    // 1 - Limpa o nome do animal
    this.ocorrenciaAtual.Animal.NomeBovino = '';

    this.recuperarListaAfixosCriador(this.formulario.criador.codigo);
    if (this.lote.Criador && this.formulario.criador.afixo !== '') {
      let afixoBovino = this.formulario.criador.afixos.filter(
        afixo => afixo.codigo == this.formulario.criador.afixo && afixo.principal == true
      )[0];
      // se for PREFIXO => AFIXO + NOME + BRINCO + TIPO IMPLANTACAO
      if (afixoBovino.posicaoAfixo == PosicaoAfixo.Prefixo) {
        this.ocorrenciaAtual.Animal.NomeBovino =
          afixoBovino.codigo +
          ' ' +
          nomeAnimal
      } else {
        // se for SUFIXO => NOME + BRINCO + AFIXO + TIPO IMPLANTACAO
        this.ocorrenciaAtual.Animal.NomeBovino =
          nomeAnimal +
          ' ' +
          afixoBovino.codigo
      }
    } else {
      // Recupera o primeiro afixo/sufixo principal
      let afixoBovino = this.formulario.criador.afixo;
      // se for PREFIXO => AFIXO + NOME + BRINCO + TIPO IMPLANTACAO
      if (this.formulario.criador.posicaoAfixo == PosicaoAfixo.Prefixo) {
        this.ocorrenciaAtual.Nome =
          afixoBovino +
          ' ' +
          nomeAnimal
      } else {
        // se for SUFIXO => NOME + BRINCO + AFIXO + TIPO IMPLANTACAO
        this.ocorrenciaAtual.Nome =
          nomeAnimal +
          ' ' +
          afixoBovino
      }
    }
  }

  async recuperarListaAfixosCriador(codigo: string) {
    await this.api.consultarCriadorPorCodigo({ codigo, consultaEmTodasFiliadas: true }).then((result: IResponseConsultaCriadorId) => {
      if (result) {
        this.formulario.criador.afixos = result.AfixosCriador || [];
        this.formulario.criador.posicaoAfixo = result.PosicaoAfixo;
        this.formulario.criador.fazendas = result.Fazendas?.map(
          ({ Endereco, ...item }: IFazenda): FazendaModel => ({
            Id: item.Id,
            Nome: item.Nome,
            IdentificacaoFazenda: item.IdentificacaoFazenda,
            Endereco: {
              bairro: Endereco.Bairro,
              cep: Endereco.Cep,
              cidade: Endereco.Cidade,
              logradouro: Endereco.Logradouro,
              numero: Endereco.Numero,
              uf: Endereco.UF,
              complemento: Endereco.Complemento,
            },
          })
        )
      }
    });
  }

  bloqueiaData() {
    if (this.ocorrenciaAtual.DataInspecao) {
      return true;
    } else {
      return false;
    }
  }

  preencheInpsetor() {
    if (this.accountService.checkRole(RolesKeys.Inspetor)) {
      const token: any = this.accountService.getPayloadToken();
      if (token) {
        this.ocorrenciaAtual.Inspetor.Codigo = token.CodigoInspetor;
        this.ocorrenciaAtual.Inspetor.NomeInspetor = token.NomePessoaUsuario;
        this.ocorrenciaAtual.Inspetor.Id = token.UserId;
        this.ocorrenciaAtual.InspetorId = token.UserId;
      }
    }
  }

  atualizaOrelhaEsquerda() {
    this.ocorrenciaAtual.OrelhaEsquerda = this.ocorrenciaAtual.Brinco;
  }

  async criadorSelecionadoInsp(idcriador: string) {
    this.atualizarGrauSanguePorTipo(this.ocorrenciaAtual.TipoGrauSangue);
  }

  isAdmin() {
    if (this.isOcorrenciaReadOnly()) {
      if (this.accountService.isAdmin) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  }
} 
