import { AccountService } from 'src/app/account/shared/account.service';
import { EstacaoDeMonta, SituacaoOcorrenciaEvento } from 'src/app/shared/enums/Enums';
import { Commons } from 'src/app/shared/commons';
import { ApiService } from 'src/app/shared/api.service';
import { Component, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { BaseEventos } from 'src/app/eventos/base-eventos';
import { TipoPadreacao } from 'src/app/shared/enums/Enums';
import { ConstantesEventos, ConstantesTipoEvento } from 'src/app/shared/constantes';
import { DateAdapter } from '@angular/material/core';
import { MyDateAdapter } from 'src/app/shared/adapters/date-adapter';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-padreacao-manter',
  templateUrl: './padreacao-manter.component.html',
  styleUrls: ['./padreacao-manter.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MyDateAdapter }
  ],
})
export class PadreacaoManterComponent extends BaseEventos implements OnInit {
  isReadOnly: boolean = false;
  padreacao: string = 'padreacao';
  permite: boolean = false;
  compradorLogado: string = '';

  readonly env = environment;

  constructor(
    private activatedRoute: ActivatedRoute,
    public accountService: AccountService,
    public api: ApiService,
    public datePipe: DatePipe,
    public commons: Commons,
    public router: Router,
    public dialog: MatDialog
  ) {
    super(api, datePipe, commons, router, accountService, dialog);
    this.tipoEvento = ConstantesTipoEvento.PADREACAO;
  }

  ngOnInit(): void {
    this.novoEvento();
    this.activatedRoute.paramMap.subscribe(params => {
      this.iniciarTela(params).then(() => {
        this.showOcorrencias();
        this.isAdmin();
      });
    });
    this.estacaoDeMontaAlterada();
  }

  descTipoPadreacao(tipo: number) {
    let descricao = "";
    if (tipo == TipoPadreacao.InseminacaoArtifical) {
      descricao = "Inseminação Artificial";
    }
    else if (tipo == TipoPadreacao.MontaNatural) {
      descricao = "Monta Natural";
    }

    return descricao;
  }

  /*isTipoPadreacaoReadOnly(){
    if(this.ocorrenciaAtual.EstacaoDeMonta == EstacaoDeMonta.Sim){
      return true;
    }else{
      return false;
    }
    if(this.lote) {
      if(this.ocorrenciaAtual.Situacao === SituacaoOcorrenciaEvento.Pendente) {
        const idCriadorSelecionado = this.lote.Criador.Id;
        const idProprietarioReprodutorSelecionado = this.ocorrenciaAtual.Reprodutor.IdProprietario;
  
        if ((this.env as any).contexto === 'wagyu' && this.ocorrenciaAtual.EstacaoDeMonta == EstacaoDeMonta.Sim) {
          return true;
        }

        if (
          idCriadorSelecionado !== '-1' &&
          idCriadorSelecionado !== '' &&
          (idProprietarioReprodutorSelecionado !== '-1' &&
          idProprietarioReprodutorSelecionado !== '') &&
          idCriadorSelecionado === idProprietarioReprodutorSelecionado
        ) {
          return false;
        } else {
          this.ocorrenciaAtual.TipoPadreacao = 1;
          return true;
        }
      }
      return true;
    }
    return true;
  }*/

  isExibirMultaEDna() {
    if ((this.env as any).contexto === 'jersey') {
      if (this.lote) {
        if (this.ocorrenciaAtual.Situacao === SituacaoOcorrenciaEvento.AguardandoAprovacao ||
          this.ocorrenciaAtual.Situacao === SituacaoOcorrenciaEvento.AprovadoSuperintendente ||
          this.ocorrenciaAtual.Situacao === SituacaoOcorrenciaEvento.RejeitadoSuperintendente) {
          return true;
        }
      }
      return false;
    } else {
      this.ocorrenciaAtual.ImputaMulta = false;
      this.ocorrenciaAtual.SolicitarDna = false;
      return false;
    }
  }

  isMultaOuDnaReadOnly() {
    if (this.lote) {
      if (this.ocorrenciaAtual.Situacao === SituacaoOcorrenciaEvento.AguardandoAprovacao && this.accountService.isSuperintendente) {
        return false;
      }
    }
    return true;
  }

  matrizCadastradaSelecionada() {
    if (this.ocorrenciaAtual.MatrizCadastrada == 1) {//Sim
      this.ocorrenciaAtual.CodigoMatrizNaoCadastrada = "";
      this.ocorrenciaAtual.NomeMatrizNaoCadastrada = "";
    }
    else if (this.ocorrenciaAtual.MatrizCadastrada == 2) {//Não
      this.ocorrenciaAtual.Matriz.Id = "-1";
      this.ocorrenciaAtual.Matriz.CodigoBovino = "";
      this.ocorrenciaAtual.Matriz.NomeBovino = "";
      this.ocorrenciaAtual.Matriz.CodigoNomeProprietario = "";
    }
  }

  reprodutorCadastradoSelecionado() {
    if (this.ocorrenciaAtual.ReprodutorCadastrado == 1) {//Sim
      this.ocorrenciaAtual.CodigoReprodutorNaoCadastrado = "";
      this.ocorrenciaAtual.NomeReprodutorNaoCadastrado = "";
    }
    else if (this.ocorrenciaAtual.ReprodutorCadastrado == 2) {//Não
      this.ocorrenciaAtual.Reprodutor.Id = "-1";
      this.ocorrenciaAtual.Reprodutor.CodigoBovino = "";
      this.ocorrenciaAtual.Reprodutor.NomeBovino = "";
      this.ocorrenciaAtual.Reprodutor.CodigoNomeProprietario = "";
    }
  }

  estacaoDeMontaAlterada() {
    if (this.ocorrenciaAtual.EstacaoDeMonta == EstacaoDeMonta.Sim) {
      this.ocorrenciaAtual.TipoPadreacao = TipoPadreacao.MontaNatural;
    } else {
      this.ocorrenciaAtual.TipoPadreacao = TipoPadreacao.InseminacaoArtifical;
    }
  }
  readonly() {
    if (this.ocorrenciaAtual.EstacaoDeMonta == EstacaoDeMonta.Sim) {
      return true;
    } else {
      return false;
    }
  }

  isAdmin() {
    if (this.accountService.isAdmin) {
      this.permite = true;
    } else {
      this.permite = false;
    }
  }

  showOcorrencias() {
    let token: any = this.accountService.getPayloadToken();
    console.log(token)
    if (token) {
      this.compradorLogado = token.CriadorId;
    }
  }
}
