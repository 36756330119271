  <div class="row" style="margin-top: 1em;">
    <div class="col-lg-12 col-sm-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <h4 class="card-title">
                Dados do Formulário
              </h4>
            </div>
            <div class="col-6 text-right">
              <button *ngIf="formulario.id" type="button" class="btn btn-link btn-sm" style="margin-right: 5px;" (click)="comunicacoes()" ><i class="far fa-comments"></i> Comunicações</button>
              <button *ngIf="formulario.id" type="button" class="btn btn-link btn-sm" (click)="documentos()"><i class="far fa-folder"></i> Documentos</button>
            </div>
          </div>

          <div *ngIf="formulario.id"  class="row">

            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <label><b>Nº Formulário: </b>{{formulario.formulario}}</label>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <label><b>Nº Protocolo: </b>{{formulario.protocolo}}</label>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <label><b>Situação: </b>{{formulario.situacao}}</label>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <label><b>Data da Criação: </b>{{formulario.dataCriacao}}</label>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <label><b>Data Comunicação: </b>{{formulario.dataComunicacao}}</label>
            </div>
          </div>
          <div *ngIf="formulario.id"  class="row">
            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <label><b>Código Criador: </b>{{formulario.criador.codigo}}</label>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <label><b>Nome Criador: </b>{{formulario.criador.nome}}</label>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <label><b>Afixo: </b>{{formulario.criador.afixo}}</label>
            </div>

          </div>

          <!--
          <div class="row">

            <div class="form-group col-lg-2 col-md-4 col-sm-6 col-xs-12">
              <label for="formulario">Nº Formulário</label>
              <input type="text" class="form-control form-control-sm" name="formulario" [(ngModel)]="formulario.formulario" readonly>
            </div>

            <div class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <label for="protocolo">Nº Protocolo</label>
              <input type="text" class="form-control form-control-sm" name="protocolo" [(ngModel)]="formulario.protocolo" readonly>
            </div>

            <div class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <label for="situacao">Situação</label>
              <input type="text" class="form-control form-control-sm" name="situacao" [(ngModel)]="formulario.situacao" readonly>
            </div>

            <div class="form-group col-lg-2 col-md-4 col-sm-6 col-xs-12">
              <label for="dataCriacao">Data da Criação</label>
              <input type="text" class="form-control form-control-sm" name="dataCriacao" [(ngModel)]="formulario.dataCriacao" readonly>
            </div>

            <div class="form-group col-lg-2 col-md-4 col-sm-6 col-xs-12">
              <label for="dataComunicacao">Data Comunicação</label>
              <input type="text" class="form-control form-control-sm" name="dataComunicacao" [(ngModel)]="formulario.dataComunicacao" readonly>
            </div>

          </div>
          -->

          <div *ngIf="!formulario.id" class="row">
            <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
              <app-consultar-criador [criador]="formulario.criador" (criadorSelecionado)="selecionarCriador($event)" (triggerConsulta)="criadorSelecionadoInsp($event)"></app-consultar-criador>
            </div>
            <div class="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <label for="afixo">Afixo</label>
              <input type="text" class="form-control form-control-sm" name="afixo" [(ngModel)]="formulario.criador.afixo" readonly>
            </div>
          </div>
          
   
        </div>
      </div>
    </div>
  </div>