<div class="row">
    <div class="col-lg-12" style="margin-top: 1em;">
      <div class="card">
        <div class="card-body table-container">
          <h4 class="card-title">
            Consultar Formulários Submetidos
          </h4>

          <form>
            <div class="row">
              <div class="form-group col-lg-3 col-md-4 col-sm-12">
                <label for="periodo">Período</label>
                <select class="form-control form-control-sm" id="periodo" name="periodo" [(ngModel)]="formConsulta.periodo" (change)="periodoAlterado()">
                  <option value="0">Todos</option>
                  <option value="1">Esta Semana</option>
                  <option value="2">Este Mês</option>
                  <option value="3">Período Específico</option>
                </select>
              </div>

              <div class="form-group col-lg-3 col-md-4 col-sm-12">
                <label>De</label>
                <div class="input-group input-group-sm">
                  <input [disabled]="formConsulta.periodo != 3" class="form-control" [matDatepicker]="dpDe" name="dataInicial" [(ngModel)]="formConsulta.dataInicial"> 
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary" type="button" (click)="dpDe.open()"> <i class="bi bi-calendar3"></i> </button>
                  </div>
                </div>
                <mat-datepicker [startAt]="formConsulta.dataInicial" #dpDe></mat-datepicker>
              </div>

              <div class="form-group col-lg-3 col-md-4 col-sm-12">
                <label>Até</label>
                <div class="input-group input-group-sm">
                  <input [disabled]="formConsulta.periodo != 3" class="form-control" [matDatepicker]="dpAte" name="dataFinal" [(ngModel)]="formConsulta.dataFinal"> 
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary" type="button" (click)="dpAte.open()"> <i class="bi bi-calendar3"></i> </button>
                  </div>
                </div>
                <mat-datepicker [startAt]="formConsulta.dataFinal" color="primary" #dpAte></mat-datepicker>
              </div>

              
            </div>

            <div class="row">

              <div class="col-lg-7 col-md-8 col-sm-12">
                <app-consultar-criador [criador]="formConsulta.criador" ></app-consultar-criador>
              </div>
              
              <div class="form-group col-lg-5 col-md-4 col-xs-12">
                <app-selecionar-filiadas [filiadaId]="formConsulta.filiadaId" [readonly]="formConsulta.filiadaReadonly" (filiadaSelecionada) ="filiadaSelecionada($event)"></app-selecionar-filiadas>
              </div>

            </div>

            <div class="row justify-content-end">
              <div class="col text-right">
                <button type="button" class="btn btn-sm btn-primary" (click)="consultar()" >Buscar</button>
              </div>
            </div>
          </form>

          <div class="row">
            <div class="col-lg-12">
          
              <table class="table table-hover table-header-custom">
                <thead>
                  <tr>
                    <th>Código</th>
                    <th>Tipo</th>
                    <th *ngIf="exibirColuna('Todas')" class="text-center">Todas</th>
                    <th *ngIf="exibirColuna('Pendente')" class="text-center">Pendente</th>
                    <th *ngIf="exibirColuna('Cancelado')" class="text-center">Cancelado</th>
                    <th *ngIf="exibirColuna('Aprovado')" class="text-center">Aprovado</th>
                    <th *ngIf="exibirColuna('Aprovado com Pendências')" class="text-center">Aprovado com Pendências</th>
                    <th *ngIf="exibirColuna('Rejeitado')" class="text-center">Rejeitado</th>
                    <th *ngIf="exibirColuna('Aguardando Resolução')" class="text-center">Aguardando Resolução</th>
                    <th *ngIf="exibirColuna('Aguardando Aprovação')" class="text-center">Aguardando Aprovação</th>
                    <th *ngIf="exibirColuna('Aguardando Aprovação Veterinário')" class="text-center">Aguardando Aprovação Veterinário</th>
                    <th *ngIf="exibirColuna('Aprovado Pelo Superintendente')" class="text-center">Aprovado Pelo Superintendente</th>
                    <th *ngIf="exibirColuna('Rejeitado Pelo Superintendente')" class="text-center">Rejeitado Pelo Superintendente</th>
                    <th *ngIf="exibirColuna('Rejeitado Pelo Veterinário')" class="text-center">Rejeitado Pelo Veterinário</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let evento of dataSourceSubmetidos let i = index">
                    <td>
                      <span *ngIf="evento.tipoIcon === 'material'" class="material-icons">{{evento.icon}}</span>
                      <mat-icon *ngIf="evento.tipoIcon === 'svg'" svgIcon="{{evento.icon}}"></mat-icon>
                    </td>
                    <td>{{evento.tipo}}</td>
                    <td class="text-center"
                      [ngClass]="{ 'custom-td-hover': evento.todas > 0  }" 
                      *ngIf="exibirColuna('Todas')" 
                      (click)="itemSelecionado(evento, evento.todas, 'Todas')"
                    >
                      {{evento.todas > 0 ? evento.todas : ''}}
                    </td>
                    <td class="text-center"
                      [ngClass]="{ 'custom-td-hover': evento.pendente > 0  }" 
                      *ngIf="exibirColuna('Pendente')" 
                      (click)="itemSelecionado(evento, evento.pendente, 'Pendente')"
                    >
                      {{evento.pendente > 0 ? evento.pendente : ''}}
                    </td>
                    <td class="text-center"
                      [ngClass]="{ 'custom-td-hover': evento.cancelado > 0  }" 
                      *ngIf="exibirColuna('Cancelado')" 
                      (click)="itemSelecionado(evento, evento.cancelado, 'Cancelado')"
                    >
                      {{evento.cancelado > 0 ? evento.cancelado : ''}}
                    </td>
                    <td class="text-center"
                      [ngClass]="{ 'custom-td-hover': evento.aprovado > 0  }" 
                      *ngIf="exibirColuna('Aprovado')" 
                      (click)="itemSelecionado(evento, evento.aprovado, 'Aprovado')"
                    >
                      {{evento.aprovado > 0 ? evento.aprovado : ''}}
                    </td>
                    <td class="text-center"
                      [ngClass]="{ 'custom-td-hover': evento.aprovadoComPendencias > 0  }" 
                      *ngIf="exibirColuna('Aprovado com Pendências')" 
                      (click)="itemSelecionado(evento, evento.aprovadoComPendencias, 'Aprovado com Pendências')"
                    >
                      {{evento.aprovadoComPendencias > 0 ? evento.aprovadoComPendencias : ''}}
                    </td>
                    <td class="text-center"
                      [ngClass]="{ 'custom-td-hover': evento.rejeitado > 0  }" 
                      *ngIf="exibirColuna('Rejeitado')" 
                      (click)="itemSelecionado(evento, evento.rejeitado, 'Rejeitado')"
                    >
                      {{evento.rejeitado > 0 ? evento.rejeitado : ''}}
                    </td>
                    <td class="text-center"
                      [ngClass]="{ 'custom-td-hover': evento.aguardandoResolucao > 0  }" 
                      *ngIf="exibirColuna('Aguardando Resolução')" 
                      (click)="itemSelecionado(evento, evento.aguardandoResolucao, 'Aguardando Resolução')"
                    >
                      {{evento.aguardandoResolucao > 0 ? evento.aguardandoResolucao : ''}}
                    </td>
                    <td class="text-center"
                      [ngClass]="{ 'custom-td-hover': evento.aguardandoAprovacao > 0  }" 
                      *ngIf="exibirColuna('Aguardando Aprovação')" 
                      (click)="itemSelecionado(evento, evento.aguardandoAprovacao, 'Aguardando Aprovação')"
                    >
                      {{evento.aguardandoAprovacao > 0 ? evento.aguardandoAprovacao : ''}}
                    </td>
                    <td class="text-center"
                      [ngClass]="{ 'custom-td-hover': evento.aguardandoAprovacaoVeterinario > 0  }" 
                      *ngIf="exibirColuna('Aguardando Aprovação Veterinário')" 
                      (click)="itemSelecionado(evento, evento.aguardandoAprovacaoVeterinario, 'Aguardando Aprovação Veterinário')"
                    >
                      {{evento.aguardandoAprovacaoVeterinario > 0 ? evento.aguardandoAprovacaoVeterinario : ''}}
                    </td>
                    <td class="text-center"
                      [ngClass]="{ 'custom-td-hover': evento.aprovadoPeloSuperintendente > 0  }" 
                      *ngIf="exibirColuna('Aprovado Pelo Superintendente')" 
                      (click)="itemSelecionado(evento, evento.aprovadoPeloSuperintendente, 'Aprovado Pelo Superintendente')"
                    >
                      {{evento.aprovadoPeloSuperintendente > 0 ? evento.aprovadoPeloSuperintendente : ''}}
                    </td>
                    <td class="text-center"
                      [ngClass]="{ 'custom-td-hover': evento.rejeitadoPeloSuperintendente > 0  }" 
                      *ngIf="exibirColuna('Rejeitado Pelo Superintendente')" 
                      (click)="itemSelecionado(evento, evento.rejeitadoPeloSuperintendente, 'Rejeitado Pelo Superintendente')"
                    >
                      {{evento.rejeitadoPeloSuperintendente > 0 ? evento.rejeitadoPeloSuperintendente : ''}}
                    </td>
                    <td class="text-center"
                      [ngClass]="{ 'custom-td-hover': evento.rejeitadoPeloVeterinario > 0  }" 
                      *ngIf="exibirColuna('Rejeitado Pelo Veterinário')" 
                      (click)="itemSelecionado(evento, evento.rejeitadoPeloVeterinario, 'Rejeitado Pelo Veterinário')"
                    >
                      {{evento.rejeitadoPeloVeterinario > 0 ? evento.rejeitadoPeloVeterinario : ''}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>