import { EnderecoModel } from "./EnderecoModel";
import { IdentificacaoFazenda } from "./IdentificacaoFazenda";

export class FazendaModel {
  Id: string;
  Nome: string;
  IdentificacaoFazenda: IdentificacaoFazenda;
  Endereco: EnderecoModel;

  constructor() {
    this.Id = "-1";
    this.Nome = "";
    this.Endereco = new EnderecoModel();
    this.IdentificacaoFazenda = new IdentificacaoFazenda();
  }
}