import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FiltroConsultaUsuariosModel } from 'src/app/models/FiltroConsultaUsuariosModel';
import { AccountService } from './../../account/shared/account.service';
import { environment } from './../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/shared/local-storage.service';
import { QueryStateService } from 'src/app/shared/query-state.service';

@Component({
  selector: 'app-filtro-consulta-usuario',
  templateUrl: './filtro-consulta-usuario.component.html',
  styleUrls: ['./filtro-consulta-usuario.component.scss']
})

export class FiltroConsultaUsuarioComponent implements OnInit {

  @Output() triggerConsulta: EventEmitter<void> = new EventEmitter<void>();
  @Input() formConsulta: FiltroConsultaUsuariosModel = new FiltroConsultaUsuariosModel();
  
  gruposUsuario: {value: string, label: string}[] = [];
  situacoes: {value: number, label: string}[] = [];
  contexto: string = 'jersey';

  constructor(
    private accountService: AccountService,
    private route: ActivatedRoute, 
    private localStorage: LocalStorageService,
    private queryState : QueryStateService
  ) { 
    this.contexto = (environment as any).contexto;
  }

  ngOnInit(): void {
    this.preencherGruposUsuario();
    this.preencherSituacoes();

    this.formConsulta.filiadaReadonly = !this.accountService.isSuperAdmin;
    if ((environment as any).contexto == 'wagyu'){
      this.formConsulta.vinculacao = 'PJ_ACGWB' 
    } 
    this.recuperarFormulario();
    this.consultar();
  }

  preencherGruposUsuario(){
    const isJersey = (environment as any).contexto === 'jersey';

    this.gruposUsuario = [];
    this.gruposUsuario.push({value: "-1", label: "Todos"});
    this.gruposUsuario.push({value: "GR_SRG_INSPETOR", label: `SRG Inspetor ${isJersey? '': ' de Registro'}`});
    this.gruposUsuario.push({value: "GR_SRG_JERSEY_ADMIN", label: `SRG ${isJersey? 'Jersey': 'Wagyu'} Administrador`});
    this.gruposUsuario.push({value: "GR_SRG_SUPERINTENDENTE", label: "SRG Superintendente"});
    this.gruposUsuario.push({value: "GR_SRG_VETERINARIO", label: "SRG Veterinário"});
    this.gruposUsuario.push({value: "GR_SRG_TECNICO", label: `SRG Técnico ${isJersey? '': ' de Abate'}`});
    this.gruposUsuario.push({value: "GR_SRG_PROCURADOR", label: "SRG Procurador"});

  }

  preencherSituacoes(){
    this.situacoes = [];
    this.situacoes.push({value: -1, label: "Todas"});
    this.situacoes.push({value: 1, label: "Ativo"});
    this.situacoes.push({value: 2, label: "Inativo"});
  }

  filiadaSelecionada(idFiliada: string){
    this.formConsulta.vinculacao = idFiliada;
  }

  consultar(){
    this.queryState.saveFormUsuario(this.formConsulta);
    //obtem a rota da página atual
    this.queryState.getRota(this.route.snapshot.url[0].path);
    this.triggerConsulta.emit();
  }

  private recuperarFormulario() {
    if(this.localStorage.get("rotaAnterior") == this.route.snapshot.url[0].path){
      // Recupera o state passado pela função de consultas do painel gerencial através da rota
      let state = history.state;

      // Recupera o state da última busca do localStorage
      const queryState = this.localStorage.get("formConsultaUsuario");
      
      // Caso não haja state do form salvo, inicializa vazio
      if (!state && !queryState) return;

      // Caso não haja state passado pelo painel gerencial, mas exista um form salvo para essa consulta no storage
      Object.assign(state, queryState);

      this.formConsulta.codigo = state.Codigo;
      this.formConsulta.cpf = state.Cpf;
      this.formConsulta.nome = state.Nome;
      this.formConsulta.grupo = state.Grupo;
      this.formConsulta.situacao = state.Situacao;
      this.formConsulta.vinculacao = state.Vinculacao;
      
      this.triggerConsulta.emit();

    }else{
      this.localStorage.remove("formConsultaUsuario");
      this.localStorage.remove("page");
    }
  }
}
