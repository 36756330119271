import { environment } from "src/environments/environment";
import { TipoRegistroReservado } from "../shared/enums/Enums";

export class ReservarRegistrosAssociacaoModel {
  FiliadaId: string;
  Tipo: TipoRegistroReservado;
  Quantidade: string;

  constructor()  {
    this.FiliadaId = (environment as any).contexto === 'jersey'? '' : 'PJ_ACGWB';
    this.Tipo = TipoRegistroReservado.None;
    this.Quantidade = '';
  }
}