import { EnumTipoEventoSRG, SituacaoOcorrenciaEvento } from 'src/app/shared/enums/Enums';

export class FiltroConsultaEventosBovinoModel {
  tipo: EnumTipoEventoSRG;
  situacao: SituacaoOcorrenciaEvento;
  eventoDe: string;
  eventoAte: string;
  
  constructor() {
    this.tipo = -1;
    this.situacao = -1;
    this.eventoDe = "";
    this.eventoAte = "";
  }
}