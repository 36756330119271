<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <i class="bi bi-x" color="primary"></i>
</button>

<h2 mat-dialog-title>Consultar Laudo de DNA</h2>

<div class="row">
  <div class="form-group col-lg-4 col-md-6 col-sm-6 col-xs-12">
    <label for="formularioProtocolo">Número do laudo</label>
    <input type="text" class="form-control form-control-sm" name="formularioProtocolo" [(ngModel)]="formConsulta.NumeroLaudo">
  </div>

  <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
    <label for="formularioProtocolo">Qualifica</label>
    <select class="form-control form-control-sm" name="qualifica" [(ngModel)]="formConsulta.Qualifica">
      <option *ngFor="let qualifica of listaQualifica" [value]="qualifica.value" [label]="qualifica.label"></option>
    </select>
  </div>

  <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
    <label for="formularioProtocolo">Sexo</label>
    <select class="form-control form-control-sm" name="sexo" [(ngModel)]="formConsulta.Sexo">
      <option value="-1" label="Todos"></option>
      <option value="1" label="Macho"></option>
      <option value="2" label="Fêmea"></option>
    </select>
  </div>
    
  <div class="form-group col-lg-2 col-md-2 col-sm-12">
    <label for="consulta">&nbsp;</label>
    <button type="button" class="btn btn-sm btn-primary form-control form-control-sm" name="consulta" (click)="botaoBuscar()">Buscar</button>
  </div>
</div>

<div class="row" *ngIf="laudos.length > 0">
  <div class="col-lg-12 table-responsive">
    <table class="table table-hover table-header-custom">
      <thead>
        <tr>
          <th>Número laudo</th>
          <th>Pai</th>
          <th>Mãe</th>
          <th>Qualifica</th>
          <th class="text-center">Selecionar</th>
        </tr>
      </thead>
      <tbody>
        <tr 
          style="cursor: pointer;" 
          (click)="selecionarLaudo(laudo)"
          *ngFor="let laudo of laudos | paginate : { itemsPerPage: pageSize, currentPage: page, totalItems: count };"
        >
          <td>{{ laudo.NumeroLaudo }}</td>
          <td>{{ (laudo.Pai.CodigoBovino || '') + ' - ' + (laudo.Pai.NomeBovino || '') }}</td>
          <td>{{ (laudo.Mae.CodigoBovino || '') + ' - ' + (laudo.Mae.NomeBovino || '') }}</td>
          <td>{{ getDescricaoQualifica(laudo.Qualifica) }}</td>
          <td class="text-center">
            <a><i class="bi bi-check icone-selecao"></i></a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="col-md-12 text-center">
    <pagination-controls 
      class="srg-paginacao" 
      previousLabel="Anterior" 
      nextLabel="Próximo" 
      [responsive]="true"
      (pageChange)="handlePageChange($event)" 
      [autoHide]="true"
    >
    </pagination-controls>
  </div>
</div>