import { CriadorModel } from './../../models/CriadorModel';
import { ApiService } from './../api.service';
import { ConsultarCriadorModalComponent } from './../consultar-criador-modal/consultar-criador-modal.component';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { IFazenda, IResponseConsultaCriadorId } from '../interfaces/Interfaces';

@Component({
  selector: 'app-consultar-criador',
  templateUrl: './consultar-criador.component.html',
  styleUrls: ['./consultar-criador.component.scss']
})
export class ConsultarCriadorComponent implements OnInit {

  @Input() apenasFrigorifico: boolean | null = null;
  @Input() buscarPorCodigo: boolean = true;
  @Input() mostrarLabelNome: boolean = true;
  @Input() recuperarListaAfixos: boolean = false;
  @Input() criador: CriadorModel = new CriadorModel();
  @Input() criadorFrigorifico: any;
  @Input() identificacaoFrigorifico: string = '';
  @Output() criadorSelecionado = new EventEmitter<string>();
  @Output() triggerConsulta: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private api: ApiService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    try {
      if (this.criadorFrigorifico.NomeCriador != null && this.criadorFrigorifico.NomeCriador != '' && this.criadorFrigorifico.NomeCriador != undefined) {
        this.criador = this.criadorFrigorifico;
        this.criador.codigo = this.criadorFrigorifico.CodigoCriador;
        this.consultarPorCodigo();
      }
    } catch { }

  }

  limpar() {
    this.criador.id = "";
    this.criador.nome = "";
    this.criador.codigo = "";
    this.criador.afixo = "";
    this.criador.afixos = [];
    this.criadorSelecionado.emit("");
  }

  consultarPorCodigo() {
    this.api.consultarCriadorPorCodigo(this.criador).then((result: any) => {
      if (result) {
        if (result.Status === 0) {
          if (this.apenasFrigorifico && result.IsFrigorifico == false) {
            this.limpar();
            Swal.fire('Informe um frigorífico válido.', '', 'error');
          } else {
            this.criador.id = result.Id;
            this.criador.nome = result.NomeCriador;
            this.criador.afixo = result.AfixoCriador;
            this.criador.afixos = result.AfixosCriador;
            this.criador.racas = result.RacasCriador;

            this.criador.fazendas = result.FazendasCriador?.filter((item: IFazenda) => ({
              Id: item.Id,
              Nome: item.Nome,
              IdentificacaoFazenda: item.IdentificacaoFazenda,
            }));

            this.criadorSelecionado.emit(this.criador.id);
            this.triggerConsulta.emit(this.criador.id);
          }

        } else {
          this.limpar();
          Swal.fire(result.Mensagem, '', 'error');
        }
      }
    })
  }

  consultarPorNome() {
    const dialogRef = this.dialog.open(
      ConsultarCriadorModalComponent,
      {
        height: '90%',
        width: '90%',
        data: { tituloConsulta: this.criador.tituloConsulta, apenasFrigorifico: this.apenasFrigorifico }
      }
    );

    dialogRef.componentInstance.criadorSelecionado.subscribe(async (result: any) => {
      if (result) {
        this.criador.id = result.CriadorId;
        this.criador.usuarioId = result.Id;
        this.criador.nome = result.Nome;
        this.criador.codigo = result.Codigo;
        this.criador.afixo = result.Afixo;

        this.consultarPorCodigo();

        if (this.recuperarListaAfixos)
          await this.recuperarListaAfixosCriador(result.Id || '-1');

        this.criadorSelecionado.emit(this.criador.id);
      }
    });

  }

  private async recuperarListaAfixosCriador(id: string): Promise<void> {
    const result: IResponseConsultaCriadorId = await this.api.recuperarCriadorPorId(id);
    if (result && result.success) {
      this.criador.afixos = result.AfixosCriador;
    } else {
      Swal.fire(result.message || 'Não foi possível recuperar os afixos do criador.', '', 'error');
    }
  }
}


