import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AccountService } from 'src/app/account/shared/account.service';
import { RolesKeys } from 'src/app/account/shared/RolesKeys';
import { OcorrenciaEventoApropriacaoProcuradorModel } from 'src/app/models/OcorrenciaEventoApropriacaoProcuradorModel';
import { ApiService } from 'src/app/shared/api.service';
import { Commons } from 'src/app/shared/commons';
import { TipoSituacaoEventoAdministrativo } from 'src/app/shared/enums/Enums';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-apropriar-procurador',
  templateUrl: './apropriar-procurador.component.html',
  styleUrls: ['./apropriar-procurador.component.scss']
})
export class ApropriarProcuradorComponent implements OnInit {

  tabSelected = new FormControl(0);

  page = 1;
  count = 0;
  pageSize = environment.pageSize;
  ocorrencias: any[] = [];

  readOnly: boolean = false;
  permiteEdicao: boolean = false;
  formulario: OcorrenciaEventoApropriacaoProcuradorModel = new OcorrenciaEventoApropriacaoProcuradorModel();

  constructor(
    private accountService: AccountService,
    private api: ApiService,
    private commons: Commons,
  ) { }

  ngOnInit(): void {
    this.preencherCriadorLogado();
    this.permiteEdicao = this.accountService.checkRole(RolesKeys.InspecaoApropriarTecnicoEditar);
  }

  private preencherCriadorLogado() {
    let token: any = this.accountService.getPayloadToken();

    if(token) {
      this.formulario.Criador.id = token.CriadorId;
      this.formulario.Criador.codigo = token.CodigoCriador;
      this.formulario.Criador.nome = token.NomeCriador;
      this.formulario.Criador.afixo = token.AfixoCriador;
      this.formulario.Criador.readonly = this.accountService.isCriador;
    }
  }

  botaoSalvar() {
    this.api.manterApropriacaoProcurador(this.formulario).then((result: any) => {
      if (result) {
        if (result.success) {
          this.botaoReset();
          this.tabChanged(0);
          this.botaoConsultar();
        }
        Swal.fire(result.message, '', result.success? 'success' : 'error');
      } else {
        Swal.fire('Não foi posssível realizar a operação.', '', 'error');
      }
    });
  }
  
  botaoConsultar() {
    this.api.consultarApropriacaoProcurador(this.formulario, this.page, this.pageSize).then((result: any) => {
      if (result && result.success) {
        this.ocorrencias = result.Itens;
        this.count = result.TotalItens;
      } else {
        this.page = 1;
        this.count = 0;
        this.ocorrencias = [];
        Swal.fire(result.message || 'Não foi posssível realizar a operação.', '', 'error');
      }
    });
  }
  
  botaoReset() {
    this.formulario = new OcorrenciaEventoApropriacaoProcuradorModel();
    this.preencherCriadorLogado();
  }
  
  handlePageChange(event: number): void {
    this.page = event;
    this.botaoConsultar();
  }

  getDescrSituacao(situacao: TipoSituacaoEventoAdministrativo): string {
    switch (situacao) {
      case TipoSituacaoEventoAdministrativo.Ativo: return 'Ativo';
      case TipoSituacaoEventoAdministrativo.Inativo: return 'Inativo';
      case TipoSituacaoEventoAdministrativo.Pendente: return 'Pendente';
      default: return '';
    }
  }

  async inativarOcorrencia(id: string): Promise<void> {
    const response = await this.commons.modalConfirmacao({
      title: 'Você tem certeza que deseja desativar esta ocorrência?'
    });

    if (!response) return;

    this.api.inativarOcorrenciaEventoAdministrativo(id).then(({ Data: result }: any) => {
      if (result && result.success) {
        Swal.fire(result.message, '', 'success');
        this.botaoConsultar();
      } else {
        Swal.fire(result.message || 'Não foi possível realizar a operação.', '', 'error');
      }
    });
  }

  editar(evento: any) {    
    Object.assign(this.formulario, evento);

    this.formulario.PeriodoVigencia.De = evento.InicioVigencia;
    this.formulario.PeriodoVigencia.Ate = evento.FimVigencia;
    this.formulario.Criador.id = evento.Criador.Id;
    this.formulario.Criador.nome = evento.Criador.NomeCriador;
    this.formulario.Criador.codigo = evento.Criador.CodigoCriador;
    
    this.tabChanged(1);
  }

  isEdicao = (): boolean => this.tabSelected.value !== 0;
  
  tabChanged = (event: any): void => this.tabSelected.setValue(event);

}
