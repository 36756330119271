import { DestinoInspecao } from './../shared/enums/Enums';
import { 
  Sexo, 
  Origem, 
  SituacaoSRG, 
  Nacionalizado, 
  TipoBuscaBovino, 
  AnimalDeCentral, 
  EnumTipoEventoSRG,
  SituacaoEntidade,
  EvolucaoPCParaPO,
  TipoMotivoBaixa, 
} from "src/app/shared/enums/Enums";
import { FazendaModel } from "./FazendaModel";
import { SangueBovinoModel } from "./SangueBovinoModel";

export class BovinoModel {
  Id: string;
  CodigoBovino: string;
  NomeBovino: string;
  SexoBovino: Sexo;
  IdProprietario: string;
  CodigoNomeProprietario: string;
  Readonly: boolean;
  TituloConsulta: string;
  TipoBusca: TipoBuscaBovino;
  ConsideraInativos: boolean;
  CodigoCentral: string;
  IdProprietarioConsulta: string;
  tipoEvento: EnumTipoEventoSRG;
  ConsultaAnimalTodosCriadores: boolean;
  OrelhaDireita: string;
  SituacaoBovino: SituacaoEntidade;
  SituacaoSRGBovino: SituacaoSRG;
  DataNascimentoBovino: string;
  RacaBovino: string;
  SangueBovino: SangueBovinoModel;
  AnimalDeCentral: AnimalDeCentral;
  Nacionalizado: Nacionalizado;
  OrigemBovino: Origem;
  PropriedadeNascimento: FazendaModel;
  PesoAoNascer: number | string;
  Brinco: string;
  EvolucaoPCParaPO: EvolucaoPCParaPO;
  AvalacaoGenealogicaId: string;
  MotivoBaixaAnimal: TipoMotivoBaixa;
  DestinoInspecao: DestinoInspecao;

  constructor() {
    this.Id = "-1";
    this.CodigoBovino = "";
    this.NomeBovino = "";
    this.SexoBovino = Sexo.Todos;
    this.IdProprietario = "";
    this.CodigoNomeProprietario = "";
    this.Readonly = false;
    this.CodigoCentral = "";
    this.TituloConsulta = "Animal";
    this.TipoBusca = TipoBuscaBovino.Todos;
    this.ConsideraInativos = true;
    this.IdProprietarioConsulta = "";
    this.tipoEvento = EnumTipoEventoSRG.Todos;
    this.ConsultaAnimalTodosCriadores = false;
    this.OrelhaDireita = "";
    this.SituacaoBovino = SituacaoEntidade.Ativo;
    this.Nacionalizado = Nacionalizado.Não;
    this.AnimalDeCentral = AnimalDeCentral.Não;
    this.SituacaoSRGBovino = SituacaoSRG["Registro Definitivo Emitido"];
    this.RacaBovino = "-1";
    this.DataNascimentoBovino = "";
    this.OrigemBovino = -1;
    this.SangueBovino = new SangueBovinoModel();
    this.PropriedadeNascimento = new FazendaModel();
    this.PesoAoNascer = '';
    this.Brinco = '';
    this.EvolucaoPCParaPO = EvolucaoPCParaPO.Nao;
    this.AvalacaoGenealogicaId = '-1';
    this.MotivoBaixaAnimal = TipoMotivoBaixa.SISTEMA;
    this.DestinoInspecao = DestinoInspecao.None;
  }
}