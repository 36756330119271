import { environment } from "src/environments/environment";
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ValidaCpfUsuario, UsuarioModel } from 'src/app/models/UsuarioModel';
import { ApiService } from 'src/app/shared/api.service';
import { UnidadesFederativas } from 'src/app/shared/enums/Enums';
import { IJsonResultModel, IValidaUsuario } from 'src/app/shared/interfaces/Interfaces';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-usuarios-manter',
  templateUrl: './usuarios-manter.component.html',
  styleUrls: ['./usuarios-manter.component.scss']
})
export class UsuariosManterComponent implements OnInit {

  labelGrupoUsuario: string = "";
  grupoAdmJersey: boolean = false;
  formRegistrar: UsuarioModel = new UsuarioModel();
  situacoes: { value: number, label: string }[] = [];
  readOnly: boolean = false;

  @Input() usuarioExistente: boolean = false;
  @Input() usuarioValidacao: IJsonResultModel<IValidaUsuario> = {} as IJsonResultModel<IValidaUsuario>;
  @Input() formCpfValido: ValidaCpfUsuario = new ValidaCpfUsuario();

  contexto: string = (environment as any).contexto;
  Action: string | null = 'Create';

  constructor(
    private router: Router,
    private api: ApiService,
    private route: ActivatedRoute
  ) {
    this.contexto = (environment as any).contexto;
  }

  ngOnInit(): void {
    const action_aux = this.route.snapshot.queryParamMap.get('Action');
    if (action_aux !== null) {
      this.Action = action_aux;
    }

    if (this.Action == 'Create') {
      if (
        this.formCpfValido.cpf === '' ||
        this.formCpfValido.grupo === '' ||
        this.formCpfValido.vinculacao === ''
      )
        this.router.navigate(['registrar_usuario']);

      this.definirLabelGrupo();

      if (this.usuarioExistente && this.usuarioValidacao.Data) {
        this.formRegistrar.email = this.usuarioValidacao.Data.Email;
        this.formRegistrar.nome = this.usuarioValidacao.Data.Nome || '';

        this.formRegistrar.celular.ddd = this.usuarioValidacao.Data.CelularDDD;
        this.formRegistrar.celular.numero = this.usuarioValidacao.Data.CelularNumero;
        this.formRegistrar.residencial.ddd = this.usuarioValidacao.Data.TelefoneDDD;
        this.formRegistrar.residencial.numero = this.usuarioValidacao.Data.TelefoneNumero;

        this.formRegistrar.endereco.cep = this.usuarioValidacao.Data.Cep;
        this.formRegistrar.endereco.bairro = this.usuarioValidacao.Data.Bairro;
        this.formRegistrar.endereco.cidade = this.usuarioValidacao.Data.Cidade;
        this.formRegistrar.endereco.numero = this.usuarioValidacao.Data.Numero;
        this.formRegistrar.endereco.logradouro = this.usuarioValidacao.Data.Logradouro;
        this.formRegistrar.endereco.complemento = this.usuarioValidacao.Data.Complemento;
        this.formRegistrar.endereco.uf = UnidadesFederativas[this.usuarioValidacao.Data.UF as keyof typeof UnidadesFederativas];
      }
    }

    else if (['Edit', 'Show'].includes(this.Action as string)) {
      if (this.Action == 'Show') {
        this.readOnly = true;
      }

      this.formRegistrar.usuarioId = this.route.snapshot.queryParamMap.get('usuarioid') as string;
      this.api.consultarUsuarioPorId(this.formRegistrar.usuarioId).then((result: any) => {
        if (result) {
          if (result.Status == 0) {
            this.formCpfValido.cpf = result.Usuario.CPF;
            this.formCpfValido.grupo = result.Usuario.GrupoUsuario.Id;
            this.formCpfValido.vinculacao = result.Usuario.DominioId;
            this.formRegistrar.codigo = result.Usuario.Codigo;

            this.definirLabelGrupo();

            this.formRegistrar.email = result.Usuario.Email.Email;
            this.formRegistrar.nome = result.Usuario.Nome;

            this.formRegistrar.usuario = result.Usuario.Login;
            this.formRegistrar.situacao = result.Usuario.Situacao;


            this.formRegistrar.CRMV_CodInspetor = this.formCpfValido.grupo === 'GR_SRG_INSPETOR' || this.formCpfValido.grupo === 'GR_SRG_TECNICO'
              ? result.Usuario.CodigoInspetor
              : result.Usuario.CRMV;
            this.formRegistrar.tipoInspetor = result.Usuario.TipoInspetor == 0 ? '-1' : result.Usuario.TipoInspetor;
            this.formRegistrar.codigoEspecializacaoInspetor = result.Usuario.CodigoEspecializacaoInspetor;

            this.formRegistrar.celular.ddd = result.Usuario.Celular.DDD;
            this.formRegistrar.celular.numero = result.Usuario.Celular.Numero;
            this.formRegistrar.residencial.ddd = result.Usuario.Telefone.DDD;
            this.formRegistrar.residencial.numero = result.Usuario.Telefone.Numero;

            this.formRegistrar.endereco.cep = result.Usuario.Endereco.Cep;
            this.formRegistrar.endereco.bairro = result.Usuario.Endereco.Bairro;
            this.formRegistrar.endereco.cidade = result.Usuario.Endereco.Cidade;
            this.formRegistrar.endereco.numero = result.Usuario.Endereco.Numero;
            this.formRegistrar.endereco.logradouro = result.Usuario.Endereco.Logradouro;
            this.formRegistrar.endereco.complemento = result.Usuario.Endereco.Complemento;
            this.formRegistrar.endereco.uf = result.Usuario.Endereco.UF;

          } else {
            Swal.fire(result.message, '', 'error');
          }
        }
      });
    }

    else if (this.Action == 'Show') {
      this.readOnly = true;
    }
  }

  private definirLabelGrupo() {
    const isJersey = this.contexto === 'jersey';

    switch (this.formCpfValido.grupo) {
      case 'GR_SRG_INSPETOR': this.labelGrupoUsuario = `SRG Inspetor ${isJersey ? '' : ' de Registro'}`; break;
      case 'GR_SRG_JERSEY_ADMIN': this.labelGrupoUsuario = `SRG ${isJersey ? 'Jersey' : 'Wagyu'} Administrador`; this.grupoAdmJersey = true; break;
      case 'GR_SRG_SUPERINTENDENTE': this.labelGrupoUsuario = 'SRG Superintendente'; break;
      case 'GR_SRG_VETERINARIO': this.labelGrupoUsuario = 'SRG Veterinário'; break;
      case 'GR_SRG_TECNICO': this.labelGrupoUsuario = `SRG Técnico ${isJersey ? '' : ' de Abate'}`; break;
      case 'GR_SRG_PROCURADOR': this.labelGrupoUsuario = 'SRG Procurador'; break;

      default: this.router.navigate(['registrar_usuario']);
    }
  }

  botaoEnviar(): void {

    const { errors } = new FormControl(
      this.formRegistrar.email,
      [Validators.required, Validators.email]
    );

    if (errors) {
      const key = Object.keys(errors);

      if (key.includes("required"))
        Swal.fire('E-mail não informado', '', 'error');

      if (key.includes("email"))
        Swal.fire('E-mail inválido', '', 'error');

      return;
    }

    if (this.Action == 'Create') {
      this.api.registrarUsuario(this.formCpfValido, this.formRegistrar).then((result: any) => {
        if (result) {
          if (result.success) {
            Swal.fire(result.message, '', 'success');
            this.formRegistrar = new UsuarioModel();
            this.formCpfValido = new ValidaCpfUsuario();
            this.router.navigate(['usuario_consultar']);
          } else {
            Swal.fire(result.message, '', 'error');
          }
        }
      });
    }
    else if (this.Action == 'Edit') {
      this.api.atualizarUsuario(this.formCpfValido, this.formRegistrar).then((result: any) => {
        if (result) {
          if (result.success) {
            Swal.fire(result.message, '', 'success');
            this.formRegistrar = new UsuarioModel();
            this.formCpfValido = new ValidaCpfUsuario();
            this.router.navigate(['usuario_consultar']);
          } else {
            Swal.fire(result.message, '', 'error');
          }
        }
      });
    }
  }

}
