<h1 mat-dialog-title *ngIf="fazenda.Id != null && fazenda.Id != '-1'">Questionário de manejo ({{fazenda.Nome}})</h1>
<h1 mat-dialog-title *ngIf="fazenda.Id == '-1'">Questionário de manejo ({{fazendasCriador[contFazenda].Nome}})</h1>
<mat-card-content class="card-personalizado">

    <label class="form-label">1. Manejo de cria dos bezerros:</label>
    <ul>
        <li><mat-checkbox class="check-box" [(ngModel)]="questionarioManejoModel.AoPeVaca">Ao pé da vaca</mat-checkbox></li>
        <li><mat-checkbox class="check-box" [(ngModel)]="questionarioManejoModel.MamadaControlada">Mamada controlada</mat-checkbox></li>
        <li><mat-checkbox class="check-box" [(ngModel)]="questionarioManejoModel.AoPeVacaCreepFeeding">Ao pé da vaca + creep feeding</mat-checkbox></li>
        <li><mat-checkbox class="check-box" [(ngModel)]="questionarioManejoModel.AleitamentoArtificial">Aleitamento artificial</mat-checkbox></li>
    </ul>

    <label class="form-label" style="margin-left: 20px;">2. Idade de desmame (em meses):</label>
    <ul>
        <div class="col-2">
            <input type="number" class="form-control form-control-sm" [(ngModel)]="questionarioManejoModel.IdadeDesmame">
        </div>
    </ul>
    
    <label class="form-label" style="margin-left: 20px;">3. Expectativa de peso ao desmame (média, kg):</label>
    <ul>
        <div class="col-2">
            <input type="number" class="form-control form-control-sm" [(ngModel)]="questionarioManejoModel.PesoDesmame">
        </div>
    </ul>

    <label class="form-label" style="margin-left: 20px;">4. Manejo recria:</label>
    <ul>
        <li><mat-checkbox class="check-box" [(ngModel)]="questionarioManejoModel.ManejoConfinado">Confinado</mat-checkbox></li>
        <li><mat-checkbox class="check-box" [(ngModel)]="questionarioManejoModel.ManejoSemiConfinado">Semi-confinado</mat-checkbox></li>
        <li><mat-checkbox class="check-box" [(ngModel)]="questionarioManejoModel.ManejoPasto">A pasto</mat-checkbox></li>
    </ul>
    
    <label class="form-label" style="margin-left: 20px;">5. Quanto tempo os animais ficam no manejo da recria (média, meses):</label>
    <ul>
        <div class="col-2">
            <input type="number" class="form-control form-control-sm" [(ngModel)]="questionarioManejoModel.TempoManejoRecria">
        </div>
    </ul>

    <label class="form-label" style="margin-left: 20px;">6. Idade da castração (média, meses):</label>
    <ul>
        <div class="col-2">
            <input type="number" class="form-control form-control-sm" [(ngModel)]="questionarioManejoModel.IdadeCastracao">
        </div>
    </ul>

    <label class="form-label" style="margin-left: 20px;">7. Peso esperado ao final da recria (média, kg):</label>
    <ul>
        <div class="col-2">
            <input type="number" class="form-control form-control-sm" [(ngModel)]="questionarioManejoModel.PesoFinalRecria">
        </div>
    </ul>

    <label class="form-label" style="margin-left: 20px;">8. Terminação:</label>
    <ul>
        <li><mat-checkbox class="check-box" [(ngModel)]="questionarioManejoModel.TerminacaoConfinamento">Confinamento</mat-checkbox></li>
        <li><mat-checkbox class="check-box" [(ngModel)]="questionarioManejoModel.TerminacaoSemiConfinamento">Semi-confinamento</mat-checkbox></li>
        <li><mat-checkbox class="check-box" [(ngModel)]="questionarioManejoModel.TerminacaoPasto">A pasto</mat-checkbox></li>
        <li><mat-checkbox class="check-box" [(ngModel)]="questionarioManejoModel.TerminacaoOutro">Outro:</mat-checkbox></li>
        <li><textarea class="form-control" *ngIf="questionarioManejoModel.TerminacaoOutro" name="outro" [(ngModel)]="questionarioManejoModel.TerminacaoOutroDetalhes" rows="1"></textarea></li>
    </ul>

    <label class="form-label" style="margin-left: 20px;">9. Tempo na terminação (média, meses):</label>
    <ul>
        <div class="col-2">
            <input type="number" class="form-control form-control-sm" [(ngModel)]="questionarioManejoModel.TempoTerminacao">
        </div>
    </ul>

    <label class="form-label" style="margin-left: 20px;">10. Peso esperado ao final da terminação (média, kg):</label>
    <ul>
        <div class="col-2">
            <input type="number" class="form-control form-control-sm" [(ngModel)]="questionarioManejoModel.PesoFinalTerminacao">
        </div>
    </ul>
    <div *ngIf="!isEdit">
        <button class="btn btn-primary btn-sm" type="button" matTooltip="Enviar" style="margin-left: 20px;" (click)="enviar()">
            <i class="fa fa-check"></i>&nbsp;Enviar
        </button>
    </div>
    <div *ngIf="isEdit">
        <button *ngIf="permiteEditar()" class="btn btn-primary btn-sm" type="button" matTooltip="Atualizar" style="margin-left: 20px;" (click)="enviar()">
            <i class="fa fa-check"></i>&nbsp;Atualizar
        </button>
        <button class="btn btn-primary btn-sm" type="button" matTooltip="Fechar" style="margin-left: 20px;" (click)="fechar()">
            <i class="fa fa-close"></i>&nbsp;Fechar
        </button>
        <label class="form-label" *ngIf="!permiteEditar()" style="margin-left: 20px; margin-top: 5px;">PARA EDIÇÃO DE DADOS DO QUESTIONÁRIO FAVOR ENTRAR EM CONTATO COM A ASSOCIAÇÃO.</label>
    </div>
    
</mat-card-content>


