import { CriadorModel } from "./CriadorModel";

export class FormularioModel{
  id: string;
  formulario: string;
  protocolo: string;
  situacao: string;
  situacaoLote: number;
  dataCriacao: string;
  dataComunicacao: string;
  criador: CriadorModel;
 
  constructor(){
    this.id = "";
    this.formulario = "";
    this.protocolo = "";
    this.situacao = "";
    this.situacaoLote = 0;
    this.dataCriacao = "";
    this.dataComunicacao = "";
    this.criador = new CriadorModel();
  }
}