<div class="card" style="margin-top: 1em;">
  <div class="card-body">
    <div *ngIf="exibirFiltrosConsulta">

      <app-filtro-consulta-certificacao [formConsulta]="formConsulta"></app-filtro-consulta-certificacao>

      <div class="row justify-content-end" style="margin-bottom: 20px;">
        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">

          <a 
            type="button" 
            class="btn btn-sm btn-block btn-primary btn-block"
            matTooltip="Novo" 
            [queryParams]="{Action: gerarParametro('Create')}" 
            [routerLink]="['/certificados_manter']" 
            >
              Novo
          </a>

        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
          <button type="button" class="btn btn-sm btn-block btn-primary btn-block" (click)="botaoConsultar()">
            Buscar
          </button>
        </div>
      </div>

      <div class="row" *ngIf="qtdCertificadosSelecionados > 0">
        <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12" style="margin-bottom: 10px;">
          <button type="button" class="btn btn-sm btn-primary btn-block" (click)="imprimirCertificados(false)">
            Imprimir {{ qtdCertificadosSelecionados }} certificado(s)
          </button>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 col-xs-12">
          <button type="button" class="btn btn-sm btn-primary btn-block" (click)="imprimirCertificados(true)">
            Imprimir {{ qtdCertificadosSelecionados }} certificado(s) (Pré-Impresso)
          </button>
        </div>
      </div>

    </div>

    <div *ngIf="count > 0" class="row" style="margin-top: 15px;">
      <div class="col-lg-12 table-responsive">
        <table class="table table-hover table-header-custom">
          <thead>
            <tr>
              <th class="text-center" width="1%"></th>
              <th>Emissão</th>
              <th>Código</th>
              <th>Nome</th>
              <th>Assinado</th>
              <th>Resp. Emissão</th>
              <th class="text-center" width="10%"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let certificado of certificados | paginate : {itemsPerPage: pageSize, currentPage: page, totalItems: count};">
              <td>
                <input type="checkbox" class="flat" *ngIf="certificado.Assinado == 1"
                  [(ngModel)]="certificado.Selecionado" (change)="handleSelecionadoChange(certificado)">
              </td>
              <td>{{ certificado.DescDataEmissao }}</td>
              <td>{{ env.contexto === 'wagyu'? certificado.OrelhaDireita : (certificado.OrelhaEsquerda || certificado.OrelhaDireita) }}</td>
              <td>{{ certificado.NomeBovino }}</td>
              <td>{{ certificado.DescAssinado }}</td>
              <td>{{ certificado.NomeRespEmissao }}</td>
              <td class="text-center text-nowrap">
                <a *ngIf="certificado.Assinado == 1" type="button" matTooltip="Imprimir o certificado"
                  style="margin-right: 8px;" (click)="imprimirCertificado(certificado.Id, false)">
                  <i class="fa fa-print"></i>
                </a>
                <a *ngIf="certificado.Assinado == 1" type="button" matTooltip="Imprimir o certificado pré impresso" (click)="imprimirCertificado(certificado.Id, true)">
                  <i class="far fa-file-alt"></i>
                </a>
                <a *ngIf="certificado.Assinado == 2" type="button" matTooltip="Visualizar o certificado" (click)="visualizarCertificado(certificado.Id, certificado.TipoCertificado)">
                  <i class="fas fa-file-alt"></i>
                </a>
                <a *ngIf="certificado.Assinado == 2" type="button" matTooltip="Apagar certificado" (click)="apagarCertificado(certificado.Id)" style="margin-left: 5px;">
                  <i class="fas fa-trash-alt"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="col-md-12 text-center">
        <pagination-controls class="srg-paginacao" previousLabel="Anterior" nextLabel="Próximo" [responsive]="true"
          (pageChange)="handlePageChange($event)" [autoHide]="true">
        </pagination-controls>
      </div>

    </div>

  </div>
</div>