<div class="row" style="margin-top: 15px;">
  <div class="col-lg-12 table-responsive">
    <table class="table table-hover table-header-custom">
      <thead>
        <tr>
          <th class="text-center">Data</th>
          <th *ngIf="env.contexto === 'jersey'" class="text-center">Número da Lactação</th>
          <th *ngIf="env.contexto === 'jersey'" class="text-center">Garupa</th>
          <th *ngIf="env.contexto === 'jersey'" class="text-center">Sistema Mamário</th>
          <th class="text-center">Pernas e Pés</th>
          <th *ngIf="env.contexto === 'jersey'" class="text-center">Força Leiteira</th>
          <th class="text-center">Pontuação Final</th>
        </tr>
      </thead>
      <tbody *ngIf="classificacoes.length > 0">
        <tr *ngFor="let classificacao of classificacoes | paginate : {itemsPerPage: pageSize, currentPage: page, totalItems: count};">
          <td class="text-center">{{ classificacao.Data | date: 'dd/MM/yyyy' }}</td>
          <td *ngIf="env.contexto === 'jersey'" class="text-center">{{ classificacao.NumeroLactacao }}</td>
          <td *ngIf="env.contexto === 'jersey'" class="text-center">{{ classificacao.Garupa }}</td>
          <td *ngIf="env.contexto === 'jersey'" class="text-center">{{ classificacao.SistemaMamario }}</td>
          <td class="text-center">{{ classificacao.PernasPes }}</td>
          <td *ngIf="env.contexto === 'jersey'" class="text-center">{{ classificacao.ForcaLeiteira }}</td>
          <td class="text-center">{{ classificacao.PontuacaoFinal }}</td>
        </tr>
      </tbody>
      <tbody *ngIf="classificacoes.length === 0">
        <tr class="text-center">
          <td colspan="7">
            <strong>Sem itens para exibição.</strong>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="col-md-12 text-center" *ngIf="classificacoes.length > 0">
    <pagination-controls  
      [autoHide]="true"
      [responsive]="true"
      nextLabel="Próximo" 
      class="srg-paginacao" 
      previousLabel="Anterior" 
      (pageChange)="handlePageChange($event)" 
    >
    </pagination-controls>
  </div>
  
</div>