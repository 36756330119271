import { TipoManejo } from "../shared/enums/Enums";
import { BovinoModel } from "./BovinoModel";
import { OcorrenciaEventoModel } from "./OcorrenciaEventoModel";

export class OcorrenciaEventoPesagemModel extends OcorrenciaEventoModel {

	AnimalCadastrado: BovinoModel;

	//Data da Pesagem
	DataPesagem: string;

	//Peso (kg)
	Peso: number | string;

	//Circunferência escrotal (cm)(será preenchido somente se for macho)
	CircunferenciaEscrotal: number | string;

	//Altura da cernelha (m) tabela de altura slide a seguir
	AlturaCernelha: number | string;

	//Manejo (o usuário irá selecionar se é confinado, pasto ou semi confinado)
	Manejo: TipoManejo;

	//Manejo do criador (campo livre)
	ManejoCriador: string;

	constructor() {
		super();

		this.AnimalCadastrado = new BovinoModel();
		this.AnimalCadastrado.TituloConsulta = "Animal";
		this.AnimalCadastrado.ConsultaAnimalTodosCriadores = false;
		this.AnimalCadastrado.ConsideraInativos = false;

		this.DataPesagem = "";
		this.Peso = '';
		this.CircunferenciaEscrotal = '';
		this.AlturaCernelha = '';
		this.Manejo = TipoManejo.NONE;
		this.ManejoCriador = "";
	}
}