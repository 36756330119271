import { AccountService } from 'src/app/account/shared/account.service';
import { Commons } from 'src/app/shared/commons';
import { ApiService } from 'src/app/shared/api.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { BaseEventos } from 'src/app/eventos/base-eventos';
import { ConstantesTipoEvento } from 'src/app/shared/constantes';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { IItemSugestaoNascimento } from 'src/app/shared/interfaces/Interfaces';
import { ConsultarSugestaoNascimentoModalComponent } from 'src/app/shared/consultar-sugestao-nascimento-modal/consultar-sugestao-nascimento-modal.component';
import { AnimalCadastrado, EnumTipoEventoSRG, SituacaoOcorrenciaEvento } from 'src/app/shared/enums/Enums';
import { BovinoModel } from 'src/app/models/BovinoModel';
import { OcorrenciaEventoInovulacaoModel } from 'src/app/models/OcorrenciaEventoInovulacaoModel';
import { OcorrenciaEventoPadreacaoModel } from 'src/app/models/OcorrenciaEventoPadreacaoModel';

@Component({
  selector: 'app-transferencia-manter',
  templateUrl: './transferencia-manter.component.html',
  styleUrls: ['./transferencia-manter.component.scss']
})
export class TransferenciaManterComponent extends BaseEventos implements OnInit {

  @Output() acaoSelecionada = new EventEmitter<string>();

  isReadOnly: boolean = false;
  sugestaoSelecionada: boolean = false;
  readonly env = environment;
  descEventoSugestao: string = `${(this.env as any).contexto === 'jersey' ? 'Padreação' : 'Cobertura'} / Inovulação`;

  constructor(
    private activatedRoute: ActivatedRoute,
    public accountService: AccountService,
    public api: ApiService,
    public datePipe: DatePipe,
    public commons: Commons,
    public router: Router,
    public dialog: MatDialog
  ) {
    super(api, datePipe, commons, router, accountService, dialog);
    this.tipoEvento = ConstantesTipoEvento.TRANSFERENCIA;
  }

  ngOnInit(): void {
    this.novoEvento();
    this.activatedRoute.paramMap.subscribe(params => {
      this.iniciarTela(params).then(() => {
        this.setComprador(this.ocorrenciaAtual);
        this.configEventoAssociado();
        this.inovulacao();

      });
    });

  }

  prenhaAlterada() {
    if (this.ocorrenciaAtual.Prenha == 2) {
      this.ocorrenciaAtual.EventoAssociado.Id = '-1';
      this.ocorrenciaAtual.OcorrenciaEventoInovulacao = new OcorrenciaEventoInovulacaoModel();
      this.ocorrenciaAtual.OcorrenciaEventoPadreacao = new OcorrenciaEventoPadreacaoModel();

      this.sugestaoSelecionada = false;
      this.ocorrenciaAtual.SugestaoSelecionada = false;
      this.descEventoSugestao = '';
    }
  }

  async consultarEventoEmElaboracao(idCriador: string, novoItem: boolean = false, _: (() => void) | null = null) {
    super.consultarEventoEmElaboracao(idCriador, novoItem, (): void => {
      this.setComprador(this.ocorrenciaAtual);
      this.configEventoAssociado();
    });
  }

  private configEventoAssociado() {
    if (this.ocorrenciaAtual.Prenha == 1) {
      if (this.ocorrenciaAtual.OcorrenciaEventoInovulacao.Id !== '-1') {
        this.sugestaoSelecionada = true;
        this.ocorrenciaAtual.SugestaoSelecionada = true;
        this.descEventoSugestao = this.commons.getDescricaoEvento(EnumTipoEventoSRG.Inovulacao);
      } else if (this.ocorrenciaAtual.OcorrenciaEventoPadreacao.Id !== '-1') {
        this.sugestaoSelecionada = true;
        this.ocorrenciaAtual.SugestaoSelecionada = true;
        this.descEventoSugestao = this.commons.getDescricaoEvento(EnumTipoEventoSRG.Padreacao);
      }
    }
  }

  editar(evento: any) {
    super.editar(evento);
    this.setComprador(evento);
    this.inovulacao();

    if (this.ocorrenciaAtual.OcorrenciaEventoPadreacao
      && this.ocorrenciaAtual.OcorrenciaEventoPadreacao.Id
      && this.ocorrenciaAtual.OcorrenciaEventoPadreacao.Id !== '-1'
    ) {
      this.sugestaoSelecionada = true;
      this.ocorrenciaAtual.SugestaoSelecionada = true;
      this.descEventoSugestao = `${(this.env as any).contexto === 'jersey' ? 'Padreação' : 'Cobertura'}`
    } else if (this.ocorrenciaAtual.OcorrenciaEventoInovulacao
      && this.ocorrenciaAtual.OcorrenciaEventoInovulacao.Id
      && this.ocorrenciaAtual.OcorrenciaEventoInovulacao.Id !== '-1'
    ) {
      this.sugestaoSelecionada = true;
      this.ocorrenciaAtual.SugestaoSelecionada = true;
      this.ocorrenciaAtual.OcorrenciaEventoInovulacao.DataOcorrencia = this.ocorrenciaAtual.OcorrenciaEventoInovulacao.DataTransferencia;
      this.descEventoSugestao = `${(this.env as any).contexto === 'jersey' ? 'Inovulação' : 'Implantação'}`
    } else {
      this.sugestaoSelecionada = false;
      this.descEventoSugestao = '';
    }
  }

  private setComprador(ev: any) {
    this.ocorrenciaAtual.Comprador.id = ev.Comprador.Id;
    this.ocorrenciaAtual.Comprador.nome = ev.Comprador.NomeCriador;
    this.ocorrenciaAtual.Comprador.codigo = ev.Comprador.CodigoCriador;
    this.ocorrenciaAtual.Comprador.afixo = ev.Comprador.AfixoCriador;
    this.ocorrenciaAtual.Comprador.readonly = super.isOcorrenciaReadOnly();
    this.ocorrenciaAtual.Comprador.TituloConsulta = 'Comprador';
  }

  botaoConsultarLimparSugestao() {
    if (this.sugestaoSelecionada) this.botaoLimparSugestao();
    else this.consultarSugestoes();
  }

  botaoLimparSugestao() {
    this.sugestaoSelecionada = false;
    this.descEventoSugestao = `${(this.env as any).contexto === 'jersey' ? 'Padreação /  Inovulação' : 'Cobertura / Implantação'}`
    this.ocorrenciaAtual.EventoAssociado.Id = '-1';
    this.ocorrenciaAtual.EventoAssociado.Matriz = { ...(new BovinoModel()), TituloConsulta: 'Doadora' } as BovinoModel;
    this.ocorrenciaAtual.EventoAssociado.Receptora = { ...(new BovinoModel()), TituloConsulta: 'Receptora' } as BovinoModel;
    this.ocorrenciaAtual.EventoAssociado.Reprodutor = { ...(new BovinoModel()), TituloConsulta: 'Reprodutor' } as BovinoModel;
  }

  consultarSugestoes() {
    const dialogRef = this.dialog.open(
      ConsultarSugestaoNascimentoModalComponent,
      {
        height: '90%',
        width: '90%',
        autoFocus: false,
        data: {
          Mae: {
            Id: this.ocorrenciaAtual.Animal.Id,
            CodigoBovino: this.ocorrenciaAtual.AnimalCadastrado === AnimalCadastrado.Sim
              ? this.ocorrenciaAtual.Animal.CodigoBovino
              : this.ocorrenciaAtual.CodigoAnimalNaoCadastrado,
            NomeBovino: this.ocorrenciaAtual.AnimalCadastrado === AnimalCadastrado.Sim
              ? this.ocorrenciaAtual.Animal.NomeBovino
              : this.ocorrenciaAtual.NomeAnimalNaoCadastrado,
            AnimalCadastrado: this.ocorrenciaAtual.AnimalCadastrado
          },
          CriadorId: this.ocorrenciaAtual.Animal.IdProprietarioConsulta,
          SugerirIntervaloTempo: false
        }
      }
    );

    dialogRef.componentInstance.sugestaoSelecionada.subscribe((result: IItemSugestaoNascimento) => {
      if (result) {
        this.sugestaoSelecionada = true;
        this.ocorrenciaAtual.SugestaoSelecionada = true;
        this.descEventoSugestao = this.commons.getDescricaoEvento(result.TipoEventoSRG);
        this.ocorrenciaAtual.EventoAssociado.Id = result.Id;
        if (result.TipoEventoSRG == EnumTipoEventoSRG.Padreacao) {

          this.ocorrenciaAtual.OcorrenciaEventoPadreacao = {
            Id: result.Id,
            DataOcorrencia: result.Data,
            Matriz: result.MatrizCadastrada
              ? { ...result.Matriz, Readonly: true, TituloConsulta: 'Doadora' }
              : {
                CodigoBovino: result.CodigoMatrizNaoCadastrada,
                NomeBovino: result.NomeMatrizNaoCadastrada,
                Readonly: true,
                TituloConsulta: 'Doadora'
              } as BovinoModel,
            Reprodutor: result.ReprodutorCadastrado
              ? { ...result.Reprodutor, Readonly: true, TituloConsulta: 'Reprodutor' }
              : {
                CodigoBovino: result.CodigoReprodutorNaoCadastrado,
                NomeBovino: result.NomeReprodutorNaoCadastrado,
                Readonly: true,
                TituloConsulta: 'Reprodutor'
              } as BovinoModel,
          };
          this.ocorrenciaAtual.OcorrenciaEventoPadreacao.Reprodutor = result.Reprodutor;
        } else if (result.TipoEventoSRG == EnumTipoEventoSRG.Inovulacao) {

          this.ocorrenciaAtual.OcorrenciaEventoInovulacao = {
            Id: result.Id,
            DataOcorrencia: result.Data,
            DataTransferencia: result.Data,
            Doadora: { ...result.Matriz, Readonly: true, TituloConsulta: 'Doadora' },
            Reprodutor: { ...result.Reprodutor, Readonly: true, TituloConsulta: 'Reprodutor' },
            Receptora: result.ReceptoraCadastrada
              ? { ...result.Receptora, Readonly: true, TituloConsulta: 'Receptora' }
              : {
                CodigoBovino: result.CodigoReceptoraNaoCadastrada,
                NomeBovino: result.NomeReceptoraNaoCadastrada,
                Readonly: true,
                TituloConsulta: 'Receptora'
              } as BovinoModel,
          };
          this.ocorrenciaAtual.OcorrenciaEventoInovulacao.Reprodutor = result.Reprodutor;
        }
      }
    })

  }

  inovulacao() {
    if (this.ocorrenciaAtual.HasReprodutor) {
      return true;
    } else {
      return false;
    }
  }
}
