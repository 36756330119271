import { TipoRegistroDocumental, UnidadesFederativas } from "../shared/enums/Enums";
import { BovinoModel } from "./BovinoModel";
import { OcorrenciaEventoAbateModel } from "./OcorrenciaEventoAbateModel";
import { RelacaoPosAbateComAvalCarcacaModel } from "./RelacaoPosAbateComAvalCarcacaModel";
import { RelacaoPreAbateComAvalCarcacaModel } from "./RelacaoPreAbateComAvalCarcacaModel";


export class OcorrenciaEventoAbateComAvalCarcacaModel extends OcorrenciaEventoAbateModel {

	DataInspecaoAbate: string;
	NumeroGTA: number | string;
	Serie: string;
	UF: UnidadesFederativas;
	NotaFiscal: number | string;
	TipoRegistroDocumental: TipoRegistroDocumental;

	RelacoesPreAbate: RelacaoPreAbateComAvalCarcacaModel[];
	RelacoesPosAbate: RelacaoPosAbateComAvalCarcacaModel[];
	Animal: BovinoModel;

	constructor() {
		super();

		this.DataInspecaoAbate = '';
		this.NumeroGTA = '';
		this.Serie = '';
		this.UF = UnidadesFederativas.None;
		this.NotaFiscal = '';
		this.TipoRegistroDocumental = TipoRegistroDocumental.None;

		this.RelacoesPreAbate = [];
		this.RelacoesPosAbate = [];
		this.Animal = new BovinoModel();
		this.Animal.TituloConsulta = "Animal";
    this.Animal.ConsideraInativos = false;
    this.Animal.ConsultaAnimalTodosCriadores = false;

	}
}