import { AccountService } from 'src/app/account/shared/account.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FiltroConsultaPlantelModel } from 'src/app/models/FiltroConsultaPlantelModel';
import { BovinoService } from 'src/app/bovino/shared/bovino.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-filtro-consulta-plantel-inspecao',
  templateUrl: './filtro-consulta-plantel-inspecao.component.html',
  styleUrls: ['./filtro-consulta-plantel-inspecao.component.scss']
})
export class FiltroConsultaPlantelInspecaoComponent implements OnInit {

  readonly env = environment;

  @Input() formConsulta: FiltroConsultaPlantelModel = new FiltroConsultaPlantelModel();
  situacoesBovino: {value: string, label: string}[] = [];
  racas: { value: string, label: string }[] = [];

  @Output() triggerConsulta: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private accountService: AccountService,
    private bovinoService: BovinoService,
  ) { }

  ngOnInit(): void {
    this.preencherSituacoes();
    let token: any = this.accountService.getPayloadToken();

    if(token){
      this.formConsulta.criador.id = token.CriadorId;
      this.formConsulta.criador.codigo = token.CodigoCriador;
      this.formConsulta.criador.nome = token.NomeCriador;
      this.formConsulta.criador.readonly = this.accountService.isCriador;
    }
    this.racas = this.bovinoService.getListaRacas(['consulta_plantel'], true, 'Todas');
  }
  
  preencherSituacoes(){
    this.situacoesBovino = [];
    // O filtro utiliza a string "Ativo" ou "Inativo"
    this.situacoesBovino.push({value: "Ativo", label: "Ativo"}); 
    this.situacoesBovino.push({value: "Inativo", label: "Inativo"});
  }

  criadorSelecionado(idCriador: string){
    this.formConsulta.criador.id = idCriador;

    this.racas = this.bovinoService
      .getListaRacas(['consulta_plantel'], !idCriador, 'Todas')
      .filter(item => 
        (!idCriador || this.formConsulta.criador.racas.some(r => r === item.label.toUpperCase())) && item
      );
    
    if (this.racas.length === 0) this.racas.push({ label: 'Todas', value: '-1' }); 
    
    this.formConsulta.bovino.RacaBovino = !idCriador
      ? '-1'
      : this.racas[0]?.value;
  }

  consultar = () => this.triggerConsulta.emit();

}
