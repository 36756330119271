import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tooltipObservacao'
})
export class TooltipObservacaoPipe implements PipeTransform {

  transform(value: string): string {
    let obsevacao:string = 'OBSERVAÇÕES \n';
    let mensagem:(string|string[]) = value.split('<ul>');
    
    mensagem = mensagem[1].replace('</ul>', '');

    const itens = mensagem.split('<li>').map(item => item !== '' && item.replace('</li>', ''));

    itens.forEach(item => {
      if(item) obsevacao += `• ${item}`;
    });

    return obsevacao;
  }

}
