<div class="row">

  <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
    <label for="formularioProtocolo">Número do laudo</label>
    <input type="text" class="form-control form-control-sm" name="formularioProtocolo" [(ngModel)]="formConsulta.NumeroLaudo">
  </div>

  <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
    <label for="formularioProtocolo">Qualifica</label>
    <select class="form-control form-control-sm" name="qualifica" [(ngModel)]="formConsulta.Qualifica">
      <option *ngFor="let qualifica of listaQualifica" [value]="qualifica.value" [label]="qualifica.label"></option>
    </select>
  </div>

  <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
    <label for="formularioProtocolo">Sexo</label>
    <select class="form-control form-control-sm" name="sexo" [(ngModel)]="formConsulta.Sexo">
      <option value="-1" label="Todos"></option>
      <option value="1" label="Macho"></option>
      <option value="2" label="Fêmea"></option>
    </select>
  </div>
  
</div>
  
<div class="row">
  <div class="form-group col-lg-6 col-md-12 col-sm-12 col-xs-12">
    <app-consultar-bovino [bovino]="formConsulta.Pai"></app-consultar-bovino>
  </div>
  <div class="form-group col-lg-6 col-md-12 col-sm-12 col-xs-12">
    <app-consultar-bovino [bovino]="formConsulta.Mae"></app-consultar-bovino>
  </div>
</div>

<div class="row">
  <div class="form-group col-lg-6 col-md-12 col-sm-12 col-xs-12">
    <app-consultar-criador [criador]="formConsulta.Criador" (criadorSelecionado)="criadorSelecionado($event)"></app-consultar-criador>
  </div>
</div>