<div class="card">
  <div class="card-body">

    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <a class="nav-link active" id="criador-manter-tab" data-toggle="tab" href="#criador-manter" role="tab" aria-controls="criador-manter" aria-selected="true">
          Criador
        </a>
      </li>
      <!-- <li *ngIf="criador.id !== '-1'" class="nav-item" role="presentation">
        <a class="nav-link" id="criador-procuradores-tab" data-toggle="tab" href="#criador-procuradores" role="tab" aria-controls="criador-procuradores" aria-selected="false">
          Procuradores
        </a>
      </li> -->
    </ul>

    <div class="tab-content border">
      <div class="tab-pane fade show active" id="criador-manter" role="tabpanel" aria-labelledby="criador-manter-tab">
        <app-criador-manter *ngIf="manterCriadorValido" [criador]="criador" [formValidaCriador]="formValidaCriador" [readOnly]="readOnly"></app-criador-manter>
        <app-criador-validar *ngIf="!manterCriadorValido" [(valido)]="manterCriadorValido" [criador]="criador" [formValidaCriador]="formValidaCriador"></app-criador-validar>
      </div>
      <!-- <div class="tab-pane fade" id="criador-procuradores" role="tabpanel" aria-labelledby="criador-procuradores-tab">
        <app-procurador [idCriador]="criador.id" [readOnly]="readOnly"></app-procurador>
      </div> -->
    </div>

  </div>
</div>