<div class="card">
  <div class="card-body">
    <div class="row">
      <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
        <label>*Número do laudo</label>
        <input
          [disabled]="readOnly"
          name="numero_laudo"
          type="text"
          class="form-control form-control-sm"
          [(ngModel)]="formularioLaudo.NumeroLaudo"
        />
      </div>

      <div class="form-group col-lg-5 col-md-6 col-sm-6 col-xs-12">
        <label>Nome</label>
        <input
          disabled
          name="nome"
          type="text"
          class="form-control form-control-sm"
          [(ngModel)]="formularioLaudo.NomeAnimal"
        />
      </div>

      <div class="form-group col-lg-2 col-md-6 col-sm-6 col-xs-12">
        <label for="formularioProtocolo">Sexo</label>
        <select
          [disabled]="readOnly || formularioLaudo.Qualifica == 3"
          class="form-control form-control-sm"
          name="sexo"
          [(ngModel)]="formularioLaudo.Sexo"
        >
          <option
            value="-1"
            [label]="formularioLaudo.Qualifica == 3 ? '' : 'Selecione...'"
          ></option>
          <option value="1" label="Macho"></option>
          <option value="2" label="Fêmea"></option>
        </select>
      </div>
      <!-- #234 fixar opção qualifica como SIM e deixar travado -->
      <div class="form-group col-lg-2 col-md-6 col-sm-6 col-xs-12">
        <label for="formularioProtocolo">*Qualifica</label>
        <select
          [disabled]="readOnly"
          class="form-control form-control-sm"
          name="qualifica"
          [(ngModel)]="formularioLaudo.Qualifica"
          (change)="qualificaAlterado()"
          [disabled]="true"
        >
          <option
            *ngFor="let qualifica of listaQualifica"
            [value]="qualifica.value"
            [label]="qualifica.label"
          ></option>
        </select>
      </div>

      <div
        *ngIf="!readOnly && formularioLaudo.Qualifica == 3"
        class="form-group col-lg-6 col-md-12 col-sm-12 col-xs-12"
      >
        <app-consultar-bovino
          [bovino]="animalQualificaPermanente"
          (BovinoEncontrado)="animalSelecionado()"
        ></app-consultar-bovino>
      </div>

      <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
        <label>*Data Emissão</label>
        <div class="input-group input-group-sm">
          <input
            [disabled]="readOnly"
            class="form-control"
            name="data_emissao"
            [matDatepicker]="dataEmissao"
            [max]="maxDate"
            [(ngModel)]="formularioLaudo.DataEmissao"
          />
          <div class="input-group-append">
            <button
              class="btn btn-outline-secondary"
              type="button"
              (click)="dataEmissao.open()"
            >
              <i class="bi bi-calendar3"></i>
            </button>
          </div>
        </div>
        <mat-datepicker #dataEmissao></mat-datepicker>
      </div>

      <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
        <label>*Data Arquivamento</label>
        <div class="input-group input-group-sm">
          <input
            disabled
            class="form-control"
            name="data_arquivamento"
            [matDatepicker]="dataArquivamento"
            [max]="maxDate"
            [(ngModel)]="formularioLaudo.DataArquivamento"
          />
          <div class="input-group-append">
            <button
              class="btn btn-outline-secondary"
              type="button"
              (click)="dataArquivamento.open()"
            >
              <i class="bi bi-calendar3"></i>
            </button>
          </div>
        </div>
        <mat-datepicker #dataArquivamento></mat-datepicker>
      </div>

      <div
        class="form-group col-lg-6 col-md-12 col-sm-12 col-xs-12"
        *ngIf="!readOnly"
      >
        <label>*Laboratório</label>

        <!-- <mat-form-field> -->
        <mat-select
          class="form-control form-control-sm"
          [disabled]="readOnly"
          [formControl]="labCtrl"
          placeholder="Selecione ou pesquise um laboratório..."
          #singleSelect
          [(ngModel)]="formularioLaudo.Laboratorio"
          (ngModelChange)="handleLabChange($event)"
        >
          <mat-option>
            <ngx-mat-select-search
              placeholderLabel="Pesquise um laboratório aqui..."
              noEntriesFoundLabel="Não foi possível encontrar nenhum laboratório para esta pesquisa."
              [formControl]="labFilterCtrl"
            ></ngx-mat-select-search>
          </mat-option>

          <mat-option
            *ngFor="let lab of filteredLabs | async"
            [value]="lab.name"
          >
            {{ lab.name }}
          </mat-option>
        </mat-select>
        <!-- </mat-form-field> -->

        <!-- <label>*Laboratório</label> -->
        <!-- <input [disabled]="readOnly" name="laboratorio" type="text" class="form-control form-control-sm" [(ngModel)]="formularioLaudo.Laboratorio"> -->
      </div>

      <div
        class="form-group col-lg-6 col-md-12 col-sm-12 col-xs-12"
        *ngIf="readOnly"
      >
        <label>*Laboratório</label>
        <input
          [disabled]="readOnly"
          name="laboratorio"
          type="text"
          class="form-control form-control-sm"
          [(ngModel)]="formularioLaudo.Laboratorio"
        />
      </div>
    </div>

    <div class="row">
      <div class="form-group col-lg-6 col-md-12 col-sm-12 col-xs-12">
        <app-consultar-bovino
          [bovino]="formularioLaudo.Pai"
        ></app-consultar-bovino>
      </div>

      <div class="form-group col-lg-6 col-md-12 col-sm-12 col-xs-12">
        <app-consultar-bovino
          [bovino]="formularioLaudo.Mae"
        ></app-consultar-bovino>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-lg-6 col-md-12 col-sm-12 col-xs-12">
        <app-consultar-criador [criador]="formularioLaudo.Criador" (criadorSelecionado)="criadorSelecionado($event)"></app-consultar-criador>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-lg-9 col-md-9 col-sm-9 col-xs-9">
        <label>Laudo DNA</label>
        <div class="input-group input-group-sm">
          <input
            [disabled]="readOnly"
            type="text"
            readonly
            class="form-control form-control-sm"
            name="arquivo"
            [value]="formularioLaudo.Arquivo.Nome"
          />
          <div
            class="input-group-append"
            *ngIf="formularioLaudo.Id !== '' && formularioLaudo.Id !== '-1'"
          >
            <button
              [disabled]="
                formularioLaudo.Arquivo.Id === '' ||
                formularioLaudo.Arquivo.Id === '-1'
              "
              matTooltip="Visualizar Documento"
              class="btn btn-outline-secondary"
              type="button"
              (click)="visualizarDocumento(formularioLaudo.Arquivo.Id)"
            >
              <i class="fas fa-eye"></i>
            </button>
          </div>
        </div>
      </div>

      <div
        id="arquivo-container"
        class="form-group col-lg-3 col-md-3 col-sm-3 col-xs-3"
      >
        <label></label>
        <input
          type="file"
          #uploader
          name="arquivo"
          accept="application/pdf, image/jpeg, image/png, image/gif"
          (change)="onFileSelected($event)"
          (triggerDocumento)="onFileSelected($event)"
        />
        <button
          [disabled]="readOnly"
          type="button"
          class="btn btn-sm btn-block btn-primary"
          (click)="uploader.click()"
        >
          Anexar Laudo
        </button>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <label>Observação</label>
        <textarea
          type="text"
          name="observacao"
          [disabled]="readOnly"
          class="form-control form-control-sm"
          [(ngModel)]="formularioLaudo.Observacao"
          style="min-height: calc(1.5em + 0.5rem + 2px); height: 50px"
        ></textarea>
      </div>
    </div>

    <div class="row justify-content-end" id="botao-container" *ngIf="!readOnly">
      <div class="col-lg-3 col-md-10 col-sm-10">
        <button
          [disabled]="!readyToSave"
          type="button"
          class="btn btn-sm btn-block btn-primary"
          (click)="botaoSalvar()"
        >
          Salvar
        </button>
      </div>
    </div>

    <div class="row justify-content-end" id="botao-container" *ngIf="resetForm">
      <div class="col-lg-3 col-md-10 col-sm-10">
        <button
          type="button"
          class="btn btn-sm btn-block btn-primary"
          (click)="botaoCadastrarNovo()"
        >
          Novo
        </button>
      </div>
    </div>
  </div>
</div>
