import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InspetorModel } from 'src/app/models/InspetorModel';
import Swal from 'sweetalert2';
import { ApiService } from '../api.service';
import { ConsultarInspetorModalComponent } from '../consultar-inspetor-modal/consultar-inspetor-modal.component';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-consultar-inspetor',
  templateUrl: './consultar-inspetor.component.html',
  styleUrls: ['./consultar-inspetor.component.scss']
})
export class ConsultarInspetorComponent implements OnInit {

  @Input() inspetor: InspetorModel = new InspetorModel();
  @Input() tituloConsulta: string = "Classifcador(a)";
  @Output() inspetorSelecionado = new EventEmitter<string>();

  constructor(
    private api: ApiService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
  }

  limpar() {
    this.inspetor.NomeInspetor = "";
    this.inspetor.Codigo = "";
    this.inspetor.Id = "";
    this.inspetorSelecionado.emit("");
  }

  consultarPorCodigo() {
    if (!this.inspetor.Codigo) {
      Swal.fire('Informe o código para recuperação do inspetor.', '', 'error');
      return;
    }

    var locale = this.route.snapshot.url[0].path;
    if (locale == 'abatecomavalcarcaca_manter' || locale == 'abatecomavalcarcaca_manter_pos' || locale == 'abate_carcaca') {
      this.api.consultarInspetorPorCodigo(this.inspetor.Codigo, 'tecnico_abate').then((result: any) => {
        if (result) {
          if (result.Data.success) {
            this.inspetor.Id = result.Data.idInspetor;
            this.inspetor.NomeInspetor = result.Data.nomeInspetor;
            this.inspetorSelecionado.emit(this.inspetor.Codigo);
          } else {
            this.limpar();
            Swal.fire(result.Data.codigo === 3288334552 ? 'Não foi identificado inspetor com este código.' : result.Data.message, '', 'error');
          }
        } else {
          this.limpar();
          Swal.fire(result.Data.message || 'A operação não pode ser realizada.', '', 'error');
        }
      });
    } else {
      this.api.consultarInspetorPorCodigo(this.inspetor.Codigo).then((result: any) => {
        if (result) {
          if (result.Data.success) {
            this.inspetor.Id = result.Data.idInspetor;
            this.inspetor.NomeInspetor = result.Data.nomeInspetor;
            this.inspetorSelecionado.emit(this.inspetor.Codigo);
          } else {
            this.limpar();
            Swal.fire(result.Data.codigo === 3288334552 ? 'Não foi identificado inspetor com este código.' : result.Data.message, '', 'error');
          }
        } else {
          this.limpar();
          Swal.fire(result.Data.message || 'A operação não pode ser realizada.', '', 'error');
        }
      });
    }


  }

  consultarPorNome() {
    const dialogRef = this.dialog.open(
      ConsultarInspetorModalComponent,
      {
        height: '90%',
        width: '90%',
        data: {
          locale: this.route.snapshot.url[0].path
        }
      }
    );

    dialogRef.componentInstance.inspetorSelecionado.subscribe((result: any) => {
      if (result) {
        this.inspetor.Id = result.Id;
        this.inspetor.NomeInspetor = result.NomeInspetor;
        this.inspetor.Codigo = result.Codigo;
        this.inspetor.Id = result.Id;
        this.inspetorSelecionado.emit(this.inspetor.Codigo);
      }
    });

  }

}
