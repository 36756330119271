export class InspetorModel {
  Id: string;
  Codigo: string;
  Readonly: boolean;
  NomeInspetor: string;
  TipoConsulta: string;

  constructor() {
    this.Id = "-1";
    this.Codigo = "";
    this.Readonly = false;
    this.NomeInspetor = "";
    this.TipoConsulta = '';
  }
}