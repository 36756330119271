import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AccountService } from 'src/app/account/shared/account.service';
import { FazendaModel } from 'src/app/models/FazendaModel';
import { environment } from 'src/environments/environment';
import { ApiService } from '../api.service';
import { QuestionarioModalComponent } from '../questionario-modal/questionario-modal.component';

@Component({
  selector: 'app-fazenda-manter',
  templateUrl: './fazenda-manter.component.html',
  styleUrls: ['./fazenda-manter.component.scss']
})
export class FazendaManterComponent implements OnInit {

  readonly env = environment;

  @Input() length: number = 0;
  @Input() index_titulo: number = 0;
  @Input() readOnly: boolean = false;
  @Input() fazenda: FazendaModel = new FazendaModel();
  @Input() associacaoEntidadeId: string = '';
  @Output() index_remover: EventEmitter<number> = new EventEmitter<number>();

  constructor(
    private apiService: ApiService,
    public dialog: MatDialog,
    private accountService: AccountService) { }

  ngOnInit(): void {
  }

  removerFazenda(index: number): void {
    this.index_remover.emit(index);
  }

  showQuestionario() {
    let criadorId;
    if (this.accountService.isCriador) {
      let token: any = this.accountService.getPayloadToken();
      if (token) {
        criadorId = token.CriadorId;
      }
    } else {
      criadorId = this.associacaoEntidadeId;
    }

    const dialogRef = this.dialog.open(QuestionarioModalComponent, {
      height: '90%',
      width: '50%',
      autoFocus: false,
      disableClose: false,
      data: {
        CriadorId: criadorId,
        Fazenda: this.fazenda,
        IsEdit: true
      },
    })
  }
}
