import { ImputaMulta, SituacaoOcorrenciaEvento, SolicitarDna } from "../shared/enums/Enums";
import { AssociacaoOcorrenciaEventoBovinoModel } from "./AssociacaoOcorrenciaEventoBovinoModel";
import { DocumentoFSCModel } from "./DocumentoFSCModel";

export class OcorrenciaEventoModel {
  Id: string;
  Situacao: SituacaoOcorrenciaEvento;
  TipoElementoEmControleId: string;
  EventoAssociado: any;
  IndInconsitencia: boolean;
  Inconsistencia: string;
  LoteOcorrencia: any;
  Index: string;
  ListaArquivos: DocumentoFSCModel[];
  ListaBovinos: AssociacaoOcorrenciaEventoBovinoModel[];
  BovinosDaLista: string;
  ImputaMulta: ImputaMulta;
  SolicitarDna: SolicitarDna;

  constructor() {
    this.Id = "-1";
    this.Situacao = SituacaoOcorrenciaEvento.Pendente;
    this.TipoElementoEmControleId = "-1";
    this.EventoAssociado = {};
    this.IndInconsitencia = false;
    this.Inconsistencia = "";
    this.LoteOcorrencia = {};
    this.Index = "";
    this.ListaArquivos = [];
    this.ListaBovinos = [];
    this.BovinosDaLista = "";
    this.ImputaMulta = ImputaMulta.Nao;
    this.SolicitarDna = SolicitarDna.Nao;
  }
}