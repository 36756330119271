import { ItemAscendenciaNacionalizacaoModel } from "./ItemAscendenciaNacionalizacaoModel";

export class AscendenciaNacionalizacaoModel {
  IdOcorrencia: string = '-1';

  Pai: ItemAscendenciaNacionalizacaoModel | null;
  Mae: ItemAscendenciaNacionalizacaoModel | null;
  
  Avo1Paterno: ItemAscendenciaNacionalizacaoModel | null = null;
  Avo2Paterno: ItemAscendenciaNacionalizacaoModel | null = null;
  Avo1Materno: ItemAscendenciaNacionalizacaoModel | null = null;
  Avo2Materno: ItemAscendenciaNacionalizacaoModel | null = null;
  
  Bisavo1Paterno: ItemAscendenciaNacionalizacaoModel | null = null;
  Bisavo2Paterno: ItemAscendenciaNacionalizacaoModel | null = null;
  Bisavo3Paterno: ItemAscendenciaNacionalizacaoModel | null = null;
  Bisavo4Paterno: ItemAscendenciaNacionalizacaoModel | null = null;
  Bisavo1Materno: ItemAscendenciaNacionalizacaoModel | null = null;
  Bisavo2Materno: ItemAscendenciaNacionalizacaoModel | null = null;
  Bisavo3Materno: ItemAscendenciaNacionalizacaoModel | null = null;
  Bisavo4Materno: ItemAscendenciaNacionalizacaoModel | null = null;

  constructor() {
    this.Pai = new ItemAscendenciaNacionalizacaoModel();
    this.Pai.Readonly = false;
    this.Pai.PermiteAlterar = true;
    
    this.Mae = new ItemAscendenciaNacionalizacaoModel();
    this.Mae.Readonly = false;
    this.Mae.PermiteAlterar = true;
  }
}