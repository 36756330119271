export class QuestionarioManjeoModel {
    Criador: string;
    AoPeVaca: boolean;
    MamadaControlada: boolean;
    AoPeVacaCreepFeeding: boolean;
    AleitamentoArtificial: boolean;
    IdadeDesmame: number;
    PesoDesmame: number;
    ManejoConfinado: boolean;
    ManejoSemiConfinado: boolean;
    ManejoPasto: boolean;
    TempoManejoRecria: number;
    PesoFinalRecria: number;
    TerminacaoConfinamento: boolean;
    TerminacaoSemiConfinamento: boolean;
    TerminacaoPasto: boolean;
    TempoTerminacao: number;
    PesoFinalTerminacao: number;
    Fazenda: string;
    QuestionarioId: string;
    TerminacaoOutro: boolean;
    TerminacaoOutroDetalhes: string;
    IdadeCastracao: number;

    constructor() {
        this.Criador = '';
        this.AoPeVaca = false;
        this.MamadaControlada = false;
        this.AoPeVacaCreepFeeding = false;
        this.AleitamentoArtificial = false;
        this.IdadeDesmame = 0;
        this.PesoDesmame = 0;
        this.ManejoConfinado = false;
        this.ManejoSemiConfinado = false;
        this.ManejoPasto = false;
        this.TempoManejoRecria = 0;
        this.PesoFinalRecria = 0;
        this.TerminacaoConfinamento = false;
        this.TerminacaoSemiConfinamento = false;
        this.TerminacaoPasto = false;
        this.TempoTerminacao = 0;
        this.PesoFinalTerminacao = 0;
        this.Fazenda = '';
        this.QuestionarioId = '';
        this.TerminacaoOutro = false;
        this.TerminacaoOutroDetalhes = '';
        this.IdadeCastracao = 0;
    }
}