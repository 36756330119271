import { QualificaLaudoDNA, Sexo, TipoBuscaBovino } from '../shared/enums/Enums';
import { BovinoModel } from './BovinoModel';
import { CriadorModel } from './CriadorModel';
import { DocumentoFSCModel } from './DocumentoFSCModel';

export class LaudoDNAModel {
  Id: string;
  NumeroLaudo: string;
  NomeAnimal: string;
  CodigoAnimal: string;
  Laboratorio: string;
  Sexo: Sexo;
  Qualifica: QualificaLaudoDNA;
  DataEmissao: string;
  DataArquivamento: string;
  Pai: BovinoModel;
  Mae: BovinoModel;
  Observacao: string;

  Arquivo: DocumentoFSCModel;

  Mimetype: String;

  Criador: CriadorModel;

  constructor() {
    this.Id = '-1';
    this.NomeAnimal = '';
    this.CodigoAnimal = '';
    this.Laboratorio = '';
    this.NumeroLaudo = '';
    this.Sexo = Sexo.Todos;
    this.DataEmissao = '';
    this.DataArquivamento = '';
    this.Observacao = '';
    this.Qualifica = QualificaLaudoDNA.Sim;

    this.Pai = new BovinoModel();
    this.Pai.ConsultaAnimalTodosCriadores = true;
    this.Pai.TituloConsulta = 'Pai';
    this.Pai.TipoBusca = TipoBuscaBovino.Macho;

    this.Mae = new BovinoModel();
    this.Mae.TituloConsulta = 'Mãe';
    this.Mae.ConsultaAnimalTodosCriadores = true;
    this.Mae.TipoBusca = TipoBuscaBovino.Fêmea;

    this.Arquivo = new DocumentoFSCModel();

    this.Mimetype = '';

    this.Criador = new CriadorModel();
  }
}
