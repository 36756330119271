import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carcaca-manter-modal',
  templateUrl: './carcaca-manter-modal.component.html',
  styleUrls: ['./carcaca-manter-modal.component.scss']
})
export class CarcacaManterModalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
