<app-formulario-tabs [formulario]="formulario" [listaComunicacao]="lote?.ListaComunicacao"
  [ocorrenciaAtualFiv]="lote?.Ocorrencias" [tipoEvento]="fiv"
  (tabSelecionada)="tabSelecionada($event)" (criadorSelecionado)="criadorSelecionado($event)"></app-formulario-tabs>

<div class="row">
  <div class="col-lg-12 col-sm-12">
    <div class="card card-principal-evento">
      <div class="card-body">
        <!--Ocorrencia Atual-->
        <div *ngIf="!isNewLote()">
          <div class="row">
            <div class="col-lg-12">
              <div class="card card-evento">
                <div class="card-body">
                  <div class="row card-title-evento">
                    <div class="col-6">
                      <h4 class="card-title">
                        <mat-icon svgIcon="fiv"></mat-icon> Dados do Embrião
                      </h4>
                    </div>
                    <div class="col-6 text-right">
                      <button *ngIf="ocorrenciaAtual.Id !== '-1'" type="button" class="btn btn-link btn-sm"
                        (click)="documentosDaOcorrencia()">
                        <i class="far fa-folder"></i> Documentos
                      </button>
                    </div>
                  </div>

                  <div class="row">
                    <div class="form-group col-lg-2 col-md-4 col-sm-6 col-xs-12">
                      <label for="TipoOrigem">Origem</label>
                      <select class="form-control form-control-sm" name="TipoOrigem" [disabled]="isOcorrenciaReadOnly()"
                        [(ngModel)]="ocorrenciaAtual.TipoFIV" (ngModelChange)="changeTipoFiv($event)" >
                        <option value="-1">Selecione</option>
                        <option value="1">FIV</option>
                        <option value="2">TE</option>
                      </select>
                    </div>
                  </div>
                  
                  <div *ngIf="ocorrenciaAtual.TipoFIV != -1">
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <app-consultar-veterinario [veterinario]="ocorrenciaAtual.Veterinario"
                          [readonly]="isOcorrenciaReadOnly()"
                          [isReadOnly]="ocorrenciaAtual.isReadOnly"></app-consultar-veterinario>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                        <app-consultar-bovino [ocorrenciaAtualSituacao]="ocorrenciaAtual.Situacao"
                          [bovino]="ocorrenciaAtual.Doadora"></app-consultar-bovino>
                      </div>
                      <div class="form-group col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <label for="formulario">Proprietário</label>
                        <input type="text" class="form-control form-control-sm" name="Proprietario" readonly="true"
                          [(ngModel)]="ocorrenciaAtual.Doadora.CodigoNomeProprietario" />
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                        <app-consultar-bovino [bovino]="ocorrenciaAtual.Touro"
                          [ocorrenciaAtualSituacao]="ocorrenciaAtual.Situacao"
                          (BovinoEncontrado)="handleBovinoAtualChange($event)"></app-consultar-bovino>
                      </div>
                      <div class="col-lg12 col-md-12 col-sm-12 col-xs-12">
                        <table class="table table-hover table-striped table-wrap">
                          <thead>
                            <tr>
                              <th>Código Touro</th>
                              <th>Nome Touro</th>
                              <th>Proprietário</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let touro of ocorrenciaAtual.ListaBovinos">
                              <td> {{touro.Codigo}} </td>
                              <td> {{touro.Nome}} </td>
                              <td> {{touro.Proprietario}} </td>
                              <td>
                                <i [ngClass]="{'btn-icon-disabled': isOcorrenciaReadOnly(), 'fas fa-trash': true}"
                                  (click)="RemoverBovino(touro.BovinoId)"></i>
                              </td>
                            </tr>
                            <tr *ngIf="ocorrenciaAtual.ListaBovinos?.length === 0">
                              <td colspan="4" style="text-align: center;">Nenhum Touro selecionado</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div class="row">
                      <div class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <label for="TipoOrigem">Tipo</label>
                        <select class="form-control form-control-sm" name="TipoOrigem"
                          [disabled]="isOcorrenciaReadOnly() && !permiteEditarAprovado()"
                          [(ngModel)]="ocorrenciaAtual.Tipo">
                          <option *ngFor="let tipo of tiposTransferenciaFiv" [value]="tipo.value" [label]="tipo.label">
                          </option>
                        </select>
                      </div>

                      <div class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <label for="DataColeta">*Data da Coleta</label>
                        <div class="input-group input-group-sm">
                          <input class="form-control" [max]="maxDate" [matDatepicker]="dataColeta" name="DataColeta"
                            [(ngModel)]="ocorrenciaAtual.DataColeta"
                            [disabled]="!temporarioEdicaoEventoAprovado && isOcorrenciaReadOnly()">
                          <div *ngIf="!isOcorrenciaReadOnly()" class="input-group-append">
                            <button class="btn btn-outline-secondary" type="button" (click)="dataColeta.open()"> <i
                                class="bi bi-calendar3"></i> </button>
                          </div>
                        </div>
                        <mat-datepicker [startAt]="ocorrenciaAtual.DataColeta" #dataColeta></mat-datepicker>
                      </div>

                      <div class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <label for="DataCobertura">*Data da Cobertura</label>
                        <div class="input-group input-group-sm">
                          <input class="form-control" [max]="maxDate" [matDatepicker]="dataCobertura"
                            name="DataCobertura" [(ngModel)]="ocorrenciaAtual.DataCobertura"
                            [disabled]="!temporarioEdicaoEventoAprovado && isOcorrenciaReadOnly()">
                          <div *ngIf="!isOcorrenciaReadOnly()" class="input-group-append">
                            <button class="btn btn-outline-secondary" type="button" (click)="dataCobertura.open()"> <i
                                class="bi bi-calendar3"></i> </button>
                          </div>
                        </div>
                        <mat-datepicker [startAt]="ocorrenciaAtual.DataCobertura" #dataCobertura></mat-datepicker>
                      </div>

                      <div *ngIf="ocorrenciaAtual.Tipo != 1" class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <label for="DataCongelamento">*Data do Congelamento</label>
                        <div class="input-group input-group-sm">
                          <input class="form-control" [max]="maxDate" [matDatepicker]="dataCongelamento"
                            name="DataCongelamento" [(ngModel)]="ocorrenciaAtual.DataCongelamento"
                            [disabled]="!temporarioEdicaoEventoAprovado && isOcorrenciaReadOnly()">
                          <div *ngIf="!isOcorrenciaReadOnly()" class="input-group-append">
                            <button class="btn btn-outline-secondary" type="button" (click)="dataCongelamento.open()">
                              <i class="bi bi-calendar3"></i> </button>
                          </div>
                        </div>
                        <mat-datepicker [startAt]="ocorrenciaAtual.DataCongelamento" #dataCongelamento></mat-datepicker>
                        <span style="color: red;">Data do congelamento deve ser de 5 a 8 dias após a cobertura.</span>
                      </div>

                      <div class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <label for="formulario">*Embriões Coletados</label>
                        <input type="number" class="form-control form-control-sm" name="QuantidadeColetados"
                          [disabled]="isOcorrenciaReadOnly()" [(ngModel)]="ocorrenciaAtual.QuantidadeColetados" />
                      </div>

                      <div class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <label for="formulario">*Embriões Viáveis</label>
                        <input type="number" class="form-control form-control-sm" name="QuantidadeViaveis"
                          [disabled]="isOcorrenciaReadOnly()" [(ngModel)]="ocorrenciaAtual.QuantidadeViaveis" />
                      </div>

                      <div *ngIf="ocorrenciaAtual.Tipo != 1" class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <label for="formulario">*Embriões Congelados</label>
                        <input type="number" class="form-control form-control-sm" name="QuantidadeCongelados"
                          [disabled]="isOcorrenciaReadOnly()" [(ngModel)]="ocorrenciaAtual.QuantidadeCongelados" />
                      </div>

                      <div class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <label for="formulario">Embriões Subdivididos</label>
                        <input type="number" class="form-control form-control-sm" name="QuantidadeSubdivididos"
                          [disabled]="isOcorrenciaReadOnly()" [(ngModel)]="ocorrenciaAtual.QuantidadeSubdivididos" />
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
                <app-botoes-acoes-ocorrencia [lote]="lote" [idOcorrencia]="ocorrenciaAtual.Id" tipoEvento="fiv"
                  (acaoSelecionada)="acaoBotao($event)"
                  [temporarioEdicaoEventoAprovado]="temporarioEdicaoEventoAprovado"></app-botoes-acoes-ocorrencia>
              </div>
            </div>
          </div>
          <!--Lista de Ocorrencias-->
          <div>
            <div *ngIf="ocorrencias.length > 0" class="row" style="margin-top: 15px">
              <div class="col-lg-12 table-responsive">
                <table class="table table-hover table-header-custom">
                  <thead>
                    <tr>
                      <th>Doadora</th>
                      <th>Touro</th>
                      <th class="text-center">Coleta</th>
                      <th class="text-center">Cobertura</th>
                      <th class="text-center">Viáveis</th>
                      <th class="text-center">Congelados</th>
                      <th class="text-center">Subdivididos</th>
                      <th class="text-center">Situação</th>
                      <th class="text-center" width="10%">Opções</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let ev of ocorrencias"
                      [ngClass]="{'ocorrencia-em-destaque': ev.Id === ocorrenciaAtual.Id}">
                      <td> {{ ev.Doadora.CodigoNomeBovino }} </td>
                      <td> {{ ev.BovinosDaLista }} </td>
                      <td class="text-center"> {{ ev.DescDataColeta }} </td>
                      <td class="text-center"> {{ ev.DescDataCobertura }} </td>
                      <td class="text-center"> {{ ev.QuantidadeViaveis }} </td>
                      <td class="text-center"> {{ ev.QuantidadeCongelados }} </td>
                      <td class="text-center"> {{ ev.QuantidadeSubdivididos }} </td>
                      <td class="text-center text-nowrap">
                        <div [innerHtml]="descSituacao(ev.Situacao)"></div>
                      </td>
                      <td class="text-center text-nowrap acoes-evento">
                        <i *ngIf="ev.ImputaMulta === 1" matTooltip="Multa Imputada" class="fas fa-dollar-sign"></i>
                        <i *ngIf="ev.SolicitarDna === 1" matTooltip="Será solicitado DNA no Nascimento"
                          class="fas fa-dna">
                        </i>
                        <i *ngIf="permiteEdicao(ev.Situacao)" class="fas fa-edit" (click)="editar(ev)"></i>
                        <i *ngIf="!permiteEdicao(ev.Situacao)" class="fas fa-eye" (click)="editar(ev)"></i>
                        <!-- <i *ngIf="permiteEdicao(ev.Situacao)" class="fab fa-dochub" (click)="editar(ev)"></i> -->
                        <i *ngIf="!permiteEdicao(ev.Situacao)" class="fab fa-dochub" (click)="editar(ev)"></i>
                        <i *ngIf="lote.Situacao === 1" class="fas fa-trash-alt" (click)="deletar(ev)"></i>
                        <i *ngIf="ev.IndInconsitencia" class="fa fa-exclamation-triangle" style="cursor: pointer" 
                          matTooltip="{{ ev.Inconsistencia }}"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>