import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConsultaSugestaoParaNasciomentoModel } from 'src/app/models/ConsultaSugestaoParaNasciomentoModel';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { ApiService } from '../api.service';
import { Commons } from '../commons';
import { ConsultarLaudoDnaModalComponent } from '../consultar-laudo-dna-modal/consultar-laudo-dna-modal.component';
import { AnimalCadastrado, EnumTipoEventoSRG, TipoFiv, TipoPadreacao } from '../enums/Enums';
import { IItemSugestaoNascimento, IResponseConsultaSugestaoNascimento } from '../interfaces/Interfaces';

interface IDataInput {
  CriadorId: string;
  Mae: {
    Id: string;
    CodigoBovino: string;
    NomeBovino: string;
    AnimalCadastrado?: AnimalCadastrado;
  }
  DataNascimento: string;
  SugerirIntervaloTempo: boolean;
}

@Component({
  selector: 'app-consultar-sugestao-nascimento-modal',
  templateUrl: './consultar-sugestao-nascimento-modal.component.html',
  styleUrls: ['./consultar-sugestao-nascimento-modal.component.scss']
})
export class ConsultarSugestaoNascimentoModalComponent implements OnInit {

  readonly env = environment;

  page = 1;
  count = 0;
  pageSize = environment.pageSize;

  sugestoes: IItemSugestaoNascimento[] = [];
  formConsulta: ConsultaSugestaoParaNasciomentoModel = new ConsultaSugestaoParaNasciomentoModel();

  @Output()
  sugestaoSelecionada: EventEmitter<IItemSugestaoNascimento> = new EventEmitter<IItemSugestaoNascimento>();

  constructor(
    public commons: Commons,
    private api: ApiService,
    public dialogRef: MatDialogRef<ConsultarLaudoDnaModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDataInput | null
  ) {
    if (data) {
      if (data.Mae.Id == "-1") {
        data.Mae.Id = "";
      }
      this.formConsulta.Mae.Id = data.Mae.Id;
      this.formConsulta.Mae.CodigoBovino = data.Mae.CodigoBovino;
      this.formConsulta.Mae.NomeBovino = data.Mae.NomeBovino;
      this.formConsulta.Mae.Readonly = false;

      if (data.Mae.AnimalCadastrado !== null)
        this.formConsulta.AnimalCadastrado = data.Mae.AnimalCadastrado == AnimalCadastrado.Nao
          ? AnimalCadastrado.Nao
          : AnimalCadastrado.Sim;

      this.formConsulta.CriadorId = data.CriadorId;
      this.formConsulta.DataNascimento = data.DataNascimento;

      if (data.SugerirIntervaloTempo)
        if ((this.env as any).contexto === 'wagyu') {
          this.formConsulta.SugestaoDe = this.handlePeriodoSugestao(309);
          this.formConsulta.SugestaoAte = this.handlePeriodoSugestao(210);
        } else {
          this.formConsulta.SugestaoDe = this.handlePeriodoSugestao(298);
          this.formConsulta.SugestaoAte = this.handlePeriodoSugestao(250);
        }
    }
  }

  ngOnInit(): void {
  }

  private handlePeriodoSugestao(days: number): string {
    const d = new Date(this.formConsulta.DataNascimento);
    return new Date(d.setDate(d.getDate() - days)).toISOString();
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.consultar();
  }

  botaoBuscar() {
    this.page = 1;
    this.consultar();
  }

  async consultar() {
    await this.api.consultarSugestaoParaNascimento(this.formConsulta, this.pageSize, this.page).then((result: IResponseConsultaSugestaoNascimento) => {
      if (result && result.success) {
        this.sugestoes = result.Itens;
        this.sugestoes.forEach((sugestao: any) => {
          if (sugestao.Matriz.CodigoNomeBovino == "") {
            sugestao.Matriz.CodigoNomeBovino = sugestao.CodigoNomeMatrizNaoCadastrada;
          }
        })
        this.count = result.TotalItens;
      } else {
        this.page = 1;
        this.count = 0;
        this.sugestoes = [];
        Swal.fire(result.message || 'A operação não pode ser realizada.', '', 'error');
      }
    });
  }

  selecionarSugestao(sugestao: IItemSugestaoNascimento) {
    const d = new Date(this.formConsulta.DataNascimento);
    const f = new Date(sugestao.Data);

    if (d < f) {
      Swal.fire('A data da cobertura precisa ser menor que a data de nascimento!', '', 'warning');
      return;
    }
    this.sugestaoSelecionada.emit(sugestao);
    this.dialogRef.close();
  }

  descricaoTipo(tipoEvento: EnumTipoEventoSRG, tipoPadreacao: TipoPadreacao, tipoFiv: TipoFiv): string {
    if (tipoEvento === EnumTipoEventoSRG.Padreacao) {
      switch (tipoPadreacao) {
        case TipoPadreacao.InseminacaoArtifical:
          return 'Inseminação Artificial';
        case TipoPadreacao.MontaNatural:
          return 'Monta Natural';
      }
    }

    if (tipoEvento === EnumTipoEventoSRG.Inovulacao) {
      switch (tipoFiv) {
        case TipoFiv.FIV: return 'FIV';
        case TipoFiv.TE: return 'TE';
      }

    }

    return '';
  }

  fechar = () => this.dialogRef.close();

}
