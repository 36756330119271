<div class="row" style="margin-top: 15px;">
  <div class="col-lg-12 table-responsive">
    <table class="table table-hover table-header-custom">
      <thead>
        <tr>
          <th class="text-center">ORDEM</th>
          <th class="text-center">Parto</th>
          <th class="text-center">Idade</th>
          <th class="text-center">NO</th>
          <th class="text-center">Dias</th>
          <th class="text-center">Leite</th>
          <th class="text-center">Gordura</th>
          <th class="text-center">%G</th>
          <th class="text-center">Proteína</th>
          <th class="text-center">%P</th>
          <th class="text-center">Prêmio</th>
          <th class="text-center">Data Encerramento</th>
          <th class="text-center">Oficial</th>
        </tr>
      </thead>
      <tbody *ngIf="controleLeiteiro.length > 0">
        <tr *ngFor="let controle of controleLeiteiro | paginate : {itemsPerPage: pageSize, currentPage: page, totalItems: count};">
          <td class="text-center">{{ controle.Ordem }}</td>
          <td class="text-center">{{ controle.DataParto | date: 'dd/MM/yyyy' }}</td>
          <td class="text-center">{{ controle.Idade }}</td>
          <td class="text-center">{{ controle.NumeroOrdenhas }}</td>
          <td class="text-center">{{ controle.DiasLactacao }}</td>
          <td class="text-center">{{ controle.Leite }}</td>
          <td class="text-center">{{ controle.Gordura }}</td>
          <td class="text-center">{{ controle.PertGordura }}</td>
          <td class="text-center">{{ controle.Proteina }}</td>
          <td class="text-center">{{ controle.PertProteina }}</td>
          <td class="text-center">{{ controle.Premio }}</td>
          <td class="text-center">{{ controle.DataEncerramento | date: 'dd/MM/yyyy' }}</td>
          <td class="text-center">{{ controle.Oficial }}</td>
        </tr>
        <tr>
          <td class="text-center" colspan="4"><strong>TOTAIS ACOMULADOS</strong></td>
          <td class="text-center"><strong>{{ totais.DiasLactacao }}</strong></td>
          <td class="text-center"><strong>{{ totais.Leite }}</strong></td>
          <td class="text-center"><strong>{{ totais.Gordura }}</strong></td>
          <td class="text-center"><strong>{{ totais.PertGordura }}</strong></td>
          <td class="text-center"><strong>{{ totais.Proteina }}</strong></td>
          <td class="text-center"><strong>{{ totais.PertProteina }}</strong></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
      <tbody *ngIf="controleLeiteiro.length === 0">
        <tr class="text-center">
          <td colspan="13">
            <strong>Sem itens para exibição.</strong>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="col-md-12 text-center" *ngIf="controleLeiteiro.length > 0">
    <pagination-controls  
      [autoHide]="true"
      [responsive]="true"
      nextLabel="Próximo" 
      class="srg-paginacao" 
      previousLabel="Anterior" 
      (pageChange)="handlePageChange($event)" 
    >
    </pagination-controls>
  </div>
  
</div>