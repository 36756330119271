import { environment } from "src/environments/environment";
import { Nacionalizado, TipoBuscaBovino, TipoGrauSangue, TipoItemNacionalizacao } from "../shared/enums/Enums";

export class ItemAscendenciaNacionalizacaoModel {
  AnimalExistente: boolean = false;
  PermiteAlterar: boolean;
  BovinoId: string = '-1';
  Codigo: string = '';
  CodigoCentral: string = '';    
  Nome: string = '';
  Titulo: string = '';
  DataNascimento?: string;
  TituloConsulta: string = '';
  TipoBusca: TipoBuscaBovino = TipoBuscaBovino.Todos;
  Tipo: TipoItemNacionalizacao = TipoItemNacionalizacao.None;
  Readonly: boolean = false;
  Sexo: number = -1;
  TipoGrauSangue: TipoGrauSangue = TipoGrauSangue.PO;
  GrauSangue: string = '1/1';
  Raca: string = (environment as any).contexto === 'jersey'? 'Jersey' : 'WAGYU KUROGE';
  Situacao: string = '';
  Nacionalizado: Nacionalizado = Nacionalizado.Sim;
  ProprietarioId: string = '-1';
  Proprietario: string = '-1';
  ItemParaCadastrar: boolean = false;

  constructor(nivelAnteriorCadastrado: boolean = false) {
    this.PermiteAlterar = nivelAnteriorCadastrado;
  }
}