<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <i class="bi bi-x" color="primary"></i>
</button>

<h2 mat-dialog-title>Buscar Embrião</h2>

<div class="row">
  <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
    <app-consultar-bovino [bovino]="formConsulta.Doadora"></app-consultar-bovino>
  </div>

  <div class="form-group col-lg-2 col-md-6 col-sm-6 col-xs-12">
    <label>De</label>
    <div class="input-group input-group-sm">
      <input class="form-control" name="sugestao_de" [matDatepicker]="sugestaoDe" [(ngModel)]="formConsulta.SugestaoDe"> 
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" type="button" (click)="sugestaoDe.open()"> <i class="bi bi-calendar3"></i> </button>
      </div>
    </div>
    <mat-datepicker [startAt]="formConsulta.SugestaoDe" #sugestaoDe></mat-datepicker>
  </div>

  <div class="form-group col-lg-2 col-md-6 col-sm-6 col-xs-12">
    <label>Até</label>
    <div class="input-group input-group-sm">
      <input class="form-control" name="sugestao_ate" [matDatepicker]="sugestaoAte" [(ngModel)]="formConsulta.SugestaoAte"> 
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" type="button" (click)="sugestaoAte.open()"> <i class="bi bi-calendar3"></i> </button>
      </div>
    </div>
    <mat-datepicker [startAt]="formConsulta.SugestaoAte" #sugestaoAte></mat-datepicker>
  </div>
    
  <div class="form-group col-lg-2 col-md-12 col-sm-12">
    <label for="consulta">&nbsp;</label>
    <button type="button" class="btn btn-sm btn-primary form-control form-control-sm" name="consulta" (click)="botaoBuscar()">Buscar</button>
  </div>
</div>

<div class="row" *ngIf="sugestoes.length > 0">
  <div class="col-lg-12 table-responsive">
    <table class="table table-hover table-header-custom">
      <thead>
        <tr>
          <th class="text-center">Origem</th>
          <th class="text-center">Nacionalizado</th>
          <th>Doadora</th>
          <th>Touro</th>
          <th class="text-center">Coleta</th>
          <th class="text-center">Cobertura</th>
          <th class="text-center">Viáveis</th>
          <th class="text-center">Congealdos</th>
          <th class="text-center">Disponíveis</th>
          <th class="text-center">Selecionar</th>
        </tr>
      </thead>
      <tbody>
        <tr 
          style="cursor: pointer;" 
          (click)="selecionarSugestao(sugestao)"
          *ngFor="let sugestao of sugestoes | paginate : { itemsPerPage: pageSize, currentPage: page, totalItems: count };"
        >
          <td class="text-center"> {{ getDescrOrigem(sugestao.TipoFIV) }} </td>
          <td class="text-center"> {{ getDescNacionalizado(sugestao.Nacionalizado) }} </td>
          <td> {{ sugestao.Doadora.CodigoNomeBovino }} </td>
          <td> {{ sugestao.Touro?.CodigoNomeBovino || sugestao.Touros }} </td>
          <td class="text-center"> {{ sugestao.DescDataColeta }} </td>
          <td class="text-center"> {{ sugestao.DescDataCobertura }} </td>
          <td class="text-center"> {{ sugestao.QuantidadeViaveis }} </td>
          <td class="text-center"> {{ sugestao.QuantidadeCongelados }} </td>
          <td class="text-center"> {{ sugestao.QuantidadeDisponiveis }} </td>
          <td class="text-center">
            <a><i class="bi bi-check icone-selecao"></i></a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="col-md-12 text-center">
    <pagination-controls 
      class="srg-paginacao" 
      previousLabel="Anterior" 
      nextLabel="Próximo" 
      [responsive]="true"
      (pageChange)="handlePageChange($event)" 
      [autoHide]="true"
    >
    </pagination-controls>
  </div>
</div>