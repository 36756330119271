import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConsultaOcorrenciasPorSituacaoModel } from 'src/app/models/ConsultaOcorrenciasPorSituacaoModel';
import { ApiService } from 'src/app/shared/api.service';
import { Commons } from 'src/app/shared/commons';
import { ConstantesRotas } from 'src/app/shared/constantes';
import { EnumTipoEventoSRG, TipoConsultaCarcaca, TipoPeriodoFixoConsulta } from 'src/app/shared/enums/Enums';
import { IItenOcorrenciasPorSituacao, IResponseConsultaOcorrenciasPorSituacao } from 'src/app/shared/interfaces/Interfaces';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ocorrencias-por-situacao-modal',
  templateUrl: './ocorrencias-por-situacao-modal.component.html',
  styleUrls: ['./ocorrencias-por-situacao-modal.component.scss']
})
export class OcorrenciasPorSituacaoModalComponent implements OnInit {

  page = 1;
  count = 0;
  pageSize = environment.pageSize;
  listaQualifica: { value: number, label: string }[] = [];

  itens: IItenOcorrenciasPorSituacao[] = [];
  formConsulta: ConsultaOcorrenciasPorSituacaoModel = new ConsultaOcorrenciasPorSituacaoModel();

  constructor(
    public commons: Commons,
    private api: ApiService,
    private router: Router,
    public dialogRef: MatDialogRef<OcorrenciasPorSituacaoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConsultaOcorrenciasPorSituacaoModel | null
  ) {
    if (data) this.formConsulta = data;
  }

  ngOnInit(): void {
    this.botaoBuscar();
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.consultar();
  }

  botaoBuscar() {
    this.page = 1;
    this.consultar();
  }

  consultar() {
    this.api.recuperarOcorrenciasPorSituacao(this.formConsulta, this.page, this.pageSize).then((result: IResponseConsultaOcorrenciasPorSituacao) => {
      if (result && result.success) {
        this.itens = result.Itens;
        this.count = result.TotalItens;
      } else {
        this.page = 1;
        this.count = 0;
        this.itens = [];
        Swal.fire(result.message || 'A operação não pode ser realizada.', '', 'error');
      }
    });
  }

  fechar = () => this.dialogRef.close();

  private convertToMillis = (day: number): number => ((60 * 60 * 24 * 1000) * day);

  private recuperarPeriodoConsulta(): { De: string, Ate: string } {

    if (this.formConsulta.periodo == TipoPeriodoFixoConsulta.PERIODO_ESPECIFICO)
      return {
        De: this.formConsulta.dataInicial ? new Date(this.formConsulta.dataInicial).toISOString() : '',
        Ate: this.formConsulta.dataFinal ? new Date(this.formConsulta.dataFinal).toISOString() : '',
      }

    const now = new Date();
    now.setHours(0, 0, 0, 0);

    if (this.formConsulta.periodo == TipoPeriodoFixoConsulta.ESTE_MES)
      return {
        De: new Date(now.getFullYear(), now.getMonth(), 1).toISOString(),
        Ate: new Date(now.getFullYear(), now.getMonth() + 1, 0).toISOString(),
      }

    if (this.formConsulta.periodo == TipoPeriodoFixoConsulta.ESTA_SEMANA) {
      const firstday = new Date(now.getTime() - this.convertToMillis(now.getDay()));
      const lastday = new Date(firstday.getTime() + this.convertToMillis(6));
      return {
        De: firstday.toISOString(),
        Ate: lastday.toISOString(),
      }
    }

    return { Ate: '', De: '' };
  }

  redirecionar(id: string, codigo: string, nome: string) {
    const url = ((evento: number): string | null => {
      switch (evento) {
        case EnumTipoEventoSRG.Baixa: return ConstantesRotas.BAIXA_CONSULTAR;
        case EnumTipoEventoSRG.Nascimento: return ConstantesRotas.NASCIMENTO_CONSULTAR;
        case EnumTipoEventoSRG.FIV: return ConstantesRotas.TE_FIV_CONSULTAR;
        case EnumTipoEventoSRG.Nacionalizacao: return ConstantesRotas.NACIONALIZACAO_CONSULTAR_SEMEN;
        case EnumTipoEventoSRG.NacionalizacaoEmbriao: return ConstantesRotas.NACIONALIZACAO_CONSULTAR_EMBRIAO;
        case EnumTipoEventoSRG.Padreacao: return ConstantesRotas.PADREACAO_CONSULTAR;
        case EnumTipoEventoSRG.Inovulacao: return ConstantesRotas.INOVULACAO_CONSULTAR;
        case EnumTipoEventoSRG.ClassificacaoLinear: return ConstantesRotas.CLASSIFICACAO_CONSULTAR;
        case EnumTipoEventoSRG.Pesagem: return ConstantesRotas.PESAGEM_CONSULTAR;
        case EnumTipoEventoSRG.Inspecao: return ConstantesRotas.INSPECAO_CONSULTAR;
        case EnumTipoEventoSRG.Transferencia: return ConstantesRotas.TRANSFERENCIA_CONSULTAR;
        case EnumTipoEventoSRG.Carcaca: return ConstantesRotas.CARCACA_CONSULTAR;
        default: return null;
      }
    })(this.formConsulta.EventoInt);

    if (url) {
      this.fechar();
      this.router.navigate([url], {
        state: {
          Criador: { id, codigo, nome },
          Situacao: this.formConsulta.SituacaoInt,
          Periodo: this.recuperarPeriodoConsulta(),
          OcorrenciaCarcaca: TipoConsultaCarcaca.Pre
        },
      });
    }
  }
}
