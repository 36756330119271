<app-formulario-tabs [formulario]="formulario" [listaComunicacao]="lote?.ListaComunicacao"
  (tabSelecionada)="tabSelecionada($event)" (criadorSelecionado)="criadorSelecionado($event)"></app-formulario-tabs>

<div class="row">
  <div class="col-lg-12 col-sm-12">
    <div class="card card-principal-evento">
      <div class="card-body">
        <!--Ocorrencia Atual-->
        <div *ngIf="!isNewLote()">
          <div class="row">
            <div class="col-lg-12">
              <div class="card card-evento">
                <div class="card-body">
                  <div class="row card-title-evento">
                    <div class="col-6">
                      <h4 class="card-title">
                        <mat-icon svgIcon="controle_carcaca"></mat-icon> Protocolo Carcaça
                      </h4>
                    </div>
                    <div class="col-6 text-right">
                      <button *ngIf="ocorrenciaAtual.Id !== '-1'" type="button" class="btn btn-link btn-sm"
                        (click)="documentosDaOcorrencia()">
                        <i class="far fa-folder"></i> Documentos
                      </button>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                      <app-consultar-criador 
                        [criador]="ocorrenciaAtual.Frigorifico"
                        [apenasFrigorifico]="true"
                      >
                      </app-consultar-criador>
                    </div>

                    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                      <app-consultar-inspetor [tituloConsulta]="'Inspetor'" [inspetor]="ocorrenciaAtual.Inspetor">
                      </app-consultar-inspetor>
                    </div>
                  </div>

                  <div class="row">

                    <div class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
                      <label>Data da Inspeção no Curral e Abate</label>
                      <div class="input-group input-group-sm">
                        <input class="form-control" [matDatepicker]="DataInspecaoAbate"
                          name="DataInspecaoAbate" [(ngModel)]="ocorrenciaAtual.DataInspecaoAbate"
                          [disabled]="isOcorrenciaReadOnly()">
                        <div *ngIf="!isOcorrenciaReadOnly()" class="input-group-append">
                          <button class="btn btn-outline-secondary" type="button" (click)="DataInspecaoAbate.open()"> 
                            <i class="bi bi-calendar3"></i> </button>
                        </div>
                      </div>
                      <mat-datepicker [startAt]="ocorrenciaAtual.DataInspecaoAbate" #DataInspecaoAbate></mat-datepicker>
                    </div>

                    <div class="form-group col-lg-2 col-md-6 col-sm-6 col-xs-12">
                      <label>Tipo de Registro</label>
                      <select class="form-control form-control-sm" name="TipoRegistroDocumental"
                        [(ngModel)]="ocorrenciaAtual.TipoRegistroDocumental" [disabled]="isOcorrenciaReadOnly()">
                        <option *ngFor="let uf of this.TipoRegistroDocumental" [value]="uf.value" [label]="uf.label">
                        </option>
                      </select>
                    </div>

                    <div class="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                      <label for="NumeroGta">Número/GTA</label>
                      <input class="form-control form-control-sm" min="0" step="10" name="NumeroGta" type="number"
                        [(ngModel)]="ocorrenciaAtual.NumeroGTA" [disabled]="isOcorrenciaReadOnly()">
                    </div>

                    <div class="col-lg-1 col-md-6 col-sm-6 col-xs-12">
                      <label for="LetraSerie">Serie</label>
                      <input mask="A" class="form-control form-control-sm" min="0" step="10" name="LetraSerie" type="text"
                        [(ngModel)]="ocorrenciaAtual.Serie" style="text-transform: uppercase" [disabled]="isOcorrenciaReadOnly()">
                    </div>

                    <div class="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                      <label for="Uf">UF</label>
                      <select class="form-control form-control-sm" name="UFs" [(ngModel)]="ocorrenciaAtual.UF" [disabled]="isOcorrenciaReadOnly()">
                        <option *ngFor="let uf of this.UFs" [value]="uf.value" [label]="uf.label"></option>
                      </select>
                    </div>

                    <div class="col-lg-2 col-md-6 col-sm-6 col-xs-12">
                      <label for="NotaFiscal">Nota Fiscal</label>
                      <input class="form-control form-control-sm" min="0" step="100" name="NotaFiscal" type="number"
                        [(ngModel)]="ocorrenciaAtual.NotaFiscal" [disabled]="isOcorrenciaReadOnly()">
                    </div>

                  </div>

                  <!--<div class="row" *ngIf="!isOcorrenciaReadOnly()">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <app-consultar-bovino [bovino]="ocorrenciaAtual.Animal"
                      [ocorrenciaAtualSituacao]="ocorrenciaAtual.Situacao"
                      (BovinoEncontrado)="bovinoSelecionado($event)"></app-consultar-bovino>
                    </div>
                  </div>-->
                  <div *ngIf="showBotaoAdicionarAnimais()">
                    <button class="btn btn-primary btn-sm" type="button" matTooltip="Adicionar animais" (click)="openModal()">Adicionar Animais</button>
                    
                  </div>
                  <div class="row" style="margin-top: 30px;">
                    <!-- AVALIACAO PRÉ ABATE -->
                    <div class="table-responsive col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <table class="table table-hover table-striped table-wrap">
                        <thead>
                          <td colspan="9" class="text-center"><b>RELAÇÃO PRÉ-ABATE</b></td>
                          <tr>
                            <th>Nome do Animal</th>
                            <th>N° do Animal</th>
                            <th>Data de Nascimento</th>
                            <th>Idade (meses)</th>
                            <th>Sexo</th>
                            <th>Comp Racial</th>
                            <th>N° do RGD/CCG</th>
                            <th>Peso Vivo</th>
                            <th class="text-center"> {{ (lote && lote.IsEmDeliberacao) || ocorrenciaAtual.Situacao === 55? 'Relação Pós-Abate' : '' }} </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let relacao of ocorrenciaAtual.RelacoesPreAbate; let i = index">
                            <td> {{ relacao.Nome }} </td>
                            <td> {{ relacao.OrelhaEsquerda }} </td>
                            <td> {{ relacao.DataNascimento | date: 'dd/MM/yyyy' }} </td>
                            <td> {{ relacao.Idade }} </td>
                            <td> {{ commons.getDescricaoSexo(relacao.Sexo) }} </td>
                            <td> {{ relacao.ComposicaoRacial }} </td>
                            <td> {{ relacao.OrelhaDireita }} </td>
                            <td> {{ relacao.PesoVivo === 0? '-' : relacao.PesoVivo }} </td>
                            <td class="text-center action-icons">
                              <a type="button" matTooltip="Baixar o certificado" (click)="imprimeCertificado(relacao.IdBovino)">
                                <i class="fa fa-print" style="color: #6c757d;"></i>
                              </a>
                              <a *ngIf="!isOcorrenciaReadOnly()" type="button" matTooltip="Remover animal">
                                <i class="fas fa-trash" style="color: #6c757d;" (click)="removerBovino(relacao.IdBovino)"></i>
                              </a>
                              <!--<a *ngIf="lote && lote.IsEmDeliberacao && ocorrenciaAtual.RelacoesPosAbate[i].Id === '-1'" type="button" matTooltip="Não preenchida">
                                <i class="fa fa-exclamation-triangle" style="color: #6c757d;"></i>
                              </a>-->
                            </td>
                          </tr>
                        </tbody>
                        <tr *ngIf="ocorrenciaAtual.ListaBovinos?.length === 0">
                          <td colspan="9" class="text-center">Nenhuma relação inserida</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="col-lg-12">
              <div style="margin-bottom: 20px;" *ngIf="showBotaoRelatorio()">
                <button class="btn btn-primary btn-sm" type="button" matTooltip="Gerar relatório" (click)="gerarRelatorio(ocorrenciaAtual.Id)">Gerar relatório de Pré-Abate</button>
              </div>
              <app-botoes-acoes-ocorrencia 
                [lote]="lote" 
                [idOcorrencia]="ocorrenciaAtual.Id"
                (acaoSelecionada)="acaoBotao($event)"
                tipoEvento="abate-com-aval-carcaca"
              ></app-botoes-acoes-ocorrencia>
            </div>

          </div>
          
          <!--Lista de Ocorrencias-->
          <!--<div>
            <div *ngIf="ocorrencias.length > 0" class="row" style="margin-top: 15px">
              <div class="col-lg-12 table-responsive">
                <table class="table table-hover table-header-custom">
                  <thead>
                    <tr>
                      <th>Frigorífico</th>
                      <th>Inspetor</th>
                      <th>Data Inspeção e abate</th>
                      <th>Tipo Registro</th>
                      <th>GTA</th>
                      <th>Série</th>
                      <th>Nota fiscal</th>
                      <th>UF</th>
                      <th>Situação</th>
                      <th width="10%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="let ev of ocorrencias"
                      [ngClass]="{
                        'ocorrencia-em-destaque': ev.Id === ocorrenciaAtual.Id
                      }"
                    >
                      <td> {{ ev.Frigorifico.CodigoCriador }} - {{ ev.Frigorifico.NomeCriador }} </td>
                      <td> {{ ev.Inspetor.Codigo }} - {{ ev.Inspetor.NomeInspetor }} </td>
                      <td> {{ ev.DataInspecaoAbate | date: 'dd/MM/yyyy' }} </td>
                      <td> {{ getDescricaoTipoRegistroDocumental(ev.TipoRegistroDocumental) }} </td>
                      <td> {{ ev.NumeroGTA }} </td>
                      <td> {{ ev.Serie }} </td>
                      <td> {{ ev.NotaFiscal }} </td>
                      <td> {{ getSiglaUf(ev.UF) }} </td>
                      <td>{{ commons.getDescricaoSituacao(ev.Situacao) }}</td>
                      <td class="text-center text-nowrap acoes-evento">
                        <i
                          *ngIf="ev.ImputaMulta === 1"
                          matTooltip="Multa Imputada"
                          class="fas fa-dollar-sign"
                        ></i>
                        <i
                          *ngIf="permiteEdicao(ev.Situacao)"
                          class="fas fa-edit"
                          (click)="editar(ev)"
                        ></i>
                        <i
                          *ngIf="!permiteEdicao(ev.Situacao)"
                          class="fas fa-eye"
                          (click)="editar(ev)"
                        ></i>
                        <i
                          *ngIf="lote.Situacao === 1"
                          class="fas fa-trash-alt"
                          (click)="deletar(ev)"
                        ></i>
                        <i
                          *ngIf="ev.IndInconsitencia"
                          class="fa fa-exclamation-triangle"
                          style="cursor: pointer"
                          matTooltip="{{ ev.Inconsistencia }}"
                        ></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>-->
        </div>
      </div>
    </div>
  </div>
</div>