import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import { AccountService } from 'src/app/account/shared/account.service';
// import { RolesKeys } from 'src/app/account/shared/RolesKeys';
import { ControleLeiteiroModel } from 'src/app/models/ControleLeiteiroModel';
import { ApiService } from 'src/app/shared/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-controle-leiteiro-manter',
  templateUrl: './controle-leiteiro-manter.component.html',
  styleUrls: ['./controle-leiteiro-manter.component.scss']
})
export class ControleLeiteiroManterComponent implements OnInit {

  permiteNovo: boolean = true;
  formRegistrar: ControleLeiteiroModel = new ControleLeiteiroModel();

  constructor(
    private router: Router,
    private api: ApiService,
    // private accountService: AccountService
  ) { }

  ngOnInit(): void {
    // this.permiteNovo = this.accountService.checkRole(RolesKeys.?);
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];

    if (file) {
      this.formRegistrar.arquivo.nome = file.name;
      this.formRegistrar.arquivo.tamanho = file.size;
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => {
        this.formRegistrar.arquivo.itens = 
          reader.result?.toString()
          .split('\r\n')
          .filter((line:string) => (line !== '' && line.replace(/\r|\n/g,'')));
      };
    }
  }
  
  anexarArquivo() {

    if (!this.formRegistrar.dataImportacao) {
      Swal.fire('Data de importação é obrigatório', '', 'error');
      return;
    }

    if (this.formRegistrar.arquivo.nome && this.formRegistrar.arquivo.itens) {

      this.api.registrarControleLeiteiro(this.formRegistrar).then((result: any) => {
        if (result) {
          if (result.success) {
            Swal.fire('Operação realizada com sucesso!' + '\n' +
              'Caso existam animais destacados em vermelho, os mesmos deverão ser verificados antes da confirmação da importação.',
              '', 'success');
            this.formRegistrar = new ControleLeiteiroModel();
            this.router.navigateByUrl(`analisar_controle_leiteiro?id=${result.Id}`);
          } else {
            Swal.fire(result.message, '', 'error');
          }
        }
      });
    } else {
      Swal.fire('Selecione um arquivo para enviar', '', 'error');
    }
  }

}

