<div class="card" style="margin-top: 1em;">
  <div class="card-body">
    <div *ngIf="exibirFiltrosConsulta">
      <app-filtro-consulta-usuario [formConsulta]="formConsulta" (triggerConsulta)="botaoConsultar()"></app-filtro-consulta-usuario>
      <div class="row justify-content-end">
        <div *ngIf="permiteNovo" class="col-lg-2 col-md-4 col-sm-6">
          <button type="button" class="btn btn-sm btn-block btn-primary" (click)="botaoNovo()">Novo</button>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-6">
          <button type="button" class="btn btn-sm btn-block btn-primary" (click)="botaoConsultar()">Buscar</button>
        </div>
      </div>
    </div>

    <div *ngIf="usuarios.length > 0"  class="row" style="margin-top: 15px;">
      <div class="col-lg-12 table-responsive">
        <table class="table table-hover table-header-custom">
          <thead>
            <tr>
              <th>Código</th> 
              <th>Login</th> 
              <th>Nome</th> 
              <th>CPF</th> 
              <th>Situação</th> 
              <th class="text-center" width="10%"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let usuario of usuarios | paginate : {itemsPerPage: pageSize, currentPage: page, totalItems: count};">
              <td>{{ usuario.Codigo }}</td>
              <td>{{ usuario.Login}}</td>
              <td>{{ usuario.Nome }}</td> 
              <td>{{ usuario.CPF }}</td> 
              <td>{{ usuario.DescSituacao }}</td> 
              <td class="action-icons">
                <a type="button" *ngIf="permiteNovo" matTooltip="Reiniciar senha" (click)="botaReiniciarSenha(usuario.Id)">
                  <i class="fas fa-key"></i>
                </a>
                <a 
                  type="button" 
                  *ngIf="permiteNovo"
                  matTooltip="Visualizar" 
                  [queryParams]="{usuarioid: usuario.Id, Action: 'Show'}"
                  [routerLink]="['/usuario_manter']" 
                >
                  <i class="fas fa-eye"></i>
                </a>
                <a 
                  type="button" 
                  *ngIf="permiteNovo"
                  matTooltip="Editar" 
                  [queryParams]="{usuarioid: usuario.Id, Action: 'Edit'}"
                  [routerLink]="['/usuario_manter']" 
                >
                  <i class="action-icons fas fa-edit"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
  
      <div class="col-md-12 text-center">
        <pagination-controls 
          class="srg-paginacao"
          previousLabel="Anterior" 
          nextLabel="Próximo" 
          [responsive]="true" 
          (pageChange)="handlePageChange($event)"
          [autoHide]="true"
        >
        </pagination-controls>
      </div>
      
    </div>
  </div>
</div>
