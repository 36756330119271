<div class="row">
  <div class="form-group col-lg-4 col-md-4 col-sm-12">
    <label for="codigo_procurador">Código {{tituloConsulta}}</label>
    <div class="input-group input-group-sm">
      <input [disabled]="procurador.Readonly" type="text" class="form-control" name="codigoProcurador" [(ngModel)]="procurador.Codigo" autocomplete="off" (keydown.enter)="consultarPorCodigo()">
      <div *ngIf="!procurador.Readonly" class="input-group-append">
        <button class="btn btn-outline-secondary" type="button" (click)="consultarPorCodigo()"> <i class="bi bi-check"></i> </button>
      </div>
    </div>
  </div>

  <div class="form-group col-sm-12 col-lg-8 col-md-8">
    <label for="nomeProcurador">Nome {{tituloConsulta}}</label>
    <input *ngIf="procurador.Readonly" type="text" class="form-control form-control-sm" name="nome_procurador" [(ngModel)]="procurador.NomeProcurador" readonly>

    <div *ngIf="!procurador.Readonly" class="input-group input-group-sm">
      <input type="text" class="form-control" name="nomeProcurador" [(ngModel)]="procurador.NomeProcurador" readonly>
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" type="button" (click)="consultarPorNome()"> <i class="bi bi-search"></i> </button>
        <button class="btn btn-outline-secondary" type="button" (click)="limpar()"> <i class="bi bi-eraser"></i> </button>
      </div>
    </div>
  </div>
</div>