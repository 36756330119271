<app-formulario-tabs
  [formulario]="formulario"
  [listaComunicacao]="lote?.ListaComunicacao"
  (tabSelecionada)="tabSelecionada($event)"
  (criadorSelecionado)="criadorSelecionado($event)"
></app-formulario-tabs>

<div class="row">
  <div class="col-lg-12 col-sm-12">
    <div class="card card-principal-evento">
      <div class="card-body">
        <!--Ocorrencia Atual-->
        <div *ngIf="!isNewLote()">
          <div class="row">
            <div class="col-lg-12">
              <div class="card card-evento">
                <div class="card-body">
                  <div class="row card-title-evento">
                    <div class="col-6">
                      <h4 class="card-title">
                        <mat-icon svgIcon="nacionalizacao"></mat-icon> Dados de Nacionalização de Embrião
                      </h4>
                    </div>
                    <div class="col-6 text-right">
                      <button
                        *ngIf="ocorrenciaAtual.Id !== '-1'"
                        type="button"
                        class="btn btn-link btn-sm"
                        (click)="documentosDaOcorrencia()"
                      >
                        <i class="far fa-folder"></i> Documentos
                      </button>
                    </div>
                  </div>  

                  <div class="row">
                    <div class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
                      <label for="TipoOrigem">Origem</label>
                      <select
                        class="form-control form-control-sm"
                        name="TipoOrigem"
                        [(ngModel)]="ocorrenciaAtual.TipoFIV"
                      >
                        <option value="1">FIV</option>
                        <option value="2">TE</option>
                      </select>
                    </div>

                    <div class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <label for="formulario">*Total Embriões</label>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          name="QuantidadeViaveis"
                          [(ngModel)]="ocorrenciaAtual.QuantidadeViaveis"
                        />
                    </div>

                    <div class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <label for="PaisOrigem">*País Origem</label>
                        <select
                          class="form-control form-control-sm"
                          name="PaisOrigem"
                          [(ngModel)]="ocorrenciaAtual.PaisOrigem"
                        >
                          <option value="0">Selecione...</option>
                          <option value="1">CAN</option>
                          <option value="2">DEU</option>
                          <option value="3">GBR</option>
                          <option value="4">USA</option>
                        </select>
                    </div>

                    <div class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <label for="formulario">*Código Central</label>
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            name="CodigoCentral"
                            [(ngModel)]="ocorrenciaAtual.CodigoCentral"
                        />
                    </div>

                    <div class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <label for="DataColeta">{{ env.contexto === 'jersey'? '*' : '' }}Data da Cobertura</label>
                        <div class="input-group input-group-sm">
                            <input class="form-control"  [max]="maxDate" [matDatepicker]="DataCobertura" name="DataCobertura" [(ngModel)]="ocorrenciaAtual.DataCobertura" [disabled]="isOcorrenciaReadOnly()">
                            <div *ngIf="!isOcorrenciaReadOnly()" class="input-group-append">
                                <button class="btn btn-outline-secondary" type="button" (click)="DataCobertura.open()"> <i class="bi bi-calendar3"></i> </button>
                            </div>
                        </div>
                        <mat-datepicker [startAt]="ocorrenciaAtual.DataCobertura" #DataCobertura></mat-datepicker>
                    </div>

                    <div class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
                      <label for="DataColeta">{{ env.contexto === 'jersey'? '*' : '' }}Data do Congelamento</label>
                      <div class="input-group input-group-sm">
                          <input class="form-control"  [max]="maxDate" [matDatepicker]="DataCongelamento" name="DataCongelamento" [(ngModel)]="ocorrenciaAtual.DataCongelamento" [disabled]="isOcorrenciaReadOnly()">
                          <div *ngIf="!isOcorrenciaReadOnly()" class="input-group-append">
                              <button class="btn btn-outline-secondary" type="button" (click)="DataCongelamento.open()"> <i class="bi bi-calendar3"></i> </button>
                          </div>
                      </div>
                      <mat-datepicker [startAt]="ocorrenciaAtual.DataCongelamento" #DataCongelamento></mat-datepicker>
                    </div>
                  </div>


                  <div class="row">
                      <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                          <app-consultar-bovino 
                              [bovino]="ocorrenciaAtual.Doadora">
                          </app-consultar-bovino>                      
                      </div>
                      <div class="form-group col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <label for="formulario">Proprietário</label>
                          <input
                              type="text"
                              class="form-control form-control-sm"
                              name="Proprietario"
                              readonly="true"
                              [(ngModel)]="ocorrenciaAtual.Doadora.CodigoNomeProprietario"
                          />
                      </div>
                  </div>

                  <div class="row">
                      <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                          <app-consultar-bovino
                              [bovino]="ocorrenciaAtual.Touro"
                              (BovinoEncontrado)="handleBovinoAtualChange($event)"
                          ></app-consultar-bovino>                      
                      </div>
                      <div class="col-lg12 col-md-12 col-sm-12 col-xs-12">
                          <table class="table table-hover table-striped table-wrap">
                          <thead>
                              <tr>
                                  <th>Código Touro</th>
                                  <th>Nome Touro</th>
                                  <th>Proprietário</th>
                                  <th></th>
                              </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let touro of ocorrenciaAtual.ListaBovinos">
                              <td> {{touro.Codigo}} </td>
                              <td> {{touro.Nome}} </td>
                              <td> {{touro.Proprietario}} </td>
                              <td>
                                <a type="button" matTooltip="Remover touro">
                                  <i class="fas fa-trash" style="color: #6c757d;" (click)="removerBovino(touro.BovinoId)"></i>
                                </a>
                              </td>
                            </tr>
                              <tr *ngIf="ocorrenciaAtual.ListaBovinos?.length === 0">
                                <td colspan="4" class="text-center">Nenhum Touro selecionado</td>
                              </tr>
                          </tbody>
                          </table>
                      </div>
                  </div>
                  <!-- </div> -->
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <app-botoes-acoes-ocorrencia
                [lote]="lote"
                [idOcorrencia]="ocorrenciaAtual.Id"
                (acaoSelecionada)="acaoBotao($event)"
              ></app-botoes-acoes-ocorrencia>
            </div>
          </div>

          <!--Lista de Ocorrencias-->
          <div>
            <div
              *ngIf="ocorrencias.length > 0"
              class="row"
              style="margin-top: 15px"
            >
              <div class="col-lg-12 table-responsive">
                <table class="table table-hover table-header-custom">
                  <thead>
                    <tr>
                      <th>Doadora</th>
                      <th>Touro</th>
                      <th class="text-center">Sexo</th>
                      <th class="text-center">Código Central</th>
                      <th class="text-center">Total Embriões</th>
                      <th class="text-center">Situação</th>
                      <th class="text-center" width="10%">Opções</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="let ev of ocorrencias"
                      [ngClass]="{
                        'ocorrencia-em-destaque': ev.Id === ocorrenciaAtual.Id
                      }"
                    >
                    <td> {{ ev.Doadora.CodigoNomeBovino }} </td>
                    <td> {{ ev.BovinosDaLista }}</td> 
                    <td class="text-center"> {{ commons.getDescricaoSexo(ev.Sexo) }} </td>
                    <td class="text-center">{{ ev.CodigoCentral }}</td>
                    <td class="text-center">{{ ev.QuantidadeViaveis }}</td>
                    <td class="text-center text-nowrap">
                      <div [innerHtml]="descSituacao(ev.Situacao)"></div>
                    </td>
                    <td class="text-center text-nowrap acoes-evento">
                      <i
                        *ngIf="ev.ImputaMulta === 1"
                        matTooltip="Multa Imputada"
                        class="fas fa-dollar-sign"
                      ></i>
                      <i
                        *ngIf="ev.SolicitarDna === 1"
                        matTooltip="Será solicitado DNA no Nascimento"
                        class="fas fa-dna"
                      >
                      </i>
                      <i
                        *ngIf="permiteEdicao(ev.Situacao)"
                        class="fas fa-edit"
                        (click)="editar(ev)"
                      ></i>
                      <i
                        *ngIf="!permiteEdicao(ev.Situacao)"
                        class="fas fa-eye"
                        (click)="editar(ev)"
                      ></i>
                      <i
                        *ngIf="lote.Situacao === 1"
                        class="fas fa-trash-alt"
                        (click)="deletar(ev)"
                      ></i>
                      <i
                        *ngIf="ev.IndInconsitencia"
                        class="fa fa-exclamation-triangle"
                        style="cursor: pointer"
                        matTooltip="{{ ev.Inconsistencia }}"
                      ></i>
                    </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
