import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FiltroConsultaEventosBovinoModel } from 'src/app/models/FiltroConsultaEventosBovinoModel';
import { ApiService } from 'src/app/shared/api.service';
import { EnumTipoEventoSRG, SituacaoOcorrenciaEvento } from 'src/app/shared/enums/Enums';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { Commons } from 'src/app/shared/commons';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-eventos-bovino',
  templateUrl: './eventos-bovino.component.html',
  styleUrls: ['./eventos-bovino.component.scss']
})
export class EventosBovinoComponent implements OnInit {

  @Input() bovinoId: string | null = null;

  page = 1;
  count = 0;
  eventosBovino: any[] = [];
  maxDate: Date = new Date();
  pageSize = environment.pageSize;
  situacoes: { value: number, label: string }[] = [];
  tiposEvento: { value: number, label: string }[] = [];
  formConsulta: FiltroConsultaEventosBovinoModel = new FiltroConsultaEventosBovinoModel();

  constructor(
    private router: Router,
    private api: ApiService,
    public commons: Commons,
    private datePipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.preencherSituacoes();
    this.preencherTiposEvento();
    this.botaoConsultar(false);
  }

  botaoConsultar(exibirErro: boolean = false) {

    if (!this.bovinoId) {
      this.router.navigate(['plantel_consultar']);
      return;
    }

    this.api.consultarEventosBovino(this.bovinoId, this.formConsulta, this.page, this.pageSize).then((result: any) => {
      if (result) {
        if (result.success) {
          this.count = result.TotalItens;
          this.eventosBovino = [];
          result.Itens?.forEach((element: any) => {
            element.Icone = this.commons.getNomeIcone(element.Tipo);
            element.DescDataOcorrencia = element.DataOcorrencia === '0001-01-01T00:00:00' ? '-' : this.datePipe.transform(element.DataOcorrencia, "dd/MM/yyyy");
            this.eventosBovino.push(element);
          });
        } else if (exibirErro === true) {
          this.count = 0;
          this.eventosBovino = [];
          Swal.fire(result.message, '', 'error');
        }
      }
    });
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.botaoConsultar(false);
  }

  preencherSituacoes(): void {
    this.situacoes = [];
    this.situacoes.push({ value: -1, label: 'Todos' });
    this.situacoes.push({ value: SituacaoOcorrenciaEvento.Pendente, label: 'Pendente' });
    this.situacoes.push({ value: SituacaoOcorrenciaEvento.Cancelado, label: 'Cancelado' });
    this.situacoes.push({ value: SituacaoOcorrenciaEvento.Aprovado, label: 'Aprovado' });
    this.situacoes.push({ value: SituacaoOcorrenciaEvento.AprovadoComPendencias, label: 'Aprovado com pendências' });
    this.situacoes.push({ value: SituacaoOcorrenciaEvento.Rejeitado, label: 'Rejeitado' });
    this.situacoes.push({ value: SituacaoOcorrenciaEvento.AguardandoAprovacao, label: 'Aguardando Aprovação' });
    this.situacoes.push({ value: SituacaoOcorrenciaEvento.AguardandoResolucao, label: 'Aguardando Resolução' });
    this.situacoes.push({ value: SituacaoOcorrenciaEvento.AprovadoSuperintendente, label: 'Aprovado pelo Superintendente' });
    this.situacoes.push({ value: SituacaoOcorrenciaEvento.RejeitadoSuperintendente, label: 'Rejeitado pelo Superintendente' });
  }

  preencherTiposEvento(): void {
    this.tiposEvento = [];
    this.tiposEvento.push({ value: -1, label: 'Todos' });
    this.tiposEvento.push({ value: EnumTipoEventoSRG.Padreacao, label: (environment as any).contexto === 'jersey' ? 'Padreação' : 'Cobertura' });
    this.tiposEvento.push({ value: EnumTipoEventoSRG.Nascimento, label: 'Nascimento' });
    this.tiposEvento.push({ value: EnumTipoEventoSRG.FIV, label: 'FIV' });
    this.tiposEvento.push({ value: EnumTipoEventoSRG.Inovulacao, label: (environment as any).contexto === 'jersey' ? 'Inovulação' : 'Implantação' });
    this.tiposEvento.push({ value: EnumTipoEventoSRG.Baixa, label: 'Baixa' });
    this.tiposEvento.push({ value: EnumTipoEventoSRG.Transferencia, label: 'Transferência' });
    this.tiposEvento.push({ value: EnumTipoEventoSRG.Inspecao, label: 'Inspeção' });
    this.tiposEvento.push({ value: EnumTipoEventoSRG.Premiacao, label: 'Premiação' });
    this.tiposEvento.push({ value: EnumTipoEventoSRG.Nacionalizacao, label: 'Nacionalização' });
    this.tiposEvento.push({ value: EnumTipoEventoSRG.NacionalizacaoEmbriao, label: 'Nacionalização de Embrião' });
    this.tiposEvento.push({ value: EnumTipoEventoSRG.ClassificacaoLinear, label: 'Classificação Linear' });
    this.tiposEvento.push({ value: EnumTipoEventoSRG.Pesagem, label: "Pesagem" });
    this.tiposEvento.push({ value: EnumTipoEventoSRG.Carcaca, label: "Abate com Avaliação de Carcaça" });
  }

}
