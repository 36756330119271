import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/shared/api.service';
import { SituacaoRegistroReservado, TipoRegistroReservado } from 'src/app/shared/enums/Enums';
import { IRequestVisualizarRegistrosReservados, IResponseConsultaRegistrosReservados, ItemRegistroReservado } from 'src/app/shared/interfaces/Interfaces';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-registros-reservados',
  templateUrl: './registros-reservados.component.html',
  styleUrls: ['./registros-reservados.component.scss']
})
export class RegistrosReservadosComponent implements OnInit {

  TipoRegistro = TipoRegistroReservado;

  permiteReservarParaAssociacao: boolean = false;
  registrosPorInspetor: ItemRegistroReservado[] = [];
  registrosPorAssociacao: ItemRegistroReservado[] = [];

  constructor(
    private api: ApiService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.consultar();
  }

  private consultar () {
    this.api.consultarRegistrosReservados().then((result: IResponseConsultaRegistrosReservados) => {
      if (result && result.success) {
        this.registrosPorInspetor = result.ItensPorInspetor;
        this.registrosPorAssociacao = result.ItensPorAssociacao;
        this.permiteReservarParaAssociacao = result.IsAssociacaoPrincipal;
      } else {
        this.registrosPorInspetor = [];
        this.registrosPorAssociacao = [];
        Swal.fire(result.message || 'Não foi possível recuperar os registros reservados.', '', 'error');
        this.router.navigate(['plantel_consultar']);
      }
    });
  }

  botaoGerarParaAssociacao() {
    this.router.navigate(['manter_registro_associacao']);
  }
  botaoGerarParaInspetor() {
    this.router.navigate(['manter_registro_inspetor']);
  }

  visualizarPorAssociacao(para: string, tipo: TipoRegistroReservado) {
    this.visualizarRegistroReservado({
      tipo,
      reservadoPara: para,
      situacao: SituacaoRegistroReservado.RESERVADO_ASSOCIACAO,
    });
  }

  visualizarPorInspetor(para: string, tipo: TipoRegistroReservado) {
    this.visualizarRegistroReservado({
      tipo,
      reservadoPara: para,
      situacao: SituacaoRegistroReservado.RESERVADO_INSPETOR,
    });
  }

  private visualizarRegistroReservado({ reservadoPara, situacao, tipo }: IRequestVisualizarRegistrosReservados) {
    this.router.navigate(['registros_reservados_visualizar'], {
      queryParams: {
        tipo,
        situacao,
        para: reservadoPara,
      }
    });
  }

}
