<div class="row">
  <div *ngIf="!tecnico.readonly" class="form-group col-lg-4 col-md-4 col-sm-12">
  
    <label for="codigo_tecnico">Código {{tituloConsulta}}</label>
    <div class="input-group input-group-sm">
      <input type="text" class="form-control" name="codigoTecnico" [(ngModel)]="tecnico.Codigo" autocomplete="off" (keydown.enter)="consultarPorCodigo()">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" type="button" (click)="consultarPorCodigo()"> <i class="bi bi-check"></i> </button>
      </div>
    </div>
  
  </div>
  
  <div [ngClass]="{
      'form-group col-sm-12': true,
      'col-lg-12 col-md-12': tecnico.readonly,
      'col-lg-8 col-md-8': !tecnico.readonly
    }"
  >
    <label for="nomeTecnico">{{tecnico.readonly? '' : 'Nome'}} {{tituloConsulta}}</label>
    <input *ngIf="tecnico.readonly" type="text" class="form-control form-control-sm" name="nome_tecnico" [(ngModel)]="tecnico.NomeTecnico" readonly>
  
    <div *ngIf="!tecnico.readonly" class="input-group input-group-sm">
      <input type="text" class="form-control" name="nomeTecnico" [(ngModel)]="tecnico.NomeTecnico" readonly>
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" type="button" (click)="consultarPorNome()"> <i class="bi bi-search"></i> </button>
        <button class="btn btn-outline-secondary" type="button" (click)="limpar()"> <i class="bi bi-eraser"></i> </button>
      </div>
    </div>
  </div>
</div>