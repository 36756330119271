<div class="row">

    <div class="form-group col-lg-4 col-md-6 col-sm-6 col-xs-12">
        <label for="formularioProtocolo">Nome</label>
        <input type="text" class="form-control form-control-sm" name="formularioProtocolo" [(ngModel)]="formConsulta.nome">
    </div>
    
    <div class="form-group col-lg-2 col-md-6 col-sm-6 col-xs-12">
        <label for="formularioProtocolo">UF</label>
        <select class="form-control form-control-sm" name="UFs" [(ngModel)]="formConsulta.uf">
          <option *ngFor="let uf of UFs" [value]="uf.value" [label]="uf.label"></option>
        </select>
    </div>

    <div class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <label>Exposição De:</label>
        <div class="input-group input-group-sm">
          <input class="form-control" name="exposicao_de" [matDatepicker]="exposicaoDe" [(ngModel)]="formConsulta.exposicaoDe"> 
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button" (click)="exposicaoDe.open()"> <i class="bi bi-calendar3"></i> </button>
          </div>
        </div>
        <mat-datepicker [startAt]="formConsulta.exposicaoDe" #exposicaoDe></mat-datepicker>
    </div>

    <div class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <label>Exposição Até:</label>
        <div class="input-group input-group-sm">
          <input class="form-control" name="exposicao_ate" [matDatepicker]="exposicaoAte" [(ngModel)]="formConsulta.exposicaoAte"> 
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button" (click)="exposicaoAte.open()"> <i class="bi bi-calendar3"></i> </button>
          </div>
        </div>
        <mat-datepicker [startAt]="formConsulta.exposicaoAte" #exposicaoAte></mat-datepicker>
    </div>
      
</div>