import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/shared/api.service';
import { Commons } from 'src/app/shared/commons';
import { TipoCertificado, TipoGrauSangue } from 'src/app/shared/enums/Enums';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-crias-bovino',
  templateUrl: './crias-bovino.component.html',
  styleUrls: ['./crias-bovino.component.scss']
})
export class CriasBovinoComponent implements OnInit {

  @Input() bovinoId: string | null = null;

  page = 1;
  count = 0;
  crias: any[] = [];
  pageSize = environment.pageSize;

  constructor(
    private router: Router,
    private api: ApiService,
    public commons: Commons,
  ) { }

  ngOnInit(): void {
    this.consultar();
  }

  consultar(): void {

    if (!this.bovinoId) {
      this.router.navigate(['plantel_consultar']);
      return;
    }

    this.api.consultarCriasBovino(this.bovinoId, this.page, this.pageSize).then((result: any) => {
      if (result) {
        if (result.success) {
          this.count = result.TotalItens;
          this.crias = [];
          result.Itens?.forEach((element: any) => {
            element.TipoGrauSangue = TipoGrauSangue[element.TipoGrauSangue];
            element.Certificado = TipoCertificado[element.Certificado];
            this.crias.push(element);
          });
        } else {
          Swal.fire(result.message, '', 'error');
        }
      }
    });
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.consultar();
  }

}
