import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { BovinoService } from '../bovino/shared/bovino.service';
import { TipoGrauSangue } from './enums/Enums';

@Injectable({
    providedIn: 'root'
})
export class CalculoGrauSangueService {
    tipoGrauSangue: number = -1;

    constructor(
        private bovinoService: BovinoService
    ) {
    }

    calculaGrauSangue(grauSangueReprodutor: number | string, grauSangueMatriz: number | string) {
        // PC x PA = Não é permitido
        // PA x PC = Não é permitido
        // PO x PA4 para fêmea = Não é permitido
        if ((grauSangueReprodutor == TipoGrauSangue.PC && grauSangueMatriz == TipoGrauSangue.PA)
            || (grauSangueReprodutor == TipoGrauSangue.PA && grauSangueMatriz == TipoGrauSangue.PC)
            || (grauSangueReprodutor == TipoGrauSangue.PO && grauSangueMatriz == TipoGrauSangue.PA4)) {
            return Swal.fire('Não foi possível recuperar os dados do criador.', '', 'error');
        }
        // 38A x 34B = 12B
        // 38P x 34B = 12B
        // PA x 34B = 12B
        // PC x 34B = 12B
        // PO x 34B = 12B
        // ZT x 34B = 12B
        else if (
            (grauSangueReprodutor == TipoGrauSangue._38A && grauSangueMatriz == TipoGrauSangue._34B) ||
            (grauSangueReprodutor == TipoGrauSangue._38P && grauSangueMatriz == TipoGrauSangue._34B) ||
            (grauSangueReprodutor == TipoGrauSangue.PA && grauSangueMatriz == TipoGrauSangue._34B) ||
            (grauSangueReprodutor == TipoGrauSangue.PC && grauSangueMatriz == TipoGrauSangue._34B) ||
            (grauSangueReprodutor == TipoGrauSangue.PO && grauSangueMatriz == TipoGrauSangue._34B) ||
            (grauSangueReprodutor == TipoGrauSangue.ZT && grauSangueMatriz == TipoGrauSangue._34B)
        ) {
            this.tipoGrauSangue = TipoGrauSangue._12B;
        }
        // 38A x ZT = 34B
        // 38B x ZT = 34B
        // 38P x ZT = 34B
        // ZT x 12B = 34B
        else if (
            (grauSangueReprodutor == TipoGrauSangue._38A && grauSangueMatriz == TipoGrauSangue.ZT) ||
            (grauSangueReprodutor == TipoGrauSangue._38B && grauSangueMatriz == TipoGrauSangue.ZT) ||
            (grauSangueReprodutor == TipoGrauSangue._38P && grauSangueMatriz == TipoGrauSangue.ZT) ||
            (grauSangueReprodutor == TipoGrauSangue.ZT && grauSangueMatriz == TipoGrauSangue._12B)
        ) {
            this.tipoGrauSangue = TipoGrauSangue._34B;
        }
        // 12B x 14B = 38P
        // 14B x 12B = 38P
        // 34B x PC = 38P
        // 34B x PO = 38P
        // 38A x 38B = 38P
        // 38P x 38B = 38P
        // PO x 34B = 38P
        else if (
            (grauSangueReprodutor == TipoGrauSangue._12B && grauSangueMatriz == TipoGrauSangue._14B) ||
            (grauSangueReprodutor == TipoGrauSangue._14B && grauSangueMatriz == TipoGrauSangue._12B) ||
            (grauSangueReprodutor == TipoGrauSangue._34B && grauSangueMatriz == TipoGrauSangue.PC) ||
            (grauSangueReprodutor == TipoGrauSangue._34B && grauSangueMatriz == TipoGrauSangue.PO) ||
            (grauSangueReprodutor == TipoGrauSangue._38A && grauSangueMatriz == TipoGrauSangue._38B) ||
            (grauSangueReprodutor == TipoGrauSangue._38P && grauSangueMatriz == TipoGrauSangue._38B) ||
            (grauSangueReprodutor == TipoGrauSangue.PO && grauSangueMatriz == TipoGrauSangue._38P)
        ) {
            this.tipoGrauSangue = TipoGrauSangue._38P;
        }
        // PC1 x PC1 = PC1
        // PC1 x PC2 = PC1
        // PO x PA = PC1
        // PO x PC = PC1
        else if (
            (grauSangueReprodutor == TipoGrauSangue.PC1 && grauSangueMatriz == TipoGrauSangue.PC1) ||
            (grauSangueReprodutor == TipoGrauSangue.PC1 && grauSangueMatriz == TipoGrauSangue.PC2) ||
            (grauSangueReprodutor == TipoGrauSangue.PO && grauSangueMatriz == TipoGrauSangue.PA) ||
            (grauSangueReprodutor == TipoGrauSangue.PO && grauSangueMatriz == TipoGrauSangue.PC)
        ) {
            this.tipoGrauSangue = TipoGrauSangue.PC1;
        }
        // 38A x 12B = 38B
        // 38P x 12B = 38B
        else if (
            (grauSangueReprodutor == TipoGrauSangue._38A && grauSangueMatriz == TipoGrauSangue._38B) ||
            (grauSangueReprodutor == TipoGrauSangue._38P && grauSangueMatriz == TipoGrauSangue._38B)
        ) {
            this.tipoGrauSangue = TipoGrauSangue._38B;
        }
        // PC x PC = PC
        // PO x 16B = PC
        else if (
            (grauSangueReprodutor == TipoGrauSangue.PC && grauSangueMatriz == TipoGrauSangue.PC) ||
            (grauSangueReprodutor == TipoGrauSangue.PO && grauSangueMatriz == TipoGrauSangue._16B)
        ) {
            this.tipoGrauSangue = TipoGrauSangue.PC;
        }
        // PO x PC4 = PO
        // PO x PO = PO
        // PO x PA4 = PO (apenas macho)
        else if (
            (grauSangueReprodutor == TipoGrauSangue.PO && grauSangueMatriz == TipoGrauSangue.PC4) ||
            (grauSangueReprodutor == TipoGrauSangue.PO && grauSangueMatriz == TipoGrauSangue.PO) ||
            (grauSangueReprodutor == TipoGrauSangue.PO && grauSangueMatriz == TipoGrauSangue.PA4)
        ) {
            this.tipoGrauSangue = TipoGrauSangue.PO;
        }
        // PO x 12B = 14B
        else if (grauSangueReprodutor == TipoGrauSangue.PO && grauSangueMatriz == TipoGrauSangue._12B) {
            this.tipoGrauSangue = TipoGrauSangue._14B;
        }
        // PO x 18B = 16B
        else if (grauSangueReprodutor == TipoGrauSangue.PO && grauSangueMatriz == TipoGrauSangue._18B) {
            this.tipoGrauSangue = TipoGrauSangue._16B;
        }
        // PO x 14B = 18B
        else if (grauSangueReprodutor == TipoGrauSangue.PO && grauSangueMatriz == TipoGrauSangue._14B) {
            this.tipoGrauSangue = TipoGrauSangue._18B;
        }
        // 38P x 38P = 38A
        else if (grauSangueReprodutor == TipoGrauSangue._38P && grauSangueMatriz == TipoGrauSangue._38P) {
            this.tipoGrauSangue = TipoGrauSangue._38A;
        }
        // PA x PA = PA
        else if (grauSangueReprodutor == TipoGrauSangue.PA && grauSangueMatriz == TipoGrauSangue.PA) {
            this.tipoGrauSangue = TipoGrauSangue.PA;
        }
        // PO x PC1 = PC2
        else if (grauSangueReprodutor == TipoGrauSangue.PO && grauSangueMatriz == TipoGrauSangue.PC1) {
            this.tipoGrauSangue = TipoGrauSangue.PC2;
        }
        // PO x PC2 = PC3
        else if (grauSangueReprodutor == TipoGrauSangue.PO && grauSangueMatriz == TipoGrauSangue.PC2) {
            this.tipoGrauSangue = TipoGrauSangue.PC3;
        }
        // PO x PC3 = PC4
        else if (grauSangueReprodutor == TipoGrauSangue.PO && grauSangueMatriz == TipoGrauSangue.PC3) {
            this.tipoGrauSangue = TipoGrauSangue.PC4;
        }
        // ZT x ZT = ZT
        else if (grauSangueReprodutor == TipoGrauSangue.ZT && grauSangueMatriz == TipoGrauSangue.ZT) {
            this.tipoGrauSangue = TipoGrauSangue.ZT;
        }
        // CCG x CCG = CGG
        else if (grauSangueReprodutor == TipoGrauSangue.CCG && grauSangueMatriz == TipoGrauSangue.CCG) {
            this.tipoGrauSangue = TipoGrauSangue.CCG;
        }
        //PAI 38A x MÃE ZT
        else if (grauSangueReprodutor == TipoGrauSangue._38A && grauSangueMatriz == TipoGrauSangue.ZT) {
            this.tipoGrauSangue = TipoGrauSangue._34B;
        }
        //PAI 38B x MÃE ZT
        else if (grauSangueReprodutor == TipoGrauSangue._38B && grauSangueMatriz == TipoGrauSangue.ZT) {
            this.tipoGrauSangue = TipoGrauSangue._34B;
        }
        //PAI 38P x MÃE ZT
        else if (grauSangueReprodutor == TipoGrauSangue._38P && grauSangueMatriz == TipoGrauSangue.ZT) {
            this.tipoGrauSangue = TipoGrauSangue._34B;
        }
        //PAI PA x MÃE ZT
        else if (grauSangueReprodutor == TipoGrauSangue.PA && grauSangueMatriz == TipoGrauSangue.ZT) {
            this.tipoGrauSangue = TipoGrauSangue._12B;
        }
        //PAI PC x MÃE ZT
        else if (grauSangueReprodutor == TipoGrauSangue.PC && grauSangueMatriz == TipoGrauSangue.ZT) {
            this.tipoGrauSangue = TipoGrauSangue._12B;
        }
        //PAI PO x MÃE ZT
        else if (grauSangueReprodutor == TipoGrauSangue.PO && grauSangueMatriz == TipoGrauSangue.ZT) {
            this.tipoGrauSangue = TipoGrauSangue._12B;
        }
        //PAI ZT x MÃE 12B
        else if (grauSangueReprodutor == TipoGrauSangue.ZT && grauSangueMatriz == TipoGrauSangue._12B) {
            this.tipoGrauSangue = TipoGrauSangue._34B;
        }
        //PAI ZT x MÃE PA
        else if (grauSangueReprodutor == TipoGrauSangue.ZT && grauSangueMatriz == TipoGrauSangue.PA) {
            this.tipoGrauSangue = TipoGrauSangue._12B;
        }
        //PAI ZT x MÃE PC
        else if (grauSangueReprodutor == TipoGrauSangue.ZT && grauSangueMatriz == TipoGrauSangue.PC) {
            this.tipoGrauSangue = TipoGrauSangue._12B;
        }
        //PAI ZT x MÃE PO
        else if (grauSangueReprodutor == TipoGrauSangue.ZT && grauSangueMatriz == TipoGrauSangue.PO) {
            this.tipoGrauSangue = TipoGrauSangue._12B;
        }
        //PAI ZT x MÃE PO
        else if (grauSangueReprodutor == TipoGrauSangue.ZT && grauSangueMatriz == TipoGrauSangue.PO) {
            this.tipoGrauSangue = TipoGrauSangue._12B;
        }
        //PAI PO X MÃE CCG
        else if (grauSangueReprodutor == TipoGrauSangue.PO && grauSangueMatriz == TipoGrauSangue.CCG) {
            this.tipoGrauSangue = TipoGrauSangue._14B;
        }
        return this.tipoGrauSangue;
    }

    calcularPercentual(tipo: TipoGrauSangue) {
        switch (tipo) {
            case TipoGrauSangue._12B: return "1/2";
            case TipoGrauSangue._14B: return "3/4";
            case TipoGrauSangue._16B: return "15/16";
            case TipoGrauSangue._18B: return "7/8";
            case TipoGrauSangue._34B: return "1/4";
            case TipoGrauSangue._38A: return "5/8";
            case TipoGrauSangue._38B: return "9/16";
            case TipoGrauSangue._38P: return "5/8";
            case TipoGrauSangue.PC: return "31/32";
            case TipoGrauSangue.PC1: return "63/64";
            case TipoGrauSangue.PC2: return "127/128";
            case TipoGrauSangue.PC3: return "255/256";
            case TipoGrauSangue.PC4: return "511/512";
            case TipoGrauSangue.PA: return "31/32";
            case TipoGrauSangue.PO: return "1/1";
            case TipoGrauSangue.CCG: return "1/2";
            case TipoGrauSangue.ZT: return "0";
            default: return "";
        }
    }
}
