import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/shared/api.service';
import { Commons } from 'src/app/shared/commons';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-premiacao-bovino',
  templateUrl: './premiacao-bovino.component.html',
  styleUrls: ['./premiacao-bovino.component.scss']
})
export class PremiacaoBovinoComponent implements OnInit {

  @Input() bovinoId: string | null = null;

  page = 1;
  count = 0;
  premiacoes: any[] = [];
  pageSize = environment.pageSize;

  constructor(
    private router: Router,
    private api: ApiService,
    public commons: Commons,
  ) { }

  ngOnInit(): void {
    this.consultar();
  }

  consultar(): void {

    if (!this.bovinoId) {
      this.router.navigate(['plantel_consultar']);
      return;
    }

    this.api.consultarPremiacoesBovino(this.bovinoId, this.page, this.pageSize).then((result: any) => {
      if (result) {
        if (result.success) {
          this.count = result.TotalItens;
          this.premiacoes = result.Itens;
        } 
      }
    });

  }

  handlePageChange(event: number): void {
    this.page = event;
    this.consultar();
  }

}
