import { BovinoModel } from "./BovinoModel";
import { CriadorModel } from "./CriadorModel";

export class FiltroConsultaCertificadosModel {
    proprietario: CriadorModel;
    emissaoDe: string;
    emissaoAte: string;
    assinado: number;
    certificado: number;
    bovino: BovinoModel;
    classeCertificado: number;

    constructor() {
        this.proprietario = new CriadorModel();
        this.emissaoDe = "";
        this.emissaoAte = "";
        this.assinado = 2;
        this.certificado = 1;
        this.bovino = new BovinoModel();
        this.classeCertificado = -1;
    }
}
