<div class="row">

  <div class="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
    <label for="protocolo">Doadora</label>
    <input
      type="text"
      class="form-control form-control-sm"
      name="Doadora"
      [(ngModel)]="codigoNomeBovino"
      disabled
    />
  </div>

  <div class="form-group col-lg-2 col-md-6 col-sm-5 col-xs-12">
    <label for="TipoOrigem">Origem</label>
    <select
      class="form-control form-control-sm"
      name="TipoOrigem"
      disabled
      [(ngModel)]="embriao.TipoFIV"
    >
      <option value="1">FIV</option>
      <option value="2">TE</option>
    </select>
  </div>

  <div *ngIf="tipoEvento == 52" class="form-group col-lg-4 col-md-12 col-sm-7 col-xs-12">
    <label for="TipoOrigem">Tipo</label>
    <select
      class="form-control form-control-sm"
      name="TipoOrigem"
      disabled
      [(ngModel)]="embriao.Tipo"
    >
      <option *ngFor="let tipo of tiposTransferenciaFiv" [value]="tipo.value" [label]="tipo.label"></option>
    </select>
  </div>

  <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <label>Touro(s)</label>
    <textarea 
      readonly
      type="text" 
      name="touros" 
      class="form-control form-control-sm"
      [(ngModel)]="embriao.BovinosDaLista"
      style="min-height: calc(1.5em + 0.5rem + 2px); height: 50px;"
    >
    </textarea>
  </div>

  <div *ngIf="tipoEvento == 52" class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
    <label for="DataColeta">Data da Coleta</label>
    <div class="input-group input-group-sm">
      <input class="form-control" [matDatepicker]="dataColeta" name="DataColeta" [(ngModel)]="embriao.DataColeta" disabled>
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" type="button" (click)="dataColeta.open()"> <i class="bi bi-calendar3"></i> </button>
      </div>
    </div>
    <mat-datepicker [startAt]="embriao.DataColeta" #dataColeta></mat-datepicker>
  </div>

  <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
    <label for="DataCobertura">Data da Cobertura</label>
    <div class="input-group input-group-sm">
      <input class="form-control" [matDatepicker]="dataCobertura" name="DataCobertura" [(ngModel)]="embriao.DataCobertura" disabled>
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" type="button" (click)="dataCobertura.open()"> <i class="bi bi-calendar3"></i> </button>
      </div>
    </div>
    <mat-datepicker [startAt]="embriao.DataCobertura" #dataCobertura></mat-datepicker>
  </div>

  <div *ngIf="tipoEvento == 52" class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
    <label for="formulario">Embriões Congelados</label>
    <input
      type="number"
      class="form-control form-control-sm"
      name="QuantidadeColetados"
      disabled
      [(ngModel)]="embriao.QuantidadeCongelados"
    />
  </div>

  <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
    <label for="formulario">Embriões Viáveis</label>
    <input
      type="number"
      class="form-control form-control-sm"
      name="QuantidadeViaveis"
      disabled
      [(ngModel)]="embriao.QuantidadeViaveis"
    />
  </div>

</div>