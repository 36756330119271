import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FiltroConsultaLaudoDnaModel } from 'src/app/models/FiltroConsultaLaudoDnaModel';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { ApiService } from '../api.service';
import { QualificaLaudoDNA } from '../enums/Enums';
import { IItemLaudoDna, IResponseConsultaLaudoDna } from '../interfaces/Interfaces';

@Component({
  selector: 'app-consultar-laudo-dna-modal',
  templateUrl: './consultar-laudo-dna-modal.component.html',
  styleUrls: ['./consultar-laudo-dna-modal.component.scss']
})
export class ConsultarLaudoDnaModalComponent implements OnInit {

  page = 1;
  count = 0;
  pageSize = environment.pageSize;
  listaQualifica: { value: number, label: string }[] = [];

  laudos: IItemLaudoDna[] = [];
  formConsulta: FiltroConsultaLaudoDnaModel = new FiltroConsultaLaudoDnaModel();

  @Output()
  laudoSelecionado: EventEmitter<IItemLaudoDna> = new EventEmitter<IItemLaudoDna>();

  constructor(
    private api: ApiService,
    public dialogRef: MatDialogRef<ConsultarLaudoDnaModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FiltroConsultaLaudoDnaModel | null
  ) {
    if (data) this.formConsulta = data;
  }

  ngOnInit(): void {
    this.preencherQualifica();
    this.formConsulta.Qualifica = QualificaLaudoDNA.Sim;
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.consultar();
  }

  botaoBuscar(){
    this.page = 1;
    this.consultar();
  }

  consultar() {
    this.formConsulta.NomeAnimal = '';
    this.formConsulta.CodigoAnimal = '';
    
    this.api.consultarLaudoDna(this.formConsulta, this.page, this.pageSize).then((result: IResponseConsultaLaudoDna) => {
      if (result && result.success) {
        this.laudos = result.Itens;
        this.count = result.TotalItens;
      } else {
        this.page = 1;
        this.count = 0;
        this.laudos = [];
        Swal.fire(result.message || 'A operação não pode ser realizada.', '', 'error');
      }
    });
  }

  selecionarLaudo(laudo: IItemLaudoDna) {
    this.laudoSelecionado.emit(laudo);
    this.dialogRef.close();
  }

  fechar = () => this.dialogRef.close();

  preencherQualifica() {
    this.listaQualifica = [];

    this.listaQualifica.push({ value: QualificaLaudoDNA.Sim, label: 'Sim'} );
    this.listaQualifica.push({ value: QualificaLaudoDNA.Nao, label: 'Não'} );
  }

  getDescricaoQualifica(qualifica: QualificaLaudoDNA): string {
    switch (qualifica) {
      case QualificaLaudoDNA.Nao: return 'Não';
      case QualificaLaudoDNA.Sim: return 'Sim';
      case QualificaLaudoDNA.Permanente: return 'Permanente';
      default: return '';
    }
  }

  botaoConsultar() {
    this.api.consultarLaudoDna(this.formConsulta, this.page, this.pageSize).then((result: IResponseConsultaLaudoDna) => {
      if (result && result.success) {
        this.laudos = result.Itens;
        this.count = result.TotalItens;
      } else {
        this.page = 1;
        this.count = 0;
        this.laudos = [];
        Swal.fire(result.message || 'A operação não pode ser realizada.', '', 'error');
      }
    });
  }

}
