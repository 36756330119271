import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConsultaSugestaoFivModel } from 'src/app/models/ConsultaSugestaoFivModel';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { ApiService } from '../api.service';
import { Commons } from '../commons';
import { Nacionalizado, TipoFiv } from '../enums/Enums';
import { IItenFiv, IResponseConsultaSugestaoFiv } from '../interfaces/Interfaces';

interface IDataInput {
  CriadorId: string;
  Doadora: {
    Id: string;
    CodigoBovino: string;
    NomeBovino: string;
    IdProprietario: string;
    IdProprietarioConsulta: string;
  }
}

@Component({
  selector: 'app-consultar-sugestao-fiv-modal',
  templateUrl: './consultar-sugestao-fiv-modal.component.html',
  styleUrls: ['./consultar-sugestao-fiv-modal.component.scss']
})
export class ConsultarSugestaoFivModalComponent implements OnInit {

  readonly env = environment;

  page = 1;
  count = 0;
  pageSize = environment.pageSize;

  sugestoes: IItenFiv[] = [];
  formConsulta: ConsultaSugestaoFivModel = new ConsultaSugestaoFivModel();

  @Output()
  embriaoSelecionado: EventEmitter<IItenFiv> = new EventEmitter<IItenFiv>();

  constructor(
    public commons: Commons,
    private api: ApiService,
    private datePipe: DatePipe,
    public dialogRef: MatDialogRef<ConsultarSugestaoFivModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDataInput | null
  ) {
    if (data) {

      this.formConsulta.Doadora.Id = data.Doadora.Id;
      this.formConsulta.Doadora.CodigoBovino = data.Doadora.CodigoBovino;
      this.formConsulta.Doadora.NomeBovino = data.Doadora.NomeBovino;
      this.formConsulta.Doadora.Readonly = (data.Doadora.Id !== '' && data.Doadora.Id !== '-1');

      this.formConsulta.CriadorId = data.CriadorId || '-1';
      this.formConsulta.Doadora.IdProprietarioConsulta = data.Doadora.IdProprietarioConsulta;
      this.formConsulta.Doadora.IdProprietario = data.Doadora.IdProprietario;
    }

    this.formConsulta.SugestaoDe = this.handlePeriodoSugestao();
  }
  
  ngOnInit(): void {
  }
  
  private handlePeriodoSugestao (): string {
    const now = new Date();
    return new Date(now.setFullYear(now.getFullYear() - 1)).toISOString();
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.consultar();
  }

  botaoBuscar(){
    this.page = 1;
    this.consultar();
  }
  
  consultar() {    
    this.api.consultarSugestaoFiv(this.formConsulta, this.pageSize, this.page).then((result: IResponseConsultaSugestaoFiv) => {
      this.sugestoes = [];
      if (result && result.success) {
        if(result.Itens && result.Itens.length > 0){
          result.Itens.forEach((element: any) => {
            element.DescDataColeta = element.DataColeta === '0001-01-01T00:00:00' ? '-' : this.datePipe.transform(element.DataColeta,"dd/MM/yyyy");
            element.DescDataCobertura = element.DataCobertura === '0001-01-01T00:00:00' ? '-' : this.datePipe.transform(element.DataCobertura,"dd/MM/yyyy");
            this.sugestoes.push(element);
          });
          this.count = result.TotalItens;
      }else{
        Swal.fire(result.message || 'Não foi possível encontrar nenhum registro com os filtros informados', '', 'error');
      }
      } else {
        this.page = 1;
        this.count = 0;
        this.sugestoes = [];
        Swal.fire(result.message || 'A operação não pode ser realizada.', '', 'error');
      }
    });
  }

  selecionarSugestao(sugestao: IItenFiv) {
    this.embriaoSelecionado.emit(sugestao);
    this.dialogRef.close();
  }

  fechar = () => this.dialogRef.close();

  getDescrOrigem = (tipo: number): string => (tipo == TipoFiv.FIV? 'FIV' : 'TE');
  
  getDescNacionalizado = (tipo: number): string => (tipo == Nacionalizado.Sim? 'Sim' : 'Não');

}
