import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/shared/api.service';
import { SituacaoControleLeiteiro, SituacaoItemControleLeiteiro } from '../../shared/enums/Enums';
import { ControleLeiteiroModel } from '../../models/ControleLeiteiroModel';
import Swal from 'sweetalert2';
import { Commons } from 'src/app/shared/commons';

@Component({
  selector: 'app-controle-leiteiro-analisar',
  templateUrl: './controle-leiteiro-analisar.component.html',
  styleUrls: ['./controle-leiteiro-analisar.component.scss']
})
export class ControleLeiteiroAnalisarComponent implements OnInit {

  id: string | null = null;
  apenasLeitura:boolean = true;
  controleLeiteiro: ControleLeiteiroModel = new ControleLeiteiroModel();

  constructor(
    private router: Router,
    private api: ApiService,
    private commons: Commons,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.controleLeiteiro.arquivo.itens = [];
    this.id = this.route.snapshot.queryParamMap.get('id');

    if (!this.id) {
      Swal.fire('A operação não pode ser realizada.', '', 'error');
      this.router.navigate(['controle_leiteiro_consultar']);
      return;
    }

    this.api.analisarArquivoControleLeiteiro(this.id || '').then((result: any) => {
      if (result && result.success) {

        this.controleLeiteiro.descricao = result.Descricao;
        this.controleLeiteiro.dataImportacao = result.DataImportacao;
        this.controleLeiteiro.arquivo.nome = result.NomeArquivo;
        this.controleLeiteiro.arquivo.itens = result.Itens;

        result.Situacao = SituacaoControleLeiteiro[result.Situacao];
        const situacao: keyof typeof SituacaoControleLeiteiro = result.Situacao;
        this.apenasLeitura = SituacaoControleLeiteiro[situacao] === SituacaoControleLeiteiro.Aprovado;

      } else {
        Swal.fire(result.message, '', 'error');
        this.router.navigate(['controle_leiteiro_consultar']);
      }
    });
  }
  
  obterCorDeFundo(situacao: any): string|null {

    if (situacao === SituacaoItemControleLeiteiro.ProcessamentoOk)
      return '#ccffe5';
    
    if (
      situacao === SituacaoItemControleLeiteiro.ImportacaoErro || 
      situacao === SituacaoItemControleLeiteiro.ProcessamentoErro
    )
      return '#f69696';

    return null;
  }
  
  downloadArquivo(): void {
    this.api.downloadArqControleLeiteiro(this.id || '').then((result: any) => {
      if (!result) {
        Swal.fire('Não foi possível realizar a operação.', '', 'error');
        return;
      } 

      const blob: Blob = this.commons.base64toBlob(result);
      const blobURL: string = URL.createObjectURL(blob);

      const anchor: HTMLAnchorElement = document.createElement('a');
      anchor.href = blobURL;
      anchor.download = this.controleLeiteiro.arquivo.nome;
      anchor.click();
      anchor.remove();
    });
  }

  async aprovarImportacao(): Promise<void> {
    const response = await this.commons.modalConfirmacao({
      title: 'A importação do controle leiteiro será realizada apenas para os animais cujos dados tenham sido validados pelo sistema. \n' + 
      'Confirma a importação  deste arquivo de controle leiteiro?'
    });

    if (!response) return;

    this.api.confirmarImportacaoArqControleLeiteiro(this.id || '').then((result: any) => {
      if (result && result.success) {
        Swal.fire(result.message, '', 'success');
        this.router.navigate(['controle_leiteiro_consultar']);
      } else {
        Swal.fire(result.message, '', 'error');
      }
    });
  }

  async descartarImportacao(): Promise<void> {    
    const response = await this.commons.modalConfirmacao({
      cancelButtonText: 'Não, desejo manter',       // se for preciso adicionar icone no botão, bastar colocar a tag <i></i> junto ao texto
      confirmButtonText: 'Sim, desejo descartar',
      title: 'Confirma o descarte do arquivo de importação do controle leiteiro.'
    });
    if (!response) return;

    this.api.descartarArquivoControleLeiteiro(this.id || '').then((result: any) => {
      if (result && result.success) {
        Swal.fire(result.message, '', 'success');
        this.router.navigate(['controle_leiteiro_consultar']);
      } else {
        Swal.fire(result.message, '', 'error');
      }
    });
  }

}
