export class FiltroConsultaUsuariosModel{
  codigo: string;
  cpf: string;  
  nome: string;
  grupo: number;
  vinculacao: string;
  situacao: number;
  filiadaReadonly: boolean;

  constructor(){
    this.codigo = "";
    this.cpf = "";
    this.nome = "";
    this.grupo = -1;
    this.vinculacao = "";
    this.situacao = -1;
    this.filiadaReadonly = true;
  }
}
