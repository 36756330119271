<div class="row">
  <div class="col-lg-12">

    <h3>Dados do criador</h3>

    <div class="row">

      <div class="form-group col-lg-4 col-md-6 col-sm-6 col-xs-12">
        <label>*{{formValidaCriador.tipoPessoa == 1? 'CPF' : 'CNPJ'}}</label>
        <input disabled name="cpf_cnpj" type="text" class="form-control form-control-sm"
          [ngModel]="formValidaCriador.cpf_cnpj"
          mask="{{formValidaCriador.tipoPessoa == 1? '000.000.000-00' : '00.000.000/0000-00'}}">
      </div>

      <div class="form-group col-lg-8 col-md-6 col-sm-6 col-xs-12" *ngIf="env.contexto === 'jersey'">
        <app-selecionar-filiadas [readonly]="true" [filiadaId]="formValidaCriador.vinculacao"
          defaultFirstSelectedLabel="Selecione...">
        </app-selecionar-filiadas>
      </div>

      <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
        <label>Código</label>
        <input disabled name="codigo" type="text" class="form-control form-control-sm" [(ngModel)]="criador.codigo">
      </div>

      <div class="form-group col-md-6 col-sm-6 col-xs-12" [ngClass]="{'col-lg-3': env.contexto === 'jersey', 'col-lg-5': env.contexto === 'wagyu'}">
        <label>*{{formValidaCriador.tipoPessoa == 1? 'Nome' : 'Razão Social'}}</label>
        <input [disabled]="readOnly" name="nome" type="text" class="form-control form-control-sm" [(ngModel)]="criador.nome">
      </div>

      <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
        <label>*E-mail</label>
        <input [disabled]="readOnly" name="email" type="text" class="form-control form-control-sm" [(ngModel)]="criador.email">
      </div>

      <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
        <label>E-mail secundário</label>
        <input [disabled]="readOnly" name="email" type="text" class="form-control form-control-sm" [(ngModel)]="criador.emailSecundario">
      </div>

      <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
        <label>*Usuário</label>
        <input [disabled]="readOnly" name="usuario" type="text" class="form-control form-control-sm" [(ngModel)]="criador.usuario">
      </div>

      <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
        <label for="formularioProtocolo">Situação</label>
        <select [disabled]="readOnly" class="form-control form-control-sm" name="situacao" [(ngModel)]="criador.situacao">
          <option value="1" label="Ativo"></option>
          <option value="2" label="Inativo"></option>
        </select>
      </div>

      <div *ngIf="formValidaCriador.tipoPessoa == 2" class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
        <label>Inscrição Estadual</label>
        <input [disabled]="readOnly" name="inscricao_estadual" type="text" class="form-control form-control-sm"
          [(ngModel)]="criador.inscricaoEstadual">
      </div>

      <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
        <label>Data de cadastro</label>
        <div class="input-group input-group-sm">
          <input 
            disabled
            class="form-control" 
            name="data_cadastro" 
            [matDatepicker]="dataCadastro" 
            [ngModel]="criador.dataCadastro"
          >
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button" (click)="dataCadastro.open()"> 
              <i class="bi bi-calendar3"></i> 
            </button>
          </div>
        </div>
        <mat-datepicker [startAt]="criador" #dataCadastro></mat-datepicker>
      </div>

    </div>

    <app-endereco-manter [endereco]="criador.endereco" [readOnly]="readOnly"></app-endereco-manter>

    <app-telefones-manter [celular]="criador.celular" [residencial]="criador.residencial" [readOnly]="readOnly"></app-telefones-manter>

    <div class="row">

      <div *ngIf="formValidaCriador.tipoPessoa == 1" class="form-group col-lg-6 col-md-12 col-sm-12 col-xs-12">
        <app-afixo-manter [criador]="criador" [readOnly]="readOnly"></app-afixo-manter>
      </div>

      <div class="form-group col-xs-12" [ngClass]="{'col-lg-2 col-md-4 col-sm-4': env.contexto === 'wagyu', 'col-lg-3 col-md-6 col-sm-6': env.contexto === 'jersey'}">
        <label for="formularioProtocolo">Sócio</label>
        <select [disabled]="readOnly" class="form-control form-control-sm" name="socio" [(ngModel)]="criador.socio">
          <option value="1" label="Sim"></option>
          <option value="2" label="Não"></option>
        </select>
      </div>
      
      <div class="form-group col-xs-12" [ngClass]="{'col-lg-2 col-md-4 col-sm-4': env.contexto === 'wagyu', 'col-lg-3 col-md-6 col-sm-6': env.contexto === 'jersey'}">
        <label for="formularioProtocolo">Suspenso</label>
        <select [disabled]="readOnly" class="form-control form-control-sm" name="suspenso" [(ngModel)]="criador.suspenso">
          <option value="1" label="Sim"></option>
          <option value="2" label="Não"></option>
        </select>
      </div>

      <!-- frigoríficos - exclusivo para wagyu -->
      <div *ngIf="env.contexto === 'wagyu' && formValidaCriador.tipoPessoa == 2" class="form-group col-lg-2 col-md-4 col-sm-4 col-xs-12">
        <label for="formularioProtocolo">Frigorífico</label>
        <select [disabled]="readOnly" class="form-control form-control-sm" name="suspenso" [(ngModel)]="criador.frigorifico">
          <option value="1" label="Sim"></option>
          <option value="2" label="Não"></option>
        </select>
      </div>

      <div *ngIf="criador.frigorifico == 1" class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
        <label>Tipo Serviço de Inspeção</label>
        <select [disabled]="readOnly" class="form-control form-control-sm" name="tipo_servico_inspecao" [(ngModel)]="criador.tipoServicoInspecao">
          <option value="-1" label="Selecione ..."></option>
          <option value="1" label="SIF"></option>
          <option value="2" label="SISP"></option>
          <option value="3" label="SIM"></option> 
          <option value="4" label="SISB"></option>
        </select>
      </div>

      <div *ngIf="criador.frigorifico == 1" class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
        <label>Numero Serviço de Inspeção</label>
        <input [disabled]="readOnly" name="numero_servico_inspecao" type="text" class="form-control form-control-sm" [(ngModel)]="criador.numeroServicoInspecao">
      </div>
    </div>

    <div class="row">
      <div *ngIf="formValidaCriador.tipoPessoa == 2" class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <app-afixo-manter [criador]="criador" [readOnly]="readOnly"></app-afixo-manter>
      </div>
    </div>


    <!-- escolha de racas - exclusivo para wagyu -->
    <div *ngIf="env.contexto === 'wagyu'" style="margin-bottom: 2rem;">
      
      <label>Raça(s)</label>

      <mat-chip-list #chipListRacas aria-label="Seleção de raças">
        <mat-chip
          [removable]="!readOnly"
          [selectable]="!readOnly"
          *ngFor="let raca of criador.racas"
          (removed)="removerRaca(raca)"
        >
          {{ getLabelRaca(raca) }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input
          #racasInput
          [disabled]="readOnly"
          [matAutocomplete]="auto"
          placeholder="Escolher nova raça..."
          [matChipInputFor]="chipListRacas"
          class="form-control form-control-sm"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        >
      </mat-chip-list>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="racaSelecionada($event)">
        <mat-option *ngFor="let raca of listaRacas" [value]="raca.value">
          {{ raca.label }}
        </mat-option>
      </mat-autocomplete>

    </div>

    <div class="form-group">
      <label for="observacao">Observação</label>
      <textarea class="form-control form-control-sm" name="observacao" [(ngModel)]="criador.observacao" rows="3"></textarea>
    </div>

    <h3 class="custom-titulo" *ngIf="env.contexto === 'jersey'">Dados da Fazenda Principal</h3>

    <div class="titulo-fazenda titulo-fazenda-wagyu" *ngIf="env.contexto === 'wagyu'">
      <h3>Dados da(as) Fazenda(s)</h3>
      <a *ngIf="!readOnly" type="button" matTooltip="Adicionar mais uma fazenda">
        <i class="fas fa-plus" (click)="adicionarMaisFazenda()"></i>
      </a>
    </div>

    <div *ngFor="let fazenda of criador.fazendas; let i = index">
      <app-fazenda-manter 
        [index_titulo]="i" 
        [fazenda]="fazenda" 
        [readOnly]="readOnly" 
        [length]="criador.fazendas.length" 
        (index_remover)="removerFazenda($event)"
        [associacaoEntidadeId] = "criador.associacaoEntidadeId"
      >
      </app-fazenda-manter>
    </div>
    
    <div class="row justify-content-end" id="botao-container">

      <div *ngIf="!readOnly" class="col-lg-3 col-md-10 col-sm-10 col-xs-12">
        <button type="button" (click)="botaoEnviar()" class="btn btn-sm btn-block btn-primary">
          Enviar
        </button>
      </div>

    </div>

  </div>
</div>