export class FiltroExposicaoModel {
	uf: number;
	nome: string;
	exposicaoDe: string;
	exposicaoAte: string;

	constructor() {
		this.uf = 0;
		this.nome = "";
		this.exposicaoDe = "";
		this.exposicaoAte = "";
	}
}
