import { AnimalCadastrado, IsGemelar, Sexo, TipoBuscaBovino } from "../shared/enums/Enums";
import { ConsultaRapidaBovinoModel } from "./ConsultaRapidaBovinoModel";
import { FazendaModel } from "./FazendaModel";
import { LaudoDNAModel } from "./LaudoDNAModel";
import { OcorrenciaEventoInovulacaoModel } from "./OcorrenciaEventoInovulacaoModel";
import { OcorrenciaEventoModel } from "./OcorrenciaEventoModel";
import { OcorrenciaEventoPadreacaoModel } from "./OcorrenciaEventoPadreacaoModel";
import { environment } from 'src/environments/environment';

export class OcorrenciaEventoNascimentoModel extends OcorrenciaEventoModel {
  MaeCadastrada: AnimalCadastrado;
  PaiCadastrado: AnimalCadastrado;

  TipoRegistro: number;
  OrelhaDireita: string;
  Nome: string;
  Sexo: Sexo;
  AnimalRegistrar: number;

  // wagyu
  LaudoDna: LaudoDNAModel;
  Peso: number | string;
  Brinco: number | string;
  Raca: string;
  Fazenda: FazendaModel;

  DataOcorrencia: string;
  DataNascimento: string;
  Mae: ConsultaRapidaBovinoModel;
  Pai: ConsultaRapidaBovinoModel;

  OcorrenciaEventoInovulacao: OcorrenciaEventoInovulacaoModel;
  OcorrenciaEventoPadreacao: OcorrenciaEventoPadreacaoModel;

  GrauDeSanguePai: number | string;
  GrauDeSangueMae: number | string;
  GrauDeSangueProduto: number | string;

  IsGemelar: IsGemelar;

  TipoRegistroGemeo: number;
  OrelhaDireitaGemeo: string;
  NomeGemeo: string;
  SexoGemeo: Sexo;
  AnimalRegistrarGemeo: number;
  BrincoGemeo: number | string;
  RacaGemeo: string;
  PesoGemeo: number | string;
  LaudoDnaGemeo: LaudoDNAModel;


  constructor() {
    super();

    this.DataOcorrencia = "";
    this.DataNascimento = "";

    this.TipoRegistro = 1;
    this.OrelhaDireita = "";
    this.Nome = "";
    this.Sexo = Sexo.Fêmea;
    this.AnimalRegistrar = 1;

    this.LaudoDna = new LaudoDNAModel();
    if (environment.raca == 'kuroge') {
      this.Raca = 'WAGYU_KUROGE';
    } else if (environment.raca == 'akaushi') {
      this.Raca = 'WAGYU_AKAUSHI';
    } else {
      this.Raca = '-1';
    }

    this.Peso = '';
    this.Fazenda = new FazendaModel();
    this.Brinco = '';

    this.MaeCadastrada = AnimalCadastrado.Sim;
    this.PaiCadastrado = AnimalCadastrado.Sim;

    this.OcorrenciaEventoInovulacao = new OcorrenciaEventoInovulacaoModel();
    this.OcorrenciaEventoPadreacao = new OcorrenciaEventoPadreacaoModel();

    this.Mae = new ConsultaRapidaBovinoModel();
    this.Mae.TituloConsulta = "Mãe";
    this.Mae.ConsultaAnimalTodosCriadores = true;
    this.Mae.TipoBusca = TipoBuscaBovino.Fêmea;
    this.Mae.SexoBovino = Sexo.Fêmea;
    this.Mae.ConsideraInativos = false;

    this.Pai = new ConsultaRapidaBovinoModel();
    this.Pai.TituloConsulta = "Pai";
    this.Pai.ConsultaAnimalTodosCriadores = true;
    this.Pai.TipoBusca = TipoBuscaBovino.Macho;
    this.Pai.SexoBovino = Sexo.Macho;
    this.Pai.ConsideraInativos = false;
    this.GrauDeSanguePai = -1;
    this.GrauDeSangueMae = -1;
    this.GrauDeSangueProduto = -1;

    this.IsGemelar = IsGemelar.Nao;

    this.TipoRegistroGemeo = 1;
    this.OrelhaDireitaGemeo = "";
    this.NomeGemeo = "";
    this.SexoGemeo = Sexo.Fêmea;
    this.AnimalRegistrarGemeo = 1;
    this.BrincoGemeo = '';
    if (environment.raca == 'kuroge') {
      this.RacaGemeo = 'WAGYU_KUROGE';
    } else if (environment.raca == 'akaushi') {
      this.RacaGemeo = 'WAGYU_AKAUSHI';
    } else {
      this.RacaGemeo = '-1';
    }
    this.PesoGemeo = '';
    this.LaudoDnaGemeo = new LaudoDNAModel();
  }
}