import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BovinoModel } from 'src/app/models/BovinoModel';
import { CriadorModel } from 'src/app/models/CriadorModel';
import { IdentificacaoCriadorModel } from 'src/app/models/IdentificacaoCriadorModel';
import { ApiService } from 'src/app/shared/api.service';
import { SituacaoSRG, Origem, TipoMotivoBaixa, TipoGrauSangue, SituacaoOcorrenciaEvento, EnumTipoEventoSRG } from 'src/app/shared/enums/Enums';
import { IBovinoDetalhes, ICriador, IFazenda, IItemListaRacasBovinos } from 'src/app/shared/interfaces/Interfaces';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { BovinoService } from '../../shared/bovino.service';
import { FiltroConsultaEventosBovinoModel } from 'src/app/models/FiltroConsultaEventosBovinoModel';
import { OcorrenciaEventoInspecaoModel } from 'src/app/models/OcorrenciaEventoInspecaoModel';

@Component({
  selector: 'app-dados-bovino',
  templateUrl: './dados-bovino.component.html',
  styleUrls: ['./dados-bovino.component.scss'],
})
export class DadosBovinoComponent implements OnInit {
  readonly env = environment;

  readOnly: boolean = true;
  readonlyStatus: boolean = false;
  auxUltimoAfixo: IdentificacaoCriadorModel = new IdentificacaoCriadorModel();

  mostrarBotaoNovo: boolean = false;
  origem: { value: number; label: string }[] = [];
  situacoesSRG: { value: number; label: string }[] = [];

  idBovino: string = '';
  consulta: boolean = false;
  bovino = <IBovinoDetalhes>{
    Pai: new BovinoModel(),
    Mae: new BovinoModel(),
    Criador: new CriadorModel(),
    Proprietario: new CriadorModel(),
    PropriedadeNascimento: <IFazenda>{},
  };

  grauSangue: { value: string; label: string }[] = [];
  tipoSanguineo: { value: number; label: string }[] = [];
  racas: { value: string; label: string }[] = [];

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private router: Router,
    public bovinoService: BovinoService
  ) {
    this.bovino.Criador.tituloConsulta = 'Criador';
    this.bovino.Proprietario.tituloConsulta = 'Proprietário';
  }

  ngOnInit(): void {
    const actionParam = this.route.snapshot.queryParamMap.get('a') as string;
    if (actionParam) {
      this.readOnly = JSON.parse(atob(actionParam));
      this.readonlyStatus = JSON.parse(atob(actionParam));
    }

    // const readonly: (string|null) = this.route.snapshot.queryParamMap.get('readOnly');
    // this.readOnly = (readonly !== null && readonly === 'true');

    this.mostrarBotaoNovo = ((): boolean => {
      const fromUrl = this.route.snapshot.queryParamMap.get('sugestao');
      return fromUrl !== null && fromUrl === 'true';
    })();

    this.grauSangue.push({ value: '-1', label: 'Selecione...' });
    this.tipoSanguineo.push({ value: -1, label: 'Selecione...' });
    this.racas = this.bovinoService.getListaRacas(['manter_bovino'], true);

    this.route.params.subscribe(params => {
      this.idBovino = params['id'];
    });

    if (this.idBovino) {
      this.consultarDetalhes();
    }

    this.preencherOrigem();
    this.preencherSituacoesSRG();
    //this.atualizarTiposPorRaca(this.bovino.Raca);
  }

  consultarDetalhes() {
    this.api.consultarDetalhesBovino(this.idBovino).then((result: any) => {
      if (result) {
        if (result.success) {
          this.bovino = {
            ...result.Bovino,
            Pai: {
              ...result.Bovino.Pai,
              // Readonly: true,
              Readonly: this.readOnly,
            },
            Mae: {
              ...result.Bovino.Mae,
              // Readonly: true,
              Readonly: this.readOnly,
            },
            Proprietario: this.convert2CriadorModel(result.Bovino.Proprietario, 'Proprietário'),
            Criador: this.convert2CriadorModel(result.Bovino.Criador, 'Criador'),
          };

          this.auxUltimoAfixo.codigo = this.bovino.Criador.afixo;
          this.auxUltimoAfixo.posicaoAfixo = this.bovino.Criador.posicaoAfixo;

          const racasEnv = (this.env as any).racas;
          this.bovino.Raca = racasEnv?.filter(
            (item: IItemListaRacasBovinos) => item.label.toLowerCase() === this.bovino.Raca.toLowerCase()
          )[0].value;

          this.atualizarTiposPorRaca(this.bovino.Raca);
          this.animalAbate(result.DestinoInspecao);
        } else {
          Swal.fire(result.Mensagem, '', 'error');
        }
      }
    });
  }

  botaoSalvar() {
    const request_data = {
      bovino: this.convert2BovinoModel(this.bovino),
      pai: this.bovino.Pai,
      mae: this.bovino.Mae,
      criador: this.bovino.Criador,
      proprietario: this.bovino.Proprietario,
    };

    this.api.registrarBovino(request_data).then((result: any) => {
      if (result) {
        if (result.success) {
          Swal.fire(result.message, '', 'success');
          this.readOnly = true;
          this.readonlyStatus = true;
          this.router.navigateByUrl(`bovino_detalhar/${result.BovinoId}?readOnly=true`);
        } else {
          Swal.fire(result.message, '', 'error');
        }
      } else {
        Swal.fire('A operação não pode ser realizada.', '', 'error');
      }
    });
  }

  private convert2CriadorModel(object: ICriador, tituloConsulta: string): CriadorModel {
    const user: CriadorModel = {
      id: object.Id,
      codigo: object.CodigoCriador,
      nome: object.NomeCriador,
      afixo: object.AfixoCriador,
      readonly: this.readOnly,
      tituloConsulta,
    } as CriadorModel;

    return user;
  }

  private convert2BovinoModel(object: IBovinoDetalhes): BovinoModel {
    const bovino: BovinoModel = {
      Id: object.Id,
      NomeBovino: object.Nome,
      CodigoBovino: object.Codigo,
      OrelhaDireita: object.OrelhaDireita,
      AvalacaoGenealogicaId: object.AvalacaoGenealogicaId,
      SituacaoSRGBovino: object.SituacaoSrg,
      DataNascimentoBovino: object.DataNascimento,
      SexoBovino: object.Sexo,
      RacaBovino: object.Raca,
      Brinco: object.Brinco,
      EvolucaoPCParaPO: object.EvolucaoPCParaPO,
      SangueBovino: {
        grau: object.GrauSangue,
        tipo: object.TipoGrauSangue,
      },
      AnimalDeCentral: object.AnimalDeCentral,
      Nacionalizado: object.Nacionalizado,
      SituacaoBovino: object.Situacao,
      OrigemBovino: object.Origem,
      MotivoBaixaAnimal: TipoMotivoBaixa.SISTEMA,
      PesoAoNascer: object.PesoAoNascer,
    } as BovinoModel;

    return bovino;
  }

  preencherSituacoesSRG(): void {
    for (let i = 1; i < 9; i++) {
      if (i != 7) {
        this.situacoesSRG.push({ value: i, label: SituacaoSRG[i] });
      }
    }
  }

  preencherOrigem(): void {
    this.origem.push({ label: 'Selecione...', value: -1 });
    for (let i = 16; i < 30; i++) {
      this.origem.push({ label: Origem[i], value: i });
    }
  }

  private createIdentificacao(): IdentificacaoCriadorModel {
    // to-do: recuperar lista de afixos do criador
    const afixo = new IdentificacaoCriadorModel();
    afixo.codigo = this.bovino.Criador.afixo;
    afixo.posicaoAfixo = this.bovino.Nome.startsWith(this.bovino.Criador.afixo) ? 1 : 2;
    afixo.principal = true;

    return afixo;
  }

  // formatarNomeBovino(contextoWagyu: boolean, removerCodigo: boolean) {
  //   this.bovino.Nome = removerCodigo
  //     ? this.bovinoService.removerOrelhaEsquerda(this.bovino.Nome, this.bovino.OrelhaDireita, contextoWagyu)
  //     : this.bovinoService.getNomeBovino(this.bovino.Nome, this.bovino.OrelhaDireita, [this.createIdentificacao()], contextoWagyu,
  //       (afixos: IdentificacaoCriadorModel[]): IdentificacaoCriadorModel => (
  //         afixos.filter((item: IdentificacaoCriadorModel) => item.principal && item)[0])
  //       );
  // }

  criadorSelecionado(id: string) {
    this.bovino.Nome = this.bovino.Nome.replace(this.auxUltimoAfixo.codigo, '').trim();

    this.auxUltimoAfixo.codigo = this.bovino.Criador.afixo;
    this.auxUltimoAfixo.posicaoAfixo = this.bovino.Criador.posicaoAfixo;

    if (id && id !== '')
      this.bovino.Nome = this.bovinoService.getNomeBovino(
        this.bovino.Nome,
        this.bovino.OrelhaDireita,
        [this.createIdentificacao()],
        (this.env as any).contexto === 'wagyu',
        (afixos: IdentificacaoCriadorModel[]): IdentificacaoCriadorModel =>
          afixos.filter((item: IdentificacaoCriadorModel) => item.principal && item)[0]
      );
  }

  botaoCadastrarNovo() {
    this.router.navigate(['bovino_manter']);
  }

  atualizarTiposPorRaca(raca: string): void {
    this.tipoSanguineo = this.bovinoService.getTiposPorRaca(raca, true, true);

    if (this.bovino.TipoGrauSangue == TipoGrauSangue._38P
      || this.bovino.TipoGrauSangue == TipoGrauSangue._12B
      || this.bovino.TipoGrauSangue == TipoGrauSangue._14B
      || this.bovino.TipoGrauSangue == TipoGrauSangue._16B
      || this.bovino.TipoGrauSangue == TipoGrauSangue._18B
      || this.bovino.TipoGrauSangue == TipoGrauSangue._34B
      || this.bovino.TipoGrauSangue == TipoGrauSangue._38A
      || this.bovino.TipoGrauSangue == TipoGrauSangue._38B
    ) {
      this.bovino.TipoGrauSangue = TipoGrauSangue.CCG;
    }
    /*this.tipoSanguineo.push({ value: TipoGrauSangue.PA, label: 'PA' });
    this.tipoSanguineo.push({ value: TipoGrauSangue._12B, label: 'CCG' });
    this.tipoSanguineo.push({ value: TipoGrauSangue._14B, label: 'CCG' });
    this.tipoSanguineo.push({ value: TipoGrauSangue._16B, label: 'CCG' });
    this.tipoSanguineo.push({ value: TipoGrauSangue._18B, label: 'CCG' });
    this.tipoSanguineo.push({ value: TipoGrauSangue._34B, label: 'CCG' });
    this.tipoSanguineo.push({ value: TipoGrauSangue._38A, label: 'CCG' });
    this.tipoSanguineo.push({ value: TipoGrauSangue._38B, label: 'CCG' });
    this.tipoSanguineo.push({ value: TipoGrauSangue._38P, label: 'CCG' });

    this.tipoSanguineo.push({ value: TipoGrauSangue.PC1, label: 'PC' });
    this.tipoSanguineo.push({ value: TipoGrauSangue.PC2, label: 'PC' });
    this.tipoSanguineo.push({ value: TipoGrauSangue.PC3, label: 'PC' });
    this.tipoSanguineo.push({ value: TipoGrauSangue.PC4, label: 'PC' });*/


    this.grauSangue = [];
    this.atualizarGrauSanguePorTipo(this.bovino.TipoGrauSangue);
  }

  atualizarGrauSanguePorTipo(tipo: number): void {
    this.grauSangue = this.bovinoService.getGrauSanguePorTipo(tipo, true, true);
  }

  animalAbate(destino: string) {
    if (destino != null && destino != "" && destino != "-1") {
      if (destino == "2") {
        this.bovino.SituacaoSrg = 8;
        this.readonlyStatus = true;
      }
    }
  }
}
