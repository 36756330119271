import { AnimalCadastrado, EnumTipoEventoSRG, IsGemelar, SituacaoSRG, TipoGrauSangue, TipoInspecao, TipoMotivoBaixa } from './../shared/enums/Enums';
import { ConfirmacaoModalComponent } from './shared/confirmacao-modal/confirmacao-modal.component';
import { AccountService } from './../account/shared/account.service';
import { LoteOcorrenciaEventoModel } from 'src/app/models/LoteOcorrenciaEventoModel';
import { DatePipe } from '@angular/common';
import { FormularioModel } from '../models/FormularioModel';
import { ApiService } from '../shared/api.service';
import { Commons } from '../shared/commons';
import { SituacaoOcorrenciaEvento, SituacaoLoteOcorrencia, EstacaoDeMonta } from '../shared/enums/Enums';
import Swal from 'sweetalert2';
import { ConstantesAcaoEvento, ConstantesEventos, ConstantesTipoEvento } from '../shared/constantes';
import { ParamMap, Router } from '@angular/router';
import { OcorrenciaEventoPadreacaoModel } from '../models/OcorrenciaEventoPadreacaoModel';
import { MatDialog } from '@angular/material/dialog';
import { DocumentosOcorrenciaModalComponent } from './shared/documentos-ocorrencia-modal/documentos-ocorrencia-modal.component';
import { OcorrenciaEventoBaixaModel } from '../models/OcorrenciaEventoBaixaModel';
import { OcorrenciaEventoNascimentoModel } from '../models/OcorrenciaEventoNascimentoModel';
import { OcorrenciaEventoTeFivModel } from '../models/OcorrenciaEventoTeFivModel';
import { OcorrenciaEventoInovulacaoModel } from '../models/OcorrenciaEventoInovulacaoModel';
import { OcorrenciaEventoTransferenciaModel } from '../models/OcorrenciaEventoTransferenciaModel';
import { OcorrenciaEventoEmbriaoModel } from '../models/OcorrenciaEventoEmbriaoModel';
import { OcorrenciaEventoClassificacaoLinearModel } from '../models/OcorrenciaEventoClassificacaoLinearModel';
import { OcorrenciaEventoSemenModel } from '../models/OcorrenciaEventoSemenModel';
import { OcorrenciaEventoInspecaoModel } from '../models/OcorrenciaEventoInspecaoModel';
import { OcorrenciaEventoPesagemModel } from '../models/OcorrenciaEventoPesagemModel';
import { OcorrenciaEventoAbateComAvalCarcacaModel } from '../models/OcorrenciaEventoAbateComAvalCarcacaModel';
import { environment } from 'src/environments/environment';
import { CriadorModel } from '../models/CriadorModel';
import { EventEmitter, Output } from '@angular/core';
import { IJsonResultModel } from '../shared/interfaces/Interfaces';

interface IValueLabel<V, L> {
  value: V;
  label: L;
}
export class BaseEventos {
  grauSangue2: { value: string; label: string }[] = [];
  private readonly env2 = environment;
  acao: string = ConstantesAcaoEvento.Visualizar;
  idEvento: string = '';
  tipoEvento: string = '';
  tabAtual: string = 'FORMULARIO';
  formulario: FormularioModel = new FormularioModel();
  maxDate: Date = new Date();

  lote: any;
  ocorrenciaAtual: any;
  ocorrencias: any[] = [];

  temporarioEdicaoEventoAprovado: boolean = false;
  temporarioEdicaoEventoAguardandoAprovacao: boolean = false;

  constructor(
    public api: ApiService,
    public datePipe: DatePipe,
    public commons: Commons,
    public router: Router,
    public accountService: AccountService,
    public dialog: MatDialog,
  ) { }

  async iniciarTela(params: ParamMap): Promise<void> {
    this.idEvento = atob(params.get('e') ?? '');
    this.acao = atob(params.get('a') ?? '');

    //Se recebeu por parâmetro um id de evento recupera ele
    if (this.idEvento) {
      await this.consultarEventoPorId(this.idEvento);
    } else {
      //Se o usuário logado for um criador, verifica se ele tem um evento em elaboração
      if (this.accountService.isCriador) {
        let token: any = this.accountService.getPayloadToken();
        this.formulario.criador.usuarioId = token.UserId;
        if (token) {
          this.criadorSelecionado(token.CriadorId);
        }
      }
    }
  }

  novoEvento(manterInfosParaSugestao: boolean = false) {
    switch (this.tipoEvento) {
      case ConstantesTipoEvento.PADREACAO:
        this.ocorrenciaAtual = new OcorrenciaEventoPadreacaoModel();
        if (this.lote) {
          this.ocorrenciaAtual.Matriz.IdProprietarioConsulta = this.lote.Criador.Id;
          this.ocorrenciaAtual.Reprodutor.IdProprietarioConsulta = this.lote.Criador.Id;
        }
        break;

      case ConstantesTipoEvento.BAIXA:
        this.ocorrenciaAtual = new OcorrenciaEventoBaixaModel();
        if (this.lote) {
          this.ocorrenciaAtual.Animal.IdProprietarioConsulta = this.lote.Criador.Id;
          this.lote.Ocorrencias.forEach((ocorrencia: any) => {
            if (ocorrencia !== null && ocorrencia.DataAbate != null) {
              if (ocorrencia.DataAbate !== '') {
                console.log('1')
                this.ocorrenciaAtual.DataAbate = ocorrencia.DataAbate;
                this.ocorrenciaAtual.DataBaixa = ocorrencia.DataBaixa;
                this.ocorrenciaAtual.QuantidadeSelos = ocorrencia.QuantidadeSelos;
                this.ocorrenciaAtual.Frigorifico.tituloConsulta = 'Frigorífico';
                this.ocorrenciaAtual.Frigorifico = ocorrencia.Frigorifico;
                this.ocorrenciaAtual.Frigorifico.id = ocorrencia.Frigorifico.id;
                this.ocorrenciaAtual.Inspetor = ocorrencia.Inspetor;
                this.ocorrenciaAtual.Inspetor.Id = ocorrencia.Inspetor.Id;
                this.ocorrenciaAtual.NomeInspetor = ocorrencia.Inspetor.NomeInspetor;
                this.ocorrenciaAtual.PreAbate = ocorrencia.PreAbate;
                this.ocorrenciaAtual.HasPreAbate = ocorrencia.HasPreAbate;
                if (this.ocorrenciaAtual.HasPreAbate) {
                  this.ocorrenciaAtual.PossuiPreAbate = '1';
                } else {
                  this.ocorrenciaAtual.PossuiPreAbate = '2';
                }
                this.ocorrenciaAtual.FrigorificoAbate.id = ocorrencia.FrigorificoAbate.Id;
                this.ocorrenciaAtual.FrigorificoAbate.usuarioId = ocorrencia.FrigorificoAbate.Id;
                this.ocorrenciaAtual.FrigorificoAbate.nome = ocorrencia.FrigorificoAbate.NomeCriador;
                this.ocorrenciaAtual.FrigorificoAbate.codigo = ocorrencia.FrigorificoAbate.CodigoCriador;
                this.ocorrenciaAtual.FrigorificoAbate.afixo = ocorrencia.FrigorificoAbate.AfixoCriador;
                this.ocorrenciaAtual.FrigorificoAbate.readonly = false;
                this.ocorrenciaAtual.FrigorificoAbate.tituloConsulta = 'Frigorífico';

                this.ocorrenciaAtual.Frigorifico.id = ocorrencia.Frigorifico.Id;
                this.ocorrenciaAtual.Frigorifico.usuarioId = ocorrencia.Frigorifico.Id;
                this.ocorrenciaAtual.Frigorifico.nome = ocorrencia.Frigorifico.NomeCriador;
                this.ocorrenciaAtual.Frigorifico.codigo = ocorrencia.Frigorifico.CodigoCriador;
                this.ocorrenciaAtual.Frigorifico.afixo = ocorrencia.Frigorifico.AfixoCriador;
                this.ocorrenciaAtual.Frigorifico.readonly = false;
                this.ocorrenciaAtual.Frigorifico.tituloConsulta = 'Frigorífico';
                console.log(this.ocorrenciaAtual)
                return;
              }
            }
          });
        }
        break;

      case ConstantesTipoEvento.NASCIMENTO:
        this.ocorrenciaAtual = new OcorrenciaEventoNascimentoModel();
        if (this.lote) {
          this.ocorrenciaAtual.Mae.IdProprietarioConsulta = this.lote.Criador.Id;
          this.ocorrenciaAtual.Pai.IdProprietarioConsulta = this.lote.Criador.Id;
        }
        break;

      case ConstantesTipoEvento.TEFIV:
        this.ocorrenciaAtual = new OcorrenciaEventoTeFivModel();
        if (this.lote) {
          this.ocorrenciaAtual.Doadora.IdProprietarioConsulta = this.lote.Criador.Id;
          this.ocorrenciaAtual.Touro.IdProprietarioConsulta = this.lote.Criador.Id;
          this.ocorrenciaAtual.isReadOnly = true;
          if (this.lote.Ocorrencias[0].Veterinario) {
            this.ocorrenciaAtual.Veterinario.crmv = this.lote.Ocorrencias[0].Veterinario.crmv;
            this.ocorrenciaAtual.Veterinario.nomeVeterinario = this.lote.Ocorrencias[0].Veterinario.nomeVeterinario;
          }
        }


        break;

      case ConstantesTipoEvento.INOVULACAO:
        this.ocorrenciaAtual = {
          ...new OcorrenciaEventoInovulacaoModel(),
          OcorrenciaEventoFiv: manterInfosParaSugestao
            ? this.ocorrenciaAtual.OcorrenciaEventoFiv
            : new OcorrenciaEventoTeFivModel(),
          OcorrenciaNacionalizacaoEmbriao: manterInfosParaSugestao
            ? this.ocorrenciaAtual.OcorrenciaNacionalizacaoEmbriao
            : new OcorrenciaEventoEmbriaoModel(),
        };
        if (this.lote) {
          this.ocorrenciaAtual.Doadora.IdProprietarioConsulta = this.lote.Criador.Id;
          this.ocorrenciaAtual.Reprodutor.IdProprietarioConsulta = this.lote.Criador.Id;
          this.ocorrenciaAtual.Receptora.IdProprietarioConsulta = this.lote.Criador.Id;
          this.ocorrenciaAtual.isReadOnly = true;
          if (this.lote.Ocorrencias[0].Veterinario) {
            this.ocorrenciaAtual.Veterinario.id = this.lote.Ocorrencias[0].Veterinario.id;
            this.ocorrenciaAtual.Veterinario.crmv = this.lote.Ocorrencias[0].Veterinario.crmv;
            this.ocorrenciaAtual.Veterinario.nomeVeterinario = this.lote.Ocorrencias[0].Veterinario.nomeVeterinario;
          }

        }
        break;

      case ConstantesTipoEvento.TRANSFERENCIA:
        this.ocorrenciaAtual = {
          ...new OcorrenciaEventoTransferenciaModel(),
          Comprador: manterInfosParaSugestao ? this.ocorrenciaAtual.Comprador : new CriadorModel(),
        };
        if (this.lote) {
          this.ocorrenciaAtual.Animal.IdProprietarioConsulta = this.lote.Criador.Id;
        }
        if (this.ocorrenciaAtual.OcorrenciaEventoInovulacao.Reprodutor ||
          this.ocorrenciaAtual.OcorrenciaEventoPadreacao.Reprodutor) {
          this.ocorrenciaAtual.HasReprodutor = true;
        } else {
          this.ocorrenciaAtual.HasReprodutor = false;
        }
        this.ocorrenciaAtual.CodigoAnimalNaoCadastrado = "";
        this.ocorrenciaAtual.NomeAnimalNaoCadastrado = "";
        this.ocorrenciaAtual.SugestaoSelecionada = false;


        break;

      case ConstantesTipoEvento.EMBRIAO:
        this.ocorrenciaAtual = new OcorrenciaEventoEmbriaoModel();
        if (this.lote) {
          this.ocorrenciaAtual.Doadora.IdProprietarioConsulta = this.lote.Criador.Id;
          this.ocorrenciaAtual.Touro.IdProprietarioConsulta = this.lote.Criador.Id;
        }
        break;

      case ConstantesTipoEvento.NACIONALIZACAO:
        this.ocorrenciaAtual = new OcorrenciaEventoSemenModel();
        break;

      case ConstantesTipoEvento.CLASSIFICAO_LINEAR:
        this.ocorrenciaAtual = new OcorrenciaEventoClassificacaoLinearModel();
        if (this.lote) {
          this.ocorrenciaAtual.Touro.Readonly = true;
          this.ocorrenciaAtual.Touro.TituloConsulta = 'Touro';
          this.ocorrenciaAtual.Animal.IdProprietarioConsulta = this.lote.Criador.Id;
          this.ocorrenciaAtual.Inspetor.Id = this.lote.OcorrenciaAtual.InspetorId;
          this.ocorrenciaAtual.Inspetor.NomeInspetor = this.lote.OcorrenciaAtual.NomeInspetor;
          this.ocorrenciaAtual.Inspetor.readonly =
            this.ocorrenciaAtual.Inspetor.NomeInspetor || this.ocorrenciaAtual.Inspetor.Id;
        }
        break;

      case ConstantesTipoEvento.INSPECAO:
        this.ocorrenciaAtual = new OcorrenciaEventoInspecaoModel();
        if (this.lote) {
          this.ocorrenciaAtual.Mae.IdProprietarioConsulta = this.lote.Criador.Id;
          this.ocorrenciaAtual.Pai.IdProprietarioConsulta = this.lote.Criador.Id;
          // this.ocorrenciaAtual.Inspetor.Id = this.lote.OcorrenciaAtual.InspetorId;
          this.ocorrenciaAtual.isCastrado = false;

          this.lote.Ocorrencias.forEach((ocorrencia: OcorrenciaEventoInspecaoModel) => {
            if (ocorrencia !== null && ocorrencia.Inspetor != null) {
              if (ocorrencia.InspetorId !== '-1') {
                this.ocorrenciaAtual.Inspetor = ocorrencia.Inspetor;
                this.ocorrenciaAtual.NomeInspetor = ocorrencia.NomeInspetor;
                this.ocorrenciaAtual.InspetorId = ocorrencia.InspetorId;
                this.ocorrenciaAtual.Inspetor.Id = ocorrencia.InspetorId;
                this.ocorrenciaAtual.TipoInspecao = ocorrencia.TipoInspecao;
                this.ocorrenciaAtual.DataInspecao = ocorrencia.DataInspecao;


                if (ocorrencia.TipoInspecao == 1 && ((ocorrencia.GrauSangue != null && ocorrencia.TipoGrauSangue != null) ||
                  (ocorrencia.GrauSangue != '-1' && ocorrencia.TipoGrauSangue != -1))) {
                  this.ocorrenciaAtual.GrauSangue = ocorrencia.GrauSangue;
                  this.ocorrenciaAtual.TipoGrauSangue = ocorrencia.TipoGrauSangue;
                  this.ocorrenciaAtual.SituacaoSrg = SituacaoSRG['Registro Definitivo Emitido'];
                }
                return;
              }
            }
          });

          // this.ocorrenciaAtual.Inspetor.NomeInspetor = this.lote.OcorrenciaAtual.NomeInspetor;
          // this.ocorrenciaAtual.Inspetor.readonly = this.ocorrenciaAtual.Inspetor.NomeInspetor || this.ocorrenciaAtual.Inspetor.Id;
          // this.ocorrenciaAtual.CodigoInspetor = this.lote.OcorrenciaAtual.CodigoInspetor;
          //this.ocorrenciaAtual.DataInspecao = this.lote.OcorrenciaAtual.DataInspecao;
        }
        break;

      case ConstantesTipoEvento.ABATE_COM_AVAL_CARCACA:
        this.ocorrenciaAtual = new OcorrenciaEventoAbateComAvalCarcacaModel();
        if (this.lote) {
          this.ocorrenciaAtual.Animal.IdProprietarioConsulta = this.lote.Criador.Id;
          this.ocorrenciaAtual.RelacoesPreAbate = [];
        }
        break;

      case ConstantesTipoEvento.PESAGEM:
        this.ocorrenciaAtual = new OcorrenciaEventoPesagemModel();
        if (this.lote) {
          this.ocorrenciaAtual.AnimalCadastrado.IdProprietarioConsulta = this.lote.Criador.Id;
        }
        break;
    }
  }

  async criadorSelecionado(idCriador: string, novoItem: boolean = false) {
    await this.consultarEventoEmElaboracao(idCriador, novoItem);

    /* Seta id de criador para o componente de consultas de bovino */
    this.setIdConsultaBovino(idCriador);

    if (this.tipoEvento !== ConstantesTipoEvento.TRANSFERENCIA && this.ocorrenciaAtual.AnimalCadastrado)
      this.ocorrenciaAtual.AnimalCadastrado.IdProprietarioConsulta = idCriador;
  }

  async consultarEventoEmElaboracao(idCriador: string, novoItem: boolean = false, cb: (() => void) | null = null) {
    await this.api.consultarEventoEmElaboracaoPorCriador(idCriador, this.tipoEvento).then((result: any) => {
      if (this.tipoEvento == ConstantesTipoEvento.ABATE_COM_AVAL_CARCACA) {
        this.preencherFormulario(result, false);
        if (novoItem) {
          this.novoEvento();
          /* Seta id de criador para o componente de consultas de bovino */
          this.setIdConsultaBovino(idCriador);
        }

        if (cb) cb();
      } else {
        {
          this.preencherFormulario(result, novoItem);
          if (novoItem) {
            this.novoEvento();
            /* Seta id de criador para o componente de consultas de bovino */
            this.setIdConsultaBovino(idCriador);
          }

          if (cb) cb();
        }
      }

    });
  }

  tabSelecionada(tab: string) {
    this.tabAtual = tab;
  }

  async consultarEventoPorId(idEvento: string): Promise<void> {
    if (this.tipoEvento) {
      const result = await this.api.consultarEventoPorId(idEvento, this.tipoEvento);
      if (result && result.success) {
        this.preencherFormulario(result, false);
        // Eventos que permitem edição por superintendente/admin após aprovação
        this.temporarioEdicaoEventoAprovado =
          (this.accountService.isAdmin || this.accountService.isSuperintendente) &&
          (result.OcorrenciaAtual.Situacao == SituacaoOcorrenciaEvento.Aprovado ||
            result.OcorrenciaAtual.Situacao == SituacaoOcorrenciaEvento.AprovadoSuperintendente) &&
          (result.TipoOcorrenciaEmExecucao.includes('OcorrenciaEventoFivModel') ||
            result.TipoOcorrenciaEmExecucao.includes('OcorrenciaEventoInovulacaoModel') ||
            result.TipoOcorrenciaEmExecucao.includes('OcorrenciaEventoNascimentoModel'));

        // Eventos que permitem edição por superintendente/admin enquanto aguardando aprovação
        this.temporarioEdicaoEventoAguardandoAprovacao =
          (this.accountService.isAdmin || this.accountService.isSuperintendente) &&
          (result.OcorrenciaAtual.Situacao == SituacaoOcorrenciaEvento.AguardandoAprovacao ||
            result.OcorrenciaAtual.Situacao == SituacaoOcorrenciaEvento.AguardandoAprovacaoVeterinario) &&
          result.TipoOcorrenciaEmExecucao.includes('OcorrenciaEventoInovulacaoModel');
      } else {
        Swal.fire(result.message || 'Não foi possível realizar a operação.', '', 'error');
        this.router.navigate([`${this.tipoEvento}_consultar`]);
      }
    }
  }

  acaoBotao(acao: any) {
    switch (acao) {
      case ConstantesEventos.ACAO_BOTAO_NOVO:
        {
          this.confirmarNovoEvento();
        }
        break;
      case ConstantesEventos.ACAO_BOTAO_SALVAR:
        {
          this.salvar();
        }
        break;
      case ConstantesEventos.ACAO_BOTAO_DESCARTAR:
        {
          this.descartar();
        }
        break;
      case ConstantesEventos.ACAO_BOTAO_SUBMETER_E_APROVAR:
        {
          this.submeterEAprovar();
        }
        break;
      case ConstantesEventos.ACAO_BOTAO_APROVAR:
        {
          this.aprovar();
        }
        break;
      //case ConstantesEventos.ACAO_BOTAO_REJEITAR: { this.rejeitar() }; break;
      case ConstantesEventos.ACAO_BOTAO_APROVAR_COM_PENDENCIAS:
        {
          this.aprovarComPendencia();
        }
        break;
      case ConstantesEventos.ACAO_BOTAO_REVERTER_REJEICAO:
        {
        }
        break;
      case ConstantesEventos.ACAO_BOTAO_GERAR_PDF:
        {
        }
        break;
      case ConstantesEventos.ACAO_BOTAO_GERAR_EXCEL:
        {
        }
        break;
      case ConstantesEventos.ACAO_BOTAO_SUBMETER:
        {
          this.submeter();
        }
        break;
      case ConstantesEventos.ACAO_BOTAO_APROVAR_TODAS:
        {
        }
        break;
      case ConstantesEventos.ACAO_BOTAO_REVISAR:
        {
          this.revisar();
        }
        break;
      case ConstantesEventos.ACAO_BOTAO_APROVAR_TODOS:
        {
          this.aprovarTodos();
        }
        break;
    }
  }

  confirmarNovoEvento() {
    Swal.fire({
      title: 'Confirmação',
      html: this.msgConfirmacaoNova(),
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim',
    }).then(resultConfirmacao => {
      if (resultConfirmacao.isConfirmed) {
        this.novoEvento();
      }
    });
  }

  salvar(confirmacao: boolean = false) {
    var loteSalvar = this.prepararLoteParaSalvar(confirmacao);
    this.api.salvarEvento(loteSalvar, this.tipoEvento).then(async (result: any) => {
      if (result.AlertInconsistencia) {
        Swal.fire({
          title: this.tituloMsgConfirmacao(),
          html: this.msgConfirmacao(result.AlertInconsistencia),
          icon: 'question',
          showCancelButton: true,
          cancelButtonText: 'Não',
          confirmButtonText: 'Sim',
        }).then(resultConfirmacao => {
          if (resultConfirmacao.isConfirmed) {
            this.salvar(true);
          }
        });
      } else if (result.success === false) {
        Swal.fire({ html: result.message, icon: 'error' });
      } else {
        if (this.ocorrenciaAtual.Id === '-1') {
          if (this.tipoEvento != 'fiv' && this.tipoEvento != 'padreacao') {
            Swal.fire({
              title: this.tituloMsgSucesso(),
              html: this.msgSucessoCadastro(),
              icon: 'success',
              showCancelButton: true,
              cancelButtonText: 'Não',
              confirmButtonText: 'Sim',
            }).then(resultSucesso => {
              if (resultSucesso.isConfirmed) {
                this.ocorrencias = [];
                result.Ocorrencias.forEach((element: any) => {
                  if (element.Id === result.NovaOcorrenciaSalva) {
                    this.ocorrenciaAtual = element;
                    this.documentosDaOcorrencia();
                  }
                });
              } else {
                this.novoEvento(true);

              }
            });
          } else {
            Swal.fire({
              title: this.tituloMsgSucesso(),
              html: 'Ocorrência salva com sucesso!',
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'Continuar',
            }).then(resultSucesso => {
              if (resultSucesso.isConfirmed) {
                this.novoEvento(true);
              }
            });
          }

        } else {
          Swal.fire({ title: this.tituloMsgSucesso(), html: this.msgSucessoAtualizacao(), icon: 'success' }).then(
            () => {
              if (this.ocorrenciaAtual.Situacao == SituacaoOcorrenciaEvento.AguardandoResolucao) {
                window.location.reload();
                return;
              }
              this.novoEvento();
            }
          );
        }
      }
      //durante o desenvolvimento dessa tela, achei melhor transformar ela em uma tela com apenas uma ocorrencia por protocolo
      //ja que sao varios animais por abate, entao ela precisa ser tratada de forma diferente das outras quando é salva
      //basicamente eu forço um reload da pagina na mesma ocorrencia para aparecer o botao de relatorio e o criador n criar uma nova ocorrencia sem querer
      if (this.tipoEvento == ConstantesTipoEvento.ABATE_COM_AVAL_CARCACA) {
        Swal.fire({
          title: this.tituloMsgSucesso(),
          html: 'Ocorrência salva com sucesso!',
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: 'Continuar',
        }).then(resultSucesso => {
          if (resultSucesso.isConfirmed) {
            this.router.navigate([
              `abatecomavalcarcaca_manter`,
              { e: btoa(result.Ocorrencias[0].Id), a: btoa(ConstantesAcaoEvento.Editar) },
            ]);
          }
        });
      }
      this.preencherFormulario(result, true);
    });
  }

  descartar() {
    Swal.fire({
      title: 'Confirmação',
      html: 'Confirma o descarte deste formulário e de todos os seus itens?',
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim',
    }).then(resultConfirmacao => {
      if (resultConfirmacao.isConfirmed) {
        this.api.descartarEvento(this.lote.Id, this.tipoEvento).then((result: any) => {
          if (result.success) {
            Swal.fire({
              title: this.tituloMsgSucesso(),
              html: 'Formulário descartado com sucesso.',
              icon: 'success',
            }).then(() => {
              this.router.navigate([`${this.tipoEvento}_consultar`]);
            });
          } else {
            Swal.fire(result.message, '', 'error');
          }
        });
      }
    });
  }

  async submeter() {
    if (this.tipoEvento == 'padreacao' || this.tipoEvento == 'fiv') {
      if (this.lote.ListaArquivos.length == 0) {
        Swal.fire({ html: "É necessário anexar pelo menos um arquivo ao lote de comunicação. ", icon: 'error' });
      } else {
        let label;
        switch (this.tipoEvento) {
          case 'padreacao':
            label = 'coberturas';
            break;
          case 'fiv':
            label = 'TE/FIV';
            break;
        }
        await Swal.fire({
          title: 'Declaração',
          html: 'É necessário anexar a nota fiscal do sêmen à comunicação de ' + label + '. Estou ciente e me responsabilizo pelo conteúdo do anexo, sabendo que o registro proveniente desta comunicação poderá ser cancelado caso o conteúdo do arquivo não seja válido.' +
            '<br>Você confirma a veracidade dos dados?',
          input: 'radio',
          inputOptions: {
            sim: 'Sim&nbsp;&nbsp;&nbsp;',
            nao: 'Não'
          },
          inputAttributes: {
            required: 'true'
          },
          icon: 'question',
          showCancelButton: true,
          cancelButtonText: 'Voltar',
          confirmButtonText: 'Confirmar',
        }).then(resultConfirmacao => {
          if (resultConfirmacao.isConfirmed) {
            if (resultConfirmacao.value == null || resultConfirmacao.value == 'nao' || resultConfirmacao.value == '') {
              Swal.fire({ html: "É necessário confirmar que o documento é uma nota fiscal.", icon: 'error' });
            } else {
              let loteComAlgumaInconsistencia = false;
              if (this.tipoEvento == 'padreacao' || this.tipoEvento == 'fiv') {
                if (this.lote.ListaArquivos.length == 0) {
                  Swal.fire({ html: "É necessário anexar pelo menos um arquivo ao lote de comunicação. ", icon: 'error' });
                } else {
                  this.lote.Ocorrencias.forEach((element: any) => {
                    if (element.IndInconsitencia) {
                      loteComAlgumaInconsistencia = true;
                    }
                  });
                  let mensagem = 'Confirma Envio do Formulário?';
                  if (loteComAlgumaInconsistencia) {
                    mensagem = 'Há registros de Padreação com inconsistência. Confirma envio mesmo assim?';
                  }

                  const dialogRef = this.dialog.open(ConfirmacaoModalComponent, {
                    width: '100vw',
                    height: '100vh',
                    maxWidth: '400px',
                    maxHeight: '500px',
                    autoFocus: false,
                    data: {
                      mensagem: mensagem,
                      loteComAlgumaInconsistencia: loteComAlgumaInconsistencia,
                    },
                  });

                  dialogRef.componentInstance.dadosSelecionados.subscribe((result: any) => {
                    if (result) {
                      let data = result.dataComunicacao;
                      let observacao = result.observacao;

                      var loteSalvar = this.prepararLoteParaSalvar();
                      loteSalvar.Text = observacao;
                      loteSalvar.DataComunicacao = data;
                      this.api.enviar(loteSalvar, this.tipoEvento).then((result: any) => {
                        if (result.Data.success === false) {
                          Swal.fire({ html: result.message, icon: 'error' });
                        } else {
                          Swal.fire({
                            title: this.tituloMsgSucesso(),
                            html: 'Operação realizada com sucesso.',
                            icon: 'success',
                          }).then(() => {
                            // Configura o retorno e recupera o id da última ocorrência salva, caso contrário pega a ocorrência de retorno
                            if (loteSalvar.NovaOcorrenciaSalva && !this.commons.isNullOID(loteSalvar.NovaOcorrenciaSalva)) {
                              if (this.accountService.isCriador || this.accountService.isProcurador || this.accountService.isInspetor) {
                                //window.location.reload();
                                this.router.navigate([
                                  `${this.tipoEvento}_manter`,
                                  { e: btoa(loteSalvar.NovaOcorrenciaSalva), a: btoa(ConstantesAcaoEvento.Visualizar) },
                                ]);
                              } else if (this.accountService.isAdmin || this.accountService.isSuperintendente || this.accountService.isVeterinario) {

                                this.router.navigate([
                                  `${this.tipoEvento}_manter`,
                                  { e: btoa(loteSalvar.NovaOcorrenciaSalva), a: btoa(ConstantesAcaoEvento.Deliberar) },
                                ]);
                              }
                            } else {
                              if (this.accountService.isCriador || this.accountService.isProcurador) {

                                this.router.navigate([
                                  `${this.tipoEvento}_manter`,
                                  { e: btoa(result.Data.idEvento), a: btoa(ConstantesAcaoEvento.Visualizar) },
                                ]);
                              } else if (this.accountService.isAdmin || this.accountService.isSuperintendente || this.accountService.isVeterinario) {
                                this.router.navigate([
                                  `${this.tipoEvento}_manter`,
                                  { e: btoa(result.Data.idEvento), a: btoa(ConstantesAcaoEvento.Deliberar) },
                                ]);
                              }
                            }
                          });
                        }
                      });
                    }
                  });
                }
              }
            }
          }
        });
      }
    }
    else if (this.tipoEvento == 'baixa' && this.lote.Ocorrencias[0].TipoMotivoBaixa == TipoMotivoBaixa.ABATE_FRIGORIFICO) {
      if (this.lote.ListaArquivos.length == 0) {
        return Swal.fire({ html: "É necessário anexar pelo menos um arquivo ao lote de comunicação. ", icon: 'error' });
      } else {
        Swal.fire({
          title: 'Declaração',
          html: 'Estou ciente e me responsabilizo pelo conteúdo do anexo, sabendo que a certificação proveniente desta comunicação poderá ser cancelada caso o conteúdo do arquivo não seja válido.' +
            '<br>Você confirma a veracidade dos dados?',
          input: 'radio',
          inputOptions: {
            sim: 'Sim&nbsp;&nbsp;&nbsp;',
            nao: 'Não'
          },
          inputAttributes: {
            required: 'true'
          },
          icon: 'question',
          showCancelButton: true,
          cancelButtonText: 'Voltar',
          confirmButtonText: 'Confirmar',
        }).then(resultConfirmacao => {
          if (resultConfirmacao.isConfirmed) {
            if (resultConfirmacao.value == null || resultConfirmacao.value == 'nao' || resultConfirmacao.value == '') {
              Swal.fire({ html: "É necessário confirmar que os documentos anexados são os exigidos na certificação de carne.", icon: 'error' });
            } else {
              let mensagem = 'Confirma Envio do Formulário?';

              const dialogRef = this.dialog.open(ConfirmacaoModalComponent, {
                width: '100vw',
                height: '100vh',
                maxWidth: '400px',
                maxHeight: '500px',
                autoFocus: false,
                data: {
                  mensagem: mensagem,
                  loteComAlgumaInconsistencia: false,
                },
              });

              dialogRef.componentInstance.dadosSelecionados.subscribe((result: any) => {
                if (result) {
                  let data = result.dataComunicacao;
                  let observacao = result.observacao;

                  var loteSalvar = this.prepararLoteParaSalvar();
                  loteSalvar.Text = observacao;
                  loteSalvar.DataComunicacao = data;
                  this.api.enviar(loteSalvar, this.tipoEvento).then((result: any) => {
                    if (result.Data.success === false) {
                      Swal.fire({ html: result.message, icon: 'error' });
                    } else {
                      Swal.fire({
                        title: this.tituloMsgSucesso(),
                        html: 'Operação realizada com sucesso.',
                        icon: 'success',
                      }).then(() => {
                        // Configura o retorno e recupera o id da última ocorrência salva, caso contrário pega a ocorrência de retorno
                        if (loteSalvar.NovaOcorrenciaSalva && !this.commons.isNullOID(loteSalvar.NovaOcorrenciaSalva)) {
                          if (this.accountService.isCriador || this.accountService.isProcurador || this.accountService.isInspetor) {
                            //window.location.reload();
                            this.router.navigate([
                              `abatecomavalcarcaca_manter_pos`,
                              { e: btoa(this.lote.Ocorrencias[0].Id), a: btoa(ConstantesAcaoEvento.Visualizar) },
                            ]);
                          } else if (this.accountService.isAdmin || this.accountService.isSuperintendente || this.accountService.isVeterinario) {

                            this.router.navigate([
                              `abatecomavalcarcaca_manter_pos`,
                              { e: btoa(this.lote.Ocorrencias[0].Id), a: btoa(ConstantesAcaoEvento.Deliberar) },
                            ]);
                          }
                        } else {
                          if (this.accountService.isCriador || this.accountService.isProcurador) {

                            this.router.navigate([
                              `abatecomavalcarcaca_manter_pos`,
                              { e: btoa(this.lote.Ocorrencias[0].Id), a: btoa(ConstantesAcaoEvento.Visualizar) },
                            ]);
                          } else if (this.accountService.isAdmin || this.accountService.isSuperintendente || this.accountService.isVeterinario) {
                            this.router.navigate([
                              `abatecomavalcarcaca_manter_pos`,
                              { e: btoa(this.lote.Ocorrencias[0].Id), a: btoa(ConstantesAcaoEvento.Deliberar) },
                            ]);
                          }
                        }
                      });
                    }
                  });
                }
              });
            }
          }
        });
      }

    }
    else {
      let loteComAlgumaInconsistencia = false;
      this.lote.Ocorrencias.forEach((element: any) => {
        if (element.IndInconsitencia) {
          loteComAlgumaInconsistencia = true;
        }
      });

      let mensagem = 'Confirma Envio do Formulário?';
      if (loteComAlgumaInconsistencia) {
        mensagem = 'Há registros de Padreação com inconsistência. Confirma envio mesmo assim?';
      }

      const dialogRef = this.dialog.open(ConfirmacaoModalComponent, {
        width: '100vw',
        height: '100vh',
        maxWidth: '400px',
        maxHeight: '500px',
        autoFocus: false,
        data: {
          mensagem: mensagem,
          loteComAlgumaInconsistencia: loteComAlgumaInconsistencia,
        },
      });

      dialogRef.componentInstance.dadosSelecionados.subscribe((result: any) => {
        if (result) {
          let data = result.dataComunicacao;
          let observacao = result.observacao;

          var loteSalvar = this.prepararLoteParaSalvar();
          loteSalvar.Text = observacao;
          loteSalvar.DataComunicacao = data;
          this.api.enviar(loteSalvar, this.tipoEvento).then((result: any) => {
            if (result.Data.success === false) {

              Swal.fire({ html: result.message, icon: 'error' });
            } else {
              Swal.fire({
                title: this.tituloMsgSucesso(),
                html: 'Operação realizada com sucesso.',
                icon: 'success',
              }).then(() => {

                // Configura o retorno e recupera o id da última ocorrência salva, caso contrário pega a ocorrência de retorno
                if (loteSalvar.NovaOcorrenciaSalva && !this.commons.isNullOID(loteSalvar.NovaOcorrenciaSalva)) {
                  if (this.accountService.isCriador || this.accountService.isProcurador || this.accountService.isInspetor || this.accountService.isTecnico) {
                    //window.location.reload();
                    if (loteSalvar.Ocorrencias[0].TipoMotivoBaixa != null && loteSalvar.Ocorrencias[0].TipoMotivoBaixa == 55) {
                      this.router.navigate([
                        `abatecomavalcarcaca_manter_pos`,
                        { e: btoa(loteSalvar.NovaOcorrenciaSalva), a: btoa(ConstantesAcaoEvento.Visualizar) },
                      ]);
                    } else {

                      this.router.navigate([
                        `${this.tipoEvento}_manter`,
                        { e: btoa(loteSalvar.NovaOcorrenciaSalva), a: btoa(ConstantesAcaoEvento.Visualizar) },
                      ]);
                    }

                  } else if (this.accountService.isAdmin || this.accountService.isSuperintendente || this.accountService.isVeterinario) {
                    //window.location.reload();
                    if (loteSalvar.Ocorrencias[0].TipoMotivoBaixa != null && loteSalvar.Ocorrencias[0].TipoMotivoBaixa == 55) {
                      this.router.navigate([
                        `abatecomavalcarcaca_manter_pos`,
                        { e: btoa(loteSalvar.NovaOcorrenciaSalva), a: btoa(ConstantesAcaoEvento.Deliberar) },
                      ]);
                    } else {
                      this.router.navigate([
                        `${this.tipoEvento}_manter`,
                        { e: btoa(loteSalvar.NovaOcorrenciaSalva), a: btoa(ConstantesAcaoEvento.Visualizar) },
                      ]);
                    }
                  }
                } else {

                  if (this.accountService.isCriador || this.accountService.isProcurador || this.accountService.isTecnico) {
                    window.location.reload();
                    this.router.navigate([
                      `${this.tipoEvento}_manter`,
                      { e: btoa(result.Data.idEvento), a: btoa(ConstantesAcaoEvento.Visualizar) },
                    ]);
                  } else if (this.accountService.isAdmin || this.accountService.isSuperintendente) {
                    //window.location.reload();
                    if (this.tipoEvento == 'abate-com-aval-carcaca') {
                      this.router.navigate([
                        `abatecomavalcarcaca_manter`,
                        { e: btoa(result.Data.idEvento), a: btoa(ConstantesAcaoEvento.Deliberar) },
                      ]);
                    } else {
                      this.router.navigate([
                        `${this.tipoEvento}_manter`,
                        { e: btoa(result.Data.idEvento), a: btoa(ConstantesAcaoEvento.Deliberar) },
                      ]);
                    }

                  }
                }
              });
            }
          });
        }
      });
    }


  }

  async submeterEAprovar() {
    /*let loteComAlgumaInconsistencia = false;
    this.lote.Ocorrencias.forEach((element: any) => {
      if(element.IndInconsitencia){
        loteComAlgumaInconsistencia = true;
      }
    });

    let mensagem = "Confirma Envio e Aprovação do Formulário?";
    if(loteComAlgumaInconsistencia){
      mensagem = "Há registros de Padreação com inconsistência. Confirma envio mesmo assim?";
    }

    const dialogRef = this.dialog.open(
      ConfirmacaoModalComponent,
      {
        width: "100vw",
        height: "100vh",
        maxWidth: "400px",
        maxHeight: "500px",
        autoFocus: false,
        data: {
          mensagem: mensagem,
          loteComAlgumaInconsistencia: loteComAlgumaInconsistencia }
        }
    );

    dialogRef.componentInstance.dadosSelecionados.subscribe((result : any) => {
      if(result){
        let data = result.dataComunicacao;
        let observacao = result.observacao;

        var loteSalvar = this.prepararLoteParaSalvar();
        loteSalvar.Text = observacao;
        loteSalvar.DataComunicacao = data;

        this.api.enviarEAprovar(loteSalvar, this.tipoEvento).then(({ Data: result }: any) => {
          if(result.success === false){
            Swal.fire({ html: result.message, icon: 'error' });
          }else{
            Swal.fire({title: this.tituloMsgSucesso(), html: "Operação realizada com sucesso.", icon: 'success'}).then(() => {
              if(this.accountService.isCriador){
                this.router.navigate([`${this.tipoEvento}_manter`, {e: btoa(result.idEvento), a: btoa(ConstantesAcaoEvento.Editar)}]);
              }else{
                this.router.navigate([`${this.tipoEvento}_manter`, {e: btoa(result.idEvento), a: btoa(ConstantesAcaoEvento.Deliberar)}]);
              }
            });
          }
        });
      }
    });*/
  }

  aprovar() {
    Swal.fire({
      title: 'Confirmação',
      html: 'ATENÇÃO! Aprovar <strong>qualquer</strong> ocorrência de forma isolada em um protocolo desativa a opção "Aprovar todos". Confirma a aprovação do registro?',
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim',
      input: 'textarea',
      inputPlaceholder: 'Utilize este campo para informar alguma observação da aprovação.',
    }).then(resultConfirmacao => {
      if (resultConfirmacao.isConfirmed) {
        var loteSalvar = this.prepararLoteParaSalvar();
        loteSalvar.ObservacaoDeliberacao = resultConfirmacao.value;

        this.api.aprovar(loteSalvar, this.tipoEvento).then(({ Data: result }: any) => {
          if (result.success === false) {
            Swal.fire(result.message, '', 'error');
          } else {
            Swal.fire({
              title: this.tituloMsgSucesso(),
              html: 'Operação realizada com sucesso.',
              icon: 'success',
            }).then(() => {
              if (this.idEvento == result.idEvento) {
                if (loteSalvar.Ocorrencias[0].TipoMotivoBaixa != null && loteSalvar.Ocorrencias[0].TipoMotivoBaixa == 55) {
                  this.router.navigate([
                    `abatecomavalcarcaca_manter_pos`,
                    { e: btoa(result.idEvento), a: btoa(ConstantesAcaoEvento.Deliberar) },
                  ]);
                } else {
                  //console.log(this.tipoEvento)
                  this.router.navigate([
                    `${this.tipoEvento}_manter`,
                    { e: btoa(result.idEvento), a: btoa(ConstantesAcaoEvento.Deliberar) },
                  ]);
                }
              } else {
                if (loteSalvar.Ocorrencias[0].TipoMotivoBaixa != null && loteSalvar.Ocorrencias[0].TipoMotivoBaixa == 55) {
                  this.router.navigate([
                    `abatecomavalcarcaca_manter_pos`,
                    { e: btoa(result.idEvento), a: btoa(ConstantesAcaoEvento.Deliberar) },
                  ]);
                } else {
                  //console.log(this.tipoEvento)
                  this.router.navigate([
                    `${this.tipoEvento}_manter`,
                    { e: btoa(result.idEvento), a: btoa(ConstantesAcaoEvento.Deliberar) },
                  ]);
                }
              }
            });
          }
        });
      }
    });
  }

  aprovarComPendencia() {
    Swal.fire({
      title: 'Confirmação',
      html: 'Confirma a aprovação com pendências do registro?',
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim',
      input: 'textarea',
      inputPlaceholder: 'Utilize este campo para informar alguma observação da aprovação com pendências.',
    }).then(resultConfirmacao => {
      if (resultConfirmacao.isConfirmed) {
        var loteSalvar = this.prepararLoteParaSalvar();
        loteSalvar.ObservacaoDeliberacao = resultConfirmacao.value;

        this.api.aprovarComPendencia(loteSalvar, this.tipoEvento).then(({ Data: result }: any) => {
          if (result.success === false) {
            Swal.fire(result.message, '', 'error');
          } else {
            Swal.fire({
              title: this.tituloMsgSucesso(),
              html: 'Operação realizada com sucesso.',
              icon: 'success',
            }).then(() => {
              if (this.idEvento == result.idEvento) {
                window.location.reload();
              } else {
                this.router.navigate([
                  `${this.tipoEvento}_manter`,
                  { e: btoa(result.idEvento), a: btoa(ConstantesAcaoEvento.Deliberar) },
                ]);
              }
            });
          }
        });
      }
    });
  }

  /*rejeitar(){
    Swal.fire({
      title: "Confirmação",
      html: "Confirma a rejeição do registro?",
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim',
      input: 'textarea',
      inputPlaceholder: 'Utilize este campo para informar alguma observação da rejeição.',
    }).then((resultConfirmacao) => {
      if (resultConfirmacao.isConfirmed) {

        var loteSalvar = this.prepararLoteParaSalvar();
        loteSalvar.ObservacaoDeliberacao = resultConfirmacao.value;

        this.api.rejeitar(loteSalvar, this.tipoEvento).then((result: any) => {
          if(result.success === false){
            Swal.fire(result.message, '', 'error');
          }else{
            Swal.fire({title: this.tituloMsgSucesso(), html: "Operação realizada com sucesso.", icon: 'success'}).then(() => {
              if(this.idEvento == result.Data.idEvento){
                window.location.reload();
              }else{
                this.router.navigate([`${this.tipoEvento}_manter`, {e: btoa(result.Data.idEvento), a: btoa(ConstantesAcaoEvento.Deliberar)}]);
              }
            });
          }
        });

      }
    });
  }*/

  revisar() {
    Swal.fire({
      title: 'Confirmação',
      html: 'Confirma o envio do registro para revisão?',
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim',
      input: 'textarea',
      inputPlaceholder: 'Utilize este campo para informar alguma observação da revisão.',
    }).then(resultConfirmacao => {
      if (resultConfirmacao.isConfirmed) {
        var loteSalvar = this.prepararLoteParaSalvar();
        loteSalvar.ObservacaoDeliberacao = resultConfirmacao.value;

        this.api.revisar(loteSalvar, this.tipoEvento).then((result: any) => {
          if (result.success === false) {
            Swal.fire(result.message, '', 'error');
          } else {
            Swal.fire({
              title: this.tituloMsgSucesso(),
              html: 'Operação realizada com sucesso.',
              icon: 'success',
            }).then(() => {
              if (this.idEvento == result.Data.idEvento) {
                window.location.reload();
              } else {
                this.router.navigate([
                  `${this.tipoEvento}_manter`,
                  { e: btoa(result.Data.idEvento), a: btoa(ConstantesAcaoEvento.Deliberar) },
                ]);
              }
            });
          }
        });
      }
    });
  }

  aprovarTodos() {
    Swal.fire({
      title: 'Atenção!',
      html: 'Ao confirmar, todas as comunicações desse protocolo serão aprovadas. Deseja continuar?',
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim',
      input: 'textarea',
      inputPlaceholder: 'Utilize este campo para informar alguma observação da aprovação.',
    }).then(resultConfirmacao => {
      if (resultConfirmacao.isConfirmed) {
        var loteSalvar = this.prepararLoteParaSalvar();
        loteSalvar.ObservacaoDeliberacao = resultConfirmacao.value;

        this.api.aprovarTodos(loteSalvar, this.tipoEvento).then(({ Data: result }: any) => {
          if (result.success === false) {
            Swal.fire(result.message, '', 'error');
          } else {
            Swal.fire({
              title: this.tituloMsgSucesso(),
              html: 'Operação realizada com sucesso.',
              icon: 'success',
            }).then(() => {
              console.log(this.idEvento, result.idEvento);
              console.log(loteSalvar.Ocorrencias[0].TipoMotivoBaixa, loteSalvar.Ocorrencias[0].TipoMotivoBaixa == 55);
              if (this.idEvento == result.idEvento) {
                if (loteSalvar.Ocorrencias[0].TipoMotivoBaixa != null && loteSalvar.Ocorrencias[0].TipoMotivoBaixa == 55) {
                  this.router.navigate([
                    `abatecomavalcarcaca_manter_pos`,
                    { e: btoa(result.idEvento), a: btoa(ConstantesAcaoEvento.Visualizar) },
                  ]);
                } else {
                  //console.log(this.tipoEvento)
                  this.router.navigate([
                    `${this.tipoEvento}_manter`,
                    { e: btoa(result.idEvento), a: btoa(ConstantesAcaoEvento.Deliberar) },
                  ]);
                }

              } else {
                if (loteSalvar.Ocorrencias[0].TipoMotivoBaixa != null && loteSalvar.Ocorrencias[0].TipoMotivoBaixa == 55) {
                  this.router.navigate([
                    `abatecomavalcarcaca_manter_pos`,
                    { e: btoa(result.idEvento), a: btoa(ConstantesAcaoEvento.Visualizar) },
                  ]);
                } else {
                  //console.log(this.tipoEvento)
                  this.router.navigate([
                    `${this.tipoEvento}_manter`,
                    { e: btoa(result.idEvento), a: btoa(ConstantesAcaoEvento.Deliberar) },
                  ]);
                }
              }
            });
          }
        });
      }
    });
  }


  prepararLoteParaSalvar(confirmacao: boolean = false) {
    var loteSalvar = JSON.parse(JSON.stringify(this.lote));
    loteSalvar.OcorrenciaAtual = JSON.parse(JSON.stringify(this.ocorrenciaAtual));
    loteSalvar.OcorrenciaAtual.LoteOcorrencia = JSON.parse(JSON.stringify(loteSalvar));
    //loteSalvar.OcorrenciaAtual.LoteOcorrencia.OcorrenciaAtual = {};

    loteSalvar.success = true;
    loteSalvar.message = '';
    loteSalvar.ConfirmaInconsistencia = confirmacao;
    loteSalvar.AlertInconsistencia = '';
    loteSalvar.Contexto = (environment as any).contexto;
    return loteSalvar;
  }

  preencherFormulario(result: any, novoItem: boolean) {
    if (result.Id !== '-1') {

      this.lote = result;
      this.lote.IsEmDeliberacao = this.acao === ConstantesAcaoEvento.Deliberar;
      if (!novoItem) {
        this.ocorrenciaAtual = result.OcorrenciaAtual;
        this.lote.OcorrenciaAtual = result.OcorrenciaAtual;
        if (this.tipoEvento == ConstantesTipoEvento.INSPECAO) {
          this.ocorrenciaAtual.Animal.NomeBovino = result.OcorrenciaAtual.NomeAnimal;
          this.ocorrenciaAtual.Animal.DataNascimento = result.OcorrenciaAtual.DataNascimento;
          this.ocorrenciaAtual.DataNascimento = result.OcorrenciaAtual.DataNascimento;

          if (this.lote.OcorrenciaAtual.TipoInspecao == 1) {
            this.ocorrenciaAtual.GrauSangue = this.lote.OcorrenciaAtual.GrauSangue;
            this.ocorrenciaAtual.TipoGrauSangue = this.lote.OcorrenciaAtual.TipoGrauSangue;
            this.ocorrenciaAtual.SituacaoSrg = SituacaoSRG['Registro Definitivo Emitido'];
          }
        }
        if (this.tipoEvento == ConstantesTipoEvento.TEFIV || this.tipoEvento == ConstantesTipoEvento.INOVULACAO) {
          if (result.OcorrenciaAtual.Veterinario.crmv != "" && result.OcorrenciaAtual.Veterinario.crmv != null) {
            this.ocorrenciaAtual.isReadOnly = true;
          }
        }
      }
      if (this.tipoEvento == ConstantesTipoEvento.ABATE_COM_AVAL_CARCACA) {
        this.editar(result.Ocorrencias[0]);
      }
      if (this.tipoEvento == ConstantesTipoEvento.BAIXA && result.OcorrenciaAtual.Frigorifico.Id != null
        && result.OcorrenciaAtual.Frigorifico.Id != '') {
        console.log(result.OcorrenciaAtual.HasPreAbate)
        this.ocorrenciaAtual.Frigorifico.id = result.OcorrenciaAtual.Frigorifico.Id;
        this.ocorrenciaAtual.Frigorifico.codigo = result.OcorrenciaAtual.Frigorifico.CodigoCriador;
        this.ocorrenciaAtual.Frigorifico.nome = result.OcorrenciaAtual.Frigorifico.NomeCriador;
        this.ocorrenciaAtual.Frigorifico.tituloConsulta = result.OcorrenciaAtual.Frigorifico.TituloConsulta;
        this.ocorrenciaAtual.TipoMotivoBaixa = result.OcorrenciaBaixaAtual.TipoMotivoBaixa;
        this.ocorrenciaAtual.PreAbate = result.OcorrenciaBaixaAtual.PreAbate;
        if (this.ocorrenciaAtual.HasPreAbate) {
          this.ocorrenciaAtual.PossuiPreAbate = '1';
        } else {
          this.ocorrenciaAtual.PossuiPreAbate = '2';
        }
        if (result.OcorrenciaAtual.FrigorificoAbate.Id != null
          && result.OcorrenciaAtual.FrigorificoAbate.Id != ''
          && result.OcorrenciaAtual.FrigorificoAbate.Id != '-1') {
          this.ocorrenciaAtual.FrigorificoAbate.Id = result.OcorrenciaAtual.FrigorificoAbate.Id;
          this.ocorrenciaAtual.FrigorificoAbate.CodigoCriador = result.OcorrenciaAtual.FrigorificoAbate.CodigoCriador;
          this.ocorrenciaAtual.FrigorificoAbate.NomeCriador = result.OcorrenciaAtual.FrigorificoAbate.NomeCriador;
          this.ocorrenciaAtual.FrigorificoAbate.TituloConsulta = result.OcorrenciaAtual.FrigorificoAbate.TituloConsulta;
          this.ocorrenciaAtual.HasPreAbate = result.OcorrenciaAtual.HasPreAbate;

          console.log(this.ocorrenciaAtual)
        }
      }
      this.formulario.id = result.Id;
      this.formulario.situacao = this.commons.getDescricaoSituacaoLote(result.Situacao);
      this.formulario.situacaoLote = result.Situacao;
      this.formulario.formulario = result.numero;
      this.formulario.protocolo = result.Protocolo;
      this.formulario.dataCriacao = this.datePipe.transform(result.DataOcorrencia, 'dd/MM/yyyy') ?? '-';
      this.formulario.dataComunicacao = result.DescDataComunicacao;

      this.formulario.criador.readonly = true;
      this.formulario.criador.id = result.Criador.Id;
      this.formulario.criador.nome = result.Criador.NomeCriador;
      this.formulario.criador.codigo = result.Criador.CodigoCriador;
      this.formulario.criador.afixo = result.Criador.AfixoCriador;
      this.formulario.criador.posicaoAfixo = result.Criador.PosicaoAfixo;
      this.formulario.criador.usuarioId = '';

      this.ocorrencias = [];
      if (result.Ocorrencias) {
        result.Ocorrencias.forEach((element: any) => {
          element.DescDataColeta =
            element.DataColeta === '0001-01-01T00:00:00'
              ? '-'
              : this.datePipe.transform(element.DataColeta, 'dd/MM/yyyy');
          element.DescDataCobertura =
            element.DataCobertura === '0001-01-01T00:00:00'
              ? '-'
              : this.datePipe.transform(element.DataCobertura, 'dd/MM/yyyy');
          element.DescDataComunicacao =
            element.DataComunicacao === '0001-01-01T00:00:00'
              ? '-'
              : this.datePipe.transform(element.DataComunicacao, 'dd/MM/yyyy');

          this.ocorrencias.push(element);
        });
      }

      //Ordena decrescente pela data da ultima atualização ou cadastro.
      this.ocorrencias.sort((a: any, b: any) => {
        return new Date(b.DataAgendamento).getTime() - new Date(a.DataAgendamento).getTime();
      });
    } else {
      this.lote = new LoteOcorrenciaEventoModel();
      this.lote.Criador.Id = this.formulario.criador.id;
      this.lote.Criador.NomeCriador = this.formulario.criador.nome;
      this.lote.Criador.CodigoCriador = this.formulario.criador.codigo;
      this.lote.Criador.AfixoCriador = this.formulario.criador.afixo;
    }
  }

  permiteEdicao(situacaoEvento: SituacaoOcorrenciaEvento) {
    if (this.tipoEvento == ConstantesTipoEvento.INSPECAO) {
      if (this.accountService.isCriador && this.ocorrenciaAtual.Situacao === SituacaoOcorrenciaEvento.AguardandoResolucao) {
        return false;
      }
    }
    if (this.lote) {
      return (
        (this.lote.Situacao === SituacaoLoteOcorrencia.EmElaboracao &&
          situacaoEvento === SituacaoOcorrenciaEvento.Pendente) ||
        situacaoEvento === SituacaoOcorrenciaEvento.AprovadoComPendencias ||
        situacaoEvento === SituacaoOcorrenciaEvento.AguardandoResolucao
      );
    }
    return false;
  }

  editar(evento: any) {
    this.ocorrenciaAtual = JSON.parse(JSON.stringify(evento));
    this.lote.OcorrenciaAtual = JSON.parse(JSON.stringify(evento));
  }

  deletar(evento: any) {
    Swal.fire({
      title: 'Confirmação',
      html: 'Confirma a remoção do registro?',
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim',
    }).then(resultConfirmacao => {
      if (resultConfirmacao.isConfirmed) {
        this.lote.IdOcorrenciaSelecionada = evento.Id;
        this.api.deleteItem(this.lote, this.tipoEvento).then((result: any) => {
          if (result.success) {
            Swal.fire({ title: this.tituloMsgSucesso(), html: 'Registro removido com sucesso.', icon: 'success' }).then(
              () => {
                if (result.Ocorrencias.length > 0) {
                  this.preencherFormulario(result, true);
                } else {
                  this.router.navigate([`${this.tipoEvento}_consultar`]);
                }
              }
            );
          } else {
            Swal.fire(result.message, '', 'error');
          }
        });
      }
    });
  }

  documentosDaOcorrencia() {
    this.dialog.open(DocumentosOcorrenciaModalComponent, {
      height: '90%',
      width: '90%',
      autoFocus: false,
      data: { idOcorrencia: this.ocorrenciaAtual.Id, situacaoOcorrencia: this.ocorrenciaAtual.Situacao },
    });
  }

  isOcorrenciaReadOnly(): boolean {
    // #128: Aprovações com pendências podem ser editadas
    /*if ((this.acao === ConstantesAcaoEvento.Visualizar || this.acao === ConstantesAcaoEvento.Deliberar) &&
        this.ocorrenciaAtual.Situacao !== SituacaoOcorrenciaEvento.AprovadoComPendencias) {
          return true;
    }*/

    if (this.tipoEvento == ConstantesTipoEvento.INSPECAO) {
      if (this.accountService.isCriador && this.ocorrenciaAtual.Situacao === SituacaoOcorrenciaEvento.AguardandoResolucao) {
        return true;
      }
    }
    if (
      this.ocorrenciaAtual.Situacao === SituacaoOcorrenciaEvento.Pendente ||
      this.ocorrenciaAtual.Situacao === SituacaoOcorrenciaEvento.AprovadoComPendencias ||
      this.ocorrenciaAtual.Situacao === SituacaoOcorrenciaEvento.AguardandoResolucao
    ) {
      return false;
    } else {
      return true;
    }
  }

  isNewLote(): boolean {
    if (this.formulario) {
      return (
        this.formulario.criador.id === '' ||
        this.formulario.criador.id === '-1' ||
        this.formulario.criador.id === undefined
      );
    }
    return true;
  }

  tituloMsgConfirmacao() {
    return `Confirmação de Inconsistências`;
  }

  msgConfirmacao(AlertInconsistencia: string) {
    return `<div class='text-left'>${AlertInconsistencia}</div>`;
  }

  msgConfirmacaoNova() {
    return `Caso haja dados alterados para o registro atual os mesmos serão perdidos. Confirma a ação?`;
  }

  tituloMsgSucesso() {
    return `"Sucesso"`;
  }

  msgSucessoCadastro() {
    return `Evento cadastrado com sucesso!<br>Deseja anexar um documento a esse registro?`;
  }

  msgSucessoAtualizacao() {
    return `Evento atualizado com sucesso!`;
  }

  descSituacao(situacao: number, somenteTexto: boolean = false) {
    var descSituacao = { icone: '', texto: '' };

    switch (situacao) {
      case SituacaoOcorrenciaEvento.Pendente:
        descSituacao = { icone: 'fa fa-cog', texto: 'Pendente' };
        break;
      case SituacaoOcorrenciaEvento.Cancelado:
        descSituacao = { icone: 'fa fa-ban', texto: 'Cancelado' };
        break;
      case SituacaoOcorrenciaEvento.Aprovado:
        descSituacao = { icone: 'fa fa-check', texto: 'Aprovado' };
        break;
      case SituacaoOcorrenciaEvento.AprovadoComPendencias:
        descSituacao = { icone: 'fa fa-check-circle', texto: 'Aprovado Com Pendências' };
        break;
      case SituacaoOcorrenciaEvento.Rejeitado:
        descSituacao = { icone: 'fa fa-times', texto: 'Rejeitado' };
        break;
      case SituacaoOcorrenciaEvento.AguardandoAprovacao:
        descSituacao = { icone: 'fa fa-hourglass', texto: 'Aguardando Aprovação' };
        break;
      case SituacaoOcorrenciaEvento.AguardandoResolucao:
        descSituacao = { icone: 'fa fa-clock-o', texto: 'Aguardando Resolução' };
        break;
      case SituacaoOcorrenciaEvento.AprovadoSuperintendente:
        descSituacao = { icone: 'fa fa-check', texto: 'Aprovado Pelo Superintendente' };
        break;
      case SituacaoOcorrenciaEvento.RejeitadoSuperintendente:
        descSituacao = { icone: 'fa fa-times', texto: 'Rejeitado Pelo Superintendente' };
        break;
      case SituacaoOcorrenciaEvento.AguardandoAprovacaoVeterinario:
        descSituacao = { icone: 'fas fa-user-clock', texto: 'Aguardando Aprovação Veterinário' };
        break;
      case SituacaoOcorrenciaEvento.RejeitadoPeloVeterinario:
        descSituacao = { icone: 'fas fa-user-lock', texto: 'Rejeitado Pelo Veterinário' };
        break;
    }

    if (somenteTexto) {
      return descSituacao.texto;
    } else {
      return `<i class="${descSituacao.icone}"></i> ${descSituacao.texto}`;
    }
  }

  setIdConsultaBovino(idCriador: string) {
    if (this.ocorrenciaAtual.Animal) {
      this.ocorrenciaAtual.Animal.IdProprietarioConsulta = idCriador;
      // this.ocorrenciaAtual.Animal.IdProprietario = idCriador;
    }

    if (this.ocorrenciaAtual.Doadora) {
      this.ocorrenciaAtual.Doadora.IdProprietarioConsulta = idCriador;
      // this.ocorrenciaAtual.Doadora.IdProprietario = idCriador;
    }

    if (this.ocorrenciaAtual.Matriz) {
      this.ocorrenciaAtual.Matriz.IdProprietarioConsulta = idCriador;
      // this.ocorrenciaAtual.Matriz.IdProprietario = idCriador;
    }

    if (this.ocorrenciaAtual.Mae) {
      this.ocorrenciaAtual.Mae.IdProprietarioConsulta = idCriador;
      // this.ocorrenciaAtual.Mae.IdProprietario = idCriador;
    }

    if (this.ocorrenciaAtual.Pai) {
      this.ocorrenciaAtual.Pai.IdProprietarioConsulta = idCriador;
      // this.ocorrenciaAtual.Pai.IdProprietario = idCriador;
    }

    if (this.ocorrenciaAtual.Reprodutor) {
      this.ocorrenciaAtual.Reprodutor.IdProprietarioConsulta = idCriador;
      // this.ocorrenciaAtual.Reprodutor.IdProprietario = idCriador;
    }

    if (this.ocorrenciaAtual.Receptora) {
      this.ocorrenciaAtual.Receptora.IdProprietarioConsulta = idCriador;
      // this.ocorrenciaAtual.Receptora.IdProprietario = idCriador;
    }

    if (this.ocorrenciaAtual.Touro) {
      this.ocorrenciaAtual.Touro.IdProprietarioConsulta = idCriador;
      // this.ocorrenciaAtual.Touro.IdProprietario = idCriador;
    }
  }

  getGrauSanguePorTipo(tipo: number, selecione: boolean = false, isAdmin?: boolean): IValueLabel<string, string>[] {
    const grauSangue: IValueLabel<string, string>[] = [];

    if (selecione) grauSangue.push({ value: '-1', label: 'Selecione...' });
    /**
    if (tipo == TipoGrauSangue.SRD) {
      grauSangue.push({ value: '1/2', label: '1/2' });
      return grauSangue;
    }
    **/
    if (tipo == TipoGrauSangue.PO) {
      grauSangue.push({ value: '1/1', label: '1/1' });
      return grauSangue;
    }

    if (tipo == TipoGrauSangue.PA) {
      grauSangue.push({ value: '31/32', label: '31/32' });

      //if ((this.env2 as any).contexto === 'wagyu') {
      //  grauSangue.push({ value: '63/64', label: '63/64' });
      //  grauSangue.push({ value: '127/128', label: '127/128' });
      //  grauSangue.push({ value: '255/256', label: '255/256' });
      //  grauSangue.push({ value: '511/512', label: '511/512' });
      //}

      return grauSangue;
    }

    //if (tipo == TipoGrauSangue.PS) {
    //  grauSangue.push({ value: '5/8', label: '5/8' });
    //  return grauSangue;
    //}

    if (tipo == TipoGrauSangue.CCG) {

      grauSangue.push({ value: '1/2', label: '1/2' });
      //grauSangue.push({ value: '1/4', label: '1/4' });
      grauSangue.push({ value: '3/4', label: '3/4' });
      //if (contextoJersey) grauSangue.push({ value: '1/8', label: '1/8' });
      //grauSangue.push({ value: '3/8', label: '3/8' });
      //grauSangue.push({ value: '5/8', label: '5/8' });
      grauSangue.push({ value: '7/8', label: '7/8' });
      //if (contextoJersey) grauSangue.push({ value: '3/16', label: '3/16' });
      //grauSangue.push({ value: '9/16', label: '9/16' });
      //grauSangue.push({ value: '11/16', label: '11/16' });
      //grauSangue.push({ value: '15/16', label: '15/16' });
      return grauSangue;
    }

    /*if (tipo == TipoGrauSangue.PC || (tipo == TipoGrauSangue.ZT && isAdmin === true)) {
      if (tipo == TipoGrauSangue.ZT && isAdmin === true) {
        grauSangue.push({ value: '1/2', label: '0' });
      }
      grauSangue.push({ value: '31/32', label: '31/32' });
      grauSangue.push({ value: '63/64', label: '63/64' });
      grauSangue.push({ value: '127/128', label: '127/128' });
      grauSangue.push({ value: '255/256', label: '255/256' });
      grauSangue.push({ value: '511/512', label: '511/512' });
      grauSangue.push({ value: '1023/1024', label: '1023/1024' });
      grauSangue.push({ value: '2047/2048', label: '2047/2048' });
      grauSangue.push({ value: '4095/4096', label: '4095/4096' });
      grauSangue.push({ value: '8191/8192', label: '8191/8192' });
      grauSangue.push({ value: '16383/16384', label: '16383/16384' });
      grauSangue.push({ value: '32767/32768', label: '32767/32768' });
      grauSangue.push({ value: '65535/65536', label: '65535/65536' });
      grauSangue.push({ value: '131071/131072', label: '131071/131072' });
      grauSangue.push({ value: '262143/262144', label: '262143/262144' });
      grauSangue.push({ value: '524287/524288', label: '524287/524288' });
      grauSangue.push({ value: '1048575/1048576', label: '1048575/1048576' });
      grauSangue.push({ value: '2097151/2097154', label: '2097151/2097154' });
      grauSangue.push({ value: '4194303/4194304', label: '4194303/4194304' });
      grauSangue.push({ value: '8388607/8388608', label: '8388607/8388608' });
      grauSangue.push({ value: '16777215/16777216', label: '16777215/16777216' });
      grauSangue.push({ value: '33554431/33554432', label: '33554431/33554432' });
      grauSangue.push({ value: '67108863/67108864', label: '67108863/67108864' });
      return grauSangue;
    }*/

    if (isAdmin === false && tipo == TipoGrauSangue.ZT) {
      // Grau de sangue ZT fixo p/ criadores
      grauSangue.push({ value: '1/2', label: '0' });
      return grauSangue;
    }
    if (tipo == TipoGrauSangue._12B) {
      grauSangue.push({ value: '1/2', label: '1/2' });
      return grauSangue;
    }
    if (tipo == TipoGrauSangue._14B) {
      grauSangue.push({ value: '3/4', label: '3/4' });
      return grauSangue;
    }
    if (tipo == TipoGrauSangue._16B) {
      grauSangue.push({ value: '15/16', label: '15/16' });
      return grauSangue;
    }
    if (tipo == TipoGrauSangue._18B) {
      grauSangue.push({ value: '7/8', label: '7/8' });
      return grauSangue;
    }
    if (tipo == TipoGrauSangue._34B) {
      grauSangue.push({ value: '1/4', label: '1/4' });
      return grauSangue;
    }
    if (tipo == TipoGrauSangue._38A) {
      grauSangue.push({ value: '5/8', label: '5/8' });
      return grauSangue;
    }
    if (tipo == TipoGrauSangue._38B) {
      grauSangue.push({ value: '9/16', label: '9/16' });
      return grauSangue;
    }
    if (tipo == TipoGrauSangue._38P) {
      grauSangue.push({ value: '5/8', label: '5/8' });
      return grauSangue;
    }
    if (tipo == TipoGrauSangue.PC1) {
      grauSangue.push({ value: '63/64', label: '63/64' });
      return grauSangue;
    }
    if (tipo == TipoGrauSangue.PC2) {
      grauSangue.push({ value: '127/128', label: '127/128' });
      return grauSangue;
    }
    if (tipo == TipoGrauSangue.PC3) {
      grauSangue.push({ value: '255/256', label: '255/256' });
      return grauSangue;
    }
    if (tipo == TipoGrauSangue.PC4) {
      grauSangue.push({ value: '511/512', label: '511/512' });
      return grauSangue;
    }

    return [];
  }
}
