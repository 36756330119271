import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FiltroConsultaCriadoresModel } from 'src/app/models/FiltroConsultaCriadoresModel';
import { LocalStorageService } from 'src/app/shared/local-storage.service';
import { QueryStateService } from 'src/app/shared/query-state.service';
import { environment } from 'src/environments/environment';
import { AccountService } from './../../account/shared/account.service';

@Component({
  selector: 'app-filtro-consulta-criador',
  templateUrl: './filtro-consulta-criador.component.html',
  styleUrls: ['./filtro-consulta-criador.component.scss']
})
export class FiltroConsultaCriadorComponent implements OnInit {

  @Output() triggerConsulta: EventEmitter<void> = new EventEmitter<void>();
  @Input() formConsulta: FiltroConsultaCriadoresModel = new FiltroConsultaCriadoresModel();
  
  readonly env = environment;
  tiposUsuario: {value: number, label: string}[] = [];
  situacoesEvento: {value: number, label: string}[] = [];

  constructor(
    private accountService: AccountService,
    private route: ActivatedRoute, 
    private localStorage: LocalStorageService,
    private queryState : QueryStateService
  ) { }

  ngOnInit(): void {
    this.preencherTiposusuario();
    this.preencherSituacoes();
    this.formConsulta.filiadaReadonly = !this.accountService.isSuperAdmin;   
    this.recuperarFormulario();
    this.consultar();
    
  }

  alterarTipoUsuario() {
    if (this.formConsulta.tipoUsuario == -1) this.formConsulta.cpf_cnpj = ""; 
  }

  preencherTiposusuario(){
    this.tiposUsuario = [];
    this.tiposUsuario.push({value: -1, label: "Todos"});
    this.tiposUsuario.push({value: 1, label: "Pessoa Física"});
    this.tiposUsuario.push({value: 2, label: "Pessoa Jurídica"});
  }

  preencherSituacoes(){
    this.situacoesEvento = [];
    this.situacoesEvento.push({value: -1, label: "Todas"});
    this.situacoesEvento.push({value: 1, label: "Ativo"});
    this.situacoesEvento.push({value: 2, label: "Inativo"});
  }
  
  filiadaSelecionada(idFiliada: string){
    this.formConsulta.vinculacao = idFiliada;
  }

  //consultar = () => this.triggerConsulta.emit();

  consultar(){
    this.queryState.saveFormCriador(this.formConsulta);
    //obtem a rota da página atual
    this.queryState.getRota(this.route.snapshot.url[0].path);
    this.triggerConsulta.emit();
  }

  private recuperarFormulario() {
    if(this.localStorage.get("rotaAnterior") == this.route.snapshot.url[0].path){
      // Recupera o state passado pela função de consultas do painel gerencial através da rota
      let state = history.state;

      // Recupera o state da última busca do localStorage
      const queryState = this.localStorage.get("formConsultaCriador");
      
      // Caso não haja state do form salvo, inicializa vazio
      if (!state && !queryState) return;

      // Caso não haja state passado pelo painel gerencial, mas exista um form salvo para essa consulta no storage
      Object.assign(state, queryState);

      this.formConsulta.afixo = state.Afixo;
      this.formConsulta.codigo = state.Codigo;
      this.formConsulta.cpf_cnpj = state.Cpf_cnpj;
      this.formConsulta.nome = state.Nome;
      this.formConsulta.situacao = state.Situacao;
      this.formConsulta.tipoUsuario = state.TipoUsuario;
      
      this.triggerConsulta.emit();

    }else{
      this.localStorage.remove("formConsultaCriador");
      this.localStorage.remove("page");
    }
  }
}
