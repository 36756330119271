import { ApiService } from 'src/app/shared/api.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-documentos-lote-modal',
  templateUrl: './documentos-lote-modal.component.html',
  styleUrls: ['./documentos-lote-modal.component.scss']
})
export class DocumentosLoteModalComponent implements OnInit {

  documentos: any[] = [];
  fileName = '';
  base64: any;
  descricao = '';
  idLote = '';
  situacaoLote: number = 0;

  constructor(
    private api: ApiService,
    public dialogRef: MatDialogRef<DocumentosLoteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.idLote = this.data.idLote;
    this.situacaoLote = this.data.situacaoLote;

    this.consultarArquivos();
  }

  consultarArquivos(){
    this.api.arquivosDoLote(this.idLote).then((result: any) =>{
      if(result){
        this.documentos = result.ListaArquivos;
      }
    })
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];

    if (file) {
      this.fileName = file.name;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.base64 = reader.result;
      };
    }
  }

  anexarArquivo() {
    if(this.fileName && this.base64){

      let arquivo = {
        IdLote: this.idLote,
        Descricao: this.descricao,
        Nome: this.fileName,
        Content: this.base64.split(",")[1]
      };

      this.api.uploadArquivoDoLote(arquivo).then((result: any) => {
        if(result){
          if(result.success){
            Swal.fire("Arquivo anexado com sucesso.", '', 'success');
            this.descricao = "";
            this.fileName = "";
            this.base64 = "";
            this.consultarArquivos();
          }else{
            Swal.fire(result.message, '', 'error');
          }
        }
      });
    }else{
      Swal.fire("Selecione um arquivo para enviar", '', 'error');
    }
  }

  deletar(arquivo: any){
    Swal.fire({title: "Confirmação", html: "Confirma a exclusão do arquivo?", icon: 'question', showCancelButton: true, cancelButtonText: 'Não', confirmButtonText: 'Sim'}).then((resultConfirmacao) => {
      if (resultConfirmacao.isConfirmed) {
        this.api.excluirArquivo(arquivo).then((result: any) => {
          if(result){
            if(result.success){
              Swal.fire("Arquivo excluido com sucesso.", '', 'success');
              this.consultarArquivos();
            }else{
              Swal.fire(result.message, '', 'error');
            }
          }
        });
      }
    });
  }

  download(arquivo: any){
    this.api.downloadArquivo(arquivo).then((result: any) => {
      if(result){
        const linkSource = `data:application/octet-stream;base64,${result.Content}`;
        const downloadLink = document.createElement("a");
        const fileName = result.Nome;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    });
  }

}
