import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReservarRegistrosAssociacaoModel } from 'src/app/models/ReservarRegistrosAssociacaoModel';
import { ApiService } from 'src/app/shared/api.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { BovinoService } from '../../shared/bovino.service';

@Component({
  selector: 'app-manter-registro-associacao',
  templateUrl: './manter-registro-associacao.component.html',
  styleUrls: ['./manter-registro-associacao.component.scss']
})
export class ManterRegistroAssociacaoComponent implements OnInit {

  readonly env = environment;

  tiposRegistros: { label: string, value: number }[] = [];
  formulario: ReservarRegistrosAssociacaoModel = new ReservarRegistrosAssociacaoModel();

  constructor(
    private router: Router,
    private api: ApiService,
    private bovinoService: BovinoService,
  ) { }

  ngOnInit(): void {
    this.tiposRegistros = this.bovinoService.getTiposRegistrosReservados('Selecione...');
  }

  botaoSalvar() {
    this.api.reservarRegistroAssociacao(this.formulario).then((result: any) => {
      if (result && result.success) {
        Swal.fire(result.message, '', 'success');
        this.router.navigate(['registros_reservados']);
      } else {
        Swal.fire(result.message || 'Não foi possível reservar o registro.', '', 'error');
      }
    });
  }

  filiadaSelecionada(idFiliada: string) {
    this.formulario.FiliadaId = idFiliada;
  }

}
