import { AccountService } from 'src/app/account/shared/account.service';
import { Commons } from 'src/app/shared/commons';
import { ApiService } from 'src/app/shared/api.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { BaseEventos } from 'src/app/eventos/base-eventos';
import { Sexo, TipoBuscaBovino, TipoPadreacao } from 'src/app/shared/enums/Enums';
import { ConstantesTipoEvento } from 'src/app/shared/constantes';
import { MatDialog } from '@angular/material/dialog';
import { ConsultaRapidaBovinoModel } from 'src/app/models/ConsultaRapidaBovinoModel';
import { environment } from 'src/environments/environment';
import { AssociacaoOcorrenciaEventoBovinoModel } from 'src/app/models/AssociacaoOcorrenciaEventoBovinoModel';
import { BovinoModel } from 'src/app/models/BovinoModel';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-manter-embriao',
  templateUrl: './manter-embriao.component.html',
  styleUrls: ['./manter-embriao.component.scss']
})
export class ManterEmbriaoComponent extends BaseEventos implements OnInit {

  readonly env = environment;

  isReadOnly: boolean = false;
  
  constructor(
    private activatedRoute: ActivatedRoute, 
    public accountService: AccountService,
    public api: ApiService,
    public datePipe: DatePipe,
    public commons: Commons,
    public router: Router,
    public dialog: MatDialog
  ) { 
    super(api, datePipe, commons, router, accountService, dialog);
    this.tipoEvento = ConstantesTipoEvento.EMBRIAO;
  }

  ngOnInit(): void {
    this.novoEvento();
    this.activatedRoute.paramMap.subscribe(params => {
      this.iniciarTela(params);      
    });
  }

  handleBovinoAtualChange(bovino: BovinoModel) {
    if (!bovino || !bovino.Id || bovino.Id === '-1' ) return;

    const hasBovino = this.ocorrenciaAtual.ListaBovinos.filter((item: AssociacaoOcorrenciaEventoBovinoModel) => item.BovinoId === bovino.Id);

    if (hasBovino && hasBovino.length > 0) {
      Swal.fire('O bovino selecionado já está na lista.', '', 'warning');
      this.resetBovinoAtual();
      return;
    }
    
    this.ocorrenciaAtual.ListaBovinos.push(<AssociacaoOcorrenciaEventoBovinoModel>{
      BovinoId: bovino.Id,
      Codigo: bovino.CodigoBovino,
      OcorrenciaId: this.ocorrenciaAtual.Id,
      Nome: bovino.NomeBovino,
      Proprietario: bovino.CodigoNomeProprietario,
    });

    this.resetBovinoAtual();
  }

  private resetBovinoAtual() {
    this.ocorrenciaAtual.Touro = new BovinoModel();
    this.ocorrenciaAtual.Touro.TituloConsulta = "Touro";
    this.ocorrenciaAtual.Touro.TipoBusca = TipoBuscaBovino.Macho;
    this.ocorrenciaAtual.Touro.SexoBovino = Sexo.Macho;
    this.ocorrenciaAtual.Touro.ConsideraInativos = false;
    this.ocorrenciaAtual.Touro.ConsultaAnimalTodosCriadores = true;
    this.ocorrenciaAtual.Touro.IdProprietarioConsulta = this.lote.Criador.Id; 
  }

  removerBovino(id: string): void {
    if (this.ocorrenciaAtual.ListaBovinos?.length === 0) return;

    this.ocorrenciaAtual.ListaBovinos = this.ocorrenciaAtual.ListaBovinos?.filter(
      (item: AssociacaoOcorrenciaEventoBovinoModel) => item.BovinoId !== id
    );
  }

  salvar(confirmacao: boolean = false) {
    if (this.lote.OcorrenciaNacionalizacaoEmbriaoAtual)
      this.lote.OcorrenciaNacionalizacaoEmbriaoAtual.ListaBovinos = [];
    super.salvar(confirmacao);
  }

  descTipoPadreacao(tipo: number)
  {
    let descricao = "";
    if(tipo == TipoPadreacao.InseminacaoArtifical){
      descricao = "Inseminação Artificial";
    }
    else if(tipo == TipoPadreacao.MontaNatural){
      descricao = "Monta Natural";
    }

    return descricao;
  }

  editar(evento: any) {
    super.editar(evento);
    this.ocorrenciaAtual.Touro = new ConsultaRapidaBovinoModel();
    this.ocorrenciaAtual.Touro.TituloConsulta = "Touro";
    this.ocorrenciaAtual.Touro.TipoBusca = TipoBuscaBovino.Macho;
    this.ocorrenciaAtual.Touro.SexoBovino = Sexo.Macho;
    this.ocorrenciaAtual.Touro.ConsideraInativos = false;
    this.ocorrenciaAtual.Touro.IdProprietarioConsulta = this.lote.Criador.Id;
    this.ocorrenciaAtual.Touro.ConsultaAnimalTodosCriadores = false;
  }

}
