<div class="card">
  <div class="card-body">

    <div class="row justify-content-end">
      <div *ngIf="permiteReservarParaAssociacao" class="col-lg-3 col-md-6 col-sm-6 col-xs-12" style="margin-bottom: 10px;">
        <button type="button" class="btn btn-sm btn-block btn-primary" (click)="botaoGerarParaAssociacao()">
          Gerar e Reservar Registros Para Associação
        </button>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12" style="margin-bottom: 10px;">
        <button type="button" class="btn btn-sm btn-block btn-primary" (click)="botaoGerarParaInspetor()">
          Reservar Registros Para Inspetores
        </button>
      </div>
    </div>

    <div class="row" style="margin-top: 25px;">
      <div class="col-lg-12 table-responsive">
        <table class="table table-hover table-header-custom">
          <thead>
            <tr>
              <th>Associação</th>
              <th class="text-center">PO Fêmea</th>
              <th class="text-center">PO Macho</th>
              <th class="text-center">PO Nacionalizado</th>
              <th class="text-center">PC</th>
              <th class="text-center">PA</th>
              <th class="text-center">CCG (G)</th>
              <th class="text-center">CCG (Y)</th>
              <th class="text-center" width="7%"></th>
            </tr>
          </thead>
          <tbody *ngIf="registrosPorAssociacao.length > 0">
            <tr *ngFor="let registro of registrosPorAssociacao">
              <td> {{ registro.ReservadoPara }} </td>
              <td class="text-center">
                <a type="button" (click)="visualizarPorAssociacao(registro.ReservadoParaId, TipoRegistro.POFemea)">
                  {{ registro.CountPOFemeas > 0? registro.CountPOFemeas : '' }}
                </a>
              </td>
              <td class="text-center">
                <a type="button" (click)="visualizarPorAssociacao(registro.ReservadoParaId, TipoRegistro.POMacho)">
                  {{ registro.CountPOMacho > 0? registro.CountPOMacho : '' }}
                </a>
              </td>
              <td class="text-center">
                <a type="button" (click)="visualizarPorAssociacao(registro.ReservadoParaId, TipoRegistro.PONacionalizado)">
                  {{ registro.CountPONacionalizado > 0? registro.CountPONacionalizado : '' }}
                </a>
              </td>
              <td class="text-center">
                <a type="button" (click)="visualizarPorAssociacao(registro.ReservadoParaId, TipoRegistro.PC)">
                  {{ registro.CountPC > 0? registro.CountPC : '' }}
                </a>
              </td>
              <td class="text-center">
                <a type="button" (click)="visualizarPorAssociacao(registro.ReservadoParaId, TipoRegistro.PA)">
                  {{ registro.CountPA > 0? registro.CountPA : '' }}
                </a>
              </td>
              <td class="text-center">
                <a type="button" (click)="visualizarPorAssociacao(registro.ReservadoParaId, TipoRegistro.CCG)">
                  {{ registro.CountCCG > 0? registro.CountCCG : '' }}
                </a>
              </td>
              <td class="text-center">
                <a type="button" (click)="visualizarPorAssociacao(registro.ReservadoParaId, TipoRegistro.CCG2)">
                  {{ registro.CountCCG2 > 0? registro.CountCCG2 : '' }}
                </a>
              </td>
              <td class="text-center" width="7%">
                <a 
                  type="button" 
                  matTooltip="Visualizar" 
                  [queryParams]="{ filiada: registro.ReservadoParaId }"
                  [routerLink]="['/registros_reservados_consultar']"
                >
                  <i class="action-icons fas fa-eye"></i>
                </a>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="registrosPorAssociacao.length === 0">
            <tr class="text-center">
              <td colspan="9">
                <strong>Sem itens para exibição.</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row" style="margin-top: 30px;">
      <div class="col-lg-12 table-responsive">
        <table class="table table-hover table-header-custom">
          <thead>
            <tr>
              <th>Inspetor</th>
              <th class="text-center">PO Fêmea</th>
              <th class="text-center">PO Macho</th>
              <th class="text-center">PC</th>
              <th class="text-center">PA</th>
              <th class="text-center">CCG (Y)</th>
            </tr>
          </thead>
          <tbody *ngIf="registrosPorInspetor.length > 0">
            <tr *ngFor="let registro of registrosPorInspetor">
              <td> {{ registro.ReservadoPara }} </td>
              <td class="text-center"> 
                <a type="button" (click)="visualizarPorInspetor(registro.ReservadoParaId, TipoRegistro.POFemea)">
                  {{ registro.CountPOFemeas > 0? registro.CountPOFemeas : '' }} 
                </a>
              </td>
              <td class="text-center">
                <a type="button" (click)="visualizarPorInspetor(registro.ReservadoParaId, TipoRegistro.POMacho)">
                  {{ registro.CountPOMacho > 0? registro.CountPOMacho : '' }} 
                </a>
              </td>
              <td class="text-center">
                <a type="button" (click)="visualizarPorInspetor(registro.ReservadoParaId, TipoRegistro.PC)">
                  {{ registro.CountPC > 0? registro.CountPC : '' }} 
                </a>
              </td>
              <td class="text-center">
                <a type="button" (click)="visualizarPorInspetor(registro.ReservadoParaId, TipoRegistro.PA)">
                  {{ registro.CountPA > 0? registro.CountPA : '' }} 
                </a>
              </td>
              <td class="text-center">
                <a type="button" (click)="visualizarPorInspetor(registro.ReservadoParaId, TipoRegistro.CCG2)">
                  {{ registro.CountCCG2 > 0? registro.CountCCG2 : '' }} 
                </a>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="registrosPorInspetor.length === 0">
            <tr class="text-center">
              <td colspan="8">
                <strong>Sem itens para exibição.</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</div>