import { VeterinarioModel } from 'src/app/models/VeterinarioModel';
import { Component, Input, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { ApiService } from '../api.service';
import { AccountService } from 'src/app/account/shared/account.service';
import { ConstantesTipoEvento } from '../constantes';

@Component({
  selector: 'app-consultar-veterinario',
  templateUrl: './consultar-veterinario.component.html',
  styleUrls: ['./consultar-veterinario.component.scss']
})
export class ConsultarVeterinarioComponent implements OnInit {

  @Input() readonly: boolean = false;
  @Input() veterinario: VeterinarioModel = new VeterinarioModel();
  @Input() isReadOnly: boolean = false;

  constructor(
    private api: ApiService,
    private accountService: AccountService
  ) { }

  ngOnInit(): void {
    if (this.accountService.isVeterinario &&
      this.veterinario.crmv === "") {
      const token: any = this.accountService.getPayloadToken();
      if (token && token.CRMV) {
        this.readonly = true;
        this.veterinario.crmv = token.CRMV;
        this.consultarPorCrmv();
      }
      if (this.isReadOnly) {
        this.readonly = true;
      }
    }
  }

  limpar() {
    this.veterinario.id = "";
    this.veterinario.nomeVeterinario = "";
    this.veterinario.crmv = "";

  }

  consultarPorCrmv() {
    this.api.consultarVeterinarioPorCrmv(this.veterinario).then((result: any) => {
      if (result) {
        if (result.Status == 0) {
          this.veterinario.id = result.Veterinario.id;
          this.veterinario.nomeVeterinario = result.Veterinario.nomeVeterinario;
          this.veterinario.crmv = result.Veterinario.crmv;

        } else {
          this.limpar();
          Swal.fire(result.Mensagem, '', 'error');
        }
      }
    })
  }

  // consultarPorNome(){
  //   if(this.veterinario.IdProprietarioConsulta === ''){
  //     Swal.fire("Por favor informe o criador.", '', 'error');
  //   }
  //   else{
  //     const dialogRef = this.dialog.open(
  //       ConsultarVeterinarioModalComponent, 
  //       {
  //         height: '90%', 
  //         width: '90%',
  //         autoFocus: false,
  //         data: { veterinario: this.veterinario }
  //       }
  //     );

  //     dialogRef.componentInstance.veterinarioSelecionado.subscribe((result : any) => {
  //       if(result){
  //         this.veterinario.Id = result.Id;
  //         this.veterinario.CodigoVeterinario = result.CodigoVeterinario;
  //         this.veterinario.NomeVeterinario = result.NomeVeterinario;
  //         this.veterinario.SexoVeterinario = result.SexoVeterinario;
  //         this.veterinario.CodigoNomeProprietario = result.ProprietarioVeterinario;
  //         this.veterinario.IdProprietario = result.IdProprietario;
  //       }
  //     });
  //   }
  // }

  isReadOnlyFunction() {
    if (this.isReadOnly) {
      return true;
    } else {
      return false;
    }
  }
}
