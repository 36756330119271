import { Sexo, TipoBuscaBovino, TipoFiv, TipoTransferencia } from "../shared/enums/Enums";
import { BovinoModel } from "./BovinoModel";
import { OcorrenciaEventoModel } from "./OcorrenciaEventoModel";
import { VeterinarioModel } from "./VeterinarioModel";

export class OcorrenciaEventoTeFivModel extends OcorrenciaEventoModel {
  Veterinario: VeterinarioModel;
  TipoFIV: TipoFiv;
  Doadora: BovinoModel;
  Touro: BovinoModel;
  Tipo: TipoTransferencia;
  DataColeta: string;
  DataCobertura: string;
  DataCongelamento: string;
  QuantidadeColetados: number;
  QuantidadeViaveis: number;
  QuantidadeCongelados: number;
  QuantidadeSubdivididos: number;

  isReadOnly: boolean;

  constructor() {
    super();

    this.Veterinario = new VeterinarioModel();
    this.TipoFIV = TipoFiv.None;
    this.Doadora = new BovinoModel();
    this.Touro = new BovinoModel();
    this.Tipo = TipoTransferencia.COLETA_COM_IMPLANTE_TOTAL;
    this.DataColeta = "";
    this.DataCobertura = "";
    this.DataCongelamento = "";
    this.QuantidadeColetados = 0;
    this.QuantidadeViaveis = 0;
    this.QuantidadeCongelados = 0;
    this.QuantidadeSubdivididos = 0;

    this.Doadora.TituloConsulta = "Doadora";
    this.Doadora.TipoBusca = TipoBuscaBovino.Matriz;
    this.Doadora.SexoBovino = Sexo.Fêmea;
    this.Doadora.ConsideraInativos = false;
    this.Doadora.ConsultaAnimalTodosCriadores = true;

    this.Touro.TituloConsulta = "Touro";
    this.Touro.TipoBusca = TipoBuscaBovino.Macho;
    this.Touro.SexoBovino = Sexo.Macho;
    this.Touro.ConsideraInativos = false;
    this.Touro.ConsultaAnimalTodosCriadores = true;

    this.isReadOnly = false;
  }
}