import { ECertificado } from "../shared/enums/Enums";
import { BovinoModel } from "./BovinoModel";

export class CertificadoModel{
    id: string;
    animal: BovinoModel;
    dataEmissao: string;
    certificado: ECertificado;
  
    constructor(){
        this.id = "";
        this.animal = new BovinoModel();
        this.dataEmissao = "";
        this.certificado = -1;      
    }
  }
  