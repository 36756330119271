export class FiltroConsultaControleLeiteiro {
	situacao: number;
	controleLeiteiroDe: string;
	controleLeiteiroAte: string;

	constructor() {
		this.situacao = 2;
		this.controleLeiteiroDe = "";
		this.controleLeiteiroAte = "";
	}
}
