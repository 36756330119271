export class UsuarioProcuradorModel {
  Id: string;
  Codigo: string;
  Readonly: boolean;
  NomeProcurador: string;

  constructor() {
    this.Id = "-1";
    this.Codigo = "";
    this.Readonly = false;
    this.NomeProcurador = "";
  }
}