<app-formulario-tabs [formulario]="formulario" [listaComunicacao]="lote?.ListaComunicacao"
  (tabSelecionada)="tabSelecionada($event)" (criadorSelecionado)="criadorSelecionado($event)"></app-formulario-tabs>

<div class="row">
  <div class="col-lg-12 col-sm-12">
    <div class="card card-principal-evento">
      <div class="card-body">
        <!--Ocorrencia Atual-->
        <div *ngIf="!isNewLote()">
          <div class="row">
            <div class="col-lg-12">
              <div class="card card-evento">
                <div class="card-body">
                  <div class="row card-title-evento">
                    <div class="col-6">
                      <h4 class="card-title">
                        <mat-icon svgIcon="fiv"></mat-icon> Dados da Transferência
                      </h4>
                    </div>
                    <div class="col-6 text-right">
                      <button *ngIf="ocorrenciaAtual.Id !== '-1'" type="button" class="btn btn-link btn-sm"
                        (click)="documentosDaOcorrencia()">
                        <i class="far fa-folder"></i> Documentos
                      </button>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-9 col-md-12 col-sm-12">
                      <app-consultar-criador [criador]="ocorrenciaAtual.Comprador"></app-consultar-criador>
                    </div>

                    <div class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
                      <label for="formulario">Afixo</label>
                      <input type="text" readonly="true" class="form-control form-control-sm" name="afixo"
                        [(ngModel)]="ocorrenciaAtual.Comprador.afixo" />
                    </div>
                  </div>

                  <div class="row">
                    <div class="form-group col-lg-2 col-md-4 col-sm-6 col-xs-12">
                      <label for="AnimalCadastrado">Animal Cadastrado?</label>
                      <select class="form-control form-control-sm" name="AnimalCadastrado"
                        [(ngModel)]="ocorrenciaAtual.AnimalCadastrado" [disabled]="isOcorrenciaReadOnly()">
                        <option value="1">Sim</option>
                        <option value="2">Não</option>
                      </select>
                    </div>

                    <div *ngIf="ocorrenciaAtual.AnimalCadastrado == 1" class="col-lg-10 col-md-8 col-sm-12 col-xs-12">
                      <app-consultar-bovino [bovino]="ocorrenciaAtual.Animal"
                        [ocorrenciaAtualSituacao]="ocorrenciaAtual.Situacao"></app-consultar-bovino>
                    </div>

                    <div *ngIf="ocorrenciaAtual.AnimalCadastrado == 2"
                      class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
                      <label for="formulario">*Código Animal</label>
                      <input type="text" class="form-control form-control-sm" name="CodigoAnimalNaoCadastrado"
                        [(ngModel)]="ocorrenciaAtual.CodigoAnimalNaoCadastrado" [disabled]="isOcorrenciaReadOnly()">
                    </div>

                    <div *ngIf="ocorrenciaAtual.AnimalCadastrado == 2"
                      class="form-group col-lg-7 col-md-4 col-sm-6 col-xs-12">
                      <label for="protocolo">*Nome Animal</label>
                      <input type="text" class="form-control form-control-sm" name="NomeAnimalNaoCadastrado"
                        [(ngModel)]="ocorrenciaAtual.NomeAnimalNaoCadastrado" [disabled]="isOcorrenciaReadOnly()">
                    </div>

                  </div>

                  <div class="row">

                    <div class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
                      <label for="DataVenda">*Data da Venda</label>
                      <div class="input-group input-group-sm">
                        <input class="form-control" [max]="maxDate" [matDatepicker]="DataVenda" name="DataVenda"
                          [(ngModel)]="ocorrenciaAtual.DataVenda" [disabled]="isOcorrenciaReadOnly()">
                        <div *ngIf="!isOcorrenciaReadOnly()" class="input-group-append">
                          <button class="btn btn-outline-secondary" type="button" (click)="DataVenda.open()"> <i
                              class="bi bi-calendar3"></i> </button>
                        </div>
                      </div>
                      <mat-datepicker [startAt]="ocorrenciaAtual.DataVenda" #DataVenda></mat-datepicker>
                    </div>

                    <div class="form-group col-lg-2 col-md-4 col-sm-6 col-xs-12">
                      <label for="Prenha">*Prenha</label>
                      <select class="form-control form-control-sm" name="Prenha" [(ngModel)]="ocorrenciaAtual.Prenha"
                        [disabled]="isOcorrenciaReadOnly()" (change)="prenhaAlterada()">
                        <option value="1">Sim</option>
                        <option value="2">Não</option>
                      </select>
                    </div>

                    <div *ngIf="env.contexto === 'wagyu'" class="form-group col-lg-2 col-md-4 col-sm-6 col-xs-12">
                      <label for="TransferenciaMotivo">*Motivo</label>
                      <select class="form-control form-control-sm" name="TransferenciaMotivo"
                        [(ngModel)]="ocorrenciaAtual.TransferenciaMotivo" [disabled]="isOcorrenciaReadOnly()">
                        <option value="-1">Selecione...</option>
                        <option value="1">Venda</option>
                        <option value="2">Troca</option>
                        <option value="3">Doação</option>
                        <option value="4">Cessão</option>
                      </select>
                    </div>

                    <div class="form-group col-lg-2 col-md-4 col-sm-6 col-xs-12">
                      <label for="TipoTransferenciaAnimal">*Tipo de Transferência</label>
                      <select class="form-control form-control-sm" name="TipoTransferenciaAnimal"
                        [(ngModel)]="ocorrenciaAtual.TipoTransferenciaAnimal" [disabled]="isOcorrenciaReadOnly()">
                        <option value="1">Temporária</option>
                        <option value="2">Definitiva</option>
                      </select>
                    </div>

                    <div *ngIf="ocorrenciaAtual.TipoTransferenciaAnimal == 1"
                      class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
                      <label for="DataLimite">*Data Limite</label>
                      <div class="input-group input-group-sm">
                        <input class="form-control" [matDatepicker]="DataLimite" name="DataLimite"
                          [(ngModel)]="ocorrenciaAtual.DataLimite" [disabled]="isOcorrenciaReadOnly()">
                        <div *ngIf="!isOcorrenciaReadOnly()" class="input-group-append">
                          <button class="btn btn-outline-secondary" type="button" (click)="DataLimite.open()"> <i
                              class="bi bi-calendar3"></i> </button>
                        </div>
                      </div>
                      <mat-datepicker [startAt]="ocorrenciaAtual.DataLimite" #DataLimite></mat-datepicker>
                    </div>

                    <div *ngIf="ocorrenciaAtual.TipoTransferenciaAnimal == 1"
                      class="form-group col-lg-2 col-md-4 col-sm-6 col-xs-12">
                      <label for="SituacaoAposDataLimite">*Situação Após Data Limite</label>
                      <select class="form-control form-control-sm" name="SituacaoAposDataLimite"
                        [(ngModel)]="ocorrenciaAtual.SituacaoAposDataLimite">
                        <option value="0">Selecione</option>
                        <option value="1">Efetiva a Transferência</option>
                        <option value="2">Permanece com o Proprietário Atual</option>
                      </select>
                    </div>

                  </div>

                  <mat-divider *ngIf="ocorrenciaAtual.SugestaoSelecionada" style="margin-top: 20px; margin-bottom: 20px;"></mat-divider>

                  <div *ngIf="ocorrenciaAtual.Prenha == 1" class="row"
                    [ngStyle]="{ 'margin-bottom': !sugestaoSelecionada ? '25px' : '0', 'margin-top': sugestaoSelecionada? '20px' : '0' }">
                    <div *ngIf="ocorrenciaAtual.SugestaoSelecionada" class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
                      <label for="DataOcorrencia">Data {{ descEventoSugestao }}</label>
                      <div class="input-group input-group-sm">
                        <input [disabled]="ocorrenciaAtual.SugestaoSelecionada" class="form-control" [max]="maxDate"
                          [matDatepicker]="DataEventoSugestao" name="DataEventoSugestao"
                          [(ngModel)]="ocorrenciaAtual.OcorrenciaEventoPadreacao.DataOcorrencia || ocorrenciaAtual.OcorrenciaEventoInovulacao.DataTransferencia" />
                        <div *ngIf="!isOcorrenciaReadOnly()" class="input-group-append">
                          <button class="btn btn-outline-secondary" type="button" (click)="DataEventoSugestao.open()">
                            <i class="bi bi-calendar3"></i>
                          </button>
                        </div>
                      </div>
                      <mat-datepicker [startAt]="ocorrenciaAtual.Data" #DataEventoSugestao></mat-datepicker>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                      <label></label>
                      <button [disabled]="isOcorrenciaReadOnly()" type="button" class="btn btn-sm btn-block btn-primary"
                        (click)="botaoConsultarLimparSugestao()">
                        {{ ocorrenciaAtual.SugestaoSelecionada? 'Limpar' : 'Buscar' }} {{ descEventoSugestao }}
                      </button>
                    </div>
                  </div>

                  <div *ngIf="ocorrenciaAtual.SugestaoSelecionada" class="row">
                    <div class="col-sm-12 col-xs-12 col-lg-12 col-md-12">
                      <app-consultar-bovino
                        [bovino]="['Inovulação', 'Implantação'].includes(descEventoSugestao) ? ocorrenciaAtual.OcorrenciaEventoInovulacao.Doadora : ocorrenciaAtual.OcorrenciaEventoPadreacao.Matriz">
                      </app-consultar-bovino>
                    </div>
                  </div>

                  <div *ngIf="ocorrenciaAtual.SugestaoSelecionada" class="row"> 
                    <div class="col-sm-12 col-xs-12 col-lg-12 col-md-12">
                      <div *ngIf="!inovulacao()">
                        <label>Touro(s)</label>
                        <textarea 
                          readonly
                          type="text" 
                          name="touros" 
                          class="form-control form-control-sm"
                          [(ngModel)]="ocorrenciaAtual.OcorrenciaEventoInovulacao.OcorrenciaEventoFiv.BovinosDaLista"
                          style="min-height: calc(1.5em + 0.5rem + 2px); height: 50px;"
                        >
                        </textarea>
                      </div>
                      <div *ngIf="inovulacao()">
                        <app-consultar-bovino
                          [bovino]="['Inovulação', 'Implantação'].includes(descEventoSugestao) ? ocorrenciaAtual.OcorrenciaEventoInovulacao.Reprodutor : ocorrenciaAtual.OcorrenciaEventoPadreacao.Reprodutor">
                        </app-consultar-bovino>
                      </div>
                      
                    </div>
                  </div>

                  <div *ngIf="ocorrenciaAtual.SugestaoSelecionada && ['Inovulação', 'Implantação'].includes(descEventoSugestao)"
                    class="row">
                    <div class="col-sm-12 col-xs-12 col-lg-12 col-md-12">
                      <app-consultar-bovino [bovino]="ocorrenciaAtual.OcorrenciaEventoInovulacao.Receptora">
                      </app-consultar-bovino>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <app-botoes-acoes-ocorrencia [lote]="lote" [idOcorrencia]="ocorrenciaAtual.Id"
                (acaoSelecionada)="acaoBotao($event)" tipoEvento="transferencia"></app-botoes-acoes-ocorrencia>
            </div>
          </div>

          <!--Lista de Ocorrencias-->
          <div>
            <div *ngIf="ocorrencias.length > 0" class="row" style="margin-top: 15px">
              <div class="col-lg-12 table-responsive">
                <table class="table table-hover table-header-custom">
                  <thead>
                    <tr>
                      <th>Animal</th>
                      <th>Comprador</th>
                      <th class="text-center">Tipo</th>
                      <th class="text-center">Data da Transferência</th>
                      <th class="text-center">Prenha</th>
                      <th *ngIf="env.contexto === 'wagyu'" class="text-center">Motivo</th>
                      <th class="text-center">Situação</th>
                      <th class="text-center" width="10%">Opções</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let ev of ocorrencias" [ngClass]="{
                        'ocorrencia-em-destaque': ev.Id === ocorrenciaAtual.Id
                      }">
                      <td>
                        {{
                        ev.AnimalCadastrado == 1
                        ? ev.Animal.CodigoNomeBovino
                        : ev.CodigoNomeAnimalNaoCadastrado
                        }}
                      </td>
                      <td>
                        {{
                        (ev.Comprador.CodigoCriador || '') + ' - ' + (ev.Comprador.NomeCriador || '')
                        }}
                      </td>
                      <td class="text-center">
                        {{ commons.getTipoTransferenciaAnimal(ev.TipoTransferenciaAnimal) }}
                      </td>

                      <td class="text-center">{{ ev.DataVenda | date: "dd/MM/yyyy" }}</td>
                      <td class="text-center">{{ commons.getPrenhez(ev.Prenha) }}</td>
                      <td *ngIf="env.contexto === 'wagyu'" class="text-center">{{
                        commons.getTransferenciaMotivo(ev.TransferenciaMotivo) }}</td>
                      <td class="text-center text-nowrap">
                        <div [innerHtml]="descSituacao(ev.Situacao)"></div>
                      </td>
                      <td class="text-center text-nowrap acoes-evento">
                        <i *ngIf="ev.ImputaMulta === 1" matTooltip="Multa Imputada" class="fas fa-dollar-sign"></i>
                        <i *ngIf="ev.SolicitarDna === 1" matTooltip="Será solicitado DNA no Nascimento"
                          class="fas fa-dna">
                        </i>
                        <i *ngIf="permiteEdicao(ev.Situacao)" class="fas fa-edit" (click)="editar(ev)"></i>
                        <i *ngIf="!permiteEdicao(ev.Situacao)" class="fas fa-eye" (click)="editar(ev)"></i>
                        <i *ngIf="lote.Situacao === 1" class="fas fa-trash-alt" (click)="deletar(ev)"></i>
                        <i *ngIf="ev.IndInconsitencia" class="fa fa-exclamation-triangle" style="cursor: pointer"
                          matTooltip="{{ ev.Inconsistencia }}"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>