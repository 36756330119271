<div class="card" style="margin-top: 1em">
  <div class="card-body">
    <div *ngIf="exibirFiltrosConsulta">
      <app-filtro-consulta-plantel
        [formConsulta]="formConsulta"
        (triggerConsulta)="botaoConsultar()"></app-filtro-consulta-plantel>

      <div class="row justify-content-end">
        <div *ngIf="count > 0" class="col-lg-2 col-md-4 col-sm-6">
          <button type="button" class="btn btn-sm btn-block btn-primary" (click)="botaoGerarRelatorio(1)">
            Gerar Excel
          </button>
        </div>
        <!-- <div *ngIf="count > 0" class="col-lg-2 col-md-4 col-sm-6">
            <button type="button" class="btn btn-sm btn-block btn-primary" (click)="botaoGerarRelatorio(2)">Gerar PDF</button>
          </div> -->
        <div class="col-lg-2 col-md-4 col-sm-6">
          <button type="button" class="btn btn-sm btn-block btn-primary" (click)="botaoConsultar()">Buscar</button>
        </div>
      </div>
    </div>

    <div *ngIf="bovinos.length > 0" class="row" style="margin-top: 15px">
      <div class="col-lg-12 table-responsive">
        <table class="table table-hover table-header-custom">
          <thead>
            <tr>
              <th class="text-center">Código</th>
              <th>Nome</th>
              <th class="text-center">Data de Nascimento</th>
              <th class="text-center">{{ env.contexto === 'jersey' ? 'Orelha Direita' : 'Orelha Esquerda' }}</th>
              <th class="text-center">Sexo</th>
              <th class="text-center">Tipo/Grau de Sangue</th>
              <th class="text-center">Raça</th>
              <th class="text-center">Certificado</th>
              <th class="text-center" width="10%"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let bov of bovinos | paginate : { itemsPerPage: pageSize, currentPage: page, totalItems: count }">
              <td class="text-center">{{ bov.CodigoBovino }}</td>
              <td>{{ bov.NomeBovino }}</td>
              <td class="text-center">{{ bov.DescDataNascimento }}</td>
              <td class="text-center">{{ bov.OrelhaDireita }}</td>
              <td class="text-center">{{ bov.Sexo }}</td>
              <td class="text-center">
                {{ bov.TipoGrauSangue }} - {{ bov.TipoGrauSangue === 'ZT' ? '0' : bov.GrauSangue }}
              </td>
              <td class="text-center">{{ bov.Raca }}</td>
              <td class="text-center">{{ bov.Certificado }}</td>
              <td class="text-center text-nowrap">
                <a [routerLink]="[]" (click)="botaoDetalhar(bov.Id, true)">
                  <i class="action-icons fa fa-eye"></i>
                </a>
                <a *ngIf="permiteEdicao" [routerLink]="[]" (click)="botaoDetalhar(bov.Id, false)">
                  <i class="action-icons fa fa-edit"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="col-md-12 text-center">
        <pagination-controls
          class="srg-paginacao"
          previousLabel="Anterior"
          nextLabel="Próximo"
          [responsive]="true"
          (pageChange)="handlePageChange($event)"
          [autoHide]="false">
        </pagination-controls>
      </div>
    </div>
  </div>
</div>
