<div class="row">

  <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
    <label for="formularioProtocolo">Celular</label>
    <select class="form-control form-control-sm" name="celular" disabled>
      <option value="celular" label="Celular"></option>
    </select>
  </div>

  <div class="form-group input-sem-label col-lg-1 col-md-2 col-sm-2 col-4">
    <input 
      type="text" 
      mask="(00)"
      name="celular_ddd" 
      placeholder="(DDD)" 
      [disabled]="readOnly"
      [(ngModel)]="celular.ddd"
      class="form-control form-control-sm " 
    >
  </div>

  <div class="form-group input-sem-label telefone-container col-lg-2 col-md-4 col-sm-4 col-8">
    <input 
      type="text" 
      mask="00000-0000"
      placeholder="(número)" 
      name="celular_numero" 
      [disabled]="readOnly"
      [(ngModel)]="celular.numero"
      class="form-control form-control-sm " 
    >
  </div>

  <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
    <label for="formularioProtocolo">Telefone</label>
    <select class="form-control form-control-sm" name="telefone" disabled>
      <option value="residencial" label="Residencial"></option>
    </select>
  </div>

  <div class="form-group input-sem-label col-lg-1 col-md-2 col-sm-2 col-4">
    <input 
      type="text" 
      mask="(00)"
      name="telefone_ddd" 
      [disabled]="readOnly"
      placeholder="(DDD)" 
      [(ngModel)]="residencial.ddd"
      class="form-control form-control-sm" 
    >
  </div>

  <div class="form-group input-sem-label telefone-container col-lg-2 col-md-4 col-sm-4 col-8">
    <input 
      type="text" 
      mask="0000-0000"
      placeholder="(número)" 
      [disabled]="readOnly"
      name="telefone_numero" 
      [(ngModel)]="residencial.numero"
      class="form-control form-control-sm" 
    >
  </div>
</div>