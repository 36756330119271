import { Component, OnInit, Input } from '@angular/core';
import { FiltroConsultaCertificadosPendentesModel } from 'src/app/models/FiltroConsultaCertificadosPendentesModel';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-filtro-consulta-certificacao-pendentes',
  templateUrl: './filtro-consulta-certificacao-pendentes.component.html',
  styleUrls: ['./filtro-consulta-certificacao-pendentes.component.scss']
})

export class FiltroConsultaCertificacaoPendentesComponent implements OnInit {

  readonly env = environment;
  @Input() formConsulta: FiltroConsultaCertificadosPendentesModel = new FiltroConsultaCertificadosPendentesModel();
  
  constructor(
  ) { }

  ngOnInit(): void {
  }

  filiadaSelecionada(idFiliada: string) {
    this.formConsulta.vinculacao = idFiliada;
  }

}
