import { Component, Input, OnInit } from '@angular/core';
import { EnumTipoEventoSRG, TipoFiv, TipoTransferencia } from 'src/app/shared/enums/Enums';
import { environment } from 'src/environments/environment';

interface Ocorrencia {
  Doadora: {
    CodigoBovino: string;
    NomeBovino: string;
  }
  TipoFIV: TipoFiv;
  Tipo: TipoTransferencia;
  BovinosDaLista: string;
  DataColeta: string;
  DataCobertura: string;
  QuantidadeCongelados: number;
  QuantidadeViaveis: number;
}

@Component({
  selector: 'app-inovulacao-embriao-selecionado',
  templateUrl: './inovulacao-embriao-selecionado.component.html',
  styleUrls: ['./inovulacao-embriao-selecionado.component.scss']
})
export class InovulacaoEmbriaoSelecionadoComponent implements OnInit {

  readonly env = environment;
  codigoNomeBovino: string = "";
  tiposTransferenciaFiv: { label: string, value:  number }[] = [];

  @Input() tipoEvento: EnumTipoEventoSRG = EnumTipoEventoSRG.FIV;
  @Input() embriao: Ocorrencia = <Ocorrencia>{
    BovinosDaLista: '',
    DataCobertura: '',
    DataColeta: '',
    Doadora: {
      CodigoBovino: '',
      NomeBovino: '',
    },
    QuantidadeCongelados: 0,
    QuantidadeViaveis: 0,
    Tipo: TipoTransferencia.COLETA_COM_CONGELAMENTO_TOTAL,
    TipoFIV: TipoFiv.FIV,
  };
  
  constructor() { }

  ngOnInit(): void {
    this.preencherTipos();

    this.codigoNomeBovino = `${this.embriao.Doadora.CodigoBovino || ''} - ${this.embriao.Doadora.NomeBovino || ''}`;
  }

  private preencherTipos() {
    this.tiposTransferenciaFiv = [];

    this.tiposTransferenciaFiv.push({
      value: TipoTransferencia.COLETA_COM_IMPLANTE_TOTAL, 
      label: `Coleta Com Implante Total ${(this.env as any).contexto === 'wagyu'? '(a fresco)' : ''}`
    });
    this.tiposTransferenciaFiv.push({
      value: TipoTransferencia.COLETA_COM_CONGELAMENTO_TOTAL, 
      label: 'Coleta Com Congelamento Total'
    });
    this.tiposTransferenciaFiv.push({
      value: TipoTransferencia.COLETA_COM_IMPLANTE_E_CONGELAMENTO, 
      label: 'Coleta Com Implante E Congelamento'
    });
  }

}
