import { AnimalCadastrado, EstacaoDeMonta, Sexo, TipoBuscaBovino, TipoPadreacao } from "../shared/enums/Enums";
import { ConsultaRapidaBovinoModel } from "./ConsultaRapidaBovinoModel";
import { OcorrenciaEventoModel } from "./OcorrenciaEventoModel";

export class OcorrenciaEventoPadreacaoModel extends OcorrenciaEventoModel {
  TipoPadreacao: TipoPadreacao;
  DataOcorrencia: string;
  MatrizCadastrada: AnimalCadastrado;
  Matriz: ConsultaRapidaBovinoModel;
  CodigoMatrizNaoCadastrada: string;
  NomeMatrizNaoCadastrada: string;
  CodigoNomeMatrizNaoCadastrada: string;
  ReprodutorCadastrado: AnimalCadastrado;
  Reprodutor: ConsultaRapidaBovinoModel;
  CodigoReprodutorNaoCadastrado: string;
  NomeReprodutorNaoCadastrado: string;
  CodigoNomeReprodutorNaoCadastrado: string;

  // wagyu
  EstacaoDeMonta: EstacaoDeMonta;
  InicioEstacaoDeMonta: string;
  FimEstacaoDeMonta: string;

  isPadreacaoReadonly: boolean;

  constructor() {
    super();

    this.TipoPadreacao = TipoPadreacao.InseminacaoArtifical;
    this.DataOcorrencia = "";
    this.MatrizCadastrada = AnimalCadastrado.Sim;
    this.Matriz = new ConsultaRapidaBovinoModel();
    this.CodigoMatrizNaoCadastrada = "";
    this.NomeMatrizNaoCadastrada = "";
    this.CodigoNomeMatrizNaoCadastrada = "";
    this.ReprodutorCadastrado = AnimalCadastrado.Sim;
    this.Reprodutor = new ConsultaRapidaBovinoModel();
    this.CodigoReprodutorNaoCadastrado = "";
    this.NomeReprodutorNaoCadastrado = "";
    this.CodigoNomeReprodutorNaoCadastrado = "";
    
    this.EstacaoDeMonta = EstacaoDeMonta.Nao; 
    this.InicioEstacaoDeMonta = '';
    this.FimEstacaoDeMonta = '';

    this.Matriz.TituloConsulta = "Matriz";
    this.Matriz.TipoBusca = TipoBuscaBovino.Matriz;
    this.Matriz.SexoBovino = Sexo.Fêmea;
    this.Matriz.ConsideraInativos = false;
    this.Matriz.ConsultaAnimalTodosCriadores = true;
    
    this.Reprodutor.TituloConsulta = "Reprodutor";
    this.Reprodutor.TipoBusca = TipoBuscaBovino.Reprodutor;
    this.Reprodutor.SexoBovino = Sexo.Macho;
    this.Reprodutor.ConsideraInativos = false;
    this.Reprodutor.ConsultaAnimalTodosCriadores = true;

    this.isPadreacaoReadonly = true;
  }
}