import { Platform } from "@angular/cdk/platform";
import { MatDateFormats, NativeDateAdapter } from "@angular/material/core";

export class MyDateAdapter extends NativeDateAdapter {
  constructor() {
    super('pt-br', new Platform(''));
    this.setLocale('pt-br');
  }

  private _to2digit = (n: number): string => ('00' + n).slice(-2);

  public format(date: Date, displayFormat: Object): string {     
    return displayFormat === 'input' || displayFormat === 'pickerTitle'
      ? this._to2digit(date.getDate()) + '/' + this._to2digit(date.getMonth()+1) + '/' + date.getFullYear()
      : date.toDateString();
  }

  public parse(value: any): Date | null {
    
    const [ day, month, year ] = ((): [number, number, number] => {
      
      // usuario informou a data sem barra
      // exemplo: 18022022, converter para 18/02/2022
      const regex = /^([0-9]{2})([0-9]{2})([0-9]{4})/;
      if (regex.test(value)) {
        const [ _, day, month, year ] = regex.exec(value) as any;
        if (
          (day >= 1 && day <= 31) &&
          (month >= 1 && month <= 12)
        )
          return [day, month, year];
      }

      // usuario informou a data com barra
      // exemplo: 18/02/2022
      if ((/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/.test(value))) 
        return value.split('/');

      return [-1, -1, -1];
    })();
    
    if (day != -1) {
      const date = new Date(+year, +month-1, +day);
      date.setHours(new Date().getHours());
  
      return date;
    }

    return null;
  }
}

export const MY_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: 'pickerTitle',
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  }
};