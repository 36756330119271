import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/shared/api.service';
import { SituacaoRegistroReservado, TipoRegistroReservado } from 'src/app/shared/enums/Enums';
import { IItemVisualizarRegistrosReservados, IRequestVisualizarRegistrosReservados, IResponseVisualizarRegistrosReservados } from 'src/app/shared/interfaces/Interfaces';
import Swal from 'sweetalert2';
import { BovinoService } from '../../shared/bovino.service';

@Component({
  selector: 'app-registros-reservados-visualizar',
  templateUrl: './registros-reservados-visualizar.component.html',
  styleUrls: ['./registros-reservados-visualizar.component.scss']
})
export class RegistrosReservadosVisualizarComponent implements OnInit {

  SituacaoRegistro = SituacaoRegistroReservado;

  nome: string = ''
  registros: IItemVisualizarRegistrosReservados[] = [];
  formulario: IRequestVisualizarRegistrosReservados = <IRequestVisualizarRegistrosReservados>{};

  constructor(
    private api: ApiService,
    private router: Router,
    public bovinoService: BovinoService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {

    const tipo = this.validarParametroTipo();
    if (tipo === -1) {
      Swal.fire('Não foi possível recuperar as informações.', '', 'error');
      this.router.navigate(['registros_reservados']);
    }
    
    const situacao = this.validarParametroSituacao();
    if (situacao === -1) {
      Swal.fire('Não foi possível recuperar as informações.', '', 'error');
      this.router.navigate(['registros_reservados']);
    }
    
    const reservadoPara = this.route.snapshot.queryParamMap.get('para');
    if (!reservadoPara) {
      Swal.fire('Não foi possível recuperar as informações.', '', 'error');
      this.router.navigate(['registros_reservados']);
    }

    this.formulario = {
      tipo,
      situacao,
      reservadoPara: reservadoPara || '-1',
    }
    
    this.consultar();
  }

  private consultar() {
    this.api.visualizarRegistrosReservados(this.formulario).then((result: IResponseVisualizarRegistrosReservados) => {
      if (result && result.success) {
        this.registros = result.Itens;
        this.nome = result.ReservadoPara;
      } else {
        this.registros = [];
        Swal.fire(result.message || 'Não foi possível recuperar os dados para visualizar os registros reservados.', '', 'error');
        this.router.navigate(['plantel_consultar']);
      }
    });
  }

  private validarParametroTipo(): number {
    const tipo = this.route.snapshot.queryParamMap.get('tipo');
    if (!tipo) return -1;

    const converted = Number(tipo);
    if (Number.isNaN(converted) || converted === -1) return -1;

    return converted in TipoRegistroReservado 
      ? converted
      : -1;
  }

  private validarParametroSituacao(): number {
    const situacao = this.route.snapshot.queryParamMap.get('situacao');
    if (!situacao) return -1;

    const converted = Number(situacao);
    if (Number.isNaN(converted)) return -1;

    return converted in SituacaoRegistroReservado 
      ? converted
      : -1;
  }

}
