import { EnderecoModel } from './EnderecoModel';
import { NumeroTelefonicoModel } from './NumeroTelefonicoModel';

export class ValidaCpfUsuario {
	cpf: string;
	grupo: string;
	vinculacao: string;

	constructor() {
		this.cpf = '';
		this.grupo = '-1';
		this.vinculacao = '';
	}
}

export class UsuarioModel {
	nome: string;
	email: string;
	codigo: string;
	usuario: string;
	situacao: number;
	usuarioId: string;
	criadorId: string;
	endereco: EnderecoModel;
	CRMV_CodInspetor: string;
	celular: NumeroTelefonicoModel;
	residencial: NumeroTelefonicoModel;
	grupo: string;
	tipoInspetor: number;
	codigoEspecializacaoInspetor: string;

	constructor() {
		this.nome = '';
		this.email = '';
		this.codigo = '';
		this.usuario = '';
		this.situacao = 1;
		this.usuarioId = '';
		this.criadorId = '';
		this.CRMV_CodInspetor = '';
		this.grupo = '';
		this.endereco = new EnderecoModel();
		this.celular = new NumeroTelefonicoModel();
		this.residencial = new NumeroTelefonicoModel();
		this.tipoInspetor = -1;
		this.codigoEspecializacaoInspetor = '';
	}
}
