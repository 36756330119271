import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './account/login/login.component';
import { AuthenticationComponent } from './layout/authentication/authentication.component';
import { HomeComponent } from './layout/home/home.component';
import { PrincipalComponent } from './principal/principal.component';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatTabsModule } from '@angular/material/tabs'
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { HttpClientModule } from '@angular/common/http';
import { httpInterceptorProviders } from './http-interceptors';
import { SpinnerComponent } from './layout/spinner/spinner.component';
import { SubmetidosComponent } from './principal/submetidos/submetidos.component';
import { ConsultarCriadorComponent } from './shared/consultar-criador/consultar-criador.component';
import { ConsultarBovinoComponent } from './shared/consultar-bovino/consultar-bovino.component';
import { ConsultarCriadorModalComponent } from './shared/consultar-criador-modal/consultar-criador-modal.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SelecionarFiliadasComponent } from './shared/selecionar-filiadas/selecionar-filiadas.component';
import { PadreacaoManterComponent } from './eventos/padreacao/padreacao-manter/padreacao-manter.component';
import { PadreacaoConsultarComponent } from './eventos/padreacao/padreacao-consultar/padreacao-consultar.component';
import { NascimentoConsultarComponent } from './eventos/nascimento/nascimento-consultar/nascimento-consultar.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { FiltroConsultaEventosComponent } from './shared/filtro-consulta-eventos/filtro-consulta-eventos.component';
import { ConsultarBovinoModalComponent } from './shared/consultar-bovino-modal/consultar-bovino-modal.component';
import { AutoFocusDirective } from './shared/directives/auto-focus.directive';
import { OpcoesRetornoConsultaOcorrenciaComponent } from './eventos/shared/opcoes-retorno-consulta-ocorrencia/opcoes-retorno-consulta-ocorrencia.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormularioTabsComponent } from './eventos/shared/formulario-tabs/formulario-tabs.component';
import { BotoesAcoesOcorrenciaComponent } from './eventos/shared/botoes-acoes-ocorrencia/botoes-acoes-ocorrencia.component';
import { ConfirmacaoModalComponent } from './eventos/shared/confirmacao-modal/confirmacao-modal.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { InovulacaoConsultarComponent } from './eventos/inovulacao/inovulacao-consultar/inovulacao-consultar.component';
import { InspecaoConsultarComponent } from './eventos/inspecao/inspecao-consultar/inspecao-consultar.component';
import { InspecaoRelatorioComponent } from './eventos/inspecao/inspecao-relatorio/inspecao-relatorio.component';
import { ComunicacoesModalComponent } from './eventos/shared/comunicacoes-modal/comunicacoes-modal.component';
import { DocumentosLoteModalComponent } from './eventos/shared/documentos-lote-modal/documentos-lote-modal.component';
import { DocumentosOcorrenciaModalComponent } from './eventos/shared/documentos-ocorrencia-modal/documentos-ocorrencia-modal.component';
import { CriadorConsultarComponent } from './criador/criador-consultar/criador-consultar.component';
import { FiltroConsultaCriadorComponent } from './criador/filtro-consulta-criador/filtro-consulta-criador.component';
import { TeFivConsultarComponent } from './eventos/te_fiv/te-fiv-consultar/te-fiv-consultar.component';
import { CriadorManterComponent } from './criador/criador-gerenciar/criador-manter/criador-manter.component';
import { BaixaConsultarComponent } from './eventos/baixa/baixa-consultar/baixa-consultar.component';
import { TransferenciaConsultarComponent } from './eventos/transferencia/transferencia-consultar/transferencia-consultar.component';
import { ClassificacaoLinearConsultarComponent } from './eventos/classificacao_linear/classificacao-linear-consultar/classificacao-linear-consultar.component';
import { UsuariosConsultarComponent } from './usuarios/usuarios-consultar/usuarios-consultar.component';
import { FiltroConsultaUsuarioComponent } from './usuarios/filtro-consulta-usuario/filtro-consulta-usuario.component';
import { ConsultarSemenComponent } from './eventos/nacionalizacao/semen/consultar-semen/consultar-semen.component';
import { FiltroConsultaNacionalizacaoComponent } from './eventos/nacionalizacao/filtro-consulta-nacionalizacao/filtro-consulta-nacionalizacao.component';
import { ConsultarEmbriaoComponent } from './eventos/nacionalizacao/embriao/consultar-embriao/consultar-embriao.component';
import { ExposicaoConsultarComponent } from './exposicao/exposicao-consultar/exposicao-consultar.component';
import { FiltroConsultaExposicaoComponent } from './exposicao/filtro-consulta-exposicao/filtro-consulta-exposicao.component';
import { CertificadosConsultarComponent } from './certificados/certificados-consultar/certificados-consultar.component';
import { FiltroConsultaCertificacaoComponent } from './certificados/filtro-consulta-certificacao/filtro-consulta-certificacao.component';
import { PlantelConsultarComponent } from './bovino/plantel/plantel-consultar/plantel-consultar.component';
import { FiltroConsultaPlantelComponent } from './shared/filtro-consulta-plantel/filtro-consulta-plantel.component';
import { BaixaManterComponent } from './eventos/baixa/baixa-manter/baixa-manter.component';
import { NascimentoManterComponent } from './eventos/nascimento/nascimento-manter/nascimento-manter.component';
import { ExposicaoManterComponent } from './exposicao/exposicao-manter/exposicao-manter.component';
import { ControleLeiteiroConsultarComponent } from './controle-leiteiro/controle-leiteiro-consultar/controle-leiteiro-consultar.component';
import { FiltroConsultaControleLeiteiroComponent } from './controle-leiteiro/filtro-consulta-controle-leiteiro/filtro-consulta-controle-leiteiro.component';
import { ControleLeiteiroManterComponent } from './controle-leiteiro/controle-leiteiro-manter/controle-leiteiro-manter.component';
import { ControleLeiteiroAnalisarComponent } from './controle-leiteiro/controle-leiteiro-analisar/controle-leiteiro-analisar.component';
import { TooltipObservacaoPipe } from './controle-leiteiro/pipe-tooltip-observacao/tooltip-observacao.pipe';
import { TeFivManterComponent } from './eventos/te_fiv/te-fiv-manter/te-fiv-manter.component';
import { AlterarSenhaComponent } from './account/alterar-senha/alterar-senha.component';
import { UsuariosManterValidaCpfComponent } from './usuarios/usuarios-manter-valida-cpf/usuarios-manter-valida-cpf.component';
import { UsuariosManterComponent } from './usuarios/usuarios-manter/usuarios-manter.component';
import { EnderecoManterComponent } from './shared/endereco-manter/endereco-manter.component';
import { ConsultarVeterinarioComponent } from './shared/consultar-veterinario/consultar-veterinario.component';
import { InovulacaoManterComponent } from './eventos/inovulacao/inovulacao-manter/inovulacao-manter.component';
import { BovinoDetalharComponent } from './bovino/bovino-detalhar/bovino-detalhar.component';
import { DadosBovinoComponent } from './bovino/bovino-detalhar/dados-bovino/dados-bovino.component';
import { GenealogiaBovinoComponent } from './bovino/bovino-detalhar/genealogia-bovino/genealogia-bovino.component';
import { EventosBovinoComponent } from './bovino/bovino-detalhar/eventos-bovino/eventos-bovino.component';
import { DocumentosBovinoComponent } from './bovino/bovino-detalhar/documentos-bovino/documentos-bovino.component';
import { CriasBovinoComponent } from './bovino/bovino-detalhar/crias-bovino/crias-bovino.component';
import { ControleLeiteiroBovinoComponent } from './bovino/bovino-detalhar/controle-leiteiro-bovino/controle-leiteiro-bovino.component';
import { ClassificacaoBovinoComponent } from './bovino/bovino-detalhar/classificacao-bovino/classificacao-bovino.component';
import { PremiacaoBovinoComponent } from './bovino/bovino-detalhar/premiacao-bovino/premiacao-bovino.component';
import { TransferenciaManterComponent } from './eventos/transferencia/transferencia-manter/transferencia-manter.component';
import { CriadorValidarComponent } from './criador/criador-gerenciar/criador-validar/criador-validar.component';
import { ManterEmbriaoComponent } from './eventos/nacionalizacao/embriao/manter-embriao/manter-embriao.component';
import { TelefonesManterComponent } from './shared/telefones-manter/telefones-manter.component';
import { PerfilComponent } from './usuarios/perfil/perfil.component';
import { CriadorGerenciarComponent } from './criador/criador-gerenciar/criador-gerenciar.component';
import { BovinoManterComponent } from './bovino/bovino-manter/bovino-manter.component';
import { ClassificacaoLinearManterComponent } from './eventos/classificacao_linear/classificacao-linear-manter/classificacao-linear-manter.component';
import { ConsultarInspetorComponent } from './shared/consultar-inspetor/consultar-inspetor.component';
import { ConsultarInspetorModalComponent } from './shared/consultar-inspetor-modal/consultar-inspetor-modal.component';
import { ProcuradorComponent } from './procurador/procurador.component';
import { FazendaManterComponent } from './shared/fazenda-manter/fazenda-manter.component';
import { AfixoManterComponent } from './shared/afixo-manter/afixo-manter.component';
import { CertificadoConsultarPendentesComponent } from './certificados/certificado-consultar-pendentes/certificado-consultar-pendentes.component';
import { FiltroConsultaCertificacaoPendentesComponent } from './certificados/filtro-consulta-certificacao-pendentes/filtro-consulta-certificacao-pendentes.component';
import { CertificadosManterComponent } from './certificados/certificados-manter/certificados-manter.component';
import { CertificadoAssinarComponent } from './certificados/certificado-assinar/certificado-assinar.component';
import { ManterSemenComponent } from './eventos/nacionalizacao/semen/manter-semen/manter-semen.component';
import { InspecaoManterComponent } from './eventos/inspecao/inspecao-manter/inspecao-manter.component';
import { ItemAscendenciaComponent } from './eventos/nacionalizacao/semen/manter-semen/item-ascendencia/item-ascendencia.component';
import { ConsultarTecnicoComponent } from './shared/consultar-tecnico/consultar-tecnico.component';
import { ConsultarTecnicoModalComponent } from './shared/consultar-tecnico-modal/consultar-tecnico-modal.component';
import { CadastroAnimalAscendenciaComponent } from './eventos/nacionalizacao/semen/manter-semen/cadastro-animal-ascendencia/cadastro-animal-ascendencia.component';
import { RegistrosReservadosComponent } from './bovino/registros-reservados/registros-reservados.component';
import { RegistrosReservadosVisualizarComponent } from './bovino/registros-reservados/registros-reservados-visualizar/registros-reservados-visualizar.component';
import { RegistrosReservadosConsultarComponent } from './bovino/registros-reservados/registros-reservados-consultar/registros-reservados-consultar.component';
import { ManterRegistroAssociacaoComponent } from './bovino/registros-reservados/manter-registro-associacao/manter-registro-associacao.component';
import { ManterRegistroInspetorComponent } from './bovino/registros-reservados/manter-registro-inspetor/manter-registro-inspetor.component';
import { PesagemManterComponent } from './eventos/pesagem/pesagem-manter/pesagem-manter.component';
import { PesagemConsultarComponent } from './eventos/pesagem/pesagem-consultar/pesagem-consultar.component';
import { LaudoDnaManterComponent } from './laudo-dna/laudo-dna-manter/laudo-dna-manter.component';
import { LaudoDnaConsultarComponent } from './laudo-dna/laudo-dna-consultar/laudo-dna-consultar.component';
import { ConsultarLaudoDnaComponent } from './shared/consultar-laudo-dna/consultar-laudo-dna.component';
import { ConsultarLaudoDnaModalComponent } from './shared/consultar-laudo-dna-modal/consultar-laudo-dna-modal.component';
import { FiltroConsultaLaudoDnaComponent } from './laudo-dna/filtro-consulta-laudo-dna/filtro-consulta-laudo-dna.component';
import { ConsultarSugestaoNascimentoModalComponent } from './shared/consultar-sugestao-nascimento-modal/consultar-sugestao-nascimento-modal.component';
import { RouterModule } from '@angular/router';
import { MyDateAdapter, MY_DATE_FORMATS } from './shared/adapters/date-adapter';
import { ConsultarSugestaoFivModalComponent } from './shared/consultar-sugestao-fiv-modal/consultar-sugestao-fiv-modal.component';
import { InovulacaoEmbriaoSelecionadoComponent } from './eventos/inovulacao/inovulacao-embriao-selecionado/inovulacao-embriao-selecionado.component';
import { OcorrenciasPorSituacaoModalComponent } from './principal/ocorrencias-por-situacao-modal/ocorrencias-por-situacao-modal.component';
import { CarcacaConsultarComponent } from './eventos/controle_carcaca/carcaca-consultar/carcaca-consultar.component';
import { CarcacaManterComponent } from './eventos/controle_carcaca/carcaca-manter/carcaca-manter.component';
import { CarcacaManterModalComponent } from './shared/carcaca-manter-modal/carcaca-manter-modal/carcaca-manter-modal.component';
import { ApropriarTecnicoComponent } from './eventos/inspecao/apropriar-tecnico/apropriar-tecnico.component';
import { RelacaoAposAbateModalComponent } from './eventos/controle_carcaca/relacao-apos-abate-modal/relacao-apos-abate-modal.component';
import { FiltroConsultaPlantelInspecaoComponent } from './shared/filtro-consulta-plantel-inspecao/filtro-consulta-plantel-inspecao.component';
import { ApropriarVeterinarioComponent } from './eventos/te_fiv/apropriar-veterinario/apropriar-veterinario.component';
import { ApropriarProcuradorComponent } from './criador/criador-gerenciar/apropriar-procurador/apropriar-procurador.component';
import { ConsultarProcuradorComponent } from './shared/consultar-procurador/consultar-procurador.component';
import { ConsultarProcuradorModalComponent } from './shared/consultar-procurador-modal/consultar-procurador-modal';
import { StoreModule } from '@ngrx/store';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { CarcacaManterPosComponent } from './eventos/controle_carcaca/carcaca-manter-pos/carcaca-manter-pos.component';
import { ListaAnimaisModalComponent } from './eventos/controle_carcaca/lista-animais-modal/lista-animais-modal.component';
import { QuestionarioModalComponent } from './shared/questionario-modal/questionario-modal.component';
import { MatCheckboxModule } from '@angular/material/checkbox';


const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    AuthenticationComponent,
    PrincipalComponent,
    SpinnerComponent,
    SubmetidosComponent,
    ConsultarCriadorComponent,
    ConsultarBovinoComponent,
    ConsultarVeterinarioComponent,
    ConsultarCriadorModalComponent,
    SelecionarFiliadasComponent,
    PadreacaoManterComponent,
    PadreacaoConsultarComponent,
    NascimentoConsultarComponent,
    FiltroConsultaEventosComponent,
    ConsultarBovinoModalComponent,
    AutoFocusDirective,
    OpcoesRetornoConsultaOcorrenciaComponent,
    FormularioTabsComponent,
    BotoesAcoesOcorrenciaComponent,
    ConfirmacaoModalComponent,
    InovulacaoConsultarComponent,
    InspecaoConsultarComponent,
    ComunicacoesModalComponent,
    DocumentosLoteModalComponent,
    DocumentosOcorrenciaModalComponent,
    CriadorConsultarComponent,
    FiltroConsultaCriadorComponent,
    TeFivConsultarComponent,
    CriadorManterComponent,
    BaixaConsultarComponent,
    TransferenciaConsultarComponent,
    ClassificacaoLinearConsultarComponent,
    UsuariosConsultarComponent,
    FiltroConsultaUsuarioComponent,
    ConsultarSemenComponent,
    FiltroConsultaNacionalizacaoComponent,
    ConsultarEmbriaoComponent,
    ExposicaoConsultarComponent,
    FiltroConsultaExposicaoComponent,
    PlantelConsultarComponent,
    FiltroConsultaPlantelComponent,
    FiltroConsultaCertificacaoComponent,
    PlantelConsultarComponent,
    CertificadosConsultarComponent,
    BaixaManterComponent,
    NascimentoManterComponent,
    ExposicaoManterComponent,
    ControleLeiteiroConsultarComponent,
    FiltroConsultaControleLeiteiroComponent,
    ControleLeiteiroManterComponent,
    ControleLeiteiroAnalisarComponent,
    TooltipObservacaoPipe,
    TeFivManterComponent,
    AlterarSenhaComponent,

    UsuariosManterValidaCpfComponent,
    UsuariosManterComponent,
    EnderecoManterComponent,
    InovulacaoManterComponent,
    BovinoDetalharComponent,
    DadosBovinoComponent,
    GenealogiaBovinoComponent,
    EventosBovinoComponent,
    DocumentosBovinoComponent,
    CriasBovinoComponent,
    ControleLeiteiroBovinoComponent,
    ClassificacaoBovinoComponent,
    PremiacaoBovinoComponent,
    TransferenciaManterComponent,
    CriadorValidarComponent,
    TelefonesManterComponent,
    CriadorGerenciarComponent,
    ManterEmbriaoComponent,
    TelefonesManterComponent,
    PerfilComponent,
    BovinoManterComponent,
    ClassificacaoLinearManterComponent,
    ConsultarInspetorComponent,
    ConsultarInspetorModalComponent,
    ProcuradorComponent,
    FazendaManterComponent,
    AfixoManterComponent,
    CertificadoConsultarPendentesComponent,
    FiltroConsultaCertificacaoPendentesComponent,
    CertificadosManterComponent,
    CertificadoAssinarComponent,
    ManterSemenComponent,
    InspecaoManterComponent,
    ItemAscendenciaComponent,
    ConsultarTecnicoComponent,
    ConsultarTecnicoModalComponent,
    CadastroAnimalAscendenciaComponent,
    RegistrosReservadosComponent,
    RegistrosReservadosVisualizarComponent,
    RegistrosReservadosConsultarComponent,
    ManterRegistroAssociacaoComponent,
    ManterRegistroInspetorComponent,
    PesagemManterComponent,
    PesagemConsultarComponent,
    LaudoDnaManterComponent,
    LaudoDnaConsultarComponent,
    ConsultarLaudoDnaComponent,
    ConsultarLaudoDnaModalComponent,
    FiltroConsultaLaudoDnaComponent,
    ConsultarSugestaoNascimentoModalComponent,
    ConsultarSugestaoFivModalComponent,
    InovulacaoEmbriaoSelecionadoComponent,
    OcorrenciasPorSituacaoModalComponent,
    CarcacaConsultarComponent,
    CarcacaManterComponent,
    CarcacaManterModalComponent,
    ApropriarTecnicoComponent,
    RelacaoAposAbateModalComponent,
    InspecaoRelatorioComponent,
    FiltroConsultaPlantelInspecaoComponent,
    ApropriarVeterinarioComponent,
    ApropriarProcuradorComponent,
    ConsultarProcuradorComponent,
    ConsultarProcuradorModalComponent,
    CarcacaManterPosComponent,
    ListaAnimaisModalComponent,
    QuestionarioModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatTabsModule,
    MatListModule,
    MatCardModule,
    MatChipsModule,
    MatTableModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    HttpClientModule,
    NgxPaginationModule,
    MatAutocompleteModule,
    NgxMaskModule.forRoot(maskConfig),
    RouterModule,
    StoreModule.forRoot({}, {}),
    RouterModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule,
    NgxMaskModule.forRoot(maskConfig),
    MatCheckboxModule
  ],
  providers: [
    httpInterceptorProviders,
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    { provide: DateAdapter, useClass: MyDateAdapter },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    DatePipe
  ],
  bootstrap: [AppComponent],
  entryComponents: []
})
export class AppModule { }
