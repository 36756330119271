import { Frigorifico, MotivoReprovacaoAbate, TipoCertificadoBaixa } from "../shared/enums/Enums";
import { CriadorModel } from "./CriadorModel";
import { InspetorModel } from "./InspetorModel";
import { OcorrenciaEventoModel } from "./OcorrenciaEventoModel";


export class OcorrenciaEventoAbateModel extends OcorrenciaEventoModel {

  PesoVivo: number | string;
  PesoCarcaca: number | string;
  Gordura: number | string;
  NroDentes: number | string;
  SituacaoAbate: TipoCertificadoBaixa;
  MotivoReprovacaoAbate: MotivoReprovacaoAbate;
  Inspetor: InspetorModel;
	Frigorifico: CriadorModel;

  constructor () {
    super()

    this.PesoCarcaca = '';
    this.PesoVivo = '';
    this.Gordura = '';
    this.NroDentes = '';
    this.SituacaoAbate = TipoCertificadoBaixa.None;
    this.MotivoReprovacaoAbate = MotivoReprovacaoAbate.None;

    this.Inspetor = new InspetorModel();
		this.Frigorifico = new CriadorModel();

		this.Frigorifico.frigorifico = Frigorifico.Sim;
		this.Frigorifico.tituloConsulta = 'Frigorífico';
  }
}