import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CriadorModel, ValidaCriadorModel } from 'src/app/models/CriadorModel';
import { FazendaModel } from 'src/app/models/FazendaModel';
import { ApiService } from 'src/app/shared/api.service';
import { Commons } from 'src/app/shared/commons';
import { IResponseConsultaCriadorId } from 'src/app/shared/interfaces/Interfaces';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-criador-gerenciar',
  templateUrl: './criador-gerenciar.component.html',
  styleUrls: ['./criador-gerenciar.component.scss']
})
export class CriadorGerenciarComponent implements OnInit {

  readonly env = environment;

  id: string | null = null;
  readOnly: boolean = false;

  manterCriadorValido: boolean = false;

  criador: CriadorModel = new CriadorModel();
  formValidaCriador: ValidaCriadorModel = new ValidaCriadorModel();

  constructor(
    private commons: Commons,
    private api: ApiService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.queryParamMap.get('id');

    const readonly: (string | null) = this.route.snapshot.queryParamMap.get('readOnly');
    this.readOnly = (readonly !== null && readonly === 'true');

    if (this.id) this.recuperarPorId();
  }

  recuperarPorId(): void {
    if (!this.id) {
      this.manterCriadorValido = false;
      return;
    }

    this.api.recuperarCriadorPorId(this.id).then((result: IResponseConsultaCriadorId) => {
      if (result && result.success) {
        this.manterCriadorValido = true;

        this.criador.id = result.Id;
        this.criador.usuarioId = result.UsuarioId;
        this.criador.email = result.Email.Email;
        this.criador.codigo = result.Codigo;
        this.criador.socio = result.Socio;
        this.criador.racas = result.Racas;
        this.criador.suspenso = result.Suspenso;
        this.criador.frigorifico = result.Frigorifico;
        this.criador.tipoServicoInspecao = result.TipoServicoInspecaoFrigorifico;
        this.criador.numeroServicoInspecao = result.NumeroServicoInspecaoFrigorifico;
        this.criador.situacao = result.Situacao;
        this.criador.inscricaoEstadual = result.InscricaoEstadual;
        this.criador.dataCadastro = result.DataInicioVigencia;
        this.criador.associacaoEntidadeId = result.AssociacaoEntidadeId;
        this.formValidaCriador.cpf_cnpj = result.CPF || result.CNPJ;
        this.formValidaCriador.grupo = result.GrupoUsuario.Nome;
        this.formValidaCriador.vinculacao = result.FiliadaId;
        this.formValidaCriador.tipoPessoa = result.TipoUsuario;
        this.criador.usuario = result.Login;
        this.criador.nome = result.Nome || result.RazaoSocial;
        this.criador.afixos = result.AfixosCriador;
        this.criador.posicaoAfixo = result.PosicaoAfixo;
        this.criador.emailSecundario = result.EmailSecundario.Email;
        this.criador.observacao = result.Observacao;
        if ((this.env as any).contexto === 'jersey') {
          this.criador.afixo = result.Afixo;
        }
        // else {
        //   const afixoPrincipal = result.AfixosCriador?.filter(item => item.principal);
        //   if (afixoPrincipal.length === 1) {
        //     this.criador.idAfixo = afixoPrincipal[0].Id || "-1";
        //     this.criador.afixo = afixoPrincipal[0].codigo;
        //     this.criador.posicaoAfixo = afixoPrincipal[0].posicaoAfixo;
        //   }
        // }

        this.criador.fazendas = result.Fazendas?.map(item => {
          const fazenda = new FazendaModel();
          fazenda.Nome = item.Nome;
          fazenda.Id = item.Id;
          this.commons.converterEndereco(fazenda.Endereco, item.Endereco);
          return fazenda;
        });

        this.commons.converterTelefone(this.criador.celular, result.Celular);
        this.commons.converterTelefone(this.criador.residencial, result.Telefone);
        this.commons.converterEndereco(this.criador.endereco, result.Endereco);

      } else {
        this.manterCriadorValido = false;
      }
    });
  }

}
