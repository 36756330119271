<div class="row">
  
  <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
    <label>Tipo</label>
    <select class="form-control form-control-sm" name="tipo_evento" [(ngModel)]="formConsulta.tipo">
      <option *ngFor="let tipo of tiposEvento" [value]="tipo.value" [label]="tipo.label"></option>
    </select>
  </div>

  <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
    <label>Situação</label>
    <select class="form-control form-control-sm" name="situacoes" [(ngModel)]="formConsulta.situacao">
      <option *ngFor="let situacao of situacoes" [value]="situacao.value" [label]="situacao.label"></option>
    </select>
  </div>

  <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
    <label>De:</label>
    <div class="input-group input-group-sm">
      <input class="form-control" name="evento_de" [matDatepicker]="eventoDe" [max]="maxDate" [(ngModel)]="formConsulta.eventoDe"> 
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" type="button" (click)="eventoDe.open()"> <i class="bi bi-calendar3"></i> </button>
      </div>
    </div>
    <mat-datepicker [startAt]="formConsulta.eventoDe" #eventoDe></mat-datepicker>
  </div>

  <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
    <label>Até:</label>
    <div class="input-group input-group-sm">
      <input class="form-control" name="evento_ate" [matDatepicker]="eventoAte" [max]="maxDate" [(ngModel)]="formConsulta.eventoAte"> 
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" type="button" (click)="eventoAte.open()"> <i class="bi bi-calendar3"></i> </button>
      </div>
    </div>
    <mat-datepicker [startAt]="formConsulta.eventoAte" #eventoAte></mat-datepicker>
  </div>
    
</div>


<div class="row justify-content-end">
  <div class="col-lg-3 col-md-6 col-sm-6">
    <button type="button" class="btn btn-sm btn-block btn-primary" (click)="botaoConsultar(true)">Buscar</button>
  </div>
</div>

<div class="row" style="margin-top: 15px;">
  <div class="col-lg-12 table-responsive">
    <table class="table table-hover table-header-custom">
      <thead>
        <tr>
          <th width="5%"></th>
          <th width="8%" class="text-center">Tipo</th>
          <th width="8%" class="text-center">Data</th>
          <th class="text-center" style="max-width: 35%;">Descrição</th>
          <th class="text-center" style="max-width: 34%;">Observação</th>
          <th width="10%" class="text-center">Situação</th>
        </tr>
      </thead>
      <tbody *ngIf="eventosBovino.length > 0">
        <tr style="height: 100px;" *ngFor="let evento of eventosBovino | paginate : {itemsPerPage: pageSize, currentPage: page, totalItems: count};">
          <td class="text-center align-middle">
            <mat-icon *ngIf="evento.Icone.tipo === 'svg'" [svgIcon]="evento.Icone.icone"></mat-icon>
            <mat-icon *ngIf="evento.Icone.tipo === 'material'" aria-hidden="false" [attr.aria-label]="evento.Icone.nome">
              {{ evento.Icone.icone }}
            </mat-icon>
          </td>
          <td class="text-center align-middle">{{ evento.Icone.nome }}</td>
          <td class="text-center align-middle">{{ evento.DescDataOcorrencia }}</td>
          <td class="align-middle">{{ evento.Descricao }}</td>
          <td class="align-middle" [ngClass]="{'text-center': !evento.Observacao}">{{ evento.Observacao? evento.Observacao : '-' }}</td>
          <td class="text-center align-middle">{{ commons.getDescricaoSituacao(evento.Situacao) }}</td>
        </tr>
      </tbody>
      <tbody *ngIf="eventosBovino.length === 0">
        <tr class="text-center">
          <td colspan="6">
            <strong>Sem itens para exibição.</strong>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="col-md-12 text-center" *ngIf="eventosBovino.length > 0">
    <pagination-controls  
      [autoHide]="true"
      [responsive]="true"
      nextLabel="Próximo" 
      class="srg-paginacao" 
      previousLabel="Anterior" 
      (pageChange)="handlePageChange($event)" 
    >
    </pagination-controls>
  </div>
  
</div>