import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from '../../shared/api.service';
import { PerfilModel } from '../../models/PerfilModel';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { FazendaModel } from 'src/app/models/FazendaModel';
import { Commons } from 'src/app/shared/commons';
import { CriadorModel } from 'src/app/models/CriadorModel';
import { MatDialog } from '@angular/material/dialog';
import { QuestionarioModalComponent } from 'src/app/shared/questionario-modal/questionario-modal.component';
import { AccountService } from 'src/app/account/shared/account.service';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit {

  readonly env = environment;

  perfilModel: PerfilModel = new PerfilModel();
  id: string = "";
  associadoId: string = "GR_SRG_ASSOCIADO";
  criadorModel: CriadorModel = new CriadorModel();

  constructor(
    private commons: Commons,
    private apiService: ApiService,
    public dialog: MatDialog,
    private accountService: AccountService
  ) { }

  ngOnInit(): void {
    this.apiService.recuperaPerfil().then(response => {
      this.perfilModel.Afixo = response.Afixo;
      this.perfilModel.Login = response.Login;
      this.perfilModel.CNPJ = response.CNPJ;
      this.perfilModel.TipoUsuario = response.TipoUsuario;
      this.perfilModel.CodigoAcesso = response.CodigoAcesso;
      this.perfilModel.FiliadaId = response.FiliadaId;

      Object.assign(this.perfilModel.GrupoUsuario, response.GrupoUsuario);
      this.perfilModel.CPF.cpf = response.CPF;
      this.perfilModel.TipoEmail = response.Email.Tipo;
      this.perfilModel.Usuario.email = response.Email.Email;

      this.perfilModel.Usuario.usuario = response.UsuarioId;
      this.perfilModel.Usuario.codigo = response.Codigo;
      this.perfilModel.Usuario.situacao = response.Situacao;
      this.perfilModel.Usuario.nome = response.Nome;
      this.perfilModel.Usuario.endereco = response.Endereco;
      this.id = response.Id;
      this.commons.converterEndereco(this.perfilModel.Usuario.endereco, response.Endereco);
      this.commons.converterTelefone(this.perfilModel.Usuario.residencial, response.Telefone);
      this.commons.converterTelefone(this.perfilModel.Usuario.celular, response.Celular);

      this.perfilModel.Fazendas = response.Fazendas?.map((item: any) => {
        const fazenda = new FazendaModel();
        fazenda.Nome = item.Nome;
        fazenda.Id = item.Id;
        this.commons.converterEndereco(fazenda.Endereco, item.Endereco);
        return fazenda;
      });
    });
  }

  preencheCampos() {


  }

  botaoAlterar() {
    this.apiService.alterarPerfil(this.perfilModel).then((result: any) => {
      if (result) {
        Swal.fire(result.message, '', result.success ? 'success' : 'error');
      }
    });
  }

  adicionarMaisFazenda(): void {
    this.perfilModel.Fazendas.push(new FazendaModel());
  }

  removerFazenda(index_fazenda: number): void {
    this.perfilModel.Fazendas = this.perfilModel.Fazendas.filter((item, index) => index !== index_fazenda && item);
  }


}
