import { PadreacaoConsultarComponent } from './eventos/padreacao/padreacao-consultar/padreacao-consultar.component';
import { PadreacaoManterComponent } from './eventos/padreacao/padreacao-manter/padreacao-manter.component';
import { NascimentoConsultarComponent } from './eventos/nascimento/nascimento-consultar/nascimento-consultar.component';
import { NascimentoManterComponent } from './eventos/nascimento/nascimento-manter/nascimento-manter.component';
import { InovulacaoConsultarComponent } from './eventos/inovulacao/inovulacao-consultar/inovulacao-consultar.component';
import { TeFivConsultarComponent } from './eventos/te_fiv/te-fiv-consultar/te-fiv-consultar.component';
import { TransferenciaConsultarComponent } from './eventos/transferencia/transferencia-consultar/transferencia-consultar.component';
import { ClassificacaoLinearConsultarComponent } from './eventos/classificacao_linear/classificacao-linear-consultar/classificacao-linear-consultar.component';
import { ConsultarSemenComponent } from './eventos/nacionalizacao/semen/consultar-semen/consultar-semen.component';
import { ConsultarEmbriaoComponent } from './eventos/nacionalizacao/embriao/consultar-embriao/consultar-embriao.component';
import { CertificadosConsultarComponent } from './certificados/certificados-consultar/certificados-consultar.component';
import { CertificadosManterComponent } from './certificados/certificados-manter/certificados-manter.component';
import { CertificadoConsultarPendentesComponent } from './certificados/certificado-consultar-pendentes/certificado-consultar-pendentes.component';
import { BaixaManterComponent } from './eventos/baixa/baixa-manter/baixa-manter.component'
import { PesagemManterComponent } from './eventos/pesagem/pesagem-manter/pesagem-manter.component';

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './account/login/login.component';
import { AuthGuard } from './account/shared/auth.guard';
import { AuthGuardLogin } from './account/shared/auth.guard.login';
import { AuthenticationComponent } from './layout/authentication/authentication.component';
import { HomeComponent } from './layout/home/home.component';
import { PrincipalComponent } from './principal/principal.component';
import { InspecaoConsultarComponent } from './eventos/inspecao/inspecao-consultar/inspecao-consultar.component';
import { BaixaConsultarComponent } from './eventos/baixa/baixa-consultar/baixa-consultar.component';
import { ExposicaoConsultarComponent } from './exposicao/exposicao-consultar/exposicao-consultar.component';
import { CriadorConsultarComponent } from './criador/criador-consultar/criador-consultar.component';
import { UsuariosConsultarComponent } from './usuarios/usuarios-consultar/usuarios-consultar.component';
import { PlantelConsultarComponent } from './bovino/plantel/plantel-consultar/plantel-consultar.component';
import { ExposicaoManterComponent } from './exposicao/exposicao-manter/exposicao-manter.component';
import { ConstantesRotas } from './shared/constantes';
import { ControleLeiteiroConsultarComponent } from './controle-leiteiro/controle-leiteiro-consultar/controle-leiteiro-consultar.component';
import { ControleLeiteiroManterComponent } from './controle-leiteiro/controle-leiteiro-manter/controle-leiteiro-manter.component';
import { ControleLeiteiroAnalisarComponent } from './controle-leiteiro/controle-leiteiro-analisar/controle-leiteiro-analisar.component';
import { AlterarSenhaComponent } from './account/alterar-senha/alterar-senha.component';
import { UsuariosManterValidaCpfComponent } from './usuarios/usuarios-manter-valida-cpf/usuarios-manter-valida-cpf.component';
import { UsuariosManterComponent } from './usuarios/usuarios-manter/usuarios-manter.component';
import { TeFivManterComponent } from './eventos/te_fiv/te-fiv-manter/te-fiv-manter.component';
import { InovulacaoManterComponent } from './eventos/inovulacao/inovulacao-manter/inovulacao-manter.component';
import { BovinoDetalharComponent } from './bovino/bovino-detalhar/bovino-detalhar.component';
import { TransferenciaManterComponent } from './eventos/transferencia/transferencia-manter/transferencia-manter.component';
import { CriadorGerenciarComponent } from './criador/criador-gerenciar/criador-gerenciar.component';
import { ManterEmbriaoComponent } from './eventos/nacionalizacao/embriao/manter-embriao/manter-embriao.component';
import { PerfilComponent } from './usuarios/perfil/perfil.component'
import { BovinoManterComponent } from './bovino/bovino-manter/bovino-manter.component';
import { ClassificacaoLinearManterComponent } from './eventos/classificacao_linear/classificacao-linear-manter/classificacao-linear-manter.component';
import { ProcuradorComponent } from './procurador/procurador.component';
import { CertificadoAssinarComponent } from './certificados/certificado-assinar/certificado-assinar.component';
import { ManterSemenComponent } from './eventos/nacionalizacao/semen/manter-semen/manter-semen.component';
import { InspecaoManterComponent } from './eventos/inspecao/inspecao-manter/inspecao-manter.component';
import { RegistrosReservadosComponent } from './bovino/registros-reservados/registros-reservados.component';
import { RegistrosReservadosVisualizarComponent } from './bovino/registros-reservados/registros-reservados-visualizar/registros-reservados-visualizar.component';
import { RegistrosReservadosConsultarComponent } from './bovino/registros-reservados/registros-reservados-consultar/registros-reservados-consultar.component';
import { ManterRegistroAssociacaoComponent } from './bovino/registros-reservados/manter-registro-associacao/manter-registro-associacao.component';
import { ManterRegistroInspetorComponent } from './bovino/registros-reservados/manter-registro-inspetor/manter-registro-inspetor.component';
import { PesagemConsultarComponent } from './eventos/pesagem/pesagem-consultar/pesagem-consultar.component';
import { LaudoDnaManterComponent } from './laudo-dna/laudo-dna-manter/laudo-dna-manter.component';
import { LaudoDnaConsultarComponent } from './laudo-dna/laudo-dna-consultar/laudo-dna-consultar.component';
import { CarcacaConsultarComponent } from './eventos/controle_carcaca/carcaca-consultar/carcaca-consultar.component';
import { CarcacaManterComponent } from './eventos/controle_carcaca/carcaca-manter/carcaca-manter.component';
import { ApropriarTecnicoComponent } from './eventos/inspecao/apropriar-tecnico/apropriar-tecnico.component';
import { InspecaoRelatorioComponent } from './eventos/inspecao/inspecao-relatorio/inspecao-relatorio.component';
import { ApropriarVeterinarioComponent } from './eventos/te_fiv/apropriar-veterinario/apropriar-veterinario.component';
import { ApropriarProcuradorComponent } from './criador/criador-gerenciar/apropriar-procurador/apropriar-procurador.component';
import { CarcacaManterPosComponent } from './eventos/controle_carcaca/carcaca-manter-pos/carcaca-manter-pos.component';



const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      { path: '', component: PrincipalComponent },
      { path: 'criadores_consultar', component: CriadorConsultarComponent },
      { path: ConstantesRotas.CRIADOR_MANTER, component: CriadorGerenciarComponent },
      { path: 'padreacao_manter', component: PadreacaoManterComponent },
      { path: 'padreacao_consultar', component: PadreacaoConsultarComponent },
      { path: 'fiv_consultar', component: TeFivConsultarComponent },
      { path: 'fiv_manter', component: TeFivManterComponent },
      { path: 'baixa_consultar', component: BaixaConsultarComponent },
      { path: 'baixa_manter', component: BaixaManterComponent },
      { path: 'nascimento_consultar', component: NascimentoConsultarComponent },
      { path: 'nascimento_manter', component: NascimentoManterComponent },
      { path: 'inovulacao_consultar', component: InovulacaoConsultarComponent },
      { path: 'inovulacao_manter', component: InovulacaoManterComponent },
      { path: 'inspecao_consultar', component: InspecaoConsultarComponent },
      { path: 'inspecao_manter', component: InspecaoManterComponent },
      { path: 'transferencia_consultar', component: TransferenciaConsultarComponent },
      { path: 'transferencia_manter', component: TransferenciaManterComponent },
      { path: 'usuario_consultar', component: UsuariosConsultarComponent },
      { path: 'usuario_manter', component: UsuariosManterComponent },
      { path: 'registrar_usuario', component: UsuariosManterValidaCpfComponent },
      { path: 'exposicao_consultar', component: ExposicaoConsultarComponent },
      { path: 'registrar_exposicao', component: ExposicaoManterComponent },
      { path: 'controle_leiteiro_consultar', component: ControleLeiteiroConsultarComponent },
      { path: 'registrar_controle_leiteiro', component: ControleLeiteiroManterComponent },
      { path: 'analisar_controle_leiteiro', component: ControleLeiteiroAnalisarComponent },
      { path: 'classificacaolinear_consultar', component: ClassificacaoLinearConsultarComponent },
      { path: 'classificacaolinear_manter', component: ClassificacaoLinearManterComponent },
      { path: 'nacionalizacao_consultar', component: ConsultarSemenComponent },
      { path: 'nacionalizacao_manter', component: ManterSemenComponent },
      { path: 'nacionalizacaoembriao_consultar', component: ConsultarEmbriaoComponent },
      { path: 'nacionalizacaoembriao_manter', component: ManterEmbriaoComponent },
      { path: 'certificados_consultar', component: CertificadosConsultarComponent },
      { path: 'certificados_manter', component: CertificadosManterComponent },
      { path: 'assinar_certificado', component: CertificadoAssinarComponent },
      { path: 'pendentes_de_emissao', component: CertificadoConsultarPendentesComponent },
      { path: 'plantel_consultar', component: PlantelConsultarComponent },
      { path: 'alterar_senha', component: AlterarSenhaComponent },
      { path: 'bovino_detalhar/:id', component: BovinoDetalharComponent },
      { path: 'registros_reservados', component: RegistrosReservadosComponent },
      { path: 'registros_reservados_visualizar', component: RegistrosReservadosVisualizarComponent },
      { path: 'registros_reservados_consultar', component: RegistrosReservadosConsultarComponent },
      { path: 'manter_registro_associacao', component: ManterRegistroAssociacaoComponent },
      { path: 'manter_registro_inspetor', component: ManterRegistroInspetorComponent },
      { path: 'perfil', component: PerfilComponent },
      { path: 'bovino_manter', component: BovinoManterComponent },
      { path: 'procurador', component: ProcuradorComponent },
      { path: 'pesagem_manter', component: PesagemManterComponent },
      { path: 'pesagem_consultar', component: PesagemConsultarComponent },
      { path: 'laudo_dna_manter', component: LaudoDnaManterComponent },
      { path: 'laudo_dna_consultar', component: LaudoDnaConsultarComponent },
      { path: 'abatecomavalcarcaca_consultar', component: CarcacaConsultarComponent },
      { path: 'abatecomavalcarcaca_manter', component: CarcacaManterComponent },
      { path: 'inspecao/apropriar_tecnico', component: ApropriarTecnicoComponent },
      { path: 'inspecao_relatorio', component: InspecaoRelatorioComponent },
      { path: 'fiv/apropriar_veterinario', component: ApropriarVeterinarioComponent },
      { path: 'criador/apropriar_procurador', component: ApropriarProcuradorComponent },
      { path: 'abatecomavalcarcaca_manter_pos', component: CarcacaManterPosComponent },
      { path: 'abate_carcaca/apropriar_tecnico', component: ApropriarTecnicoComponent },
    ],
    canActivate: [AuthGuard]
  },
  {
    path: '',
    component: AuthenticationComponent,
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      { path: 'login', component: LoginComponent },
    ],
    canActivate: [AuthGuardLogin]
  },
  { path: '**', redirectTo: '' }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
