<div class="custom-card row">

  <label>{{ itemAscendencia?.Codigo }} - {{ itemAscendencia?.Nome }}</label>

  <div>

    <div *ngIf="!ocorrenciaReadOnly && itemAscendencia?.Readonly">
      <i class="fas fa-search icon-disabled"></i>
      
      <a *ngIf="!mostrarCardCadastro && !itemAscendencia?.AnimalExistente" type="button" matTooltip="Adicionar" (click)="alterarModoRecuperacaoAnimal()">
        <i class="fas fa-plus"></i>
      </a>
      <a *ngIf="mostrarCardCadastro && !itemAscendencia?.AnimalExistente" type="button" matTooltip="Esconder" (click)="alterarModoRecuperacaoAnimal()">
        <i class="fas fa-minus"></i>
      </a>
      
      <i class="fas fa-eraser icon-disabled"></i>
    </div>

    <div >
      <a type="button" matTooltip="Buscar animal" (click)="buscar()">
        <i class="fas fa-search"></i>
      </a>
  
      <a  type="button" matTooltip="Adicionar" (click)="alterarModoRecuperacaoAnimal()">
        <i class="fas fa-plus"></i>
      </a>
      <a *ngIf="mostrarCardCadastro && !itemAscendencia?.AnimalExistente" type="button" matTooltip="Esconder" (click)="alterarModoRecuperacaoAnimal()">
        <i class="fas fa-minus"></i>
      </a>
  
      <a type="button" matTooltip="Apagar" (click)="limpar()">
        <i class="fas fa-eraser"></i>
      </a>
    </div>

    <!--<div>
      <i class="fas fa-search icon-disabled"></i>
      <i class="fas fa-plus icon-disabled"></i>
      <i class="fas fa-eraser icon-disabled"></i>
    </div>-->

  </div>

</div>

<app-cadastro-animal-ascendencia
  *ngIf="mostrarCardCadastro" 
  [bovino]=$any(itemAscendencia)
  [permiteCodigoCentral]="permiteCodigoCentral"
  (bovinoParaSalvar)="bovinoPreenchidoParaCadastro($event)"
>
</app-cadastro-animal-ascendencia>
