import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from 'src/app/account/shared/account.service';
import { FiltroConsultaLaudoDnaModel } from 'src/app/models/FiltroConsultaLaudoDnaModel';
import { QualificaLaudoDNA } from 'src/app/shared/enums/Enums';
import { LocalStorageService } from 'src/app/shared/local-storage.service';
import { QueryStateService } from 'src/app/shared/query-state.service';

@Component({
  selector: 'app-filtro-consulta-laudo-dna',
  templateUrl: './filtro-consulta-laudo-dna.component.html',
  styleUrls: ['./filtro-consulta-laudo-dna.component.scss']
})
export class FiltroConsultaLaudoDnaComponent implements OnInit {

  listaQualifica: { value: number, label: string }[] = [];

  @Input()
  formConsulta: FiltroConsultaLaudoDnaModel = new FiltroConsultaLaudoDnaModel();
  @Output() triggerConsulta: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private accountService: AccountService,
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private queryState: QueryStateService
  ) {
    this.preencherQualifica();
  }

  ngOnInit(): void {
    let token: any = this.accountService.getPayloadToken();

    if (token) {
      this.formConsulta.Criador.id = token.CriadorId;
      this.formConsulta.Criador.codigo = token.CodigoCriador;
      this.formConsulta.Criador.nome = token.NomeCriador;
      this.formConsulta.Criador.readonly = this.accountService.isCriador;
    }
    this.recuperarFormulario();
  }

  preencherQualifica() {
    this.listaQualifica = [];

    this.listaQualifica.push({ value: -1, label: 'Todos' });
    this.listaQualifica.push({ value: QualificaLaudoDNA.Sim, label: 'Sim' });
    this.listaQualifica.push({ value: QualificaLaudoDNA.Nao, label: 'Não' });
    this.listaQualifica.push({ value: QualificaLaudoDNA.Permanente, label: 'Permanente' });
  }

  criadorSelecionado(criadorId: string) {
    this.formConsulta.Criador.id = criadorId;
  }

  private recuperarFormulario() {
    if (this.localStorage.get("rotaAnterior") == this.route.snapshot.url[0].path) {
      // Recupera o state passado pela função de consultas do painel gerencial através da rota
      let state = history.state;

      // Recupera o state da última busca do localStorage
      const queryState = this.localStorage.get("formConsultaLaudos");

      // Caso não haja state do form salvo, inicializa vazio
      if (!state && !queryState) return;

      // Caso não haja state passado pelo painel gerencial, mas exista um form salvo para essa consulta no storage
      Object.assign(state, queryState);

      this.formConsulta.CodigoAnimal = state.CodigoAnimal;
      this.formConsulta.Pai.Id = state.Pai.Id;
      this.formConsulta.Pai.NomeBovino = state.Pai.NomeBovino;
      this.formConsulta.Pai.CodigoBovino = state.Pai.CodigoBovino;
      this.formConsulta.Mae.Id = state.Mae.Id;
      this.formConsulta.Mae.NomeBovino = state.Mae.NomeBovino;
      this.formConsulta.Mae.CodigoBovino = state.Mae.CodigoBovino;
      this.formConsulta.Criador.codigo = state.Criador.Codigo;
      this.formConsulta.Criador.nome = state.Criador.Nome;
      this.formConsulta.NomeAnimal = state.NomeAnimal;
      this.formConsulta.NumeroLaudo = state.NumeroLaudo;
      this.formConsulta.Sexo = state.Sexo;
      this.formConsulta.Qualifica = state.Qualifica || -1;


      this.triggerConsulta.emit();

    } else {
      this.localStorage.remove("formConsultaUsuario");
      this.localStorage.remove("page");
    }
  }
}
