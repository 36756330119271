import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ItemAscendenciaNacionalizacaoModel } from 'src/app/models/ItemAscendenciaNacionalizacaoModel';
import { ConsultarBovinoModalComponent } from 'src/app/shared/consultar-bovino-modal/consultar-bovino-modal.component';
import { Sexo, TipoBuscaBovino } from 'src/app/shared/enums/Enums';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-item-ascendencia',
  templateUrl: './item-ascendencia.component.html',
  styleUrls: ['./item-ascendencia.component.scss']
})
export class ItemAscendenciaComponent implements OnInit {

  mostrarCardCadastro: boolean = false;
  permiteCodigoCentral: boolean = false;

  @Input() ocorrenciaReadOnly: boolean = false;
  @Input() idProprietario: string = '-1';
  @Input() tipoBusca: TipoBuscaBovino = TipoBuscaBovino.Macho;
  @Input() itemAscendencia: ItemAscendenciaNacionalizacaoModel | null = null;
  @Input() permiteSalvarAprovado : boolean = false;
  
  @Output() bovinoId: EventEmitter<string> = new EventEmitter<string>();
  @Output() itemAdicionado: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() itemParaRemover: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.permiteCodigoCentral = this.tipoBusca === TipoBuscaBovino.Macho;
  }

  private mensagemFaltaDeInfo(title: string): void {
    Swal.fire(title, '', 'error');
  }
  
  bovinoPreenchidoParaCadastro(item: ItemAscendenciaNacionalizacaoModel) {

    if (!item.Codigo) return this.mensagemFaltaDeInfo('Código não informado.');
    if (!item.DataNascimento) return this.mensagemFaltaDeInfo('Data de Nascimento não informada.');
    if (!item.Nome) return this.mensagemFaltaDeInfo('Nome não informado.');
    if (this.permiteCodigoCentral && !item.CodigoCentral) return this.mensagemFaltaDeInfo('Código Central não informado.');
    if (item.ProprietarioId === '-1' || item.Proprietario === '-1') return this.mensagemFaltaDeInfo('Proprietário não informado.');

    this.mostrarCardCadastro = false;

    this.itemAscendencia = item;
    this.itemAscendencia.AnimalExistente = false;
    this.itemAscendencia.ItemParaCadastrar = true;
    this.itemAscendencia.Sexo = this.tipoBusca === TipoBuscaBovino.Macho? Sexo.Macho : Sexo.Fêmea;

    this.itemAdicionado.emit(true);
  }
  
  alterarModoRecuperacaoAnimal() {
    this.mostrarCardCadastro = !this.mostrarCardCadastro; 
  }
  
  limpar() {
    if (this.itemAscendencia) this.itemParaRemover.emit(true);
  }
  
  buscar() {
    if (this.idProprietario === '-1') {
      Swal.fire('Por favor, informe o criador.', '', 'error');
      return;
    }
    
    const dialogRef = this.dialog.open(
      ConsultarBovinoModalComponent, 
      {
        height: '90%', 
        width: '90%',
        autoFocus: false,
        data: { bovino: {
          ConsultaAnimalTodosCriadores: false,
          ConsideraInativos: true,
          TipoBusca: this.tipoBusca,
          IdProprietarioConsulta: this.idProprietario
        }}
      }
    );

    dialogRef.componentInstance.bovinoSelecionado.subscribe((result : any) => {
      if(result) {
        this.bovinoId.emit(result.Id);
        this.mostrarCardCadastro = false;
      }
    });
  }

  desabilitarCampos(){
    if(this.permiteSalvarAprovado){
      return true;
    }else{
      return false;
    }
  }
}
