import { FiltroConsultaPlantelModel } from './../models/FiltroConsultaPlantelModel';
import { FiltroConsultaPlantelComponent } from './filtro-consulta-plantel/filtro-consulta-plantel.component';
import { FiltroConsultaLaudoDnaModel } from './../models/FiltroConsultaLaudoDnaModel';
import { FiltroConsultaCertificadosModel } from './../models/FiltroConsultaCertificadosModel';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'src/app/shared/local-storage.service';
import { FiltroConsultaEventosModel } from 'src/app/models/FiltroConsultaEventosModel';
import { ActivatedRoute } from '@angular/router';
import { FiltroConsultaCriadoresModel } from '../models/FiltroConsultaCriadoresModel';
import { FiltroConsultaUsuariosModel } from '../models/FiltroConsultaUsuariosModel';

@Injectable({
  providedIn: 'root'
})
export class QueryStateService {
  //foi necessário criar uma variável para cada model
  formConsulta: FiltroConsultaEventosModel = new FiltroConsultaEventosModel();
  formConsultaCriador: FiltroConsultaCriadoresModel = new FiltroConsultaCriadoresModel();
  formConsultaUsuario: FiltroConsultaUsuariosModel = new FiltroConsultaUsuariosModel();
  formConsultaCertificados: FiltroConsultaCertificadosModel = new FiltroConsultaCertificadosModel();
  formConsultaLaudos: FiltroConsultaLaudoDnaModel = new FiltroConsultaLaudoDnaModel();
  formConsultaPlantel: FiltroConsultaPlantelModel = new FiltroConsultaPlantelModel();
  constructor(
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
  ) { }
  //todas as funções fazem a mesma coisa, recebem o formulário da tela, 
  //salvam no state e depois salvam o state
  //no localStorage
  save(form: FiltroConsultaEventosModel) {
    this.formConsulta = form;
    const state = {
      Criador: {
        id: this.formConsulta.criador.id,
        nome: this.formConsulta.criador.nome,
        codigo: this.formConsulta.criador.codigo
      },
      Bovino: {
        Id: this.formConsulta.bovino.Id,
        NomeBovino: this.formConsulta.bovino.NomeBovino,
        CodigoBovino: this.formConsulta.bovino.CodigoBovino,
      },
      Periodo: {
        Ate: this.formConsulta.eventoAte,
        De: this.formConsulta.eventoDe,
      },
      FormularioProtocolo: this.formConsulta.formularioProtocolo,
      Situacao: this.formConsulta.situacao,
      ComunicacaoAte: this.formConsulta.comunicacaoAte,
      ComunicacaoDe: this.formConsulta.comunicacaoDe,
    };

    this.localStorage.set("formConsulta", state);
  }

  saveFormCriador(form: FiltroConsultaCriadoresModel) {
    this.formConsultaCriador = form;
    const state = {
      Afixo: this.formConsultaCriador.afixo,
      Codigo: this.formConsultaCriador.codigo,
      Cpf_cnpj: this.formConsultaCriador.cpf_cnpj,
      Nome: this.formConsultaCriador.nome,
      Situacao: this.formConsultaCriador.situacao,
      TipoUsuario: this.formConsultaCriador.tipoUsuario
    };

    this.localStorage.set("formConsultaCriador", state);
  }

  saveFormUsuario(form: FiltroConsultaUsuariosModel) {
    this.formConsultaUsuario = form;
    const state = {
      Codigo: this.formConsultaUsuario.codigo,
      Cpf: this.formConsultaUsuario.cpf,
      Nome: this.formConsultaUsuario.nome,
      Situacao: this.formConsultaUsuario.situacao,
      Vinculacao: this.formConsultaUsuario.vinculacao,
      Grupo: this.formConsultaUsuario.grupo
    };
    this.localStorage.set("formConsultaUsuario", state);
  }

  saveFormCertificados(form: FiltroConsultaCertificadosModel) {
    this.formConsultaCertificados = form;
    const state = {
      Assinado: this.formConsultaCertificados.assinado,
      Bovino: {
        Id: this.formConsultaCertificados.bovino.Id,
        NomeBovino: this.formConsultaCertificados.bovino.NomeBovino,
        CodigoBovino: this.formConsultaCertificados.bovino.CodigoBovino,
      },
      Certificado: this.formConsultaCertificados.certificado,
      EmissaoAte: this.formConsultaCertificados.emissaoAte,
      emissaoDe: this.formConsultaCertificados.emissaoDe,
      Proprietario: {
        Codigo: this.formConsultaCertificados.proprietario.codigo,
        Nome: this.formConsultaCertificados.proprietario.nome
      }
    };
    this.localStorage.set("formConsultaCerificado", state);
  }

  saveFormLadudos(form: FiltroConsultaLaudoDnaModel) {
    this.formConsultaLaudos = form;
    const state = {
      CodigoAnimal: this.formConsultaLaudos.CodigoAnimal,
      Pai: {
        Id: this.formConsultaLaudos.Pai.Id,
        NomeBovino: this.formConsultaLaudos.Pai.NomeBovino,
        CodigoBovino: this.formConsultaLaudos.Pai.CodigoBovino,
      },
      Mae: {
        Id: this.formConsultaLaudos.Mae.Id,
        NomeBovino: this.formConsultaLaudos.Mae.NomeBovino,
        CodigoBovino: this.formConsultaLaudos.Mae.CodigoBovino,
      },
      NomeAnimal: this.formConsultaLaudos.NomeAnimal,
      NumeroLaudo: this.formConsultaLaudos.NumeroLaudo,
      Sexo: this.formConsultaLaudos.Sexo,
      Criador: {
        Codigo: this.formConsultaLaudos.Criador.codigo,
        Nome: this.formConsultaLaudos.Criador.nome
      },
      Qualifica: this.formConsultaLaudos.Qualifica
    };
    this.localStorage.set("formConsultaLaudos", state);
  }

  saveFormPlantel(form: FiltroConsultaPlantelModel) {
    this.formConsultaPlantel = form;
    const state = {
      Bovino: {
        Id: this.formConsultaPlantel.bovino.Id,
        NomeBovino: this.formConsultaPlantel.bovino.NomeBovino,
        CodigoBovino: this.formConsultaPlantel.bovino.CodigoBovino,
      },
      NascimentoAte: this.formConsultaPlantel.nascimentoAte,
      NascimentoDe: this.formConsultaPlantel.nascimentoDe,
      Situacao: this.formConsultaPlantel.situacao,
      Criador: {
        Codigo: this.formConsultaPlantel.criador.codigo,
        Nome: this.formConsultaPlantel.criador.nome,
        Id: this.formConsultaPlantel.criador.id
      }
    };
    this.localStorage.set("formConsultaPlantel", state);
  }

  //função para pegar a paginação
  paginacao(number: number) {
    this.localStorage.set("page", number);
  }

  //função que pega o path da rota para limpar o localStorage quando troca de evento/tela
  getRota(rota: string) {
    this.localStorage.set("rotaAnterior", rota);
  }
}


