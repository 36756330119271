<div class="card" style="margin-top: 1em;">
  <div class="card-body col-md-6 offset-md-3">
    <div class="row justify-content-midle p-1">
      <label for="senhaAtual">Senha Atual</label>
      <input type="password" class="form-control form-control-sm" name="senhaAtual"  minlength="8" [(ngModel)]="infoLogin.senhaAtual" required>
    </div>
    <div class="row justify-content-midle p-1">
      <label for="senhaNova">Nova Senha (Minimo 8 caracteres)</label>
      <input type="password" class="form-control form-control-sm" name="senhaNova" minlength="8" [(ngModel)]="infoLogin.senhaNova" required>
    </div>
    <div class="row justify-content-midle p-1">
      <label for="senhaConfirmacao">Confirma Senha (Minimo 8 caracteres)</label>
      <input type="password" class="form-control form-control-sm" name="senhaConfirmacao" minlength="8" [(ngModel)]="infoLogin.confirmacaoSenhaNova" required>
    </div>

    <div class="row justify-content-end">
      <div class="col-lg-3 col-md-4 col-sm-6  pt-2">
        <button type="button" class="btn btn-sm btn-block btn-primary"
        (click)="botaoVoltar()">Voltar</button>
      </div>
      <div  class="col-lg-3 col-md-4 col-sm-6  pt-2">
        <button type="button" class="btn btn-sm btn-block btn-primary"
        (click)="botaoAlterar()">Alterar</button>
      </div>
    </div>

  </div>
</div>

