<app-formulario-tabs
  [formulario]="formulario"
  [listaComunicacao]="lote?.ListaComunicacao"
  (tabSelecionada)="tabSelecionada($event)"
  (criadorSelecionado)="criadorSelecionado($event)"></app-formulario-tabs>

<div class="row">
  <div class="col-lg-12 col-sm-12">
    <div class="card card-principal-evento">
      <div class="card-body">
        <!--Ocorrencia Atual-->
        <div *ngIf="!isNewLote()">
          <div class="row">
            <div class="col-lg-12">
              <div class="card card-evento">
                <div class="card-body">
                  <div class="row card-title-evento">
                    <div class="col-6">
                      <h4 class="card-title"><mat-icon svgIcon="bovino"></mat-icon> Dados do Nascimento</h4>
                    </div>
                    <div class="col-6 text-right">
                      <button
                        *ngIf="ocorrenciaAtual.Id !== '-1'"
                        type="button"
                        class="btn btn-link btn-sm"
                        (click)="documentosDaOcorrencia()">
                        <i class="far fa-folder"></i> Documentos
                      </button>
                    </div>
                  </div>

                  <div class="row">
                    <div class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
                      <label for="DataOcorrencia">*Data Nascimento</label>
                      <div class="input-group input-group-sm">
                        <input
                          [disabled]="sugestaoSelecionada"
                          class="form-control"
                          [max]="maxDate"
                          [matDatepicker]="dataNascimento"
                          name="DataOcorrencia"
                          [(ngModel)]="ocorrenciaAtual.DataNascimento"
                          [disabled]="isOcorrenciaReadOnly()" />
                        <div *ngIf="!isOcorrenciaReadOnly()" class="input-group-append">
                          <button class="btn btn-outline-secondary" type="button" (click)="dataNascimento.open()">
                            <i class="bi bi-calendar3"></i>
                          </button>
                        </div>
                      </div>
                      <mat-datepicker [startAt]="ocorrenciaAtual.DataNascimento" #dataNascimento></mat-datepicker>
                    </div>

                    <div *ngIf="sugestaoSelecionada" class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
                      <label for="DataOcorrencia">Data {{ descEventoSugestao }}</label>
                      <div class="input-group input-group-sm">
                        <input
                          [disabled]="sugestaoSelecionada"
                          class="form-control"
                          [max]="maxDate"
                          [matDatepicker]="DataEventoSugestao"
                          name="DataEventoSugestao"
                          [(ngModel)]="
                            ocorrenciaAtual.OcorrenciaEventoPadreacao.DataOcorrencia ||
                            ocorrenciaAtual.OcorrenciaEventoInovulacao.DataOcorrencia
                          " />
                        <div *ngIf="!isOcorrenciaReadOnly()" class="input-group-append">
                          <button class="btn btn-outline-secondary" type="button" (click)="DataEventoSugestao.open()">
                            <i class="bi bi-calendar3"></i>
                          </button>
                        </div>
                      </div>
                      <mat-datepicker [startAt]="ocorrenciaAtual.Data" #DataEventoSugestao></mat-datepicker>
                    </div>

                    <!-- Caso seja COBERTURA (Padreacao) -->
                    <div
                      class="form-group col-lg-2 col-md-4 col-sm-6 col-xs-12"
                      *ngIf="
                        (eventoAssociado === 50 || ocorrenciaAtual.OcorrenciaEventoPadreacao.Id !== '-1') &&
                        env.contexto === 'wagyu'
                      ">
                      <label for="TipoOrigem">Origem</label>
                      <select
                        class="form-control form-control-sm"
                        name="TipoOrigem"
                        [(ngModel)]="ocorrenciaAtual.OcorrenciaEventoPadreacao.TipoPadreacao"
                        [disabled]="true">
                        <option value="1">Inseminação Artificial</option>
                        <option value="2">Monta Natural</option>
                      </select>
                    </div>

                    <!-- Caso seja IMPLANTAÇÃO (Inovulacao) -->
                    <!-- Implantação pode ser um evento TE/FIV ou Nacionalização de Embrião -->
                    <div
                      class="form-group col-lg-2 col-md-4 col-sm-6 col-xs-12"
                      *ngIf="
                        eventoAssociado === 53 &&
                        env.contexto === 'wagyu'
                      ">
                      <div *ngIf="showOrigemPelaFiv()">
                        <label for="TipoOrigem">Origem</label>
                        <select
                          class="form-control form-control-sm"
                          name="TipoOrigem"
                          [(ngModel)]="
                            ocorrenciaAtual.OcorrenciaEventoInovulacao.OcorrenciaEventoFiv.TipoFIV 
                          "
                          [disabled]="true">
                          <option value="1">FIV</option>
                          <option value="2">TE</option>
                        </select>
                      </div>
                      <div *ngIf="showOrigemPelaInov()">
                        <label for="TipoOrigem">Origem</label>
                        <select
                          class="form-control form-control-sm"
                          name="TipoOrigem"
                          [(ngModel)]="
                            ocorrenciaAtual.OcorrenciaEventoInovulacao.TipoFiv
                          "
                          [disabled]="true">
                          <option value="1">FIV</option>
                          <option value="2">TE</option>
                        </select>
                      </div>
                      
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                      <label></label>
                      <button
                        [disabled]="isOcorrenciaReadOnly() || aprovadoComPendencia()"
                        type="button"
                        class="btn btn-sm btn-block btn-primary"
                        (click)="botaoConsultarLimparSugestao()">
                        {{ sugestaoSelecionada ? 'Limpar' : 'Buscar' }} {{ descEventoSugestao }}
                      </button>
                    </div>
                  </div>

                  <div class="row">
                    <!-- Linha da mãe -->
                    <div *ngIf="!sugestaoSelecionada" class="form-group col-lg-2 col-md-4 col-sm-6 col-xs-12">
                      <label for="TipoNascimento">Mãe Cadastrada?</label>
                      <select
                        class="form-control form-control-sm"
                        name="TipoNascimento"
                        [(ngModel)]="ocorrenciaAtual.MaeCadastrada"
                        [disabled]="
                          isOcorrenciaReadOnly() || desabilitarCamposAcessoCriador() || aprovadoComPendencia()
                        ">
                        <option value="1">Sim</option>
                        <option value="2">Não</option>
                      </select>
                    </div>

                    <div
                      *ngIf="ocorrenciaAtual.MaeCadastrada == 1"
                      class="col-sm-6 col-xs-12"
                      [ngClass]="{
                        'col-lg-10 col-md-8': !sugestaoSelecionada,
                        'col-lg-12 col-md-10': sugestaoSelecionada
                      }">
                      <app-consultar-bovino
                        [bovino]="ocorrenciaAtual.Mae"
                        [nascimento]="true"
                        [ocorrenciaAtualSituacao]="ocorrenciaAtual.Situacao"></app-consultar-bovino>
                    </div>
                    

                    <div
                      *ngIf="ocorrenciaAtual.MaeCadastrada == 2"
                      class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
                      <label for="formulario">*Código Mãe</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        name="CodigoMaeNaoCadastrada"
                        [(ngModel)]="ocorrenciaAtual.OcorrenciaEventoPadreacao.CodigoMatrizNaoCadastrada"
                        [disabled]="isOcorrenciaReadOnly() || desabilitarCamposAcessoCriador()" />
                    </div>

                    <div
                      *ngIf="ocorrenciaAtual.MaeCadastrada == 2"
                      class="form-group col-lg-7 col-md-4 col-sm-6 col-xs-12">
                      <label for="protocolo">*Nome Mãe</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        name="NomeMaeNaoCadastrada"
                        [(ngModel)]="ocorrenciaAtual.OcorrenciaEventoPadreacao.NomeMatrizNaoCadastrada"
                        [disabled]="isOcorrenciaReadOnly() || desabilitarCamposAcessoCriador()" />
                    </div>
                  </div>
                  <!-- FIM Linha da mãe -->

                  <!-- Linha da receptora quando associar uma inovulacao/implantacao -->
                  <div
                    *ngIf="
                      sugestaoSelecionada &&
                      ['Inovulação', 'Implantação'].includes(descEventoSugestao) &&
                      ocorrenciaAtual.OcorrenciaEventoInovulacao.Receptora
                    "
                    class="row">
                    <!-- <div class="col-sm-12 col-xs-12 col-lg-12 col-md-12">
                      <app-consultar-bovino
                        [bovino]="ocorrenciaAtual.OcorrenciaEventoInovulacao.Receptora"
                        [ocorrenciaAtualSituacao]="ocorrenciaAtual.Situacao"></app-consultar-bovino>
                    </div> -->

                    <div class="form-group col-lg-4 col-md-4 col-sm-12">
                      <label for="formulario">Código Receptora</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        name="codigoReceptora"
                        [(ngModel)]="ocorrenciaAtual.OcorrenciaEventoInovulacao.Receptora.CodigoBovino"
                        [disabled]="true" />
                    </div>

                    <div class="form-group col-lg-8 col-md-4 col-sm-12">
                      <label for="protocolo">Nome Receptora</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        name="nomeReceptora"
                        [(ngModel)]="ocorrenciaAtual.OcorrenciaEventoInovulacao.Receptora.NomeBovino"
                        [disabled]="true" />
                    </div>
                  </div>
                  <!-- FIM da Linha da Receptora -->

                  <div class="row">
                    <div *ngIf="!sugestaoSelecionada" class="form-group col-lg-2 col-md-4 col-sm-6 col-xs-12">
                      <label for="TipoNascimento">Pai Cadastrado?</label>
                      <select
                        class="form-control form-control-sm"
                        name="TipoNascimento"
                        [(ngModel)]="ocorrenciaAtual.PaiCadastrado"
                        [disabled]="
                          isOcorrenciaReadOnly() || desabilitarCamposAcessoCriador() || aprovadoComPendencia()
                        ">
                        <option value="1">Sim</option>
                        <option value="2">Não</option>
                      </select>
                    </div>

                    <div
                      *ngIf="ocorrenciaAtual.PaiCadastrado == 1"
                      class="col-sm-6 col-xs-12"
                      [ngClass]="{
                        'col-lg-10 col-md-8': !sugestaoSelecionada,
                        'col-lg-12 col-md-10': sugestaoSelecionada
                      }">
                      <app-consultar-bovino
                        [bovino]="ocorrenciaAtual.Pai"
                        [ocorrenciaAtualSituacao]="ocorrenciaAtual.Situacao"
                        [nascimento]="true"
                        (BovinoEncontrado)="animalSelecionado($event)"></app-consultar-bovino>
                    </div>
                    <div *ngIf="ocorrenciaAtual.MaeCadastrada == 1"
                      class="form-group col-lg-2 col-md-4 col-sm-6 col-xs-12">
                      <label for="grauSangueMae">Grau de Sangue Mãe</label>
                      <input type="text"
                      class="form-control form-control-sm"
                      name="grauSangueMae"
                      [(ngModel)]="grauDeSangueMae"
                      [disabled]="true">
                    </div>
                    <div *ngIf="ocorrenciaAtual.PaiCadastrado == 1"
                      class="form-group col-lg-2 col-md-4 col-sm-6 col-xs-12">
                      <label for="grauSangueMae">Grau de Sangue Pai</label>
                      <input type="text"
                      class="form-control form-control-sm"
                      name="grauSangueMae"
                      [(ngModel)]="grauDeSanguePai"
                      [disabled]="true">
                    </div>
                    <div
                      class="form-group col-lg-2 col-md-4 col-sm-6 col-xs-12">
                      <label for="grauSangueMae">Grau de Sangue Produto</label>
                      <input type="text"
                      class="form-control form-control-sm"
                      name="grauSangueMae"
                      [(ngModel)]="grauDeSangueProduto"
                      [disabled]="true">
                    </div>
                    <div
                      *ngIf="ocorrenciaAtual.PaiCadastrado == 2"
                      class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
                      <label for="formulario">*Código Pai</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        name="CodigoPaiNaoCadastrado"
                        [(ngModel)]="ocorrenciaAtual.CodigoPaiNaoCadastrado"
                        [disabled]="isOcorrenciaReadOnly() || desabilitarCamposAcessoCriador()" />
                    </div>

                    <div
                      *ngIf="ocorrenciaAtual.PaiCadastrado == 2"
                      class="form-group col-lg-7 col-md-4 col-sm-6 col-xs-12">
                      <label for="protocolo">*Nome Pai</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        name="NomePaiNaoCadastrado"
                        [(ngModel)]="ocorrenciaAtual.NomePaiNaoCadastrado"
                        [disabled]="isOcorrenciaReadOnly() || desabilitarCamposAcessoCriador()" />
                    </div>
                  </div>

                  <!-- FIM linha do pai -->

                  <!-- p/ wagyu - laudo dna  -->
                  <div class="row" *ngIf="env.contexto === 'wagyu'">
                    <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <app-consultar-laudo-dna
                        [laudo]="ocorrenciaAtual.LaudoDna"
                        [pai]="ocorrenciaAtual.Pai"
                        [mae]="ocorrenciaAtual.Mae"
                        (laudoEncontrado)="laudoSelecionado($event)"
                        [readonly]="
                          !ocorrenciaAtual.Pai.Id ||
                          ocorrenciaAtual.Pai.Id === '-1' ||
                          !ocorrenciaAtual.Mae.Id ||
                          ocorrenciaAtual.Mae.Id === '-1'
                        "
                        [permiteVisualizarArquivo]="lote && lote.IsEmDeliberacao">
                      </app-consultar-laudo-dna>
                    </div>
                  </div>

                  <h4>Dados do Nascimento</h4>

                  <div class="row">
                    <div class="form-group col-lg-2 col-md-4 col-sm-6 col-xs-12">
                      <label for="TipoPadreacao">Tipo Registro</label>
                      <select
                        class="form-control form-control-sm"
                        name="TipoRegistro"
                        [(ngModel)]="ocorrenciaAtual.TipoRegistro"
                        [disabled]="isOcorrenciaReadOnly()">
                        <option value="1" selected="">Nascimento</option>
                        <option value="2">Aborto</option>
                        <option value="3">Natimorto</option>
                      </select>
                    </div>

                    <div class="form-group col-lg-2 col-md-4 col-sm-6 col-xs-12">
                      <label for="formulario">{{
                        env.contexto === 'jersey' ? '*Orelha Direita' : '*Número Particular'
                      }}</label>
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        name="OrelhaDireita"
                        [(ngModel)]="ocorrenciaAtual.OrelhaDireita"
                        [disabled]="isOcorrenciaReadOnly()"
                        (focusout)="handleOrelhaDireitaChange()" />
                    </div>

                    <!-- p/ wagyu - brinco -->
                    <div *ngIf="env.contexto === 'wagyu'" class="form-group col-lg-2 col-md-4 col-sm-6 col-xs-12">
                      <label for="protocolo">*Brinco</label>
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        name="brinco"
                        [(ngModel)]="ocorrenciaAtual.Brinco"
                        [disabled]="isOcorrenciaReadOnly()"
                        (focusout)="ocorrenciaAtual.Nome = ''" />
                    </div>

                    <div
                      class="form-group col-md-8 col-sm-6 col-xs-12"
                      [ngClass]="{ 'col-lg-6': env.contexto === 'wagyu', 'col-lg-4': env.contexto === 'jersey' }">
                      <label for="formulario">
                        {{
                          env.contexto === 'jersey'
                            ? '*Nome (Digite o nome do animal com afixo)'
                            : '*Nome (Digite o nome do animal sem afixo)'
                        }}
                      </label>
                      <div class="input-group input-group input-group-sm">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          name="Nome"
                          [(ngModel)]="ocorrenciaAtual.Nome"
                          [disabled]="isOcorrenciaReadOnly() && !permiteEdicaoNome()"
                          (focusout)="simplyformat(ocorrenciaAtual.Nome)"
                          (focusin)="ocorrenciaAtual.Nome = ''" />
                        <div class="input-group-append">
                          <button
                            [disabled]="isReadOnly"
                            matTooltip="Limpar Nome do Animal"
                            class="btn btn-outline-secondary"
                            type="button"
                            (click)="handleLimparNome()">
                            <i class="bi bi-eraser"></i>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-lg-2 col-md-4 col-sm-6 col-xs-12">
                      <label for="TipoPadreacao">*Sexo</label>
                      <select
                        class="form-control form-control-sm"
                        name="Sexo"
                        [(ngModel)]="ocorrenciaAtual.Sexo"
                        [disabled]="isOcorrenciaReadOnly()">
                        <option value="1">Macho</option>
                        <option value="2" selected="">Fêmea</option>
                      </select>
                    </div>

                    <!-- p/ wagyu - peso ao nascer -->
                    <div *ngIf="env.contexto === 'wagyu'" class="form-group col-lg-2 col-md-6 col-sm-6 col-xs-12">
                      <label for="protocolo">*Peso ao nascer</label>
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        name="peso_nascer"
                        [(ngModel)]="ocorrenciaAtual.Peso"
                        [disabled]="isOcorrenciaReadOnly()" />
                    </div>

                    <!-- p/ wagyu - raca -->
                    <div *ngIf="env.contexto === 'wagyu'" class="form-group col-lg-2 col-md-6 col-sm-6 col-xs-12">
                      <label for="formularioProtocolo">*Raça</label>
                      <select
                        [disabled]="isOcorrenciaReadOnly()"
                        class="form-control form-control-sm"
                        name="raca"
                        [(ngModel)]="ocorrenciaAtual.Raca">
                        <option *ngFor="let raca of racas" [value]="raca.value" [label]="raca.label"></option>
                      </select>
                    </div>

                    <!-- p/ wagyu - local de nascimento -->
                    <div
                      *ngIf="env.contexto === 'wagyu' && !isOcorrenciaReadOnly()"
                      class="form-group col-lg-4 col-md-8 col-sm-6 col-xs-12">
                      <label for="formularioProtocolo">*Local de Nascimento</label>
                      <select
                        [disabled]="isOcorrenciaReadOnly()"
                        class="form-control form-control-sm"
                        name="local_nascimento"
                        [(ngModel)]="ocorrenciaAtual.Fazenda.Id">
                        <option
                          *ngFor="let local of locaisNascimento"
                          [value]="local.value"
                          [label]="local.label"></option>
                      </select>
                    </div>
                    <div
                      *ngIf="env.contexto === 'wagyu' && isOcorrenciaReadOnly()"
                      class="form-group col-lg-4 col-md-8 col-sm-6 col-xs-12">
                      <label for="formularioProtocolo">*Local de Nascimento</label>
                      <input
                        disabled
                        type="text"
                        class="form-control form-control-sm"
                        name="local_nascimento"
                        [(ngModel)]="ocorrenciaAtual.Fazenda.Nome" />
                    </div>

                    <div class="form-group col-lg-2 col-md-4 col-sm-6 col-xs-12">
                      <label for="TipoPadreacao">*Animal para Registrar?</label>
                      <select
                        class="form-control form-control-sm"
                        name="AnimalRegistrar"
                        [(ngModel)]="ocorrenciaAtual.AnimalRegistrar"
                        [disabled]="isOcorrenciaReadOnly()">
                        <option value="1">Sim</option>
                        <option value="2" selected="">Não</option>
                      </select>
                    </div>
                  </div>
                  <!--parto gemelar-->
                  <div>
                    <div class="row">
                      <div class="form-group col-lg-2 col-md-4 col-sm-6 col-xs-12">
                        <label for="isGemelar">*Parto gemelar?</label>
                        <select
                          class="form-control form-control-sm"
                          name="IsGemelar"
                          [(ngModel)]="ocorrenciaAtual.IsGemelar"
                          [disabled]="isOcorrenciaReadOnly()"
                          (ngModelChange)="simplyformat(nome)">
                          <option value="1">Sim</option>
                          <option value="2" selected="">Não</option>
                        </select>
                      </div>
                    </div>

                  

                    <!--Animal gemeo-->
                    <div *ngIf="ocorrenciaAtual.IsGemelar == 1">
                        <!-- p/ wagyu - laudo dna  -->
                      <div class="row">
                        <div class="form-group col-lg-2 col-md-4 col-sm-6 col-xs-12">
                          <label for="TipoPadreacao">Tipo Registro</label>
                          <select
                            class="form-control form-control-sm"
                            name="TipoRegistroGemeo"
                            [(ngModel)]="ocorrenciaAtual.TipoRegistroGemeo"
                            [disabled]="isOcorrenciaReadOnly()">
                            <option value="1" selected="">Nascimento</option>
                            <option value="2">Aborto</option>
                            <option value="3">Natimorto</option>
                          </select>
                        </div>
    
                        <div class="form-group col-lg-2 col-md-4 col-sm-6 col-xs-12">
                          <label for="formulario">{{
                            env.contexto === 'jersey' ? '*Orelha Direita' : '*Número Particular'
                          }}</label>
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            name="OrelhaDireitaGemeo"
                            [(ngModel)]="ocorrenciaAtual.OrelhaDireitaGemeo"
                            [disabled]="isOcorrenciaReadOnly()"
                            (focusout)="handleOrelhaDireitaChangeGemeo()" />
                        </div>
    
                        <!-- p/ wagyu - brinco -->
                        <div *ngIf="env.contexto === 'wagyu'" class="form-group col-lg-2 col-md-4 col-sm-6 col-xs-12">
                          <label for="protocolo">*Brinco</label>
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            name="brincoGemeo"
                            [(ngModel)]="ocorrenciaAtual.BrincoGemeo"
                            [disabled]="isOcorrenciaReadOnly()"
                            (focusout)="ocorrenciaAtual.NomeGemeo = ''" />
                        </div>
    
                        <div
                          class="form-group col-md-8 col-sm-6 col-xs-12"
                          [ngClass]="{ 'col-lg-6': env.contexto === 'wagyu', 'col-lg-4': env.contexto === 'jersey' }">
                          <label for="formulario">
                            {{
                              env.contexto === 'jersey'
                                ? '*Nome (Digite o nome do animal com afixo)'
                                : '*Nome (Digite o nome do animal sem afixo)'
                            }}
                          </label>
                          <div class="input-group input-group input-group-sm">
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              name="NomeGemeo"
                              [(ngModel)]="ocorrenciaAtual.NomeGemeo"
                              [disabled]="isOcorrenciaReadOnly() && !permiteEdicaoNome()"
                              (focusout)="simplyformatGemeo(ocorrenciaAtual.NomeGemeo)"
                              (focusin)="ocorrenciaAtual.NomeGemeo = ''" />
                            <div class="input-group-append">
                              <button
                                [disabled]="isReadOnly"
                                matTooltip="Limpar Nome do Animal"
                                class="btn btn-outline-secondary"
                                type="button"
                                (click)="handleLimparNomeGemeo()">
                                <i class="bi bi-eraser"></i>
                              </button>
                            </div>
                          </div>
                        </div>
    
                        <div class="form-group col-lg-2 col-md-4 col-sm-6 col-xs-12">
                          <label for="TipoPadreacao">*Sexo</label>
                          <select
                            class="form-control form-control-sm"
                            name="SexoGemeo"
                            [(ngModel)]="ocorrenciaAtual.SexoGemeo"
                            [disabled]="isOcorrenciaReadOnly()">
                            <option value="1">Macho</option>
                            <option value="2" selected="">Fêmea</option>
                          </select>
                        </div>
    
                        <!-- p/ wagyu - peso ao nascer -->
                        <div *ngIf="env.contexto === 'wagyu'" class="form-group col-lg-2 col-md-6 col-sm-6 col-xs-12">
                          <label for="protocolo">*Peso ao nascer</label>
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            name="peso_nascerGemeo"
                            [(ngModel)]="ocorrenciaAtual.PesoGemeo"
                            [disabled]="isOcorrenciaReadOnly()" />
                        </div>
    
                        <!-- p/ wagyu - raca -->
                        <div *ngIf="env.contexto === 'wagyu'" class="form-group col-lg-2 col-md-6 col-sm-6 col-xs-12">
                          <label for="formularioProtocolo">*Raça</label>
                          <select
                            [disabled]="isOcorrenciaReadOnly()"
                            class="form-control form-control-sm"
                            name="racaGemeo"
                            [(ngModel)]="ocorrenciaAtual.RacaGemeo">
                            <option *ngFor="let raca of racas" [value]="raca.value" [label]="raca.label"></option>
                          </select>
                        </div>
    
                        <!-- p/ wagyu - local de nascimento 
                        <div
                          *ngIf="env.contexto === 'wagyu' && !isOcorrenciaReadOnly()"
                          class="form-group col-lg-4 col-md-8 col-sm-6 col-xs-12">
                          <label for="formularioProtocolo">*Local de Nascimento</label>
                          <select
                            [disabled]="isOcorrenciaReadOnly()"
                            class="form-control form-control-sm"
                            name="local_nascimento"
                            [(ngModel)]="ocorrenciaAtual.Fazenda.Id">
                            <option
                              *ngFor="let local of locaisNascimento"
                              [value]="local.value"
                              [label]="local.label"></option>
                          </select>
                        </div>
                        <div
                          *ngIf="env.contexto === 'wagyu' && isOcorrenciaReadOnly()"
                          class="form-group col-lg-4 col-md-8 col-sm-6 col-xs-12">
                          <label for="formularioProtocolo">*Local de Nascimento</label>
                          <input
                            disabled
                            type="text"
                            class="form-control form-control-sm"
                            name="local_nascimento"
                            [(ngModel)]="ocorrenciaAtual.Fazenda.Nome" />
                        </div>
                        -->
                        <div class="form-group col-lg-2 col-md-4 col-sm-6 col-xs-12">
                          <label for="TipoPadreacao">*Animal para Registrar?</label>
                          <select
                            class="form-control form-control-sm"
                            name="AnimalRegistrarGemeo"
                            [(ngModel)]="ocorrenciaAtual.AnimalRegistrarGemeo"
                            [disabled]="isOcorrenciaReadOnly()">
                            <option value="1">Sim</option>
                            <option value="2" selected="">Não</option>
                          </select>
                        </div>
                      </div>
                      <div class="row" *ngIf="env.contexto === 'wagyu'">
                        <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <app-consultar-laudo-dna
                            [laudo]="ocorrenciaAtual.LaudoDnaGemeo"
                            [pai]="ocorrenciaAtual.Pai"
                            [mae]="ocorrenciaAtual.Mae"
                            (laudoEncontrado)="laudoSelecionadoGemeo($event)"
                            [readonly]="
                              !ocorrenciaAtual.Pai.Id ||
                              ocorrenciaAtual.Pai.Id === '-1' ||
                              !ocorrenciaAtual.Mae.Id ||
                              ocorrenciaAtual.Mae.Id === '-1'
                            "
                            [permiteVisualizarArquivo]="lote && lote.IsEmDeliberacao">
                          </app-consultar-laudo-dna>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-12">
              <app-botoes-acoes-ocorrencia
                [lote]="lote"
                [idOcorrencia]="ocorrenciaAtual.Id"
                (acaoSelecionada)="acaoBotao($event)"
                [permiteEdicaoNome]="permiteEdicaoNome()"
                [temporarioEdicaoEventoAprovado]="temporarioEdicaoEventoAprovado"
                tipoEvento="nascimento"></app-botoes-acoes-ocorrencia>
            </div>
          </div>

          <!--Lista de Ocorrencias-->
          <div>
            <div *ngIf="ocorrencias.length > 0" class="row" style="margin-top: 15px">
              <div class="col-lg-12 table-responsive">
                <table class="table table-hover table-header-custom">
                  <thead>
                    <tr>
                      <th>Tipo</th>
                      <th>Mãe</th>
                      <th>Pai</th>
                      <th>Nome do Animal</th>
                      <th>Nascimento</th>
                      <th>Sexo</th>
                      <th class="text-center">Data Cobertura/Padreação</th>
                      <th>{{ env.contexto === 'jersey' ? 'Orelha Direita' : 'Número Particular' }}</th>
                      <th *ngIf="env.contexto === 'wagyu'">Brinco</th>
                      <th *ngIf="env.contexto === 'wagyu'">Raça</th>
                      <th>Situação</th>
                      <th width="10%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="let ev of ocorrencias"
                      [ngClass]="{
                        'ocorrencia-em-destaque': ev.Id === ocorrenciaAtual.Id
                      }">
                      <td>{{ commons.getTipoRegistro(ev.TipoRegistro) }}</td>
                      <td>
                        {{ ev.MaeCadastrada == 1 ? ev.Mae.CodigoNomeBovino : ev.CodigoNomeMaeNaoCadastrada }}
                      </td>
                      <td>
                        {{ ev.PaiCadastrado == 1 ? ev.Pai.CodigoNomeBovino : ev.CodigoNomePaiNaoCadastrado }}
                      </td>
                      <td>{{ ev.Nome }}</td>
                      <td>{{ ev.DataNascimento | date : 'dd/MM/yyyy' }}</td>
                      <td>{{ commons.getDescricaoSexo(ev.Sexo) }}</td>
                      <td class="text-center">
                        {{
                          (ev.OcorrenciaEventoPadreacao.Id !== '-1'
                            ? ev.OcorrenciaEventoPadreacao.DataOcorrencia
                            : ev.OcorrenciaEventoInovulacao.DataTransferencia
                          ) | date : 'dd/MM/yyyy'
                        }}
                      </td>
                      <td>{{ ev.OrelhaDireita }}</td>
                      <td *ngIf="env.contexto === 'wagyu'">{{ ev.Brinco }}</td>
                      <td *ngIf="env.contexto === 'wagyu'">{{ getLabelRaca(ev.Raca) }}</td>
                      <td>{{ commons.getDescricaoSituacao(ev.Situacao) }}</td>
                      <td class="text-center text-nowrap acoes-evento">
                        <i *ngIf="ev.ImputaMulta === 1" matTooltip="Multa Imputada" class="fas fa-dollar-sign"></i>
                        <i
                          *ngIf="ev.SolicitarDna === 1"
                          matTooltip="Será solicitado DNA no Nascimento"
                          class="fas fa-dna">
                        </i>
                        <i *ngIf="permiteEdicao(ev.Situacao)" class="fas fa-edit" (click)="editar(ev)"></i>
                        <i *ngIf="!permiteEdicao(ev.Situacao)" class="fas fa-eye" (click)="editar(ev)"></i>
                        <i *ngIf="lote.Situacao === 1" class="fas fa-trash-alt" (click)="deletar(ev)"></i>
                        <i
                          *ngIf="ev.IndInconsitencia"
                          class="fa fa-exclamation-triangle"
                          style="cursor: pointer"
                          matTooltip="{{ ev.Inconsistencia }}"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
