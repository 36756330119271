import { TipoPeriodoFixoConsulta } from "../shared/enums/Enums";
import { CriadorModel } from "./CriadorModel";

export class ConsultaSubmetidosModel {
  periodo: TipoPeriodoFixoConsulta;
  dataInicial: string;
  dataFinal: string;
  criador: CriadorModel;
  filiadaId: string;
  filiadaReadonly: boolean;
  associacao: string;

  constructor() {
    this.periodo =  TipoPeriodoFixoConsulta.TODOS;
    this.dataInicial =  '';
    this.dataFinal =  '';
    this.criador =  new CriadorModel();
    this.filiadaId =  '';
    this.filiadaReadonly = true;
    this.associacao =  '';
  }
}