import { AnimalCadastrado } from "../shared/enums/Enums";
import { BovinoModel } from "./BovinoModel";

export class ConsultaSugestaoParaNasciomentoModel {
  CriadorId: string;
  DataNascimento: string;
  SugestaoDe: string;
  SugestaoAte: string;
  Mae: BovinoModel;
  TituloConsulta: string;
  CodigoNomeBovino: string;
  Prefix: string;
  AnimalCadastrado: AnimalCadastrado;

  constructor() {
    this.CriadorId = '-1';
    this.DataNascimento = '';
    this.AnimalCadastrado = AnimalCadastrado.Sim;
    this.Prefix = '';
    this.SugestaoDe = '';
    this.SugestaoAte = '';
    this.CodigoNomeBovino = '';
    this.TituloConsulta = 'Busca Padreação / Inovulação';

    this.Mae = new BovinoModel();
    this.Mae.TituloConsulta = 'Mãe';
    this.Mae.ConsideraInativos = true;
  }
}