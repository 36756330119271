export class AlterarSenhaModel{

  senhaAtual: string;
  senhaNova: string;
  confirmacaoSenhaNova: string;

  constructor(){
    this.senhaAtual = "";
    this.senhaNova = "";
    this.confirmacaoSenhaNova = "";
  }
}
