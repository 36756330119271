import { AccountService } from 'src/app/account/shared/account.service';
import { Commons } from 'src/app/shared/commons';
import { ApiService } from 'src/app/shared/api.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { BaseEventos } from 'src/app/eventos/base-eventos';
import { ConstantesTipoEvento } from 'src/app/shared/constantes';
import { MatDialog } from '@angular/material/dialog';

import { environment } from 'src/environments/environment';
import { TipoMotivoMorteNatural, TipoMotivoBaixa } from 'src/app/shared/enums/Enums';


@Component({
  selector: 'app-baixa-manter',
  templateUrl: './baixa-manter.component.html',
  styleUrls: ['./baixa-manter.component.scss'],
  providers: [
  ],
})

export class BaixaManterComponent extends BaseEventos implements OnInit {
  isReadOnly: boolean = false;

  readonly env = environment;

  motivos: { label: string; value: TipoMotivoBaixa; }[] = [];
  causasMorteNatural: { label: string; value: TipoMotivoMorteNatural; }[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    public accountService: AccountService,
    public api: ApiService,
    public datePipe: DatePipe,
    public commons: Commons,
    public router: Router,
    public dialog: MatDialog
  ) {
    super(api, datePipe, commons, router, accountService, dialog);
    this.tipoEvento = ConstantesTipoEvento.BAIXA;
  }

  ngOnInit(): void {
    this.novoEvento();

    this.activatedRoute.paramMap.subscribe(params => {
      this.iniciarTela(params).then(async () => {
        this.ocorrenciaAtual.TipoMotivoBaixa = -1;
        //this.setFrigorifico(this.ocorrenciaAtual);

      });
    });

    this.preencherTiposMotivo();
    this.preencherCausasMorteNatural();
  }

  private preencherTiposMotivo(): void {
    this.motivos = [];
    this.motivos.push({ value: -1, label: 'Selecione...' });

    if ((this.env as any).contexto === 'jersey') {
      this.motivos.push({ value: TipoMotivoBaixa.DESCARTE, label: 'Descarte' });
      this.motivos.push({ value: TipoMotivoBaixa.ENVENENAMENTO, label: 'Envenenamento' });
      this.motivos.push({ value: TipoMotivoBaixa.PICADA_DE_COBRA, label: 'Picada de Cobra' });
    }

    this.motivos.push({ value: TipoMotivoBaixa.MORTE_NATURAL, label: 'Morte' });
    this.motivos.push({ value: TipoMotivoBaixa.BAIXA_POR_VENDA, label: 'Baixa por Venda' });
  }

  private preencherCausasMorteNatural(): void {
    this.causasMorteNatural = [];
    this.causasMorteNatural.push({ value: -1, label: 'Selecione...' });

    Object.values(TipoMotivoMorteNatural).map((item: any) => {
      if (!isNaN(item) && item > 0)
        this.causasMorteNatural.push({ value: item, label: this.commons.getDescricaoMotivoMorte(item) });
    });
  }

  private setFrigorifico(ev: any) {
    this.ocorrenciaAtual.Frigorifico.id = ev.Frigorifico.Id;
    this.ocorrenciaAtual.Frigorifico.usuarioId = ev.Frigorifico.Id;
    this.ocorrenciaAtual.Frigorifico.nome = ev.Frigorifico.NomeCriador;
    this.ocorrenciaAtual.Frigorifico.codigo = ev.Frigorifico.CodigoCriador;
    this.ocorrenciaAtual.Frigorifico.afixo = ev.Frigorifico.AfixoCriador;
    this.ocorrenciaAtual.Frigorifico.readonly = super.isOcorrenciaReadOnly();
    this.ocorrenciaAtual.Frigorifico.tituloConsulta = 'Frigorífico';

  }

  editar(evento: any) {
    super.editar(evento);
    this.setFrigorifico(evento);
  }

  frigorificoSelecionado(ev: any) {
    this.ocorrenciaAtual.Frigorifico.id = ev;
    this.ocorrenciaAtual.Frigorifico.usuarioId = ev;
  }
}
