export class DocumentoFSCModel{
  Id: string;
  Descricao: string;
  Nome: string;
  Length: number;
  Content: string;

  constructor(){
    this.Id = "";
    this.Descricao = "";
    this.Nome = "";
    this.Content = "";
    this.Length = -1;
  }
}