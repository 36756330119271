<!-- afixos para wagyu -->
<div class="row" *ngIf="env.contexto === 'wagyu'"> 

  <div class="row form-group col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding-right: 0;" *ngIf="permiteAdicionarAfixo()">
    <div class="form-group custom-pad-col-xs col-lg-4 col-md-3 col-sm-3 col-xs-12">
      <label>Posição afixo</label>
      <select [disabled]="readOnly" class="form-control form-control-sm" name="posicao_afixo" [(ngModel)]="criador.posicaoAfixo">
        <option value="1" label="Prefixo"></option>
        <option value="2" label="Sufixo"></option>
      </select>
    </div>

    <div class="form-group col-lg-3 col-md-3 col-sm-3 col-xs-12" *ngIf="permiteAdicionarAfixo()">
      <label>Afixo Principal</label>
      <select [disabled]="readOnly" class="form-control form-control-sm" name="afixo_principal" [(ngModel)]="afixoPrincipal">
        <option value="1" label="Sim"></option>
        <option value="2" label="Não"></option>
      </select>
    </div>
    
    <div class="form-group col-lg-5 col-md-6 col-sm-6 col-xs-12" style="padding-right: 0;" *ngIf="permiteAdicionarAfixo()">
      <label>Afixo selecionado (Use esse campo para adicionar um novo afixo)</label>
      <div class="input-group input-group-sm">
        <input [disabled]="readOnly" type="text" name="afixo" [(ngModel)]="criador.afixo" class="form-control form-control-sm">
        <div class="input-group-append">
          <button [disabled]="readOnly" class="btn btn-outline-secondary" type="button" (click)="botaoValidarAfixo()">
            <i class="bi bi-check"></i>
          </button>
        </div>
      </div>
      <span style="color: red;"> *Ao adicionar um novo afixo, salve o perfil usando o botão "Enviar" na parte inferior da tela.</span>
    </div>
    
  </div>
</div>
<div class="row">
  <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
    <label>Afixo(s) disponíveis</label>
    <div class="input-group input-group-sm">
      <select class="form-control form-control-sm" [(ngModel)]="afixoSelecionado" (click)="avisoAfixo()" (ngModelChange)="alterarAfixoPrincipal(criador.codigo, afixoSelecionado)">
        <option *ngFor="let afixo of criador.afixos" [value]="afixo.Id" [label]="getLabelAfixo(afixo)"></option>
      </select>
      <!-- <div class="input-group-append">
        <button type="button" matTooltip="Salvar Afixo Principal"
          class="btn btn-outline-secondary"
          [disabled]="readOnly"
          (click)="alterarAfixoPrincipal(criador.codigo, afixoSelecionado)">
        <i class="bi bi-check"></i>
      </button>
      </div> -->
    </div>
    <span style="color: red;"> *Escolha qual será seu afixo principal.</span>
  </div>
</div>



<!-- afixo para jersey -->
<div class="row" *ngIf="env.contexto === 'jersey'">

  <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
    <label>Afixo</label>
    <div class="input-group input-group-sm">
      <input [disabled]="readOnly" type="text" name="afixo" [(ngModel)]="criador.afixo" class="form-control form-control-sm">
      <div class="input-group-append">
        <button [disabled]="readOnly" class="btn btn-outline-secondary" type="button" (click)="botaoValidarAfixo()">
          <i class="bi bi-check"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
    <label for="formularioProtocolo">Posição afixo</label>
    <select [disabled]="readOnly" class="form-control form-control-sm" name="posicao_afixo" [(ngModel)]="criador.posicaoAfixo">
      <option value="1" label="Prefixo"></option>
      <option value="2" label="Sufixo"></option>
    </select>
  </div>

</div>
