import { Sexo, SiglaPais, TipoBuscaBovino, TipoFiv } from "../shared/enums/Enums";
import { ConsultaRapidaBovinoModel } from "./ConsultaRapidaBovinoModel";
import { OcorrenciaEventoModel } from "./OcorrenciaEventoModel";

export class OcorrenciaEventoEmbriaoModel extends OcorrenciaEventoModel {
  
    TipoFIV: TipoFiv;
    QuantidadeViaveis: number;
    PaisOrigem: SiglaPais;
    CodigoCentral: string;
    DataCobertura: string;
    DataCongelamento: string;
    Doadora: ConsultaRapidaBovinoModel;
    Touro: ConsultaRapidaBovinoModel;
    
    constructor(){
        super();

        this.TipoFIV = TipoFiv.FIV;
        this.QuantidadeViaveis = 0;
        this.PaisOrigem = SiglaPais.None;
        this.CodigoCentral = "";
        this.DataCobertura = "";
        this.DataCongelamento = "";
        this.Doadora = new ConsultaRapidaBovinoModel(); 
        this.Touro = new ConsultaRapidaBovinoModel(); 

        this.Doadora.TituloConsulta = "Doadora";
        this.Doadora.TipoBusca = TipoBuscaBovino.Matriz;
        this.Doadora.SexoBovino = Sexo.Fêmea;
        this.Doadora.ConsideraInativos = false;
        this.Doadora.ConsultaAnimalTodosCriadores = false;

        this.Touro.TituloConsulta = "Touro";
        this.Touro.TipoBusca = TipoBuscaBovino.Macho;
        this.Touro.SexoBovino = Sexo.Macho;
        this.Touro.ConsideraInativos = false;
        this.Touro.ConsultaAnimalTodosCriadores = false;
    }
}