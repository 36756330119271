import { InspetorModel } from 'src/app/models/InspetorModel';
import { AnimalCadastrado, Frigorifico, Sexo, TipoBuscaBovino, TipoCertificadoBaixa, TipoMotivoBaixa, TipoMotivoMorteNatural } from "../shared/enums/Enums";
import { ConsultaRapidaBovinoModel } from "./ConsultaRapidaBovinoModel";
import { CriadorModel } from './CriadorModel';
import { OcorrenciaEventoModel } from "./OcorrenciaEventoModel";

export class OcorrenciaEventoBaixaModel extends OcorrenciaEventoModel {
  DataBaixa: string;
  Animal: ConsultaRapidaBovinoModel;
  TipoMotivoBaixa: TipoMotivoBaixa;
  CruzadoCadastrado: AnimalCadastrado.Nao;
  NomeAnimalCruzado: string;
  CodigoAnimalCruzado: string;
  MotivoMorte: TipoMotivoMorteNatural;
  PesoVivo: string;
  PesoCarcaca: string;
  NroDentes: number;
  Gordura: number;
  Marmoreio: string;
  CertificadoBaixa: TipoCertificadoBaixa;
  MotivoReprova: string;
  Inspetor: InspetorModel;
  Frigorifico: CriadorModel;
  DataAbate: string;
  QuantidadeSelos: number;
  PreAbate: string;
  IdadeAnimalCruzado: number;
  SexoAnimalCruzado: Sexo;
  CompRacial: string;

  FrigorificoAbate: CriadorModel;
  HasPreAbate: boolean;
  OrdemRomaneio: number;
  PossuiPreAbate: string;

  constructor() {
    super();

    this.DataBaixa = "";
    this.TipoMotivoBaixa = TipoMotivoBaixa.ABATE_FRIGORIFICO;
    this.CruzadoCadastrado = AnimalCadastrado.Nao;
    this.NomeAnimalCruzado = "";
    this.CodigoAnimalCruzado = "";
    this.MotivoMorte = TipoMotivoMorteNatural.None;
    this.PesoVivo = "";
    this.PesoCarcaca = "";
    this.NroDentes = 1;
    this.Gordura = 1.00;
    this.Marmoreio = "1";
    this.CertificadoBaixa = TipoCertificadoBaixa.Aprovado;
    this.MotivoReprova = "";

    this.Frigorifico = new CriadorModel();
    this.Inspetor = new InspetorModel();

    this.Frigorifico.frigorifico = Frigorifico.Sim;
    this.Frigorifico.tituloConsulta = 'Frigorífico';

    this.Animal = new ConsultaRapidaBovinoModel();
    this.Animal.TituloConsulta = "Animal";
    this.Animal.TipoBusca = TipoBuscaBovino.Todos;
    this.Animal.ConsultaAnimalTodosCriadores = false;
    this.DataAbate = "";
    this.QuantidadeSelos = 0;
    this.PreAbate = "";
    this.SexoAnimalCruzado = Sexo.Macho;
    this.IdadeAnimalCruzado = 0;
    this.CompRacial = "";

    this.FrigorificoAbate = new CriadorModel();
    this.HasPreAbate = true;
    this.OrdemRomaneio = 0;
    this.PossuiPreAbate = '1';

  }
}