import { CriadorModel } from 'src/app/models/CriadorModel';


export class FiltroConsultaCertificadosPendentesModel{
  
    criador: CriadorModel;
    vinculacao: string;
  
    constructor(){
        this.criador = new CriadorModel();
        this.vinculacao = '';
    }
  }
  