import { TipoTelefone } from "../shared/enums/Enums";

export class NumeroTelefonicoModel {

  tipo: TipoTelefone;
  ramal: string;
  ddd: number | string;
  numero: number | string;

  constructor() {
    this.ddd = '';
    this.numero = '';
    this.tipo = TipoTelefone.Celular;
    this.ramal = '';
  }
}
