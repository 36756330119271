import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { BovinoModel } from 'src/app/models/BovinoModel';
import { FiltroConsultaLaudoDnaModel } from 'src/app/models/FiltroConsultaLaudoDnaModel';
import { LaudoDNAModel } from 'src/app/models/LaudoDNAModel';
import Swal from 'sweetalert2';
import { ApiService } from '../api.service';
import { Commons } from '../commons';
import { ConsultarLaudoDnaModalComponent } from '../consultar-laudo-dna-modal/consultar-laudo-dna-modal.component';
import { IItemLaudoDna, IJsonResultModel, IResponseRecuperarLaudoPorId, IResponseVisualizarLaudoDna } from '../interfaces/Interfaces';

@Component({
  selector: 'app-consultar-laudo-dna',
  templateUrl: './consultar-laudo-dna.component.html',
  styleUrls: ['./consultar-laudo-dna.component.scss']
})
export class ConsultarLaudoDnaComponent implements OnInit {

  @Input()
  readonly: boolean = false;
  @Input()
  permiteVisualizarArquivo: boolean = false;
  @Input()
  laudo: LaudoDNAModel = new LaudoDNAModel();
  @Input()
  resetLaudo: Observable<void> = new Observable<void>();
  @Input()
  recuperarLaudoPorId: Observable<string> = new Observable<string>();
  @Input()
  pai: BovinoModel = new BovinoModel();
  @Input()
  mae: BovinoModel = new BovinoModel();

  @Output()
  laudoEncontrado: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private api: ApiService,
    private dialog: MatDialog,
    private commons: Commons,
  ) { }

  ngOnInit(): void {
    this.resetLaudo.subscribe(() => {
      this.laudo = new LaudoDNAModel();
    });

    this.recuperarLaudoPorId.subscribe((id: string) => {
      this.recuperarLaudo(id);
    });
  }

  private recuperarLaudo(id: string) {
    this.api.recuperarLaudoDnaPorId(id).then((result: IResponseRecuperarLaudoPorId) => {
      if (result && result.success) {

        Object.assign(this.laudo, result);
        this.laudo.NomeAnimal = result.NomeAnimal;
        this.laudo.NumeroLaudo = result.NumeroLaudo;
        Object.assign(this.laudo.Arquivo, result.Arquivo);
        Object.assign(this.laudo.Pai, { ...result.Pai, TituloConsulta: 'Pai' });
        Object.assign(this.laudo.Mae, { ...result.Mae, TituloConsulta: 'Mãe' });

      } else {
        Swal.fire(result.message || 'Não foi possível realizar a operação.', '', 'error');
      }
    });
  }

  consultarPorNumero() {
    if (!this.laudo.NumeroLaudo) return;

    this.api.recuperarLaudoDnaPorNumero(this.laudo).then((result: IResponseRecuperarLaudoPorId) => {
      if (result && result.success) {
        const { Arquivo, ...rest } = result;
        Object.assign(this.laudo, rest);
        Object.assign(this.laudo.Arquivo, Arquivo);

        this.laudoEncontrado.emit(result.Id);
      } else {
        this.laudoEncontrado.emit('');
        this.laudo = new LaudoDNAModel();
        Swal.fire(result.message || 'Não foi possível realizar a operação.', '', 'error');
      }
    });
  }

  async visualizarDocumento() {
    if (!this.laudo.Arquivo.Id || this.laudo.Arquivo.Id === '-1') return;

    await this.api.visualizarLaudoDna(this.laudo.Arquivo.Id).then((result: IJsonResultModel<IResponseVisualizarLaudoDna>) => {
      if (result && result.Data && result.Data.success) {
        this.commons.visualizarBase64EmPdf(result.Data.content, result.ContentType || '');
      } else {
        Swal.fire(result.Data?.message || 'Não foi possível realizar a operação.', '', 'error');
      }
    });
  }

  limpar() {
    this.laudo = new LaudoDNAModel();
    this.laudo.Id = "-1";
    this.laudoEncontrado.emit('-1');
  }

  consultaAvancada() {
    const formConsulta = new FiltroConsultaLaudoDnaModel();

    // Procura apenas laudos relacionados aos animais passados para o componente
    if (this.mae.Id !== "-1" && this.pai.Id !== "-1") {
      formConsulta.Mae.Id = this.mae.Id;
      formConsulta.Pai.Id = this.pai.Id;
    }

    const dialogRef = this.dialog.open(
      ConsultarLaudoDnaModalComponent,
      {
        height: '90%',
        width: '90%',
        autoFocus: false,
        data: formConsulta
      }
    );

    dialogRef.componentInstance.laudoSelecionado.subscribe((result: IItemLaudoDna) => {
      if (result) {
        const { Arquivo, NumeroLaudo, ...rest } = result;
        this.laudo.NumeroLaudo = NumeroLaudo;
        Object.assign(this.laudo, rest);
        Object.assign(this.laudo.Arquivo, Arquivo);

        this.laudoEncontrado.emit(result.Id);
      }
    });
  }

}
