import { environment } from "src/environments/environment";

export class FiltroConsultaCriadoresModel{
  cpf_cnpj: string;
  tipoUsuario: number;
  codigo: string;
  nome: string;
  situacao: number;
  vinculacao: string;
  afixo: string;
  filiadaReadonly: boolean;

  constructor(){
    this.cpf_cnpj = "";
    this.tipoUsuario = -1;
    this.codigo = "";
    this.nome = "";
    this.situacao = -1;
    this.vinculacao = (environment as any).contexto === 'wagyu'? 'PJ_ACGWB' : '';
    this.afixo = "";
    this.filiadaReadonly = true;
  }
}
