<div class="card card-body" style="margin-top: 1em;">

  <div class="row">

    <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
      <label for="formularioProtocolo">*Grupo de Usuário</label>
      <select class="form-control form-control-sm" name="grupo" disabled>
        <option value="formCpfValido.grupo" [label]="labelGrupoUsuario"></option>
      </select>
    </div>

    <div class="form-group col-lg-2 col-md-6 col-sm-6 col-xs-12">
      <label>*CPF</label>
      <input 
        disabled
        name="cpf" 
        type="text" 
        mask="000.000.000-00"
        class="form-control form-control-sm" 
        [ngModel]="formCpfValido.cpf" 
      >
    </div>

    <div *ngIf="['GR_SRG_INSPETOR'].includes(formCpfValido.grupo)" class="form-group col-lg-2 col-md-6 col-sm-6 col-xs-12">
      <label for="codigoInspetor">*Código do Inspetor</label>
      <input [disabled]="readOnly" 
        name="codigoInspetor" type="text" 
        class="form-control form-control-sm" 
        [(ngModel)]="formRegistrar.CRMV_CodInspetor" >
    </div>

    <div *ngIf="['GR_SRG_VETERINARIO'].includes(formCpfValido.grupo)" class="form-group col-lg-2 col-md-6 col-sm-6 col-xs-12">
      <label>*CRMV</label>
      <input [disabled]="readOnly" 
      name="codigo" type="text" 
      class="form-control form-control-sm" 
      [(ngModel)]="formRegistrar.CRMV_CodInspetor" >
    </div>
    <div *ngIf="['GR_SRG_INSPETOR'].includes(formCpfValido.grupo)" class="form-group">
      <label for="tipoInspetor">*Especialização</label>
      <select class="form-control form-control-sm"
        name="tipoInspetor"
        [(ngModel)]="formRegistrar.tipoInspetor"
        [disabled]="readOnly"> 
        <option value="-1">Selecione</option>
        <option value="1">Engenheiro(a) Agrônomo(a)</option>
        <option value="2">Veterinário(a)</option>
        <option value="3">Zootecnista</option>
      </select>
    </div>


    <div *ngIf="formRegistrar.tipoInspetor != -1" class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
      <label *ngIf="formRegistrar.tipoInspetor == 1" for="CRMV_CodInspetor">*CREA</label>
      <label *ngIf="formRegistrar.tipoInspetor == 2" for="CRMV_CodInspetor">*CRMV</label>
      <label *ngIf="formRegistrar.tipoInspetor == 3" for="CRMV_CodInspetor">*CRMVZ</label>
      <input name="CRMV_CodInspetor" type="text" 
        class="form-control form-control-sm"
        [(ngModel)]="formRegistrar.codigoEspecializacaoInspetor"
        [disabled]="readOnly">
    </div>

    <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12" *ngIf="formCpfValido.grupo === 'GR_SRG_TECNICO'">
      <label for="formularioProtocolo">Código Técnico de Abate</label>
      <input name="codigoTecnicoDeAbate" type="text" 
        class="form-control form-control-sm"
        [(ngModel)]="formRegistrar.CRMV_CodInspetor"
        [disabled]="readOnly">
    </div>
    <div 
      [ngClass]="{
        'col-md-6 col-sm-6 col-xs-12': true,
        'form-group col-lg-7': grupoAdmJersey,
        'form-group col-lg-5': !grupoAdmJersey
      }"
      *ngIf="contexto == 'jersey'"
    >
      <app-selecionar-filiadas 
        [readonly]="true" 
        [filiadaId]="formCpfValido.vinculacao" 
        defaultFirstSelectedLabel="Selecione..."
      >
      </app-selecionar-filiadas>
    </div>

    <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
      <label>Código</label>
      <input 
        disabled
        name="codigo" 
        type="text" 
        class="form-control form-control-sm" 
        [(ngModel)]="formRegistrar.codigo"
      >
    </div>

    <!-- <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12" *ngIf="formCpfValido.grupo === 'GR_SRG_TECNICO'">
      <label for="formularioProtocolo">Vinculação à frigorífico</label>
      <select class="form-control form-control-sm" name="vinculacaoFrigorifico" [(ngModel)]="formRegistrar.vinculacaoFrigorifico">
        <option value="1" label="Sim"></option>
        <option value="0" label="Não"></option>
      </select>
    </div> -->

    <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
      <label>*Nome</label>
      <input
        [disabled]="readOnly"
        name="nome" type="text" class="form-control form-control-sm" [(ngModel)]="formRegistrar.nome" >
    </div>

    <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
      <label>*E-mail</label>
      <input [disabled]="readOnly" name="email" type="text" class="form-control form-control-sm" [(ngModel)]="formRegistrar.email" >
    </div>

    <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
      <label>*Usuário</label>
      <input [disabled]="readOnly" name="usuario" type="text" class="form-control form-control-sm" [(ngModel)]="formRegistrar.usuario" >
    </div>

    <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
      <label for="formularioProtocolo">Situação</label>
      <select [disabled]="readOnly" class="form-control form-control-sm" name="situacao" [(ngModel)]="formRegistrar.situacao">
        <option value="1" label="Ativo"></option>
        <option value="2" label="Inativo"></option>
      </select>
    </div>

  </div>

  <app-endereco-manter [readOnly]="readOnly" [endereco]="formRegistrar.endereco"></app-endereco-manter>

  <app-telefones-manter [readOnly]="readOnly" [celular]="formRegistrar.celular" [residencial]="formRegistrar.residencial"></app-telefones-manter>

  <div *ngIf='!readOnly' class="row justify-content-end" id="botao-container">
    
    <div class="col-lg-3 col-md-10 col-sm-10 col-xs-12">
      <button 
        type="button" 
        (click)="botaoEnviar()"
        class="btn btn-sm btn-block btn-primary"
      >
        Enviar
      </button>
    </div>
  
  </div>

</div>