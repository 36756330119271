<div class="row">

  <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
    <label>Tipo</label>
    <select name="situacao" class="form-control form-control-sm" [(ngModel)]="formValidaCriador.tipoPessoa">
      <option value="1" label="Pessoa física"></option>
      <option value="2" label="Pessoa jurídica"></option>
    </select>
  </div>

  <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
    <label>*{{ formValidaCriador.tipoPessoa == 1? "CPF" : "CNPJ"}}</label>
    <input 
      type="text" 
      name="cpf_cnpj" 
      class="form-control form-control-sm"
      [(ngModel)]="formValidaCriador.cpf_cnpj"
      mask="{{formValidaCriador.tipoPessoa == 1? '000.000.000-00' : '00.000.000/0000-00'}}" 
    >
  </div>

  <div *ngIf="env.contexto === 'jersey'" class="form-group col-lg-5 col-md-8 col-sm-8 col-xs-12">
    <app-selecionar-filiadas [readonly]="false" [filiadaId]="formValidaCriador.vinculacao"
      defaultFirstSelectedLabel="Selecione..." (filiadaSelecionada)="filiadaSelecionada($event)">
    </app-selecionar-filiadas>
  </div>

  <div 
    class="botao-container col-xs-12" 
    [ngClass]="{'botao-wagyu col-lg-2 col-md-12 col-sm-12': env.contexto === 'wagyu', 'botao-jersey col-lg-1 col-md-4 col-sm-4': env.contexto === 'jersey'}"
  >
    <button type="button" (click)="botaoValidar()" matTooltip="Consultar pessoa por CPF"
      class="btn btn-sm btn-block btn-primary">
      <i class="fas fa-check"></i>
    </button>
  </div>

</div>