import { SituacaoLoteOcorrencia } from "../shared/enums/Enums";
import { ComunicacaoLoteOcorrenciaModel } from "./ComunicacaoLoteOcorrenciaModel";
import { ConsultaRapidaCriadorModel } from "./ConsultaRapidaCriadorModel";
import { DocumentoFSCModel } from "./DocumentoFSCModel";
import { OcorrenciaEventoModel } from "./OcorrenciaEventoModel";

export class LoteOcorrenciaEventoModel {
  Id: string;
  Protocolo: string;
  Formulario: string;
  Situacao: SituacaoLoteOcorrencia;
  DataOcorrencia: string;
  DataComunicacao: string;
  DescDataComunicacao: String;
  Observacao: string;
  Criador: ConsultaRapidaCriadorModel;
  numero: string;
  public TipoOcorrenciaEmExecucao: string;
  OcorrenciaAtual: OcorrenciaEventoModel;
  HtmlFieldPrefixOcorrenciaAtual: string;
  IsEmDeliberacao: boolean;
  ConfirmaInconsistencia: boolean;
  ObservacaoDeliberacao: string;
  IdOcorrenciaSelecionada: string;
  ListaComunicacao: ComunicacaoLoteOcorrenciaModel[];
  Arquivo: DocumentoFSCModel;
  ListaArquivos: DocumentoFSCModel[];
  AprovarTodosItens: boolean;
  Text: string;

  constructor() {
    this.Id = "-1";
    this.Protocolo = "";
    this.Formulario = "";
    this.Situacao = SituacaoLoteOcorrencia.EmElaboracao;
    this.DataOcorrencia = "";
    this.DataComunicacao = "";
    this.DescDataComunicacao = "";
    this.Observacao = "";
    this.Criador = new ConsultaRapidaCriadorModel();
    this.numero = "";
    this.TipoOcorrenciaEmExecucao = "";
    this.OcorrenciaAtual = new OcorrenciaEventoModel();
    this.HtmlFieldPrefixOcorrenciaAtual = "";
    this.IsEmDeliberacao = false;
    this.ConfirmaInconsistencia = false;
    this.ObservacaoDeliberacao = "";
    this.IdOcorrenciaSelecionada = "";
    this.ListaComunicacao = [];
    this.Arquivo = new DocumentoFSCModel();
    this.ListaArquivos = [];
    this.AprovarTodosItens = false;
    this.Text = "";
  }
  
}