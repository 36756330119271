<app-formulario-tabs [formulario]="formulario" [listaComunicacao]="lote?.ListaComunicacao"
  (tabSelecionada)="tabSelecionada($event)" (criadorSelecionado)="criadorSelecionado($event, false)" (triggerConsultaInsp)="criadorSelecionadoInsp($event)">
</app-formulario-tabs>

<div class="row">
  <div class="col-lg-12 col-sm-12">
    <div class="card card-principal-evento">
      <div class="card-body">
        <!--Ocorrencia Atual-->
        <div *ngIf="!isNewLote()">
          <div class="row">
            <div class="col-lg-12">
              <div class="card card-evento">
                <div class="card-body">
                  <div class="row card-title-evento">
                    <div class="col-6">
                      <h4 class="card-title">
                        <mat-icon svgIcon="padreacao"></mat-icon> Dados da Inspeção
                      </h4>
                    </div>
                    <div class="col-6 text-right">
                      <button *ngIf="ocorrenciaAtual.Id !== '-1'" type="button" class="btn btn-link btn-sm"
                        (click)="documentosDaOcorrencia()"><i class="far fa-folder"></i> Documentos</button>
                    </div>
                  </div>
                  
                  <div class="row">
                    <div *ngIf="existeInspetor()" class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <app-consultar-inspetor [tituloConsulta]="'Inspetor'" 
                      [inspetor]="ocorrenciaAtual.Inspetor"
                      ></app-consultar-inspetor>
                    </div>
                    <!--Carrega o inspetor para tela caso não seja um novo lote-->
                    <div *ngIf="!existeInspetor()" class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <label for="protocolo">Inspetor</label>
                      <input type="text" class="form-control form-control-sm" name="Inspetor" disabled
                        [(ngModel)]="ocorrenciaAtual.NomeInspetor" >
                    </div>

                    <div class="form-group col-lg-3 col-md-3 col-sm-6 col-xs-12">
                      <label for="DataOcorrencia">*Data da Inspeção</label>
                      <div class="input-group input-group-sm">
                        <input class="form-control" [max]="maxDate" [matDatepicker]="dataInspecao" name="DataOcorrencia"
                          [(ngModel)]="ocorrenciaAtual.DataInspecao" required
                          [disabled]="isOcorrenciaReadOnly() || bloqueiaData()">
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary" type="button" (click)="dataInspecao.open()"> <i
                              class="bi bi-calendar3"></i> </button>
                        </div>
                      </div>
                      <mat-datepicker [startAt]="ocorrenciaAtual.DataInspecao" #dataInspecao></mat-datepicker>
                    </div>

                    <div class="form-group col-lg-3 col-md-3 col-sm-12 col-xs-12">
                      <label for="TipoInspecao">*Tipo de Inspeção</label>
                      <select required class="form-control form-control-sm" name="TipoInspecao"
                        [(ngModel)]="ocorrenciaAtual.TipoInspecao" (ngModelChange)="AutoSelectAvaliacao()"
                        [disabled]="isOcorrenciaReadOnly()">
                        <option value="-1" selected>Selecione</option>
                        <option value="1">Avaliação</option>
                        <option value="2">Inspeção de Registro</option>
                      </select>
                    </div>
                  </div>

                  <div *ngIf="ocorrenciaAtual.TipoInspecao !== -1">
                    <hr>
                    <div class="row">
                      <div *ngIf="ocorrenciaAtual.TipoInspecao == 2"
                        class="form-group col-lg-5 col-md-5 col-sm-12 col-xs-12">
                        <app-consultar-bovino [bovino]="ocorrenciaAtual.Animal"
                          [ocorrenciaAtualSituacao]="ocorrenciaAtual.Situacao"
                          (BovinoEncontrado)="animalSelecionado($event)">
                        </app-consultar-bovino>
                      </div>

                      <div *ngIf="ocorrenciaAtual.TipoInspecao == 1"
                        class="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <!-- <label for="protocolo">Nome Animal</label> -->
                        <label for="protocolo">{{ contexto === 'jersey' ? '*Nome (Digite o nome do animal com afixo)' :
                          '*Nome (Digite o nome do animal sem afixo)' }}
                        </label>
                        <input type="text" class="form-control form-control-sm" name="NomeBovino"
                          [(ngModel)]="ocorrenciaAtual.Animal.NomeBovino"
                          (focusout)="simplyformat(ocorrenciaAtual.Animal.NomeBovino)"
                          [disabled]="isOcorrenciaReadOnly()"
                          (focusin)="ocorrenciaAtual.Animal.NomeBovino = ''">
                      </div>

                      <div class="form-group col-lg-3 col-md-3 col-sm-6 col-xs-12">
                        <label for="dataNascimento">*Data de Nascimento</label>
                        <div class="input-group input-group-sm">
                          <input class="form-control" [max]="maxDate" [matDatepicker]="dataNascimento"
                            name="dataNascimento" [(ngModel)]="ocorrenciaAtual.Animal.DataNascimento"
                            [disabled]="isOcorrenciaReadOnly() || ocorrenciaAtual.TipoInspecao == 2">
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary" type="button" (click)="dataNascimento.open()"> <i
                                class="bi bi-calendar3"></i> </button>
                          </div>
                        </div>
                        <mat-datepicker [startAt]="ocorrenciaAtual.Animal.DataNascimento" #dataNascimento></mat-datepicker>
                      </div>

                      <div class="form-group col-lg-2 col-md-2 col-sm-12 col-xs-12">
                        <label for="protocolo">*Sexo</label>
                        <!-- <select class="form-control form-control-sm" name="sexo" [disabled]="isEditMode()" value={{ocorrenciaAtual.Animal.SexoBovino}} [(ngModel)]="ocorrenciaAtual.Sexo" (ngModelChange)="atualizarDestinoInspecao()"> -->
                        <select class="form-control form-control-sm" name="sexo" [disabled]="isEditMode()"
                          [(ngModel)]="ocorrenciaAtual.Sexo" (ngModelChange)="atualizarDestinoInspecao()"
                          [disabled]="isOcorrenciaReadOnly() || ocorrenciaAtual.TipoInspecao == 2">
                          <option value="-1" selected>Selecione</option>
                          <option value="1">Macho</option>
                          <option value="2">Fêmea</option>
                        </select>
                      </div>
                      <div class="form-group col-lg-3 col-md-3 col-sm-12 col-xs-12" *ngIf="ocorrenciaAtual.Sexo == '1'">
                        <label for="Castrado">*Castrado</label>
                        <select required class="form-control form-control-sm" name="Castrado"
                        [(ngModel)]="ocorrenciaAtual.Castrado" (ngModelChange)="bloquearReproducaoCastrado()"
                        [disabled]="false">
                        <option value="-1" selected>Selecione</option>
                        <option value="1">Sim</option>
                        <option value="2">Não</option>
                        </select>
                      </div>
                      <div class="form-group col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label for="Chifre">*Possui chifre</label>
                        <select required class="form-control form-control-sm" name="PossuiChifre"
                        [(ngModel)]="ocorrenciaAtual.PossuiChifre" [disabled]="isOcorrenciaReadOnly()">
                        <option value="-1" selected>Selecione</option>
                        <option value="1">Sim</option>
                        <option value="2">Não</option>
                        </select>
                      </div>
                      <div class="form-group col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label for="Chifre">*Pelagem</label>
                        <select required class="form-control form-control-sm" name="Pelagem"
                        [(ngModel)]="ocorrenciaAtual.Pelagem" [disabled]="isOcorrenciaReadOnly()">
                        <option value="-1" selected>Selecione</option>
                        <option value="1">Mista</option>
                        <option value="2">Preto</option>
                        <option value="3">Marrom</option>
                        <option value="4">Araça</option>
                        </select>
                      </div>
                      <div class="form-group col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label for="Chifre">*Mancha pele</label>
                        <select required class="form-control form-control-sm" name="ManchaPele"
                        [(ngModel)]="ocorrenciaAtual.ManchaPele" [disabled]="isOcorrenciaReadOnly()">
                        <option value="-1" selected>Selecione</option>
                        <option value="1">Sim</option>
                        <option value="2">Não</option>
                        </select>
                      </div>
                      <div class="form-group col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label for="Chifre">*Desenvolvimento</label>
                        <select required class="form-control form-control-sm" name="Desenvolvimento"
                        [(ngModel)]="ocorrenciaAtual.Desenvolvimento" [disabled]="isOcorrenciaReadOnly()">
                        <option value="-1" selected>Selecione</option>
                        <option value="1">Superior</option>
                        <option value="2">Regular</option>
                        <option value="3">Inferior</option>
                        </select>
                      </div>
                      <div class="form-group col-lg-3 col-md-3 col-sm-12 col-xs-12">
                      <label for="Peso">*Peso do animal (20Kg a 2000Kg)</label>
                      <input type="number" name="Peso" class="form-control form-control-sm"
                      [(ngModel)]="ocorrenciaAtual.Peso" required [disabled]="isOcorrenciaReadOnly()">
                      </div>
                      <div class="form-group col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label for="Chifre">*Avaliação Reprodutiva</label>
                        <select required class="form-control form-control-sm" name="AvaliacaoReprodutiva"
                        [(ngModel)]="ocorrenciaAtual.AvaliacaoReprodutiva" [disabled]="isOcorrenciaReadOnly()">
                        <option value="-1" selected>Selecione</option>
                        <option value="1">Apto</option>
                        <option value="2">Não Apto</option>
                        </select>
                      </div>
                      <div class="form-group col-lg-2 col-md-2 col-sm-12 col-xs-12">
                        <label for="protocolo">Brinco</label>
                        <input type="number" class="form-control form-control-sm" name="brinco"
                          [(ngModel)]="ocorrenciaAtual.Brinco"
                          [disabled]="isOcorrenciaReadOnly() || ocorrenciaAtual.TipoInspecao == 2" 
                          (focusout)="atualizaOrelhaEsquerda()"/>
                      </div>

                      <div class="form-group col-lg-2 col-md-2 col-sm-10 col-xs-10">
                        <label for="Genotipado">*Genotipado</label>
                        <select required class="form-control form-control-sm" name="Genotipado"
                        [(ngModel)]="ocorrenciaAtual.Genotipado" [disabled]="isAdmin()">
                        <option value="-1" selected>Selecione</option>
                        <option value="1">Sim</option>
                        <option value="2">Não</option>
                        </select>
                      </div>

                    </div>
                    <div class="row" *ngIf="ocorrenciaAtual.TipoInspecao == 2">
                        <div class="form-group col-lg-3 col-md-3 col-sm-12 col-xs-12">
                          <label for="protocolo">Código Mãe</label>
                          <input type="text" class="form-control form-control-sm" disabled [value]="ocorrenciaAtual.Mae.CodigoBovino || ' '" name="codigoMae">
                        </div>
                        <div class="form-group col-lg-3 col-md-3 col-sm-12 col-xs-12">
                          <label for="protocolo">Nome Mãe</label>
                          <input type="text" class="form-control form-control-sm" disabled [value]="ocorrenciaAtual.Mae.NomeBovino || ' '" name="nomeMae">
                        </div>
                        <div class="form-group col-lg-3 col-md-3 col-sm-12 col-xs-12">
                          <label for="protocolo">Código Pai</label>
                          <input type="text" class="form-control form-control-sm" disabled [value]="ocorrenciaAtual.Pai.CodigoBovino || ' '" name="codigoPai">
                        </div>
                        <div class="form-group col-lg-3 col-md-3 col-sm-12 col-xs-12">
                          <label for="protocolo">Nome Pai</label>
                          <input type="text" class="form-control form-control-sm" disabled [value]="ocorrenciaAtual.Pai.NomeBovino || ' '" name="nomePai">
                        </div>
                    </div>

                    <hr>

                    <div class="row">
                      <div *ngIf="ocorrenciaAtual.TipoInspecao == 2" class="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <label for="protocolo">*Tipo Sangue</label>
                        <select class="form-control form-control-sm" name="tipoSangue" [disabled]="true"
                          [(ngModel)]="ocorrenciaAtual.TipoGrauSangue"
                          (ngModelChange)="atualizarGrauSanguePorTipo($event)">
                          <option *ngFor="let tipoSangue of tiposGrauSangue" [value]="tipoSangue.value"
                            [label]="tipoSangue.label"></option>
                        </select>
                      </div>

                      <div *ngIf="ocorrenciaAtual.TipoInspecao == 1" class="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12" >
                        <label for="protocolo">*Tipo Sangue</label>
                        <select class="form-control form-control-sm" name="tipoSangue" [disabled]="false"
                          [(ngModel)]="ocorrenciaAtual.TipoGrauSangue"
                          (ngModelChange)="atualizarGrauSanguePorTipo($event)">
                          <option *ngFor="let tipoSangue of tiposGrauSangueAvaliacao" [value]="tipoSangue.value"
                            [label]="tipoSangue.label"></option>
                        </select>
                      </div>

                      <div *ngIf="ocorrenciaAtual.TipoInspecao == 2" class="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <label for="protocolo">*Grau Sangue</label>
                        <select class="form-control form-control-sm" name="grauSangue" [disabled]="true"
                          [(ngModel)]="ocorrenciaAtual.GrauSangue">
                          <option *ngFor="let grau of grauSangue" [value]="grau.value" [label]="grau.label"></option>
                        </select>
                      </div>
                      <div *ngIf="ocorrenciaAtual.TipoInspecao == 1 && ocorrenciaAtual.TipoGrauSangue == 11" class="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <label for="protocolo">*Grau Sangue</label>
                         <select class="form-control form-control-sm" name="grauSangue"
                          [(ngModel)]="ocorrenciaAtual.GrauSangue">
                          <option *ngFor="let grau of grauSangueAvaliacaoCCG" [value]="grau.value" [label]="grau.value"></option>
                        </select>
                        <!--<input class="form-control form-control-sm"type="text" name="grauSangue" [disabled]="true" [(ngModel)]="ocorrenciaAtual.GrauSangue">-->
                      </div>
                      <div *ngIf="ocorrenciaAtual.TipoInspecao == 1 && ocorrenciaAtual.TipoGrauSangue == 12" class="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <label for="protocolo">*Grau Sangue</label>
                         <select class="form-control form-control-sm" name="grauSangue"
                          [(ngModel)]="ocorrenciaAtual.GrauSangue">
                          <option *ngFor="let grau of grauSangueAvaliacaoPA" [value]="grau.value" [label]="grau.label"></option>
                        </select>
                        <!--<input class="form-control form-control-sm"type="text" name="grauSangue" [disabled]="true" [(ngModel)]="ocorrenciaAtual.GrauSangue">-->
                      </div>
                      


                      <div class="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <label for="protocolo">*Situação SRG</label>
                        <select required class="form-control form-control-sm" name="situacaoSrg"
                          [disabled] = "true"
                          [(ngModel)]="ocorrenciaAtual.SituacaoSrg">
                          <option *ngFor="let situacao of situacoesSRG" [value]="situacao.value"
                            [label]="situacao.label"></option>
                        </select>
                      </div>

                      <div class="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <label for="protocolo">*Orelha Esquerda</label>
                        <input required type="text" class="form-control form-control-sm" name="OrelhaEsquerda"
                          [disabled]="ocorrenciaAtual.TipoInspecao == 2 || isOcorrenciaReadOnly()" [(ngModel)]="ocorrenciaAtual.OrelhaEsquerda">
                      </div>

                      <div class="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <label for="protocolo">*Orelha Direita</label>
                        <input type="text" class="form-control form-control-sm" name="OrelhaDireita"
                          [disabled]="ocorrenciaAtual.TipoInspecao == 2 || (ocorrenciaAtual.TipoInspecao !== 2 && ocorrenciaAtual.Situacao !== 50) || isOcorrenciaReadOnly()" 
                          [(ngModel)]="ocorrenciaAtual.OrelhaDireita">
                      </div>
                    </div>

                    <hr>

                    <div class="row">
                      <div class="form-group col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label for="protocolo">*Apto</label>
                        <select required class="form-control form-control-sm" name="TipoInspecao"
                          [(ngModel)]="ocorrenciaAtual.AptoRegistro" (ngModelChange)="atualizarDestinoInspecao()" [disabled]="isOcorrenciaReadOnly()">
                          <option value="-1" selected disabled hidden>Selecione</option>
                          <option value="1">Sim</option>
                          <option value="2">Não</option>
                          <option value="3">Não Avaliado</option>
                        </select>
                      </div>

                      <div *ngIf="contexto == 'wagyu'" class="form-group col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label for="protocolo">Destino</label>
                        <select class="form-control form-control-sm" name="Destino"
                          [(ngModel)]="ocorrenciaAtual.Destino" [disabled]="ocorrenciaAtual.isCastrado">
                          <option value="-1" selected disabled hidden>Selecione</option>
                          <option value="1">Reprodução</option>
                          <option value="2">Abate</option>
                        </select>
                      </div>
                    </div>

                    <div class="row">
                      <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <label for="protocolo">Observação</label>
                        <textarea rows="2" class="form-control form-control-sm" name="Observação"
                          [(ngModel)]="ocorrenciaAtual.Observacao" [disabled]="isOcorrenciaReadOnly()"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-12">
              <app-botoes-acoes-ocorrencia [lote]="lote" [idOcorrencia]="ocorrenciaAtual.Id"
                (acaoSelecionada)="acaoBotao($event)" tipoEvento="inspecao"></app-botoes-acoes-ocorrencia>
            </div>
          </div>
          <!--Lista de Ocorrencias-->
          <div>
            <div *ngIf="ocorrencias.length > 0" class="row" style="margin-top: 15px;">
              <div class="col-lg-12 table-responsive">
                <table class="table table-header-custom">
                  <thead>
                    <tr>
                      <th>Animal</th>
                      <th>Pai</th>
                      <th>Mãe</th>
                      <th>Sexo</th>
                      <th>Situação SRG</th>
                      <th>Tipo Inspeção</th>
                      <th>Apto</th>
                      <th>Situação</th>
                      <th class="text-center" width="10%">Opções</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let ev of ocorrencias"
                      [ngClass]="{'ocorrencia-em-destaque': ev.Id === ocorrenciaAtual.Id, 'bg-amarelo': ev.AptoRegistro == 3 }">
                      <td>{{ ev.NomeAnimal }}</td>
                      <td>{{ ev.Pai.CodigoNomeBovino }}</td>
                      <td>{{ ev.Mae.CodigoNomeBovino }}</td>
                      <td>{{ commons.getDescricaoSexo(ev.Sexo) }}</td>
                      <td>{{ commons.getDescricaoSituacaoSRG(ev.SituacaoSrg) }}</td>
                      <td>{{ commons.getDescricaoTipoInspecao(ev.TipoInspecao) }}</td>
                      <td>{{ commons.getDescricaoApto(ev.AptoRegistro) }}</td>
                      <td>{{ commons.getDescricaoSituacao(ev.Situacao) }}</td>
                      <td class="text-center acoes-evento">
                        <i *ngIf="permiteEdicao(ev.Situacao)" class="fas fa-edit" (click)="editar(ev)"></i>
                        <i *ngIf="!permiteEdicao(ev.Situacao)" class="fas fa-eye" (click)="editar(ev)"></i>
                        <i *ngIf="lote.Situacao === 1" class="fas fa-trash-alt" (click)="deletar(ev)"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>