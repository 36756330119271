import { AprovacaoCarcacaAbate, CoberturaGorduraAbate } from "../shared/enums/Enums";


export class RelacaoPosAbateComAvalCarcacaModel {
  Id: string;
  IdBovino: string;
  IdOcorrencia: string;
  Readonly: boolean;
  OrdemRomaneio: number | string;
  NroDentes: number | string;
  Marmoreio: number | string;
  PesoCarcaca: number | string;
  CoberturaGordura: CoberturaGorduraAbate;
  AprovacaoCarcaca: AprovacaoCarcacaAbate;

  constructor() {
    this.Id = '-1';
    this.IdBovino = '-1';
    this.IdOcorrencia = '-1';
    this.Readonly = false;
    this.OrdemRomaneio = '';
    this.NroDentes = '';
    this.Marmoreio = '';
    this.PesoCarcaca = '';
    this.CoberturaGordura = CoberturaGorduraAbate.None;
    this.AprovacaoCarcaca = AprovacaoCarcacaAbate.None;
  }
}