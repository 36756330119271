<div class="card">
  <div class="card-body">
    
    <app-filtro-consulta-laudo-dna [formConsulta]="formConsulta" (triggerConsulta)="botaoConsultar()"></app-filtro-consulta-laudo-dna>

    <div class="row justify-content-end">
      <div *ngIf="permiteNovo"  class="col-lg-2 col-md-4 col-sm-6">
        <button type="button" class="btn btn-sm btn-block btn-primary" (click)="novo()" >Novo</button>
      </div>
      <div class="col-lg-2 col-md-4 col-sm-6">
        <button type="button" class="btn btn-sm btn-block btn-primary" (click)="botaoConsultar()" >Buscar</button>
      </div>
    </div>

    <div *ngIf="laudos.length > 0"  class="row" style="margin-top: 15px;">
      <div class="col-lg-12 table-responsive">
        <table class="table table-hover table-header-custom">
          <thead>
            <tr>
              <th>Número laudo</th>
              <th>Código</th> 
              <th>Nome</th> 
              <th>Pai</th> 
              <th>Mãe</th> 
              <th>Qualifica</th> 
              <th>Laboratório</th> 
              <th width="10%"></th> 
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let laudo of laudos | paginate : { itemsPerPage: pageSize, currentPage: page, totalItems: count };">
              <td>{{ laudo.NumeroLaudo }}</td>
              <td>{{ laudo.CodigoAnimal || '-' }}</td>
              <td>{{ laudo.NomeAnimal || '-' }}</td>
              <td>{{ (laudo.Pai.CodigoBovino || '') + ' - ' + (laudo.Pai.NomeBovino || '') }}</td>
              <td>{{ (laudo.Mae.CodigoBovino || '') + ' - ' + (laudo.Mae.NomeBovino || '') }}</td>
              <td>{{ getDescricaoQualifica(laudo.Qualifica) }}</td>
              <td>{{ laudo.Laboratorio }}</td>
              <td width="10%" class="text-center">
                <a 
                  type="button" 
                  matTooltip="Visualizar"
                  [queryParams]="{id: laudo.Id, readOnly: true}"
                  [routerLink]="['/laudo_dna_manter']" 
                >
                  <i class="action-icons fas fa-eye"></i>
                </a>
                <a 
                  type="button"
                  *ngIf="permiteNovo"
                  matTooltip="Editar"
                  [queryParams]="{id: laudo.Id, readOnly: false}"
                  [routerLink]="['/laudo_dna_manter']" 
                >
                  <i class="action-icons fas fa-edit"></i>
                </a>
                <a type="button" matTooltip="Visualizar documento" (click)="visualizarDocumento(laudo.Arquivo?.Id)">
                  <i class="action-icons fas fa-file-alt"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="col-md-12 text-center">
        <pagination-controls 
          class="srg-paginacao"
          previousLabel="Anterior" 
          nextLabel="Próximo" 
          [responsive]="true" 
          (pageChange)="handlePageChange($event)"
          [autoHide]="true"
        >
        </pagination-controls>
      </div>
    
    </div>

  </div>
</div>

