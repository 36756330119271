import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/account/shared/account.service';
import { BovinoModel } from 'src/app/models/BovinoModel';
import { CriadorModel } from 'src/app/models/CriadorModel';
import { IdentificacaoCriadorModel } from 'src/app/models/IdentificacaoCriadorModel';
import { ApiService } from 'src/app/shared/api.service';
import { Origem, PosicaoAfixo, SituacaoSRG, TipoBuscaBovino, TipoGrauSangue } from 'src/app/shared/enums/Enums';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { BovinoService } from '../shared/bovino.service';


@Component({
  selector: 'app-bovino-manter',
  templateUrl: './bovino-manter.component.html',
  styleUrls: ['./bovino-manter.component.scss']
})
export class BovinoManterComponent implements OnInit {

  readonly env = environment;

  afixoSelecionado: IdentificacaoCriadorModel | null = null;

  bovino: BovinoModel = new BovinoModel();
  criador: CriadorModel = new CriadorModel();
  bovinoPai: BovinoModel = new BovinoModel();
  bovinoMae: BovinoModel = new BovinoModel();
  proprietario: CriadorModel = new CriadorModel();

  origem: { value: number, label: string }[] = [];
  situacoesSRG: { value: number, label: string }[] = [];

  racas: { value: string, label: string }[] = [];
  grauSangue: { value: string, label: string }[] = [];
  tipoSanguineo: { value: number, label: string }[] = [];

  isAdmin: boolean = false;

  constructor(
    private api: ApiService,
    private router: Router,
    private bovinoService: BovinoService,
    private accountService: AccountService,
  ) { }

  ngOnInit(): void {
    this.bovinoPai.TituloConsulta = 'pai';
    this.bovinoMae.TituloConsulta = 'mãe';
    this.bovinoMae.TipoBusca = TipoBuscaBovino.Fêmea;
    this.bovinoPai.TipoBusca = TipoBuscaBovino.Macho;
    this.proprietario.tituloConsulta = 'Proprietário';
    this.grauSangue.push({ value: '-1', label: 'Selecione...' });
    this.tipoSanguineo.push({ value: -1, label: 'Selecione...' });

    this.preencherOrigem();
    this.preencherSituacoesSRG();
    this.racas = this.bovinoService.getListaRacas(['manter_bovino'], true);

    // Remove raça desconhecida da lista de cadastro
    // A raça não deve ser removida do environment e banco para evitar raça em branco na consulta de alguns animais
    this.racas = this.racas.filter((item, idx, arr) => {
      return item.value !== '02551ee85dd94141b544a9a1e25a0969';
    })

    if (this.accountService.isCriador) {
      const token: any = this.accountService.getPayloadToken();
      if (token) {
        const afixo = new IdentificacaoCriadorModel();
        afixo.codigo = token.AfixoCriador;
        afixo.posicaoAfixo = PosicaoAfixo.None;

        this.criador.afixos = [afixo];
        this.criador.codigo = token.CodigoCriador;
        this.criador.id = token.CriadorId;
        this.criador.nome = token.NomeCriador;
        this.criador.readonly = true;

        this.proprietario.afixo = afixo.codigo;
        this.proprietario.codigo = token.CodigoCriador;
        this.proprietario.id = token.CriadorId;
        this.proprietario.nome = token.NomeCriador;
        this.proprietario.readonly = true;
        this.criadorSelecionado(token.CriadorId);
      }
    } else if (this.accountService.isAdmin) {
      this.isAdmin = true;
    }
    this.isCriador();
  }

  getLabelIdentificacao(identificacao: IdentificacaoCriadorModel): string {
    return `${identificacao.posicaoAfixo === -1 ? '' : `${PosicaoAfixo[identificacao.posicaoAfixo]} - `}${identificacao.codigo}`;
  }

  criadorSelecionado(event: string): void {
    this.bovinoMae.IdProprietarioConsulta = event;
    this.bovinoPai.IdProprietarioConsulta = event;
    const afixo = this.criador.afixos.filter(afixo => afixo.principal && afixo.Id !== '-1');
    this.afixoSelecionado = afixo.length === 1 ? afixo[0] : null;
    this.criador.idAfixo = this.afixoSelecionado?.Id || '-1';

    // if (event === '') this.bovino.NomeBovino = '';
    // else this.formatarNomeBovino(false, (this.env as any).contexto === 'jersey');
  }

  alterarAfixoSelecionado() {
    const afixo = this.criador.afixos.filter(afixo => afixo.Id === this.criador.idAfixo);
    this.afixoSelecionado = afixo.length === 1 ? afixo[0] : null;

    // this.formatarNomeBovino(true);
  }

  // formatarNomeBovino(changedByCombo: boolean = false, contextoJersey: boolean = false) {
  //   if (contextoJersey || this.bovino.NomeBovino === '' || !this.afixoSelecionado || this.afixoSelecionado.Id === '-1')
  //     return;

  //   const elementosNomeBovino = this.bovino.NomeBovino.split(' ');
  //   const nomePossuiAfixo = this.criador.afixos.filter((item: IdentificacaoCriadorModel) =>
  //     item.posicaoAfixo === PosicaoAfixo.Prefixo
  //     ? elementosNomeBovino[0] === `${item.codigo}`
  //     : elementosNomeBovino[elementosNomeBovino.length - 1] === `${item.codigo}`
  //   );

  //   if (nomePossuiAfixo.length === 0) {
  //     this.bovino.NomeBovino = this.afixoSelecionado.posicaoAfixo === PosicaoAfixo.Prefixo
  //       ? `${this.afixoSelecionado.codigo} ${this.bovino.NomeBovino}`
  //       : `${this.bovino.NomeBovino} ${this.afixoSelecionado.codigo}`;

  //     return;
  //   }

  //   if (changedByCombo) {
  //     this.bovino.NomeBovino = this.bovino.NomeBovino.replace(
  //       nomePossuiAfixo[0].posicaoAfixo === PosicaoAfixo.Prefixo? `${nomePossuiAfixo[0].codigo} ` : ` ${nomePossuiAfixo[0].codigo}`,
  //       ''
  //     );

  //     this.bovino.NomeBovino = this.afixoSelecionado.posicaoAfixo === PosicaoAfixo.Prefixo
  //       ? `${this.afixoSelecionado.codigo} ${this.bovino.NomeBovino}`
  //       : `${this.bovino.NomeBovino} ${this.afixoSelecionado.codigo}`;

  //     return;
  //   }

  //   // se o usuario mudou o afixo sozinho, entao atualiza o item selecionado no combobox
  //   const afixo = this.criador.afixos.filter(item =>
  //     (item.codigo === nomePossuiAfixo[0].codigo && item.posicaoAfixo === nomePossuiAfixo[0].posicaoAfixo) ||
  //     (nomePossuiAfixo.length === 2 && item.codigo === nomePossuiAfixo[1].codigo && item.posicaoAfixo === nomePossuiAfixo[1].posicaoAfixo)
  //   );
  //   if (afixo.length === 1) this.criador.idAfixo = afixo[0].Id;
  //   else {
  //     Swal.fire('Não foi possível definir o afixo escolhido.', '', 'warning');
  //     this.bovino.NomeBovino = '';
  //   }
  // }

  atualizarTiposPorRaca(raca: string): void {
    this.bovino.SangueBovino.tipo = -1;
    this.bovino.SangueBovino.grau = '-1';

    this.tipoSanguineo = this.bovinoService.getTiposPorRaca(raca, true, this.isAdmin);

    this.grauSangue = [];
    this.grauSangue.push({ value: '-1', label: 'Selecione...' });

    if (!this.isAdmin) {
      this.tipoSanguineo = [];
      this.tipoSanguineo.push({ value: TipoGrauSangue.ZT, label: 'ZT' });
      this.bovino.SangueBovino.tipo = TipoGrauSangue.ZT;
    }

    this.atualizarGrauSanguePorTipo(this.bovino.SangueBovino.tipo);
  }

  atualizarGrauSanguePorTipo(tipo: number): void {
    this.grauSangue = [];
    this.grauSangue.push({ value: '-1', label: 'Selecione...' });

    if (!this.isAdmin) { // Para criador, tipo de sangue fixo em ZT // 1/2
      this.tipoSanguineo = [];
      this.tipoSanguineo.push({ value: TipoGrauSangue.ZT, label: 'ZT' });
      this.bovino.SangueBovino.tipo = TipoGrauSangue.ZT;
      this.grauSangue = this.bovinoService.getGrauSanguePorTipo(TipoGrauSangue.ZT, false, false);
    } else {
      this.grauSangue = this.bovinoService.getGrauSanguePorTipo(tipo, false, true);
    }
  }

  botaoSalvar(): void {

    const request_data = {
      bovino: this.bovino,
      pai: this.bovinoPai,
      mae: this.bovinoMae,
      criador: this.criador,
      proprietario: this.proprietario
    }

    this.api.registrarBovino(request_data).then((result: any) => {
      if (result) {
        if (result.success) {
          Swal.fire(result.message, '', 'success');
          this.router.navigate([`bovino_detalhar/${result.BovinoId}`], {
            queryParams: {
              sugestao: (this.env as any).contexto === 'wagyu',
              readOnly: true,
            }
          });
        } else {
          Swal.fire(result.message, '', 'error');
        }
      } else {
        Swal.fire('A operação não pode ser realizada.', '', 'error');
      }
    });
  }

  preencherSituacoesSRG(): void {
    for (let i = 1; i < 7; i++) {
      this.situacoesSRG.push({ value: i, label: SituacaoSRG[i] });
    }
  }

  preencherOrigem(): void {
    this.origem.push({ label: 'Selecione...', value: -1 });
    for (let i = 16; i < 30; i++) {
      this.origem.push({ label: Origem[i], value: i });
    }
  }

  isCriador() {
    if (this.accountService.isCriador) {
      return true;
    } else {
      return false;
    }
  }
}
