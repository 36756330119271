import { environment } from "src/environments/environment";
import { Sexo, SiglaPais, TipoGrauSangue } from "../shared/enums/Enums";
import { AscendenciaNacionalizacaoModel } from "./AscendenciaNacionalizacaoModel";
import { OcorrenciaEventoModel } from "./OcorrenciaEventoModel";

export class OcorrenciaEventoSemenModel extends OcorrenciaEventoModel {
  Codigo: string;
  Nome: string;
  Sexo: Sexo;
  DataNascimento: string;
  TipoGrauSangue: TipoGrauSangue;
  GrauSangue: string;
  Raca: string;
  PaisOrigem: SiglaPais;
  Tatuagem: string;
  NumeroRegistroOrigem: string;
  CodigoCentral: string;
  Ascendencia: AscendenciaNacionalizacaoModel;

  constructor() {
    super();
    this.Codigo = '';
    this.Nome = '';
    this.GrauSangue = '1/1';
    this.Raca = (environment as any).contexto === 'jersey'? 'Jersey' : 'WAGYU KUROGE';
    this.Tatuagem = '';
    this.NumeroRegistroOrigem = '';
    this.CodigoCentral = '';
    this.DataNascimento = '';
    this.Sexo = Sexo.Macho;
    this.TipoGrauSangue = TipoGrauSangue.PO;
    this.PaisOrigem = SiglaPais.None;
    this.Ascendencia = new AscendenciaNacionalizacaoModel();
  }

}