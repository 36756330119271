<app-formulario-tabs
  [formulario]="formulario"
  [listaComunicacao]="lote?.ListaComunicacao"
  (tabSelecionada)="tabSelecionada($event)"
  (criadorSelecionado)="criadorSelecionado($event)"
></app-formulario-tabs>

<div class="row">
  <div class="col-lg-12 col-sm-12">
    <div class="card card-principal-evento">
      <div class="card-body">
        <!--Ocorrencia Atual-->
        <div *ngIf="!isNewLote()">
          <div class="row">
            <div class="col-lg-12">
              <div class="card card-evento">
                <div class="card-body">
                  <div class="row card-title-evento">
                    <div class="col-6">
                      <h4 class="card-title">
                        <mat-icon svgIcon="inovulacao"></mat-icon> 
                        Dados da {{ env.contexto === 'jersey'? 'Inovulação' : 'Implantação' }}
                      </h4>
                    </div>
                    <div class="col-6 text-right">
                      <button
                        *ngIf="ocorrenciaAtual.Id !== '-1'"
                        type="button"
                        class="btn btn-link btn-sm"
                        (click)="documentosDaOcorrencia()"
                      >
                        <i class="far fa-folder"></i> Documentos
                      </button>
                    </div>
                  </div>

                  <div class="row" style="margin-bottom: 20px;">
                    <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                      <button [disabled]="isOcorrenciaReadOnly() || aprovadoComPendencia()" type="button" class="btn btn-sm btn-block btn-primary" (click)="botaoConsultarLimparEmbriao()">
                        {{ embriaoSelecionado? 'Limpar' : 'Buscar' }} Embrião
                      </button>
                    </div>
                  </div>

                  <div class="row" *ngIf="!embriaoSelecionado && ocorrenciaAtual.Doadora.Id !== '-1'">
                    <div class="form-group col-lg-2 col-md-4 col-sm-6 col-xs-12">
                      <label for="TipoOrigem">Origem</label>
                      <select
                        class="form-control form-control-sm"
                        name="TipoOrigem"
                        [disabled]="true"
                        [(ngModel)]="ocorrenciaAtual.OcorrenciaEventoFiv.TipoFIV"
                      >
                        <option value="-1" *ngIf="ocorrenciaAtual.OcorrenciaEventoFiv.Id === '-1'"></option>
                        <option value="1" *ngIf="ocorrenciaAtual.OcorrenciaEventoFiv.Id !== '-1'">FIV</option>
                        <option value="2" *ngIf="ocorrenciaAtual.OcorrenciaEventoFiv.Id !== '-1'">TE</option>
                      </select>
                    </div>
                  </div>

                  <!-- region doadora -->
                  <div class="row" *ngIf="!embriaoSelecionado">
                    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                      <app-consultar-bovino
                        [bovino]="ocorrenciaAtual.Doadora"
                        [ocorrenciaAtualSituacao]="ocorrenciaAtual.Situacao"
                        [permite]="permite"
                      ></app-consultar-bovino>
                    </div>
                    <div
                      class="form-group col-lg-4 col-md-4 col-sm-4 col-xs-12"
                    >
                      <label for="formulario">Proprietário</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        name="Proprietario"
                        readonly="true"
                        [(ngModel)]="
                          ocorrenciaAtual.Doadora.CodigoNomeProprietario
                        "
                      />
                    </div>
                  </div>

                  <!-- region Reprodutor -->
                  <div class="row" *ngIf="!embriaoSelecionado">
                    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                      <app-consultar-bovino
                        [bovino]="ocorrenciaAtual.Reprodutor"
                        [ocorrenciaAtualSituacao]="ocorrenciaAtual.Situacao"
                        [permite]="permite"
                      ></app-consultar-bovino>
                    </div>
                    <div
                      class="form-group col-lg-4 col-md-4 col-sm-4 col-xs-12"
                    >
                      <label for="formulario">Proprietário</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        name="Proprietario"
                        readonly="true"
                        [(ngModel)]="
                          ocorrenciaAtual.Reprodutor.CodigoNomeProprietario
                        "
                      />
                    </div>
                  </div>
                  <!-- endregion -->

                  <app-inovulacao-embriao-selecionado 
                    *ngIf="embriaoSelecionado" 
                    [tipoEvento]="tipoEventoEmbriao"
                    [embriao]="tipoEventoEmbriao == 52? ocorrenciaAtual.OcorrenciaEventoFiv : ocorrenciaAtual.OcorrenciaNacionalizacaoEmbriao"
                  >
                  </app-inovulacao-embriao-selecionado>

                  <h4>Dados da {{ env.contexto === 'jersey'? 'Inovulação' : 'Implantação' }}</h4>

                  <div class="row">
                    <div
                      class="form-group col-lg-2 col-md-4 col-sm-6 col-xs-12"
                    >
                      <label for="ReceptoraCadastrada"
                        >Receptora Cadastrada?</label
                      >
                      <select
                        class="form-control form-control-sm"
                        name="ReceptoraCadastrada"
                        [(ngModel)]="ocorrenciaAtual.ReceptoraCadastrada"
                        [disabled]="isOcorrenciaReadOnly()"
                      >
                        <option value="1">Sim</option>
                        <option value="2">Não</option>
                      </select>
                    </div>

                    <div
                      *ngIf="ocorrenciaAtual.ReceptoraCadastrada == 1"
                      class="col-lg-10 col-md-8 col-sm-6 col-xs-12"
                    >
                      <app-consultar-bovino
                        [bovino]="ocorrenciaAtual.Receptora"
                        [ocorrenciaAtualSituacao]="ocorrenciaAtual.Situacao"
                        [permite]="permite"
                      ></app-consultar-bovino>
                    </div>

                    <div
                      *ngIf="ocorrenciaAtual.ReceptoraCadastrada == 2"
                      class="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12"
                    >
                      <label for="formulario">*Código Receptora</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        name="CodigoReceptoraNaoCadastrada"
                        [(ngModel)]="
                          ocorrenciaAtual.CodigoReceptoraNaoCadastrada
                        "
                        [disabled]="isOcorrenciaReadOnly()"
                      />
                    </div>

                    <div
                      *ngIf="ocorrenciaAtual.ReceptoraCadastrada == 2"
                      class="form-group col-lg-7 col-md-4 col-sm-6 col-xs-12"
                    >
                      <label for="protocolo">*Nome Receptora</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        name="NomeReceptoraNaoCadastrada"
                        [(ngModel)]="ocorrenciaAtual.NomeReceptoraNaoCadastrada"
                        [disabled]="isOcorrenciaReadOnly()"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div
                      class="form-group col-lg-4 col-md-4 col-sm-4 col-xs-12"
                    >
                      <label for="ReceptoraCadastrada">*Tipo</label>
                      <select
                        class="form-control form-control-sm"
                        name="TipoTransferencia"
                        [(ngModel)]="ocorrenciaAtual.TipoTransferencia"
                        [disabled]="isOcorrenciaReadOnly() && !temporarioEdicaoEventoAprovado"
                      >
                      <option *ngFor="let tipo of tiposTransferencia" [value]="tipo.value" [label]="tipo.label"></option>
                      </select>
                    </div>
                    <div
                      class="form-group col-lg-4 col-md-4 col-sm-4 col-xs-12"
                    >
                      <label for="DataOcorrencia">*Data Transferência</label>
                      <div class="input-group input-group-sm">
                        <input
                          class="form-control"
                          [max]="maxDate"
                          [matDatepicker]="dataTransferencia"
                          name="DataOcorrencia"
                          [(ngModel)]="ocorrenciaAtual.DataTransferencia"
                          [disabled]="!temporarioEdicaoEventoAprovado && isOcorrenciaReadOnly() && !temporarioEdicaoEventoAguardandoAprovacao"
                        />
                        <div
                          *ngIf="!isOcorrenciaReadOnly()"
                          class="input-group-append"
                        >
                          <button
                            class="btn btn-outline-secondary"
                            type="button"
                            (click)="dataTransferencia.open()"
                          >
                            <i class="bi bi-calendar3"></i>
                          </button>
                        </div>
                      </div>
                      <mat-datepicker
                        [startAt]="ocorrenciaAtual.DataTransferencia"
                        #dataTransferencia
                      ></mat-datepicker>
                    </div>
                    <div
                      class="form-group col-lg-4 col-md-4 col-sm-4 col-xs-12"
                    >
                      <label for="formulario">*Quantidade de Transferidos</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        name="QuantidadeTransferidos"
                        [disabled]="isOcorrenciaReadOnly()"
                        [(ngModel)]="ocorrenciaAtual.QuantidadeTransferidos"
                      />
                    </div>
                  </div>

                  <div *ngIf="env.contexto === 'wagyu'" class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <app-consultar-veterinario
                        [veterinario]="ocorrenciaAtual.Veterinario"
                        [readonly]="isOcorrenciaReadOnly()"
                        [isReadOnly]="ocorrenciaAtual.isReadOnly" 
                      ></app-consultar-veterinario>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div class="col-lg-12">
              <app-botoes-acoes-ocorrencia
                [lote]="lote"
                [idOcorrencia]="ocorrenciaAtual.Id"
                (acaoSelecionada)="acaoBotao($event)"
                [temporarioEdicaoEventoAprovado]="temporarioEdicaoEventoAprovado"
                [temporarioEdicaoEventoAguardandoAprovacao]="temporarioEdicaoEventoAguardandoAprovacao"
                tipoEvento="inovulacao"
              ></app-botoes-acoes-ocorrencia>
            </div>
          </div>

          <!--Lista de Ocorrencias-->
          <div>
            <div
              *ngIf="ocorrencias.length > 0"
              class="row"
              style="margin-top: 15px"
            >
              <div class="col-lg-12 table-responsive">
                <table class="table table-hover table-header-custom">
                  <thead>
                    <tr>
                      <th>Doadora</th>
                      <th>Reprodutor</th>
                      <th>Receptora</th>
                      <th>Transferência</th>
                      <th class="text-center">Transferidos</th>
                      <th>Situação</th>
                      <th width="10%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="let ev of ocorrencias"
                      [ngClass]="{
                        'ocorrencia-em-destaque': ev.Id === ocorrenciaAtual.Id
                      }"
                    >
                      <td *ngIf="permite || ev.Comprador == compradorLogado">{{ ev.Doadora.CodigoNomeBovino }}</td>
                      <td *ngIf="permite || ev.Comprador == compradorLogado">{{ 
                        ev.OcorrenciaEventoFiv.BovinosDaLista || ev.OcorrenciaNacionalizacaoEmbriao.BovinosDaLista || ev.Reprodutor.CodigoNomeBovino 
                      }}</td>
                      <td *ngIf="permite || ev.Comprador == compradorLogado">{{ ev.ReceptoraCadastrada == 1 ? ev.Receptora.CodigoNomeBovino : ev.CodigoNomeReceptoraNaoCadastrada }}</td>
                      <td *ngIf="permite || ev.Comprador == compradorLogado">{{ ev.DataTransferencia | date: 'dd/MM/yyyy' }}</td>
                      <td class="text-center" *ngIf="permite || ev.Comprador == compradorLogado">{{ ev.QuantidadeTransferidos }}</td>
                      <td *ngIf="permite || ev.Comprador == compradorLogado">{{ commons.getDescricaoSituacao(ev.Situacao) }}</td>
                      <td class="text-center text-nowrap acoes-evento" *ngIf="permite || ev.Comprador == compradorLogado">
                        <i
                          *ngIf="ev.ImputaMulta === 1"
                          matTooltip="Multa Imputada"
                          class="fas fa-dollar-sign"
                        ></i>
                        <i
                          *ngIf="ev.SolicitarDna === 1"
                          matTooltip="Será solicitado DNA no Nascimento"
                          class="fas fa-dna"
                        >
                        </i>
                        <i
                          *ngIf="permiteEdicao(ev.Situacao)"
                          class="fas fa-edit"
                          (click)="editar(ev)"
                        ></i>
                        <i
                          *ngIf="!permiteEdicao(ev.Situacao)"
                          class="fas fa-eye"
                          (click)="editar(ev)"
                        ></i>
                        <i
                          *ngIf="lote.Situacao === 1"
                          class="fas fa-trash-alt"
                          (click)="deletar(ev)"
                        ></i>
                        <i
                          *ngIf="ev.IndInconsitencia"
                          class="fa fa-exclamation-triangle"
                          style="cursor: pointer"
                          matTooltip="{{ ev.Inconsistencia }}"
                        ></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
