import { Component, Input, OnInit } from '@angular/core';
import { EnderecoModel } from 'src/app/models/EnderecoModel';
import { ApiService } from '../api.service';
import { UnidadesFederativas } from '../enums/Enums';

@Component({
  selector: 'app-endereco-manter',
  templateUrl: './endereco-manter.component.html',
  styleUrls: ['./endereco-manter.component.scss']
})
export class EnderecoManterComponent implements OnInit {

  UFs: { value: number, label: string }[] = [];
  @Input() readOnly: boolean = false;
  @Input() endereco: EnderecoModel = new EnderecoModel();

  constructor(
    private api: ApiService
  ) { }

  ngOnInit(): void {
    this.preencherEstados();
  }

  preencherEstados(){
    this.UFs = [];
    this.UFs.push({ value: 0, label: 'Selecione ...' });
    for (let i=1 ; i<28 ; i++)
      this.UFs.push({ value: i, label: UnidadesFederativas[i]});
  }

  botaoConsultarCEP(): void {
    const cep = this.endereco.cep;

    if(!cep) return;

    this.api.consultarCEP(cep).then((result:any) => {
      if (result) {
        this.endereco.bairro = result.bairro || '';
        this.endereco.cidade = result.localidade || '';
        this.endereco.logradouro = result.logradouro || '';
        this.endereco.complemento = result.complemento || '';
        this.endereco.uf = Number(UnidadesFederativas[result.uf]) || 0;
      }
    });
  }
}
