<div class="card">
  <div class="card-body">

    <div class="row">
      <div class="form-group col-lg-5 col-md-6 col-sm-6 col-xs-12">
        <label>Reservado para</label>
        <input disabled name="reservado_para" type="text" class="form-control form-control-sm" [ngModel]="nome">
      </div>
      
      <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
        <label>Tipo Grau Sangue</label>
        <input disabled name="tipo_grau_sangue" type="text" class="form-control form-control-sm" [ngModel]="bovinoService.getLabelTipoRegistroReservado(formulario.tipo)">
      </div>
    </div>

    <div class="row" style="margin-top: 25px;">
      <div class="col-lg-12 table-responsive">
        <table class="table table-hover table-header-custom">
          <thead>
            <tr>
              <th>Registro</th>
              <th>Gerado em</th>
              <th>Responsável geração</th>
              <th>Reservado para o Inspetor em</th>
              <th>Responsável reserva inspetor</th>
            </tr>
          </thead>
          <tbody *ngIf="registros.length > 0">
            <tr *ngFor="let registro of registros">
              <td> {{ registro.Registro }} </td>
              <td> {{ registro.DataReservaFiliada | date: 'dd/MM/yyyy HH:mm:ss' }}</td>
              <td> {{ registro.ResponsavelReservaFiliada }}</td>
              <td> {{ (formulario.situacao === SituacaoRegistro.RESERVADO_INSPETOR? registro.DataReservaInspetor : '') | date: 'dd/MM/yyyy HH:mm:ss' }}</td>
              <td> {{ registro.ResponsavelReservaInspetor }}</td>
            </tr>
          </tbody>
          <tbody *ngIf="registros.length === 0">
            <tr class="text-center">
              <td colspan="5">
                <strong>Sem itens para exibição.</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</div>