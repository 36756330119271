import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import Swal from 'sweetalert2';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../api.service';
import { InspetorModel } from 'src/app/models/InspetorModel';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-consultar-inspetor-modal',
  templateUrl: './consultar-inspetor-modal.component.html',
  styleUrls: ['./consultar-inspetor-modal.component.scss']
})
export class ConsultarInspetorModalComponent implements OnInit {

  inspetores: any[] = [];
  formConsulta: InspetorModel = new InspetorModel();
  @Output() inspetorSelecionado = new EventEmitter<any>();

  constructor(
    private api: ApiService,
    public dialogRef: MatDialogRef<ConsultarInspetorModalComponent>,
    private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void { }

  botaoBuscar() {
    //var locale = this.route.snapshot.url[0].path;
    if (this.data.locale == 'abatecomavalcarcaca_manter' || this.data.locale == 'abatecomavalcarcaca_manter_pos' || this.data.locale == 'abate_carcaca') {
      this.formConsulta.TipoConsulta = 'tecnico_abate';
      this.api.consultarInspetores(this.formConsulta).then((result: any) => {
        if (result) {
          if (result.success) {
            this.inspetores = result.Itens;
          } else {
            this.inspetores = [];
            Swal.fire(result.message, '', 'error');
          }
        }
      });
    } else {
      this.api.consultarInspetores(this.formConsulta).then((result: any) => {
        if (result) {
          if (result.success) {
            this.inspetores = result.Itens;
          } else {
            this.inspetores = [];
            Swal.fire(result.message, '', 'error');
          }
        }
      });
    }

  }

  selecionarInspetor(inspetor: any) {
    this.inspetorSelecionado.emit(inspetor);
    this.dialogRef.close();
  }

  fechar() {
    this.dialogRef.close();
  }

}
