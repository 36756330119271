import { ApiService } from './../api.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-selecionar-filiadas',
  templateUrl: './selecionar-filiadas.component.html',
  styleUrls: ['./selecionar-filiadas.component.scss']
})
export class SelecionarFiliadasComponent implements OnInit {

  @Input() filiadaId: string = "";
  @Input() readonly: boolean = true;
  @Input() defaultFirstSelectedLabel: string = "Todos";
  @Output() filiadaSelecionada = new EventEmitter<string>();

  filiadas: any[] = [];

  constructor(private api: ApiService) { }

  ngOnInit(): void {
    this.consultarFiliadas();
  }

  consultarFiliadas(){
    if(this.filiadas.length <= 0){
      this.api.consultarFiliadas().then((result: any) =>{
        if(result){
          if(result.Status === 0){
            this.filiadas.push({ id: "", nome: this.defaultFirstSelectedLabel});
            if (result.Filiadas.length > 0)
              result.Filiadas.forEach((element: any) => {
                this.filiadas.push({ id: element.Id, nome: element.Nome});
              });
          }
        }
      })
    }
  }

  onChange(){
    this.filiadaSelecionada.emit(this.filiadaId);
  }

}
