<div class="card">
  <div class="card-body">

    <div class="row">

      <div *ngIf="env.contexto === 'jersey'" class="form-group col-lg-6 col-md-12 col-sm-12 col-xs-12">
        <app-selecionar-filiadas 
          [readonly]="true" 
          [filiadaId]="formulario.FiliadaId"
        >
        </app-selecionar-filiadas>
      </div>
      
      <div class="form-group col-md-6 col-sm-6 col-xs-12" [ngClass]="{ 'col-lg-3': env.contexto === 'jersey', 'col-lg-2': env.contexto === 'wagyu' }">
        <label>Tipo</label>
        <select class="form-control form-control-sm" name="tipos" [(ngModel)]="formulario.Tipo">
          <option *ngFor="let tipo of tiposRegistros" [value]="tipo.value" [label]="tipo.label"></option>
        </select>
      </div>

      <div class="form-group col-md-6 col-sm-6 col-xs-12" [ngClass]="{ 'col-lg-3': env.contexto === 'jersey', 'col-lg-2': env.contexto === 'wagyu' }">
        <label>*Quantidade</label>
        <input name="quantidade" mask="000000000"  type="text" class="form-control form-control-sm" [(ngModel)]="formulario.Quantidade">
      </div>

      <div class="form-group col-lg-8 col-md-12 col-sm-12 col-xs-12">
        <app-consultar-inspetor [inspetor]="formulario.Inspetor" tituloConsulta="Inspetor(a)"></app-consultar-inspetor>
      </div>

    </div>

    <div class="row justify-content-end">
      <div class="col-lg-3 col-md-4 col-sm-6">
        <button type="button" class="btn btn-sm btn-block btn-primary" (click)="botaoSalvar()">Reservar</button>
      </div>
    </div>

  </div>
</div>