import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UsuarioProcuradorModel } from 'src/app/models/UsuarioProcuradorModel';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { ApiService } from '../api.service';
import { ConsultarProcuradorModalComponent } from '../consultar-procurador-modal/consultar-procurador-modal';


@Component({
  selector: 'app-consultar-procurador',
  templateUrl: './consultar-procurador.component.html',
  styleUrls: ['./consultar-procurador.component.scss']
})
export class ConsultarProcuradorComponent implements OnInit {

  @Input() procurador: UsuarioProcuradorModel = new UsuarioProcuradorModel();
  @Input() tituloConsulta: string = "Classifcador(a)";
  @Output() procuradorSelecionado = new EventEmitter<string>();

  constructor(
    private api: ApiService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  limpar(){
    this.procurador.NomeProcurador = "";
    this.procurador.Codigo = "";
    this.procurador.Id = "";
    this.procuradorSelecionado.emit("");
  }

  consultarPorCodigo(){
    if (!this.procurador.Codigo) {
      Swal.fire('Informe o código para recuperação do procurador.', '', 'error');
      return;
    }

    this.api.consultarUsuarioProcuradorPorNomeCodigo(this.procurador).then((result: any) => {
      if(result){
        if(result.success){
          this.procurador.Id = result.Id;
          this.procurador.NomeProcurador = result.NomeProcurador;
          this.procurador.Codigo = result.Codigo;
          this.procuradorSelecionado.emit(this.procurador.Codigo);
        } else {
          this.limpar();
          Swal.fire(result.message, '', 'error');
        }
      } else {
        this.limpar();
        Swal.fire(result.message || 'A operação não pode ser realizada.', '', 'error');
      }
    });
  }

  consultarPorNome(){
    const dialogRef = this.dialog.open(
      ConsultarProcuradorModalComponent, 
      {
        height: '90%', 
        width: '90%',
      }
    );

    dialogRef.componentInstance.procuradorSelecionado.subscribe((result : any) => {
      if(result){
        this.procurador.Id = result.Id;
        this.procurador.NomeProcurador = result.NomeProcurador;
        this.procurador.Codigo = result.Codigo;
        this.procurador.Id = result.Id;
        this.procuradorSelecionado.emit(this.procurador.Codigo);
      }
    });
    
  }
}
