import { PrincipalService } from './principal.service';
import { AccountService } from './../account/shared/account.service';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from '../shared/local-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { QuestionarioModalComponent } from '../shared/questionario-modal/questionario-modal.component';

@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.scss'],
})
export class PrincipalComponent implements OnInit {
  readonly env = environment;

  listContadores: any[] = [];
  totais: any = null;
  displayedColumns: string[] = ['tipo', 'quantidade', 'color'];
  displayedColumnsExposicoes: string[] = ['nome', 'municipio', 'uf', 'juradoDeAdmissao', 'juradoDePista', 'participar'];
  dataSourceExposicoes: ExposicoesElement[] = [];
  dataSource: RegistroReservadoElement[] = [];

  ELEMENT_DATA: RegistroReservadoElement[] = [];
  EXPOSICOES: ExposicoesElement[] = [];

  constructor(private accountService: AccountService, private principalService: PrincipalService, private localStorage: LocalStorageService, public dialog: MatDialog,) { }

  ngOnInit(): void {
    this.accountService.refreshProperties();
    this.consultarPainelGerencial();
    this.localStorage.remove("formConsulta");
    this.localStorage.remove("page");
    this.showQuestionarioCriador();
  }

  consultarPainelGerencial() {
    const result = this.principalService.consultarPainelGerencial();
    if (result) {
      result
        .then(result => {
          this.listContadores = result.model.ListContadores;
          this.totais = result.model.TotaisNumerosRegistros;

          if ((this.env as any).contexto === 'jersey' && this.totais) {
            this.ELEMENT_DATA.push({
              tipo: 'PO Fêmea',
              quantidade: this.totais.CountPOFemeas,
              color: this.definirCor(this.totais.CountPOFemeas, this.totais.Situacao),
            });
            this.ELEMENT_DATA.push({
              tipo: 'PO Macho',
              quantidade: this.totais.CountPOMacho,
              color: this.definirCor(this.totais.CountPOMacho, this.totais.Situacao),
            });
            this.ELEMENT_DATA.push({
              tipo: 'PO Nacionalizado',
              quantidade: this.totais.CountPONacionalizado,
              color: this.definirCor(this.totais.CountPONacionalizado, this.totais.Situacao),
            });
            this.ELEMENT_DATA.push({
              tipo: 'PC',
              quantidade: this.totais.CountPC,
              color: this.definirCor(this.totais.CountPC, this.totais.Situacao),
            });
            this.ELEMENT_DATA.push({
              tipo: 'PA',
              quantidade: this.totais.CountPA,
              color: this.definirCor(this.totais.CountPA, this.totais.Situacao),
            });
            this.ELEMENT_DATA.push({
              tipo: 'CCG (G)',
              quantidade: this.totais.CountCCG,
              color: this.definirCor(this.totais.CountCCG, this.totais.Situacao),
            });
            this.ELEMENT_DATA.push({
              tipo: 'CCG (Y)',
              quantidade: this.totais.CountCCG2,
              color: this.definirCor(this.totais.CountCCG2, this.totais.Situacao),
            });
          }
          this.dataSource = this.ELEMENT_DATA;

          if (result.model.ListExposicoes) {
            result.model.ListExposicoes.forEach((element: any) => {
              this.EXPOSICOES.push({
                nome: element.NomeExposicao,
                municipio: element.Municipio,
                uf: element.UF,
                juradoDeAdmissao: element.JuradoAdmissao,
                juradoDePista: element.JuradoPista,
                participar: '',
              });
            });
          }
          this.dataSourceExposicoes = this.EXPOSICOES;
        })
        .catch(err => {
          Swal.fire({
            title: 'Houve um erro de conexão com o servidor. Tente novamente ou entre em contato com o administrador',
            showCancelButton: false,
            showConfirmButton: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            icon: 'error',
          });
        });
    } else {
    }
  }

  definirCor(quantidade: number, situacao: number) {
    let maximo = situacao == 1 ? 100 : 50;
    let minimo = situacao == 1 ? 50 : 20;

    if (quantidade >= maximo) {
      return 'color: #00EE76;';
    } else if (quantidade >= minimo && quantidade < maximo) {
      return 'color: #FFD700;';
    } else {
      return 'color: #FF4500;';
    }
  }

  getIcon(evento: number) {
    let icone = '';
    switch (evento) {
      case 52:
        icone = 'fiv';
        break;
      case 50:
        icone = 'padreacao';
        break;
      case 53:
        icone = 'inovulacao';
        break;
      case 51:
        icone = 'bovino';
        break;
    }
    return icone;
  }

  showQuestionarioCriador() {
    if (this.accountService.isCriador) {
      let token: any = this.accountService.getPayloadToken();
      if (token.QuestionarioRespondido == undefined || token.QuestionarioRespondido == null) {
        this.accountService.logoff();
      }

      if (token.QuestionarioRespondido == '0') {
        const dialogRef = this.dialog.open(QuestionarioModalComponent, {
          height: '90%',
          width: '50%',
          autoFocus: true,
          disableClose: true
        });
      }
    }
  }
}

export interface RegistroReservadoElement {
  tipo: string;
  quantidade: number;
  color: string;
}

export interface ExposicoesElement {
  nome: string;
  municipio: string;
  uf: string;
  juradoDeAdmissao: string;
  juradoDePista: string;
  participar: string;
}
