<div class="row" style="margin-top: 15px;">
  <div class="col-lg-12 table-responsive">
    <table class="table table-hover table-header-custom">
      <thead>
        <tr>
          <th>Premiação</th>
          <th>Exposição</th>
          <th>Local</th>
          <th class="text-center">Ano</th>
        </tr>
      </thead>
      <tbody *ngIf="premiacoes.length > 0">
        <tr *ngFor="let premiacao of premiacoes | paginate : {itemsPerPage: pageSize, currentPage: page, totalItems: count};">
          <td>{{ premiacao.Premiacao }}</td>
          <td>{{ premiacao.Exposicao }}</td>
          <td>{{ premiacao.Local }}</td>
          <td class="text-center">{{ premiacao.Ano }}</td>
        </tr>
      </tbody>
      <tbody *ngIf="premiacoes.length === 0">
        <tr class="text-center">
          <td colspan="4">
            <strong>Sem itens para exibição.</strong>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="col-md-12 text-center" *ngIf="premiacoes.length > 0">
    <pagination-controls  
      [autoHide]="true"
      [responsive]="true"
      nextLabel="Próximo" 
      class="srg-paginacao" 
      previousLabel="Anterior" 
      (pageChange)="handlePageChange($event)" 
    >
    </pagination-controls>
  </div>
  
</div>