import { Component, Input, OnInit } from '@angular/core';
import { NumeroTelefonicoModel } from 'src/app/models/NumeroTelefonicoModel';
import { TipoTelefone } from '../enums/Enums';

@Component({
  selector: 'app-telefones-manter',
  templateUrl: './telefones-manter.component.html',
  styleUrls: ['./telefones-manter.component.scss']
})
export class TelefonesManterComponent implements OnInit {

  @Input() readOnly: boolean = false;
  @Input() celular: NumeroTelefonicoModel = new NumeroTelefonicoModel();
  @Input() residencial: NumeroTelefonicoModel = new NumeroTelefonicoModel();

  constructor() { }

  ngOnInit(): void {
    this.celular.tipo = TipoTelefone.Celular;
    this.residencial.tipo = TipoTelefone.Residencial;
  }

}
