import { AccountService } from 'src/app/account/shared/account.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FiltroConsultaPlantelModel } from 'src/app/models/FiltroConsultaPlantelModel';
import { BovinoService } from 'src/app/bovino/shared/bovino.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from '../local-storage.service';
import { QueryStateService } from '../query-state.service';

@Component({
  selector: 'app-filtro-consulta-plantel',
  templateUrl: './filtro-consulta-plantel.component.html',
  styleUrls: ['./filtro-consulta-plantel.component.scss']
})
export class FiltroConsultaPlantelComponent implements OnInit {

  readonly env = environment;

  @Input() formConsulta: FiltroConsultaPlantelModel = new FiltroConsultaPlantelModel();
  situacoesBovino: { value: string, label: string }[] = [];
  racas: { value: string, label: string }[] = [];

  @Output() triggerConsulta: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private accountService: AccountService,
    private bovinoService: BovinoService,
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private queryState: QueryStateService

  ) { }

  ngOnInit(): void {
    this.preencherSituacoes();
    let token: any = this.accountService.getPayloadToken();

    if (token) {
      this.formConsulta.criador.id = token.CriadorId;
      this.formConsulta.criador.codigo = token.CodigoCriador;
      this.formConsulta.criador.nome = token.NomeCriador;
      this.formConsulta.criador.readonly = this.accountService.isCriador;
    }
    this.racas = this.bovinoService.getListaRacas(['consulta_plantel'], true, 'Todas');

    this.recuperarFormulario();


  }

  preencherSituacoes() {
    this.situacoesBovino = [];
    // O filtro utiliza a string "Ativo" ou "Inativo"
    this.situacoesBovino.push({ value: "Ativo", label: "Ativo" });
    this.situacoesBovino.push({ value: "Inativo", label: "Inativo" });
  }

  criadorSelecionado(idCriador: string) {
    this.formConsulta.criador.id = idCriador;

    this.racas = this.bovinoService
      .getListaRacas(['consulta_plantel'], !idCriador, 'Todas')
      .filter(item =>
        (!idCriador || this.formConsulta.criador.racas.some(r => r === item.label.toUpperCase())) && item
      );

    if (this.racas.length === 0) this.racas.push({ label: 'Todas', value: '-1' });

    this.formConsulta.bovino.RacaBovino = !idCriador
      ? '-1'
      : this.racas[0]?.value;
  }


  //consultar = () => this.triggerConsulta.emit();

  consultar() {
    this.queryState.saveFormPlantel(this.formConsulta);
    //obtem a rota da página atual
    this.queryState.getRota(this.route.snapshot.url[0].path);
    this.triggerConsulta.emit();
  }

  private recuperarFormulario() {
    if (this.localStorage.get("rotaAnterior") == this.route.snapshot.url[0].path) {

      // Recupera o state passado pela função de consultas do painel gerencial através da rota
      let state = history.state;

      // Recupera o state da última busca do localStorage
      const queryState = this.localStorage.get("formConsultaPlantel");

      // Caso não haja state do form salvo, inicializa vazio
      //if (!state && !queryState) return;

      // Caso não haja state passado pelo painel gerencial, mas exista um form salvo para essa consulta no storage
      Object.assign(state, queryState);

      if (state.Criador)
        Object.assign(this.formConsulta.criador, state.Criador)

      if (state.Bovino) {
        Object.assign(this.formConsulta.bovino, state.Bovino)
      }
      this.formConsulta.bovino.Id = state.Bovino.Id;
      this.formConsulta.bovino.NomeBovino = state.Bovino.NomeBovino;
      this.formConsulta.bovino.CodigoBovino = state.Bovino.CodigoBovino;
      this.formConsulta.nascimentoAte = state.NascimentoAte;
      this.formConsulta.nascimentoDe = state.NascimentoDe;
      this.formConsulta.criador.codigo = state.Criador.Codigo;
      this.formConsulta.criador.nome = state.Criador.Nome;
      this.formConsulta.criador.id = state.Criador.Id;

      this.triggerConsulta.emit();

    } else {
      this.localStorage.remove("formConsultaPlantel");
      this.localStorage.remove("page");
    }
  }

}
