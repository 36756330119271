<div id="page-content-wrapper">
  <nav class="navbar navbar-expand-lg navbar-light bg-light border-bottom">

    <a id="menu-toggle">
      <div class="media">
        <span (click)="toggle()" class="btn inline-icon material-icons">menu</span>
        <img src="{{logoImage}}"  class="align-self-center mr-3" height="36px"/>
        <div class="media-body">
          <h4 class="mt-0" style="margin-bottom: 0px;">SRG - Sistema de Registro Genealógico</h4>
          <small style="font-size: x-small;">Versão: {{versao}}</small>
        </div>
      </div>
    </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
        <li class="nav-item dropdown" style="    text-align: end;">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{nomeUsuario}}
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" [routerLink]="['/perfil']">Perfil</a>
            <a class="dropdown-item" *ngIf="isCriador && env.contexto === 'wagyu'" [routerLink]="['/registrar_usuario']">Novo Procurador</a>
            <a class="dropdown-item" *ngIf="isCriador && env.contexto === 'wagyu'" [routerLink]="['/criador/apropriar_procurador']">Liberar Procurador</a>
            <a class="dropdown-item" [routerLink]="['/alterar_senha']">Alterar Senha</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" (click)="sair()" >Sair</a>
          </div>
        </li>
      </ul>
    </div>
  </nav>

  <div class="d-flex" id="wrapper">
    <div class="bg-primary border-right" id="sidebar-wrapper">
      <div class="sidebar-heading">
        <br><br>
      </div>

      <div class="list-group list-group-flush">
        <nav class="sb-sidenav accordion">
          <div class="sb-sidenav-menu">
            <div class="nav" id="sidenavAccordion">

              <div style="margin-left: 10px; padding-left: 10px; padding-bottom: 10px; color: #ffffff">
                <h5 class="card-title" style="margin-bottom: 5px; font-size: inherit;">Bem vindo: {{primeiroNomeUsuario}}</h5>
                <p class="card-text">
                  <small style="font-size: x-small">{{funcaoUsuario}} - {{siglaAssociacao}}</small><br>
                </p>
              </div>

              <div *ngFor="let item of listItensMenu" class="list-group-item list-group-item-action bg-primary" style="padding: 0px; border: 0px !important;">

                <!--Item simples-->
                <a *ngIf="item.subItens.length == 0" class="list-group-item list-group-item-action bg-primary"  (click)="navegarPara(item.rota)" >
                  <span *ngIf="item.materialIcon" class="inline-icon material-icons">{{item.materialIcon}}</span>
                  <mat-icon *ngIf="item.svgIcon" svgIcon="{{item.svgIcon}}"></mat-icon>
                  {{item.label}}
                </a>

                <!--Item com subItens-->
                <a *ngIf="item.subItens.length >= 1" class="list-group-item list-group-item-action bg-primary collapsed"  data-toggle="collapse" attr.data-target="#{{item.idCollapseLayout}}" aria-expanded="false">
                  <span *ngIf="item.materialIcon" class="inline-icon material-icons">{{item.materialIcon}}</span>
                  <mat-icon *ngIf="item.svgIcon" svgIcon="{{item.svgIcon}}"></mat-icon>
                  {{item.label}}
                  <div class="sb-sidenav-collapse-arrow">
                    <span class="inline-icon material-icons">expand_less</span>
                  </div>
                </a>

                <div *ngIf="item.subItens.length >= 1" class="collapse" id="{{item.idCollapseLayout}}" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                  <nav class="sb-sidenav-menu-nested nav">

                    <div *ngFor="let subItem of item.subItens" class="list-group-item list-group-item-action bg-primary" style="padding: 0px; border: 0px !important;">

                      <a *ngIf="subItem.subItens.length == 0" class="list-group-item list-group-item-action bg-primary-light" (click)="navegarPara(subItem.rota)">
                        <span *ngIf="subItem.materialIcon" class="inline-icon material-icons">{{subItem.materialIcon}}</span>
                        <mat-icon *ngIf="subItem.svgIcon" svgIcon="{{subItem.svgIcon}}"></mat-icon>
                        {{subItem.label}}
                      </a>

                      <a *ngIf="subItem.subItens.length >= 1" class="list-group-item list-group-item-action bg-primary-light collapsed" data-toggle="collapse" attr.data-target="#{{subItem.idCollapseLayout}}" aria-expanded="false" attr.aria-controls="{{subItem.idCollapseLayout}}">
                        <span *ngIf="subItem.materialIcon" class="inline-icon material-icons">{{subItem.materialIcon}}</span>
                          <mat-icon *ngIf="subItem.svgIcon" svgIcon="{{subItem.svgIcon}}"></mat-icon>
                          {{subItem.label}}
                          <div class="sb-sidenav-collapse-arrow"><span class="inline-icon material-icons">arrow_drop_up</span></div>
                      </a>

                      <div *ngIf="subItem.subItens.length >= 1" class="collapse" id="{{subItem.idCollapseLayout}}" aria-labelledby="headingOne">
                        <nav class="sb-sidenav-menu-nested nav">
                          <div *ngFor="let subItem2 of subItem.subItens" class="list-group-item list-group-item-action bg-primary" style="padding: 0px; border: 0px !important;">

                            <a class="list-group-item list-group-item-action bg-primary-light2"  (click)="navegarPara(subItem2.rota)">
                              <span *ngIf="subItem2.materialIcon" class="inline-icon material-icons" style="margin-left: 10px;">{{subItem2.materialIcon}}</span>
                              <mat-icon *ngIf="subItem2.svgIcon" svgIcon="{{subItem2.svgIcon}}" style="margin-left: 10px;"></mat-icon>
                              {{subItem2.label}}
                            </a>

                          </div>
                        </nav>
                      </div>

                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>

    <div class="container-fluid">
      <nav aria-label="breadcrumb" style="margin-bottom: 1em;">
        <ol class="breadcrumb">
            <li class="breadcrumb-item" [ngClass]="{'active': item.active}" *ngFor="let item of breadcrumbList" (click)="navegarBreadcrumb(item)" ><a>{{item.label}}</a></li>
        </ol>
      </nav>
      <!--<div *ngIf="!isProduction" style="display: flex; justify-content: center;">
        <h2 style="color: red; margin: 0;">Aplicação em ambiente de dev/homolog</h2>
      </div>-->
      <router-outlet (activate)="onActivate()"></router-outlet>
    </div>

  </div>
</div>
