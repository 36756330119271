<div class="row">
  <div class="form-group col-lg-12 col-md-12 col-sm-12">
    <label>Número Laudo de DNA</label>
    <div class="input-group input-group-sm">
      <input
        [disabled]="true"
        type="text"
        class="form-control"
        name="numero_laudo_dna"
        [(ngModel)]="laudo.NumeroLaudo" />
      <div class="input-group-append">
        <!-- <button [disabled]="readonly" matTooltip="Validar número do laudo" class="btn btn-outline-secondary" type="button" (click)="consultarPorNumero()">
          <i class="bi bi-check"></i>
        </button> -->
        <button
          [disabled]="readonly"
          matTooltip="Buscar laudo"
          class="btn btn-outline-secondary"
          type="button"
          (click)="consultaAvancada()">
          <i class="bi bi-search"></i>
        </button>
        <button
          [disabled]="laudo.Arquivo.Id === '' || laudo.Arquivo.Id === '-1'"
          matTooltip="Visualizar Documento"
          class="btn btn-outline-secondary"
          type="button"
          (click)="visualizarDocumento()">
          <i class="fas fa-eye"></i>
        </button>
        <button
          [disabled]="readonly"
          matTooltip="Limpar laudo"
          class="btn btn-outline-secondary"
          type="button"
          (click)="limpar()">
          <i class="bi bi-eraser"></i>
        </button>
      </div>
    </div>
  </div>
</div>
