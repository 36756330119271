<div class="card" style="margin-top: 1em;">
    <div class="card-body">
        <div *ngIf="exibirFiltrosConsulta">
            <app-filtro-consulta-criador [formConsulta]="formConsulta" (triggerConsulta)="botaoConsultar()"></app-filtro-consulta-criador>
            <div class="row justify-content-end">
                <div *ngIf="permiteNovo" class="col-lg-2 col-md-4 col-sm-6">
                    <button type="button" class="btn btn-sm btn-block btn-primary" (click)="novo()">Novo</button>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-6">
                    <button type="button" class="btn btn-sm btn-block btn-primary"
                        (click)="botaoConsultar()">Buscar</button>
                </div>
            </div>
        </div>

        <div *ngIf="criadores.length > 0"  class="row" style="margin-top: 15px;">
            <div class="col-lg-12 table-responsive">
              <table class="table table-hover table-header-custom">
                <thead>
                  <tr>
                    <th>Código</th> 
                    <th>Login</th> 
                    <th>Nome</th> 
                    <th>CPF/CNPJ</th> 
                    <th>Afixo</th> 
                    <th>Situação</th> 
                    <th>Associação</th> 
                    <th class="text-center" width="10%"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let criador of criadores | paginate : {itemsPerPage: pageSize, currentPage: page, totalItems: count};">
                        <td>{{ criador.Codigo }}</td> 
                        <td>{{ criador.Login }}</td> 
                        <td>{{ criador.Nome }}</td> 
                        <td>{{ criador.CPF || criador.CNPJ }}</td> 
                        <td>{{ criador.Afixo }}</td> 
                        <td>{{ criador.DescSituacao }}</td> 
                        <td>{{ criador.CodigoFiliada }}</td> 
                        <td>
                          <a type="button" *ngIf="permiteNovo" matTooltip="Reiniciar senha" (click)="botaReiniciarSenha(criador.Id)">
                            <i class="action-icons fas fa-key"></i>
                          </a>
                          <a 
                            type="button" 
                            matTooltip="Visualizar" 
                            *ngIf="permiteNovo"
                            [queryParams]="{id: criador.Id, readOnly: true}"
                            [routerLink]="['/criador_manter']" 
                          >
                            <i class="action-icons fas fa-eye"></i>
                          </a>
                          <a 
                            type="button" 
                            *ngIf="permiteNovo"
                            matTooltip="Editar" 
                            [queryParams]="{id: criador.Id, readOnly: false}"
                            [routerLink]="['/criador_manter']" 
                          >
                            <i class="action-icons fas fa-edit"></i>
                          </a>
                        </td>
                  </tr>
                </tbody>
              </table>
            </div>
      
            <div class="col-md-12 text-center">
              <pagination-controls 
                class="srg-paginacao"
                previousLabel="Anterior" 
                nextLabel="Próximo" 
                [responsive]="true" 
                (pageChange)="handlePageChange($event)"
                [autoHide]="true"
              >
              </pagination-controls>
            </div>
          
          </div>

    </div>
</div>
