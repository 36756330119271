import { AccountService } from 'src/app/account/shared/account.service';
import { Component, Input, OnInit } from '@angular/core';
import { SituacaoOcorrenciaEvento, SituacaoLoteOcorrencia } from 'src/app/shared/enums/Enums';
import { ActivatedRoute, Router } from '@angular/router';
import { ConstantesAcaoEvento } from '../../../shared/constantes';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/shared/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-opcoes-retorno-consulta-ocorrencia',
  templateUrl: './opcoes-retorno-consulta-ocorrencia.component.html',
  styleUrls: ['./opcoes-retorno-consulta-ocorrencia.component.scss']
})
export class OpcoesRetornoConsultaOcorrenciaComponent implements OnInit {

  @Input() evento: any = {};

  isMulta: boolean = false;
  isDNA: boolean = false;
  isVisualizar: boolean = false;
  isEditar: boolean = false;
  isDeliberar: boolean = false;
  isInconsistencia: boolean = false;
  inconsistencia: string = "";
  isDeletar: boolean = false;

  constructor(private accountService: AccountService, private router: Router, public api: ApiService, private route: ActivatedRoute,) { }

  ngOnInit(): void {
    if (this.evento) {
      if (this.evento.IndInconsitencia) {
        this.isInconsistencia = true;
        this.inconsistencia = this.evento.Inconsistencia
      }

      if (this.evento.ImputarMulta) {
        this.isMulta = true;
      }

      if (this.evento.SolicitarDna) {
        this.isDNA = true;
      }

      if (this.permiteDeliberacao() && this.accountService.checkRole(this.evento.TipoEvento + "_Deliberar")) {
        this.isDeliberar = true;
      }

      if (this.permiteEdicao()) {
        if (this.accountService.checkRole(this.evento.TipoEvento + "_Edit")) {
          this.isEditar = true;
        }
      } else {
        this.isVisualizar = true;
      }
      if (this.evento.SituacaoOcorrencia == SituacaoOcorrenciaEvento.AguardandoResolucao) {
        this.isDeletar = true;
        this.evento.IdOcorrenciaSelecionada = this.evento.Id;
        this.evento.Situacao = SituacaoOcorrenciaEvento.AguardandoResolucao;
      }
    }
  }

  permiteDeliberacao() {
    if ((environment as any).contexto === 'wagyu' && this.accountService.isVeterinario) {
      const token: any = this.accountService.getPayloadToken();

      return (
        (this.evento.SituacaoLote === SituacaoLoteOcorrencia.Protocolado ||
          this.evento.SituacaoLote === SituacaoLoteOcorrencia.ProcessadoComPendencias) &&
        this.evento.SituacaoOcorrencia === SituacaoOcorrenciaEvento.AguardandoAprovacaoVeterinario &&
        (token && token.CRMV === (this.evento.CRMV.trim().replace("/", " ")))
      );
    }

    // Se for superintendente ou admin
    if (this.accountService.isSuperintendente || this.accountService.isAdmin) {
      return (
        (
          this.evento.SituacaoLote === SituacaoLoteOcorrencia.Protocolado ||
          this.evento.SituacaoLote === SituacaoLoteOcorrencia.ProcessadoOk ||
          this.evento.SituacaoLote === SituacaoLoteOcorrencia.ProcessadoComPendencias ||
          this.evento.SituacaoLote === SituacaoLoteOcorrencia.Rejeitado
        )
        &&
        (
          this.evento.SituacaoOcorrencia === SituacaoOcorrenciaEvento.AguardandoAprovacao ||
          // NOTE: Para alguns eventos que estão protocolados, mas a situação da ocorrência está "pendente" ou "Aguardando Resolucao"
          this.evento.SituacaoOcorrencia === SituacaoOcorrenciaEvento.Pendente ||
          this.evento.SituacaoOcorrencia === SituacaoOcorrenciaEvento.AguardandoAprovacao ||
          this.evento.SituacaoOcorrencia === SituacaoOcorrenciaEvento.AprovadoComPendencias
        )
      );
    } else {
      return (
        !this.accountService.isCriador
        &&
        (
          this.evento.SituacaoLote === SituacaoLoteOcorrencia.Protocolado ||
          this.evento.SituacaoLote === SituacaoLoteOcorrencia.ProcessadoOk ||
          this.evento.SituacaoLote === SituacaoLoteOcorrencia.ProcessadoComPendencias ||
          this.evento.SituacaoLote === SituacaoLoteOcorrencia.Rejeitado
        )
        &&
        (
          this.evento.SituacaoOcorrencia === SituacaoOcorrenciaEvento.Pendente ||
          this.evento.SituacaoOcorrencia === SituacaoOcorrenciaEvento.AprovadoComPendencias ||
          this.evento.SituacaoOcorrencia === SituacaoOcorrenciaEvento.AguardandoResolucao ||
          // p/ wagyu - baixa ou transferencia em atraso deve ser aprovada por adm ou superintendente
          // Padreacao não pode ter aprovação automática
          ((environment as any).contexto === 'wagyu' &&
            (this.evento.TipoEvento === 'Baixa' ||
              this.evento.TipoEvento === 'Transferencia' ||
              this.evento.TipoEvento === 'Fiv' ||
              this.evento.TipoEvento === 'Nascimento' ||
              this.evento.TipoEvento === 'Padreacao' ||
              this.evento.TipoEvento === 'Inovulacao' ||
              this.evento.TipoEvento === 'Inspecao' ||
              this.evento.TipoEvento === 'Pesagem') &&
            this.evento.SituacaoOcorrencia === SituacaoOcorrenciaEvento.AguardandoAprovacao
          )
        )
      );
    }
  }

  permiteEdicao() {
    return (this.evento.SituacaoLote === SituacaoLoteOcorrencia.EmElaboracao) && (this.evento.SituacaoOcorrencia === SituacaoOcorrenciaEvento.Pendente);
  }

  visualizar() {
    if (this.evento.TipoEvento.toLowerCase() == 'baixa' && this.route.snapshot.url[0].path == 'abatecomavalcarcaca_consultar') {
      this.router.navigate(['abatecomavalcarcaca_manter_pos', { e: btoa(this.evento.Id), a: btoa(ConstantesAcaoEvento.Visualizar) }]);
    } else {
      this.router.navigate([this.evento.TipoEvento.toLowerCase() + '_manter', { e: btoa(this.evento.Id), a: btoa(ConstantesAcaoEvento.Visualizar) }]);
    }
  }

  editar() {
    if (this.evento.TipoEvento.toLowerCase() == 'baixa' && this.route.snapshot.url[0].path == 'abatecomavalcarcaca_consultar') {
      this.router.navigate(['abatecomavalcarcaca_manter_pos', { e: btoa(this.evento.Id), a: btoa(ConstantesAcaoEvento.Editar) }]);
    } else {
      this.router.navigate([this.evento.TipoEvento.toLowerCase() + '_manter', { e: btoa(this.evento.Id), a: btoa(ConstantesAcaoEvento.Editar) }]);
    }
  }

  deliberar() {
    if (this.evento.TipoEvento.toLowerCase() == 'baixa' && this.route.snapshot.url[0].path == 'abatecomavalcarcaca_consultar') {
      this.router.navigate(['abatecomavalcarcaca_manter_pos', { e: btoa(this.evento.Id), a: btoa(ConstantesAcaoEvento.Deliberar) }]);
    } else {
      this.router.navigate([this.evento.TipoEvento.toLowerCase() + '_manter', { e: btoa(this.evento.Id), a: btoa(ConstantesAcaoEvento.Deliberar) }]);
    }
  }

  deletar() {
    Swal.fire({
      title: 'Confirmação',
      html: 'Confirma a remoção do registro?',
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim',
    }).then(resultConfirmacao => {
      if (resultConfirmacao.isConfirmed) {
        this.api.limparEventoAssociadoNascimento(this.evento.Id);
        this.api.deleteItem(this.evento, this.evento.TipoEvento).then((result: any) => {
          if (result.success) {
            Swal.fire({ title: "Sucesso!", html: 'Registro removido com sucesso.', icon: 'success' }).then(
              () => {
                window.location.reload();
              }
            );
          } else {
            Swal.fire(result.message, '', 'error');
          }
        });
      }
    });
  }
}
