import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BovinoModel } from '../models/BovinoModel';
import { FiltroConsultaEventosModel } from '../models/FiltroConsultaEventosModel';
import { FiltroConsultaCriadoresModel } from '../models/FiltroConsultaCriadoresModel';
import { FiltroConsultaUsuariosModel } from '../models/FiltroConsultaUsuariosModel';
import { FiltroConsultaNacionalizacaoModel } from '../models/FiltroConsultaNacionalizacaoModel';
import { FiltroExposicaoModel } from '../models/FiltroConsultaExposicaoModel';
import { CriadorModel, ValidaCriadorModel } from '../models/CriadorModel';
import { FiltroConsultaPlantelModel } from '../models/FiltroConsultaPlantelModel';
import { FiltroConsultaCertificadosModel } from '../models/FiltroConsultaCertificadosModel';
import { FiltroConsultaCertificadosPendentesModel } from '../models/FiltroConsultaCertificadosPendentesModel';
import { ExposicaoModel } from '../models/ExposicaoModel';
import { FiltroConsultaControleLeiteiro } from '../models/FiltroConsultaControleLeiteiroModel';
import { ControleLeiteiroModel } from '../models/ControleLeiteiroModel';
import { AlterarSenhaModel } from '../models/AlterarSenhaModel';
import { UsuarioModel, ValidaCpfUsuario } from '../models/UsuarioModel';
import { VeterinarioModel } from '../models/VeterinarioModel';
import { PerfilModel } from '../models/PerfilModel';
import {
  IJsonResultModel,
  IRequestManterBovino,
  IRequestVisualizarRegistrosReservados,
  IResponseConsultaCriadorId,
  IResponseConsultaLaudoDna,
  IResponseConsultaOcorrenciasPorSituacao,
  IResponseConsultaRegistrosReservados,
  IResponseConsultaRegistrosReservadosFiltro,
  IResponseConsultaSugestaoFiv,
  IResponseConsultaSugestaoNascimento,
  IResponseRecuperarLaudoPorId,
  IResponseVisualizarLaudoDna,
  IResponseVisualizarRegistrosReservados,
} from './interfaces/Interfaces';
import { InspetorModel } from '../models/InspetorModel';
import { ProcuradorModel } from '../models/ProcuradorModel';
import { FiltroConsultaEventosBovinoModel } from '../models/FiltroConsultaEventosBovinoModel';
import { CertificadoModel } from '../models/CertificadoModel';
import { TecnicoModel } from '../models/TecnicoModel';
import { FiltroConsultaRegistrosReservadosModels } from '../models/FiltroConsultaRegistrosReservadosModels';
import { ReservarRegistrosInspetorModel } from '../models/ReservarRegistrosInspetorModel';
import { ReservarRegistrosAssociacaoModel } from '../models/ReservarRegistrosAssociacaoModel';
import { LaudoDNAModel } from '../models/LaudoDNAModel';
import { FiltroConsultaLaudoDnaModel } from '../models/FiltroConsultaLaudoDnaModel';
import { ConsultaSugestaoParaNasciomentoModel } from '../models/ConsultaSugestaoParaNasciomentoModel';
import { ConsultaSugestaoFivModel } from '../models/ConsultaSugestaoFivModel';
import { ConsultaOcorrenciasPorSituacaoModel } from '../models/ConsultaOcorrenciasPorSituacaoModel';
import { ConsultaSubmetidosModel } from '../models/ConsultaSubmetidosModel';
import { OcorrenciaEventoApropriacaoTecnicoModel } from '../models/OcorrenciaEventoApropriacaoTecnicoModel';
import { TipoGerarRelatorio } from './enums/Enums';
import { RelacaoPosAbateComAvalCarcacaModel } from '../models/RelacaoPosAbateComAvalCarcacaModel';
import { OcorrenciaEventoApropriacaoVeterinarioModel } from '../models/OcorrenciaEventoApropriacaoVeterinarioModel';
import { RolesKeys } from '../account/shared/RolesKeys';
import { OcorrenciaEventoApropriacaoProcuradorModel } from '../models/OcorrenciaEventoApropriacaoProcuradorModel';
import { UsuarioProcuradorModel } from '../models/UsuarioProcuradorModel';
import { QuestionarioManjeoModel } from '../models/QuestionarioManejoModel';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private readonly contexto: string = (environment as any).contexto;

  constructor(private http: HttpClient, private datePipe: DatePipe) { }

  //#region painel gerencial

  async consultarPainelGerencial(formConsulta: ConsultaSubmetidosModel) {
    const data = {
      PeriodoFixo: formConsulta.periodo,
      PeriodoDe: this.datePipe.transform(formConsulta.dataInicial, 'yyyy-MM-dd'),
      PeriodoAte: this.datePipe.transform(formConsulta.dataFinal, 'yyyy-MM-dd'),
      IdCriador: formConsulta.criador.id,
      FiliadaId: formConsulta.filiadaId,
    };

    return await this.http.post<any>(`${environment.api}/home/formularios_submetidos`, data).toPromise();
  }

  async recuperarOcorrenciasPorSituacao(
    formConsulta: ConsultaOcorrenciasPorSituacaoModel,
    page: number,
    pageSize: number
  ) {
    const data = {
      PeriodoFixo: formConsulta.periodo,
      PeriodoDe: this.datePipe.transform(formConsulta.dataInicial, 'yyyy-MM-dd'),
      PeriodoAte: this.datePipe.transform(formConsulta.dataFinal, 'yyyy-MM-dd'),
      IdCriador: formConsulta.criador.id,
      FiliadaId: formConsulta.filiadaId,
      SituacaoInt: formConsulta.SituacaoInt,
      EventoInt: formConsulta.EventoInt,
      Page: page,
      PageSize: pageSize,
    };

    return await this.http
      .post<IResponseConsultaOcorrenciasPorSituacao>(`${environment.api}/home/recuperar_ocorrencias`, data)
      .toPromise();
  }

  async manterQuestionarioManejo(questionarioModel: QuestionarioManjeoModel) {
    const data = {
      Criador: questionarioModel.Criador,
      AoPeVaca: questionarioModel.AoPeVaca,
      MamadaControlada: questionarioModel.MamadaControlada,
      AoPeVacaCreepFeeding: questionarioModel.AoPeVacaCreepFeeding,
      AleitamentoArtificial: questionarioModel.AleitamentoArtificial,
      IdadeDesmame: questionarioModel.IdadeDesmame,
      PesoDesmame: questionarioModel.PesoDesmame,
      ManejoConfinado: questionarioModel.ManejoConfinado,
      ManejoSemiConfinado: questionarioModel.ManejoSemiConfinado,
      ManejoPasto: questionarioModel.ManejoPasto,
      TempoManejoRecria: questionarioModel.TempoManejoRecria,
      PesoFinalRecria: questionarioModel.PesoFinalRecria,
      TerminacaoConfinamento: questionarioModel.TerminacaoConfinamento,
      TerminacaoSemiConfinamento: questionarioModel.TerminacaoSemiConfinamento,
      TerminacaoPasto: questionarioModel.TerminacaoPasto,
      TempoTerminacao: questionarioModel.TempoTerminacao,
      PesoFinalTerminacao: questionarioModel.PesoFinalTerminacao,
      Fazenda: questionarioModel.Fazenda,
      QuestionarioId: questionarioModel.QuestionarioId,
      TerminacaoOutro: questionarioModel.TerminacaoOutro,
      TerminacaoOutroDetalhes: questionarioModel.TerminacaoOutroDetalhes,
      IdadeCastracao: questionarioModel.IdadeCastracao
    };
    return await this.http.post<any>(`${environment.api}/home/manter_questionario_manejo`, data).toPromise();
  }

  async consultarQuestionarioManejo(criadorId: string, fazendaId: string) {
    const url =
      `${environment.api}/home/consultar_questionario_manejo?criadorId=${criadorId}&fazendaId=${fazendaId}`;

    return await this.http.get<any>(url).toPromise();
  }
  //#endregion

  //#region Criador

  async consultarCriadorPorCodigo(criador: any) {
    let data = {
      Codigo: criador.codigo,
      ConsultaEmTodasFiliadas: criador.consultaEmTodasFiliadas,
    };

    return await this.http.post<any>(`${environment.api}/criador/criador_por_codigo`, data).toPromise();
  }

  async consultarCriador(
    codigo: string,
    nome: string,
    situacao: number,
    page: number,
    pageSize: number,
    consultaEmTodasFiliadas: boolean,
    filiadaId: string,
    apenasFrigorifico?: boolean
  ) {
    let data = {
      CodigoCriador: codigo,
      NomeCriador: nome,
      Situacao: situacao,
      Page: page,
      PageSize: pageSize,
      ConsultaEmTodasFiliadas: consultaEmTodasFiliadas,
      FiliadaId: filiadaId,
      Frigorifico: apenasFrigorifico,
    };

    return await this.http.post<any>(`${environment.api}/criador/criadores`, data).toPromise();
  }

  async validarCriador(dados: ValidaCriadorModel) {
    const data = {
      GrupoId: dados.grupo,
      CpfOuCnpj: dados.cpf_cnpj,
      FiliadaId: dados.vinculacao,
      TipoUsuario: dados.tipoPessoa,
    };

    return await this.http.post<any>(`${environment.api}/usuario/validar_cpf`, data).toPromise();
  }

  async registrarCriador(dados: CriadorModel, criadorValido: ValidaCriadorModel) {
    const data = {
      Afixo: dados.afixo,
      Socio: dados.socio,
      Login: dados.usuario,
      Celular: dados.celular,
      Endereco: dados.endereco,
      Suspenso: dados.suspenso,
      Codigo: dados.codigo,
      Situacao: dados.situacao,
      UsuarioId: dados.usuarioId,
      Telefone: dados.residencial,
      Racas: dados.racas,
      PosicaoAfixo: dados.posicaoAfixo,
      FiliadaId: criadorValido.vinculacao,
      Frigorifico: dados.frigorifico,
      TipoServicoInspecaoFrigorifico: dados.tipoServicoInspecao,
      NumeroServicoInspecaoFrigorifico: dados.numeroServicoInspecao,
      TipoUsuario: criadorValido.tipoPessoa,
      Email: {
        Email: dados.email,
      },
      Fazenda: {
        Id: dados.fazendas[0].Id,
        Nome: dados.fazendas[0].Nome,
        Endereco: dados.fazendas[0].Endereco,
      },
      Fazendas: dados.fazendas,
      AfixosCriador: dados.afixos,
      Contexto: this.contexto,
      Observacao: dados.observacao,
      EmailSecundario: {
        Email: dados.emailSecundario,
      },
    };
    Object.assign(
      data,
      criadorValido.tipoPessoa === 1
        ? { Nome: dados.nome, Cpf: criadorValido.cpf_cnpj }
        : { RazaoSocial: dados.nome, Cnpj: criadorValido.cpf_cnpj, InscricaoEstadual: dados.inscricaoEstadual }
    );

    return await this.http.post<any>(`${environment.api}/criador/registrar`, data).toPromise();
  }

  async verificarAfixoDisponivel(afixo: string) {
    const data = {
      Afixo: afixo,
    };

    return await this.http.post<any>(`${environment.api}/criador/afixo_disponivel`, data).toPromise();
  }

  async atualizarAfixoPrincipal(codigoCriador: string, idAfixo: string) {
    const data = {
      CodigoCriador: codigoCriador,
      IdAfixo: idAfixo
    }
    return await this.http.post<any>(`${environment.api}/criador/atualizar_afixo`, data).toPromise();
  }

  async consultarProcuradores(id: string, pr: any) {
    const data = {
      Id: id,
      Pr: pr,
    };
    return await this.http.post<any>(`${environment.api}/criador/consulta_procuradores`, data).toPromise();
  }

  async alterarProcurador(procurador: ProcuradorModel, idCriador: string) {
    const data = {
      usuarioId: procurador.Usuario.usuario,
      criadorId: idCriador,
      codigo: procurador.Usuario.codigo,
      afixo: procurador.Afixo,
      login: procurador.Login,
      nome: procurador.Usuario.nome,
      cpf: procurador.CPF.cpf,
      cnpj: procurador.CNPJ,
      email: {
        Tipo: procurador.TipoEmail,
        email: procurador.Usuario.email,
      },
      situacao: procurador.Usuario.situacao,
      telefone: procurador.Usuario.residencial,
      celular: procurador.Usuario.celular,
      grupoUsuario: procurador.GrupoUsuario,
      endereco: procurador.Usuario.endereco,
      filiadaId: procurador.FiliadaId,
      fazenda: {
        id: procurador.IdFazenda,
        nome: procurador.Fazenda.Nome,
        identificacaoFazenda: procurador.IdentificacaoFazenda,
        endereco: procurador.Fazenda.Endereco,
      },
      codigoAcesso: procurador.CodigoAcesso,
      tipoUsuario: procurador.TipoUsuario,
    };

    return await this.http.post<any>(`${environment.api}/criador/manter_procurador`, data).toPromise();
  }

  //#endregion

  //#region Bovino

  async registrarBovino(dados: IRequestManterBovino) {
    const data = {
      Contexto: this.contexto,
      Pai: dados.pai,
      Mae: dados.mae,
      Criador: dados.criador,
      Proprietario: dados.proprietario,

      Id: dados.bovino.Id,
      Raca: dados.bovino.RacaBovino,
      Nome: dados.bovino.NomeBovino,
      Sexo: dados.bovino.SexoBovino,
      AvalacaoGenealogicaId: dados.bovino.AvalacaoGenealogicaId,
      PesoAoNascer: dados.bovino.PesoAoNascer,
      Brinco: dados.bovino.Brinco,
      TipoMotivoBaixa: dados.bovino.MotivoBaixaAnimal,
      Origem: dados.bovino.OrigemBovino,
      Codigo: dados.bovino.CodigoBovino,
      Situacao: dados.bovino.SituacaoBovino,
      OrelhaDireita: dados.bovino.OrelhaDireita,
      Nacionalizado: dados.bovino.Nacionalizado,
      CodigoCentral: dados.bovino.CodigoCentral,
      GrauSangue: dados.bovino.SangueBovino.grau,
      SituacaoSRG: dados.bovino.SituacaoSRGBovino,
      AnimalDeCentral: dados.bovino.AnimalDeCentral,
      TipoGrauSangue: dados.bovino.SangueBovino.tipo,
      DataNascimento: dados.bovino.DataNascimentoBovino,
    };

    return await this.http.post<any>(`${environment.api}/bovino/registrar`, data).toPromise();
  }

  async consultarBovinoPorCodigo(bovino: BovinoModel) {
    let data = {
      Tipo: bovino.TipoBusca,
      CodigoBovino: bovino.CodigoBovino,
      ConsideraInativos: bovino.ConsideraInativos,
      IdProprietarioConsulta: bovino.IdProprietarioConsulta,
      TipoEvento: bovino.tipoEvento,
      ConsultaAnimalTodosCriadores: bovino.ConsultaAnimalTodosCriadores,
    };

    return await this.http.post<any>(`${environment.api}/bovino/bovino_por_codigo`, data).toPromise();
  }

  async consultarBovinos(bovino: BovinoModel, page: number, pageSize: number) {
    let data = {
      Tipo: bovino.TipoBusca,
      CodigoBovino: bovino.CodigoBovino,
      NomeBovino: bovino.NomeBovino,
      ConsideraInativos: bovino.ConsideraInativos,
      IdProprietarioConsulta: bovino.IdProprietarioConsulta,
      TipoEvento: bovino.tipoEvento,
      ConsultaAnimalTodosCriadores: bovino.ConsultaAnimalTodosCriadores,
      Page: page,
      PageSize: pageSize,
    };

    return await this.http.post<any>(`${environment.api}/bovino/bovinos`, data).toPromise();
  }

  async consultarRegistrosReservados() {
    return await this.http
      .get<IResponseConsultaRegistrosReservados>(`${environment.api}/bovino/recuperar_registros_reservados`)
      .toPromise();
  }

  async visualizarRegistrosReservados(dados: IRequestVisualizarRegistrosReservados) {
    const url =
      `${environment.api}/bovino/visualizar_registros_reservados` +
      `?para=${dados.reservadoPara}` +
      `&tipo=${dados.tipo}` +
      `&situacao=${dados.situacao}`;

    return await this.http.get<IResponseVisualizarRegistrosReservados>(url).toPromise();
  }

  async consultarRegistrosReservadosComFiltro(dados: FiltroConsultaRegistrosReservadosModels) {
    const data = {
      FiliadaId: dados.filiadaId,
      Tipo: dados.tipo,
      Inspetor: dados.inspetor,
    };

    return await this.http
      .post<IResponseConsultaRegistrosReservadosFiltro>(
        `${environment.api}/bovino/consultar_registros_reservados_filtro`,
        data
      )
      .toPromise();
  }

  async reservarRegistroInspetor(dados: ReservarRegistrosInspetorModel) {
    const data = {
      FiliadaId: dados.FiliadaId,
      Tipo: dados.Tipo,
      Quantidade: dados.Quantidade,
      Inspetor: dados.Inspetor,
    };

    return await this.http.post<any>(`${environment.api}/bovino/reservar_registros_para_inspetor`, data).toPromise();
  }

  async reservarRegistroAssociacao(dados: ReservarRegistrosAssociacaoModel) {
    const data = {
      FiliadaId: dados.FiliadaId,
      Tipo: dados.Tipo,
      Quantidade: dados.Quantidade,
    };

    return await this.http.post<any>(`${environment.api}/bovino/reservar_registros_para_associacao`, data).toPromise();
  }

  async consultarAnimaisPreAbate(idProprietario: string) {
    const url =
      `${environment.api}/bovino/consultar_animais_pre_abate?idProprietario=${idProprietario}`;

    return await this.http.get<any>(url).toPromise();
  }

  //#endregion

  //region veterinario
  async consultarVeterinarioPorCrmv(veterinario: VeterinarioModel) {
    let data = {
      crmv: veterinario.crmv,
    };

    return await this.http.post<any>(`${environment.api}/veterinario/veterinario_por_crmv`, data).toPromise();
  }
  //end region

  //#endregion Base Eventos

  async consultarEventoEmElaboracaoPorCriador(idCriador: string, evento: string) {
    let data = {
      IdCriador: idCriador,
    };

    return await this.http
      .post<any>(`${environment.api}/${evento}/buscar_ocorrencia_em_elaboracao_por_criador`, data)
      .toPromise();
  }

  async consultarEventoPorId(idOcorrencia: string, evento: string) {
    let data = {
      IdOcorrencia: idOcorrencia,
    };

    return await this.http.post<any>(`${environment.api}/${evento}/recuperar_ocorrencia_por_id`, data).toPromise();
  }

  async salvarEvento(loteOcorrencia: any, evento: string) {
    return await this.http.post<any>(`${environment.api}/${evento}/salvar`, loteOcorrencia).toPromise();
  }

  async descartarEvento(idLote: string, evento: string) {
    let data = {
      Fields: idLote,
    };
    return await this.http.post<any>(`${environment.api}/${evento}/descartar`, data).toPromise();
  }

  async deleteItem(loteOcorrencia: any, evento: string) {
    return await this.http.post<any>(`${environment.api}/${evento}/delete_item`, loteOcorrencia).toPromise();
  }

  async enviar(loteOcorrencia: any, evento: string) {
    return await this.http.post<any>(`${environment.api}/${evento}/enviar`, loteOcorrencia).toPromise();
  }

  async enviarEAprovar(loteOcorrencia: any, evento: string) {
    return await this.http.post<any>(`${environment.api}/${evento}/enviar_aprovar`, loteOcorrencia).toPromise();
  }

  async aprovar(loteOcorrencia: any, evento: string) {
    return await this.http.post<any>(`${environment.api}/${evento}/aprovar`, loteOcorrencia).toPromise();
  }

  async aprovarComPendencia(loteOcorrencia: any, evento: string) {
    return await this.http.post<any>(`${environment.api}/${evento}/aprovar_com_pendencia`, loteOcorrencia).toPromise();
  }

  async rejeitar(loteOcorrencia: any, evento: string) {
    return await this.http.post<any>(`${environment.api}/${evento}/rejeitar`, loteOcorrencia).toPromise();
  }
  async revisar(loteOcorrencia: any, evento: string) {
    return await this.http.post<any>(`${environment.api}/${evento}/revisar`, loteOcorrencia).toPromise();
  }

  async aprovarTodos(loteOcorrencia: any, evento: string) {
    return await this.http.post<any>(`${environment.api}/${evento}/aprovar_todos`, loteOcorrencia).toPromise();
  }

  async arquivosDoLote(idLote: string) {
    let data = {
      IdLote: idLote,
    };
    return await this.http.post<any>(`${environment.api}/commons/arquivos_lote`, data).toPromise();
  }

  async uploadArquivoDoLote(arquivo: any) {
    return await this.http.post<any>(`${environment.api}/commons/uploado_arquivo_lote`, arquivo).toPromise();
  }

  async arquivosDaOcorrencia(idOcorrencia: string) {
    let data = {
      IdOcorrencia: idOcorrencia,
    };
    return await this.http.post<any>(`${environment.api}/commons/arquivos_ocorrencia`, data).toPromise();
  }

  async uploadArquivoDaOcorrencia(arquivo: any) {
    return await this.http.post<any>(`${environment.api}/commons/uploado_arquivo_ocorrencia`, arquivo).toPromise();
  }

  async downloadArquivo(arquivo: any) {
    return await this.http.post<Blob>(`${environment.api}/commons/download`, arquivo).toPromise();
  }

  async excluirArquivo(arquivo: any) {
    return await this.http.post<Blob>(`${environment.api}/commons/excluir_arquivo`, arquivo).toPromise();
  }

  //#endregion

  //#region Padreação

  async consultarPadreacoes(consulta: FiltroConsultaEventosModel, page: number, pageSize: number) {
    let data = {
      Protocolo: consulta.formularioProtocolo,
      Situacao: consulta.situacao,
      PeriodoPadreacao: {
        PeriodoDe: this.datePipe.transform(consulta.eventoDe, 'yyyy-MM-dd'),
        PeriodoAte: this.datePipe.transform(consulta.eventoAte, 'yyyy-MM-dd'),
      },
      PeriodoComunicacao: {
        PeriodoDe: this.datePipe.transform(consulta.comunicacaoDe, 'yyyy-MM-dd'),
        PeriodoAte: this.datePipe.transform(consulta.comunicacaoAte, 'yyyy-MM-dd'),
      },
      Criador: {
        Id: consulta.criador.id,
      },
      Animal: {
        Id: consulta.bovino.Id,
      },
      Page: page,
      PageSize: pageSize,
    };

    return await this.http.post<any>(`${environment.api}/padreacao/index`, data).toPromise();
  }

  //#endregion

  //#region Nascimento

  async consultarNascimentos(consulta: FiltroConsultaEventosModel, page: number, pageSize: number) {
    let data = {
      Protocolo: consulta.formularioProtocolo,
      Situacao: consulta.situacao,
      PeriodoNascimento: {
        PeriodoDe: this.datePipe.transform(consulta.eventoDe, 'yyyy-MM-dd'),
        PeriodoAte: this.datePipe.transform(consulta.eventoAte, 'yyyy-MM-dd'),
      },
      PeriodoComunicacao: {
        PeriodoDe: this.datePipe.transform(consulta.comunicacaoDe, 'yyyy-MM-dd'),
        PeriodoAte: this.datePipe.transform(consulta.comunicacaoAte, 'yyyy-MM-dd'),
      },
      Criador: {
        Id: consulta.criador.id,
      },
      Animal: {
        Id: consulta.bovino.Id,
      },
      Page: page,
      PageSize: pageSize,
    };

    return await this.http.post<any>(`${environment.api}/nascimento/index`, data).toPromise();
  }

  async consultarSugestaoParaNascimento(
    formulario: ConsultaSugestaoParaNasciomentoModel,
    pageSize: number,
    page: number
  ) {
    const data = {
      CriadorId: formulario.CriadorId,
      MaeId: formulario.Mae.Id,
      CodigoBovino: formulario.Mae.CodigoBovino,
      NomeBovino: formulario.Mae.NomeBovino,
      PeriodoSugestao: {
        PeriodoDe: this.datePipe.transform(formulario.SugestaoDe, 'yyyy-MM-dd'),
        PeriodoAte: this.datePipe.transform(formulario.SugestaoAte, 'yyyy-MM-dd'),
      },
      PageSize: pageSize,
      Page: page,
      AnimalCadastrado: formulario.AnimalCadastrado,
    };

    return await this.http
      .post<IResponseConsultaSugestaoNascimento>(`${environment.api}/nascimento/consultar_sugestao`, data)
      .toPromise();
  }

  async limparEventoAssociadoNascimento(ocorrenciaId: string) {
    let data = {
      OcorrenciaEventoId: ocorrenciaId,
    };

    return await this.http.post<any>(`${environment.api}/nascimento/limpar_eventoAssociado`, data).toPromise();
  }

  //#endregion

  //#region Pesagem

  async consultarPesagens(consulta: FiltroConsultaEventosModel, page: number, pageSize: number) {
    let data = {
      Protocolo: consulta.formularioProtocolo,
      Situacao: consulta.situacao,
      PeriodoPesagem: {
        PeriodoDe: this.datePipe.transform(consulta.eventoDe, 'yyyy-MM-dd'),
        PeriodoAte: this.datePipe.transform(consulta.eventoAte, 'yyyy-MM-dd'),
      },
      PeriodoComunicacao: {
        PeriodoDe: this.datePipe.transform(consulta.comunicacaoDe, 'yyyy-MM-dd'),
        PeriodoAte: this.datePipe.transform(consulta.comunicacaoAte, 'yyyy-MM-dd'),
      },
      Criador: {
        Id: consulta.criador.id,
      },
      Animal: {
        Id: consulta.bovino.Id,
      },
      Page: page,
      PageSize: pageSize,
    };

    return await this.http.post<any>(`${environment.api}/pesagem/index`, data).toPromise();
  }

  //#endregion

  //#region TeFiv

  async consultarTeFiv(consulta: FiltroConsultaEventosModel, page: number, pageSize: number) {
    let data = {
      Protocolo: consulta.formularioProtocolo,
      Situacao: consulta.situacao,
      PeriodoColeta: {
        PeriodoDe: this.datePipe.transform(consulta.eventoDe, 'yyyy-MM-dd'),
        PeriodoAte: this.datePipe.transform(consulta.eventoAte, 'yyyy-MM-dd'),
      },
      PeriodoComunicacao: {
        PeriodoDe: this.datePipe.transform(consulta.comunicacaoDe, 'yyyy-MM-dd'),
        PeriodoAte: this.datePipe.transform(consulta.comunicacaoAte, 'yyyy-MM-dd'),
      },
      Criador: {
        Id: consulta.criador.id,
      },
      Animal: {
        Id: consulta.bovino.Id,
      },
      Contexto: this.contexto,
      Page: page,
      PageSize: pageSize,
    };

    return await this.http.post<any>(`${environment.api}/fiv/index`, data).toPromise();
  }

  //#endregion

  //#region Abate com aval carcaca
  async consultarAbateComAvalCaracaca(consulta: FiltroConsultaEventosModel, page: number, pageSize: number) {
    const data = {
      Protocolo: consulta.formularioProtocolo,
      Situacao: consulta.situacao,
      PeriodoAbate: {
        PeriodoDe: this.datePipe.transform(consulta.eventoDe, 'yyyy-MM-dd'),
        PeriodoAte: this.datePipe.transform(consulta.eventoAte, 'yyyy-MM-dd'),
      },
      PeriodoComunicacao: {
        PeriodoDe: this.datePipe.transform(consulta.comunicacaoDe, 'yyyy-MM-dd'),
        PeriodoAte: this.datePipe.transform(consulta.comunicacaoAte, 'yyyy-MM-dd'),
      },
      Criador: { Id: consulta.criador.id },
      Animal: { Id: consulta.bovino.Id },
      Page: page,
      PageSize: pageSize,
      ConsultaCarcaca: consulta.ocorrenciaCarcaca
    };

    return await this.http.post<any>(`${environment.api}/abate-com-aval-carcaca/index`, data).toPromise();
  }

  async consultarRelacaoAbateComAvalCaracaca(
    { idBovino, idOcorrencia }: { idBovino: string; idOcorrencia: string } = { idBovino: '-1', idOcorrencia: '-1' },
    page: number,
    pageSize: number
  ) {
    const data = {
      IdBovino: idBovino,
      IdOcorrencia: idOcorrencia,
      Page: page,
      PageSize: pageSize,
    };

    return await this.http
      .post<any>(`${environment.api}/abate-com-aval-carcaca/consultar_relacao_abate`, data)
      .toPromise();
  }

  async salvarRelacaoPosAbateComAvalCarcaca(formulario: RelacaoPosAbateComAvalCarcacaModel) {
    const data = {
      IdBovino: formulario.IdBovino,
      IdOcorrencia: formulario.IdOcorrencia,
      OrdemRomaneio: formulario.OrdemRomaneio,
      NroDentes: formulario.NroDentes,
      Marmoreio: formulario.Marmoreio,
      PesoCarcaca: formulario.PesoCarcaca,
      CoberturaGordura: formulario.CoberturaGordura,
      AprovacaoCarcaca: formulario.AprovacaoCarcaca,
      Id: formulario.Id,
    };

    return await this.http
      .post<any>(`${environment.api}/abate-com-aval-carcaca/manter_relacao_pos_abate`, data)
      .toPromise();
  }

  async gerarRelatorioPreAbate(idOcorrencia: string, idLote: string) {
    if (idLote == '') {
      const url =
        `${environment.api}/abate-com-aval-carcaca/gerar_relatorio_pre_abate?idOcorrencia=${idOcorrencia}`;
      return await this.http.get<any>(url).toPromise();
    } else if (idOcorrencia == '') {
      const url =
        `${environment.api}/abate-com-aval-carcaca/gerar_relatorio_pre_abate?idLote=${idLote}`;
      return await this.http.get<any>(url).toPromise();
    }



  }
  //#endregion

  //#region Inovulacoes
  async consultarInovulacoes(consulta: FiltroConsultaEventosModel, page: number, pageSize: number) {
    let data = {
      Protocolo: consulta.formularioProtocolo,
      Situacao: consulta.situacao,
      PeriodoTransferencia: {
        PeriodoDe: this.datePipe.transform(consulta.eventoDe, 'yyyy-MM-dd'),
        PeriodoAte: this.datePipe.transform(consulta.eventoAte, 'yyyy-MM-dd'),
      },
      PeriodoComunicacao: {
        PeriodoDe: this.datePipe.transform(consulta.comunicacaoDe, 'yyyy-MM-dd'),
        PeriodoAte: this.datePipe.transform(consulta.comunicacaoAte, 'yyyy-MM-dd'),
      },
      Criador: {
        Id: consulta.criador.id,
      },
      Animal: {
        Id: consulta.bovino.Id,
      },
      Contexto: this.contexto,
      Page: page,
      PageSize: pageSize,
    };

    //TODO: mudar para inovulacao depois que acertar a API
    return await this.http.post<any>(`${environment.api}/inovulacao/index`, data).toPromise();
  }

  async consultarSugestaoFiv(formulario: ConsultaSugestaoFivModel, pageSize: number, page: number) {
    const data = {
      DoadoraId: formulario.Doadora.Id,
      CriadorId: formulario.CriadorId,
      PeriodoFiv: {
        PeriodoDe: this.datePipe.transform(formulario.SugestaoDe, 'yyyy-MM-dd'),
        PeriodoAte: this.datePipe.transform(formulario.SugestaoAte, 'yyyy-MM-dd'),
      },
      PageSize: pageSize,
      Page: page,
    };

    return await this.http
      .post<IResponseConsultaSugestaoFiv>(`${environment.api}/inovulacao/consultar_sugestao_fiv`, data)
      .toPromise();
  }

  //#endregion

  //#region Usuarios
  async consultarUsuarios(consulta: FiltroConsultaUsuariosModel, page: number, pageSize: number) {
    let data = {
      Codigo: consulta.codigo,
      Cpf: consulta.cpf,
      Nome: consulta.nome,
      GrupoUsuarioId: consulta.grupo,
      FiliadaId: consulta.vinculacao,
      Situacao: consulta.situacao,
      Page: page,
      PageSize: pageSize,
    };

    return await this.http.post<any>(`${environment.api}/usuario/index`, data).toPromise();
  }

  async validarCpfUsuario(dados: ValidaCpfUsuario) {
    const data = {
      TipoUsuario: 1,
      CpfOuCnpj: dados.cpf,
      GrupoId: dados.grupo,
      FiliadaId: dados.vinculacao,
    };

    return await this.http.post<any>(`${environment.api}/usuario/validar_cpf`, data).toPromise();
  }

  async consultarCEP(cep: string) {
    return await this.http.get(`https://viacep.com.br/ws/${cep}/json`).toPromise();
  }

  async registrarUsuario(cpfValido: ValidaCpfUsuario, dados: UsuarioModel) {
    const data = {
      Nome: dados.nome,
      Email: {
        Email: dados.email,
      },
      Cpf: cpfValido.cpf,
      Login: dados.usuario,
      Celular: dados.celular,
      Situacao: dados.situacao,
      GrupoUsuario: {
        Id: cpfValido.grupo,
      },
      Endereco: dados.endereco,
      Telefone: dados.residencial,
      FiliadaId: cpfValido.vinculacao,
      Contexto: this.contexto,
      TipoInspetor: dados.tipoInspetor,
      CodigoEspecializacaoInspetor: dados.codigoEspecializacaoInspetor,
    };

    if (cpfValido.grupo !== 'GR_SRG_JERSEY_ADMIN')
      Object.assign(
        data,
        cpfValido.grupo === 'GR_SRG_VETERINARIO'
          ? { CRMV: dados.CRMV_CodInspetor }
          : { CodigoInspetor: dados.CRMV_CodInspetor }
      );

    return await this.http.post<any>(`${environment.api}/usuario/registrar`, data).toPromise();
  }

  async atualizarUsuario(cpfValido: ValidaCpfUsuario, dados: UsuarioModel) {
    const data = {
      Codigo: dados.codigo,
      UsuarioId: dados.usuarioId,
      Nome: dados.nome,
      Email: {
        Email: dados.email,
      },
      Cpf: cpfValido.cpf,
      Login: dados.usuario,
      Celular: dados.celular,
      Situacao: dados.situacao,
      GrupoUsuario: {
        Id: cpfValido.grupo,
      },
      Endereco: dados.endereco,
      Telefone: dados.residencial,
      FiliadaId: cpfValido.vinculacao,
      TipoInspetor: dados.tipoInspetor,
      CodigoEspecializacaoInspetor: dados.codigoEspecializacaoInspetor,
    };

    if (cpfValido.grupo !== 'GR_SRG_JERSEY_ADMIN')
      Object.assign(
        data,
        cpfValido.grupo === 'GR_SRG_VETERINARIO'
          ? { CRMV: dados.CRMV_CodInspetor }
          : { CodigoInspetor: dados.CRMV_CodInspetor }
      );

    return await this.http.post<any>(`${environment.api}/usuario/atualizar`, data).toPromise();
  }

  async consultarUsuarioPorId(usuarioId: string) {
    const data = {
      usuarioId: usuarioId,
      somenteLeitura: false,
    };
    return await this.http.post<any>(`${environment.api}/usuario/consulta_por_id`, data).toPromise();
  }

  async alterarSenha(alterarSenha: AlterarSenhaModel) {
    const data = {
      senhaAtual: alterarSenha.senhaAtual,
      senhaNova: alterarSenha.senhaNova,
      confirmacaoSenhaNova: alterarSenha.confirmacaoSenhaNova,
    };
    return await this.http.post<any>(`${environment.api}/acesso/alterar_senha`, data).toPromise();
  }

  async recuperaPerfil() {
    return await this.http.get<any>(`${environment.api}/usuario/perfil`).toPromise();
  }

  async alterarPerfil(alterarPerfil: PerfilModel) {
    const data = {
      usuarioId: alterarPerfil.Usuario.usuario,
      codigo: alterarPerfil.Usuario.codigo,
      afixo: alterarPerfil.Afixo,
      login: alterarPerfil.Login,
      nome: alterarPerfil.Usuario.nome,
      cpf: alterarPerfil.CPF.cpf,
      cnpj: alterarPerfil.CNPJ,
      email: {
        Tipo: alterarPerfil.TipoEmail,
        email: alterarPerfil.Usuario.email,
      },
      situacao: alterarPerfil.Usuario.situacao,
      telefone: alterarPerfil.Usuario.residencial,
      celular: alterarPerfil.Usuario.celular,
      grupoUsuario: alterarPerfil.GrupoUsuario,
      endereco: alterarPerfil.Usuario.endereco,
      filiadaId: alterarPerfil.FiliadaId,
      fazendas: alterarPerfil.Fazendas,
      codigoAcesso: alterarPerfil.CodigoAcesso,
      tipoUsuario: alterarPerfil.TipoUsuario,
    };

    return await this.http.post<any>(`${environment.api}/usuario/atualizar_perfil`, data).toPromise();
  }

  async reiniciarSenha(usuarioId: any) {
    return await this.http
      .get<any>(`${environment.api}/acesso/reiniciar_senha?usuarioId=${usuarioId}&contexto=${this.contexto}`)
      .toPromise();
  }

  //#endregion

  //#region Controle leiteiro
  async registrarControleLeiteiro(dados: ControleLeiteiroModel) {
    const data = {
      Nome: dados.arquivo.nome,
      Descricao: dados.descricao,
      LinhasArquivo: dados.arquivo.itens,
      Tamanho: dados.arquivo.tamanho,
      DataImportacao: this.datePipe.transform(dados.dataImportacao, 'yyyy-MM-dd'),
    };

    return await this.http.post<any>(`${environment.api}/controle_leiteiro/registrar`, data).toPromise();
  }

  async consultarControleLeiteiro(consulta: FiltroConsultaControleLeiteiro, page: number, pageSize: number) {
    const data = {
      Situacao: consulta.situacao,
      PeriodoControleLeiteiro: {
        PeriodoDe: this.datePipe.transform(consulta.controleLeiteiroDe, 'yyyy-MM-dd'),
        PeriodoAte: this.datePipe.transform(consulta.controleLeiteiroAte, 'yyyy-MM-dd'),
      },
      Page: page,
      PageSize: pageSize,
    };

    return await this.http.post<any>(`${environment.api}/controle_leiteiro/index`, data).toPromise();
  }

  async analisarArquivoControleLeiteiro(id: string) {
    return await this.http.get<any>(`${environment.api}/controle_leiteiro/analisar_arquivo?id=${id}`).toPromise();
  }

  async descartarArquivoControleLeiteiro(id: string) {
    return await this.http.get<any>(`${environment.api}/controle_leiteiro/descartar_arquivo?id=${id}`).toPromise();
  }

  async confirmarImportacaoArqControleLeiteiro(id: string) {
    return await this.http.get<any>(`${environment.api}/controle_leiteiro/aprovar_arquivo?id=${id}`).toPromise();
  }

  async downloadArqControleLeiteiro(id: string) {
    return await this.http.get<any>(`${environment.api}/controle_leiteiro/download_arquivo?id=${id}`).toPromise();
  }

  //#endregion

  //#region Exposicoes

  async registrarExposicao(dados: ExposicaoModel) {
    const data = {
      Uf: dados.uf,
      Email: dados.email,
      NomeExposicao: dados.nome,
      Municipio: dados.municipio,
      Responsavel: dados.responsavel,
      JuradoPista: dados.juradoPista,
      JuradoAdmissao: dados.juradoAdmissao,
      DataExposicao: this.datePipe.transform(dados.dataExposicao, 'yyyy-MM-dd'),
      FimInscricoes: this.datePipe.transform(dados.fimInscricoes, 'yyyy-MM-dd'),
      InicioInscricoes: this.datePipe.transform(dados.inicioInscricoes, 'yyyy-MM-dd'),
    };

    return await this.http.post<any>(`${environment.api}/exposicao/registrar`, data).toPromise();
  }

  async consultarExposicao(consulta: FiltroExposicaoModel, page: number, pageSize: number) {
    const data = {
      Uf: consulta.uf,
      Nome: consulta.nome,
      PeriodoExposicao: {
        PeriodoDe: this.datePipe.transform(consulta.exposicaoDe, 'yyyy-MM-dd'),
        PeriodoAte: this.datePipe.transform(consulta.exposicaoAte, 'yyyy-MM-dd'),
      },
      Page: page,
      PageSize: pageSize,
    };

    return await this.http.post<any>(`${environment.api}/exposicao/index`, data).toPromise();
  }

  //#endregion

  //#region Inspetor

  async consultarInspetores(dados: InspetorModel) {
    const data = {
      Codigo: dados.Codigo,
      NomeInspetor: dados.NomeInspetor,
      TipoConsulta: dados.TipoConsulta
    };

    return await this.http.post<any>(`${environment.api}/inspetor/inspetores`, data).toPromise();
  }

  async consultarInspetorPorCodigo(codigo: string, tipoConsulta: string = '') {
    return await this.http.get(`${environment.api}/inspetor/inspetor_por_codigo?codigo=${codigo}&tipoConsulta=${tipoConsulta}`).toPromise();
  }

  //#endregion

  //#region Inspecoes
  async consultarInspecoes(consulta: FiltroConsultaEventosModel, page: number, pageSize: number) {
    let data = {
      Protocolo: consulta.formularioProtocolo,
      Situacao: consulta.situacao,
      PeriodoInspecao: {
        PeriodoDe: this.datePipe.transform(consulta.eventoDe, 'yyyy-MM-dd'),
        PeriodoAte: this.datePipe.transform(consulta.eventoAte, 'yyyy-MM-dd'),
      },
      PeriodoComunicacao: {
        PeriodoDe: this.datePipe.transform(consulta.comunicacaoDe, 'yyyy-MM-dd'),
        PeriodoAte: this.datePipe.transform(consulta.comunicacaoAte, 'yyyy-MM-dd'),
      },
      Criador: {
        Id: consulta.criador.id,
      },
      Animal: {
        Id: consulta.bovino.Id,
      },
      Page: page,
      PageSize: pageSize,
    };

    return await this.http.post<any>(`${environment.api}/inspecao/index`, data).toPromise();
  }

  async gerarRelatorioInspecao(consulta: FiltroConsultaPlantelModel, forma: TipoGerarRelatorio) {
    const url = ((): string | null => {
      switch (forma) {
        case TipoGerarRelatorio.Excel:
          return `${environment.api}/inspecao/gerar_relatorio_inspecao`;
        // case TipoGerarRelatorio.Pdf: return `${environment.api}/bovino/plantel_gerar_pdf`;
        default:
          return null;
      }
    })();

    if (!url) return;

    const data = {
      Animal: {
        Id: consulta.bovino.Id,
      },
      Criador: {
        Id: consulta.criador.id,
        CodigoCriador: consulta.criador.codigo,
        NomeCriador: consulta.criador.nome,
      },
      NomeBovino: consulta.bovino.NomeBovino,
      CodigoBovino: consulta.bovino.CodigoBovino,
      RacaBovino: consulta.bovino.RacaBovino,
      PeriodoNascimento: {
        PeriodoAte: this.datePipe.transform(consulta.nascimentoAte, 'yyyy-MM-dd'),
        PeriodoDe: this.datePipe.transform(consulta.nascimentoDe, 'yyyy-MM-dd'),
      },
      Inspetor: consulta.inspetor,
      Situacao: consulta.situacao,
      Contexto: this.contexto,
    };

    return await this.http.post<any>(url, data).toPromise();
  }

  async consultarPlantelInspecao(consulta: FiltroConsultaPlantelModel, page: number, pageSize: number) {
    let data = {
      Animal: {
        CodigoBovino: consulta.bovino.CodigoBovino,
        NomeBovino: consulta.bovino.NomeBovino,
      },
      Criador: {
        Id: consulta.criador.id,
        DominioId: consulta.criador.id,
        DominioUsuarioLogadoId: consulta.criador.id,
        Controller: 'Criador',
        CodigoCriador: consulta.criador.codigo,
        NomeCriador: consulta.criador.nome,
        AfixoCriador: consulta.criador.afixo,
      },
      Inspetor: consulta.inspetor,
      DominioId: consulta.criador.id,
      DominioUsuarioLogadoId: consulta.criador.id,
      NomeBovino: consulta.bovino.NomeBovino,
      CodigoBovino: consulta.bovino.CodigoBovino,
      RacaBovino: consulta.bovino.RacaBovino,
      Page: page,
      PageSize: pageSize,
      PeriodoNascimento: {
        DominioId: consulta.criador.id,
        DominioUsuarioLogadoId: consulta.criador.id,
        PeriodoAte: this.datePipe.transform(consulta.nascimentoAte, 'yyyy-MM-dd'),
        PeriodoDe: this.datePipe.transform(consulta.nascimentoDe, 'yyyy-MM-dd'),
      },
      Readonly: false,
      ReturnController: 'Home',
      Situacao: consulta.situacao,
      SituacaoSRG: 'Todos',
      Contexto: this.contexto,
    };

    return await this.http.post<any>(`${environment.api}/inspecao/plantel_inspecao`, data).toPromise();
  }

  async carregarAnimaisParaInspecao(idCriador: string, dataInspecao: string, idInspetor: string) {
    let data = {
      idCriador: idCriador,
      dataInspecao: dataInspecao,
      idInspetor: idInspetor,
    };

    return await this.http.post<any>(`${environment.api}/inspecao/carregar_animais_para_inspecao`, data).toPromise();
  }

  //#endregion

  //#region Tecnico

  async consultarTecnicos(dados: TecnicoModel) {
    const data = {
      Codigo: dados.Codigo,
      NomeTecnico: dados.NomeTecnico,
    };

    return await this.http.post<any>(`${environment.api}/tecnico/tecnicos`, data).toPromise();
  }

  async consultarTecnicoPorCodigo(codigo: string) {
    return await this.http.get(`${environment.api}/tecnico/tecnico_por_codigo?codigo=${codigo}`).toPromise();
  }

  //#endregion

  //#region Baixas
  async consultarBaixas(consulta: FiltroConsultaEventosModel, page: number, pageSize: number) {
    let data = {
      Protocolo: consulta.formularioProtocolo,
      Situacao: consulta.situacao,
      PeriodoBaixa: {
        PeriodoDe: this.datePipe.transform(consulta.eventoDe, 'yyyy-MM-dd'),
        PeriodoAte: this.datePipe.transform(consulta.eventoAte, 'yyyy-MM-dd'),
      },
      PeriodoComunicacao: {
        PeriodoDe: this.datePipe.transform(consulta.comunicacaoDe, 'yyyy-MM-dd'),
        PeriodoAte: this.datePipe.transform(consulta.comunicacaoAte, 'yyyy-MM-dd'),
      },
      Criador: {
        Id: consulta.criador.id,
      },
      Animal: {
        Id: consulta.bovino.Id,
      },
      Page: page,
      PageSize: pageSize,
      ConsultaCarcaca: consulta.ocorrenciaCarcaca
    };

    return await this.http.post<any>(`${environment.api}/baixa/index`, data).toPromise();
  }

  //endregion

  //#region Transferencias
  async consultarTransferencias(consulta: FiltroConsultaEventosModel, page: number, pageSize: number) {
    let data = {
      Protocolo: consulta.formularioProtocolo,
      Situacao: consulta.situacao,
      PeriodoTransferencia: {
        PeriodoDe: this.datePipe.transform(consulta.eventoDe, 'yyyy-MM-dd'),
        PeriodoAte: this.datePipe.transform(consulta.eventoAte, 'yyyy-MM-dd'),
      },
      PeriodoComunicacao: {
        PeriodoDe: this.datePipe.transform(consulta.comunicacaoDe, 'yyyy-MM-dd'),
        PeriodoAte: this.datePipe.transform(consulta.comunicacaoAte, 'yyyy-MM-dd'),
      },
      Criador: {
        Id: consulta.criador.id,
      },
      Animal: {
        Id: consulta.bovino.Id,
      },
      Page: page,
      PageSize: pageSize,
    };

    return await this.http.post<any>(`${environment.api}/transferencia/index`, data).toPromise();
  }

  //#endregion
  //#region ClassificacoesLineares
  async consultarClassificacoesLineares(consulta: FiltroConsultaEventosModel, page: number, pageSize: number) {
    let data = {
      Protocolo: consulta.formularioProtocolo,
      Situacao: consulta.situacao,
      PeriodoClassificacao: {
        PeriodoDe: this.datePipe.transform(consulta.eventoDe, 'yyyy-MM-dd'),
        PeriodoAte: this.datePipe.transform(consulta.eventoAte, 'yyyy-MM-dd'),
      },
      PeriodoComunicacao: {
        PeriodoDe: this.datePipe.transform(consulta.comunicacaoDe, 'yyyy-MM-dd'),
        PeriodoAte: this.datePipe.transform(consulta.comunicacaoAte, 'yyyy-MM-dd'),
      },
      Criador: {
        Id: consulta.criador.id,
      },
      Animal: {
        Id: consulta.bovino.Id,
      },
      Page: page,
      PageSize: pageSize,
    };

    return await this.http.post<any>(`${environment.api}/classificacaoLinear/index`, data).toPromise();
  }

  //#endregion
  //#region Semen
  async consultarSemen(consulta: FiltroConsultaNacionalizacaoModel, page: number, pageSize: number) {
    let data = {
      Protocolo: consulta.formularioProtocolo,
      Situacao: consulta.situacao,
      PeriodoNacionalizacao: {
        PeriodoDe: this.datePipe.transform(consulta.eventoDe, 'yyyy-MM-dd'),
        PeriodoAte: this.datePipe.transform(consulta.eventoAte, 'yyyy-MM-dd'),
      },
      Criador: {
        Id: consulta.criador.id,
      },
      Animal: {
        Id: consulta.bovino.Id,
      },
      Page: page,
      PageSize: pageSize,
    };

    return await this.http.post<any>(`${environment.api}/nacionalizacao/index`, data).toPromise();
  }

  async consultarAscendenciaNacionalizacao(id: string) {
    return await this.http
      .get<any>(`${environment.api}/nacionalizacao/recuperar_ascendencia?idOcorrencia=${id}`)
      .toPromise();
  }

  //#endregion
  //#region Embriao
  async consultarEmbriao(consulta: FiltroConsultaNacionalizacaoModel, page: number, pageSize: number) {
    let data = {
      Protocolo: consulta.formularioProtocolo,
      Situacao: consulta.situacao,
      PeriodoNacionalizacao: {
        PeriodoDe: this.datePipe.transform(consulta.eventoDe, 'yyyy-MM-dd'),
        PeriodoAte: this.datePipe.transform(consulta.eventoAte, 'yyyy-MM-dd'),
      },
      Criador: {
        Id: consulta.criador.id,
      },
      Animal: {
        Id: consulta.bovino.Id,
      },
      Page: page,
      PageSize: pageSize,
    };

    return await this.http.post<any>(`${environment.api}/nacionalizacao-embriao/index`, data).toPromise();
  }

  //#endregion

  //#region Certificados
  async consultarCertificados(consulta: FiltroConsultaCertificadosModel, page: number, pageSize: number) {
    let data = {
      Criador: {
        CodigoCriador: consulta.proprietario.codigo,
        NomeCriador: consulta.proprietario.nome,
      },
      Bovino: {
        CodigoBovino: consulta.bovino.CodigoBovino,
        NomeBovino: consulta.bovino.NomeBovino,
        Id: consulta.bovino.Id,
      },
      Assinado: consulta.assinado,
      Certificado: consulta.certificado,
      ClasseCertificado: consulta.classeCertificado,
      PeriodoConsulta: {
        PeriodoDe: this.datePipe.transform(consulta.emissaoDe, 'yyyy-MM-dd'),
        PeriodoAte: this.datePipe.transform(consulta.emissaoAte, 'yyyy-MM-dd'),
      },
      Page: page,
      PageSize: pageSize,
    };

    return await this.http.post<any>(`${environment.api}/certificado/index`, data).toPromise();
  }

  async manterCertificado(certificado: CertificadoModel) {
    let data = {
      CertificadoId: certificado.id,
      Animal: {
        CodigoBovino: certificado.animal.CodigoBovino,
        NomeBovino: certificado.animal.NomeBovino,
        Id: certificado.animal.Id,
      },
      DataEmissao:
        certificado.dataEmissao == null || certificado.dataEmissao == '' ? Date.now() : certificado.dataEmissao,
      Certificado: certificado.certificado,
      Contexto: this.contexto,
    };

    return await this.http.post<any>(`${environment.api}/certificado/registrar`, data).toPromise();
  }

  async consultarCertificadosPendentes(
    consulta: FiltroConsultaCertificadosPendentesModel,
    page: number,
    pageSize: number
  ) {
    let data = {
      FiliadaId: consulta.vinculacao,
      Criador: {
        Id: consulta.criador.id,
        CodigoCriador: consulta.criador.codigo,
        NomeCriador: consulta.criador.nome,
      },
      Page: page,
      PageSize: pageSize,
    };

    return await this.http.post<any>(`${environment.api}/certificado/pendentes_emissao`, data).toPromise();
  }

  async consultarCertificadosPorId(certificadoId: string) {
    return await this.http.get<any>(`${environment.api}/certificado/recuperar_por_id?id=${certificadoId}`).toPromise();
  }

  async recuperarCertificadoPendente(idBovino: string) {
    return await this.http.get<any>(`${environment.api}/certificado/gerar_certificado?id=${idBovino}`).toPromise();
  }

  async visualizarCertificado(id: string, tipo: number) {
    return await this.http
      .get<any>(`${environment.api}/certificado/visualizar?idBovino=${id}&tipo=${tipo}&contexto=${this.contexto}`)
      .toPromise();
  }

  async assinarCertificado(tipo: number, certificados: string[]) {
    const data = {
      Certificado: tipo,
      Itens: certificados,
      Contexto: this.contexto,
    };

    return await this.http.post<any>(`${environment.api}/certificado/assinar`, data).toPromise();
  }

  async imprimirCertificado(id: string, preImpresso: boolean) {
    const url: string = `${environment.api}/certificado/${preImpresso ? 'imprimir_pre_impresso' : 'imprimir'}?id=${id}`;

    return await this.http.get<any>(url).toPromise();
  }

  async imprimirCertificados(lista: string[], preImpresso: boolean) {
    const url: string = `${environment.api}/certificado/${preImpresso ? 'imprimir_lista_pre_impresso' : 'imprimir_lista'
      }`;

    return await this.http.post<any>(url, lista).toPromise();
  }

  async recuperarCertificadoPorBovino(idBovino: string) {
    return await this.http.get<any>(`${environment.api}/certificado/recuperar_por_bovino?idBovino=${idBovino}`).toPromise();
  }

  async apagarCertificado(certificadoId: string) {
    return await this.http.get<any>(`${environment.api}/certificado/apagar_certificado?certificadoId=${certificadoId}`).toPromise();
  }

  //#endregion

  //#region Laudo DNA

  async manterLaudoDna(formulario: LaudoDNAModel) {
    const data = {
      Id: formulario.Id,
      NumeroLaudo: formulario.NumeroLaudo,
      DataEmissao: this.datePipe.transform(formulario.DataEmissao, 'yyyy-MM-dd'),
      Sexo: formulario.Sexo,
      Qualifica: formulario.Qualifica,
      Observacao: formulario.Observacao,
      Pai: formulario.Pai,
      NomeAnimal: formulario.NomeAnimal,
      CodigoAnimal: formulario.CodigoAnimal,
      Laboratorio: formulario.Laboratorio,
      Mae: formulario.Mae,
      Arquivo: formulario.Arquivo,
      Mimetype: formulario.Mimetype,
      IdCriador: formulario.Criador.codigo,
    };

    return await this.http.post<any>(`${environment.api}/laudo_dna/manter`, data).toPromise();
  }

  async consultarLaudoDna(formulario: FiltroConsultaLaudoDnaModel, page: number, pageSize: number) {
    const data = {
      CodigoAnimal: formulario.CodigoAnimal,
      NomeAnimal: formulario.NomeAnimal,
      NumeroLaudo: formulario.NumeroLaudo,
      Criador: formulario.Criador,
      Sexo: formulario.Sexo,
      Qualifica: formulario.Qualifica,
      Pai: formulario.Pai,
      Mae: formulario.Mae,
      Page: page,
      PageSize: pageSize,
    };

    return await this.http.post<any>(`${environment.api}/laudo_dna/consultar`, data).toPromise();
  }

  async recuperarLaudoDnaPorId(id: string) {
    return await this.http
      .get<IResponseRecuperarLaudoPorId>(`${environment.api}/laudo_dna/recuperar_por_id?id=${id}`)
      .toPromise();
  }

  async visualizarLaudoDna(id: string) {
    return await this.http
      .get<IJsonResultModel<IResponseVisualizarLaudoDna>>(`${environment.api}/laudo_dna/visualizar_documento?id=${id}`)
      .toPromise();
  }

  async recuperarLaudoDnaPorNumero(form: LaudoDNAModel) {
    const data = {
      NumeroLaudo: form.NumeroLaudo,
      Pai: {
        Id: form.Pai.Id,
      },
      Mae: {
        Id: form.Mae.Id,
      },
    };

    return await this.http
      .post<IResponseRecuperarLaudoPorId>(`${environment.api}/laudo_dna/recuperar_por_numero`, data)
      .toPromise();
  }

  //#endregion

  //#region Associações

  async consultarFiliadas() {
    return await this.http.get<any>(`${environment.api}/acesso/consultar-filiadas`).toPromise();
  }

  //#endregion

  //#region Criador

  async recuperarCriadorPorId(id: string) {
    return await this.http
      .get<IResponseConsultaCriadorId>(`${environment.api}/criador/recuperar_criador_por_id?id=${id}`)
      .toPromise();
  }

  async consultarCriadores(consulta: FiltroConsultaCriadoresModel, page: number, pageSize: number) {
    let data = {
      CodigoCriador: consulta.codigo,
      NomeCriador: consulta.nome,
      Situacao: consulta.situacao,
      TipoUsuario: consulta.tipoUsuario,
      ConsultaEmTodasFiliadas: true,
      FiliadaId: consulta.vinculacao,
      Afixo: consulta.afixo,
      Page: page,
      PageSize: pageSize,
    };

    Object.assign(
      data,
      consulta.tipoUsuario == 1 ? { CpfCriador: consulta.cpf_cnpj } : { CNPJCriador: consulta.cpf_cnpj }
    );

    return await this.http.post<any>(`${environment.api}/criador/index`, data).toPromise();
  }

  //#endregion

  //#region Plantel

  async gerarRelatorioPlantel(consulta: FiltroConsultaPlantelModel, forma: TipoGerarRelatorio) {
    const url = ((): string | null => {
      switch (forma) {
        case TipoGerarRelatorio.Excel:
          return `${environment.api}/bovino/plantel_gerar_excel`;
        case TipoGerarRelatorio.Pdf:
          return `${environment.api}/bovino/plantel_gerar_pdf`;
        default:
          return null;
      }
    })();

    if (!url) return;

    const data = {
      Animal: {
        Id: consulta.bovino.Id,
      },
      Criador: {
        Id: consulta.criador.id,
        CodigoCriador: consulta.criador.codigo,
        NomeCriador: consulta.criador.nome,
      },
      NomeBovino: consulta.bovino.NomeBovino,
      CodigoBovino: consulta.bovino.CodigoBovino,
      RacaBovino: consulta.bovino.RacaBovino,
      PeriodoNascimento: {
        PeriodoAte: this.datePipe.transform(consulta.nascimentoAte, 'yyyy-MM-dd'),
        PeriodoDe: this.datePipe.transform(consulta.nascimentoDe, 'yyyy-MM-dd'),
      },
      Situacao: consulta.situacao,
      Contexto: this.contexto,
    };

    return await this.http.post<any>(url, data).toPromise();
  }

  async consultarPlantel(consulta: FiltroConsultaPlantelModel, page: number, pageSize: number) {
    let data = {
      Animal: {
        Id: '-1',
        CodigoBovino: consulta.bovino.CodigoBovino,
        NomeBovino: consulta.bovino.NomeBovino,
      },
      Controller: 'Bovino',
      Criador: {
        Id: consulta.criador.id,
        DominioId: consulta.criador.id,
        DominioUsuarioLogadoId: consulta.criador.id,
        Controller: 'Criador',
        CodigoCriador: consulta.criador.codigo,
        NomeCriador: consulta.criador.nome,
        AfixoCriador: consulta.criador.afixo,
      },
      DominioId: consulta.criador.id,
      DominioUsuarioLogadoId: consulta.criador.id,
      Fields: null,
      Id: '-1',
      IndCriarDependencias: true,
      IsAdiminJersey: false,
      IsJerseyBrasil: false,
      IsRealizadaViaPopup: false,
      Itens: null,
      NomeBovino: consulta.bovino.NomeBovino,
      CodigoBovino: consulta.bovino.CodigoBovino,
      RacaBovino: consulta.bovino.RacaBovino,
      Page: page,
      PageSize: pageSize,
      PeriodoNascimento: {
        DominioId: consulta.criador.id,
        DominioUsuarioLogadoId: consulta.criador.id,
        PeriodoAte: this.datePipe.transform(consulta.nascimentoAte, 'yyyy-MM-dd'),
        PeriodoDe: this.datePipe.transform(consulta.nascimentoDe, 'yyyy-MM-dd'),
      },
      ProprietarioId: '16496B25-83E5-4A94-AD38-819A8FAAD899',
      Readonly: false,
      ReturnController: 'Home',
      Situacao: consulta.situacao,
      SituacaoSRG: 'Todos',
      Contexto: this.contexto,
    };

    return await this.http.post<any>(`${environment.api}/bovino/plantel`, data).toPromise();
  }

  ////#endregion

  ////#region Detalhes Bovino
  async consultarDetalhesBovino(bovinoid: string, readonly?: boolean) {
    let data = {
      BovinoId: bovinoid,
      ReadOnly: readonly,
      Contexto: this.contexto,
    };
    return await this.http.post<any>(`${environment.api}/bovino/bovino_detalhes/`, data).toPromise();
  }

  async consultarEventosBovino(
    bovinoId: string,
    consulta: FiltroConsultaEventosBovinoModel,
    page: number,
    pageSize: number
  ) {
    const data = {
      BovinoId: bovinoId,
      Tipo: consulta.tipo,
      Situacao: consulta.situacao,
      PeriodoConsulta: {
        PeriodoDe: this.datePipe.transform(consulta.eventoDe, 'yyyy-MM-dd'),
        PeriodoAte: this.datePipe.transform(consulta.eventoAte, 'yyyy-MM-dd'),
      },
      Page: page,
      PageSize: pageSize,
    };

    return await this.http.post<any>(`${environment.api}/bovino/bovino_eventos`, data).toPromise();
  }

  async consultarCriasBovino(bovinoId: string, page: number, pageSize: number) {
    const data = {
      BovinoId: bovinoId,
      Page: page,
      PageSize: pageSize,
    };

    return await this.http.post<any>(`${environment.api}/bovino/bovino_crias`, data).toPromise();
  }

  async consultarClassificacoesBovino(bovinoId: string, page: number, pageSize: number) {
    const data = {
      BovinoId: bovinoId,
      Page: page,
      PageSize: pageSize,
    };

    return await this.http.post<any>(`${environment.api}/bovino/bovino_classificacoes`, data).toPromise();
  }

  async consultarPremiacoesBovino(bovinoId: string, page: number, pageSize: number) {
    const data = {
      BovinoId: bovinoId,
      Page: page,
      PageSize: pageSize,
    };

    return await this.http.post<any>(`${environment.api}/bovino/bovino_premiacoes`, data).toPromise();
  }

  async consultarControleLeiteiroBovino(bovinoId: string, page: number, pageSize: number) {
    const data = {
      BovinoId: bovinoId,
      Page: page,
      PageSize: pageSize,
    };

    return await this.http.post<any>(`${environment.api}/bovino/bovino_controle_leiteiro`, data).toPromise();
  }

  async consultarDocumentosBovino(bovinoId: string, page: number, pageSize: number) {
    const data = {
      BovinoId: bovinoId,
      Page: page,
      PageSize: pageSize,
    };

    return await this.http.post<any>(`${environment.api}/bovino/bovino_documentos`, data).toPromise();
  }

  async consultarGenealogiaBovino(bovinoId: string) {
    const data = {
      BovinoId: bovinoId,
    };

    return await this.http.post<any>(`${environment.api}/bovino/bovino_genealogia`, data).toPromise();
  }

  async downloadDocumentoBovino(documentoId: string, certificadoId: string) {
    return await this.http
      .get<any>(`${environment.api}/bovino/bovino_documentos_download?arq_id=${documentoId}&certificadoId=${certificadoId}`)
      .toPromise();
  }

  async visualizarDocumento(documentoId: string, certificadoId: string) {
    return await this.http
      .get<any>(`${environment.api}/bovino/visualizar_arquivo?idDocumento=${documentoId}&certificadoId=${certificadoId}`)
      .toPromise();
  }
  ////#endregion

  //#region Evento administrativo - apropriacao tecnico

  async manterApropriacaoTecnico(formulario: OcorrenciaEventoApropriacaoTecnicoModel) {
    const data = {
      Id: formulario.Id,
      Descricao: formulario.Descricao,
      Situacao: formulario.Situacao,
      DataOcorrencia: formulario.DataOcorrencia,
      InicioVigencia: this.datePipe.transform(formulario.PeriodoVigencia.De, 'yyyy-MM-dd'),
      FimVigencia: this.datePipe.transform(formulario.PeriodoVigencia.Ate, 'yyyy-MM-dd'),
      Inspetor: {
        Id: formulario.Inspetor.Id,
      },
      Veterinario: {
        Id: -1,
      },
      Criador: {
        Id: formulario.Criador.id,
      },
      Procurador: {
        Id: -1,
      },
      GrupoUsuarioId: RolesKeys.Inspetor.toString(),
      Contexto: this.contexto,
    };

    return await this.http
      .post<OcorrenciaEventoApropriacaoTecnicoModel>(`${environment.api}/apropriacao_tecnico/manter`, data)
      .toPromise();
  }

  async consultarApropriacaoTecnico(form: OcorrenciaEventoApropriacaoTecnicoModel, page: number, pageSize: number) {
    const data = {
      InicioVigencia: this.datePipe.transform(form.PeriodoVigencia.De, 'yyyy-MM-dd'),
      FimVigencia: this.datePipe.transform(form.PeriodoVigencia.Ate, 'yyyy-MM-dd'),
      Inspetor: {
        Id: form.Inspetor.Id,
      },
      Veterinario: {
        Id: -1,
      },
      Criador: {
        Id: form.Criador.id,
      },
      Procurador: {
        Id: -1,
      },
      PageSize: pageSize,
      Page: page,
      GrupoUsuarioId: form.GrupoUsuarioId,
      Contexto: this.contexto,
    };

    return await this.http.post<any>(`${environment.api}/apropriacao_tecnico/index`, data).toPromise();
  }

  async inativarOcorrenciaEventoAdministrativo(id: string) {
    return await this.http.get<any>(`${environment.api}/apropriacao_tecnico/inativar?id=${id}`).toPromise();
  }

  async manterApropriacaoVeterinario(formulario: OcorrenciaEventoApropriacaoVeterinarioModel) {
    const data = {
      Id: formulario.Id,
      Descricao: formulario.Descricao,
      Situacao: formulario.Situacao,
      DataOcorrencia: formulario.DataOcorrencia,
      InicioVigencia: this.datePipe.transform(formulario.PeriodoVigencia.De, 'yyyy-MM-dd'),
      FimVigencia: this.datePipe.transform(formulario.PeriodoVigencia.Ate, 'yyyy-MM-dd'),
      Inspetor: {
        Id: -1,
      },
      Veterinario: {
        Id: formulario.Veterinario.id,
      },
      Criador: {
        Id: formulario.Criador.id,
      },
      Procurador: {
        Id: -1,
      },
      GrupoUsuarioId: RolesKeys.Veterinario.toString(),
      Contexto: this.contexto,
    };

    return await this.http
      .post<OcorrenciaEventoApropriacaoVeterinarioModel>(`${environment.api}/apropriacao_tecnico/manter`, data)
      .toPromise();
  }

  async consultarApropriacaoVeterinario(
    form: OcorrenciaEventoApropriacaoVeterinarioModel,
    page: number,
    pageSize: number
  ) {
    const data = {
      InicioVigencia: this.datePipe.transform(form.PeriodoVigencia.De, 'yyyy-MM-dd'),
      FimVigencia: this.datePipe.transform(form.PeriodoVigencia.Ate, 'yyyy-MM-dd'),
      Inspetor: {
        Id: -1,
      },
      Veterinario: {
        Id: form.Veterinario.id,
      },
      Criador: {
        Id: form.Criador.id,
      },
      Procurador: {
        Id: -1,
      },
      PageSize: pageSize,
      Page: page,
      GrupoUsuarioId: RolesKeys.Veterinario.toString(),
      Contexto: this.contexto,
    };

    return await this.http.post<any>(`${environment.api}/apropriacao_tecnico/index`, data).toPromise();
  }

  async manterApropriacaoProcurador(formulario: OcorrenciaEventoApropriacaoProcuradorModel) {
    const data = {
      Id: formulario.Id,
      Descricao: formulario.Descricao,
      Situacao: formulario.Situacao,
      DataOcorrencia: formulario.DataOcorrencia,
      InicioVigencia: this.datePipe.transform(formulario.PeriodoVigencia.De, 'yyyy-MM-dd'),
      FimVigencia: this.datePipe.transform(formulario.PeriodoVigencia.Ate, 'yyyy-MM-dd'),
      Inspetor: {
        Id: -1,
      },
      Veterinario: {
        Id: -1,
      },
      Criador: {
        Id: formulario.Criador.id,
      },
      Procurador: {
        Id: formulario.Procurador.Id,
      },
      GrupoUsuarioId: RolesKeys.Procurador.toString(),
      Contexto: this.contexto,
    };

    return await this.http
      .post<OcorrenciaEventoApropriacaoProcuradorModel>(`${environment.api}/apropriacao_tecnico/manter`, data)
      .toPromise();
  }

  async consultarApropriacaoProcurador(
    formulario: OcorrenciaEventoApropriacaoProcuradorModel,
    page: number,
    pageSize: number
  ) {
    const data = {
      InicioVigencia: this.datePipe.transform(formulario.PeriodoVigencia.De, 'yyyy-MM-dd'),
      FimVigencia: this.datePipe.transform(formulario.PeriodoVigencia.Ate, 'yyyy-MM-dd'),
      Inspetor: {
        Id: -1,
      },
      Veterinario: {
        Id: -1,
      },
      Criador: {
        Id: formulario.Criador.id,
      },
      Procurador: {
        Id: formulario.Procurador.Id,
      },
      PageSize: pageSize,
      Page: page,
      GrupoUsuarioId: RolesKeys.Procurador.toString(),
      Contexto: this.contexto,
    };

    return await this.http.post<any>(`${environment.api}/apropriacao_tecnico/index`, data).toPromise();
  }
  //#endregion

  async consultarUsuarioProcuradorPorNomeCodigo(procurador: UsuarioProcuradorModel) {
    let data = {
      Codigo: procurador.Codigo,
      NomeProcurador: procurador.NomeProcurador,
    };

    return await this.http.post<any>(`${environment.api}/procurador/procurador_por_codigo_nome`, data).toPromise();
  }

  async consultarUsuarioProcuradores(dados: UsuarioProcuradorModel, page?: number, pageSize?: number) {
    const data = {
      Codigo: dados.Codigo,
      NomeProcurador: dados.NomeProcurador,
      PageSize: pageSize ?? null,
      Page: page ?? null,
    };

    return await this.http.post<any>(`${environment.api}/procurador/procuradores`, data).toPromise();
  }
}
