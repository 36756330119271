import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TecnicoModel } from 'src/app/models/TecnicoModel';
import Swal from 'sweetalert2';
import { ApiService } from '../api.service';
import { ConsultarTecnicoModalComponent } from '../consultar-tecnico-modal/consultar-tecnico-modal.component';
  

@Component({
  selector: 'app-consultar-tecnico',
  templateUrl: './consultar-tecnico.component.html',
  styleUrls: ['./consultar-tecnico.component.scss']
})
export class ConsultarTecnicoComponent implements OnInit {

  @Input() tecnico: TecnicoModel = new TecnicoModel();
  @Input() tituloConsulta: string = "Classificador(a)";
  @Output() tecnicoSelecionado = new EventEmitter<string>();

  constructor(
    private api: ApiService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  limpar(){
    this.tecnico.NomeTecnico = "";
    this.tecnico.Codigo = "";
    this.tecnico.Id = "";
    this.tecnicoSelecionado.emit("");
  }

  consultarPorCodigo(){
    if (!this.tecnico.Codigo) {
      Swal.fire('Informe o código para recuperação do tecnico.', '', 'error');
      return;
    }

    this.api.consultarTecnicoPorCodigo(this.tecnico.Codigo).then((result: any) => {
      if(result){
        if(result.Data.success){
          this.tecnico.Id = result.Data.idTecnico;
          this.tecnico.NomeTecnico = result.Data.nomeTecnico;
          this.tecnicoSelecionado.emit(this.tecnico.Codigo);
        } else {
          this.limpar();
          Swal.fire(result.Data.codigo === 3288334552 ? 'Não foi identificado tecnico com este código.' : result.Data.message, '', 'error');
        }
      } else {
        this.limpar();
        Swal.fire(result.Data.message || 'A operação não pode ser realizada.', '', 'error');
      }
    });
  }

  consultarPorNome(){
    const dialogRef = this.dialog.open(
      ConsultarTecnicoModalComponent, 
      {
        height: '90%', 
        width: '90%',
      }
    );

    dialogRef.componentInstance.tecnicoSelecionado.subscribe((result : any) => {
      if(result){
        this.tecnico.Id = result.Id;
        this.tecnico.NomeTecnico = result.NomeTecnico;
        this.tecnico.Codigo = result.Codigo;
        this.tecnico.Id = result.Id;
        this.tecnicoSelecionado.emit(this.tecnico.Codigo);
      }
    });
    
  }

}
