<div class="row">
  <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12" *ngFor="let item of listContadores" style="margin-top: 1em;">
    <div class="card card-bg-{{getIcon(item.Tipo)}}">
      <div class="card-body">
        <h2 class="card-title">
          {{item.Titulo}}
        </h2>
        <p class="card-text">Este mês: {{item.ValorAtual}}<br>Mês Anterior: {{item.ValorAnterior}}</p>
      </div>
    </div>
  </div>
</div>

<app-submetidos></app-submetidos>

<div class="row" *ngIf="env.contexto === 'jersey' && totais" >
  <div class="col-lg-6 col-sm-12" style="margin-top: 1em;">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">
          Números de Registros Disponíveis
        </h4>

        <table class="table table-hover table-header-custom">
          <thead>
            <tr>
              <th>Tipo Grau Sangue</th>
              <th>Quantidade</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of dataSource let i = index">
              <td>{{item.tipo}}</td>
              <td>{{item.quantidade}}</td>
              <td><span class="material-icons" [style]="item.color">sell</span></td>
            </tr>
          </tbody>
        </table>

      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="dataSourceExposicoes.length > 0">
  <div class="col-lg-12 col-sm-12" style="margin-top: 1em;">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">
          Exposições
        </h4>

        <table class="table table-hover table-header-custom">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Município</th>
              <th>UF</th>
              <th>Jurado de Admissão</th>
              <th>Jurado de Pista</th>
              <th>Participar</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of dataSourceExposicoes let i = index">
              <td>{{item.nome}}</td>
              <td>{{item.municipio}}</td>
              <td>{{item.uf}}</td>
              <td>{{item.juradoDeAdmissao}}</td>
              <td>{{item.juradoDePista}}</td>
              <td><i class="bi bi-pencil-square"></i></td>
            </tr>
          </tbody>
        </table>

      </div>
    </div>
  </div>
</div>